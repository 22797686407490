import React, {
  useMemo,
  useState,
  useEffect,
} from 'react';
import * as yup from "yup";
import {
  ChevronDown,
  CloseIcon,
  CopyIcon,
} from "assets/icons";
import { Button } from "components/common/Button";
import { ButtonsContainer } from "components/common/ButtonsContainer";
import {
  StyledAdditionalOptionsContainer,
  StyledButtonContainer,
  StyledExpandButton,
  StyledFormContainer,
  StyledFormSectionLabel,
} from "components/common/Forms/styled";
import { actionBlocker } from "utils/actionBlocker";
import {
  ContractRenewalLine,
  ContractRenewalLineFormFields,
  ContractRenewalLineStateFormFields,
} from 'interfaces/contractLines';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import { formDataSelector } from 'store/selectors/formState';
import { useGetAllProductsQuery } from 'store/services/products';
import { useGetAllTagsQuery } from 'store/services/tags';
import { TagType } from 'interfaces/tags';
import {
  getTagsIdsForSelectedRows,
  tagsSortFn,
} from 'utils/tags';
import {
  formatDateStringToDisplay,
  isoToDate,
} from 'utils/dates';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  parseStateFormData,
  parseContractLineFormData,
  prepareRenewalContractLine,
} from 'utils/contractLines';
import { Datepicker } from 'components/common/Datepicker';
import { Dropdown } from 'components/common/Dropdown';
import { InputBasic } from 'components/common/Input';
import { AssistiveText } from 'components/common/Input/AssistiveText';
import { NumberInput } from 'components/common/NumberInput';
import { SectionLabel } from 'components/common/SectionLabel';
import { copyToClipboard } from 'utils/copyToClipboard';
import { formStateActions } from 'store/slices/formState';
import { CheckboxInput } from 'components/common/CheckboxInput';
import { contractsActions } from 'store/slices/contracts';
import {
  predefinedContractSelector,
  renewalLinesSelector,
} from 'store/selectors/contracts';
import {
  cannotBeBeforeContractBookingDateFn,
  cannotBeBeforeContractStartDateFn,
} from 'pages/Contracts/EditContractLines/yupSchema';
import { Contract } from 'interfaces/contracts';

interface Props {
  onClose: () => void;
  onSuccess: (id: string) => void;
  selectedRows: ContractRenewalLine[];
  onAddTag: () => void;
}

export const RenewContractLines = ({
  onClose,
  onSuccess,
  onAddTag,
  selectedRows,
}: Props) => {
  const dispatch = useAppDispatch();
  const formData = useAppSelector(formDataSelector);
  const isBulkEdit = selectedRows.length > 1;
  const [isOptionsExpanded, setIsOptionsExpanded] = useState(false);
  const renewalLines = useAppSelector(renewalLinesSelector);
  const selectedContract = useAppSelector(predefinedContractSelector);

  const selectedRowsProductsIds = selectedRows.map((cl) => cl.product.id!);

  const {
    products,
  } = useGetAllProductsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      products: data
        ?.filter((prod) => !prod.deleted || selectedRowsProductsIds.includes(prod.id)),
    }),
  });


  const contractLineNames = useMemo(() => {
    return (renewalLines || [])
      ?.filter((line) => line.name !== selectedRows.at(0)?.name)
      .map((line) => line.name);
  }, [selectedRows, renewalLines]);

  const selectedRowsTagsIds = useMemo(() => getTagsIdsForSelectedRows(selectedRows), [selectedRows]);

  const {
    tagOptions,
  } = useGetAllTagsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      tagOptions: data
        ?.filter((tag) => tag.type === TagType.CONTRACT && (!tag.deleted || selectedRowsTagsIds.includes(tag.id)))
        ?.sort(tagsSortFn) || [],
    }),
  });

  const defaultValues = useMemo(() => ({
    ...(formData
      ? {
        ...parseStateFormData(formData as ContractRenewalLineStateFormFields),
      }
      : {
        contract: [selectedContract],
        customer: [],
        product: [],
        name: '',
        amount: '',
        previousAmount: '',
        startDate: undefined,
        endDate: undefined,
        cancelDate: undefined,
        bookingDate: undefined,
        revRecStartDate: undefined,
        revRecEndDate: undefined,
        crmId: '',
        externalLink: '',
        notes: '',
        tags: [],
        contractBasedMRRCalc: false,
      }
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  const editContractLinesSchema = (contractLineNames: string[] | undefined, selectedContract: Contract) => yup.object({
    contract: yup.array()
      .of(
        yup.object({
          id: yup.string(),
          name: yup.string(),
        }))
      .min(1, 'Contract is required.'),
    product: yup.array()
      .of(
        yup.object({
          id: yup.string(),
          name: yup.string(),
        }))
      .min(1, 'Product is required.'),
    name: yup.string()
      .required('Contract line name is required.')
      .test(
        'isUnique',
        'Contract line name must be unique.',
        (val) => !(contractLineNames?.includes(val || '')),
      ),
    amount: yup.string()
      .required('Amount is required.'),
    bookingDate: yup.date()
      .nullable()
      .test(
        'cannotBeBeforeContractBookingDate',
        `Service booking date cannot be before contract's booking date (${formatDateStringToDisplay(selectedContract?.bookingDate)})`,
        cannotBeBeforeContractBookingDateFn(selectedContract),
      )
      .required('Service booking date is required.'),
    startDate: yup.date()
      .nullable()
      .test(
        'cannotBeBeforeContractStartDate',
        `Service start date cannot be before contract's start date (${formatDateStringToDisplay(selectedContract?.startDate)})`,
        cannotBeBeforeContractStartDateFn(selectedContract),
      )
      .required('Start date is required.'),
    endDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'Service end date cannot be before start date.'),
      })
      .test(
        'cannotBeBeforeContractStartDate',
        `Service end date cannot be before contract's start date (${formatDateStringToDisplay(selectedContract?.startDate)})`,
        cannotBeBeforeContractStartDateFn(selectedContract),
      )
      .test(
        'cannotBeAfterContractEndDate',
        `Service end date cannot be after contract's end date (${formatDateStringToDisplay(selectedContract?.endDate!)})`,
        (val) => {
          if (val && selectedContract?.endDate) {
            return val.getTime() <= isoToDate(selectedContract.endDate)!
              .getTime();
          } else {
            return true;
          }
        },
      )
      .required('Service end date is required.'),
    cancelDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'Service cancel date cannot be before start date.'),
      })
      .test(
        'cannotBeBeforeContractStartDate',
        `Service cancel date cannot be before contract's start date (${formatDateStringToDisplay(selectedContract?.startDate)})`,
        cannotBeBeforeContractStartDateFn(selectedContract),
      )
      .test(
        'cannotBeAfterContractCancelDate',
        `Service cancel date cannot be after contract's cancel date (${formatDateStringToDisplay(selectedContract?.cancelDate!)})`,
        (val) => {
          if (val && selectedContract?.cancelDate) {
            return val.getTime() <= isoToDate(selectedContract.cancelDate)!
              .getTime();
          } else {
            return true;
          }
        },
      ),
    revRecStartDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'Revenue recognition start date cannot be before line item start date.'),
      })
      .test(
        'cannotBeBeforeContractStartDate',
        `Revenue recognition start date cannot be before contract's start date (${formatDateStringToDisplay(selectedContract?.startDate)})`,
        cannotBeBeforeContractStartDateFn(selectedContract),
      ),
    revRecEndDate: yup.date()
      .nullable()
      .when('revRecStartDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'Revenue recognition end date cannot be before Revenue recognition start date.'),
      })
      .test(
        'cannotBeBeforeContractStartDate',
        `Revenue recognition end date cannot be before contract's start date (${formatDateStringToDisplay(selectedContract?.startDate)})`,
        cannotBeBeforeContractStartDateFn(selectedContract),
      ),
    crmId: yup.string(),
    externalLink: yup.string()
      .url('Please provide valid URL.'),
    notes: yup.string(),
  })
    .required();

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState: { errors, isDirty, dirtyFields },
    trigger,
  } = useForm<ContractRenewalLineFormFields>({
    resolver: yupResolver(editContractLinesSchema(contractLineNames, selectedContract)),
    mode: 'onChange',
    defaultValues,
  });

  const triggerTouched = (field: any) => ((dirtyFields as Record<string, boolean | undefined>)[field]) && trigger(field);

  const handleSaveAndClose = handleSubmit((data) => {
    const contractLine = prepareRenewalContractLine(data);

    dispatch(contractsActions.updateContractRenewalLine(contractLine));
    onClose();
    onSuccess(data.id);
  });

  const handleSave = handleSubmit((data) => {
    const contractLine = prepareRenewalContractLine(data);

    dispatch(contractsActions.updateContractRenewalLine(contractLine));
    onSuccess(data.id);
  });

  const handleAddTag = ({ name }: { name: string }) => {
    dispatch(formStateActions.setPredefinedData({
      name,
      type: TagType.CONTRACT,
    }));
    dispatch(formStateActions.setFormData(parseContractLineFormData(getValues())));
    onAddTag();
  };

  useEffect(() => {
    const selectedRow = selectedRows[0];

    if (isBulkEdit) {
      reset(defaultValues);
    } else if (formData) {
      reset(parseStateFormData(formData as ContractRenewalLineStateFormFields));
    } else if (selectedRow) {
      reset({
        id: selectedRow.id,
        customer: [selectedRow.customer],
        product: [selectedRow.product],
        name: selectedRow.name,
        amount: `${selectedRow.amount}`,
        previousAmount: `${selectedRow.previousAmount}`,
        startDate: isoToDate(selectedRow.startDate),
        endDate: isoToDate(selectedRow.endDate),
        cancelDate: isoToDate(selectedRow.cancelDate),
        bookingDate: isoToDate(selectedRow.bookingDate),
        revRecStartDate: isoToDate(selectedRow.revRecStartDate),
        revRecEndDate: isoToDate(selectedRow.revRecEndDate),
        contract: [selectedContract],
        crmId: selectedRow.crmId || '',
        externalLink: selectedRow.externalLink || '',
        notes: selectedRow.notes || '',
        tags: selectedRow.tags,
        contractBasedMRRCalc: selectedRow.contractBasedMRRCalc,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows, reset, isBulkEdit, defaultValues, selectedContract]);

  return (
    <>
      <header>
        <ButtonsContainer>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={() => actionBlocker(onClose, isDirty)}
            pushRight
            data-cy="ercl-button-close"
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <StyledFormSectionLabel data-cy="ercl-header">
          <span>Edit Contract Renewal Line</span>
          <ButtonsContainer>
            <Button
              variant="simple"
              size="large"
              type="button"
              onClick={(e) => copyToClipboard(e, selectedRows[0].id)}
              data-cy="ercl-button-id"
            >
              <span>Insights Contract Line ID</span>
              <CopyIcon />
            </Button>
          </ButtonsContainer>
        </StyledFormSectionLabel>
      </header>
      <main>
        <StyledFormContainer>
          <>
            <Controller
              name="contract"
              control={control}
              render={({ field }) =>
                <Dropdown
                  labelText="Contract"
                  labelField="name"
                  valueField="id"
                  searchBy="name"
                  placeholder="Select Contract"
                  options={[]}
                  values={getValues('contract')}
                  {...field}
                  error={errors.contract?.message}
                  dataCy="ercl-dropdown-contract"
                  disabled
                />
              }
            />
            <SectionLabel secondary>
              <span>Contract Line information</span>
            </SectionLabel>
            <InputBasic
              isRequired
              labelText="Contract Line Name"
              placeholder="Name your contract line"
              {...register('name')}
              onBlur={(e) => {
                const fieldValue = e.target.value;

                if (fieldValue) {
                  setValue('name', fieldValue.trim(), { shouldValidate: true });
                }
              }}
              error={errors.name?.message}
              tooltip="You can change the contract line name, but it must be a unique value."
              data-cy="ercl-input-name"
            />
          </>
          <Controller
            name="product"
            control={control}
            render={({ field }) =>
              <div>
                <Dropdown
                  labelText="Product"
                  labelField="name"
                  valueField="id"
                  searchBy="name"
                  placeholder="Select Product SKU"
                  options={products || []}
                  values={getValues('product')}
                  {...field}
                  error={errors.product?.message}
                  isRequired
                  dataCy="ercl-dropdown-product"
                  disabled
                />
                <AssistiveText
                  text={getValues('product')
                    .at(0)?.revenueType} />
              </div>
            }
          />
          <Controller
            name="previousAmount"
            control={control}
            render={({ field }) =>
              <NumberInput
                labelText="Original Amount"
                {...field}
                onChange={field.onChange}
                placeholder="Enter Amount"
                error={errors.amount?.message}
                tooltip="Enter the total value of the product for this contract."
                isRequired
                data-cy="ercl-input-amount"
                disabled
              />
            }
          />
          <Controller
            name="amount"
            control={control}
            render={({ field }) =>
              <NumberInput
                labelText="Renewal Amount"
                {...field}
                onChange={field.onChange}
                placeholder="Enter Amount"
                error={errors.amount?.message}
                tooltip="Enter the total value of the product for this contract."
                isRequired
                data-cy="ercl-input-amount"
              />
            }
          />
          <Controller
            name="bookingDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Service Booking Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('startDate');
                  triggerTouched('endDate');
                  triggerTouched('cancelDate');
                }}
                error={errors.bookingDate?.message}
                tooltip="Service booking date is used to indicate when this contract line was initially booked."
                isRequired
              />
            }
          />
          <Controller
            name="startDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Service Start Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('bookingDate');
                  triggerTouched('endDate');
                  triggerTouched('cancelDate');
                }}
                error={errors.startDate?.message}
                tooltip="The service start date is used to indicate the beginning of service for this contract line."
                isRequired
                data-cy="ercl-datepicker-start"
              />
            }
          />
          <Controller
            name="endDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Service End Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('startDate');
                  triggerTouched('bookingDate');
                  triggerTouched('cancelDate');
                }}
                error={errors.endDate?.message}
                tooltip="This contract line will remain active until it is renewed or cancelled."
                isRequired
                data-cy="ercl-datepicker-end"
              />
            }
          />
          <Controller
            name="cancelDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Service Cancel Date"
                placeholder="Cancel Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('startDate');
                  triggerTouched('endDate');
                  triggerTouched('bookingDate');
                }}
                error={errors.cancelDate?.message}
                tooltip="Entering a service cancel date will terminate the contract line upon that date. Your overall contract will still remain active."
                isOptional
                data-cy="ercl-datepicker-cancel"
              />
            }
          />
          <StyledFormSectionLabel>
            <span>
              Revenue Recognition
            </span>
          </StyledFormSectionLabel>
          <Controller
            name="revRecStartDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Recognition Start Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('contractLineRevRecEndDate');
                }}
                error={errors.revRecStartDate?.message}
                tooltip="Enter revenue recognition start date. You can add it now or later."
                isOptional
              />
            }
          />
          <Controller
            name="revRecEndDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Recognition End Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                }}
                error={errors.revRecEndDate?.message}
                tooltip="Enter revenue recognition end date. You can add it now or later."
                isOptional
              />
            }
          />
          <StyledExpandButton
            variant="borderless"
            size="large"
            type="button"
            onClick={() => setIsOptionsExpanded(!isOptionsExpanded)}
            isOpen={isOptionsExpanded}
            data-cy="ercl-button-additional"
          >
            Additional Options
            <ChevronDown />
          </StyledExpandButton>
          <StyledAdditionalOptionsContainer isOpen={isOptionsExpanded}>
            <Controller
              name="tags"
              control={control}
              render={({ field }) =>
                <Dropdown
                  labelText="Tags"
                  labelField="name"
                  valueField="id"
                  searchBy="name"
                  options={tagOptions}
                  placeholder="Select Tags"
                  entityName="tag"
                  values={getValues('tags')}
                  {...field}
                  isOptional
                  clearable
                  multi
                  error={errors.tags?.message}
                  tooltip="Categorize this contract line with a contract tag(s)."
                  create
                  onCreateNew={handleAddTag}
                  dataCy="ercl-dropdown-tags"
                />
              }
            />
            <InputBasic
              isOptional
              labelText="CRM ID"
              placeholder="Paste CRM identifier"
              tooltip="Paste in an ID from your CRM to help identify this contract line."
              {...register('crmId')}
              error={errors.crmId?.message}
              data-cy="ercl-input-crm"
            />
            <InputBasic
              isOptional
              labelText="External Link"
              placeholder="Paste an external link"
              tooltip="You can paste in a link to your CRM or wherever you store your contracts."
              {...register('externalLink')}
              error={errors.externalLink?.message}
              onBlur={(e) => {
                const fieldValue = e.target.value;

                if (fieldValue && !fieldValue.startsWith('http')) {
                  setValue('externalLink', 'http://' + fieldValue, { shouldValidate: true });
                }
              }}
              data-cy="ercl-input-external"
            />
            <InputBasic
              isOptional
              labelText="Notes"
              placeholder="Add a quick note"
              {...register('notes')}
              error={errors.notes?.message}
              data-cy="ercl-input-notes"
              multiline
            />
            <CheckboxInput
              isOptional
              labelText="MRR Start Date"
              {...register('contractBasedMRRCalc')}
              error={errors.contractBasedMRRCalc?.message}
              data-cy="acl-input-mrr-calc"
              tooltip="MRR calculations default to using the Item start and end date. By selecting the toggle to the Contract start and end date, MRR will be calculated using those dates."
              checkboxLabel="Contract Start/End Date"
              defaultValue={getValues().contractBasedMRRCalc}
            />
          </StyledAdditionalOptionsContainer>
        </StyledFormContainer>
      </main>
      <footer>
        <StyledButtonContainer pushRight>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={handleSave}
            disabled={false}
            data-cy="ercl-button-save-close"
          >
            SAVE
          </Button>
          <Button
            type="submit"
            onClick={handleSaveAndClose}
            disabled={false}
            data-cy="ercl-button-save-new"
          >
            SAVE & CLOSE
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};
