import * as React from "react";

const SvgComponent = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m12 6.19 4.669 4.202a1 1 0 0 1 .331.743V17.5a1 1 0 1 1-2 0v-4a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v4a1 1 0 1 1-2 0v-6.365a1 1 0 0 1 .331-.743L12 6.19Zm.669-2.088a1 1 0 0 0-1.338 0l-7.72 6.948c-.566.51-.205 1.45.557 1.45.46 0 .832.373.832.832V19.5a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 1 1 2 0v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-6.168c0-.46.372-.832.832-.832.762 0 1.123-.94.556-1.45L12.67 4.102Z"
      fill="inherit"
    />
  </svg>
);

export default SvgComponent;
