import { api } from "./api";
import { FinancialsUpdatePayload } from "interfaces/financials";
import {
  AccountsTags,
  CommonMetricTags,
} from "./api-tags";

export const financialsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    putFinancials: builder.mutation<void, FinancialsUpdatePayload>({
      query: ({ financialType, financials }) => ({
        url: `financials/${financialType}`,
        method: 'PUT',
        body: financials,
      }),
      invalidatesTags: (res, err) => !err ? [
        AccountsTags.AccountMappings,
        AccountsTags.Accounts,
        AccountsTags.FactaAccounts,
        ...CommonMetricTags,
      ] : [],
    }),
  }),
});

export const {
  usePutFinancialsMutation,
} = financialsApi;
