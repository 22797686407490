import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import {
  LeftPane,
  RightPane,
} from 'components/Layout';
import { SectionLabel } from 'components/common/SectionLabel';
import { BulkImportSidePanel } from '../../../../components/BulkImport/SidePanel';
import { Tabs } from 'components/common/Tabs';
import { AgGridReact } from '@ag-grid-community/react';
import { GridOptions } from '@ag-grid-community/core';
import { getColumnDefs } from './columnDefinitions';
import { useRightPanes } from 'hooks/useRightPanes';
import { BulkImportContractsEditContract } from '../EditContract';
import { useGetAllCustomersQuery } from 'store/services/customers';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import { useBulkImportContractsMutation } from 'store/services/import';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import { bulkImportActions } from 'store/slices/bulkImport';
import { Loading } from 'components/Loading';
import { AddCustomer } from 'pages/Customers/AddCustomer';
import { AddTag } from 'pages/Tags/AddTag';
import { Customer } from 'interfaces/customers';
import { Product } from 'interfaces/products';
import { Tag } from 'interfaces/tags';
import { FactaTable } from 'components/FactaTable';
import { CompanyRevenueType } from 'interfaces/company';
import { BulkImportContractsRow } from 'interfaces/bulkImportContracts';
import {
  bulkImportCommonDataSelector,
  bulkImportContractsResultsSelector,
  bulkImportDinstinctContractsSelector,
} from 'store/selectors/bulkImport';
import { useGetContractsSearchQuery } from 'store/services/contracts';
import { prepareValidatedContracts } from 'utils/bulkImport';
import { BulkImportDeleteContracts } from '../DeleteContracts';
import { toastWarning } from 'utils/toast';
import { Modal } from 'components/Layout/Modal';
import { DuplicatesInformation } from 'pages/Contracts/DuplicatesInformation';

export const BulkImportContractsReviewContracts = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const columnDefs = getColumnDefs();
  const gridRef = useRef<AgGridReact>(null);
  const [bulkImport, { isLoading }] = useBulkImportContractsMutation();
  const [selectedTab, setSelectedTab] = useState('all');
  const [duplicatesInformationSeen, setDuplicatesInformationSeen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<BulkImportContractsRow[]>([]);
  const contractRows = useAppSelector(bulkImportDinstinctContractsSelector);
  const bulkImportResults = useAppSelector(bulkImportContractsResultsSelector);
  const bulkImportCommonData = useAppSelector(bulkImportCommonDataSelector);
  useGetAllCustomersQuery();
  useGetContractsSearchQuery({
    filtering: { deleted: false },
    pagination: {
      page: 0,
      perPage: 0,
    },
    simplified: false,
  }, {
    selectFromResult: ({ data }) => ({
      contractNames: data?.data.map((cont) => cont.name),
    }),
  });

  const {
    rows: allRows,
  } = bulkImportResults;

  const {
    contractsCount,
    contractsErrorsCount,
    contractsDuplicatesCount,
  } = bulkImportCommonData;

  const { resetPanes, rightPanes, setSelectedPane } = useRightPanes({
    summary: true,
    edit: false,
    delete: false,
    addCustomer: false,
    addTag: false,
    duplicatesInformation: false,
  });

  const gridOptions: GridOptions = {
    onSelectionChanged: (e) => {
      const selectedNodes = e.api.getSelectedNodes();
      setSelectedRows(e.api.getSelectedRows());
      if (selectedNodes.length === 1) {
        setSelectedPane('edit');
      } else {
        setSelectedPane('summary');
      }
    },
    processCellForClipboard: ({ value }) => value?.providedValue || '',
  };

  const tabs = [
    {
      name: 'All',
      value: "all",
    },
    {
      name: `Issues ${contractsErrorsCount ? `(${contractsErrorsCount})` : ''}`,
      value: "errors",
      disabled: !contractsErrorsCount,
    },
    {
      name: `Duplicates ${contractsDuplicatesCount ? `(${contractsDuplicatesCount})` : ''}`,
      value: "duplicates",
      disabled: !contractsDuplicatesCount,
    },
  ];

  const tabbedRows = contractRows.filter((row) => {
    switch (selectedTab) {
      case 'errors':
        return row.contractErrors !== null && row.contractErrors !== 'duplicate';
      case 'duplicates':
        return row.contractErrors === 'duplicate';
      default:
        return row;
    }
  });

  const handleCancel = () => navigate('/data-input/contract-management/contracts/import');

  const handleContinue = () => {
    const validatedContracts = prepareValidatedContracts(allRows, contractRows);

    bulkImport({ data: validatedContracts })
      .unwrap()
      .then((result) => {
        if (result) {
          dispatch(bulkImportActions.populateContracts({ result }));

          if (!result.contractsErrorsCount && !result.contractsDuplicatesCount) {
            navigate('/data-input/contract-management/contracts/import/review');
          } else {
            toastWarning('There are still some issues with your data. Resolve all errors/duplicates.');
          }
        }
      });
  };

  const handleTriggerDelete = () => {
    setSelectedPane('delete');
  };

  const handleUpdateRow = (id: string, field: string, addedEntity: Customer | Product | Tag) => {
    dispatch(bulkImportActions.updateContractsField({
      id,
      name: field,
      data: {
        providedValue: addedEntity.name,
        matchedValue: { id: addedEntity.id },
        errorCode: null,
      },
    }));
    setSelectedPane('edit');
  };

  useEffect(() => {
    if (selectedTab === 'duplicates' && !duplicatesInformationSeen) {
      setSelectedPane('duplicatesInformation');
      setDuplicatesInformationSeen(true);
    }
  }, [duplicatesInformationSeen, selectedTab, setSelectedPane]);

  if (isLoading) {
    return (
      <LeftPane>
        <Loading
          title={`Validating ${contractsCount} contracts...`}
          timeoutMessages={[
            {
              timeoutMs: 20000,
              message: 'Upload process is taking more time than usual... but we\'re working on it!',
            },
            {
              timeoutMs: 40000,
              message: 'Upload process is taking too much time. If it fails, consider reducing number of lines in your file.',
            },
          ]}
        />
      </LeftPane>
    );
  }

  return (
    <>
      <LeftPane>
        <ButtonsContainer paddingBottom={20}>
          <Breadcrumbs>
            <Breadcrumb link="/data-input/contract-management/contracts">Contracts</Breadcrumb>
            <Breadcrumb link="/data-input/contract-management/contracts/import">Upload File</Breadcrumb>
            <Breadcrumb link="/data-input/contract-management/contracts/import/clean-data">Clean Data</Breadcrumb>
            <Breadcrumb>Review Contracts</Breadcrumb>
          </Breadcrumbs>
        </ButtonsContainer>
        <SectionLabel>
          <span>
            Review Contracts
            <p>Please review and update any required fields for your contracts.</p>
          </span>
        </SectionLabel>
        <Tabs
          tabs={tabs}
          value={selectedTab}
          onChange={(tab) => setSelectedTab(tab.value)}
        />
        <FactaTable
          gridRef={gridRef}
          data={tabbedRows}
          useNativeFilters
          columnDefs={columnDefs}
          isLoading={isLoading}
          selectedRowsLength={selectedRows.length}
          entityName="Contract"
          onClickDelete={handleTriggerDelete}
          gridOptions={gridOptions}
          customEmptyState={() => <>Nothing to show</>}
          showQuickSearch
          customButtons={selectedTab === 'duplicates'
            ? [{
              title: 'Remove All Duplicates',
              onClick: () => {
                gridRef.current?.api.selectAll();
                setTimeout(() => {
                  handleTriggerDelete();
                }, 0);
              },
              variant: 'borderless',
              size: 'large',
              divider: true,
            }]
            : undefined}
        />
      </LeftPane>
      <RightPane isOpen>
        {rightPanes.summary && (
          <BulkImportSidePanel
            companyRevenueType={CompanyRevenueType.CONTRACTS}
            handleCancel={handleCancel}
            handleContinue={handleContinue}
            isContinueDisabled={!!contractsErrorsCount || !!contractsDuplicatesCount || isLoading}
            step={3}
            isStepDone={contractsErrorsCount === 0 && contractsDuplicatesCount === 0}
          />
        )}
        {rightPanes.delete && selectedRows.length > 0 && (
          <BulkImportDeleteContracts
            selectedRows={selectedRows}
            onClose={() => resetPanes()}
          />
        )}
        {rightPanes.edit && selectedRows.length === 1 && (
          <BulkImportContractsEditContract
            id={selectedRows[0].id}
            onClose={() => gridRef.current?.api.deselectAll()}
            // onAddTag={() => setSelectedPane('addTag')} // TODO needs to be fixed for Bulk import (ticket: 14095)
            onAddCustomer={() => setSelectedPane('addCustomer')}
            onDelete={handleTriggerDelete}
          />
        )}
        {rightPanes.addCustomer && (
          <AddCustomer
            onClose={() => setSelectedPane('edit')}
            onSuccess={(addedEntity) => handleUpdateRow(selectedRows.at(0)!.id, 'contractCustomer', addedEntity)}
            onGoBack={() => setSelectedPane('edit')}
            isIndirectOpen
          />
        )}
        {rightPanes.addTag && (
          <AddTag
            onClose={() => setSelectedPane('edit')}
            onSuccess={(addedEntity) => handleUpdateRow(selectedRows.at(0)!.id, 'contractTags', addedEntity)}
            onGoBack={() => setSelectedPane('edit')}
            isIndirectOpen
          />
        )}
      </RightPane>
      <Modal
        isOpen={rightPanes.duplicatesInformation}
        onClose={resetPanes}
      >
        <DuplicatesInformation onClose={resetPanes} />
      </Modal>
    </>
  );
};
