import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/store";

const stateSlice = (state: RootState) => state.gridHelper;

export const gridHelperSelector = createSelector(
  stateSlice,
  (slice) => ({
    ...slice,
    totalSelected: slice.isAllSelected
      ? (slice.total || 0) - slice.deselectedIds.length
      : slice.selectedIds.length,
  }),
);
