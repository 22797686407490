import styled from 'styled-components';
import { SweatingLlamaImage } from 'assets/images';

const StyledNoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 30px;
`;

const StyledTitle = styled.p`
  font-weight: 700;
`;

const AdditionalMessage = styled.p`
  font-weight: 500;
  margin-top: 0;
`;

interface Props {
  title?: string;
  additionalMessage?: string;
}

export const FactaChartNoDataOverlay = ({
  title,
  additionalMessage,
}: Props) => {
  return (
    <StyledNoData>
      <SweatingLlamaImage />
      <StyledTitle>{title || 'No data'}</StyledTitle>
      <AdditionalMessage>{additionalMessage}</AdditionalMessage>
    </StyledNoData>
  );
};
