import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import {
  LeftPane,
  RightPane,
} from 'components/Layout';
import { SectionLabel } from 'components/common/SectionLabel';
import {
  bulkImportCommonDataSelector,
  bulkImportSubsResultsSelector,
  bulkImportContractsResultsSelector,
  bulkImportGetInvalidCustomers,
  bulkImportGetInvalidProducts,
  bulkImportGetInvalidTags,
  bulkImportIsUserAbleToNavigateBackToCleanData,
} from 'store/selectors/bulkImport';
import { BulkImportSidePanel } from '../SidePanel';
import { Tabs } from 'components/common/Tabs';
import { AgGridContainer } from 'components/common/AgGridContainer';
import { AgGridReact } from '@ag-grid-community/react';
import { GridOptions } from '@ag-grid-community/core';
import { getColumnDefs } from './columnDefinitions';
import { useRightPanes } from 'hooks/useRightPanes';
import {
  useBulkAddCustomersMutation,
  useGetAllCustomersQuery,
} from 'store/services/customers';
import {
  useBulkAddProductsMutation,
  useGetAllProductsQuery,
} from 'store/services/products';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { QuickSearch } from 'components/common/QuickSearch';
import { StyledQuantityDisplay } from 'components/Tables/styled';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import {
  prepareCustomersToCreate,
  prepareProductsToCreate,
  prepareTagsToCreate,
  prepareValidatedContracts,
  prepareValidatedSubscriptions,
} from 'utils/bulkImport';
import { toastSuccess } from 'utils/toast';
import {
  bulkImportActions,
  InvalidEntity,
} from 'store/slices/bulkImport';
import { BulkImportEntityType } from 'interfaces/bulkImport';
import { TabLabel } from './TabLabel';
import { commonGridOptions } from 'utils/aggrid';
import {
  useBulkAddTagsMutation,
  useGetAllTagsQuery,
} from 'store/services/tags';
import { AddProduct } from 'pages/Products/AddProduct';
import { Customer } from 'interfaces/customers';
import { Product } from 'interfaces/products';
import {
  Tag,
  TagType,
} from 'interfaces/tags';
import { AddCustomer } from 'pages/Customers/AddCustomer';
import { AddTag } from 'pages/Tags/AddTag';
import {
  useBulkImportContractsMutation,
  useBulkImportSubscriptionsMutation,
} from 'store/services/import';
import { invalidateTags } from 'store/services/api';
import { Loading } from 'components/Loading';
import { CompanyRevenueType } from 'interfaces/company';
import { BulkImportContractsInterface } from 'interfaces/bulkImportContracts';
import { BulkImportSubscriptionsInterface } from 'interfaces/bulkImportSubscriptions';
import {
  ContractsTags,
  SubscriptionsTags,
} from 'store/services/api-tags';
import { formStateActions } from 'store/slices/formState';

interface Props {
  companyRevenueType: CompanyRevenueType,
}

export const BulkImportCleanData = ({
  companyRevenueType,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const gridRef = useRef<AgGridReact>(null);

  const { data: customersAll } = useGetAllCustomersQuery();
  const customers = useMemo(() => customersAll?.filter((cust) => !cust.deleted), [customersAll]);

  const { data: productsAll } = useGetAllProductsQuery();
  const products = useMemo(() => productsAll?.filter((prod) => !prod.deleted), [productsAll]);

  const { data: tagsAll } = useGetAllTagsQuery();
  const tags = useMemo(() => tagsAll?.filter((tag) => !tag.deleted && tag.type === TagType.CONTRACT), [tagsAll]);

  const [bulkAddCustomers, { isLoading: isLoadingCustomers }] = useBulkAddCustomersMutation();
  const [bulkAddProducts, { isLoading: isLoadingProducts }] = useBulkAddProductsMutation();
  const [bulkAddTags, { isLoading: isLoadingTags }] = useBulkAddTagsMutation();
  const [bulkImportSubscriptions, { isLoading: isLoadingBulkImportSubscriptions }] = useBulkImportSubscriptionsMutation();
  const [bulkImportContracts, { isLoading: isLoadingBulkImportContracts }] = useBulkImportContractsMutation();

  const bulkImport = companyRevenueType === CompanyRevenueType.CONTRACTS
    ? bulkImportContracts
    : bulkImportSubscriptions;

  const isLoadingBulkImport = companyRevenueType === CompanyRevenueType.CONTRACTS
    ? isLoadingBulkImportContracts
    : isLoadingBulkImportSubscriptions;

  const importPath = companyRevenueType === CompanyRevenueType.CONTRACTS
    ? '/data-input/contract-management/contracts'
    : '/data-input/revenue-management/revenue';

  const importName = companyRevenueType === CompanyRevenueType.CONTRACTS
    ? 'Contracts'
    : 'Revenue';

  useGetAllCustomersQuery();
  useGetAllProductsQuery();
  useGetAllTagsQuery();
  const bulkImportSubscriptionsResults = useAppSelector(bulkImportSubsResultsSelector);
  const bulkImportContractsResults = useAppSelector(bulkImportContractsResultsSelector);
  const bulkImportCommonData = useAppSelector(bulkImportCommonDataSelector);
  const invalidCustomers = useAppSelector(bulkImportGetInvalidCustomers);
  const invalidProducts = useAppSelector(bulkImportGetInvalidProducts);
  const invalidTags = useAppSelector(bulkImportGetInvalidTags);
  const isUserAbleToNavigateBackToCleanData = useAppSelector(bulkImportIsUserAbleToNavigateBackToCleanData);
  const [options, setOptions] = useState<any[] | undefined>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const [invalidEntities, setInvalidEntities] = useState<InvalidEntity[]>([]);
  const [selectedTab, setSelectedTab] = useState(invalidCustomers.length
    ? BulkImportEntityType.CUSTOMERS
    : invalidProducts.length
      ? BulkImportEntityType.PRODUCTS
      : BulkImportEntityType.TAGS,
  );

  const { rows: subscriptionsRows } = bulkImportSubscriptionsResults;
  const { rows: contractsRows } = bulkImportContractsResults;
  const {
    totalRows,
    contractsCount,
    contractLinesCount,
  } = bulkImportCommonData;

  const stillInvalidSelectedEntities = invalidEntities.filter((item) => item.status === 'incorrect').length;

  const stillInvalidCustomers = invalidCustomers.filter((cust) => cust.status === 'incorrect').length;
  const stillInvalidProducts = invalidProducts.filter((prod) => prod.status === 'incorrect').length;
  const stillInvalidTags = invalidTags.filter((tag) => tag.status === 'incorrect').length;

  const isAllCleared = stillInvalidCustomers + stillInvalidProducts + stillInvalidTags === 0;

  const { rightPanes, setSelectedPane, resetPanes } = useRightPanes({
    summary: true,
    edit: false,
    delete: false,
    products: false,
    customers: false,
    tags: false,
  });

  const columnDefs = getColumnDefs({
    tab: selectedTab,
    options: options || [],
    gridHeight: containerRef.current?.clientHeight || 300,
    dispatch,
    setSelectedPane,
  });

  const gridOptions: GridOptions = {
    ...commonGridOptions,
    processCellForClipboard: ({ value, column }) => {
      const colId = column.getId();
      switch (colId) {
        case 'entity':
          return value?.name || '';
        case 'revenueType':
          return value?.id || '';
        default:
          return value;
      }
    },
    onFirstDataRendered: () => {
      dispatch(formStateActions.clear());
    },
  };

  const tabs = [
    {
      name: <TabLabel
        name="Step 1: Input Customers"
        entityName="customer"
        invalidEntities={stillInvalidCustomers}
      />,
      value: BulkImportEntityType.CUSTOMERS,
    },
    {
      name: <TabLabel
        name="Step 2: Input Products"
        entityName="product"
        invalidEntities={stillInvalidProducts}
      />,
      value: BulkImportEntityType.PRODUCTS,
    },
    {
      name: <TabLabel
        name="Step 3: Input Tags"
        entityName="tag"
        invalidEntities={stillInvalidTags}
      />,
      value: BulkImportEntityType.TAGS,
    },
  ];

  useEffect(() => {
    switch (selectedTab) {
      case BulkImportEntityType.CUSTOMERS:
        return setInvalidEntities(invalidCustomers);
      case BulkImportEntityType.PRODUCTS:
        return setInvalidEntities(invalidProducts);
      case BulkImportEntityType.TAGS:
        return setInvalidEntities(invalidTags);
    }
  }, [invalidCustomers, invalidProducts, invalidTags, selectedTab]);

  const handleCancel = () => navigate(`${importPath}/import`);

  const handleContinue = () => {
    const customersToCreate = prepareCustomersToCreate(invalidCustomers);
    const productsToCreate = prepareProductsToCreate(invalidProducts);
    const tagsToCreate = prepareTagsToCreate(invalidTags);

    const customersPromise = customersToCreate.length
      ? bulkAddCustomers(customersToCreate)
        .unwrap()
        .then((addedCustomers) => {
          toastSuccess('Customers successfully created.');
          return addedCustomers;
        })
      : Promise.resolve([]);

    const productsPromise = productsToCreate.length
      ? bulkAddProducts(productsToCreate)
        .unwrap()
        .then((addedProducts) => {
          toastSuccess('Products successfully created.');
          return addedProducts;
        })
      : Promise.resolve([]);

    const tagsPromise = tagsToCreate.length
      ? bulkAddTags(tagsToCreate)
        .unwrap()
        .then((addedTags) => {
          toastSuccess('Tags successfully created.');
          return addedTags;
        })
      : Promise.resolve([]);

    Promise.all([
      customersPromise,
      productsPromise,
      tagsPromise,
    ])
      .then(() => {
        const bulkImportPayload = companyRevenueType === CompanyRevenueType.CONTRACTS
          ? prepareValidatedContracts(contractsRows, undefined, invalidCustomers, invalidProducts, invalidTags)
          : prepareValidatedSubscriptions(subscriptionsRows, invalidCustomers, invalidProducts, invalidTags);

        // TODO Resolve different interfaces problem here
        // @ts-ignore
        bulkImport({ data: bulkImportPayload })
          .unwrap()
          .then((result) => {
            if (result) {
              if (companyRevenueType === CompanyRevenueType.CONTRACTS) {
                const typedResult = result as BulkImportContractsInterface;
                dispatch(bulkImportActions.populateContracts({ result: typedResult }));
                navigate(`${importPath}/import/review-contracts`);
              }

              if (companyRevenueType === CompanyRevenueType.SUBSCRIPTIONS) {
                const typedResult = result as BulkImportSubscriptionsInterface;
                dispatch(bulkImportActions.populateSubscriptions({ result: typedResult }));
                navigate(`${importPath}/import/review`);
              }
            } else {
              toastSuccess(`${companyRevenueType} successfully added`);
              dispatch(invalidateTags([SubscriptionsTags.Subscriptions, ContractsTags.Contracts]));
              dispatch(bulkImportActions.clear());
              navigate(importPath);
            }
          });
      });
  };

  const updateEntity = ({ addedEntity, tab }: { addedEntity: Product | Customer | Tag, tab: BulkImportEntityType }) => {
    if (tab === BulkImportEntityType.PRODUCTS) {
      dispatch(bulkImportActions.updateInvalidProduct({
        id: gridRef.current?.api.getSelectedRows()[0].id,
        entity: {
          createNew: false,
          id: addedEntity?.id || null,
          name: addedEntity?.name || null,
        },
        revenueType: {
          createNew: false,
          id: addedEntity ? (addedEntity as Product).revenueType : null,
        },
      }));
    }

    if (tab === BulkImportEntityType.CUSTOMERS) {
      dispatch(bulkImportActions.updateInvalidCustomer({
        id: gridRef.current?.api.getSelectedRows()[0].id,
        entity: {
          createNew: false,
          id: addedEntity?.id || null,
          name: addedEntity?.name || null,
        },
      }));
    }

    if (tab === BulkImportEntityType.TAGS) {
      dispatch(bulkImportActions.updateInvalidTag({
        id: gridRef.current?.api.getSelectedRows()[0].id,
        entity: {
          createNew: false,
          id: addedEntity?.id || null,
          name: addedEntity?.name || null,
        },
      }));
    }
  };

  const handleSearchChange = (search: string) => {
    gridRef.current?.api?.setGridOption('quickFilterText', search);
  };

  useEffect(() => {
    if (!totalRows && !contractsCount && !contractLinesCount) {
      navigate(`${importPath}/import`);
    }
  }, [contractLinesCount, contractsCount, importPath, navigate, totalRows]);

  useEffect(() => {
    if (isAllCleared && !isUserAbleToNavigateBackToCleanData) {
      if (companyRevenueType === CompanyRevenueType.CONTRACTS) {
        navigate(`${importPath}/import/review-contracts`);
      } else {
        navigate(`${importPath}/import/review`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  useEffect(() => {
    if (selectedTab === BulkImportEntityType.CUSTOMERS) {
      return setOptions(customers);
    }

    if (selectedTab === BulkImportEntityType.PRODUCTS) {
      return setOptions(products);
    }

    if (selectedTab === BulkImportEntityType.TAGS) {
      return setOptions(tags);
    }
  }, [customers, products, selectedTab, tags]);

  if (isLoadingCustomers || isLoadingProducts || isLoadingTags || isLoadingBulkImport) {
    return (
      <LeftPane>
        <Loading
          title={isLoadingBulkImport
            ? 'Re-validating...'
            : `Creating ${invalidCustomers.length} customers, ${invalidProducts.length} products and ${invalidTags.length} tags...`}
          timeoutMessages={[
            {
              timeoutMs: 20000,
              message: 'Upload process is taking more time than usual... but we\'re working on it!',
            },
            {
              timeoutMs: 40000,
              message: 'Upload process is taking too much time. If it fails, consider reducing number of lines in your file.',
            },
          ]}
        />
      </LeftPane>
    );
  }

  return (
    <>
      <LeftPane>
        <ButtonsContainer paddingBottom={20}>
          <Breadcrumbs>
            <Breadcrumb link={importPath}>{importName}</Breadcrumb>
            <Breadcrumb link={`${importPath}/import`}>Upload File</Breadcrumb>
            <Breadcrumb>Clean Data</Breadcrumb>
          </Breadcrumbs>
          <QuickSearch
            handleSearchChange={handleSearchChange}
            pushRight
          />
        </ButtonsContainer>
        <SectionLabel>
          <span>
            Clean Data
            <p>An automated process to easily insert and correct data</p>
          </span>
        </SectionLabel>
        <Tabs
          tabs={tabs}
          value={selectedTab}
          onChange={(tab) => setSelectedTab(tab.value as BulkImportEntityType)}
          disabled={rightPanes.customers || rightPanes.products || rightPanes.tags}
        />
        <ButtonsContainer>
          <StyledQuantityDisplay>
            {invalidEntities?.length - stillInvalidSelectedEntities} / {invalidEntities?.length || 0} {selectedTab} cleaned
          </StyledQuantityDisplay>
        </ButtonsContainer>
        <AgGridContainer
          ref={containerRef}
          className="ag-theme-material"
          $disabled={rightPanes.customers || rightPanes.products || rightPanes.tags}
          condensed
        >
          <AgGridReact
            ref={gridRef}
            rowData={invalidEntities}
            columnDefs={columnDefs}
            rowSelection="multiple"
            gridOptions={gridOptions}
            noRowsOverlayComponent={() => <>No issues to resolve</>}
          />
        </AgGridContainer>
      </LeftPane>
      <RightPane isOpen>
        {rightPanes.summary && (
          <BulkImportSidePanel
            companyRevenueType={companyRevenueType}
            handleCancel={handleCancel}
            handleContinue={handleContinue}
            isContinueDisabled={!isAllCleared || isLoadingCustomers || isLoadingProducts || isLoadingTags}
            step={2}
            isStepDone={stillInvalidCustomers + stillInvalidProducts + stillInvalidTags === 0}
          />
        )}
        {rightPanes.products && (
          <AddProduct
            isIndirectOpen
            onClose={() => resetPanes()}
            onGoBack={() => setSelectedPane('summary')}
            onSuccess={(addedEntity) => updateEntity({ addedEntity, tab: BulkImportEntityType.PRODUCTS })}
          />
        )}
        {rightPanes.customers && (
          <AddCustomer
            isIndirectOpen
            onClose={() => resetPanes()}
            onGoBack={() => setSelectedPane('summary')}
            onSuccess={(addedEntity) => updateEntity({ addedEntity, tab: BulkImportEntityType.CUSTOMERS })}
          />
        )}
        {rightPanes.tags && (
          <AddTag
            isIndirectOpen
            onClose={() => resetPanes()}
            onGoBack={() => setSelectedPane('summary')}
            onSuccess={(addedEntity) => updateEntity({ addedEntity, tab: BulkImportEntityType.TAGS })}
            allowOnlyOneTag={TagType.CONTRACT}
          />
        )}
      </RightPane>
    </>
  );
};
