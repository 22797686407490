import { api } from "./api";
import { OnProgress } from "interfaces/api";
import {
  BulkImportSubscriptionsInterface,
  BulkImportSubscriptionsPayload,
} from "interfaces/bulkImportSubscriptions";
import {
  BulkImportContractsInterface,
  BulkImportContractsPayload,
} from "interfaces/bulkImportContracts";
import {
  CommonMetricTags,
  ContractLinesTags,
  ContractsTags,
  SubscriptionsTags,
} from "./api-tags";

export const importApi = api.injectEndpoints({
  endpoints: (builder) => ({
    uploadBulkImportSubscriptions: builder.mutation<BulkImportSubscriptionsInterface, { formData: FormData; onProgress?: OnProgress }>({
      query: ({ formData, onProgress }) => {
        return {
          url: 'subscriptions/validate',
          method: 'POST',
          body: formData,
          onProgress,
        };
      },
    }),
    bulkImportSubscriptions: builder.mutation<BulkImportSubscriptionsInterface | null, { data: BulkImportSubscriptionsPayload[] }>({
      query: ({ data }) => {
        return {
          url: 'subscriptions/bulk_import',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: (res, err) => !err ? [
        SubscriptionsTags.Subscriptions,
        ...CommonMetricTags,
      ] : [],
    }),
    uploadBulkImportContracts: builder.mutation<BulkImportContractsInterface, { formData: FormData, onProgress?: OnProgress }>({
      query: ({ formData, onProgress }) => {
        return {
          url: 'contract_lines/validate',
          method: 'POST',
          body: formData,
          onProgress,
        };
      },
    }),
    bulkImportContracts: builder.mutation<BulkImportContractsInterface | null, { data: BulkImportContractsPayload[], save?: boolean }>({
      query: ({ data, save = false }) => {
        return {
          url: `contract_lines/bulk_import?save=${save}`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: (res, err) => !err ? [
        ContractsTags.Contracts,
        ContractsTags.ContractsSummary,
        ContractLinesTags.ContractLines,
        ContractLinesTags.ContractLinesByContractIds,
        ...CommonMetricTags,
      ] : [],
    }),
  }),
});

export const {
  useBulkImportSubscriptionsMutation,
  useUploadBulkImportSubscriptionsMutation,
  useBulkImportContractsMutation,
  useUploadBulkImportContractsMutation,
} = importApi;
