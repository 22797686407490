import { api } from "./api";
import { CommonMetricTags } from "./api-tags";

interface Settings {
  fiscalYearEnd: number;
  glActualEndDate: string;
  glActualStartDate: string;
  saasActualEndDate: string;
  saasActualStartDate: string;
  sigmaSaaSStartDate: string;
  sigmaRevenueDesignation: string;
  sigmaLtvChurnDriver: string;
  sigmaChurnFloor: number;
  sigmaCacPercentageSm: number;
}

export const settingsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query<Settings, void>({
      query: () => ({
        url: 'general_settings',
      }),
    }),
    updateSettings: builder.mutation<void, Partial<Settings>>({
      query: (settings) => {
        return {
          url: `general_settings`,
          method: 'PATCH',
          body: settings,
        };
      },
      invalidatesTags: (res, err) => !err ? [
        ...CommonMetricTags,
      ] : [],
      onQueryStarted(newSettings, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          settingsApi.util.updateQueryData('getSettings', undefined, (draftSettings: Settings) => {
            return {
              ...draftSettings,
              ...newSettings,
            };
          }),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useUpdateSettingsMutation,
  usePrefetch: usePrefetchSettings,
} = settingsApi;
