import styled, { css } from "styled-components";
import { theme } from "theme/theme";
import { ANIMATION_DURATION } from "utils/constants";

export const StyledIntegrationsItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.colors.borderColorDark};
  font-size: 14px;
  line-height: 21px;
  margin-top: 24px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  span {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }

  & + & {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;
  }

  &:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  ins {
    font-size: 10px;
    letter-spacing: 1px;
    line-height: 15px;
    font-weight: 500;
    background-color: ${theme.colors.sidebarBorder};
    color: ${theme.colors.white};
    padding: 4px 8px;
    margin: 0 8px;
    border-radius: 4px;
    text-decoration: none;
    vertical-align: text-top;
  }
`;

export const StyledConnected = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row !important;
  gap: 8px;
  font-size: 12px;
  background-color: ${theme.colors.success};
  color: ${theme.colors.white};
  padding: 4px 8px;
  margin: 0 0 0 8px;
  border-radius: 4px;
`;

interface StyledIntegrationsTriggerProps {
  disabled?: boolean;
}

export const StyledIntegrationsTrigger = styled.div<StyledIntegrationsTriggerProps>`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 12px 24px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: all ${ANIMATION_DURATION}ms ease;
  border-radius: 8px;
  
  ${props => props.disabled && css`
    background-color: ${theme.colors.mainBackground};
    color: ${theme.colors.textDisabled};
  `}

  &:hover {
    background-color: ${theme.colors.mainBackground};
  }

  div {
    display: flex;
    flex-direction: column;
  }
`;

interface StyledChildrenContainerProps {
  isOpen?: boolean;
}

export const StyledChildrenContainer = styled.div<StyledChildrenContainerProps>`
  opacity: ${props => props.isOpen ? 1 : 0};
  max-height: ${props => props.isOpen ? '400px' : 0};
  transition: all ${props => props.isOpen ? '0.3s' : '0.15s'} ease-in-out;
  overflow: hidden;
  
  & > div {
    padding: 24px;
  }
`;

export const StyledPushRight = styled.div`
  margin-left: auto;
`;
