import styled from "styled-components";
import { theme } from "theme/theme";
import { Box } from "components/common/Box";

export const StyledHotglueContainer = styled(Box)`
  .hg-flows-container {
    gap: 0;
    width: auto;
    display: inline-block;
  }

  .hg-flows-container .hg-flow-title {
    display: none;
  }

  .hg-flows-container > div {
    gap: 0;
  }

  .hg-flows-container > div > hr {
    display: none;
  }

  .hg-flows-container .hg-flow-subtitle {
    display: none;
  }

  .hg-flows-container .hg-connector-card {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    line-height: 21px;
    margin-top: 0;
    border: none;
    gap: 0;
    padding: 0;
  }

  .hg-connector-card .hg-connector-name {
    display: none;
  }

  .hg-connector-card .hg-connector-logo {
    display: none;
  }

  .hg-connector-card .hg-connector-linked {
    display: none;
  }

  .hg-connector-card .hg-connector-settings {
    width: 32px;
    height: 32px;
    color: ${theme.colors.primary};
    svg {
      font-size: 24px;
    }
  }  
`;