import { api } from "./api";
import { DraftsTags } from "./api-tags";

export const draftsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDraft: builder.query<any, string>({
      query: (type) => ({
        url: `drafts/${type}`,
      }),
      providesTags: [DraftsTags.Draft],
    }),
    putDraft: builder.mutation<void, { type: string, data: any }>({
      query: ({ type, data }) => ({
        url: `drafts/${type}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (res, err, args) => {
        return args.type === 'metrics_summary'
          ? [DraftsTags.Draft]
          : [];
      },
    }),
  }),
});

export const {
  useGetDraftQuery,
  usePutDraftMutation,
} = draftsApi;
