import {
  useEffect,
  useRef,
  useState,
} from "react";
// @ts-ignore
import markerSDK from '@marker.io/browser';
import { useGetAuthMeQuery } from "store/services/auth";

export const useMarkerIO = (destination: string) => {
  const widget = useRef<any>();
  const [isLoaded, setIsLoaded] = useState(false);
  const { data: user, isLoading } = useGetAuthMeQuery();

  const loadMarkerIo = async () => {
    widget.current = await markerSDK.loadWidget({
      destination,
      customData: {},
    });
    setIsLoaded(true);
  };

  const setCustomMarkerData = (customData: {}) => {
    if (isLoaded && !isLoading) {
      const company = user?.companies.find((comp) => comp.id === user.currentCompanyId);

      widget.current.setCustomData({
        userName: `${user?.firstName} ${user?.lastName}`,
        userEmail: user?.email,
        companyId: company?.id,
        companyName: company?.name,
        companyType: company?.companyRevenueType,
        ...customData,
      });
    }
  };

  useEffect(() => {
    if (isLoaded && !isLoading) {
      const company = user?.companies.find((comp) => comp.id === user.currentCompanyId);

      widget.current.setCustomData({
        userName: `${user?.firstName} ${user?.lastName}`,
        userEmail: user?.email,
        companyId: company?.id,
        companyName: company?.name,
        companyType: company?.companyRevenueType,
      });
    }
  }, [user, isLoaded, isLoading]);

  useEffect(() => {
    if (destination) {
      loadMarkerIo();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [
    setCustomMarkerData,
  ];
};