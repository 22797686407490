import { Tags } from "./tags";

export interface Product {
  id: string;
  name: string;
  description: string;
  revenueType: RevenueType;
  tags: Tags;
  crmId: string;
  deleted: boolean;
}

export interface ProductUpdatePayload {
  id?: string;
  name: string;
  description: string;
  tagIds: string[];
  crmId: string | null;
}

export interface ProductAddPayload extends ProductUpdatePayload {
  revenueType: RevenueType;
}

export interface ProductsBulkEditPayload {
  productsIds: string[];
  description: string | null;
  tagIds: string[] | null;
  crmId: string | null;
}

export interface ProductFormFields {
  id: string;
  name: string;
  description: string;
  revenueType: Array<{
    label: RevenueType,
    value: RevenueType,
  }>;
  tags:Tags;
  crmId: string;
}

export enum RevenueType {
  RECURRING = 'Recurring',
  NON_RECURRING = 'Nonrecurring',
  USAGE_BASED = 'Usage-based',
}

export type Products = Product[];
