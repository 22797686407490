import styled, { css } from "styled-components";
import { theme } from "theme/theme";

const StyledLoadingBar = styled.div<{ isWithinTable?: boolean }>`
  position: ${props => props.isWithinTable ? 'absolute' : 'relative'};
  ${props => props.isWithinTable && css`
    top: 1px;
    left: 0;
    right: 0;
  `};
  display: block;
  height: ${props => props.isWithinTable ? '3px' : '4px'};
  display: block;
  width: 100%;
  background-color: ${theme.colors.primaryBleach};
  border-radius: 2px;
  margin: 0;
  overflow: hidden;
  box-shadow: 0px 0px 15px 10px rgba(255, 255, 255, 1);
`;

const Bar1 = styled.div`
  @keyframes slidingBar1 {
    0% {
      left: -35%;
      right: 100%;
    }
    
    60% {
      left: 100%;
      right: -90%;
    }
    
    100% {
      left: 100%;
      right: -90%;
    }
  }

  position: absolute;
  background-color: ${theme.colors.primary};
  top: 0;
  left: 0;
  bottom: 0;
  transition: all 840ms cubic-bezier(0.650, 0.815, 0.735, 0.395);
  animation: slidingBar1 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
`;

const Bar2 = styled.div`
  @keyframes slidingBar2 {
    0% {
      left: -200%;
      right: 100%;
    }
    
    60% {
      left: 107%;
      right: -8%;
    }
    
    100% {
      left: 107%;
      right: -8%;
    }
  }

  position: absolute;
  background-color: ${theme.colors.primaryBleach};
  top: 0;
  left: 0;
  bottom: 0;
  transition: all 840ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  animation: slidingBar2 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite;
`;

const BarProgress = styled.div`
  position: absolute;
  background-color: ${theme.colors.primary};
  top: 0;
  left: 0;
  bottom: 0;
  transition: all 0.5s ease;
`;

interface Props {
  isWithinTable?: boolean;
  progress?: number;
}

export const LoadingBar = ({ isWithinTable, progress }: Props) => (
  <StyledLoadingBar isWithinTable={isWithinTable}>
    {progress !== undefined
      ? (
          <BarProgress style={{ width: `${Math.round(progress)}%` }} />
        )
      : (
          <>
            <Bar1 />
            <Bar2 />
          </>
        )}
  </StyledLoadingBar>
);
