import { ErrorDisplay } from "./ErrorDisplay";
import { Label } from "./Label";
import {
  FocusEventHandler,
  forwardRef,
} from "react";
import {
  StyledContainer,
  StyledInputWrapper,
  StyledInput,
  StyledTextarea,
  LoadingIcon,
  Suffix,
} from "./styled";

interface Props {
  name: string;
  isRequired?: boolean;
  error?: string;
  value?: string;
  labelText: string
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  icon?: React.ReactElement;
  isOptional?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onCustomChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  tooltip?: string;
  dataCy?: string;
  multiline?: boolean;
  isHidden?: boolean;
  condensed?: boolean;
  isLoading?: boolean;
  type?: React.HTMLInputTypeAttribute;
  max?: string | number;
  min?: string | number;
  suffix?: string;
  height?: number;
  autoFocus?: boolean;
  autoSelect?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const InputBasic = forwardRef(({
  name,
  labelText,
  error,
  isRequired,
  value,
  placeholder,
  isOptional,
  disabled,
  readonly,
  icon,
  onChange,
  onCustomChange,
  onBlur,
  tooltip,
  dataCy,
  multiline,
  isHidden,
  condensed,
  isLoading,
  type,
  max,
  min,
  suffix,
  height,
  autoFocus,
  autoSelect,
  onKeyDown,
}: Props,
  ref: any) => {
  const withError = Boolean(error);

  return (
    <StyledContainer isHidden={isHidden}>
      <Label
        isRequired={isRequired}
        isOptional={isOptional}
        labelText={labelText}
        tooltip={tooltip}
        name={name}
      />
      <StyledInputWrapper
        disabled={disabled}
        withError={withError}
      >
        {multiline
          ? <StyledTextarea
            name={name}
            withError={withError}
            ref={ref}
            value={value}
            onChange={(e) => {
              onChange && onChange(e);
              onCustomChange && onCustomChange(e);
            }}
            {...(onBlur && { onBlur })}
            placeholder={placeholder}
            data-cy={dataCy}
            readOnly={readonly}
            height={height}
            onKeyDown={onKeyDown}
          />
          : <StyledInput
            name={name}
            withError={withError}
            ref={ref}
            value={value}
            onChange={(e) => {
              onChange && onChange(e);
              onCustomChange && onCustomChange(e);
            }}
            {...(onBlur && { onBlur })}
            placeholder={placeholder}
            data-cy={dataCy}
            readOnly={readonly}
            condensed={condensed}
            type={type}
            max={max}
            min={min}
            autoFocus={autoFocus}
            onFocus={(e) => {
              if (autoSelect) {
                e.target.select();
              }
            }}
            onKeyDown={onKeyDown}
          />
        }
        {suffix && (
          <Suffix>
            {suffix}
          </Suffix>
        )}
        {icon}
        {isLoading && <LoadingIcon />}
      </StyledInputWrapper>
      {error && <ErrorDisplay error={error} />}
    </StyledContainer>
  );
});
