import React, {
  CSSProperties,
  useMemo,
  useRef,
  useState,
} from 'react';
import { StyledQuickTagging } from './styled';
import { Dropdown } from 'components/common/Dropdown';
import { useClickOutside } from 'hooks/useClickOutside';
import {
  useGetAllMetricsQuery,
  usePostBulkTagsMutation,
} from 'store/services/metrics';
import { Button } from 'components/common/Button';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import { Tooltip } from 'components/common/Tooltip';
import {
  BulkTagsRequest,
  Metrics,
} from 'interfaces/metrics';
import { toastSuccess } from 'utils/toast';
import { CheckboxInput } from 'components/common/CheckboxInput';

interface Props {
  selectedRows: Metrics;
  popperRef: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  style: CSSProperties;
  attributes: {
    [key: string]: {
      [key: string]: string;
    } | undefined;
  };
  onClose: () => void;
}

export const QuickTagging = ({
  selectedRows,
  popperRef,
  style,
  attributes,
  onClose,
}: Props) => {
  const [shouldOverwrite, setShouldOverwrite] = useState(false);
  const [selectedTags, setSelectedTags] = useState<any[]>(selectedRows.length === 1
    ? selectedRows[0].tags?.map((tag) => ({ name: tag })) || []
    : []);
  const formRef = useRef<HTMLFormElement>(null);
  useClickOutside(formRef, onClose);

  const { data: metrics } = useGetAllMetricsQuery();
  const [updateTags, { isLoading }] = usePostBulkTagsMutation();

  const options = useMemo(() => [...new Set(metrics?.filter((metric) => metric.tags)
    .flatMap((metric) => metric.tags))]
    .map((metric) => ({
      name: metric,
    })), [metrics]);

  const handleApply = () => {
    const payload: BulkTagsRequest = {
      metricsIds: selectedRows.map((metric) => metric.id),
      tags: selectedTags.map((tag) => tag.name),
      overwrite: shouldOverwrite || selectedRows.length === 1 || selectedTags.length === 0,
    };

    updateTags(payload)
      .unwrap()
      .then(() => {
        toastSuccess('Tags updated successfully.');
        onClose();
      });
  };

  return (
    <form
      ref={formRef}
      onSubmit={(ev) => {
        ev.preventDefault();
        handleApply();
      }}
    >
      <StyledQuickTagging
        ref={popperRef}
        style={style}
        {...attributes}
      >
        <Dropdown
          name="tag"
          labelText="Select tags"
          tooltip="Start typing to add new tag."
          values={selectedTags}
          labelField="name"
          valueField="name"
          options={options}
          onChange={(values) => setSelectedTags(values.map(({ name }) => ({ name: name.trim() })))}
          create
          multi
        />
        <CheckboxInput
          name="shouldOverwrite"
          labelText="Overwrite existing tags"
          tooltip="When tagging multiple metrics, you can either add new tags or overwrite all previously existing."
          checkboxLabel="Overwrite existing tags"
          onChange={(e) => setShouldOverwrite(e.target.checked)}
          defaultValue={shouldOverwrite}
          hidden={selectedRows.length === 1}
          small
        />
        <ButtonsContainer>
          <Tooltip title="Removes tags from all selected metrics.">
            <Button
              type="button"
              variant="borderless"
              color="error"
              hidden={!!selectedTags.length || isLoading || selectedRows.length === 1}
              onClick={handleApply}
            >
              Clear All Tags
            </Button>
          </Tooltip>
          <Spacer />
          <Button
            type="button"
            variant="borderless"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="button"
            disabled={(selectedRows.length > 1 && !selectedTags.length) || isLoading}
            isLoading={isLoading}
            onClick={handleApply}
          >
            Apply & Save
          </Button>
        </ButtonsContainer>
      </StyledQuickTagging>
    </form>
  );
};
