import React from 'react';
import { SectionLabel } from 'components/common/SectionLabel';
import { Section } from 'components/common/Section';
import { SettingsType } from 'interfaces/settings';
import { useGetSettingsQuery } from 'store/services/settings';
import { SettingsItem } from '../common/SettingsItem';
import { LeftPane } from "../../../components/Layout";

export const GeneralSettings = () => {
  const { data: settings, isLoading } = useGetSettingsQuery();

  return (
    <LeftPane contentWidth={1200}>
      <SectionLabel data-cy="st-header">
        General Settings
      </SectionLabel>
      <SectionLabel secondary>
        Financial Settings
      </SectionLabel>
      <Section>
        <SettingsItem
          name="fiscalYearEnd"
          title="Fiscal year-end"
          description="Set the fiscal year end date for the company"
          value={settings?.['fiscalYearEnd'].toString()}
          type={SettingsType.MONTH}
          isLoading={isLoading}
        />
        <SettingsItem
          name="glActualStartDate"
          title="Actual start date"
          description="Set the earliest data for which financial reporting is available"
          value={settings?.['glActualStartDate']}
          type={SettingsType.DATE}
          isLoading={isLoading}
        />
        <SettingsItem
          name="glActualEndDate"
          title="Actual end date"
          description="Select the most recent month that has been closed"
          value={settings?.['glActualEndDate']}
          type={SettingsType.DATE}
          shouldSetMonthEndDate
          isLoading={isLoading}
        />
      </Section>
      <SectionLabel secondary>
        Data Settings
      </SectionLabel>
      <Section>
        <SettingsItem
          name="saasActualStartDate"
          title="Actual start date"
          description="Set the earliest date for which metrics are available in reports and spreadsheet connectors"
          value={settings?.['saasActualStartDate']}
          type={SettingsType.DATE}
          isLoading={isLoading}
        />
        <SettingsItem
          name="saasActualEndDate"
          title="Actual end date"
          description="Set the latest date for which metrics are available in reports and spreadsheet connectors"
          value={settings?.['saasActualEndDate']}
          type={SettingsType.DATE}
          shouldSetMonthEndDate
          isLoading={isLoading}
        />
        <SettingsItem
          name="sigmaSaaSStartDate"
          title="SaaS Metric Start Date"
          description="Adjusts start date for calculating MRR/ARR"
          value={settings?.['sigmaSaaSStartDate']}
          type={SettingsType.OPTION}
          options={['Service Start Date', 'Committed Booking Date']}
          isLoading={isLoading}
        />
        <SettingsItem
          name="sigmaRevenueDesignation"
          title="New Vs. Expansion"
          description="Determination of new vs expansion designation of revenue"
          value={settings?.['sigmaRevenueDesignation']}
          type={SettingsType.OPTION}
          options={['Customer Level', 'Selected Contract Level']}
          isLoading={isLoading}
        />
        <SettingsItem
          name="sigmaLtvChurnDriver"
          title="LTV Churn Driver"
          description="Toggle between using Customer Churn VS Revenue Churn"
          value={settings?.['sigmaLtvChurnDriver']}
          type={SettingsType.OPTION}
          options={['Logo Churn', 'Revenue Churn']}
          isLoading={isLoading}
        />
        <SettingsItem
          name="sigmaChurnFloor"
          title="Monthly Churn Floor Assumption"
          description={`Creates a floor for "Adj Monthly Churn Assumption". This is useful for companies that don't have churn every month, but want to be able to calculate LTV and other metrics that rely on Churn %`}
          value={settings?.['sigmaChurnFloor'].toString()}
          type={SettingsType.NUMBER_WITH_DECIMALS}
          isLoading={isLoading}
        />
        <SettingsItem
          name="sigmaCacPercentageSm"
          title="Adjusted CAC as a % of Total Sales & Marketing Costs"
          description="Create an adjusted CAC metric that is based on a % of Total Sales & Marketing Costs. This can be useful for estimating Sales & Marketing Costs for new customers."
          value={settings?.['sigmaCacPercentageSm'].toString()}
          type={SettingsType.NUMBER}
          isLoading={isLoading}
        />
      </Section>
    </LeftPane>
  );
};
