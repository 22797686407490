import styled, { css } from "styled-components";
import { Button } from "components/common/Button";
import { theme } from "theme/theme";

export const StyledContextMenu = styled.div`
  display: none;
  flex-direction: column;
  background-color: ${theme.colors.mainBackground};
  color: ${theme.colors.textDark};
  box-shadow: ${theme.boxShadow};
  min-width: 260px;
  z-index: 3;

  &[data-show] {
    display: flex;
  }
`;

export const StyledMenuItem = styled(Button)`
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  border-radius: 0;
  justify-content: flex-start;
  padding: 12px 20px;
  color: ${props => props.active ? theme.colors.primary : theme.colors.textDark};
  background-color: ${theme.colors.transparent};
  border: none;
  height: auto;

  ${props => props.active && css`
    border-bottom: none !important;
  `}
  
  &:hover {
    background-color: ${theme.colors.secondaryBackground};
    box-shadow: none;
  }
`;

export const StyledMenuSpacer = styled.div`
  margin: 6px 20px;
  height: 1px;
  background: ${theme.colors.borderColorDark};
`;
