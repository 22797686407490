import React from 'react';
import { useDeleteContractsMutation } from 'store/services/contracts';
import { DeleteItems } from 'components/DeleteItems';
import { Contracts } from 'interfaces/contracts';

interface Props {
  onClose: () => void;
  onGoBack: () => void;
  onSuccess?: () => void;
  selectedRows: Contracts;
}

export const DeleteContracts = ({
  onClose,
  onGoBack,
  onSuccess,
  selectedRows,
}: Props) => {
  const [deleteContracts, { isLoading }] = useDeleteContractsMutation();

  const customDescription = selectedRows.length > 1
    ? <p>Are you sure you would like to delete these contracts? Deleting these contracts will also delete all contract lines associated with them.</p>
    : <p>Are you sure you would like to delete this contract? Deleting this contract will also delete all contract lines associated with this contract.</p>;

  const handleDeleteContracts = () => {
    const ids = selectedRows.map((contract) => contract.id);
    deleteContracts(ids)
      .unwrap()
      .then(() => {
        onSuccess && onSuccess();
        onClose();
      });
  };

  return (
    <DeleteItems
      selectedRows={selectedRows}
      onClose={onClose}
      onGoBack={onGoBack}
      isLoading={isLoading}
      handleDelete={handleDeleteContracts}
      item="Contract"
      customDescription={customDescription}
    />
  );
};
