import styled from "styled-components";
import { theme } from "theme/theme";

export const StyledAgGridDropdown = styled.div`
  font-family: 'Montserrat';
  
  & > div {
    padding: 0;
    background: ${theme.colors.mainBackground};
    
    .react-dropdown-select {
      border-radius: 0;
      font-size: 14px;

      :focus,
      :focus-visible,
      :focus-within,
      :hover {
        &::before {
          border-radius: 0;
          border-width: 0;
        }
      }
    }
  }
`;
