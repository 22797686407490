import { CheckCircleIcon } from "assets/icons";
import { Button } from "components/common/Button";
import { CompanyRevenueType } from "interfaces/company";
import { useNavigate } from "react-router";
import {
  stepsContracts,
  stepsSubscriptions,
} from "./stepsDefinition";
import { StyledOnboardingStep } from "./styled";

interface Props {
  index: number;
  name: string;
  completed: boolean;
  companyRevenueType?: CompanyRevenueType;
}

export const OnboardingStep = ({
  index,
  name,
  completed,
  companyRevenueType,
}: Props) => {
  const navigate = useNavigate();
  const handleClick = (destination: string) => navigate(destination);
  const steps = companyRevenueType === CompanyRevenueType.CONTRACTS
    ? stepsContracts
    : stepsSubscriptions;

  const step = steps.find((step) => step.name === name);

  return step
    ? (
        <StyledOnboardingStep>
          <div>
            {index + 1}.
          </div>
          <div>
            {step.label}
            <p>{step?.description}</p>
          </div>
          <div>
            {completed
              ? <CheckCircleIcon />
              : <Button
                  onClick={() => handleClick(step.navigateTo)}
                  variant="borderless"
                  size="large"
                >
                  Start
                </Button>
            }
          </div>
        </StyledOnboardingStep>
      )
    : null;
};
