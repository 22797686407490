import React, {
  CSSProperties,
  useRef,
} from 'react';
import { useClickOutside } from 'hooks/useClickOutside';
import {
  StyledContainer,
  StyledLeftContainer,
  StyledOption,
  StyledWrapper,
} from './styled';
import { useGetReportVersionsQuery } from 'store/services/reports';

interface Props {
  popperRef: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  style: CSSProperties;
  attributes: {
    [key: string]: {
      [key: string]: string;
    } | undefined;
  };
  onClose: () => void;
  onApply: (versionId: string | null, col: string) => void;
  col: string | null;
}

export const ChangeVersion = ({
  popperRef,
  attributes,
  style,
  onClose,
  onApply,
  col,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside(containerRef, onClose);
  const { data: versions } = useGetReportVersionsQuery();

  return (
    <div ref={containerRef}>
      <StyledWrapper
        ref={popperRef}
        style={style}
        {...attributes}
      >
        <StyledContainer>
          <StyledLeftContainer>
            {versions?.map(({ id, name }) => (
              <StyledOption
                key={id}
                onClick={() => {
                  onApply(id, col!);
                  onClose();
                }}
              >
                {name}
              </StyledOption>
            ))}
          </StyledLeftContainer>
        </StyledContainer>
      </StyledWrapper>
    </div>
  );
};
