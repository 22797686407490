import React, {
  useState,
  useMemo,
} from 'react';
import {
  CompanyFormFields,
  CompanyRevenueType,
} from 'interfaces/company';
import { FirstStepForm } from './FirstStepForm';
import { SecondStepForm } from './SecondStepForm';
import { ThirdStepForm } from './ThirdStepForm';
import {
  StyledContainer,
  StyledSteps,
  StyledStep,
  StyledStepNumber,
  StyledHeading,
  StyledSubHeading,
  StyledComponentWrapper,
  StyledChevron,
} from './styled';
import { LeftPane } from 'components/Layout';

export const AddCompany = () => {
  const [companyInfo, setCompanyInfo] = useState<CompanyFormFields>({
    name: '',
    currencyCode: [{
      name: 'US Dollars',
      code: 'USD',
      symbol: '$',
    }],
    domain: '',
    industry: [],
    businessType: [],
    businessStage: [],
    companyRevenueType: '' as CompanyRevenueType,
    useRevRec: false,
    populateSampleData: true,
  });
  const [currentStep, setCurrentStep] = useState(1);

  const stepsInformation = [
    {
      step: 1,
      stepName: 'Create a Company',
      headingText: 'Name Your Company',
      subHeadingText: 'A company will be the home for all your users and billing.',
      component: (
        <FirstStepForm
          setStep={setCurrentStep}
          setCompanyInfo={setCompanyInfo}
          companyInfo={companyInfo}
        />
      ),
      isActive: !!companyInfo.name || currentStep === 1,
    },
    {
      step: 2,
      stepName: 'Company Information',
      headingText: 'Add Company Information',
      subHeadingText: 'Add some information about your company, or finish it later in your settings.',
      component: (
        <SecondStepForm
          setStep={setCurrentStep}
          setCompanyInfo={setCompanyInfo}
          companyInfo={companyInfo}
        />
      ),
      isActive: !!companyInfo.name || currentStep === 2,
    },
    {
      step: 3,
      stepName: 'Revenue Model',
      headingText: 'Revenue Model',
      subHeadingText: `Select how you would like to submit sales information into our system.`,
      component: (
        <ThirdStepForm
          setStep={setCurrentStep}
          setCompanyInfo={setCompanyInfo}
          companyInfo={companyInfo}
        />
      ),
      isActive: !!companyInfo.domain || currentStep === 3,
    },
  ];

  const {
    headingText,
    subHeadingText,
    component,
  } = useMemo(() => stepsInformation.find((stepInfo) => stepInfo.step === currentStep)!, [currentStep]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LeftPane contentWidth={1200}>
      <StyledContainer>
        <StyledSteps>
          {stepsInformation.map(({ step, stepName, isActive }) => (
            <StyledStep
              key={stepName}
              onClick={() => setCurrentStep(step)}
              isActive={isActive}
            >
              <StyledChevron />
              <StyledStepNumber>
                <p>{step}</p>
              </StyledStepNumber>
              <p>{stepName}</p>
            </StyledStep>
          ))}
        </StyledSteps>
        <StyledHeading>{headingText}</StyledHeading>
        <StyledSubHeading>{subHeadingText}</StyledSubHeading>
        <StyledComponentWrapper>
          {component}
        </StyledComponentWrapper>
      </StyledContainer>
    </LeftPane>
  );
};
