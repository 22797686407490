import React from 'react';
import {
  CheckCircleIcon,
  ErrorIcon,
} from "assets/icons";
import { Status } from "components/common/Status";
import { StyledLabel } from "./styled";
import { Tooltip } from 'components/common/Tooltip';

interface Props {
  name: string;
  entityName: string;
  invalidEntities: number;
}

export const TabLabel = ({
  name,
  entityName,
  invalidEntities,
}: Props) => {
  return <StyledLabel>
    {name}
    <Tooltip title={invalidEntities ? `${invalidEntities} ${entityName}${invalidEntities > 1 ? 's' : ''} needs attention` : ''}>
      <Status color={invalidEntities ? 'warning' : 'success'}>
        {invalidEntities
          ? <><ErrorIcon /><span>({invalidEntities})</span></>
          : <CheckCircleIcon />
        }
      </Status>
    </Tooltip>
  </StyledLabel>;
};
