import React from 'react';
import {
  StyledPopupContainer,
  StyledCaption,
  StyledCategory,
  StyledList,
  StyledName,
  StyledOrigin,
  StyledPopupWrapper,
} from './styled';
import {
  FormulaElementType,
  FormulaOption,
} from 'interfaces/formulaEditor';
import { Button } from 'components/common/Button';
import { Tooltip } from 'components/common/Tooltip';
import { useNavigate } from 'react-router';

interface Props {
  option?: FormulaOption;
  isFormulaSaved?: boolean;
}

export const FormulaElementPopup = ({ option, isFormulaSaved }: Props) => {
  const navigate = useNavigate();
  if (!option) return null;

  return (
    <StyledPopupWrapper>
      <StyledPopupContainer>
        <StyledCategory>
          <span>
            {option.category} &nbsp;/&nbsp; {option.type}
          </span>
          <Tooltip title={!isFormulaSaved ? 'Save Formula first' : ''}>
            <Button
              size="large"
              variant="borderless"
              disabled={!isFormulaSaved}
              onClick={(event) => {
                event.stopPropagation();
                if (option.origin === FormulaElementType.METRIC) {
                  navigate(`/metrics/${option.id}`);
                }
                if (option.origin === FormulaElementType.ACCOUNT) {
                  navigate(`/accounts/account-mapping/${option.category === 'Balance Sheet' ? 'balance_sheet' : 'profit_and_loss'}`);
                }
              }}
            >
              View {option.origin.toLowerCase()}
            </Button>
          </Tooltip>
        </StyledCategory>
        <StyledName>
          {option.name}
        </StyledName>
        <StyledOrigin type={option.origin}>
          {option.origin === FormulaElementType.ACCOUNT && 'Facta Account'}
          {option.origin === FormulaElementType.METRIC && 'Facta Metric'}
        </StyledOrigin>
        {option.origin === FormulaElementType.ACCOUNT && (
          <>
            <StyledCaption>
              GL Accounts
            </StyledCaption>
            <StyledList>
              {!option.glAccounts?.length && <li className="empty">None</li>}
              {option.glAccounts?.map((glAcc) => (
                <li key={`li_${glAcc.id}`}>
                  {glAcc.displayName}
                  <span>
                    {glAcc.type}
                  </span>
                </li>
              ))}
            </StyledList>
          </>
        )}
        {option.origin === FormulaElementType.METRIC && (
          <>
            <StyledCaption>
              Description
            </StyledCaption>
            {option.description || 'N/A'}
          </>
        )}
      </StyledPopupContainer>
    </StyledPopupWrapper>
  );
};
