import styled from 'styled-components';
import { theme } from "theme/theme";
import { SectionLabel } from 'components/common/SectionLabel';

export const StyledList = styled.ol`
  list-style: decimal;
  padding-left: 23px;
`;

export const StyledListItem = styled.li`
  padding-left: 4px;
  display: list-item;
  list-style-position: outside;
  color: ${theme.colors.textDark};
  padding-top: 24px;
`;

export const StyledListText = styled.p`
  font-size: 16px;
  line-height: 24px;
`;

export const StyledSectionLabel = styled(SectionLabel)`
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: flex-start;

  & > p {
    font-size: 12px;
    line-height: 18px;
    color: ${theme.colors.textDark};
  }
`;

export const StyledCheckboxContainer = styled.div`
  padding-top: 20px;
  font-size: 12px;
  line-height: 18px;
  color: ${theme.colors.textDark};
`;
