import { Button } from "components/common/Button";
import styled from "styled-components";
import { theme } from "theme/theme";

export const StyledDeleteContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const VersionButton = styled(Button)`
  border: 1px solid ${theme.colors.borderColorDark} !important;
  font-weight: 500;
  font-size: 12px;

  &:hover {
    background-color: transparent;
  }
`;

export const PeriodButton = styled(VersionButton)`
  margin-right: 8px;
  height: 28px;
`;

export const StyledButtonInnerText = styled.span`
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
`;

export const StyledVersion = styled.ins`
  font-size: 12px;
  font-weight: 500;
  color: ${theme.colors.textDisabled};
  text-decoration: none;
  margin-left: 5px;
  height: 28px;
`;
