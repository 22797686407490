import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { theme } from 'theme/theme';

export const StyledContainer = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
`;

export const StyledMenu = styled.div`
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  width: 200px;
  font-size: 14px;
  color: ${theme.colors.textDark};
  background-color: ${theme.colors.secondaryBackground};
`;

export const StyledMenuHeader = styled.p`
  font-weight: 700;
  padding: 12px 4px;
`;

type StyledLinkProps = {
  $isHighlighted: boolean;
}

export const StyledLink = styled(Link)<StyledLinkProps>`
  font-size: 14px;
  font-weight: 500;
  color: ${theme.colors.textDark} !important;
  padding: 12px;
  border-radius: 4px;

  ${props => props.$isHighlighted && css`
    font-weight: 700;
    color: ${theme.colors.primary} !important;
    background-color: ${theme.colors.primaryBleach}; 
  `};

  &:hover {
    font-weight: 700;
    color: ${theme.colors.primary} !important;
    background-color: ${theme.colors.primaryBleach};
  }
`;