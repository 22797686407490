import React from 'react';
import styled from 'styled-components';
import { LeftPane } from 'components/Layout';
import { SweatingLlamaImage } from 'assets/images';

export const ErrorPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 24px;
`;

interface Props {
  title?: string;
  description?: string;
}

export const PageNotFound = ({
  title,
  description,
}: Props) => (
  <LeftPane>
    <ErrorPageContainer>
      <SweatingLlamaImage />
      <h2>{title || '404 Error'}</h2>
      <p>{description || 'Page not found'}</p>
    </ErrorPageContainer>
  </LeftPane>
);
