import React, { useMemo } from 'react';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { CloseIcon } from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { SectionLabel } from 'components/common/SectionLabel';
import { Button } from 'components/common/Button';
import { InputBasic } from 'components/common/Input';
import { Dropdown } from 'components/common/Dropdown';
import {
  BusinessStageOptions,
  BusinessTypeOptions,
  CompanyRevenueTypeOptions,
  CompanyStatusOptions,
  IndustryTypeOptions,
} from 'utils/constants';
import {
  StyledButtonContainer,
  StyledFormContainer,
} from 'components/common/Forms/styled';
import { toastSuccess } from 'utils/toast';
import { actionBlocker } from 'utils/actionBlocker';
import {
  BusinessStageType,
  BusinessType,
  Company,
  CompanyAddPayload,
  CompanyRevenueType,
  IndustryType,
} from 'interfaces/company';
import { CheckboxInput } from 'components/common/CheckboxInput';
import { RadioInput } from 'components/common/RadioInput';
import {
  useAddCompanyMutation,
  useGetCurrenciesQuery,
} from 'store/services/companies';
import { CompanyStatus } from 'interfaces/company';
import { useGetAuthMeQuery } from 'store/services/auth';

interface Props {
  onClose: () => void;
  onSuccess?: (result: Company) => void;
}

export const AddCompanyPane = ({
  onClose,
  onSuccess,
}: Props) => {
  const [addCompany, { isLoading }] = useAddCompanyMutation();
  const { data: me } = useGetAuthMeQuery();
  const { data: currencies, isFetching: isLoadingCurrencies } = useGetCurrenciesQuery();

  const GroupOptions = useMemo(() => {
    return [...new Set(me?.companies.map((company) => company.group))]
      .filter((value) => value)
      .map((value) => ({ value } as { value: string }));
  }, [me?.companies]);

  const TagOptions = useMemo(() => {
    return [...new Set(me?.companies.map((company) => company.tag))]
      .filter((value) => value)
      .map((value) => ({ value } as { value: string }));
  }, [me?.companies]);

  const schema = yup.object({
    name: yup.string()
      .required('Company name is required.'),
    companyRevenueType: yup.object()
      .required('Company Revenue Type is required.'),
    companyStatus: yup.array()
      .min(1, 'Status is required.'),
    currencyCode: yup.array()
      .min(1, 'Currency is required.'),
  })
    .required();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm<{
    name: string;
    companyRevenueType: {
      name: string;
      value: CompanyRevenueType;
      description: string;
      annotation: string;
    };
    currencyCode: {
      name: string;
      code: string;
      symbol: string;
    }[];
    companyStatus: { value: CompanyStatus }[];
    group: { value: string }[];
    domain: string;
    industry: { value: IndustryType }[];
    businessType: { value: BusinessType }[];
    businessStage: { value: BusinessStageType }[];
    useRevRec: boolean;
    populateSampleData: boolean;
    tag: { value: string }[];
  }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      domain: '',
      companyStatus: [CompanyStatusOptions.at(0)],
      currencyCode: [{
        name: 'US Dollars',
        code: 'USD',
        symbol: '$',
      }],
      group: [],
      tag: [],
      companyRevenueType: CompanyRevenueTypeOptions.at(0),
      industry: [],
      businessType: [],
      businessStage: [],
      useRevRec: false,
      populateSampleData: true,
    },
  });

  const handleAddCompany = handleSubmit((data) => {
    const payload: CompanyAddPayload = {
      name: data.name,
      domain: data.domain,
      companyStatus: data.companyStatus?.at(0)?.value || null,
      currencyCode: data.currencyCode?.at(0)?.code || 'USD',
      group: data.group?.at(0)?.value || null,
      tag: data.tag?.at(0)?.value || null,
      companyRevenueType: data.companyRevenueType!.value,
      industry: data.industry?.at(0)?.value || null,
      businessType: data.businessType.at(0)?.value || null,
      businessStage: data.businessStage.at(0)?.value || null,
      useRevRec: data.useRevRec,
      populateSampleData: data.populateSampleData,
    };

    addCompany(payload)
      .unwrap()
      .then((result) => {
        toastSuccess('Company successfully created.');
        onSuccess && onSuccess(result);
        onClose();
      });
  });

  return (
    <>
      <header>
        <ButtonsContainer paddingBottom={16}>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={() => actionBlocker(onClose, isDirty)}
            pushRight
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <SectionLabel>
          <span>
            Add Company
          </span>
        </SectionLabel>
      </header>
      <main>
        <StyledFormContainer>
          <InputBasic
            isRequired
            labelText="Company Name"
            placeholder="Add company name"
            {...register('name')}
            onBlur={(e) => {
              const fieldValue = e.target.value;

              if (fieldValue) {
                setValue('name', fieldValue.trim(), { shouldValidate: true });
              }
            }}
            error={errors.name?.message}
          />
          <Controller
            name="companyStatus"
            control={control}
            render={({ field }) =>
              <Dropdown
                isRequired
                labelText="Status"
                options={CompanyStatusOptions}
                labelField="value"
                valueField="value"
                searchBy="value"
                placeholder="Select Status"
                values={getValues('companyStatus')}
                {...field}
                error={errors.companyStatus?.message}
              />
            }
          />
          <Controller
            name="currencyCode"
            control={control}
            render={({ field }) =>
              <Dropdown
                isRequired
                labelText="Currency"
                options={currencies || []}
                labelField="name"
                valueField="code"
                searchBy="name"
                placeholder="Currency"
                values={getValues('currencyCode')}
                {...field}
                loading={isLoadingCurrencies}
                error={errors.currencyCode?.message}
              />
            }
          />
          <InputBasic
            isOptional
            labelText="Domain"
            placeholder="www.facta.io"
            {...register('domain')}
            onBlur={(e) => {
              const fieldValue = e.target.value;

              if (fieldValue) {
                setValue('domain', fieldValue.trim(), { shouldValidate: true });
              }
            }}
            error={errors.domain?.message}
          />
          <Controller
            name="industry"
            control={control}
            render={({ field }) =>
              <Dropdown
                isOptional
                clearable
                labelText="Industry"
                options={IndustryTypeOptions}
                labelField="value"
                valueField="value"
                searchBy="value"
                placeholder="Select Industry"
                values={getValues('industry')}
                {...field}
                error={errors.industry?.message}
              />
            }
          />
          <Controller
            name="group"
            control={control}
            render={({ field }) =>
              <Dropdown
                isOptional
                clearable
                labelText="Group"
                options={GroupOptions}
                labelField="value"
                valueField="value"
                searchBy="value"
                placeholder="Select Group"
                values={getValues('group')}
                {...field}
                error={errors.group?.message}
                create
                tooltip='Start typing to create a new group. Press "Enter" to save it.'
              />
            }
          />
          <Controller
            name="businessType"
            control={control}
            render={({ field }) =>
              <Dropdown
                isOptional
                clearable
                labelText="Business Type"
                options={BusinessTypeOptions}
                labelField="value"
                valueField="value"
                searchBy="value"
                placeholder="Select Business Type"
                values={getValues('businessType')}
                {...field}
                error={errors.businessType?.message}
              />
            }
          />
          <Controller
            name="businessStage"
            control={control}
            render={({ field }) =>
              <Dropdown
                isOptional
                clearable
                labelText="Business Stage"
                options={BusinessStageOptions}
                labelField="value"
                valueField="value"
                searchBy="value"
                placeholder="Select Business Stage"
                values={getValues('businessStage')}
                {...field}
                error={errors.businessStage?.message}
              />
            }
          />
          <Controller
            name="tag"
            control={control}
            render={({ field }) =>
              <Dropdown
                isOptional
                clearable
                labelText="Tag"
                options={TagOptions}
                labelField="value"
                valueField="value"
                searchBy="value"
                placeholder="Select Tag"
                values={getValues('tag')}
                {...field}
                error={errors.tag?.message}
                create
                tooltip='Start typing to create a new tag. Press "Enter" to save it.'
              />
            }
          />
          <Controller
            name="companyRevenueType"
            control={control}
            render={({ field }) =>
              <RadioInput
                isRequired
                labelText="Company Revenue Type"
                valueField="value"
                labelField="name"
                defaultValue={getValues('companyRevenueType')}
                annotationField="annotation"
                descriptionField="description"
                options={CompanyRevenueTypeOptions}
                {...field}
                onChange={(value) => {
                  field.onChange(value);
                }}
                error={errors.companyRevenueType?.message}
              />
            }
          />
          <CheckboxInput
            labelText="Revenue Recognition Module"
            {...register('useRevRec')}
            error={errors.useRevRec?.message}
            tooltip="Add the revenue recognition module to help you see the difference between your SaaS and actual Revenue."
            checkboxLabel="Include Revenue Recognition Module"
            defaultValue={getValues().useRevRec}
          />
          <CheckboxInput
            labelText="Sample Data"
            {...register('populateSampleData')}
            error={errors.populateSampleData?.message}
            tooltip="Sample data can be removed later manually."
            checkboxLabel="Populate this company with sample data"
            defaultValue={getValues().populateSampleData}
          />
        </StyledFormContainer>
      </main>
      <footer>
        <StyledButtonContainer pushRight>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            CANCEL
          </Button>
          <Button
            type="submit"
            onClick={handleAddCompany}
            disabled={isLoading}
          >
            ADD COMPANY
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};
