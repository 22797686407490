import React from 'react';
import { ArrowRightIcon } from 'assets/icons';
import {
  StyledPlaceholderItem,
  StyledSummaryContainer,
  StyledSummaryItemDate,
  StyledSummaryItemLabel,
  StyledSummaryItemValue,
} from './styled';
import { Button } from 'components/common/Button';
import { useNavigate } from 'react-router';
import { summaryItemsMappingTable } from 'utils/home';

interface SummaryItem {
  name: string;
  value: string;
  date: string;
}

interface Props {
  items?: SummaryItem[];
  isLoading?: boolean;
}

export const SummaryItems = ({
  items,
  isLoading,
}: Props) => {
  const navigate = useNavigate();
  const renderLoadingPlaceholder = () => (
    <>
      <StyledPlaceholderItem />
      <StyledPlaceholderItem/>
      <StyledPlaceholderItem/>
    </>
  );

  const handleClick = (name: string) => {
    const link = summaryItemsMappingTable[name];

    if (link) {
      navigate(link);
    }
  };

  return (
    <StyledSummaryContainer>
      {isLoading && renderLoadingPlaceholder()}
      {!isLoading && items?.map(({ name, value, date }) => (
        <div key={`cs_${name}`}>
          <StyledSummaryItemLabel>
            {name}
            {!!summaryItemsMappingTable[name] && (
              <Button
                variant="icon"
                color="primary"
                onClick={() => handleClick(name)}
              >
                <ArrowRightIcon />
              </Button>
            )}
          </StyledSummaryItemLabel>
          <StyledSummaryItemValue>{value}</StyledSummaryItemValue>
          <StyledSummaryItemDate>{date}</StyledSummaryItemDate>
        </div>
      ))}
    </StyledSummaryContainer>
  );
};
