import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAppDispatch } from 'hooks/redux';
import { usePopper } from 'react-popper';
import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  EnvIndicator,
  HeaderLogoSection,
  HeaderSection,
  StyledHeader,
  StyledHeaderDropdown,
  StyledHeaderDropdownIns,
  StyledHeaderDropdownItem,
  // UnreadMessagesIndicator,
} from './styled';
import { Button } from 'components/common/Button';
import { FactaLogo } from 'assets/images';
import {
  HelpIcon,
  PlusIcon,
  ReportSettingsIcon,
} from 'assets/icons';
import { UserMenu } from './UserMenu';
import { Tooltip } from 'components/common/Tooltip';
import {
  useChangeCompanyMutation,
  useGetAuthMeQuery,
} from 'store/services/auth';
import { clearApiState } from 'store/services/api';
import { bulkImportActions } from 'store/slices/bulkImport';
// import { useFrontAppChat } from 'hooks/useFrontAppChat';
import { useBroadcast } from 'hooks/useBroadcast';
import { getCurrentCompany } from 'utils/currentCompany';
import { useNavigate } from 'react-router';
import { INSIGHTS_HELP_URL } from 'utils/constants';
import { SelectMethods } from 'react-dropdown-select';
import { currentEnv } from 'utils/featureSwitch';
import { UserRole } from 'interfaces/auth';

interface Props {
  omitHeaderAndSidebar?: boolean;
}

export const Header = React.memo(({ omitHeaderAndSidebar }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const { openChat, messages } = useFrontAppChat();
  const [changeCompany, { isLoading }] = useChangeCompanyMutation();
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false,
          altAxis: true,
          padding: 40,
        },
      },
    ],
  });

  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const { data: userMe } = useGetAuthMeQuery();
  const currentUserCompany = getCurrentCompany(userMe);
  const isViewerRole = currentUserCompany?.role === UserRole.VIEWER;
  const [broadcastedCompany, setBroadcastedCompany] = useBroadcast('facta_insights_company', currentUserCompany?.id || '');

  const defaultAttributes = {
    firstName: '',
    lastName: '',
    email: 'Not logged in',
  };

  const {
    firstName,
    lastName,
    email,
  } = userMe || defaultAttributes;

  const options = useMemo(() => ([
    {
      id: 'add-company',
      name: 'Add Company',
      isAddCompany: true,
    },
    ...(userMe?.companies || []),
  ]), [userMe?.companies]);;

  const nameInitials = `${firstName?.at(0)
    ?.toUpperCase() || '?'}${lastName?.at(0)
      ?.toUpperCase() || '?'}`;

  const handleToggleUserMenu = () => {
    popperElement?.getAttribute('data-show') === null
      ? popperElement?.setAttribute('data-show', '')
      : popperElement?.removeAttribute('data-show');

    if (update) {
      update();
    }
  };

  const handleLogout = () => {
    signOut();
  };

  const handleLogin = () => {
    window.location.href = `${import.meta.env.VITE_APP_AUTH_APP_URL}${window.location.origin}`;
  };

  const handleCompanyChange = (companyId: string) => {
    if (companyId !== currentUserCompany?.id) {
      changeCompany({ companyId })
        .unwrap()
        .then(() => {
          dispatch(bulkImportActions.clear());
          setBroadcastedCompany(companyId);
        });
    }
  };

  const handleClickAddCompany = (methods: SelectMethods<any>) => {
    navigate('/add-company');
    methods.dropDown('close');
  };

  useEffect(() => {
    if (broadcastedCompany) {
      navigate('/');
      dispatch(clearApiState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcastedCompany, dispatch]);

  return (
    <StyledHeader>
      <HeaderLogoSection onClick={() => navigate('/')}>
        <FactaLogo />
      </HeaderLogoSection>
      <>
        <HeaderSection>
          Insights
        </HeaderSection>
        {currentEnv === 'staging' && <EnvIndicator>UAT</EnvIndicator>}
        {user
          ? (
            <HeaderSection pushRight>
              {currentUserCompany?.id && (
                <>
                  <Tooltip title="Help">
                    <Button
                      color="transparent"
                      variant="icon-inverse"
                      size="large"
                      aria-label="Help"
                      // onClick={openChat}
                      onClick={() => window.open(INSIGHTS_HELP_URL, '_blank')}
                    >
                      <HelpIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip title="General Settings">
                    <Button
                      color="transparent"
                      variant="icon-inverse"
                      size="large"
                      aria-label="General Settings"
                      onClick={() => navigate('/settings/general')}
                      disabled={omitHeaderAndSidebar}
                      hidden={isViewerRole}
                    >
                      <ReportSettingsIcon />
                    </Button>
                  </Tooltip>
                </>
              )}
              <Button
                ref={setReferenceElement}
                variant="icon-inverse"
                color="primary"
                aria-label="User Menu"
                onClick={handleToggleUserMenu}
              >
                {nameInitials}
              </Button>
            </HeaderSection>
          )
          : (
            <HeaderSection pushRight>
              <Button onClick={handleLogin}>Login</Button>
            </HeaderSection>
          )
        }
        <UserMenu
          popperRef={setPopperElement}
          style={styles.popper}
          onLogout={handleLogout}
          onClose={() => popperElement?.removeAttribute('data-show')}
          attributes={attributes}
          givenName={firstName}
          familyName={lastName}
          email={email}
          currentCompany={currentUserCompany?.id}
        />
        {currentUserCompany?.id && (
          <StyledHeaderDropdown
            options={options}
            valueField="id"
            labelField="name"
            values={currentUserCompany ? [currentUserCompany] : []}
            onChange={(val) => handleCompanyChange(val[0].id)}
            disabled={isLoading}
            backspaceDelete={false}
            searchable={false}
            loading={isLoading}
            contentRenderer={({ state }) => (
              <div>
                {state.values[0].name}
                <StyledHeaderDropdownIns>{state.values[0].role}</StyledHeaderDropdownIns>
              </div>
            )}
            itemRenderer={({ item, methods, state }) => (
              <StyledHeaderDropdownItem
                isAddCompany={item.isAddCompany}
                onClick={item.isAddCompany ? () => handleClickAddCompany(methods) : () => methods.addItem(item)}
                selected={state.values[0] === item}
              >
                {item.isAddCompany && <PlusIcon />}
                {item.name}
                {!item.isAddCompany && <StyledHeaderDropdownIns>{item.role}</StyledHeaderDropdownIns>}
              </StyledHeaderDropdownItem>
            )}
            dataCy="menu-company-dropdown"
          />
        )}
      </>
    </StyledHeader>
  );
});
