import styled from "styled-components";
import { theme } from "theme/theme";

export const StyledTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background: rgba(255,255,255,0.9);
  padding: 10px;
  border-radius: 4px;
  box-shadow: ${theme.boxShadow};
`;

export const StyledTooltipLine = styled.div`
  display: flex;
  gap: 0 8px;
  margin: 5px;
`;

export const StyledTooltipHeader = styled(StyledTooltipLine)`
  font-weight: 700;
  color: ${theme.colors.primary};
`;

export const StyledTooltipIcon = styled.div`
  width: 12px;
`;

export const StyledTooltipLabel = styled.div`
  margin-right: 10px;
`;

export const StyledTooltipValue = styled.div`
  margin-left: auto;
`;
