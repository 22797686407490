import React, {
  useEffect,
  useState,
} from 'react';
import { SettingsType } from 'interfaces/settings';
import { Button } from 'components/common/Button';
import {
  RadioCheckedIcon,
  RadioUncheckedIcon,
} from 'assets/icons';
import { Datepicker } from 'components/common/Datepicker';
import {
  StyledSettingsItem,
  Setting,
  SettingsButtons,
} from './styled';
import { useUpdateSettingsMutation } from 'store/services/settings';
import {
  formatDateStringToMMMYearDisplay,
  formatDateToISO,
  isoToDate,
  numberToMonthName,
} from 'utils/dates';
import { toastSuccess } from 'utils/toast';
import { getMonth } from 'date-fns';
import { InputBasic } from "../../../../components/common/Input";
import { RadioButton } from 'components/common/RadioButton';

interface Props {
  name: string;
  title: string;
  description: string;
  value?: string;
  type: SettingsType;
  options?: string[] | any[];
  isLoading?: boolean;
  shouldSetMonthEndDate?: boolean;
}

export const SettingsItem = ({
  name,
  title,
  description,
  value,
  type,
  options,
  isLoading,
  shouldSetMonthEndDate,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [innerState, setInnerState] = useState(value);
  const [updateSettings, { isLoading: isUpdating }] = useUpdateSettingsMutation();
  const [errorState, setErrorState] = useState('');

  const displayValue = () => {
    if (value) {
      switch (type) {
        case SettingsType.DATE:
          return formatDateStringToMMMYearDisplay(value);
        case SettingsType.MONTH:
          return numberToMonthName(parseInt(value));
        case SettingsType.NUMBER:
        case SettingsType.NUMBER_WITH_DECIMALS:
          return `${value}%`;
        default:
          return value;
      }
    } else {
      return value;
    }
  };

  const handleCancel = () => {
    setInnerState(value);
    setIsEditing(false);
  };

  const validateDecimal = (value: string, includeDecimals: boolean) => {
    if ((includeDecimals && !/^(?:100|\d{1,2})(?:\.\d{1,2})?$/.test(value)) || (!includeDecimals && !/(^100$)|^\d\d?$/.test(value))) {
      setErrorState('Percentage between 0 and 100');
    }
    else {
      setErrorState('');
    }
  };

  const handleSave = () => {
    updateSettings({
      [name]: innerState,
    })
      .unwrap()
      .then(() => {
        toastSuccess('Setting saved.');
        setIsEditing(false);
      });
  };

  useEffect(() => {
    setInnerState(value);
  }, [value]);

  const renderSetting = () => {
    switch (type) {
      case SettingsType.DATE:
        return (
          <Datepicker
            name={name}
            onChange={(date) => setInnerState(date ? formatDateToISO(date as Date) : '')}
            value={innerState ? isoToDate(innerState) : undefined}
            condensed
            data-cy="set-datepicker"
            isClearable={false}
            variant="monthYearPicker"
            shouldSetMonthEndDate={shouldSetMonthEndDate}
          />
        );
      case SettingsType.MONTH:
        return (
          <Datepicker
            name={name}
            onChange={(date) => {
              if (date) {
                setInnerState((getMonth(date as Date) + 1).toString());
              }
            }}
            value={innerState ? new Date(new Date()
              .setMonth(parseInt(innerState) - 1)) : undefined}
            condensed
            variant="monthPicker"
            data-cy="set-datepicker"
            isClearable={false}
          />
        );
      case SettingsType.OPTION:
        return (
          <>
            {options?.map((option) => (
              <RadioButton
                key={option}
                variant="simple"
                color="secondary"
                onClick={() => setInnerState(option)}
                selected={option === innerState}
                data-cy="set-button-radio"
              >
                {option === innerState ? <RadioCheckedIcon /> : <RadioUncheckedIcon />}
                {option}
              </RadioButton>
            ))}
          </>
        );
      case SettingsType.NUMBER:
      case SettingsType.NUMBER_WITH_DECIMALS:
        return (
          (<div style={{ maxWidth: 200 }}>
            <InputBasic
              name=""
              labelText=""
              error={errorState}
              value={innerState}
              onChange={(event) => {
                const value = event.target.value.replace(/[^0-9.]/, '');
                setInnerState(value);
                validateDecimal(value, type === SettingsType.NUMBER_WITH_DECIMALS);
              }}
              suffix="%"
            />
          </div>)
        );
    }
  };

  return (
    <StyledSettingsItem>
      <div>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      {isEditing
        ? (
          <Setting>
            {renderSetting()}
            <SettingsButtons>
              <Button
                variant="borderless"
                size="large"
                onClick={handleSave}
                disabled={isLoading || isUpdating || errorState !== '' || innerState === ''}
                isLoading={isUpdating}
                data-cy="set-button-save"
              >
                Save
              </Button>
              <Button
                variant="borderless"
                size="large"
                color="secondary"
                onClick={handleCancel}
                disabled={isLoading || isUpdating}
                data-cy="set-button-cancel"
              >
                Cancel
              </Button>
            </SettingsButtons>
          </Setting>
        )
        : (
          <Setting>
            {isLoading && 'Loading...'}
            {!isLoading && !value && 'Failed to load'}
            {displayValue()}
            <Button
              variant="borderless"
              size="large"
              onClick={() => setIsEditing(true)}
              data-cy="set-button-edit"
              disabled={isLoading || !value}
            >
              Edit
            </Button>
          </Setting>
        )
      }
    </StyledSettingsItem>
  );
};
