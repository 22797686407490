import React, {
  CSSProperties,
  useRef,
} from 'react';
import { useNavigate } from 'react-router';
import { useClickOutside } from 'hooks/useClickOutside';
import {
  StyledUserMenu,
  StyledUsername,
  StyledEmail,
  StyledMenuItem,
} from './styled';
import {
  EXCEL_ADDIN_URL,
  RETOOL_ADMIN_URL,
} from 'utils/constants';

interface Props {
  popperRef: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  style: CSSProperties;
  attributes: {
    [key: string]: {
      [key: string]: string;
    } | undefined;
  };
  onClose: () => void;
  onLogout: () => void;
  givenName: string | null;
  familyName: string | null;
  email: string;
  currentCompany: string | undefined;
}

export const UserMenu = ({
  popperRef,
  style,
  attributes,
  onClose,
  onLogout,
  givenName,
  familyName,
  email,
  currentCompany,
}: Props) => {
  const divRef = useRef<HTMLDivElement>(null);
  useClickOutside(divRef, onClose);
  const navigate = useNavigate();

  const handleSettingsClick = () => {
    navigate('/settings');
    onClose();
  };

  const handleCompaniesClick = () => {
    navigate('/settings/companies');
    onClose();
  };

  return (
    <div ref={divRef}>
      <StyledUserMenu
        ref={popperRef}
        style={style}
        {...attributes}
      >
        <StyledUsername>
          {(givenName || familyName) ? `${givenName} ${familyName}` : 'Unknown'}
        </StyledUsername>
        <StyledEmail>
          {email}
        </StyledEmail>
        {currentCompany && (
          <StyledMenuItem
            onClick={handleSettingsClick}
            data-cy="header-button-settings"
          >
            Settings
          </StyledMenuItem>
        )}
        {email.endsWith('@facta.io') && (
          <StyledMenuItem
            onClick={() => window.open(RETOOL_ADMIN_URL)}
            data-cy="header-button-admin"
          >
            Insights Admin
          </StyledMenuItem>
        )}
        <StyledMenuItem
          onClick={() => window.open(EXCEL_ADDIN_URL)}
          data-cy="header-button-exceladdin"
        >
          Download Excel Add-in
        </StyledMenuItem>
        <StyledMenuItem
          onClick={handleCompaniesClick}
          data-cy="header-button-settings"
        >
          Companies
        </StyledMenuItem>
        <StyledMenuItem
          onClick={onLogout}
          data-cy="header-button-logout"
        >
          Logout
        </StyledMenuItem>
      </StyledUserMenu>
    </div>
  );
};
