import {
  CompanyUser,
  SetCompanyUsersRolesPayload,
} from "interfaces/company";
import { apiHardDeleteRecipe } from "utils/api";
import { api } from "./api";
import { UsersTags } from "./api-tags";

export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllCompanyUsers: builder.query<CompanyUser[], void>({
      query: () => ({
        url: 'users',
      }),
      providesTags: [UsersTags.Users],
    }),
    deleteUsers: builder.mutation<void, string[]>({
      query: (ids) => {
        return {
          url: `users`,
          method: 'DELETE',
          body: ids,
        };
      },
      onQueryStarted: async (ids, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(usersApi.util
            .updateQueryData('getAllCompanyUsers', undefined, apiHardDeleteRecipe(ids)));
        } catch {}
      },
    }),
    bulkEditUserRoles: builder.mutation<undefined, SetCompanyUsersRolesPayload>({
      query: (users) => {
        return {
          url: 'users/set-role',
          method: 'POST',
          body: users,
        };
      },
      invalidatesTags: (res, err) => !err ? [UsersTags.Users] : [],
    }),
    inviteUser: builder.mutation<void, { email: string; role: string; }>({
      query: (body) => {
        return {
          url: 'users/invite',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (res, err) => !err ? [UsersTags.Users] : [],
    }),
  }),
});

export const {
  useGetAllCompanyUsersQuery,
  useDeleteUsersMutation,
  useBulkEditUserRolesMutation,
  usePrefetch: usePrefetchUsers,
  useInviteUserMutation,
  util: {
    resetApiState: resetUsersApiState,
  },
} = usersApi;
