type Environment = 'staging' | 'development' | 'production';

const enabledOnEnvs = (envs: Environment[]) => envs.includes((import.meta.env.VITE_APP_ENV as Environment));

export const getFeatureSwitches = () => ({
  isLocalDevelopment: enabledOnEnvs([
    'staging',
    'development',
  ]),
  bulkImport: enabledOnEnvs([
    'staging',
    'development',
    'production',
  ]),
  bulkImportContracts: enabledOnEnvs([
    'staging',
    'development',
    'production',
  ]),
  contracts: enabledOnEnvs([
    'staging',
    'development',
    'production',
  ]),
  llamaSanta: enabledOnEnvs([
    // 'staging',
    // 'development',
    // 'production',
  ]),
  integrations: enabledOnEnvs([
    'staging',
    'development',
    'production',
  ]),
  accountMapping: enabledOnEnvs([
    'staging',
    'development',
    'production',
  ]),
  accountMappingTreeView: enabledOnEnvs([
    'staging',
    'development',
    'production',
  ]),
  reports: enabledOnEnvs([
    'staging',
    'development',
    'production',
  ]),
  metrics: enabledOnEnvs([
    'staging',
    'development',
    'production',
  ]),
  manualMetrics: enabledOnEnvs([
    'staging',
    'development',
    'production',
  ]),
});

export const featureSwitch = getFeatureSwitches();

export const currentEnv = import.meta.env.VITE_APP_ENV as Environment;
