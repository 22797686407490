import styled from 'styled-components';
import { theme } from 'theme/theme';
import { Button } from 'components/common/Button';

export const StyledMoveSelected = styled.div`
  display: flex;
  background-color: ${theme.colors.mainBackground};
  color: ${theme.colors.textDark};
  box-shadow: ${theme.boxShadow};
  z-index: 3;
  max-height: calc(100vh - 240px);
`;

export const StyledOption = styled(Button)<{ highlighted?: boolean }>`
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  border-radius: 0;
  justify-content: flex-start;
  padding: 12px 20px;
  color: ${theme.colors.textDark};
  background-color: ${props => props.highlighted ? theme.colors.secondaryBackground : theme.colors.transparent};
  border: none;
  height: auto;
  width: 100%;
  min-width: 200px;

  &:hover {
    background-color: ${theme.colors.primaryBleach};
    box-shadow: none;
  }
`;

export const StyledLeftContainer = styled.div`
  border-right: 1px solid ${theme.colors.borderColorDark};
  overflow: auto;
  height: 100%;
`;

export const StyledFactaAccounts = styled.div<{ visible?: boolean }>`
  position: relative;
  top: 0;
  display: ${props => props.visible ? 'block' : 'none'};
  pointer-events: ${props => props.visible ? 'all' : 'none'};
  overflow: auto;
  height: 100%;
`;
