import React from 'react';
import {
  ArrowLeftIcon,
  CloseIcon,
} from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import {
  StyledFormContainer,
  StyledButtonContainer,
  StyledFormSectionLabel,
} from 'components/common/Forms/styled';
import { SectionLabel } from 'components/common/SectionLabel';
import { toastSuccess } from 'utils/toast';
import { Button } from 'components/common/Button';
import { useUnlinkSubscriptionMutation } from 'store/services/subscriptions';
import { useAppSelector } from 'hooks/redux';
import { InputBasic } from 'components/common/Input';
import {
  Subscriptions,
  SubscriptionsUnlinkPayload,
} from 'interfaces/subscriptions';
import { subscriptionsUnlinkTypeSelector } from 'store/selectors/subscriptions';

interface Props {
  onClose: () => void;
  onGoBack: () => void;
  selectedRows: Subscriptions;
}

export const UnlinkSubscription = ({
  onClose,
  onGoBack,
  selectedRows,
}: Props) => {
  const [unlinkSubscription, { isLoading }] = useUnlinkSubscriptionMutation();
  const type = useAppSelector(subscriptionsUnlinkTypeSelector);

  const handleUnlink = () => {
    const payload: SubscriptionsUnlinkPayload = {
      subscriptionId: selectedRows[0].id,
      type,
    };

    unlinkSubscription(payload)
      .unwrap()
      .then(() => {
        toastSuccess('Revenue successfully unlinked.');
        onClose();
      });
  };

  return (
    <>
      <header>
        <ButtonsContainer paddingBottom={16}>
          <Button
            aria-label="Go back"
            variant="icon"
            size="large"
            onClick={onGoBack}
            data-cy="ulc-button-back"
          >
            <ArrowLeftIcon />
          </Button>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={onClose}
            pushRight
            data-cy="ulc-button-close"
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <SectionLabel>
          Unlink Revenue
        </SectionLabel>
      </header>
      <main>
        <p>Are you sure you would like to unlink these revenues?</p>
        <StyledFormSectionLabel />
        <StyledFormContainer>
          <InputBasic
            name="curr"
            labelText="Current Revenue"
            disabled
            value={selectedRows[0].name}
          />
          <InputBasic
            name="nextprev"
            labelText={`${type === 'next' ? 'Next' : 'Previous'} Revenue`}
            disabled
            value={type === 'next'
              ? selectedRows[0].next.name
              : selectedRows[0].previous.name}
          />
        </StyledFormContainer>
      </main>
      <footer>
        <StyledButtonContainer pushDown>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={onGoBack}
            disabled={isLoading}
            data-cy="ulc-button-save-back"
          >
            CANCEL
          </Button>
          <Button
            type="submit"
            onClick={handleUnlink}
            disabled={isLoading}
            isLoading={isLoading}
            data-cy="ulc-button-save"
          >
            UNLINK
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};
