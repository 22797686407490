import React, {
  forwardRef,
  useState,
} from "react";
import { useDebouncedEffect } from "hooks/useDebouncedEffect";
import { SelectProps } from "react-dropdown-select";
import { Dropdown } from "../Dropdown";
import { SearchResponse } from "interfaces/api";
import { useLazyGetCustomersSearchQuery } from "store/services/customers";

interface Props extends Omit<SelectProps<any>, 'options'> {
  labelText?: string;
  isRequired?: boolean;
  isOptional?: boolean;
  withError?: boolean;
  oneSignType?: boolean;
  dataCy?: string;
  error?: string;
  tooltip?: string;
  hidden?: boolean;
  borderless?: boolean;
  maxHeight?: number;
  condensed?: boolean;
  minWidth?: number;
  rowSelectedOption?: any;
}

export const CustomersDropdownLazy = forwardRef(({
  ...props
}: Props, ref: any) => {
  const [searchTerm, setSearchTerm] = useState<string | number>('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [getCustomersList, { isFetching }] = useLazyGetCustomersSearchQuery();
  const [moreThan, setMoreThan] = useState(0);

  const deletedOption = (props.rowSelectedOption && props.rowSelectedOption?.deleted) ? [{ ...props.rowSelectedOption, disabled: true }] : [];

  useDebouncedEffect(() => {
    getCustomersList({
      filtering: {
        name: searchTerm,
        deleted: false,
      },
      pagination: {
        page: 0,
        perPage: 1000,
      },
      simplified: true,
    })
      .unwrap()
      .then((result: SearchResponse<any[]>) => {
        setSearchResults([...deletedOption, ...result.data]);
        setMoreThan(result.totalPages > 1 ? result.perPage : 0);
      });
  }, [getCustomersList, searchTerm], 200);

  return <Dropdown
    ref={ref}
    {...props}
    options={searchResults}
    // @ts-ignore needed to avoid re-implementing search function
    searchFn={(e) => {
      setSearchTerm(e.state.search);
    }}
    loading={isFetching}
    noDataLabel={searchTerm ? 'No data' : `Type to search${props.create ? ` or add new ${props.name}` : ""}`}
    onDropdownClose={() => setSearchTerm('')}
    moreThan={moreThan}
  />;
});
