import React from 'react';
import styled from 'styled-components';
import { theme } from 'theme/theme';
import {
  CheckboxIndeterminate,
  CheckboxOff,
  CheckboxOn,
} from 'assets/icons';

export const StyledCheckbox = styled.label<{ condensed?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-size: ${props => props.condensed ? 14 : 16}px;
  margin: ${props => props.condensed ? '0 8px 0 0' : '0 0 4px 0'};
`;

export const Icon = styled.div`
  border-radius: 20%;
  padding: 2px;
  width: 28px;
  height: 28px;
`;

export const CheckboxIconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  text-decoration: none;
  color: inherit;
  padding: 8px;

  &:focus-within {
    ${Icon} {
      background-color: ${theme.colors.borderColorLight};
    }
  }
`;

export const CheckboxInput = styled.input`
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0px;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
`;

interface Props {
  name: string;
  checked?: boolean;
  children?: React.ReactNode | string;
  onChange: (checked: boolean) => void;
  indeterminate?: boolean;
  condensed?: boolean;
}

export const Checkbox = ({
  name,
  checked,
  children,
  onChange,
  indeterminate,
  condensed,
}: Props) => {
  const handleClick = (ev: React.MouseEvent<HTMLSpanElement>) => {
    ev.preventDefault();
    onChange(!checked);
  };

  return (
    <StyledCheckbox
      condensed={condensed}
      className="ag-selection-checkbox"
    >
      <CheckboxIconContainer onClick={handleClick}>
        <CheckboxInput
          type="checkbox"
          name={name}
        />
        <Icon>
          {indeterminate
            ? <CheckboxIndeterminate color={theme.colors.textDisabled} />
            : checked
              ? <CheckboxOn color={theme.colors.primary} />
              : <CheckboxOff color={theme.colors.fieldBorder} />
          }
        </Icon>
      </CheckboxIconContainer>
      <span>
        {children}
      </span>
    </StyledCheckbox>
  );
};
