import { useGetAuthMeQuery } from "store/services/auth";
import {
  StyledContent,
  StyledMessage,
  StyledName,
} from "./styled";
import { Button } from "components/common/Button";
import {
  RotatingRefreshIcon,
  SparkleIcon,
} from "assets/icons";

interface Props {
  name: string;
  children: string | JSX.Element | JSX.Element[];
  animate?: boolean;
  isLoading?: boolean;
}

export const Message = ({ children, name, isLoading }: Props) => {
  const { data: user } = useGetAuthMeQuery();

  const initials = `${user?.firstName?.charAt(0) || 'N'}${user?.lastName?.charAt(0) || 'A'}`;

  return (
    <StyledMessage isLoading={isLoading}>
      <Button
        variant="icon-inverse"
        color={name === 'AI Assistant' ? "primary" : "tertiary"}
      >
        {isLoading
          ? <RotatingRefreshIcon />
          : name === 'AI Assistant'
            ? <SparkleIcon />
            : initials}
      </Button>
      <StyledContent>
        <StyledName>
          {name}
        </StyledName>
        {children}
      </StyledContent>
    </StyledMessage>
  );
};