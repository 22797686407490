import React from 'react';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useForm,
  Controller,
} from 'react-hook-form';
import {
  ArrowLeftIcon,
  CloseIcon,
  InfoIcon,
} from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import {
  StyledFormContainer,
  StyledButtonContainer,
} from 'components/common/Forms/styled';
import { SectionLabel } from 'components/common/SectionLabel';
import { toastSuccess } from 'utils/toast';
import { Button } from 'components/common/Button';
import { formatDateToISODate } from 'utils/dates';
import {
  useGetContractsSearchQuery,
  useLinkContractsMutation,
} from 'store/services/contracts';
import {
  Contracts,
  ContractsLinkPayload,
} from 'interfaces/contracts';
import { Hint } from 'components/common/Hint';
import { Dropdown } from 'components/common/Dropdown';

interface Props {
  onClose: () => void;
  onGoBack: () => void;
  selectedRows: Contracts;
}

export const LinkContracts = ({
  selectedRows,
  onClose,
  onGoBack,
}: Props) => {
  const [linkContracts, { isLoading }] = useLinkContractsMutation();
  const isLinkingAllowed = selectedRows
    .every((con) => !con.previous.length);

  const {
    contracts,
  } = useGetContractsSearchQuery({
    filtering: {
      deleted: false,
      customerName: selectedRows[0]?.customer?.name,
      startDate: [
        selectedRows[0]?.startDate,
        '2999-12-12', // Stands for infinity
      ],
    },
    pagination: {
      page: 0,
      perPage: 0,
    },
    simplified: false,
  }, {
    selectFromResult: ({ data }) => ({
      contracts: data?.data?.filter((contract) =>
        !contract.next.length
        && contract.id !== selectedRows[0]?.id,
      ),
    }),
  });

  const schema = yup.object({
    contracts: yup.array()
      .min(1, 'Select at least one previous contract to link.'),
  })
    .required();

  const defaultValues = {
    contracts: [],
    cancelDate: formatDateToISODate(new Date()),
  };

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<{ contracts: Contracts, cancelDate: Date }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const handleSaveAndBack = handleSubmit((data) => {
    const payload: ContractsLinkPayload = {
      contractIds: data.contracts.map((row) => row.id),
      nextContractId: selectedRows.map((row) => row.id)[0],
    };

    return linkContracts(payload)
      .unwrap()
      .then(() => {
        toastSuccess('Contract(s) successfully linked.');
        onGoBack();
      });
  });

  return (
    <>
      <header>
        <ButtonsContainer paddingBottom={16}>
          <Button
            aria-label="Go back"
            variant="icon"
            size="large"
            onClick={onGoBack}
            data-cy="lc-button-back"
          >
            <ArrowLeftIcon />
          </Button>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={onClose}
            pushRight
            data-cy="lc-button-close"
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <SectionLabel>
          <span>
            {selectedRows?.length > 1 ? 'Link Contracts' : 'Link Contract'}
          </span>
        </SectionLabel>
      </header>
      <main>
        <Hint>
          <InfoIcon />
          {!isLinkingAllowed
            ? (
              <div>
                {selectedRows.length > 1
                  ? 'One or more of selected contracts are already linked.'
                  : 'Selected contract is already linked.'
                }
              </div>
            )
            : (
              <div>Create a history for your contract by linking it to one or more previous ones.</div>
            )}
        </Hint>
        {isLinkingAllowed && (
          <StyledFormContainer>
            <Controller
              name="contracts"
              control={control}
              render={({ field }) =>
                <Dropdown
                  labelText="Select Previous Contract(s)"
                  labelField="name"
                  valueField="id"
                  searchBy="name"
                  placeholder="Select Previous Contract(s)"
                  options={contracts || []}
                  values={getValues('contracts')}
                  {...field}
                  error={errors.contracts?.message}
                  dataCy="lc-dropdown-contracts"
                  multi
                  tooltip="In this list, you will only see contracts with the same customer,
                      contracts that have not been previously linked and whose start date is earlier
                      than the start date of this contract."
                  noDataLabel="No eligible contracts"
                />
              }
            />
          </StyledFormContainer>
        )}
      </main>
      <footer>
        {isLinkingAllowed && (
          <StyledButtonContainer pushRight>
            <Button
              type="submit"
              onClick={handleSaveAndBack}
              disabled={isLoading}
              isLoading={isLoading}
              data-cy="lc-button-save-backs"
            >
              SAVE & BACK
            </Button>
          </StyledButtonContainer>
        )}
      </footer>
    </>
  );
};
