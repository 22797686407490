import React from 'react';
import styled from 'styled-components';
import { Tags } from 'interfaces/tags';
import { ErrorCell } from '../ErrorCell';

const StyledTag = styled.span`
  &:after {
    content: ', ';
  }

  &:last-of-type::after {
    content: '';
  }
`;

interface Props {
  value: Tags;
  errorCode?: string;
  errorValue?: string;
}

export const AgGridCellRendererTags = ({ value, errorCode, errorValue }: Props) => {
  if (errorCode) {
    return (
      <ErrorCell>{errorValue}</ErrorCell>
    );
  }

  return (value && value.length > 0
    ? value.map((tag) => (
      <StyledTag key={tag.id}>
        {tag.name}
      </StyledTag>
    ))
    : '-'
  );
};
