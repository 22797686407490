import {
  useEffect,
  useMemo,
  useState,
} from "react";

export const useBroadcast = (name: string, initialState: string): [string, React.Dispatch<React.SetStateAction<string>>] => {
  const [innerState, setInnerState] = useState(initialState);
  const channel = useMemo(() => new BroadcastChannel(name), [name]);

  channel.onmessage = (event) => {
    event.preventDefault();
    let { data: value } = event;
    setInnerState(value);
  };

  useEffect(() => {
    channel.postMessage(innerState);
  }, [channel, innerState]);

  return [innerState, setInnerState];
};
