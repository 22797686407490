import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import {
  LeftPane,
  RightPane,
} from 'components/Layout';
import { SectionLabel } from 'components/common/SectionLabel';
import {
  bulkImportCommonDataSelector,
  bulkImportSubsResultsSelector,
} from 'store/selectors/bulkImport';
import { BulkImportSidePanel } from '../../../../components/BulkImport/SidePanel';
import { Tabs } from 'components/common/Tabs';
import { AgGridReact } from '@ag-grid-community/react';
import { GridOptions } from '@ag-grid-community/core';
import { getColumnDefs } from './columnDefinitions';
import { useRightPanes } from 'hooks/useRightPanes';
import { BulkImportEdit } from '../Edit';
import { useGetAllCustomersQuery } from 'store/services/customers';
import { useGetAllProductsQuery } from 'store/services/products';
import { useGetAllSubscriptionsQuery } from 'store/services/subscriptions';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import { useBulkImportSubscriptionsMutation } from 'store/services/import';
import { BulkImportDelete } from '../Delete';
import { BulkImportSubscriptionsRow } from 'interfaces/bulkImportSubscriptions';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import { bulkImportActions } from 'store/slices/bulkImport';
import { invalidateTags } from 'store/services/api';
import { toastSuccess } from 'utils/toast';
import { Loading } from 'components/Loading';
import { AddProduct } from 'pages/Products/AddProduct';
import { AddCustomer } from 'pages/Customers/AddCustomer';
import { AddTag } from 'pages/Tags/AddTag';
import { Customer } from 'interfaces/customers';
import { Product } from 'interfaces/products';
import { Tag } from 'interfaces/tags';
import { prepareValidatedSubscriptions } from 'utils/bulkImport';
import { FactaTable } from 'components/FactaTable';
import { CompanyRevenueType } from 'interfaces/company';
import { SubscriptionsTags } from 'store/services/api-tags';

export const BulkImportReview = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const columnDefs = getColumnDefs();
  const gridRef = useRef<AgGridReact>(null);
  const [bulkImport, { isLoading }] = useBulkImportSubscriptionsMutation();
  const [selectedTab, setSelectedTab] = useState('all');
  const [selectedRows, setSelectedRows] = useState<BulkImportSubscriptionsRow[]>([]);
  const bulkImportResults = useAppSelector(bulkImportSubsResultsSelector);
  const bulkImportCommonData = useAppSelector(bulkImportCommonDataSelector);
  useGetAllCustomersQuery();
  useGetAllProductsQuery();
  useGetAllSubscriptionsQuery();

  const {
    rows,
  } = bulkImportResults;

  const {
    totalRows,
    errorRows,
    duplicateRows,
  } = bulkImportCommonData;

  const { resetPanes, rightPanes, setSelectedPane } = useRightPanes({
    summary: true,
    edit: false,
    delete: false,
    addProduct: false,
    addCustomer: false,
    addTag: false,
  });

  const gridOptions: GridOptions = {
    onSelectionChanged: (e) => {
      const selectedNodes = e.api.getSelectedNodes();
      setSelectedRows(e.api.getSelectedRows());
      if (selectedNodes.length === 1) {
        setSelectedPane('edit');
      } else {
        setSelectedPane('summary');
      }
    },
    processCellForClipboard: ({ value }) => value?.providedValue || '',
  };

  const tabs = [
    {
      name: 'All',
      value: "all",
    },
    {
      name: `Issues ${errorRows ? `(${errorRows})` : ''}`,
      value: "errors",
      disabled: !errorRows,
    },
    {
      name: `Duplicates ${duplicateRows ? `(${duplicateRows})` : ''}`,
      value: "duplicates",
      disabled: !duplicateRows,
    },
  ];

  const tabbedRows = rows.filter((row) => {
    switch (selectedTab) {
      case 'duplicates':
        return row.errors === 'duplicate';
      case 'errors':
        return row.errors !== null && row.errors !== 'duplicate';
      default:
        return row;
    }
  });

  const handleCancel = () => navigate('/data-input/revenue-management/revenue/import');

  const handleContinue = () => {
    const validatedSubscriptions = prepareValidatedSubscriptions(rows);

    bulkImport({ data: validatedSubscriptions })
      .unwrap()
      .then((result) => {
        if (result) {
          dispatch(bulkImportActions.populateSubscriptions({ result }));
        } else {
          toastSuccess('Revenues successfully added');
          dispatch(invalidateTags([SubscriptionsTags.Subscriptions]));
          dispatch(bulkImportActions.clear());
          navigate('/data-input/revenue-management/revenue');
        }
      });
  };

  const handleTriggerDelete = () => {
    setSelectedPane('delete');
  };

  const handleUpdateRow = (id: string, field: string, addedEntity: Customer | Product | Tag) => {
    dispatch(bulkImportActions.updateSubsField({
      id,
      name: field,
      data: {
        providedValue: addedEntity.name,
        matchedValue: { id: addedEntity.id },
        errorCode: null,
      },
    }));
    setSelectedPane('edit');
  };

  useEffect(() => {
    if (!rows.length) {
      navigate('/data-input/revenue-management/revenue/import');
    } else {
      dispatch(bulkImportActions.userReachedReviewDataScreen(true));
    }
  }, [dispatch, navigate, rows]);

  if (isLoading) {
    return (
      <LeftPane>
        <Loading
          title={`Uploading ${totalRows} revenues...`}
          timeoutMessages={[
            {
              timeoutMs: 20000,
              message: 'Upload process is taking more time than usual... but we\'re working on it!',
            },
            {
              timeoutMs: 40000,
              message: 'Upload process is taking too much time. If it fails, consider reducing number of lines in your file.',
            },
          ]}
        />
      </LeftPane>
    );
  }

  return (
    <>
      <LeftPane>
        <ButtonsContainer paddingBottom={20}>
          <Breadcrumbs>
            <Breadcrumb link="/data-input/revenue-management/revenue">Revenue</Breadcrumb>
            <Breadcrumb link="/data-input/revenue-management/revenue/import">Upload File</Breadcrumb>
            <Breadcrumb link="/data-input/revenue-management/revenue/import/clean-data">Clean Data</Breadcrumb>
            <Breadcrumb>Review Revenues & Submit</Breadcrumb>
          </Breadcrumbs>
        </ButtonsContainer>
        <SectionLabel>
          <span>
            Review Revenues & Submit
            <p>Please update any required fields before submitting your revenues.</p>
          </span>
        </SectionLabel>
        <Tabs
          tabs={tabs}
          value={selectedTab}
          onChange={(tab) => setSelectedTab(tab.value)}
        />
        <FactaTable
          gridRef={gridRef}
          data={tabbedRows}
          useNativeFilters
          columnDefs={columnDefs}
          isLoading={isLoading}
          selectedRowsLength={selectedRows.length}
          entityName="Revenue"
          gridOptions={gridOptions}
          onClickDelete={handleTriggerDelete}
          customEmptyState={() => <>Nothing to show</>}
          showQuickSearch
        />
      </LeftPane>
      <RightPane isOpen>
        {rightPanes.summary && (
          <BulkImportSidePanel
            companyRevenueType={CompanyRevenueType.SUBSCRIPTIONS}
            handleCancel={handleCancel}
            handleContinue={handleContinue}
            isContinueDisabled={!!errorRows || !!duplicateRows || isLoading}
            step={3}
            isStepDone={errorRows === 0 && duplicateRows === 0}
          />
        )}
        {rightPanes.edit && selectedRows.length === 1 && (
          <BulkImportEdit
            id={selectedRows[0].id}
            onClose={() => resetPanes()}
            onDelete={() => setSelectedPane('delete')}
            onAddTag={() => setSelectedPane('addTag')}
            onAddProduct={() => setSelectedPane('addProduct')}
            onAddCustomer={() => setSelectedPane('addCustomer')}
          />
        )}
        {rightPanes.delete && selectedRows.length > 0 && (
          <BulkImportDelete
            onClose={() => setSelectedPane('edit')}
            selectedRows={selectedRows}
          />
        )}
        {rightPanes.addProduct && (
          <AddProduct
            onClose={() => setSelectedPane('edit')}
            onSuccess={(addedEntity) => handleUpdateRow(selectedRows.at(0)!.id, 'product', addedEntity)}
            onGoBack={() => setSelectedPane('edit')}
            isIndirectOpen
          />
        )}
        {rightPanes.addCustomer && (
          <AddCustomer
            onClose={() => setSelectedPane('edit')}
            onSuccess={(addedEntity) => handleUpdateRow(selectedRows.at(0)!.id, 'customer', addedEntity)}
            onGoBack={() => setSelectedPane('edit')}
            isIndirectOpen
          />
        )}
        {rightPanes.addTag && (
          <AddTag
            onClose={() => setSelectedPane('edit')}
            onSuccess={(addedEntity) => handleUpdateRow(selectedRows.at(0)!.id, 'tag', addedEntity)}
            onGoBack={() => setSelectedPane('edit')}
            isIndirectOpen
          />
        )}
      </RightPane>
    </>
  );
};
