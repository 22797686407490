export const parsedPastedValue = (input: any) => {
  const regex = /-?(?:\$\s?)?(?:\d{1,3}(?:,\d{3})*|\d+)(?:\.\d+)?(?![\d.,])/g;
  const value = input?.toString();
  const match = value?.match(regex);

  if (match) {
    const num = parseFloat(match[0].replace(/[, $]/g,''));

    if (value.includes("(")) {
      return -num;
    } else {
      return num;
    }
  } else {
    return null;
  }
};
