import React from 'react';
import { Header } from 'components/Header';
import { Sidebar } from 'components/Sidebar';
import { LeftPane } from './LeftPane';
import { RightPane } from './RightPane';
import {
  ContentWrapper,
  Page,
} from './styled';

interface Props {
  children: React.ReactNode;
  omitHeaderAndSidebar: boolean;
}

export const Layout = ({
  children,
  omitHeaderAndSidebar,
}: Props) => {
  return (
    <Page>
      <Header omitHeaderAndSidebar={omitHeaderAndSidebar} />
      <ContentWrapper>
        {!omitHeaderAndSidebar && (
          <Sidebar />
        )}
        {children}
      </ContentWrapper>
    </Page>
  );
};

export {
  LeftPane,
  RightPane,
};