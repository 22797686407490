import React from 'react';
import styled from 'styled-components';
import { theme } from 'theme/theme';

const StyledProgressContainer = styled.div`
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: ${theme.colors.borderColorDark};
`;

const StyledProgress = styled.div<{ progress: number }>`
  width: ${props => props.progress}%;
  height: 4px;
  border-radius: 2px;
  background-color: ${theme.colors.primary};
  transition: width 2s ease;
`;

const StyledPercent = styled.div<{ large?: boolean }>`
  color: ${theme.colors.textDark};
  padding: 8px 0;
  font-weight: 500;
  font-size: ${props => props.large ? '14px' : '10px'};
  line-height: ${props => props.large ? '21px' : '15px'};
  letter-spacing: 1.5px;
`;

interface Props {
  progress: number;
  large?: boolean;
}

export const ProgressTracker = ({
  progress,
  large,
}: Props) => {
  return (
    <div>
      <StyledProgressContainer>
        <StyledProgress progress={progress} />
      </StyledProgressContainer>
      <StyledPercent large={large}>
        {progress}%
      </StyledPercent>
    </div>
  );
};
