import React from 'react';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useForm,
  Controller,
} from 'react-hook-form';
import {
  ArrowLeftIcon,
  CloseIcon,
} from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { Datepicker } from 'components/common/Datepicker';
import {
  StyledFormContainer,
  StyledButtonContainer,
} from 'components/common/Forms/styled';
import { SectionLabel } from 'components/common/SectionLabel';
import {
  Subscriptions,
  SubscriptionsCancelPayload,
} from 'interfaces/subscriptions';
import { toastSuccess } from 'utils/toast';
import { Button } from 'components/common/Button';
import { useCancelSubscriptionsMutation } from 'store/services/subscriptions';
import {
  formatDateToISO,
  formatDateToISODate,
  isoToDate,
} from 'utils/dates';

interface Props {
  onClose: () => void;
  onGoBack: () => void;
  selectedRows: Subscriptions;
}

export const CancelSubscriptions = ({
  selectedRows,
  onClose,
  onGoBack,
}: Props) => {
  const [cancelSubscriptions, { isLoading }] = useCancelSubscriptionsMutation();

  const latestStartDateOrBookingDate = selectedRows.length
    ? isoToDate(selectedRows
      .flatMap((sub) => sub.bookingDate ? [sub.startDate, sub.bookingDate] : [sub.startDate])
      .reduce((date1, date2) => (date1 > date2 ? date1 : date2)))!
    : formatDateToISODate(new Date());

  const schema = yup.object({
    cancelDate: yup.date()
      .nullable()
      .test(
        'isNotBeforeStartDateOrBookingDate',
        'Cancel date cannot be set before start date or booking date.',
        (val) => val ? (val.getTime() >= latestStartDateOrBookingDate.getTime()) : false,
      )
      .required('Cancel date is required.'),
  })
    .required();

  const defaultValues = {
    cancelDate: formatDateToISODate(new Date()),
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<{ cancelDate: Date }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const handleSave = handleSubmit((data) => {
    const payload: SubscriptionsCancelPayload = {
      subscriptionIds: selectedRows.map((row) => row.id),
      cancelDate: formatDateToISO(data.cancelDate!),
    };

    return cancelSubscriptions(payload)
      .unwrap()
      .then(() => {
        toastSuccess('Revenue(s) successfully canceled.');
      });
  });

  const handleSaveAndBack = handleSubmit((data) => {
    const payload: SubscriptionsCancelPayload = {
      subscriptionIds: selectedRows.map((row) => row.id),
      cancelDate: formatDateToISO(data.cancelDate!),
    };

    return cancelSubscriptions(payload)
      .unwrap()
      .then(() => {
        toastSuccess('Revenue(s) successfully canceled.');
        onGoBack();
      });
  });

  return (
    <>
      <header>
        <ButtonsContainer paddingBottom={16}>
          <Button
            aria-label="Go back"
            variant="icon"
            size="large"
            onClick={onGoBack}
            data-cy="cs-button-back"
          >
            <ArrowLeftIcon />
          </Button>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={onClose}
            pushRight
            data-cy="cs-button-close"
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <SectionLabel>
          <span>
            {selectedRows?.length > 1 ? 'Cancel Revenues' : 'Cancel Revenue'}
          </span>
        </SectionLabel>
      </header>
      <main>
        <StyledFormContainer>
          <Controller
            name="cancelDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Cancel Date"
                placeholder="Select Date"
                {...field}
                error={errors.cancelDate?.message}
                isRequired
                data-cy="cs-datepicker-cancel"
                isClearable={false}
              />
            }
          />
        </StyledFormContainer>
      </main>
      <footer>
        <StyledButtonContainer pushRight>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={handleSave}
            disabled={isLoading}
            isLoading={isLoading}
            data-cy="cs-button-save"
          >
            SAVE
          </Button>
          <Button
            type="submit"
            onClick={handleSaveAndBack}
            disabled={isLoading}
            isLoading={isLoading}
            data-cy="cs-button-save-backs"
          >
            SAVE & BACK
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};
