import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as yup from "yup";
import {
  useForm,
  Controller,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CloseIcon,
  CopyIcon,
  DeleteIcon,
} from 'assets/icons';
import {
  useBulkEditCustomersMutation,
  useLazyCustomersUniquenessQuery,
  useUpdateCustomerMutation,
} from 'store/services/customers';
import { Button } from 'components/common/Button';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import {
  Customer,
  CustomerFormFields,
} from 'interfaces/customers';
import {
  StyledButtonContainer,
  StyledCheckboxContainer,
  StyledFormContainer,
  StyledFormSectionLabel,
} from 'components/common/Forms/styled';
import { Dropdown } from 'components/common/Dropdown';
import { InputBasic } from 'components/common/Input';
import { copyToClipboard } from 'utils/copyToClipboard';
import { prepareCustomerPayload } from 'utils/customers';
import { useGetAllTagsQuery } from 'store/services/tags';
import { toastSuccess } from 'utils/toast';
import { actionBlocker } from 'utils/actionBlocker';
import { TagType } from 'interfaces/tags';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import { formDataSelector } from 'store/selectors/formState';
import { formStateActions } from 'store/slices/formState';
import {
  getTagsIdsForSelectedRows,
  tagsSortFn,
} from 'utils/tags';
import { Tooltip } from 'components/common/Tooltip';
import { Checkbox } from 'components/common/Checkbox';
import { useUniquenessCheck } from 'hooks/useUniquenessCheck';
import { gridHelperSelector } from 'store/selectors/gridHelper';

interface Props {
  onSuccess: (id?: string) => void;
  onDelete: () => void;
  onAddTag: () => void;
  selectedRows: Customer[];
  onClose: () => void;
}

export const EditCustomers = ({
  onSuccess,
  onDelete,
  onAddTag,
  selectedRows,
  onClose,
}: Props) => {
  const [updateCustomer, { isLoading }] = useUpdateCustomerMutation();
  const [customerUnique, { isFetching: isCheckingUniqueness }] = useLazyCustomersUniquenessQuery();
  const [bulkEditCustomers, { isLoading: isLoadingBulkEdit }] = useBulkEditCustomersMutation();
  const [isClearExistingTags, setIsClearExistingTags] = useState(false);
  const dispatch = useAppDispatch();
  const formData = useAppSelector(formDataSelector);
  const {
    isAllSelected,
    selectedIds,
    deselectedIds,
    totalSelected,
    filtering,
  } = useAppSelector(gridHelperSelector);

  const isBulkEdit = totalSelected > 1;

  const selectedRowsTagsIds = useMemo(() => getTagsIdsForSelectedRows(selectedRows), [selectedRows]);

  const { tagOptions } = useGetAllTagsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      tagOptions: data
        ?.filter((tag) => tag.type === TagType.CUSTOMER && (!tag.deleted || selectedRowsTagsIds.includes(tag.id)))
        .sort(tagsSortFn) || [],
    }),
  });

  const bulkEditSchema = yup.object({
    tags: yup.array()
      .of(
        yup.object({
          id: yup.string(),
          name: yup.string(),
        }),
      ),
    crmId: yup.string()
      .nullable(),
  })
    .required();

  const schema = bulkEditSchema.concat(
    yup.object()
      .shape({
        name: yup.string()
          .required('Customer name is required.')
          .test(
            'isUnique',
            'Customer name must be unique.',
            () => isNameUnique,
          ),
      }));

  const defaultValues = useMemo(() => ({
    ...(formData
      ? {
        ...formData,
      }
      : {
        id: '',
        name: '',
        tags: [],
        crmId: '',
      }
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    trigger,
    formState: { errors, isDirty },
  } = useForm<CustomerFormFields>({
    resolver: yupResolver(isBulkEdit ? bulkEditSchema : schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const [isNameUnique, setName] = useUniquenessCheck(customerUnique, {}, 'name', trigger, [selectedRows[0]?.name]);

  useEffect(() => {
    const customer = selectedRows[0];

    if (isBulkEdit && !formData) {
      reset({
        id: '',
        name: '',
        tags: [],
        crmId: '',
      });
    } else if (customer && !formData) {
      reset({
        id: customer.id,
        name: customer.name,
        crmId: customer.crmId,
        tags: customer.tags,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  const handleSaveAndClose = handleSubmit((data) => {
    const customer = prepareCustomerPayload(data);

    return updateCustomer(customer)
      .unwrap()
      .then((result) => {
        toastSuccess('Customer successfully updated.');
        onSuccess(result.id);
        onClose();
      });
  });

  const handleBulkSaveAndClose = handleSubmit((data) => {
    const changedTags = data.tags.length ? data.tags.map((tag) => tag.id) : null;
    const preparedPayload = {
      isAllSelected,
      selectedIds,
      deselectedIds,
      crmId: data.crmId || null,
      tagIds: isClearExistingTags ? [] : changedTags,
      filtering,
    };

    return bulkEditCustomers(preparedPayload)
      .unwrap()
      .then(() => {
        toastSuccess('Customers successfully updated.');
        onSuccess();
        onClose();
      });
  });

  const handleSave = handleSubmit((data) => {
    const customer = prepareCustomerPayload(data);

    return updateCustomer(customer)
      .unwrap()
      .then((result) => {
        toastSuccess('Customer successfully updated.');
        onSuccess(result.id);
      });
  });

  const handleBulkSave = handleSubmit((data) => {
    const changedTags = data.tags.length ? data.tags.map((tag) => tag.id) : null;
    const preparedPayload = {
      isAllSelected,
      selectedIds,
      deselectedIds,
      crmId: data.crmId || null,
      tagIds: isClearExistingTags ? [] : changedTags,
      filtering,
    };

    return bulkEditCustomers(preparedPayload)
      .unwrap()
      .then(() => {
        toastSuccess('Customers successfully updated.');
        onSuccess();
      });
  });

  const handleAddTag = ({ name }: { name: string }) => {
    dispatch(formStateActions.setPredefinedData({
      name,
      type: TagType.CUSTOMER,
    }));
    dispatch(formStateActions.setFormData(getValues()));
    onAddTag();
  };

  const handleClickDelete = onDelete;

  const handleClearExistingTags = () => {
    setIsClearExistingTags(!isClearExistingTags);
    setValue('tags', []);
  };

  return (
    <>
      <header>
        <ButtonsContainer>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={() => actionBlocker(onClose, isDirty)}
            pushRight
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <StyledFormSectionLabel data-cy="ec-header">
          <span>
            {isBulkEdit ? 'Bulk Edit' : 'Edit Customer'}
          </span>
          {isBulkEdit
            ? (
              <span>
                {totalSelected} Customers
              </span>
            )
            : (
              <ButtonsContainer>
                <Button
                  variant="simple"
                  size="large"
                  type="button"
                  onClick={(e) => copyToClipboard(e, selectedRows[0].id)}
                  data-cy="ec-button-copy"
                >
                  <span>
                    Insights Customer ID
                  </span>
                  <CopyIcon />
                </Button>
              </ButtonsContainer>
            )}
        </StyledFormSectionLabel>
        <ButtonsContainer paddingBottom={20}>
          <Tooltip title="Delete">
            <Button
              variant="icon"
              color="primary"
              size="large"
              onClick={handleClickDelete}
              aria-label="Delete"
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        </ButtonsContainer>
      </header>
      <main>
        <StyledFormContainer>
          {!isBulkEdit && (
            <InputBasic
              isRequired
              labelText="Customer Name"
              placeholder="Add customer name"
              {...register('name')}
              onBlur={(e) => {
                const fieldValue = e.target.value;

                if (fieldValue) {
                  setValue('name', fieldValue.trim(), { shouldValidate: true });
                }
              }}
              onCustomChange={(e) => setName(e.target.value)}
              error={errors.name?.message}
              dataCy="ec-input-name"
              isLoading={isCheckingUniqueness}
            />
          )}
          <div>
            <Controller
              name="tags"
              control={control}
              render={({ field }) =>
                <Dropdown
                  isOptional
                  labelText="Tags"
                  options={tagOptions}
                  labelField="name"
                  valueField="id"
                  searchBy="name"
                  {...field}
                  values={getValues('tags')}
                  dataCy="ep-dropdown-tags"
                  error={errors.tags?.message}
                  multi
                  create
                  onCreateNew={handleAddTag}
                  disabled={isClearExistingTags}
                />
              }
            />
            {isBulkEdit && (
              <StyledCheckboxContainer>
                <Checkbox
                  name="clearExistingTags"
                  checked={isClearExistingTags}
                  onChange={handleClearExistingTags}
                >
                  Clear existing Tags
                </Checkbox>
              </StyledCheckboxContainer>
            )}
          </div>
          <InputBasic
            isOptional
            labelText="CRM ID"
            placeholder="Paste unique identifier"
            {...register('crmId')}
            error={errors.crmId?.message}
            dataCy="ec-input-crm"
          />
        </StyledFormContainer>
      </main>
      <footer>
        <StyledButtonContainer pushRight>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={isBulkEdit ? handleBulkSave : handleSave}
            disabled={isBulkEdit ? isLoadingBulkEdit : isLoading || isCheckingUniqueness}
            isLoading={isBulkEdit ? isLoadingBulkEdit : isLoading}
            data-cy="ec-button-save"
          >
            SAVE
          </Button>
          <Button
            type="submit"
            onClick={isBulkEdit ? handleBulkSaveAndClose : handleSaveAndClose}
            disabled={isBulkEdit ? isLoadingBulkEdit : isLoading || isCheckingUniqueness}
            isLoading={isBulkEdit ? isLoadingBulkEdit : isLoading}
            data-cy="ec-button-save-close"
          >
            SAVE & CLOSE
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};
