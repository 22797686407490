import * as React from "react";

const SvgComponent = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M12.242 4.738V3.242h8.485v8.485H19.22l.005-5.923-6.454 6.454-1.06-1.06 6.453-6.455-5.923-.005Zm-.518 14.494v1.496H3.24v-8.486h1.506l-.005 5.924 6.454-6.454 1.06 1.06-6.453 6.455 5.923.005Z"
      clipRule="evenodd"
      opacity={0.9}
    />
  </svg>
);

export default SvgComponent;
