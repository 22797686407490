import { ColDef } from '@ag-grid-community/core';
import { MetricOrigin } from 'interfaces/metrics';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'theme/theme';
import {
  caseInsensitiveComparator,
  dateComparator,
} from "utils/aggrid";
import { MetricOriginOptions } from 'utils/constants';
import { formatDateStringToDisplay } from 'utils/dates';
import {
  multiFilter,
  dateFilter,
} from 'utils/filters';
import {
  FormatOptions,
  getAggregationMethodName,
} from 'utils/metrics';

const StyledCommonCellRenderer = styled.div`
  display: flex;
  align-items: center;

  ins {
    display: flex;
    align-items: center;
    height: 22px;
    text-decoration: none;
    border-radius: 4px;
    padding: 2px 4px;
    margin-left: 8px !important;
    color: ${theme.colors.textDark} !important;
    background-color: #C4C4D4;
  }
`;

const StyledTextContainer = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const OverviewCellRenderer = (item: any) => {
  if (!item.data) return null;

  return (
    <StyledCommonCellRenderer>
      <StyledTextContainer>
        <Link to={`/metrics/${item.data.id}`}>
          <span>{item.value}</span>
        </Link>
      </StyledTextContainer>
    </StyledCommonCellRenderer>
  );
};

const getSourceName = (name: MetricOrigin) => MetricOriginOptions.find((mo) => mo.value === name)?.name;

export const getColumnDefs = (): Array<ColDef> => ([
  {
    field: 'id',
    headerName: 'Id',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    cellRenderer: () => null,
    maxWidth: 70,
    pinned: 'left',
  },
  {
    field: 'name',
    headerName: 'Metric Name',
    sortable: true,
    resizable: true,
    flex: 2,
    minWidth: 300,
    cellRenderer: OverviewCellRenderer,
    comparator: caseInsensitiveComparator(),
    ...multiFilter,
  },
  {
    field: 'origin',
    headerName: 'Source',
    sortable: true,
    resizable: true,
    minWidth: 160,
    initialWidth: 160,
    valueGetter: ({ data }) => getSourceName(data.origin),
    comparator: caseInsensitiveComparator(),
    ...multiFilter,
  },
  {
    field: 'valueFormat',
    headerName: 'Format',
    sortable: true,
    resizable: true,
    minWidth: 160,
    comparator: caseInsensitiveComparator(),
    valueGetter: ({ data }) => FormatOptions.find((fo) => fo.value === data.valueFormat)?.label || '-',
    ...multiFilter,
  },
  {
    field: 'type.category',
    headerName: 'Metric Category',
    sortable: true,
    resizable: true,
    minWidth: 245,
    ...multiFilter,
    comparator: caseInsensitiveComparator(),
  },
  {
    field: 'type.name',
    headerName: 'Metric Type',
    sortable: true,
    resizable: true,
    minWidth: 230,
    comparator: caseInsensitiveComparator(),
    ...multiFilter,
  },
  {
    field: 'aggregationMethod',
    headerName: 'Aggregation Method',
    sortable: true,
    resizable: true,
    minWidth: 260,
    valueGetter: ({ data }) => getAggregationMethodName(data.aggregationMethod),
    comparator: caseInsensitiveComparator(),
    ...multiFilter,
  },
  {
    field: 'customTags',
    headerName: 'Tags',
    sortable: true,
    resizable: true,
    minWidth: 260,
    valueGetter: (params) => params.data?.tags?.map((tag: string) => tag) || [],
    // valueFormatter: (params) => params.value.length ? params.value.join(', ') : '(no tag)',
    ...multiFilter,
  },
  {
    field: 'updated',
    headerName: 'Last Updated',
    sortable: true,
    resizable: true,
    minWidth: 250,
    valueFormatter: ({ value }) => value ? formatDateStringToDisplay(value) : '-',
    comparator: dateComparator(),
    ...dateFilter,
  },
  {
    field: 'created',
    headerName: 'Created',
    sortable: true,
    resizable: true,
    minWidth: 250,
    valueFormatter: ({ value }) => value ? formatDateStringToDisplay(value) : '-',
    comparator: dateComparator(),
    ...dateFilter,
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    sortable: true,
    resizable: true,
    ...multiFilter,
    minWidth: 250,
    valueGetter: ({ data }) => `${data.createdBy?.firstName || ''} ${data.createdBy?.lastName || ''}`,
  },
]);
