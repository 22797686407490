import {
  useEffect,
  useState,
} from 'react';
import { LeftPane } from 'components/Layout';
import Dropzone from 'react-dropzone';
import { SectionLabel } from 'components/common/SectionLabel';
import { LoadingBar } from 'components/common/LoadingBar';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import { useUnpivotMutation } from 'store/services/integrations';
import {
  LoadingBarContainer,
  StyledDropdownHelperText,
  StyledDropdownMainText,
  StyledDropzoneContainer,
  StyledWrapper,
} from 'components/BulkImport/Upload/styled';
import { toastSuccess } from 'utils/toast';
import { Button } from 'components/common/Button';
import { FileIcon } from 'assets/icons';
import { UNPIVOT_TEMPLATE_FILENAME } from 'utils/constants';
import { generateTimestampQuery } from 'utils/files';

export const UnpivotTool = () => {
  const [uploadProgress, setUploadProgress] = useState({ percent: 0, remaining: 0 });
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const [unpivotFile, { isLoading }] = useUnpivotMutation();

  const handleUploadFile = (acceptedFile: File) => {
    const formData = new FormData();

    formData.append('file', acceptedFile);
    formData.append('type', acceptedFile.type);
    setIsProcessingFile(false);
    setUploadProgress({ percent: 0, remaining: 0 });

    unpivotFile({
      formData,
      onProgress: (percent, remaining) => setUploadProgress({
        percent: Math.round(percent),
        remaining: Math.round(remaining / 1000),
      }),
      name: acceptedFile.name,
    })
      .unwrap()
      .then(() => {
        toastSuccess('File successfully unpivoted and downloaded.', {
          autoClose: 10000,
        });
      });
  };

  const handleDownloadTemplate = () => {
    window.open(import.meta.env.VITE_APP_CDN_URL + UNPIVOT_TEMPLATE_FILENAME + generateTimestampQuery());
  };

  useEffect(() => {
    if (uploadProgress.percent === 100) {
      setTimeout(() => {
        setIsProcessingFile(true);
      }, 2000);
    }
  }, [uploadProgress]);

  return (
    <LeftPane>
      <ButtonsContainer paddingBottom={20}>
        <Breadcrumbs>
          <Breadcrumb link="/data-input">Data Sources</Breadcrumb>
          <Breadcrumb>Unpivot Tool</Breadcrumb>
        </Breadcrumbs>
      </ButtonsContainer>
      <ButtonsContainer>
        <SectionLabel>
          Unpivot Tool
        </SectionLabel>
        <Spacer />
        <Button
          onClick={handleDownloadTemplate}
          variant="borderless"
          size="large"
        >
          <FileIcon />
          Download Template
        </Button>
      </ButtonsContainer>
      <StyledWrapper>
        <div>
          <StyledDropdownMainText>Upload File</StyledDropdownMainText>
          <Dropzone
            disabled={isLoading}
            multiple={false}
            onDrop={acceptedFile => handleUploadFile(acceptedFile[0])}
            maxSize={524288000} // 500mb file limit
            accept={{
              'text/csv': ['.csv'],
            }}
          >
            {({
              getRootProps,
              getInputProps,
              isDragActive,
              isDragReject,
            }) => (
              <StyledDropzoneContainer {...getRootProps({ isDragActive, isDragReject })}>
                <input {...getInputProps()} />
                <StyledDropdownMainText>Drop your CSV file here or <em>browse</em></StyledDropdownMainText>
                <StyledDropdownHelperText>Max file size: [500mb]</StyledDropdownHelperText>
              </StyledDropzoneContainer>
            )}
          </Dropzone>
          <StyledDropdownHelperText>Supported files: .csv</StyledDropdownHelperText>
        </div>
      </StyledWrapper>
      {isLoading && (
        <StyledWrapper>
          <div>
            <StyledDropdownMainText>
              {isProcessingFile
                ? 'Processing file...'
                : 'File uploading...'
              }
            </StyledDropdownMainText>
            <LoadingBarContainer>
              <LoadingBar progress={isProcessingFile ? undefined : uploadProgress.percent} />
            </LoadingBarContainer>
            <StyledDropdownHelperText>
              {uploadProgress.percent}%
              {' | '}
              {isProcessingFile
                ? 'Processing file...'
                : `${uploadProgress.remaining} seconds remaining`
              }
            </StyledDropdownHelperText>
          </div>
        </StyledWrapper>
      )}
    </LeftPane>
  );
};
