import styled, { css } from "styled-components";
import { theme } from "theme/theme";
import { Link } from "react-router-dom";

export const StyledRemove = styled.div`
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  transition: opacity 300ms ease;

  button {
    border-radius: 0 8px 0 8px;
    color: ${theme.colors.textDisabled};
  }
`;

export const StyledDragDrop = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 300ms ease;

  button {
    border-radius: 8px 0 8px 0;
    color: ${theme.colors.textDisabled};

    svg {
      transform: rotate(90deg);
    }
  }
`;

export const StyledSummaryItem = styled.div<{ over: boolean }>`
  position: relative;
  overflow: hidden;
  border: 1px solid ${props => props.over ? theme.colors.primary : theme.colors.borderColorDark};
  border-radius: 8px;
  padding: 24px;
  min-height: 160px;

  ${props => props.over && css`
    > * {
      opacity: 0.2;
    }
  `}

  &:hover ${StyledDragDrop},
  &:hover ${StyledRemove} {
    opacity: 1;
    pointer-events: all;
  }
`;

export const StyledChart = styled.div`
  position: absolute;
  top: 56px;
  right: 16px;
  bottom: 16px;
  left: 0;
`;

export const StyledSummaryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 40px;

  + div {
    margin-top: 40px;
  }
`;

export const StyledPlaceholderItem = styled.div`
  @keyframes backgroundColor {
    0% {
      background-color: ${theme.colors.white};
    }
    50% {
      background-color: ${theme.colors.borderColorLight};
    }
    100% {
      background-color: ${theme.colors.white};
    }
  }

  border: none !important;
  border-radius: 8px;
  padding: 24px;
  min-height: 170px;
  animation-name: backgroundColor;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;

  &:nth-of-type(1) {
    animation-delay: 100ms;
  }
  &:nth-of-type(2) {
    animation-delay: 200ms;
  }
  &:nth-of-type(3) {
    animation-delay: 300ms;
  }
`;

export const StyledSummaryItemLabel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  color: ${theme.colors.textDark};
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;

  span {
    margin-right: 8px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;
export const StyledSummaryItemValue = styled.div<{ basicSize: number, hover?: boolean }>`
  position: relative;
  color: ${props => props.hover ? theme.colors.primary40 : theme.colors.textDark};
  font-size: ${props => props.basicSize}px;
  line-height: 51px;
  font-weight: 600;
  margin: 8px 0;
  transition: all 300ms ease;
`;

export const StyledSummaryItemDate = styled.div<{ hover?: boolean }>`
  color: ${props => props.hover ? theme.colors.primary40 : theme.colors.textDisabled};
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
`;

export const StyledChange = styled.div<{ change: number }>`
  display: flex;
  align-items: center;
  color: ${props => props.change > 0 ? theme.colors.success : props.change < 0 ? theme.colors.error : theme.colors.textDisabled};
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  margin-left: auto;
  padding-left: 8px;

  svg {
    transform: ${props => props.change > 0
    ? 'rotate(-45deg)'
    : props.change < 0
      ? 'rotate(45deg)'
      : 'unset'};
    margin-right: 4px;
    width: 18px;
    height: 18px;
  }
`;

export const StyledLink = styled(Link)`
  color: ${theme.colors.textDark};

  &:visited {
    color: ${theme.colors.textDark};
  }

  &:hover {
    color: ${theme.colors.primary};
  }
`;
