import {
  useEffect,
  useMemo,
  useState,
} from "react";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  CloseIcon,
  DeleteIcon,
} from 'assets/icons';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import { SectionLabel } from 'components/common/SectionLabel';
import { Button } from 'components/common/Button';
import { InputBasic } from 'components/common/Input';
import {
  StyledButtonContainer,
  StyledContainer,
  StyledFormContainer,
  StyledWrapper,
} from 'components/common/Forms/styled';
import { actionBlocker } from 'utils/actionBlocker';
import { RadioInput } from 'components/common/RadioInput';
import {
  MetricCategory,
  MetricFormFields,
  MetricOrigin,
  UpdateMetricRequest,
} from 'interfaces/metrics';
import { Dropdown } from 'components/common/Dropdown';
import {
  useGetAllMetricTypesQuery,
  useGetAllMetricsQuery,
  useGetMetricQuery,
  useUpdateMetricMutation,
} from "store/services/metrics";
import { toastSuccess } from "utils/toast";
import {
  MetricAggregationMethodOptions,
  MetricCategoryOptions,
} from "utils/constants";
import { Loading } from "components/Loading";
import { StyledLookback } from "./styled";
import { CheckboxInput } from "components/common/CheckboxInput";
import { Tooltip } from "components/common/Tooltip";
import { metricStartDateOptions } from "utils/metrics";
import { featureSwitch } from "utils/featureSwitch";

interface Props {
  metricId: string;
  onClose: () => void;
}

export const EditMetric = ({
  metricId,
  onClose,
}: Props) => {
  const [selectedCategory, setSelectedCategory] = useState(MetricCategory.SAAS);
  const { data: metric, isLoading: isLoadingMetric } = useGetMetricQuery(metricId);
  const { data: metricTypes, isLoading: isLoadingMetricTypes } = useGetAllMetricTypesQuery();
  const { data: metrics } = useGetAllMetricsQuery();
  const allMetricsNames = metrics?.map((metric) => metric.name.toLowerCase())
    .filter((name) => name !== metric?.name.toLowerCase()) || [];

  const options = useMemo(() => [...new Set(metrics?.filter((metric) => metric.tags)
    .flatMap((metric) => metric.tags))]
    .map((metric) => ({
      value: metric,
    })), [metrics]);

  const metricTypesByCategory = useMemo(() => metricTypes?.filter((mt) => mt.category === selectedCategory) || [],
    [metricTypes, selectedCategory]);

  const [updateMetric, { isLoading }] = useUpdateMetricMutation();

  const schema = yup.object({
    name: yup.string()
      .required('Metric Name is required.')
      .test(
        'notOneOfCaseInsensitive',
        'Metric with this name already exists.',
        (val) => !allMetricsNames.includes(val?.toLowerCase() || ''),
      ),
    description: yup.string()
      .max(250, 'Maximum of 250 characters allowed.'),
    metricType: yup.array()
      .of(yup.object())
      .min(1, 'Metric Type is required.'),
    aggregationMethod: yup.array()
      .of(yup.object())
      .min(1, 'Aggregation Method is required.'),
    lookbackPeriod: yup.number()
      .when('lookbackYTD', {
        is: (val: boolean) => !val,
        then: yup.number()
          .min(1, 'Period is required')
          .max(36, 'Max 36 months')
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable(),
      })
      .nullable(),
    lookbackYTD: yup.boolean(),
  })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors, isDirty },
    watch,
  } = useForm<MetricFormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: metric?.name,
      description: metric?.description,
      category: { name: metric?.type.category, value: metric?.type.category },
      aggregationMethod: [MetricAggregationMethodOptions.find((am) => am.value === metric?.aggregationMethod)],
      metricType: [metric?.type],
      tags: metric?.tags ? metric?.tags.map((tagName) => ({ value: tagName })) : [],
      lookbackPeriod: metric?.filters.lookback?.period || null,
      lookbackYTD: metric?.filters.lookback?.ytd || false,
      startDateField: { name: metric?.startDateField, value: metric?.startDateField },
    },
  });

  const handleSaveAndClose = handleSubmit((data) => {
    const payload: UpdateMetricRequest = {
      metricId: metricId,
      name: data.name,
      description: data.description,
      type: data.metricType[0].id,
      aggregationMethod: data.aggregationMethod[0].value,
      tags: data.tags.map((tag) => tag.value),
      recurringRevenueType: 'MRR',
      filters: {
        lookback: data.lookbackPeriod || data.lookbackYTD
          ? {
            period: data.lookbackPeriod ? Number(data.lookbackPeriod) : null,
            ytd: data.lookbackYTD,
          }
          : null,
        customerFilter: metric!.filters.customerFilter.map(({ id }) => id),
        customerTagFilter: metric!.filters.customerTagFilter.map(({ id }) => id),
        productFilter: metric!.filters.productFilter.map(({ id }) => id),
        productTagFilter: metric!.filters.productTagFilter.map(({ id }) => id),
        contractTagFilter: metric!.filters.contractTagFilter.map(({ id }) => id),
        contractLineTagFilter: metric!.filters.contractLineTagFilter.map(({ id }) => id),
        revenueTypeFilter: metric!.filters.revenueTypeFilter,
      },
      startDateField: data.startDateField.value,
    };

    return updateMetric(payload)
      .unwrap()
      .then(() => {
        toastSuccess('Metric successfully updated.');
        onClose();
      });
  });

  useEffect(() => {
    if (metric) {
      setSelectedCategory(metric.type.category);
    }
  }, [metric]);

  if (isLoadingMetricTypes || isLoadingMetric) return <Loading />;

  if (!metricTypes || !metrics || !metric) {
    return <>Error fetching metrics or metric types.</>;
  }

  return (
    <StyledContainer>
      <ButtonsContainer
        paddingBottom={40}
        alignTop
      >
        <SectionLabel
          data-cy="am-header"
          marginBottom={0}
        >
          <span>
            Edit Metric
          </span>
        </SectionLabel>
        <Spacer />
        <Button
          aria-label="Close"
          variant="icon"
          size="large"
          onClick={() => actionBlocker(onClose, isDirty)}
          data-cy="am-button-close"
        >
          <CloseIcon />
        </Button>
      </ButtonsContainer>
      <StyledWrapper>
        <StyledFormContainer onSubmit={handleSaveAndClose}>
          <InputBasic
            isRequired
            labelText="Metric Name"
            placeholder="Add metric name"
            {...register('name')}
            onBlur={(e) => {
              const fieldValue = e.target.value;

              if (fieldValue) {
                setValue('name', fieldValue.trim(), { shouldValidate: true });
              }
            }}
            error={errors.name?.message}
            dataCy="am-input-name"
          />
          <InputBasic
            isRequired
            labelText="Description"
            placeholder="Description"
            {...register('description')}
            onBlur={(e) => {
              const fieldValue = e.target.value;

              if (fieldValue) {
                setValue('description', fieldValue.trim(), { shouldValidate: true });
              }
            }}
            error={errors.description?.message}
            dataCy="am-input-description"
          />
          <Controller
            name="category"
            control={control}
            render={({ field }) =>
              <RadioInput
                isRequired
                labelText="Metric Category"
                valueField="value"
                labelField="name"
                defaultValue={getValues('category')}
                options={MetricCategoryOptions}
                {...field}
                error={errors.category?.message}
                disabled={metric?.origin === MetricOrigin.SYSTEM || metric.origin === MetricOrigin.SYSTEM_COPY}
                onChange={(value) => {
                  if (metric?.origin === MetricOrigin.CUSTOM) {
                    const metricType = metricTypes!.find((mt) => mt.name === 'Custom' && mt.category === value.name);
                    setValue('metricType', metricType ? [metricType] : []);
                  } else if (metric?.origin === MetricOrigin.MANUAL) {
                    const metricType = metricTypes!.find((mt) => mt.name === 'Manual' && mt.category === value.name);
                    setValue('metricType', metricType ? [metricType] : []);
                  }
                  field.onChange(value);
                }}
                tooltip={metric?.origin !== MetricOrigin.CUSTOM
                  ? 'Not editable for System Metrics.'
                  : ''
                }
              />
            }
          />
          <Controller
            name="metricType"
            control={control}
            render={({ field }) =>
              <Dropdown
                labelText="Metric Type"
                options={metricTypesByCategory}
                labelField="name"
                valueField="value"
                searchBy="value"
                placeholder="Select Metric Type"
                values={getValues('metricType')}
                {...field}
                error={errors.metricType?.message}
                dropdownPosition="top"
                disabled
                tooltip={metric?.origin === MetricOrigin.CUSTOM
                  ? 'Custom Metrics will always have it set to Custom.'
                  : 'Not editable for System Metrics.'
                }
              />
            }
          />
          {metric.origin !== MetricOrigin.MANUAL && (
            <StyledLookback>
              <InputBasic
                labelText="Lookback Period"
                isOptional
                {...register('lookbackPeriod')}
                type="number"
                max={36}
                min={1}
                placeholder="None "
                disabled={!!watch('lookbackYTD')}
                error={errors.lookbackPeriod?.message}
              />
              <span>
                month(s)
              </span>
              <Controller
                name="lookbackYTD"
                control={control}
                render={({ field }) =>
                  <CheckboxInput
                    isOptional
                    checkboxLabel="YTD"
                    {...field}
                    defaultValue={getValues('lookbackYTD')}
                    error={errors.lookbackYTD?.message}
                    onChange={(e) => {
                      setValue('lookbackPeriod', null);
                      field.onChange(e);
                    }}
                  />
                }
              />
              <Spacer />
              <Tooltip title="Clear Lookback">
                <Button
                  variant="icon"
                  onClick={((e) => {
                    e.preventDefault();
                    setValue('lookbackPeriod', null);
                    setValue('lookbackYTD', false);
                  })}
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </StyledLookback>
          )}
          <Controller
            name="startDateField"
            control={control}
            render={({ field }) =>
              <RadioInput
                isRequired
                labelText="Metric Start Date"
                valueField="value"
                labelField="name"
                defaultValue={getValues('startDateField')}
                options={metricStartDateOptions}
                {...field}
                onChange={(value) => {
                  field.onChange(value);
                }}
                error={errors.startDateField?.message}
              />
            }
          />
          <Controller
            name="aggregationMethod"
            control={control}
            render={({ field }) =>
              <Dropdown
                isRequired
                labelText="Aggregation Method"
                options={MetricAggregationMethodOptions}
                labelField="name"
                valueField="value"
                searchBy="name"
                placeholder="Select Aggregation Method"
                values={getValues('aggregationMethod')}
                {...field}
                error={errors.aggregationMethod?.message}
                dropdownPosition="top"
                tooltip={metric.origin === MetricOrigin.MANUAL && !featureSwitch.manualMetrics
                  ? "Temporarily unavailable."
                  : "This setting determines how other metrics aggregate data from this metric. All metrics default to the ending balance."}
              />
            }
          />
          <Controller
            name="tags"
            control={control}
            render={({ field }) =>
              <Dropdown
                tooltip="Start typing to add new tag"
                labelText="Tags"
                options={options}
                labelField="value"
                valueField="value"
                searchBy="value"
                placeholder="Select Tags"
                entityName="tag"
                values={getValues('tags')}
                {...field}
                error={errors.tags?.message}
                dropdownPosition="top"
                create
                multi
              />
            }
          />
        </StyledFormContainer>
      </StyledWrapper>
      <StyledButtonContainer pushRight>
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          onClick={() => onClose()}
          disabled={isLoading}
          data-cy="em-button-back"
        >
          CANCEL
        </Button>
        <Button
          type="button"
          onClick={handleSaveAndClose}
          disabled={isLoading}
          isLoading={isLoading}
          data-cy="em-button-save"
        >
          SAVE METRIC
        </Button>
      </StyledButtonContainer>
    </StyledContainer>
  );
};
