import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as yup from "yup";
import {
  BlockIcon,
  ChevronDown,
  CloseIcon,
  CopyIcon,
  DeleteIcon,
  LinkIcon,
  ReloadIcon,
  UnlinkIcon,
} from "assets/icons";
import { Button } from "components/common/Button";
import { ButtonsContainer } from "components/common/ButtonsContainer";
import {
  StyledAdditionalOptionsContainer,
  StyledButtonContainer,
  StyledExpandButton,
  StyledFormContainer,
  StyledFormSectionLabel,
} from "components/common/Forms/styled";
import {
  Contract,
  ContractConnection,
  ContractFormFields,
  ContractStateFormFields,
} from "interfaces/contracts";
import { actionBlocker } from "utils/actionBlocker";
import { TagType } from 'interfaces/tags';
import {
  useLazyContractsUniquenessQuery,
  useUpdateContractMutation,
} from 'store/services/contracts';
import { useGetAllTagsQuery } from 'store/services/tags';
import {
  getTagsIdsForSelectedRows,
  tagsSortFn,
} from 'utils/tags';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { Datepicker } from 'components/common/Datepicker';
import { Dropdown } from 'components/common/Dropdown';
import { InputBasic } from 'components/common/Input';
import { SectionLabel } from 'components/common/SectionLabel';
import {
  parseContractFormData,
  parseStateFormData,
  prepareContractUpdatePayload,
} from 'utils/contracts';
import { toastSuccess } from 'utils/toast';
import { isoToDate } from 'utils/dates';
import { copyToClipboard } from 'utils/copyToClipboard';
import { Tooltip } from 'components/common/Tooltip';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import { formStateActions } from 'store/slices/formState';
import { formDataSelector } from 'store/selectors/formState';
import { useUniquenessCheck } from 'hooks/useUniquenessCheck';
import { CustomersDropdownLazy } from 'components/common/CustomersDropdownLazy';
import {
  StyledHistory,
  StyledHistoryEntry,
} from './styled';
import { contractsActions } from 'store/slices/contracts';
import { CheckboxInput } from 'components/common/CheckboxInput';

interface Props {
  onClose: () => void;
  onSuccess?: (id?: string) => void;
  onSave: (id: string) => void;
  onDelete: () => void;
  onCancelContract?: () => void;
  onRenewContract?: () => void;
  onLinkContract?: () => void;
  selectedRows: Contract[];
  onAddTag: () => void;
  onAddCustomer: () => void;
  onUnlinkContract: () => void;
}

export const EditContracts = ({
  onClose,
  onSuccess,
  onSave,
  onDelete,
  onCancelContract,
  onRenewContract,
  onLinkContract,
  selectedRows,
  onAddTag,
  onAddCustomer,
  onUnlinkContract,
}: Props) => {
  const dispatch = useAppDispatch();
  const formData = useAppSelector(formDataSelector);
  const [isOptionsExpanded, setIsOptionsExpanded] = useState(false);
  const isBulkEdit = selectedRows.length > 1;
  const [updateContract, { isLoading }] = useUpdateContractMutation();
  const [contractsUnique, { isFetching: isCheckingUniqueness }] = useLazyContractsUniquenessQuery();

  const selectedRowsTagsIds = useMemo(() => getTagsIdsForSelectedRows(selectedRows), [selectedRows]);

  const {
    tagOptions,
  } = useGetAllTagsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      tagOptions: data
        ?.filter((tag) => tag.type === TagType.CONTRACT && (!tag.deleted || selectedRowsTagsIds.includes(tag.id)))
        ?.sort(tagsSortFn) || [],
    }),
  });

  const schema = yup.object({
    name: yup.string()
      .required('Contract name is required.')
      .test(
        'isUnique',
        'Contract name must be unique.',
        () => isNameUnique,
      ),
    customer: yup.array()
      .of(
        yup.object({
          id: yup.string(),
          name: yup.string(),
        }))
      .min(1, 'Customer is required.'),
    startDate: yup.date()
      .nullable()
      .required('Start date is required.'),
    startDateApplyOnItems: yup.boolean(),
    endDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'End date cannot be before start date.'),
      })
      .required('End date is required.'),
    bookingDate: yup.date()
      .nullable()
      .when('endDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .max(yup.ref('endDate'),
            'Booking date cannot be after end date.'),
      })
      .required('Booking date is required.'),
    cancelDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'Cancel date cannot be before start date.',
          ),
      }),
    crmId: yup.string(),
    externalLink: yup.string()
      .url('Please provide valid URL.'),
    notes: yup.string(),
  })
    .required();

  const defaultValues = useMemo(() => ({
    ...(formData
      ? {
        ...parseStateFormData(formData as ContractStateFormFields),
      }
      : {
        name: '',
        customer: [],
        startDate: undefined,
        endDate: undefined,
        bookingDate: undefined,
        cancelDate: undefined,
        crmId: '',
        tags: [],
        externalLink: '',
        notes: '',
      }
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty, dirtyFields },
    trigger,
    setValue,
    reset,
  } = useForm<ContractFormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const [isNameUnique, setName] = useUniquenessCheck(contractsUnique, {}, 'name', trigger, [selectedRows[0]?.name]);

  const triggerTouched = (field: any) => ((dirtyFields as Record<string, boolean | undefined>)[field]) && trigger(field);

  const handleClickRenew = onRenewContract ? onRenewContract : () => { };
  const handleClickLink = onLinkContract ? onLinkContract : () => { };
  const handleClickCancel = onCancelContract;
  const handleClickDelete = onDelete;

  const handleSaveAndClose = handleSubmit((data) => {
    const contract = prepareContractUpdatePayload(data);

    return updateContract(contract)
      .unwrap()
      .then((result) => {
        toastSuccess('Contract successfully updated.');
        onSuccess && onSuccess(result.id);
        onClose();
      });
  });

  const handleSave = handleSubmit((data) => {
    const product = prepareContractUpdatePayload(data);

    return updateContract(product)
      .unwrap()
      .then((result) => {
        onSuccess && onSuccess(result.id);
        toastSuccess('Contract successfully updated.');
        onSave(result.id);
      });
  });

  const handleAddTag = ({ name }: { name: string }) => {
    dispatch(formStateActions.setPredefinedData({
      name,
      type: TagType.CONTRACT,
    }));
    dispatch(formStateActions.setFormData(parseContractFormData(getValues())));
    onAddTag();
  };

  const handleAddCustomer = ({ name }: { name: string }) => {
    if (onAddCustomer) {
      dispatch(formStateActions.setPredefinedData({ name }));
      dispatch(formStateActions.setFormData(parseContractFormData(getValues())));
      onAddCustomer();
    }
  };

  const handleUnlinkContract = (type: 'next' | 'prev', contract: ContractConnection) => {
    const payload = {
      contractId: selectedRows[0]?.id,
      nextContractIds: type === 'next' ? [contract.id] : [],
      prevContractIds: type === 'prev' ? [contract.id] : [],
      unlinkedContractName: contract.name,
    };
    dispatch(contractsActions.setContractUnlinkPayload(payload));
    onUnlinkContract();
  };

  useEffect(() => {
    const selectedRow = selectedRows[0];

    if (isBulkEdit) {
      reset(defaultValues);
    } else if (selectedRow && !formData) {
      reset({
        id: selectedRow.id,
        name: selectedRow.name,
        customer: [selectedRow.customer],
        startDate: isoToDate(selectedRow.startDate),
        startDateApplyOnItems: false,
        endDate: isoToDate(selectedRow.endDate),
        endDateApplyOnItems: false,
        bookingDate: isoToDate(selectedRow.bookingDate),
        bookingDateApplyOnItems: false,
        cancelDate: isoToDate(selectedRow.cancelDate),
        cancelDateApplyOnItems: false,
        crmId: selectedRow.crmId || '',
        tags: selectedRow.tags,
        externalLink: selectedRow.externalLink || '',
        notes: selectedRow.notes || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows, isBulkEdit]);

  return (
    <>
      <header>
        <ButtonsContainer paddingBottom={16}>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={() => actionBlocker(onClose, isDirty)}
            pushRight
            data-cy="eco-button-close"
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <StyledFormSectionLabel data-cy="eco-header">
          <span>
            {isBulkEdit ? 'Bulk Edit' : 'Edit Contract'}
          </span>
          {isBulkEdit
            ? (
              <span>{selectedRows.length} Contracts</span>
            )
            : (
              <ButtonsContainer>
                <Button
                  variant="simple"
                  size="large"
                  type="button"
                  onClick={(e) => copyToClipboard(e, selectedRows[0].id)}
                  data-cy="eco-button-id"
                >
                  <span>
                    Insights Contract ID
                  </span>
                  <CopyIcon />
                </Button>
              </ButtonsContainer>
            )}
        </StyledFormSectionLabel>
        <ButtonsContainer paddingBottom={20}>
          <Tooltip title="Renew Contract">
            <Button
              variant="icon"
              color="primary"
              size="large"
              onClick={handleClickRenew}
              aria-label="Renew"
              data-cy="eco-button-renew"
            >
              <ReloadIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Link Contract">
            <Button
              variant="icon"
              color="primary"
              size="large"
              onClick={handleClickLink}
              aria-label="Link"
              data-cy="eco-button-link"
              disabled={selectedRows.length > 1}
            >
              <LinkIcon />
            </Button>
          </Tooltip>
          <Tooltip title={selectedRows?.length > 1 ? 'Cancel Contracts' : 'Cancel Contract'}>
            <Button
              variant="icon"
              color="primary"
              size="large"
              onClick={handleClickCancel}
              aria-label="Cancel"
              data-cy="eco-button-cancel"
            >
              <BlockIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              variant="icon"
              color="primary"
              size="large"
              onClick={handleClickDelete}
              aria-label="Delete"
              data-cy="eco-button-delete"
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        </ButtonsContainer>
      </header>

      {!isBulkEdit && (
        <>
          <main>
            <StyledFormContainer>
              {(selectedRows[0]?.previous?.length > 0 || selectedRows[0]?.next.length > 0) && (
                <SectionLabel secondary>
                  Contract History
                </SectionLabel>
              )}
              {selectedRows[0]?.previous?.length > 0 && (
                <StyledHistory>
                  <section>Previous Contract{selectedRows[0]?.previous?.length > 1 && 's'}</section>
                  {selectedRows[0]?.previous?.map((prev) => (
                    <StyledHistoryEntry key={`hist_${prev.id}`}>
                      <span>{prev.name}</span>
                      <Tooltip title="Unlink this contract">
                        <Button
                          variant="icon"
                          color="primary"
                          size="normal"
                          onClick={() => handleUnlinkContract('prev', prev)}
                        >
                          <UnlinkIcon />
                        </Button>
                      </Tooltip>
                    </StyledHistoryEntry>
                  ))}
                </StyledHistory>
              )}
              {selectedRows[0]?.next?.length > 0 && (
                <StyledHistory>
                  <section>Next Contract{selectedRows[0]?.next?.length > 1 && 's'}</section>
                  {selectedRows[0]?.next?.map((next) => (
                    <StyledHistoryEntry key={`hist_${next.id}`}>
                      <span>{next.name}</span>
                      <Tooltip title="Unlink this contract">
                        <Button
                          variant="icon"
                          color="primary"
                          size="normal"
                          onClick={() => handleUnlinkContract('next', next)}
                        >
                          <UnlinkIcon />
                        </Button>
                      </Tooltip>
                    </StyledHistoryEntry>
                  ))}
                </StyledHistory>
              )}
              <SectionLabel secondary>
                Contract Overview
              </SectionLabel>
              <InputBasic
                isRequired
                labelText="Contract Name"
                placeholder="Name your contract"
                {...register('name')}
                onBlur={(e) => {
                  const fieldValue = e.target.value;

                  if (fieldValue) {
                    setValue('name', fieldValue.trim(), { shouldValidate: true });
                  }
                }}
                onCustomChange={(e) => setName(e.target.value)}
                error={errors.name?.message}
                tooltip="You can change the contract name, but it must be a unique value."
                isLoading={isCheckingUniqueness}
              />
              <Controller
                name="customer"
                control={control}
                render={({ field }) =>
                  <CustomersDropdownLazy
                    labelText="Customer"
                    labelField="name"
                    valueField="id"
                    searchBy="name"
                    placeholder="Select Customer"
                    values={getValues('customer')}
                    {...field}
                    error={errors.customer?.message}
                    isRequired
                    create
                    onCreateNew={handleAddCustomer}
                    dataCy="eco-dropdown-customer"
                    rowSelectedOption={selectedRows[0]?.customer}
                  />
                }
              />
              <div>
                <Controller
                  name="bookingDate"
                  control={control}
                  defaultValue={undefined}
                  render={({ field }) =>
                    <Datepicker
                      labelText="Contract Booking Date"
                      placeholder="Select Date"
                      {...field}
                      onChange={(date) => {
                        field.onChange(date);
                        triggerTouched('startDate');
                        triggerTouched('endDate');
                        triggerTouched('cancelDate');
                      }}
                      error={errors.bookingDate?.message}
                      isRequired
                      data-cy="eco-datepicker-booking"
                      tooltip="Contract booking date is used to indicate when this contract was initially booked."
                    />
                  }
                />
                <CheckboxInput
                  {...register('bookingDateApplyOnItems')}
                  error={errors.bookingDateApplyOnItems?.message}
                  checkboxLabel="Apply also to all existing Contract Lines"
                  defaultValue={!!getValues().bookingDateApplyOnItems}
                  small
                />
              </div>
              <div>
                <Controller
                  name="startDate"
                  control={control}
                  defaultValue={undefined}
                  render={({ field }) =>
                    <Datepicker
                      labelText="Contract Start Date"
                      placeholder="Select Date"
                      {...field}
                      onChange={(date) => {
                        field.onChange(date);
                        triggerTouched('bookingDate');
                        triggerTouched('endDate');
                        triggerTouched('cancelDate');
                      }}
                      error={errors.startDate?.message}
                      isRequired
                      data-cy="eco-datepicker-start"
                      tooltip="The contract start date is used to indicate the beginning of service for the overall contract. It is used to calculate the term for ARR, MRR, and other SaaS metrics"
                    />
                  }
                />
                <CheckboxInput
                  {...register('startDateApplyOnItems')}
                  error={errors.startDateApplyOnItems?.message}
                  checkboxLabel="Apply also to all existing Contract Lines"
                  defaultValue={!!getValues().startDateApplyOnItems}
                  small
                />
              </div>
              <div>
                <Controller
                  name="endDate"
                  control={control}
                  defaultValue={undefined}
                  render={({ field }) =>
                    <Datepicker
                      labelText="Contract End Date"
                      placeholder="Select Date"
                      {...field}
                      onChange={(date) => {
                        field.onChange(date);
                        triggerTouched('bookingDate');
                        triggerTouched('startDate');
                        triggerTouched('cancelDate');
                      }}
                      error={errors.endDate?.message}
                      tooltip="Contract end date is used to calculate the term for ARR, MRR, and other SaaS metrics. This contract will remain active until it is renewed or cancelled."
                      isRequired
                      data-cy="eco-datepicker-end"
                    />
                  }
                />
                <CheckboxInput
                  {...register('endDateApplyOnItems')}
                  error={errors.endDateApplyOnItems?.message}
                  checkboxLabel="Apply also to all existing Contract Lines"
                  defaultValue={!!getValues().endDateApplyOnItems}
                  small
                />
              </div>
              <div>
                <Controller
                  name="cancelDate"
                  control={control}
                  defaultValue={undefined}
                  render={({ field }) =>
                    <Datepicker
                      labelText="Contract Cancel Date"
                      placeholder="Select Date"
                      {...field}
                      onChange={(date) => {
                        field.onChange(date);
                        triggerTouched('bookingDate');
                        triggerTouched('startDate');
                        triggerTouched('endDate');
                      }}
                      error={errors.cancelDate?.message}
                      isOptional
                      data-cy="eco-datepicker-cancel"
                      tooltip="Entering a contract cancel date will terminate the overall contract upon that date. Unless renewed, this contract will be counted as churn after this date."
                    />
                  }
                />
                <CheckboxInput
                  {...register('cancelDateApplyOnItems')}
                  error={errors.cancelDateApplyOnItems?.message}
                  checkboxLabel="Apply also to all existing Contract Lines"
                  defaultValue={!!getValues().cancelDateApplyOnItems}
                  small
                />
              </div>
              <StyledExpandButton
                variant="borderless"
                size="large"
                type="button"
                onClick={() => setIsOptionsExpanded(!isOptionsExpanded)}
                isOpen={isOptionsExpanded}
              >
                Additional Options
                <ChevronDown />
              </StyledExpandButton>
              <StyledAdditionalOptionsContainer isOpen={isOptionsExpanded}>
                <Controller
                  name="tags"
                  control={control}
                  render={({ field }) =>
                    <Dropdown
                      labelText="Tags"
                      options={tagOptions}
                      labelField="name"
                      valueField="id"
                      searchBy="name"
                      placeholder="Select Tags"
                      entityName="tag"
                      values={getValues('tags')}
                      {...field}
                      isOptional
                      clearable
                      multi
                      error={errors.tags?.message}
                      tooltip="Categorize this contract with a contract tag(s)."
                      create
                      onCreateNew={handleAddTag}
                      dataCy="eco-dropdown-tag"
                    />
                  }
                />
                <InputBasic
                  isOptional
                  labelText="CRM ID"
                  placeholder="Paste CRM identifier"
                  tooltip="Paste in an ID from your CRM to help identify this contract."
                  {...register('crmId')}
                  error={errors.crmId?.message}
                  data-cy="eco-input-crm"
                />
                <InputBasic
                  isOptional
                  labelText="External Link"
                  placeholder="Paste an external link"
                  tooltip="You can paste in a link to your CRM or wherever you store your contracts."
                  {...register('externalLink')}
                  error={errors.externalLink?.message}
                  onBlur={(e) => {
                    const fieldValue = e.target.value;

                    if (fieldValue && !fieldValue.startsWith('http')) {
                      setValue('externalLink', 'http://' + fieldValue, { shouldValidate: true });
                    }
                  }}
                  data-cy="eco-input-external"
                />
                <InputBasic
                  isOptional
                  labelText="Notes"
                  placeholder="Add a quick note"
                  {...register('notes')}
                  error={errors.notes?.message}
                  data-cy="eco-input-notes"
                  multiline
                />
              </StyledAdditionalOptionsContainer>
            </StyledFormContainer>
          </main>
          <footer>
            <StyledButtonContainer pushRight>
              <Button
                type="submit"
                variant="outlined"
                color="secondary"
                onClick={handleSave}
                disabled={isLoading || isCheckingUniqueness}
                isLoading={isLoading}
                data-cy="eco-button-save-back"
              >
                SAVE
              </Button>
              <Button
                type="submit"
                onClick={handleSaveAndClose}
                disabled={isLoading || isCheckingUniqueness}
                isLoading={isLoading}
                data-cy="eco-button-save"
              >
                SAVE & CLOSE
              </Button>
            </StyledButtonContainer>
          </footer>
        </>
      )}
    </>
  );
};
