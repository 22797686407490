import {
  caseInsensitiveComparator,
  dateComparator,
} from "utils/aggrid";
import {
  formatDateStringToDisplay,
  formatDateStringToMonthYearDisplay,
} from "utils/dates";
import { Tag } from "interfaces/tags";
import { AgGridCellRendererLink } from "components/common/AgGridCellRendererLink";
import { AgGridCommonCellRenderer } from "components/common/AgGridCommonCellRenderer";
import { ColGroupDef } from "@ag-grid-community/core";
import {
  dateFilter,
  multiFilter,
  numberFilter,
} from "utils/filters";

export const getColumnDefs = (revenueType: string): ColGroupDef<any>[] => ([
  {
    children: [
      {
        field: 'id',
        headerName: 'Id',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        cellRenderer: () => null,
        maxWidth: 70,
        pinned: 'left',
      },
    ],
  },
  {
    headerName: 'General',
    children: [
      {
        field: 'customer.name',
        headerName: 'Customer',
        sortable: true,
        resizable: true,
        cellRenderer: AgGridCommonCellRenderer('customer'),
        flex: 1,
        minWidth: 250,
        comparator: caseInsensitiveComparator(),
        enableRowGroup: true,
        ...multiFilter,
      },
      {
        field: 'product.name',
        headerName: 'Product',
        sortable: true,
        resizable: true,
        cellRenderer: AgGridCommonCellRenderer('product'),
        flex: 1,
        minWidth: 250,
        comparator: caseInsensitiveComparator(),
        enableRowGroup: true,
        ...multiFilter,
      },
      {
        field: 'product.revenueType',
        headerName: 'Revenue Type',
        sortable: true,
        resizable: true,
        minWidth: 220,
        comparator: caseInsensitiveComparator(),
        enableRowGroup: true,
        hide: revenueType !== 'all',
        ...multiFilter,
      },
      {
        field: 'name',
        headerName: 'Description',
        sortable: true,
        resizable: true,
        flex: 1,
        minWidth: 300,
        comparator: caseInsensitiveComparator(),
        ...multiFilter,
      },
    ],
  },
  {
    headerName: 'SaaS Metrics',
    children: [
      {
        field: 'mrr',
        headerName: 'MRR',
        sortable: true,
        type: 'currency', hide: revenueType === 'Nonrecurring',
        aggFunc: 'sum',
        ...numberFilter,
      },
      {
        field: 'arr',
        headerName: 'ARR',
        sortable: true,
        type: 'currency', hide: revenueType === 'Nonrecurring',
        aggFunc: 'sum',
        ...numberFilter,
      },
      {
        field: 'amount',
        headerName: 'Amount',
        sortable: true,
        type: 'currency',
        aggFunc: 'sum',
        ...numberFilter,
      },
    ],
  },
  {
    headerName: 'Dates',
    children: [
      {
        field: 'bookingDate',
        headerName: 'Booking Date',
        sortable: true,
        valueFormatter: ({ value }) => formatDateStringToDisplay(value),
        getQuickFilterText: ({ value }) => formatDateStringToDisplay(value),
        comparator: dateComparator(),
        enableRowGroup: true,
        ...dateFilter,
      },
      {
        field: 'startDate',
        headerName: 'Start Date',
        sortable: true,
        valueFormatter: ({ value }) => formatDateStringToDisplay(value),
        getQuickFilterText: ({ value }) => formatDateStringToDisplay(value),
        comparator: dateComparator(),
        enableRowGroup: true,
        hide: revenueType === 'Usage-based',
        ...dateFilter,
      },
      {
        field: 'startDateAsMonth',
        headerName: 'Start Date/Month',
        sortable: true,
        minWidth: 240,
        valueFormatter: ({ value }) => formatDateStringToMonthYearDisplay(value),
        getQuickFilterText: ({ value }) => formatDateStringToMonthYearDisplay(value),
        valueGetter: ({ data }) => data?.startDate ? data.startDate.substr(0, 7) : '',
        comparator: dateComparator(),
        enableRowGroup: true,
        hide: revenueType !== 'Usage-based',
      },
      {
        field: 'endDate',
        headerName: 'End Date',
        sortable: true,
        valueFormatter: ({ value }) => formatDateStringToDisplay(value),
        getQuickFilterText: ({ value }) => formatDateStringToDisplay(value),
        comparator: dateComparator(),
        enableRowGroup: true,
        hide: revenueType === 'Usage-based',
        ...dateFilter,
      },
      {
        field: 'cancelDate',
        headerName: 'Cancel Date',
        sortable: true,
        valueFormatter: ({ value }) => formatDateStringToDisplay(value) || '-',
        getQuickFilterText: ({ value }) => formatDateStringToDisplay(value),
        comparator: dateComparator(),
        enableRowGroup: true,
        hide: revenueType === 'Usage-based',
        ...dateFilter,
      },
      {
        field: 'revRecStartDate',
        headerName: 'Rev Rec Start Date',
        sortable: true,
        valueFormatter: ({ value }) => formatDateStringToDisplay(value) || '-',
        getQuickFilterText: ({ value }) => formatDateStringToDisplay(value),
        comparator: dateComparator(),
        enableRowGroup: true,
        ...dateFilter,
      },
      {
        field: 'revRecEndDate',
        headerName: 'Rev Rec End Date',
        sortable: true,
        valueFormatter: ({ value }) => formatDateStringToDisplay(value) || '-',
        getQuickFilterText: ({ value }) => formatDateStringToDisplay(value),
        comparator: dateComparator(),
        enableRowGroup: true,
        ...dateFilter,
      },
    ],
  },
  {
    headerName: 'Other',
    children: [
      {
        field: 'previous.name',
        headerName: 'Previous Revenue',
        sortable: true,
        resizable: true,
        minWidth: 260,
        valueFormatter: ({ value }) => value ? value : '-',
        comparator: caseInsensitiveComparator(),
        enableRowGroup: true,
      },
      {
        field: 'next.name',
        headerName: 'Next Revenue',
        sortable: true,
        resizable: true,
        minWidth: 250,
        valueFormatter: ({ value }) => value ? value : '-',
        comparator: caseInsensitiveComparator(),
        enableRowGroup: true,
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: true,
        resizable: true,
        minWidth: 200,
        comparator: caseInsensitiveComparator(),
        enableRowGroup: true,
        ...multiFilter,
      },
      {
        field: 'tags',
        headerName: 'Tags',
        sortable: true,
        resizable: true,
        minWidth: 200,
        getQuickFilterText: ({ value }) => value?.map((tag: Tag) => tag.name),
        valueGetter: (params) => params.data?.tags?.map((tag: Tag) => tag.name) || [],
        ...multiFilter,
      },
      {
        field: 'externalLink',
        headerName: 'External Link',
        sortable: true,
        cellRenderer: AgGridCellRendererLink,
      },
      {
        field: 'crmId',
        headerName: 'CRM ID',
        sortable: true,
        resizable: true,
        minWidth: 200,
        valueFormatter: ({ value }) => value ? value : '-',
        enableRowGroup: true,
        ...multiFilter,
      },
    ],
  },
]);