import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { CloseIcon } from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { SectionLabel } from 'components/common/SectionLabel';
import { Button } from 'components/common/Button';
import { InputBasic } from 'components/common/Input';
import {
  StyledButtonContainer,
  StyledFormContainer,
} from 'components/common/Forms/styled';
import { toastSuccess } from 'utils/toast';
import { actionBlocker } from 'utils/actionBlocker';
import {
  Company,
  DuplicateCompanyPayload,
} from 'interfaces/company';
import { CheckboxInput } from 'components/common/CheckboxInput';
import { useDuplicateCompanyMutation } from 'store/services/companies';
import { NestedCheckbox } from "./styled";
import { useEffect } from "react";

interface Props {
  onClose: () => void;
  onSuccess?: (result: Company) => void;
  selectedRows: Company[];
}

export const DuplicateCompany = ({
  onClose,
  onSuccess,
  selectedRows,
}: Props) => {
  const [duplicateCompany, { isLoading }] = useDuplicateCompanyMutation();
  const company = selectedRows[0];

  const schema = yup.object({
    name: yup.string()
      .required('Company name is required.'),
  })
    .required();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<{
    name: string;
    copyFactaAccounts: boolean;
    copyMetrics: boolean;
    copyReports: boolean;
    copyUsers: boolean;
    copyGLAccounts: boolean;
  }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: `${company?.name} Copy`,
      copyFactaAccounts: true,
      copyGLAccounts: false,
      copyMetrics: true,
      copyReports: true,
      copyUsers: true,
    },
  });

  const handleDuplicateCompany = handleSubmit((data) => {
    const payload: DuplicateCompanyPayload = {
      companyId: company.id,
      companyName: data.name,
      factaAccounts: data.copyFactaAccounts,
      glAccounts: data.copyGLAccounts,
      metrics: data.copyMetrics,
      reports: data.copyReports,
      users: data.copyUsers,
      settings: true,
      revenueManagement: true,
    };

    duplicateCompany(payload)
      .unwrap()
      .then((result) => {
        toastSuccess('Company successfully duplicated.');
        onSuccess && onSuccess(result);
        onClose();
      });
  });

  const {
    copyGLAccounts,
    copyFactaAccounts,
    copyReports,
  } = watch();

  useEffect(() => {
    if (copyGLAccounts) {
      setValue('copyFactaAccounts', true);
    }
  }, [setValue, copyGLAccounts]);

  useEffect(() => {
    if (!copyFactaAccounts) {
      setValue('copyGLAccounts', false);
      setValue('copyMetrics', false);
      setValue('copyReports', false);
    }
  }, [setValue, copyFactaAccounts]);

  useEffect(() => {
    if (copyReports) {
      setValue('copyMetrics', true);
    }
  }, [setValue, copyReports]);

  return (
    <>
      <header>
        <ButtonsContainer paddingBottom={16}>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={() => actionBlocker(onClose, isDirty)}
            pushRight
            autoFocus
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <SectionLabel>
          <div>
            Duplicate Company
            <p>Copying {company?.name}</p>
          </div>
        </SectionLabel>
      </header>
      <main>
        <StyledFormContainer>
          <InputBasic
            isRequired
            labelText="Duplicated Company Name"
            placeholder="Add company name"
            {...register('name')}
            onBlur={(e) => {
              const fieldValue = e.target.value;

              if (fieldValue) {
                setValue('name', fieldValue.trim(), { shouldValidate: true });
              }
            }}
            error={errors.name?.message}
          />
          <CheckboxInput
            {...register('copyFactaAccounts')}
            error={errors.copyFactaAccounts?.message}
            checkboxLabel="Copy Facta Accounts"
            defaultValue={getValues().copyFactaAccounts}
          />
          <NestedCheckbox>
            <CheckboxInput
              {...register('copyGLAccounts')}
              error={errors.copyGLAccounts?.message}
              checkboxLabel="Copy GL Accounts"
              defaultValue={getValues().copyGLAccounts}
            />
          </NestedCheckbox>
          <CheckboxInput
            {...register('copyMetrics')}
            error={errors.copyFactaAccounts?.message}
            checkboxLabel="Copy Metrics"
            defaultValue={getValues().copyMetrics}
            disabled={!copyFactaAccounts}
            tooltip={!copyFactaAccounts ? 'Cannot copy Metrics without copying Facta Accounts.' : ''}
          />
          <CheckboxInput
            {...register('copyReports')}
            error={errors.copyReports?.message}
            checkboxLabel="Copy Reports"
            defaultValue={getValues().copyReports}
            disabled={!copyFactaAccounts}
            tooltip={!copyFactaAccounts ? 'Cannot copy Reports without copying Facta Accounts.' : ''}
          />
          <CheckboxInput
            {...register('copyUsers')}
            error={errors.copyUsers?.message}
            checkboxLabel="Copy Users"
            defaultValue={getValues().copyUsers}
          />
        </StyledFormContainer>
      </main >
      <footer>
        <StyledButtonContainer pushRight>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            CANCEL
          </Button>
          <Button
            type="submit"
            onClick={handleDuplicateCompany}
            disabled={isLoading}
            isLoading={isLoading}
          >
            DUPLICATE
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};
