import styled from "styled-components";
import { theme } from "theme/theme";

export const ViewsSelector = styled.div<{ hasButton?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  
  & > span {
    margin-right: 8px;
    font-size: 14px;
    font-weight: 600;
    color: ${theme.colors.textDisabled};
  }

  .react-dropdown-select {
    padding: 0 8px;
    font-size: 14px;
    min-height: 32px;
    color: ${theme.colors.textDark};
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: ${({ hasButton }) => (hasButton ? "0" : "4px")};
    border-bottom-right-radius: ${({ hasButton }) => (hasButton ? "0" : "4px")};
    min-width: ${({ hasButton }) => (hasButton ? "168px" : "200px")};
    
    &::before {
      border: none !important;
    }

    .react-dropdown-select-item {
      padding: 6px 16px;
    }
  }

  .react-dropdown-select-dropdown-handle {
    margin-bottom: 3px;
  }

  button {
    margin-left: -1px;
    border-radius: 0;
    border: 1px solid ${theme.colors.fieldBorder};
    border-left: none;
  }

  button:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
