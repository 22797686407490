import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { SectionLabel } from 'components/common/SectionLabel';
import { Button } from 'components/common/Button';
import { InputBasic } from 'components/common/Input';
import { Label } from 'components/common/Input/Label';
import {
  CloseIcon,
  SparkleIcon,
} from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { usePostAccountsCategorizedMutation } from 'store/services/accounts';
import { FinancialType } from 'interfaces/financials';
import useMessages from 'hooks/useMessages';
import { Modal } from 'components/Layout/Modal';
import { AIAssistantResults } from './ResultsScreen';
import { AgGridReact } from '@ag-grid-community/react';
import { IRowNode } from '@ag-grid-community/core';
import { CategorizeResponse } from 'interfaces/accounts';
import { Message } from './Message';
import { Tooltip } from 'components/common/Tooltip';
import { AssistantHints } from './styled';

interface Props {
  financialType: FinancialType;
  onClose: () => void;
  getAccounts: () => any[];
  gridRef: React.RefObject<AgGridReact<any>>;
  onChangesApplied: () => void;
}

export const AIAssistant = ({
  financialType,
  onClose,
  getAccounts,
  gridRef,
  onChangesApplied,
}: Props) => {
  const mainRef = useRef<HTMLDivElement>(null);
  const [results, setResults] = useState<CategorizeResponse>();
  const [showResults, setShowResults] = useState(false);
  const accounts = getAccounts();;

  const { messages, addMessage } = useMessages([{
    id: crypto.randomUUID(),
    name: 'AI Assistant',
    content: <span>Hi. I am your personal AI Assistant. How can I help you?</span>,
  }]);

  const [categorize, { isLoading }] = usePostAccountsCategorizedMutation();

  const applyResults = useCallback((response: any) => {
    let allFactaAccountNodes: IRowNode[] = [];
    let glAccountsToUpdate: IRowNode[] = [];
    let factaAccountsToAdd: any[] = [];

    // Update GL accounts categories
    gridRef?.current?.api.forEachNode((node) => {
      if (node.data?.type === 'facta_account_name') {
        allFactaAccountNodes.push(node);
      }

      const account = response.find((acc: any) => node.data?.type === 'gl_account' && acc.glAccount === node.data.glAccountName);
      if (account && account.category && account.factaAccount) {
        glAccountsToUpdate.push({
          ...node.data,
          path: [account.category, account.factaAccount, account.glAccount],
        });
      }
    });

    // Add Facta accounts if missing
    response.forEach((acc: any) => {
      const isAccountPresent = allFactaAccountNodes.some((node) => JSON.stringify(node.data.path) === JSON.stringify([acc.category, acc.factaAccount]));
      const isAccountAlreadyInSet = factaAccountsToAdd.some((accs) => JSON.stringify(accs.path) === JSON.stringify([acc.category, acc.factaAccount]));

      if (!isAccountPresent && !isAccountAlreadyInSet && acc.category && acc.factaAccount) {
        factaAccountsToAdd.push({
          id: `new_account_${crypto.randomUUID()}`,
          path: [acc.category, acc.factaAccount],
          type: 'facta_account_name',
          used: true,
        });
      };
    });

    gridRef?.current?.api.applyTransactionAsync({
      add: factaAccountsToAdd,
      update: glAccountsToUpdate,
    }, () => {
      onChangesApplied();
    });
  }, [gridRef, onChangesApplied]);

  const handleCategorize = useCallback((message?: string) => {
    categorize({
      financialType,
      accounts,
      message,
    })
      .unwrap()
      .then((res) => {
        const id = crypto.randomUUID();
        addMessage({
          id,
          name: 'AI Assistant',
          content: <>
            <span>I have categorized your accounts. Click below to review and apply the changes.</span>
            <ButtonsContainer>
              <Button
                onClick={() => setShowResults(true)}
                variant="outlined"
              >
                See results
              </Button>
              <Button
                onClick={() => applyResults(res.accounts)}
                variant="outlined"
              >
                Apply
              </Button>
            </ButtonsContainer>
          </>,
        });
        setResults(res);
      })
      .catch(() => {
        addMessage({
          name: 'AI Assistant',
          content: 'I am sorry. I was not able to categorize your accounts. Please try again later.',
        });
      });
  }, [addMessage, applyResults, categorize, financialType, accounts]);

  const predefinedQuestionAndAnswer = useCallback((q: string, a: string) => () => {
    addMessage({
      name: 'You',
      content: q,
    });
    setTimeout(() => {
      addMessage({
        name: 'AI Assistant',
        content: a,
      });
      handleCategorize();
    }, 200);
  }, [addMessage, handleCategorize]);

  useEffect(() => {
    if (mainRef.current) {
      mainRef?.current?.parentElement?.scrollTo({
        top: mainRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [messages]);

  return (
    <>
      <header>
        <ButtonsContainer paddingBottom={16}>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={onClose}
            pushRight
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <SectionLabel>
          AI Assistant
        </SectionLabel>
      </header>
      <main ref={mainRef}>
        {messages.map((message) => (
          <Message
            key={message.id}
            name={message.name}
          >
            {message.content}
          </Message>
        ))}
        {isLoading && (
          <Message
            name={'AI Assistant'}
            isLoading
          >
            <span>Processing request...</span>
          </Message>
        )}
      </main>
      <footer>
        <Label
          name={''}
          labelText={'Hints'} />
        <AssistantHints>
          <Tooltip title={accounts.length ? '' : 'No uncategorized GL accounts available'}>
            <Button
              variant="outlined"
              disabled={!accounts.length || isLoading}
              onClick={predefinedQuestionAndAnswer('Help me categorize my GL accounts', 'Sure. I\'ll try to categorize your GL accounts. Please wait...')}
            >
              <SparkleIcon />
              <span>
                Help me categorize my GL accounts
              </span>
            </Button>
          </Tooltip>
        </AssistantHints>
        <InputBasic
          name={''}
          labelText="Message"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              if (!!e.currentTarget.value.trim()) {
                addMessage({
                  name: 'You',
                  content: e.currentTarget.value,
                });
                handleCategorize(e.currentTarget.value);
              }
              e.currentTarget.value = '';
            }
          }}
          multiline
          placeholder="Ask me anything..."
        />
      </footer>
      <Modal
        isOpen={!!showResults}
        onClose={() => setShowResults(false)}
        fullScreen
      >
        <AIAssistantResults
          results={results?.accounts || []}
          onClose={() => setShowResults(false)}
          onApply={() => applyResults(results?.accounts)}
        />
      </Modal>
    </>
  );
};
