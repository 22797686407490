import React, {
  forwardRef,
  useEffect,
  useState,
} from 'react';
import { ErrorDisplay } from '../Input/ErrorDisplay';
import { Label } from '../Input/Label';
import styled, { css } from 'styled-components';
import { theme } from 'theme/theme';
import {
  CheckboxOn,
  CheckboxOff,
} from 'assets/icons';
import { Tooltip } from '../Tooltip';

export const StyledContainer = styled.div<{ hidden?: boolean }>`
  display: ${props => props.hidden ? 'none' : 'flex'};
  flex-direction: column;
  padding: 0 1px;
`;

export const StyledCheckboxInput = styled.input`
  appearance: none;
  background-color: ${theme.colors.white};
  margin: 0px;
  display: none;
`;

export const Icon = styled.div`
  border-radius: 20%;
  padding: 2px;
  width: 28px;
  height: 28px;
`;

interface StyledLabelProps {
  small?: boolean;
  disabled?: boolean;
  checked?: boolean;
}

const StyledText = styled.span<{ small?: boolean }>`
  ${props => !props.small && css`
    margin: 2px 0;
  `}
`;

export const StyledLabel = styled.label<StyledLabelProps>`
  color: ${theme.colors.textDark};
  font-size: ${props => props.small ? '12px' : '16px'};
  line-height: 24px;
  display: flex;
  gap: ${props => props.small ? '4px' : '12px'};
  align-items: flex-start;
  cursor: pointer;
  
  ${Icon} {
    transition: all 200ms ease;
    color: ${props => props.disabled
    ? theme.colors.textDisabled
    : props.checked
      ? theme.colors.primary
      : theme.colors.fieldBorder}
  }

  &:hover {
    ${Icon} {
      color: ${props => props.disabled ? theme.colors.textDisabled : theme.colors.primary};
    }
  }

  ${props => props.disabled && css`
    color: ${theme.colors.textDisabled};
  `}

  ${props => props.small && css`
    ${Icon} {
      width: 24px;
      height: 24px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  `}
`;

interface Props {
  name: string;
  isRequired?: boolean;
  error?: string;
  labelText?: string;
  disabled?: boolean;
  isOptional?: boolean;
  tooltip?: string;
  dataCy?: string;
  checkboxLabel: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue: boolean;
  small?: boolean;
  hidden?: boolean;
}

export const CheckboxInput = forwardRef(({
  name,
  labelText,
  error,
  isRequired,
  isOptional,
  tooltip,
  dataCy,
  disabled,
  checkboxLabel,
  onChange,
  defaultValue,
  small,
  hidden,
}: Props,
  ref: any) => {
  const [isChecked, setIsChecked] = useState(defaultValue);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
    onChange(e);
  };

  useEffect(() => {
    setIsChecked(defaultValue);
  }, [defaultValue, onChange]);

  return (
    <StyledContainer hidden={hidden}>
      {labelText && (
        <Label
          isRequired={isRequired}
          isOptional={isOptional}
          labelText={labelText}
          tooltip={tooltip}
          name={name}
        />
      )}
      <StyledLabel
        small={small}
        disabled={disabled}
        checked={isChecked}
      >
        <Icon>
          {isChecked
            ? <CheckboxOn />
            : <CheckboxOff />
          }
        </Icon>
        <StyledCheckboxInput
          type="checkbox"
          ref={ref}
          data-cy={dataCy}
          name={name}
          disabled={disabled}
          onChange={handleChange}
        />
        <StyledText small={small}>
          {checkboxLabel}
        </StyledText>
        {!labelText && tooltip && <Tooltip
          inline
          title={tooltip}
        />}
      </StyledLabel>
      {error && <ErrorDisplay error={error} />}
    </StyledContainer>
  );
});
