import React from 'react';
import styled from "styled-components";
import { Checkbox } from "components/common/Checkbox";
import {
  useAppDispatch,
  useAppSelector,
} from "hooks/redux";
import { bulkImportActions } from "store/slices/bulkImport";
import { bulkImportIsCreateAllNewEntites } from 'store/selectors/bulkImport';

const CheckboxWrapper = styled.div`
  label {
    height: 100%;
  }

  div {
    padding: 0 10px 0 0;

    div {
      height: 24px;
      padding: 0;
    }
  }
`;

interface Props {
  tab: string;
  entityName: string;
}

export const HeaderCheckboxRenderer = ({
  tab,
  entityName,
}: Props) => {
  const dispatch = useAppDispatch();
  const isAllSelected = useAppSelector(bulkImportIsCreateAllNewEntites(tab));

  const handleCheckboxChange = () => {
    dispatch(bulkImportActions.toggleCreateNew({ tab, checked: !isAllSelected }));
  };

  return (
    <CheckboxWrapper>
      <Checkbox
        name={`Create new ${entityName}`}
        checked={isAllSelected}
        onChange={handleCheckboxChange}
        condensed
      >
        Create new {entityName}
      </Checkbox>
    </CheckboxWrapper>
  );
};
