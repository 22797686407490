import * as React from "react";

const SvgComponent = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      opacity={0.9}
      fill="inherit"
    >
      <path d="m15 20 1.41-1.41L10.83 13H21v-2H10.83l5.59-5.58L15 4l-8 8 8 8Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 20V4H3v16h2Z"
      />
    </g>
  </svg>
);

export default SvgComponent;
