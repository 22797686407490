import React from 'react';
import * as yup from "yup";
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { Contracts } from 'interfaces/contracts';
import {
  ArrowLeftIcon,
  CloseIcon,
  InfoIcon,
} from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { Datepicker } from 'components/common/Datepicker';
import {
  StyledFormContainer,
  StyledButtonContainer,
} from 'components/common/Forms/styled';
import { Hint } from 'components/common/Hint';
import { SectionLabel } from 'components/common/SectionLabel';
import { Button } from 'components/common/Button';
import {
  isoToDate,
  formatDateToISODate,
  formatDateToISO,
} from 'utils/dates';
import { contractsActions } from 'store/slices/contracts';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import {
  addDays,
  isAfter,
  isEqual,
} from 'date-fns';
import { useGetContractLinesByContractIdsQuery } from 'store/services/contractLines';
import { predefinedContractSelector } from 'store/selectors/contracts';

interface Props {
  onClose: () => void;
  onGoBack: () => void;
  selectedRows: Contracts;
}

export const RenewContractPane = ({
  onClose,
  onGoBack,
  selectedRows,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isSameCustomer = selectedRows.every((con) => con.customer.id === selectedRows[0].customer.id);
  const isRenewable = selectedRows
    .every((con) => !con.next.length);
  const isRenewalAllowed = isRenewable && isSameCustomer;
  const previousContractsIds = selectedRows.map((prevContract) => prevContract.id)
    .toString();
  const contract = useAppSelector(predefinedContractSelector);

  const {
    data: contractLines,
    isFetching,
  } = useGetContractLinesByContractIdsQuery(previousContractsIds);

  const latestStartDate = selectedRows.length
    ? isoToDate(selectedRows
      .map((con) => con.startDate)
      .reduce((date1, date2) => (date1 > date2 ? date1 : date2)))!
    : formatDateToISODate(new Date());

  const suggestedRenewalDate = selectedRows.length
    ? addDays(isoToDate(selectedRows
      .map((con) => con.endDate)
      .reduce((date1, date2) => (date1 > date2 ? date1 : date2)))!, 1)
    : formatDateToISODate(new Date());

  const schema = yup.object({
    renewalDate: yup.date()
      .nullable()
      .test(
        'isNotBeforeLatestStartDate',
        `Renewal date cannot be set before ${selectedRows.length > 1 ? 'latest' : ''} start date.`,
        (val) => val ? (val.getTime() >= latestStartDate.getTime()) : false,
      )
      .required('Renewal date is required.'),
  })
    .required();

  const defaultValues = {
    renewalDate: suggestedRenewalDate,
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<{ renewalDate: Date }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const handleSaveAndNext =  handleSubmit((data) => {
    const renewalDate = formatDateToISO(data.renewalDate);
    const predefinedContract = {
      ...contract,
      id: crypto.randomUUID(),
      name: '',
      customer: selectedRows[0].customer,
      bookingDate: '',
      startDate: renewalDate,
      endDate: '',
      renewalDate: '',
      cancelDate: '',
      tags: [],
      crmId: '',
      externalLink: '',
      notes: '',
    };

    const renewalContractLines = contractLines?.filter((line) => line.cancelDate
      ? isAfter(isoToDate(line.cancelDate)!, data.renewalDate) || isEqual(isoToDate(line.cancelDate)!, data.renewalDate)
      : !line.cancelDate,
    )
      .map((line) => ({
        ...line,
        previousAmount: line.amount,
        amount: line.amount,
        id: line.id,
        name: line.name,
        product: line.product,
        bookingDate: '',
        startDate: renewalDate,
        endDate: '',
        cancelDate: '',
        contract: line.contract,
        customer: line.customer,
        externalLink: line.externalLink,
        notes: line.notes,
        crmId: line.crmId,
        tags: line.tags,
        contractBasedMRRCalc: line.contractBasedMRRCalc,
      }));

    dispatch(contractsActions.updatePreviousContracts(selectedRows));
    dispatch(contractsActions.setContractRenewalLines(renewalContractLines || []));
    dispatch(contractsActions.updatePredefinedContract(predefinedContract));
    navigate('/data-input/contract-management/contracts/renew');
  });

  return (
    <>
      <header>
        <ButtonsContainer paddingBottom={16}>
          <Button
            aria-label="Go back"
            variant="icon"
            size="large"
            onClick={onGoBack}
            data-cy="rc-button-back"
          >
            <ArrowLeftIcon />
          </Button>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={onClose}
            pushRight
            data-cy="rc-button-close"
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <SectionLabel>
          <span>
            {selectedRows?.length > 1 ? 'Renew Contracts' : 'Renew Contract'}
          </span>
        </SectionLabel>
      </header>
      <main>
        {!isFetching && (
          <Hint>
            <InfoIcon />
            {
              !isRenewalAllowed || !contractLines?.length ? (
                <div>
                  {selectedRows.length > 1 ? (
                    <>
                      {!isRenewable && 'One or more of selected contracts is already renewed. '}
                      {!isSameCustomer && `Customers in selected contracts must be the same. `}
                      {!contractLines?.length && 'At least one of the items within the contracts must be active to renew.'}
                    </>
                  ) : (
                    <>
                      {!isRenewable && 'Selected contract is already renewed. '}
                      {!contractLines?.length && 'At least one of the contract items must be active to renew.'}
                    </>
                  )}
                </div>
              ) : (
                <div>Set a renewal date to begin the contract renewal process. This process will create a new renewal contract.</div>
              )
            }
          </Hint>
        )}
        {(isRenewalAllowed && !!contractLines?.length) && (
          <StyledFormContainer>
            <Controller
              name="renewalDate"
              control={control}
              defaultValue={undefined}
              render={({ field }) =>
                <Datepicker
                  labelText="Contract Renewal Date"
                  placeholder="Select Date"
                  {...field}
                  error={errors.renewalDate?.message}
                  isRequired
                  data-cy="rc-datepicker-cancel"
                  isClearable={false}
                />
              }
            />
          </StyledFormContainer>
        )}
      </main>
      <footer>
        {(isRenewalAllowed && !!contractLines?.length) && (
          <StyledButtonContainer pushRight>
            <Button
              type="submit"
              variant="outlined"
              color="secondary"
              onClick={onGoBack}
              data-cy="rc-button-save"
            >
              CANCEL
            </Button>
            <Button
              type="submit"
              onClick={handleSaveAndNext}
              data-cy="rc-button-save-backs"
            >
              CONTINUE
            </Button>
          </StyledButtonContainer>
        )}
      </footer>
    </>
  );
};