import React from 'react';
import { Divider } from '@aws-amplify/ui-react';
import { CloseIcon } from 'assets/icons';
import { Button } from 'components/common/Button';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { SectionLabel } from 'components/common/SectionLabel';
import { StyledImage } from './styled';

interface Props {
  onClose: () => void;
}

export const DuplicatesInformation = ({ onClose }: Props) => (
  <>
    <ButtonsContainer alignTop>
      <SectionLabel>We found duplicate contracts</SectionLabel>
      <Divider />
      <Button
        onClick={onClose}
        variant="icon"
      >
        <CloseIcon />
      </Button>
    </ButtonsContainer>
    <p>
      In Facta, we mark duplicate contracts to help you make sure you’re not putting in the same
      contract twice. We mark a contract as a duplicate if it has the same Contract Name and properties
      (i.e. Start and End Date) as another contract. Here are a few ways to clean up duplicates.
    </p>
    <StyledImage
      alt="duplicates information 1"
      src="/png/duplicates-information-1.png"
      width={300}
    />
    <SectionLabel
      tertiary
      marginTop={16}
    >
      Check Contract Name
    </SectionLabel>
    <p>
      Consider changing contracts with the same name to a different one to help you distinguish
      between each contract.
    </p>
    <StyledImage
      alt="duplicates information 2"
      src="/png/duplicates-information-2.png"
      width={300}
    />
    <SectionLabel
      tertiary
      marginTop={16}
    >
      Check Start and End Dates
    </SectionLabel>
    <p>
      If you keep the Contract Name the same, check your Start and End Dates to see if they’re the
      match. Consider changing these dates or remove the contracts that are actually duplicates.
    </p>
    <StyledImage
      alt="duplicates information 3"
      src="/png/duplicates-information-3.png"
      width={300}
    />
    <SectionLabel
      tertiary
      marginTop={16}
    >
      Remove All Duplicates
    </SectionLabel>
    <p>
      You can remove all duplicates by selecting the “Remove All Duplicates” button, which will remove
      all duplicate contracts and contract lines associated to those duplicate contracts.
    </p>
  </>
);
