import styled from "styled-components";

export const StyledLookbackPeriod = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  button {
    margin-left: auto;
    margin-bottom: 22px;
  }
`;

export const StyledPlaceholder = styled.div`
  width: 42%;
  padding-bottom: 22px;
`;

export const StyledPeriod = styled.div`
  width: 25%;
  display: flex;
  align-self: baseline;
  
  span {
    display: block;
    line-height: 49px;
    padding: 0 8px;
  }
`;

export const StyledYTD = styled.div`
  width: 20%;
  padding-bottom: 22px;
`;
