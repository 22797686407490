import React from 'react';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  ArrowLeftIcon,
  CloseIcon,
} from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { SectionLabel } from 'components/common/SectionLabel';
import { Button } from 'components/common/Button';
import { InputBasic } from 'components/common/Input';
import { Dropdown } from 'components/common/Dropdown';
import {
  StyledFormContainer,
  StyledButtonContainer,
} from 'components/common/Forms/styled';
import {
  useAddTagMutation,
  useGetAllTagsQuery,
} from 'store/services/tags';
import { toastSuccess } from 'utils/toast';
import { actionBlocker } from 'utils/actionBlocker';
import {
  Tag,
  TagFormFields,
  TagType,
} from 'interfaces/tags';
import {
  getTagTypesOptions,
  prepareTagPayload,
} from 'utils/tags';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import { predefinedDataSelector } from 'store/selectors/formState';
import { formStateActions } from 'store/slices/formState';
import { CompanyRevenueType } from 'interfaces/company';

interface Props {
  onClose: () => void;
  onSuccess?: (result: Tag) => void;
  onGoBack?: () => void;
  isIndirectOpen?: boolean;
  allowOnlyOneTag?: TagType;
  companyRevenueType?: CompanyRevenueType;
}

export const AddTag = ({
  onClose,
  onSuccess,
  onGoBack,
  isIndirectOpen,
  allowOnlyOneTag,
  companyRevenueType,
}: Props) => {
  const [addTag, { isLoading }] = useAddTagMutation();
  const dispatch = useAppDispatch();
  const predefinedData = useAppSelector(predefinedDataSelector);

  const { data: tags } = useGetAllTagsQuery();

  const TagTypeOptions = getTagTypesOptions(companyRevenueType);

  const schema = yup.object({
    name: yup.string()
      .required('Tag name is required.')
      .test(
        'isUnique',
        'Tag name must be unique for provided Tag Type.',
        (val, context) => !(tags
          ?.filter((tag) => !tag.deleted)
          ?.filter((tag) => tag.type === context.parent.type[0]?.value)
          .map((tag) => tag.name)
          ?.includes(val || '')),
      ),
    description: yup.string(),
    type: yup.array()
      .of(
        yup.object({
          label: yup.string(),
          value: yup.string(),
        }))
      .min(1, 'Type is required.'),
  })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    control,
    formState: { errors, isDirty },
    clearErrors,
    trigger,
  } = useForm<TagFormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: predefinedData?.name || '',
      description: '',
      type: predefinedData?.type || allowOnlyOneTag
        ? [{
          label: predefinedData.type as TagType || allowOnlyOneTag,
          value: predefinedData.type as TagType || allowOnlyOneTag,
        }]
        : [],
    },
  });

  const handleSaveAndNew = handleSubmit((data) => {
    const tag = prepareTagPayload(data);

    return addTag(tag)
      .unwrap()
      .then((result) => {
        toastSuccess('Tag successfully added.');
        onSuccess && onSuccess(result);
        reset();
        setTimeout(() => clearErrors(), 0);
      });
  });

  const handleSaveAndClose = handleSubmit((data) => {
    const tag = prepareTagPayload(data);

    return addTag(tag)
      .unwrap()
      .then((result) => {
        toastSuccess('Tag successfully added.');
        onSuccess && onSuccess(result);
        onClose();
      });
  });

  const handleSaveAndBack = handleSubmit((data) => {
    const tag = prepareTagPayload(data);

    return addTag(tag)
      .unwrap()
      .then((result) => {
        dispatch(formStateActions.addTagToFormData(result));
        toastSuccess('Tag successfully added.');
        onSuccess && onSuccess(result);
        onGoBack && onGoBack();
      });
  });

  return (
    <>
      <header>
        <ButtonsContainer paddingBottom={16}>
          {onGoBack && (
            <Button
              aria-label="Go back"
              variant="icon"
              size="large"
              onClick={onGoBack}
              data-cy={`at-button-back`}
            >
              <ArrowLeftIcon />
            </Button>
          )}
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={() => actionBlocker(onClose, isDirty)}
            pushRight
            data-cy="at-button-close"
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <SectionLabel data-cy="at-header">
          <span>
            Add Tag
          </span>
        </SectionLabel>
      </header>
      <main>
        <StyledFormContainer>
          <InputBasic
            isRequired
            labelText="Tag Name"
            placeholder="Name your tag"
            {...register('name')}
            onBlur={(e) => {
              const fieldValue = e.target.value;

              if (fieldValue) {
                setValue('name', fieldValue.trim(), { shouldValidate: true });
              }
            }}
            error={errors.name?.message}
            dataCy="at-input-tag-name"
          />
          <InputBasic
            isOptional
            labelText="Description"
            placeholder="Describe your tag"
            {...register('description')}
            error={errors.description?.message}
            dataCy="at-input-tag-description"
          />
          <Controller
            name="type"
            control={control}
            render={({ field }) =>
              <Dropdown
                isRequired
                labelText="Tag Type"
                options={TagTypeOptions}
                values={getValues('type')}
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  trigger();
                }}
                placeholder="Select Tag Type"
                dataCy="at-dropdown-type"
                error={errors.type?.message}
                disabled={!!predefinedData?.type || !!allowOnlyOneTag}
              />
            }
          />
        </StyledFormContainer>
      </main>
      <footer>
        <StyledButtonContainer pushRight>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={isIndirectOpen ? onGoBack : handleSaveAndClose}
            disabled={isLoading}
            isLoading={isIndirectOpen ? false : isLoading}
            data-cy="at-button-save-close"
          >
            {isIndirectOpen ? 'BACK' : 'SAVE & CLOSE'}
          </Button>
          <Button
            type="submit"
            onClick={isIndirectOpen ? handleSaveAndBack : handleSaveAndNew}
            disabled={isLoading}
            isLoading={isLoading}
            data-cy="at-button-save-new"
          >
            {isIndirectOpen ? 'SAVE & BACK' : 'SAVE & NEW'}
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};
