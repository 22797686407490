import { createSelector } from "@reduxjs/toolkit";
import { BulkImportEntityType } from "interfaces/bulkImport";
import { RootState } from "store/store";

// TODO split this into separate files (bulkImpSubs, bulkImpConts, buklImportCommon...)
const bulkImportState = (state: RootState) => state.bulkImport;

// subscriptions
export const bulkImportSubsResultsSelector = createSelector(
  bulkImportState,
  (bulkImport) => bulkImport.subscriptionsResults,
);

export const bulkImportSubsGetRowByIndex = (index: number) => createSelector(
  bulkImportState,
  (bulkImport) => bulkImport.subscriptionsResults.rows[index],
);

export const bulkImportSubsGetRowByAgGridId = (id: string) => createSelector(
  bulkImportState,
  (bulkImport) => bulkImport.subscriptionsResults.rows.find((row) => row.id === id),
);

// contracts
export const bulkImportContractsResultsSelector = createSelector(
  bulkImportState,
  (bulkImport) => bulkImport.contractsResults,
);

export const bulkImportContractsGetRowByIndex = (index: number) => createSelector(
  bulkImportState,
  (bulkImport) => bulkImport.contractsResults.rows[index],
);

export const bulkImportContractsGetRowByAgGridId = (id: string) => createSelector(
  bulkImportState,
  (bulkImport) => bulkImport.contractsResults.rows.find((row) => row.id === id),
);

export const bulkImportDinstinctContractsSelector = createSelector(
  bulkImportState,
  (bulkImport) => bulkImport.contractsResults.rows.filter((row) => row.newContract),
);

export const bulkImportContractLinesSelector = createSelector(
  bulkImportState,
  (bulkImport) => bulkImport.contractsResults.rows.filter((row) => row.newContractLine),
);

// common
export const bulkImportFileDetailsSelector = createSelector(
  bulkImportState,
  (bulkImport) => bulkImport.fileDetails,
);

export const bulkImportGetInvalidProducts = createSelector(
  bulkImportState,
  (bulkImport) => bulkImport.invalidProducts,
);

export const bulkImportGetInvalidCustomers = createSelector(
  bulkImportState,
  (bulkImport) => bulkImport.invalidCustomers,
);

export const bulkImportGetInvalidTags = createSelector(
  bulkImportState,
  (bulkImport) => bulkImport.invalidTags,
);

export const bulkImportIsCreateAllNewEntites = (tab: string) => createSelector(
  bulkImportState,
  (bulkImport) => {
    switch (tab) {
      case BulkImportEntityType.CUSTOMERS:
        return bulkImport.createAllNewCustomers;
      case BulkImportEntityType.PRODUCTS:
        return bulkImport.createAllNewProducts;
      case BulkImportEntityType.TAGS:
        return bulkImport.createAllNewTags;
    }
  },
);

export const bulkImportContractConflictedLinesSelector = createSelector(
  bulkImportState,
  (bulkImport) => bulkImport.contractConflictedLines,
);

export const bulkImportIsUserAbleToNavigateBackToCleanData = createSelector(
  bulkImportState,
  (bulkImport) => bulkImport.isUserAbleToNavigateBackToCleanData,
);

export const bulkImportCommonDataSelector = createSelector(
  bulkImportState,
  (bulkImport) => bulkImport.commonData,
);
