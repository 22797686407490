import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/store";

const contractsState = (state: RootState) => state.contracts;

export const previousContractsSelector = createSelector(
  contractsState,
  (contracts) => contracts.previousContracts,
);

export const predefinedContractSelector = createSelector(
  contractsState,
  (contracts) => contracts.predefinedContract,
);

export const renewalLinesSelector = createSelector(
  contractsState,
  (contracts) => contracts.renewalLines,
);

export const predefinedContractNameSelector = createSelector(
  contractsState,
  (contracts) => contracts.predefinedContractName,
);

export const contractUnlinkPayloadSelector = createSelector(
  contractsState,
  (contracts) => contracts.unlinkPayload,
);
