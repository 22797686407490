import { api } from "./api";

export interface Onboarding {
  steps: [{
    name: string;
    completed: boolean;
  }],
  dismissed: boolean;
}

export const onboardingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOnboarding: builder.query<Onboarding, void>({
      query: () => ({
        url: 'onboarding',
      }),
    }),
    postDismissOnboarding: builder.mutation<void, void>({
      query: () => ({
        method: 'POST',
        url: 'onboarding/dismiss',
      }),
      onQueryStarted: (_, { dispatch }) => {
        dispatch(onboardingApi.util
          .updateQueryData('getOnboarding', undefined, (onboarding) => ({ ...onboarding, dismissed: true })));
      },
    }),
  }),
});

export const {
  useGetOnboardingQuery,
  usePostDismissOnboardingMutation,
} = onboardingApi;
