import * as React from "react";

const SvgComponent = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      fill="inherit"
      opacity={0.9}
    >
      <path d="M3.242 12.243v1.495l5.924.005-6.455 6.455 1.061 1.06 6.454-6.454-.005 5.924h1.506v-8.485H3.242ZM20.727 11.728v-1.495l-5.924-.006 6.454-6.454-1.06-1.06-6.454 6.454.005-5.924h-1.506v8.485h8.485Z" />
    </g>
  </svg>
);

export default SvgComponent;
