import styled from "styled-components";
import { Deleted } from "../Deleted";

const StyledCommonCellRenderer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTextContainer = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AgGridCommonCellRenderer = (property?: string) => (item: any) => {
  if (!item.data) return null;

  return (
    <StyledCommonCellRenderer>
      <StyledTextContainer>
        {item.value}
      </StyledTextContainer>
      {property && item.data.hasOwnProperty(property) && item.data[property].deleted && (
        <Deleted>deleted</Deleted>
      )}
    </StyledCommonCellRenderer>
  );
};
