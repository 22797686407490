import { api } from "./api";
import {
  ConnectQBOCallbackRequest,
  SyncQBORequest,
} from "interfaces/integrations";
import { OnProgress } from "interfaces/api";
import { downloadFile } from "utils/downloadFile";
import {
  AccountsTags,
  AuthTags,
  CommonMetricTags,
  DraftsTags,
  IntegrationsTags,
} from "./api-tags";

export const integrationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getActiveIntegrations: builder.query<Array<string>, void>({
      query: () => ({
        url: 'integrations',
      }),
      providesTags: [IntegrationsTags.Integrations],
    }),
    postConnectQBO: builder.mutation<{ redirectUrl: string }, void>({
      query: () => ({
        method: 'POST',
        url: 'integrations/qbo/connect',
      }),
    }),
    postConnectQBOCallback: builder.mutation<void, ConnectQBOCallbackRequest>({
      query: (body) => ({
        method: 'POST',
        url: 'integrations/qbo/callback',
        body,
      }),
      invalidatesTags: (res, err) => !err ? [
        IntegrationsTags.Integrations,
      ] : [],
    }),
    postSyncQBO: builder.mutation<void, SyncQBORequest>({
      query: (body) => ({
        method: 'POST',
        url: 'integrations/qbo/sync',
        body,
      }),
      invalidatesTags: (res, err) => !err ? [
        AuthTags.User,
        AccountsTags.AccountMappings,
        AccountsTags.Accounts,
        AccountsTags.FactaAccounts,
        DraftsTags.Draft,
        ...CommonMetricTags,
      ] : [],
    }),
    postDisconnectQBO: builder.mutation<void, void>({
      query: () => ({
        method: 'POST',
        url: 'integrations/qbo/disconnect',
      }),
      invalidatesTags: (res, err) => !err ? [
        AuthTags.User,
        IntegrationsTags.Integrations,
        AccountsTags.AccountMappings,
        AccountsTags.Accounts,
        AccountsTags.FactaAccounts,
        DraftsTags.Draft,
        ...CommonMetricTags,
      ] : [],
    }),
    unpivot: builder.mutation<null, { formData: FormData; onProgress?: OnProgress, name: string }>({
      query: ({ formData, onProgress }) => {
        return {
          url: 'integrations/unpivot',
          method: 'POST',
          body: formData,
          onProgress,
        };
      },
      transformResponse: (fileContent: string, _, { name }) => {
        const oldName = name.split('.')
          .slice(0, -1)
          .join('.');

        downloadFile(fileContent, `${oldName}-unpivoted.csv`);
        return null;
      },
    }),
  }),
});

export const {
  usePostConnectQBOMutation,
  usePostConnectQBOCallbackMutation,
  useGetActiveIntegrationsQuery,
  usePostSyncQBOMutation,
  useUnpivotMutation,
  usePostDisconnectQBOMutation,
} = integrationsApi;
