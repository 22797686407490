import { useEffect } from "react";

export const useGlobalHotkeys = () => {
  const handleKeydown = (e: KeyboardEvent) => {
    if (e.key === 'y' && e.metaKey) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, []);
};
