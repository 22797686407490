import { api } from "./api";
import {
  Product,
  Products,
  ProductUpdatePayload,
  ProductsBulkEditPayload,
  ProductAddPayload,
} from "interfaces/products";
import {
  enrichWithDisabledFlag,
  apiDeleteRecipe,
  apiUpdateRecipe,
  apiAddRecipe,
  apiAddMultipleRecipe,
} from "utils/api";
import {
  CommonMetricTags,
  ContractLinesTags,
  ContractsTags,
  ProductsTags,
  SubscriptionsTags,
} from "./api-tags";

export const productsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllProducts: builder.query<Products, void>({
      query: () => ({
        url: `products`,
      }),
      transformResponse: enrichWithDisabledFlag,
      providesTags: [ProductsTags.Products],
    }),
    deleteProducts: builder.mutation<void, string[]>({
      query: (ids) => {
        return {
          url: `products`,
          method: 'DELETE',
          body: ids,
        };
      },
      onQueryStarted: async (ids, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(productsApi.util
            .updateQueryData('getAllProducts', undefined, apiDeleteRecipe(ids)));
        } catch {}
      },
      invalidatesTags: (res, err) => !err ? [
        ContractsTags.Contracts,
        SubscriptionsTags.Subscriptions,
      ] : [],
    }),
    addProduct: builder.mutation<Product, ProductAddPayload>({
      query: (product) => {
        return {
          url: `products`,
          method: 'POST',
          body: product,
        };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: addedProduct} = await queryFulfilled;
          dispatch(productsApi.util
            .updateQueryData('getAllProducts', undefined, apiAddRecipe(addedProduct)));
        } catch {}
      },
    }),
    updateProduct: builder.mutation<Product, ProductUpdatePayload>({
      query: (product) => {
        return {
          url: `products/${product.id}`,
          method: 'PUT',
          body: product,
        };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: updatedProduct} = await queryFulfilled;
          dispatch(productsApi.util
            .updateQueryData('getAllProducts', undefined, apiUpdateRecipe(updatedProduct)));
        } catch {}
      },
      invalidatesTags: (res, err) => !err ? [
        ContractsTags.Contracts,
        SubscriptionsTags.Subscriptions,
        ContractLinesTags.ContractLinesByContractIds,
      ] : [],
    }),
    bulkAddProducts: builder.mutation<Product[], ProductUpdatePayload[]>({
      query: (products) => {
        return {
          url: `products/bulk_create`,
          method: 'POST',
          body: products,
        };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: addedProducts} = await queryFulfilled;
          dispatch(productsApi.util
            .updateQueryData('getAllProducts', undefined, apiAddMultipleRecipe(addedProducts)));
        } catch {}
      },
    }),
    bulkEditProducts: builder.mutation<undefined, ProductsBulkEditPayload>({
      query: (products) => {
        return {
          url: 'products/bulk_edit',
          method: 'POST',
          body: products,
        };
      },
      invalidatesTags: (res, err) => !err ? [
        ProductsTags.Products,
        ContractsTags.Contracts,
        SubscriptionsTags.Subscriptions,
        ContractLinesTags.ContractLinesByContractIds,
        ...CommonMetricTags,
      ] : [],
    }),
  }),
});

export const {
  useGetAllProductsQuery,
  useDeleteProductsMutation,
  useAddProductMutation,
  useUpdateProductMutation,
  useBulkAddProductsMutation,
  useBulkEditProductsMutation,
} = productsApi;
