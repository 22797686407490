import React, {
  useEffect,
  useState,
} from 'react';
import { LeftPane } from 'components/Layout';
import { SweatingLlamaImage } from 'assets/images';
import { ErrorPageContainer } from 'pages/PageNotFound';

export const PageUnauthorized = () => {
  const [timer, setTimer] = useState(20);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((t) => t - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (timer === 0) {
      window.location.href = `${import.meta.env.VITE_APP_AUTH_APP_URL}${window.location.origin}`;
    }
  }, [timer]);

  return (
    <LeftPane>
      <ErrorPageContainer>
        <SweatingLlamaImage />
        <h2>Authorization error</h2>
        <p>You tried to access unauthorized resources.</p>
        <p>Logging out in {timer} seconds...</p>
      </ErrorPageContainer>
    </LeftPane>
  );
};
