import React from 'react';
import {
  GridApi,
  IRowNode,
} from '@ag-grid-community/core';
import { ContextMenu } from 'components/ContextMenu';

interface Props {
  params: any;
  onRemove: () => void;
}

export const EditAccount = ({
  params,
  onRemove,
}: Props) => {
  const api: GridApi = params.api;
  const node: IRowNode = params.node;

  const handleEditAccount = () => {
    const rowIndex = node.rowIndex;

    if (rowIndex) {
      api.startEditingCell({
        rowIndex: rowIndex,
        colKey: 'ag-Grid-AutoColumn',
      });
    }
  };

  const handleRemoveAccount = () => {
    const api: GridApi = params.api;

    api.applyTransaction({
      remove: [node],
    });
    onRemove();
  };

  const sourceDifferentThanManual = node.data.source && node.data.source !== 'manual';
  const hiddenChildrenFound = node.allLeafChildren.length > ((node.allChildrenCount || 0) + 1);
  const anyChildrenFound = !!node.allChildrenCount;

  return (
    <ContextMenu
      items={[
        {
          name: 'Edit',
          action: handleEditAccount,
          hidden: sourceDifferentThanManual,
        },
        {
          name: `Remove ${hiddenChildrenFound ? '(Remove Inactive GL Accounts to Delete)' : ''}`,
          action: handleRemoveAccount,
          hidden: sourceDifferentThanManual,
          disabled: anyChildrenFound || hiddenChildrenFound,
        },
      ]}
      color="secondary"
    />
  );
};

