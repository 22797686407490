import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LeftPane,
  RightPane,
} from 'components/Layout';
import { AddCustomer } from './AddCustomer';
import { SectionLabel } from 'components/common/SectionLabel';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import { Button } from 'components/common/Button';
import {
  RefreshIcon,
  DownloadIcon,
} from 'assets/icons';
import { AgGridReact } from '@ag-grid-community/react';
import {
  useLazyExportCustomersQuery,
  useLazyGetCustomersSearchQuery,
} from 'store/services/customers';
import { getColumnDefs } from './columnDefinitions';
import { QuickSearch } from 'components/common/QuickSearch';
import { EditCustomers } from './EditCustomers';
import { DeleteCustomers } from './DeleteCustomers';
import { useRightPanes } from 'hooks/useRightPanes';
import { AddTag } from 'pages/Tags/AddTag';
import {
  deselectAll,
  highlightNodeById,
  scrollToGridBottom,
} from 'utils/aggrid';
import { Tooltip } from 'components/common/Tooltip';
import { FactaTablePaginated } from 'components/FactaTablePaginated';
import { useLocation } from 'react-router';
import { useAppSelector } from 'hooks/redux';
import { gridHelperSelector } from 'store/selectors/gridHelper';
import { Customer } from 'interfaces/customers';
import { ContextMenu } from 'components/ContextMenu';
import { toast } from 'react-toastify';
import { toastLoading } from 'utils/toast';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import { CompanyRevenueType } from 'interfaces/company';
import { getManagementInfo } from 'utils/managementInfo';
import { useGetAllTagsQuery } from 'store/services/tags';
import { TagType } from 'interfaces/tags';

interface Props {
  setCustomMarkerData: (customData: {}) => void;
  companyRevenueType?: CompanyRevenueType;
}

export const Customers = ({ setCustomMarkerData, companyRevenueType }: Props) => {
  const location = useLocation();
  const gridRef = useRef<AgGridReact>(null);
  const [selectedRows, setSelectedRows] = useState<Customer[]>([]);
  const [quickSearch, setQuickSearch] = useState('');
  const { rightPanes, setSelectedPane, resetPanes } = useRightPanes({
    add: false,
    edit: true,
    delete: false,
    addTagFromAddCustomer: false,
    addTagFromEditCustomer: false,
  });
  const [exportCustomers] = useLazyExportCustomersQuery();
  const { data: allTagsOptions } = useGetAllTagsQuery();
  const tagsOptions = useMemo(() => allTagsOptions
    ?.filter((tag) => tag.type === TagType.CUSTOMER)
    .map((tag) => tag.name) || [], [allTagsOptions]);
  const columnDefs = useMemo(() => getColumnDefs(tagsOptions), [tagsOptions]);

  const {
    selectedIds,
    isAllSelected,
    totalSelected,
    filtering,
  } = useAppSelector(gridHelperSelector);

  const handleAddCustomer = () => {
    gridRef.current?.api.deselectAll();
    setSelectedPane('add');
  };

  const handleTriggerDelete = () => {
    setSelectedPane('delete');
  };

  const managementInfo = getManagementInfo(companyRevenueType);

  const handleCSVDownload = (filtering?: {}) => {
    const exportToast = toastLoading('Preparing file to download...');
    exportCustomers(filtering || {})
      .then(() => {
        toast.update(exportToast, { render: 'Exported successfully!', type: 'success', isLoading: false, autoClose: 2000 });
      });
  };

  useEffect(() => {
    const selectedRow = gridRef.current?.api?.getSelectedNodes()[0];
    setSelectedRows(selectedRow ? [selectedRow.data] : []);
  }, [selectedIds, isAllSelected]);

  useEffect(() => {
    setCustomMarkerData({
      selectedId: selectedIds[0],
    });
  }, [selectedIds, setCustomMarkerData]);

  useEffect(() => {
    if (location.hash === '#add') {
      location.hash = '';
      setSelectedPane('add');
    }
  }, [location, setSelectedPane]);

  return (
    <>
      <LeftPane>
        <ButtonsContainer>
          <Breadcrumbs>
            <Breadcrumb link="/data-input">Data Sources</Breadcrumb>
            <Breadcrumb link={managementInfo.path}>{managementInfo.name}</Breadcrumb>
            <Breadcrumb>Customers</Breadcrumb>
          </Breadcrumbs>
          <Spacer />
          <QuickSearch handleSearchChange={setQuickSearch} />
          <Tooltip title="Refresh table data">
            <Button
              variant="icon"
              aria-label="Refresh"
              onClick={() => gridRef.current?.api?.refreshServerSide()}
            >
              <RefreshIcon />
            </Button>
          </Tooltip>
          <ContextMenu
            customIcon={<DownloadIcon />}
            tooltip="Download data..."
            items={[
              {
                name: 'Download all data',
                action: handleCSVDownload,
              },
              {
                name: 'Download filtered data',
                action: () => handleCSVDownload(filtering),
              },
            ]}
          />
        </ButtonsContainer>
        <SectionLabel>
          <span>
            Customers
          </span>
        </SectionLabel>
        <FactaTablePaginated
          gridRef={gridRef}
          columnDefs={columnDefs}
          isDisabled={rightPanes.add}
          onClickAdd={handleAddCustomer}
          entityName="Customer"
          useLazyQuery={useLazyGetCustomersSearchQuery}
          quickSearch={quickSearch}
        />
      </LeftPane>
      <RightPane isOpen={rightPanes.add}>
        <AddCustomer
          onClose={() => resetPanes()}
          onSuccess={(result) => {
            gridRef.current?.api.refreshServerSide();
            // TODO Implmement it in better way to avoid double scrolling
            scrollToGridBottom();
            setTimeout(() => {
              scrollToGridBottom();
              highlightNodeById(result.id, gridRef);
            }, 1000);
          }}
          onAddTag={() => setSelectedPane('addTagFromAddCustomer')}
        />
      </RightPane>
      <RightPane isOpen={rightPanes.edit && totalSelected > 0}>
        <EditCustomers
          selectedRows={selectedRows}
          onAddTag={() => setSelectedPane('addTagFromEditCustomer')}
          onSuccess={(id) => {
            highlightNodeById(id, gridRef);
            gridRef.current?.api.refreshServerSide();
          }}
          onDelete={handleTriggerDelete}
          onClose={() => deselectAll(gridRef)}
        />
      </RightPane>
      <RightPane isOpen={rightPanes.delete && totalSelected > 0}>
        <DeleteCustomers
          onClose={() => {
            resetPanes();
            gridRef.current?.api.refreshServerSide();
            deselectAll(gridRef);
          }}
        />
      </RightPane>
      <RightPane isOpen={rightPanes.addTagFromAddCustomer || rightPanes.addTagFromEditCustomer}>
        <AddTag
          onClose={() => resetPanes()}
          onGoBack={() => setSelectedPane(rightPanes.addTagFromAddCustomer ? 'add' : 'edit')}
          isIndirectOpen
        />
      </RightPane>
    </>
  );
};
