import {
  useEffect,
  useState,
} from "react";
import {
  useGetDraftQuery,
  usePutDraftMutation,
} from "store/services/drafts";

export const useDraft = (type: string) => {
  const [draftFound, setDraftFound] = useState(false);
  const [putDraft, { isLoading: isSavingDraft }] = usePutDraftMutation();

  const { data: draft } = useGetDraftQuery(type, {
    refetchOnMountOrArgChange: true,
  });

  const saveDraft = (data: any) => {
    putDraft({
      type,
      data,
    });
  };

  const clearDraft = () => {
    putDraft({
      type,
      data: {},
    });
  };

  useEffect(() => {
    setDraftFound(draft && !!Object.entries(draft).length);
  }, [draft]);

  return {
    setDraftFound,
    draftFound,
    isSavingDraft,
    saveDraft,
    clearDraft,
    draft,
  };
};
