import * as React from "react";
const SvgComponent = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        fill="inherit"
        d="M3.59 17 5 18.41 8.41 15 7 13.59 3.59 17ZM16 7.5V11h-5.59L5 5.59 3.59 7l6 6H16v3.5l4.5-4.5L16 7.5Z"
      />
    </g>
  </svg>
);

export default SvgComponent;
