import styled, { css } from "styled-components";
import { theme } from "theme/theme";
import { StyledTooltipIcon } from "../Tooltip/styled";

interface SectionLabelProps {
  secondary?: boolean;
  tertiary?: boolean;
  condensed?: boolean;
  marginTop?: number;
  marginBottom?: number;
  alignTop?: boolean;
}

export const SectionLabel = styled.div<SectionLabelProps>`
  display: flex;
  justify-content: space-between;
  align-items: ${props => props.alignTop ? 'flex-start' : 'center'};
  color: ${props => props.secondary ? theme.colors.textDark : theme.colors.black};
  font-size: ${props => props.secondary ? '20px' : '24px'};
  line-height: ${props => props.secondary ? '30px' : '36px'};
  font-weight: ${props => props.secondary ? 700 : 600};
  margin-bottom: ${props => props.secondary ? '0' : '40px'};
  margin-top: ${props => props.marginTop ? `${props.marginTop}px` : 0};

  p {
    font-size: 14px;
    font-weight: 500;
    margin-top: ${props => props.condensed ? 0 : 8}px;
  }

  @media screen and (max-height: 800px) {
    margin-bottom: ${props => props.secondary ? '0' : '20px'};
  }

  ${StyledTooltipIcon} {
    display: flex;

    svg {
      width: ${props => props.secondary ? '24px' : '30px'};
      height: ${props => props.secondary ? '24px' : '30px'};
      color: ${theme.colors.primary};
    }
  }

  ${props => props.tertiary && css`
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 12px;
    margin-top: ${props.marginTop ? `${props.marginTop}px` : 0};

    p {
      font-size: 14px;
      line-height: 21px;
      color: ${theme.colors.textDark};
      margin-top: 12px;
      margin-bottom: 12px;
    }
  `}

  ${props => props.marginBottom !== undefined && css`
    margin-bottom: ${props.marginBottom}px;
  `};

  ins {
    margin-left: 12px;
    text-decoration: none;
    font-size: 12px;
    font-weight: 500;
  }

  button {
    flex-shrink: 0;
  }
`;

export const SectionMiniLabel = styled.div`
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 1.5px;
  color: ${theme.colors.textDark};
  padding-bottom: 8px;
`;

interface SectionLabelPillProps {
  secondary?: boolean;
  tertiary?: boolean
}

export const SectionLabelPill = styled.div<SectionLabelPillProps>`
  display: inline-block;
  vertical-align: middle;
  background-color: ${theme.colors.primaryStrong};
  color: ${theme.colors.white};
  font-size: 12px;
  line-height: 26px;
  font-weight: 600;
  padding: 0 8px;
  margin: 0 0 0 16px;
  border-radius: 4px;
  ${props => props.secondary && css`
    background-color: ${theme.colors.textDisabled};
  `}
  ${props => props.tertiary && css`
    background-color: ${theme.colors.tertiary};
  `}

  & + & {
    margin: 0 0 0 8px;
  }
`;
