import styled from "styled-components";
import { theme } from "theme/theme";

export const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .ag-row-level-1 .ag-cell-inline-editing {
    padding-left: 141px;
  }

  .ag-row-level-2 .ag-cell-inline-editing {
    padding-left: 182px;
  }

  .ag-row-group-leaf-indent.ag-row-group-indent-1 .ag-group-value {
    font-weight: 600;
  }

  .cell-red .ag-row-group-indent-1 {
    padding-left: 84px;
  }

  .ag-row-group-indent-2 {
    padding-left: 84px;
  }

  .cell-full-width {
    .ag-group-value {
      width: 100%;
      align-items: center;
    }
  }

  .hover-over {
    background-color: ${theme.colors.successBleach} !important;
  }

  .ag-row-level-0 {
    background-color: ${theme.colors.secondaryBackground};
    font-size: 16px;

    &.ag-row-hover .ag-cell {
      background: ${theme.colors.secondaryBackground} !important;
    }
  }
`;
