import { ColDef } from '@ag-grid-community/core';
import { ContextMenu } from 'components/ContextMenu';
import {
  Link,
  NavigateFunction,
} from 'react-router-dom';
import {
  caseInsensitiveComparator,
  dateComparator,
} from "utils/aggrid";
import { formatDateStringToDisplay } from 'utils/dates';
import { StyledContextMenuWraper } from './styled';
import {
  dateFilter,
  multiFilter,
} from 'utils/filters';

const OverviewCellRenderer = (item: any, isViewer: boolean) => {
  if (!item.data) return null;

  if (isViewer) {
    return item.value;
  }

  return (
    <Link to={`/reports/${item.data.id}`}>
      <span>{item.value}</span>
    </Link>
  );
};

interface Props {
  isViewer: boolean;
  triggerDelete: (id: string) => void;
  triggerDuplicate: (id: string) => void;
  navigate: NavigateFunction
}

export const getColumnDefs = ({
  isViewer,
  triggerDelete,
  triggerDuplicate,
  navigate,
}: Props): Array<ColDef> => ([
  {
    field: 'name',
    headerName: 'Report Name',
    sortable: true,
    resizable: true,
    flex: 2,
    minWidth: 250,
    cellRenderer: (item: any) => OverviewCellRenderer(item, isViewer),
    comparator: caseInsensitiveComparator(),
    ...multiFilter,
  },
  {
    field: 'created',
    headerName: 'Created',
    sortable: true,
    resizable: true,
    minWidth: 250,
    valueFormatter: ({ value }) => value ? formatDateStringToDisplay(value) : '-',
    comparator: dateComparator(),
    ...dateFilter,
  },
  {
    field: 'updated',
    headerName: 'Last Updated',
    sortable: true,
    resizable: true,
    minWidth: 250,
    valueFormatter: ({ value }) => value ? formatDateStringToDisplay(value) : '-',
    tooltipValueGetter: ({ data }) => data.updatedBy?.firstName
      ? `by ${data.updatedBy?.firstName || 'unknown'} ${data.updatedBy?.lastName || ''}`
      : undefined,
    comparator: dateComparator(),
    ...dateFilter,
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    sortable: true,
    resizable: true,
    minWidth: 250,
    valueGetter: ({ data }) => `${data.createdBy.firstName || ''} ${data.createdBy.lastName || ''}`,
    ...multiFilter,
  },
  {
    field: 'id',
    headerName: '',
    onCellClicked: (e) => e?.event?.stopPropagation(),
    cellRenderer: ({ value }: any) => (
      <StyledContextMenuWraper>
        <ContextMenu
          color="secondary"
          items={[
            {
              name: 'Preview Report',
              action: () => navigate(`/reports/${value}/preview`),
            },
            {
              name: 'Duplicate Report...',
              action: () => triggerDuplicate(value),
              hidden: isViewer,
            },
            {
              name: 'Download Report',
              action: () => navigate(`/reports/${value}/preview#download`),
            },
            {
              name: 'Delete Report...',
              action: () => triggerDelete(value),
              hidden: isViewer,
            },
          ]}
        />
      </StyledContextMenuWraper>
    ),
    maxWidth: 80,
  },
]);
