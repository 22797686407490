import {
  useEffect,
  useMemo,
} from 'react';
import * as yup from "yup";
import {
  useForm,
  Controller,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CloseIcon,
  CopyIcon,
} from 'assets/icons';
import {
  BusinessStageOptions,
  BusinessTypeOptions,
  CompanyRevenueTypeOptions,
  CompanyStatusOptions,
  IndustryTypeOptions,
} from 'utils/constants';
import { Button } from 'components/common/Button';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import {
  StyledButtonContainer,
  StyledFormContainer,
  StyledFormSectionLabel,
} from 'components/common/Forms/styled';
import { Dropdown } from 'components/common/Dropdown';
import { InputBasic } from 'components/common/Input';
import { copyToClipboard } from 'utils/copyToClipboard';
import { actionBlocker } from 'utils/actionBlocker';
import {
  BusinessStageType,
  BusinessType,
  Company,
  CompanyRevenueType,
  CompanyUpdateByIdPayload,
  IndustryType,
} from 'interfaces/company';
import { CheckboxInput } from 'components/common/CheckboxInput';
import { RadioInput } from 'components/common/RadioInput';
import {
  useGetCurrenciesQuery,
  useUpdateCompanyByIdMutation,
} from 'store/services/companies';
import { toastSuccess } from 'utils/toast';
import { CompanyStatus } from 'interfaces/company';
import { useGetAuthMeQuery } from 'store/services/auth';

interface Props {
  onClose: () => void;
  onSuccess: (id?: string) => void;
  selectedRows: Company[];
}

export const EditCompanyPane = ({
  onClose,
  onSuccess,
  selectedRows,
}: Props) => {
  const [updateCompanyById, { isLoading }] = useUpdateCompanyByIdMutation();
  const { data: me } = useGetAuthMeQuery();
  const { data: currencies, isFetching: isLoadingCurrencies } = useGetCurrenciesQuery();

  const GroupOptions = useMemo(() => {
    return [...new Set(me?.companies.map((company) => company.group))]
      .filter((value) => value)
      .map((value) => ({ value } as { value: string }));
  }, [me?.companies]);

  const TagOptions = useMemo(() => {
    return [...new Set(me?.companies.map((company) => company.tag))]
      .filter((value) => value)
      .map((value) => ({ value } as { value: string }));
  }, [me?.companies]);

  const schema = yup.object({
    name: yup.string()
      .required('Company name is required.'),
    companyRevenueType: yup.object()
      .required('Company Revenue Type is required.'),
    currencyCode: yup.array()
      .min(1, 'Currency is required.'),
  })
    .required();

  const defaultValues = useMemo(() => ({
    id: '',
    name: '',
    domain: '',
    currencyCode: [{
      name: 'US Dollars',
      value: 'USD',
      symbol: '$',
    }],
    companyStatus: [CompanyStatusOptions.at(0)],
    group: [],
    tag: [],
    companyRevenueType: CompanyRevenueTypeOptions.at(0),
    industry: [],
    businessType: [],
    businessStage: [],
    useRevRec: false,
  }), []);

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm<{
    id: string;
    name: string;
    companyRevenueType: {
      name: string;
      value: CompanyRevenueType;
      description: string;
      annotation: string;
    };
    currencyCode: {
      name: string;
      code: string;
      symbol: string;
    }[];
    companyStatus: { value: CompanyStatus }[];
    group: { value: string }[];
    domain: string;
    industry: { value: IndustryType }[];
    businessType: { value: BusinessType }[];
    businessStage: { value: BusinessStageType }[];
    useRevRec: boolean;
    tag: { value: string }[];
  }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  useEffect(() => {
    const company = selectedRows[0];

    if (!company) {
      onClose();
      return;
    };

    reset({
      id: company.id,
      name: company.name,
      domain: company.domain,
      currencyCode: [company.currency],
      companyStatus: company.companyStatus ? [CompanyStatusOptions.find((option) => option.value === company.companyStatus)] : undefined,
      group: company.group ? [GroupOptions.find((option) => option.value === company.group)] : undefined,
      tag: company.tag ? [TagOptions.find((option) => option.value === company.tag)] : undefined,
      industry: company.industry ? [IndustryTypeOptions.find((option) => option.value === company.industry)] : undefined,
      businessType: company.businessType ? [BusinessTypeOptions.find((option) => option.value === company.businessType)] : undefined,
      businessStage: company.businessStage ? [BusinessStageOptions.find((option) => option.value === company.businessStage)] : undefined,
      companyRevenueType: CompanyRevenueTypeOptions.find((option) => option.value === company.companyRevenueType),
      useRevRec: company.useRevRec,
    });
  }, [GroupOptions, TagOptions, onClose, reset, selectedRows]);

  const handleSaveAndClose = handleSubmit((data) => {
    const company: CompanyUpdateByIdPayload = {
      id: data.id,
      name: data.name,
      domain: data.domain,
      currencyCode: data.currencyCode.at(0)?.code || 'USD',
      companyStatus: data.companyStatus?.at(0)?.value || null,
      group: data.group?.at(0)?.value || null,
      tag: data.tag?.at(0)?.value || null,
      industry: data.industry?.at(0)?.value || null,
      businessType: data.businessType.at(0)?.value || null,
      businessStage: data.businessStage.at(0)?.value || null,
      companyRevenueType: data.companyRevenueType!.value,
      useRevRec: data.useRevRec,
    };

    return updateCompanyById(company)
      .unwrap()
      .then((result) => {
        toastSuccess('Company successfully updated.');
        onClose();
        onSuccess(result.id);
      });
  });

  return (
    <>
      <header>
        <ButtonsContainer>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={() => actionBlocker(onClose, isDirty)}
            pushRight
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <StyledFormSectionLabel>
          <span>
            Edit Company
          </span>
          <ButtonsContainer>
            <Button
              variant="simple"
              size="large"
              type="button"
              onClick={(e) => copyToClipboard(e, selectedRows[0].id)}
            >
              <span>Insights Company ID</span>
              <CopyIcon />
            </Button>
          </ButtonsContainer>
        </StyledFormSectionLabel>
      </header>
      <main>
        <StyledFormContainer>
          <InputBasic
            isRequired
            labelText="Company Name"
            placeholder="Add company name"
            {...register('name')}
            onBlur={(e) => {
              const fieldValue = e.target.value;

              if (fieldValue) {
                setValue('name', fieldValue.trim(), { shouldValidate: true });
              }
            }}
            error={errors.name?.message}
          />
          <Controller
            name="companyStatus"
            control={control}
            render={({ field }) =>
              <Dropdown
                isRequired
                labelText="Status"
                options={CompanyStatusOptions}
                labelField="value"
                valueField="value"
                searchBy="value"
                placeholder="Select Status"
                values={getValues('companyStatus')}
                {...field}
                error={errors.companyStatus?.message}
              />
            }
          />
          <Controller
            name="currencyCode"
            control={control}
            render={({ field }) =>
              <Dropdown
                isRequired
                labelText="Currency"
                options={currencies || []}
                labelField="name"
                valueField="code"
                searchBy="name"
                placeholder="Currency"
                values={getValues('currencyCode')}
                {...field}
                error={errors.currencyCode?.message}
                loading={isLoadingCurrencies}
              />
            }
          />
          <InputBasic
            isOptional
            labelText="Domain"
            placeholder="www.facta.io"
            {...register('domain')}
            onBlur={(e) => {
              const fieldValue = e.target.value;

              if (fieldValue) {
                setValue('domain', fieldValue.trim(), { shouldValidate: true });
              }
            }}
            error={errors.domain?.message}
          />
          <Controller
            name="industry"
            control={control}
            render={({ field }) =>
              <Dropdown
                isOptional
                labelText="Industry"
                options={IndustryTypeOptions}
                labelField="value"
                valueField="value"
                searchBy="value"
                placeholder="Select Industry"
                values={getValues('industry')}
                {...field}
                error={errors.industry?.message}
              />
            }
          />
          <Controller
            name="group"
            control={control}
            render={({ field }) =>
              <Dropdown
                isOptional
                clearable
                labelText="Group"
                options={GroupOptions}
                labelField="value"
                valueField="value"
                searchBy="value"
                placeholder="Select Group"
                values={getValues('group')}
                {...field}
                error={errors.group?.message}
                create
                tooltip='Start typing to create a new group. Press "Enter" to save it.'
              />
            }
          />
          <Controller
            name="businessType"
            control={control}
            render={({ field }) =>
              <Dropdown
                isOptional
                labelText="Business Type"
                options={BusinessTypeOptions}
                labelField="value"
                valueField="value"
                searchBy="value"
                placeholder="Select Business Type"
                values={getValues('businessType')}
                {...field}
                error={errors.businessType?.message}
              />
            }
          />
          <Controller
            name="businessStage"
            control={control}
            render={({ field }) =>
              <Dropdown
                isOptional
                labelText="Business Stage"
                options={BusinessStageOptions}
                labelField="value"
                valueField="value"
                searchBy="value"
                placeholder="Select Business Stage"
                values={getValues('businessStage')}
                {...field}
                error={errors.businessStage?.message}
              />
            }
          />
          <Controller
            name="tag"
            control={control}
            render={({ field }) =>
              <Dropdown
                isOptional
                clearable
                labelText="Tag"
                options={TagOptions}
                labelField="value"
                valueField="value"
                searchBy="value"
                placeholder="Select Tag"
                values={getValues('tag')}
                {...field}
                error={errors.tag?.message}
                create
                tooltip='Start typing to create a new tag. Press "Enter" to save it.'
              />
            }
          />
          <Controller
            name="companyRevenueType"
            control={control}
            render={({ field }) =>
              <RadioInput
                isRequired
                labelText="Company Revenue Type"
                valueField="value"
                labelField="name"
                defaultValue={getValues('companyRevenueType')}
                annotationField="annotation"
                descriptionField="description"
                options={CompanyRevenueTypeOptions}
                {...field}
                onChange={(value) => {
                  field.onChange(value);
                }}
                error={errors.companyRevenueType?.message}
                disabled
                tooltip="Please contact customer support to edit Company Revenue Type."
              />
            }
          />
          <CheckboxInput
            labelText="Revenue Recognition Module"
            {...register('useRevRec')}
            error={errors.useRevRec?.message}
            tooltip="Add the revenue recognition module to help you see the difference between your SaaS and actual Revenue."
            checkboxLabel="Include Revenue Recognition Module"
            defaultValue={getValues().useRevRec}
          />
        </StyledFormContainer>
      </main>
      <footer>
        <StyledButtonContainer pushRight>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={onClose}
            disabled={isLoading}
          >
            CANCEL
          </Button>
          <Button
            type="submit"
            onClick={handleSaveAndClose}
            disabled={isLoading}
            isLoading={isLoading}
          >
            SAVE & CLOSE
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};
