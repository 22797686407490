import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { LeftPane } from 'components/Layout';
import { useParams } from 'react-router';
import {
  useGetDashboardSignedUrlQuery,
  useGetDashboardsQuery,
  usePatchDashboardsMutation,
} from 'store/services/dashboards';
import { PageNotFound } from 'pages/PageNotFound';
import { DashboardSettings } from 'interfaces/settings';
import { useDebouncedEffect } from 'hooks/useDebouncedEffect';
import { Loading } from 'components/Loading';

const StyledIframeContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const StyledIframe = styled.iframe`
  border: 0;
  width: 100%;
  height: 100%;
`;

export const Dashboard = () => {
  const dashboardRef = useRef<any>();
  const { dashboardName } = useParams();
  const { data: dashboards, isLoading } = useGetDashboardsQuery();
  const [ dashboardSetting, setDashboardSetting] = useState<DashboardSettings>();
  const [updateSettings] = usePatchDashboardsMutation();
  const dashboard = dashboards?.find((dashboard) => dashboard.path === dashboardName);
  const { data: dashboardUrl } = useGetDashboardSignedUrlQuery(dashboardName!, {
    refetchOnMountOrArgChange: true,
  });

  const handleMessageChange = (event: MessageEvent<any>) => {
    if (
      event.origin === "https://app.sigmacomputing.com" &&
      event.data.type === "workbook:variables:onchange"
    ) {
      const settings: DashboardSettings = {
        name: Object.keys(event.data.workbook.variables)[0],
        value: Object.values(event.data.workbook.variables)[0] as string,
      };

      setDashboardSetting(settings);
    }
  };

  useDebouncedEffect(() => {
    if (dashboardSetting) {
      updateSettings({ dashboardPath: dashboardName!, settings: dashboardSetting });
    }
  }, [dashboardSetting], 2000);

  useEffect(() => {
    if (dashboardRef.current) {
      window?.addEventListener('message', handleMessageChange);
    }

    return () => {
      window.removeEventListener('message', handleMessageChange);
    };
  }, [dashboardName, dashboardUrl, updateSettings]);

  if (!isLoading && !dashboard) return <PageNotFound />;

  return (
    <>
      {isLoading && (
        <LeftPane>
          <Loading title="Dashboards are loading..." />
        </LeftPane>
      )}
      {dashboardUrl && dashboardName && (
        <LeftPane removeMargin>
          <StyledIframeContainer>
            <StyledIframe
              ref={dashboardRef}
              src={dashboardUrl.url}
              data-cy="iframe-dashboard"
            />
          </StyledIframeContainer>
        </LeftPane>
      )}
    </>
  );
};
