import React from 'react';
import { useAppDispatch } from 'hooks/redux';
import { DeleteItems } from 'components/DeleteItems';
import { BulkImportSubscriptionsRow } from 'interfaces/bulkImportSubscriptions';
import { bulkImportActions } from 'store/slices/bulkImport';

interface Props {
  onClose: () => void;
  selectedRows: BulkImportSubscriptionsRow[];
}

export const BulkImportDelete = ({
  onClose,
  selectedRows,
}: Props) => {
  const dispatch = useAppDispatch();

  const handleDeleteRows = () => {
    const ids = selectedRows.map((row) => row.id);

    dispatch(bulkImportActions.deleteSubsRows(ids));
  };

  return (
    <DeleteItems
      selectedRows={selectedRows}
      onClose={onClose}
      isLoading={false}
      handleDelete={handleDeleteRows}
      item="row"
      shortenDescription
    />
  );
};
