import { Options } from "@popperjs/core";

export const commonPopperOptions: Partial<Options> = {
  placement: 'bottom-start',
  strategy: 'fixed',
  modifiers: [
    {
      name: 'preventOverflow',
      options: {
        padding: 40,
        altAxis: true,
      },
    },
    {
      name: 'flip',
      enabled: false,
    },
  ],
};
