import React from 'react';
import * as yup from "yup";
import { Button } from 'components/common/Button';
import {
  useGetAllReportsQuery,
  useUpdateReportMutation,
} from 'store/services/reports';
import { toastSuccess } from 'utils/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { StyledFormContainer } from 'components/common/Forms/styled';
import { InputBasic } from 'components/common/Input';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { StyledEditName } from './styled';
import {
  ReportWithData,
  UpdateReportPayload,
} from 'interfaces/reports';

interface Props {
  report: ReportWithData;
  onClose: () => void;
}

export const EditReportName = ({ report, onClose }: Props) => {
  const [updateReport, { isLoading }] = useUpdateReportMutation();
  const { data: reports } = useGetAllReportsQuery();
  const allReportsNames = reports?.map((report) => report.name.toLowerCase())
    .filter((name) => name !== report.name.toLowerCase()) || [];

  const schema = yup.object({
    name: yup.string()
      .required('Report Name is required.')
      .test(
        'notOneOfCaseInsensitive',
        'Report with this name already exists.',
        (val) => !allReportsNames.includes(val?.toLowerCase() || ''),
      ),
  })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{ name: string; }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: report.name,
    },
  });

  const handleUpdate = handleSubmit((data) => {
    const payload: UpdateReportPayload = {
      reportId: report.id,
      name: data.name,
      notes: report.notes,
      shouldExportNotes: report.shouldExportNotes,
    };

    updateReport(payload)
      .unwrap()
      .then(() => {
        toastSuccess('Report name updated successfully.');
        onClose();
      });
  });

  return (
    <>
      <StyledFormContainer onSubmit={handleUpdate}>
        <ButtonsContainer>
          <StyledEditName>
            <InputBasic
              labelText=""
              {...register('name')}
              onBlur={(e) => {
                const fieldValue = e.target.value;

                if (fieldValue) {
                  setValue('name', fieldValue.trim(), { shouldValidate: true });
                }
              }}
              error={errors.name?.message}
              condensed
            />
          </StyledEditName>
          <Button
            type="button"
            variant="simple"
            color="primary"
            size="large"
            onClick={handleUpdate}
            disabled={isLoading}
            isLoading={isLoading}
          >
            Save
          </Button>
          <Button
            type="button"
            variant="simple"
            color="secondary"
            size="large"
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </Button>
        </ButtonsContainer>
      </StyledFormContainer>
    </>
  );
};
