import React from 'react';
import { ProgressTracker } from 'components/ProgresTracker';
import {
  StyledButtonContainer,
  StyledFormSectionLabel,
} from 'components/common/Forms/styled';
import {
  CheckCircleIcon,
  InfoIcon,
  RadioUncheckedIcon,
} from 'assets/icons';
import { Button } from 'components/common/Button';
import {
  SummaryContainer,
  SummaryEntry,
} from 'components/BulkImport/SidePanel/styled';
import { Hint } from 'components/common/Hint';

interface Props {
  handleCancel: () => void;
  handleContinue: () => void;
  isContinueDisabled?: boolean;
  instruction?: string;
}

export const RenewContractSidePanel = ({
  handleCancel,
  handleContinue,
  isContinueDisabled,
  instruction,
}: Props) => {
  const progress = isContinueDisabled ? 50 : 75;

  return (
    <>
      <header>
        <StyledFormSectionLabel >
          <span>
            Renew Contract
          </span>
        </StyledFormSectionLabel>
      </header>
      <main>
        <SummaryContainer>
          <section>Progress tracker</section>
          <ProgressTracker progress={progress} />
          <SummaryEntry>
            <span>1. Set renewal date</span>
            <span><CheckCircleIcon /></span>
          </SummaryEntry>
          <SummaryEntry>
            <span>2. Add renewal contract</span>
            <span><CheckCircleIcon /></span>
          </SummaryEntry>
          <SummaryEntry bold>
            <span>3. Renew contract lines and add new</span>
            <span>{!isContinueDisabled ?  <CheckCircleIcon /> : <RadioUncheckedIcon />}</span>
          </SummaryEntry>
          <SummaryEntry>
            <span>4. Done</span>
            <span><RadioUncheckedIcon /></span>
          </SummaryEntry>
        </SummaryContainer>
        {instruction && (
          <Hint>
            <InfoIcon />
            <div>{instruction}</div>
          </Hint>
        )}
      </main>
      <footer>
        <StyledButtonContainer pushRight>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
          >
            CANCEL
          </Button>
          <Button
            type="submit"
            onClick={handleContinue}
            disabled={isContinueDisabled}
          >
            SUBMIT
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};
