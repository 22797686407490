import React from "react";
import {
  ArrowRightIcon,
  CheckCircleIcon,
  ErrorIcon,
  PlusIcon,
} from "assets/icons";
import { CheckboxRenderer } from "./CheckboxRenderer";
import { Status } from "components/common/Status";
import { HeaderCheckboxRenderer } from "./HeaderCheckboxRenderer";
import { BulkImportEntityType } from "interfaces/bulkImport";
import { caseInsensitiveComparator } from "utils/aggrid";
import {
  ColDef,
  ICellRendererParams,
  ITooltipParams,
} from "@ag-grid-community/core";
import { StyledTooltipText } from "components/common/Tooltip/styled";
import { AgGridCellRendererDropdown } from "components/common/AgGridCellRendererDropdown";
import { Product } from "interfaces/products";
import { bulkImportActions } from "store/slices/bulkImport";
import { RevenueTypeOptions } from "utils/constants";
import { Button } from "components/common/Button";

export const StatusRenderer = ({ value }: { value: 'correct' | 'incorrect' }) => {
  switch (value) {
    case 'correct':
      return (
        <Status color="success">
          <CheckCircleIcon />
        </Status>
      );
    default:
      return (
        <Status color="warning">
          <ErrorIcon />
        </Status>
      );
  }
};

interface ColumnDefsProps {
  tab: BulkImportEntityType;
  options: any[];
  gridHeight: number;
  setSelectedPane: (pane: any) => void;
  dispatch: (action: any) => void;
}

const getEntityName = (entityType: BulkImportEntityType) => {
  switch (entityType) {
    case BulkImportEntityType.CUSTOMERS:
      return 'Customer';
    case BulkImportEntityType.PRODUCTS:
      return 'Product';
    case BulkImportEntityType.TAGS:
      return 'Tag';
  }
};

const getTooltip = ({ value, data }: ITooltipParams, entityType: BulkImportEntityType) => {
  if (value === 'incorrect') {
    return `${data.name || 'This entity was'} not found in Insights ${entityType}. Create new or map to existing one.`;
  }
};

export const getColumnDefs = ({
  tab,
  options,
  setSelectedPane,
  dispatch,
}: ColumnDefsProps): Array<ColDef> => ([
  {
    field: 'status',
    headerName: 'Status',
    width: 130,
    sortable: true,
    cellRenderer: StatusRenderer,
    tooltipComponent: ({ value }: ITooltipParams) => <StyledTooltipText data-show>{value}</StyledTooltipText>,
    tooltipValueGetter: (params) => getTooltip(params, tab),
  },
  {
    field: 'name',
    headerName: `${getEntityName(tab)} Name`,
    resizable: true,
    sortable: true,
    minWidth: 250,
    flex: 1,
    valueFormatter: ({ value }) => value,
    getQuickFilterText: ({ value }) => value,
    comparator: caseInsensitiveComparator(),
  },
  {
    field: '',
    headerName: '',
    resizable: false,
    width: 80,
    cellRenderer: () => <Status><ArrowRightIcon /></Status>,
  },
  {
    field: 'createNew',
    headerName: `Create New ${getEntityName(tab)}`,
    minWidth: 250,
    headerComponent: () => <HeaderCheckboxRenderer
      tab={tab}
      entityName={getEntityName(tab)}
    />,
    cellRenderer: ({ value, data }: any) => <CheckboxRenderer
      data={data}
      value={value}
      tab={tab}
    />,
  },
  {
    field: 'entity',
    headerName: `Match to Current ${getEntityName(tab)}`,
    resizable: true,
    minWidth: 250,
    flex: 1,
    sortable: false,
    editable: (params) => !params.data.entity.createNew,
    cellEditor: 'agRichSelectCellEditor',
    cellEditorPopup: false,
    cellEditorParams: {
      values: options,
      cellHeight: 40,
      formatValue: (value: any) => value?.name || '-',
      allowTyping: true,
    },
    valueSetter: (params: any) => {
      const newEntity = typeof params.newValue === 'string'
        ? options.find((opt) => opt.name === params.newValue)
        : params.newValue;

      if (!newEntity) {
        return false;
      }

      if (tab === BulkImportEntityType.PRODUCTS) {
        const product = options?.find((opt) => opt.id === newEntity.id) as Product;

        dispatch(bulkImportActions.updateInvalidProduct({
          id: params.data.id,
          entity: newEntity,
          revenueType: {
            createNew: params.data.revenueType.createNew,
            id: product ? product.revenueType : null,
          },
        }));
      }

      if (tab === BulkImportEntityType.CUSTOMERS) {
        dispatch(bulkImportActions.updateInvalidCustomer({
          id: params.data.id,
          entity: newEntity,
        }));
      }

      if (tab === BulkImportEntityType.TAGS) {
        dispatch(bulkImportActions.updateInvalidTag({
          id: params.data.id,
          entity: newEntity,
        }));
      }

      return false;
    },
    valueGetter: (params: any) => {
      return params.data.entity;
    },
    cellRenderer: (params: ICellRendererParams) => {
      return (
        <AgGridCellRendererDropdown
          gridParams={params}
          error={!params.value?.name}
          disabled={params.value?.createNew}
        >
          {params.value?.createNew ? '-' : params.value?.name || 'Select'}
        </AgGridCellRendererDropdown>
      );
    },
    valueFormatter: () => 'dummy',
  },
  {
    field: 'revenueType',
    headerName: 'Revenue Type',
    resizable: true,
    minWidth: 250,
    hide: tab !== BulkImportEntityType.PRODUCTS,
    flex: 1,
    sortable: false,
    editable: (params) => params.data.entity.createNew,
    cellEditor: 'agRichSelectCellEditor',
    cellEditorPopup: false,
    cellEditorParams: {
      values: RevenueTypeOptions,
      formatValue: (value: any) => value?.label || '-',
      cellHeight: 40,
      allowTyping: true,
    },
    valueSetter: (params: any) => {
      const newEntity = typeof params.newValue === 'string'
        ? RevenueTypeOptions.find((opt) => opt.label === params.newValue)
        : params.newValue;

      if (!newEntity) {
        return false;
      }

      dispatch(bulkImportActions.updateInvalidProduct({
        id: params.data.id,
        entity: {
          createNew: params.data.entity.createNew,
          id: null,
          name: null,
        },
        revenueType: {
          createNew: params.data.revenueType.createNew,
          id: newEntity.value || null,
        },
      }));

      return false;
    },
    valueGetter: (params: any) => {
      return params.data.revenueType;
    },
    cellRenderer: (params: ICellRendererParams) => {
      return (
        <AgGridCellRendererDropdown
          gridParams={params}
          error={!params.value?.id}
          disabled={!params.value?.createNew}
        >
          {!params.value?.createNew
            ? params.value?.id || '-'
            : params.value?.id || 'Select'}
        </AgGridCellRendererDropdown>
      );
    },
    valueFormatter: () => 'dummy',
  },
  {
    field: 'addNewEntity',
    headerName: 'Add',
    headerTooltip: `Create new ${getEntityName(tab)} and use it.`,
    width: 65,
    suppressMenu: true,
    maxWidth: 65,
    sortable: false,
    cellRenderer: (params: ICellRendererParams) => (
      <Status>
        <Button
          variant="icon"
          color="primary"
          onClick={() => setSelectedPane(tab)}
          disabled={params.data.entity.createNew}
        >
          <PlusIcon />
        </Button>
      </Status>
    ),
  },
]);
