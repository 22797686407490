import React, { useState } from 'react';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { CloseIcon } from 'assets/icons';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import { SectionLabel } from 'components/common/SectionLabel';
import { Button } from 'components/common/Button';
import { InputBasic } from 'components/common/Input';
import {
  StyledButtonContainer,
  StyledContainer,
  StyledFormContainer,
  StyledWrapper,
} from 'components/common/Forms/styled';
import { toastSuccess } from 'utils/toast';
import { actionBlocker } from 'utils/actionBlocker';
import { Box } from 'components/common/Box';
import {
  useAddReportMutation,
  useGetAllReportsQuery,
} from 'store/services/reports';
import {
  Report,
  AddReportFormFields,
} from 'interfaces/reports';
import { StyledModalBoxesContainer } from 'components/Layout/Modal/styled';
import { RadioInput } from 'components/common/RadioInput';
import { ReportTemplateOptions } from 'utils/constants';
import { CheckboxInput } from 'components/common/CheckboxInput';

interface Props {
  onClose: () => void;
  onSuccess?: (result: Report) => void;
}

enum Step {
  FIRST,
  FROM_BLANK,
  FROM_TEMPLATE,
}

export const AddReport = ({
  onClose,
  onSuccess,
}: Props) => {
  const [secondStep, setSecondStep] = useState<Step | null>(null);
  const [addReport, { isLoading }] = useAddReportMutation();
  const { data: reports } = useGetAllReportsQuery();
  const allReportsNames = reports?.map((report) => report.name.toLowerCase()) || [];

  const schema = yup.object({
    name: yup.string()
      .required('Report name is required.')
      .test(
        'notOneOfCaseInsensitive',
        'Report with this name already exists.',
        (val) => !allReportsNames.includes(val?.toLowerCase() || ''),
      ),
    template: yup.object({
      id: yup.string(),
      name: yup.string(),
    })
      .nullable(),
  })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors, isDirty },
  } = useForm<AddReportFormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      template: null,
      includeCopies: false,
      includeCustom: false,
      includeAccounts: false,
    },
  });

  const handleSaveAndClose = handleSubmit((data) => {
    const payload = secondStep === Step.FROM_BLANK
      ? { name: data.name }
      : {
        name: data.name,
        template: {
          name: data.template!.id,
          includeCopies: data.includeCopies,
          includeCustom: data.includeCustom,
          includeAccounts: data.includeAccounts,
        },
      };

    return addReport(payload)
      .unwrap()
      .then((result) => {
        toastSuccess('Report successfully added.');
        onClose();
        onSuccess && onSuccess(result);
      });
  });

  return (
    <StyledContainer>
      <ButtonsContainer
        paddingBottom={40}
        alignTop
      >
        <SectionLabel
          data-cy="ar-header"
          marginBottom={0}
        >
          <span>
            Add Report
            <ins>{secondStep ? 2 : 1} of 2</ins>
          </span>
        </SectionLabel>
        <Spacer />
        <Button
          aria-label="Close"
          variant="icon"
          size="large"
          onClick={() => actionBlocker(onClose, isDirty)}
          data-cy="ar-button-close"
        >
          <CloseIcon />
        </Button>
      </ButtonsContainer>
      <StyledWrapper suppressOverflow>
        {secondStep === null && (
          <StyledModalBoxesContainer>
            <Box marginBottom={0}>
              <SectionLabel tertiary>
                <span>
                  Create from Template
                  <p>Start building a report from a template. Simply select where you want to start.</p>
                </span>
              </SectionLabel>
              <div>
                <Button onClick={() => setSecondStep(Step.FROM_TEMPLATE)}>
                  CREATE FROM TEMPLATE
                </Button>
              </div>
            </Box>
            <Box marginBottom={0}>
              <SectionLabel tertiary>
                <span>
                  Create from Blank
                  <p>Build a report from scratch. Select a report type and build your report lines.</p>
                </span>
              </SectionLabel>
              <div>
                <Button onClick={() => setSecondStep(Step.FROM_BLANK)}>
                  CREATE FROM BLANK
                </Button>
              </div>
            </Box>
          </StyledModalBoxesContainer>
        )}
        {secondStep !== null && (
          <StyledFormContainer onSubmit={handleSaveAndClose}>
            <SectionLabel secondary>
              {secondStep === Step.FROM_BLANK && 'Create from Blank'}
              {secondStep === Step.FROM_TEMPLATE && 'Create from Template'}
            </SectionLabel>
            <InputBasic
              isRequired
              labelText="Report Name"
              placeholder="Add report name"
              {...register('name')}
              onBlur={(e) => {
                const fieldValue = e.target.value;

                if (fieldValue) {
                  setValue('name', fieldValue.trim(), { shouldValidate: true });
                }
              }}
              error={errors.name?.message}
              dataCy="ar-input-name"
            />
            {secondStep === Step.FROM_TEMPLATE && (
              <>
                <Controller
                  name="template"
                  control={control}
                  render={({ field }) =>
                    <RadioInput
                      labelText="Report Template"
                      valueField="id"
                      labelField="name"
                      defaultValue={ReportTemplateOptions[0]}
                      options={ReportTemplateOptions}
                      {...field}
                      error={errors.template?.message}
                    />
                  }
                />
                <Controller
                  name="includeCopies"
                  control={control}
                  render={({ field }) =>
                    <CheckboxInput
                      labelText="Include Copies of System Metrics"
                      tooltip="If checked, report will also include your own copies (duplicates) of system metrics."
                      isOptional
                      {...field}
                      checkboxLabel="Include System Copies"
                      defaultValue={getValues('includeCopies')}
                      error={errors.includeCopies?.message}
                    />
                  }
                />
                <Controller
                  name="includeCustom"
                  control={control}
                  render={({ field }) =>
                    <CheckboxInput
                      labelText="Include Custom Metrics"
                      tooltip="If checked, report will also include your custom metrics."
                      isOptional
                      {...field}
                      checkboxLabel="Include Custom Metrics"
                      defaultValue={getValues('includeCustom')}
                      error={errors.includeCustom?.message}
                    />
                  }
                />
                <Controller
                  name="includeAccounts"
                  control={control}
                  render={({ field }) =>
                    <CheckboxInput
                      labelText="Include Facta Accounts"
                      tooltip="If checked, report will also include your Facta Accounts."
                      isOptional
                      {...field}
                      checkboxLabel="Include Facta Accounts"
                      defaultValue={getValues('includeAccounts')}
                      error={errors.includeAccounts?.message}
                    />
                  }
                />
              </>
            )}
          </StyledFormContainer>
        )}
      </StyledWrapper>
      {secondStep !== null && (
        <StyledButtonContainer pushRight>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={() => setSecondStep(null)}
            disabled={isLoading}
            data-cy="ar-button-save-new"
          >
            BACK
          </Button>
          <Button
            type="button"
            onClick={handleSaveAndClose}
            disabled={isLoading}
            isLoading={isLoading}
            data-cy="ar-button-save-close"
          >
            CREATE REPORT
          </Button>
        </StyledButtonContainer>
      )}
    </StyledContainer>
  );
};
