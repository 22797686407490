import * as React from "react";

const SvgComponent = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      opacity={0.9}
      fill="inherit">
      <path d="m9 20-1.41-1.41L13.17 13H3v-2h10.17L7.58 5.42 9 4l8 8-8 8Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 20V4h2v16h-2Z" />
    </g>
  </svg>
);

export default SvgComponent;
