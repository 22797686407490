import { Link } from 'react-router-dom';
import { ColDef } from '@ag-grid-community/core';
import { caseInsensitiveComparator } from "utils/aggrid";
import { AgGridCommonCellRenderer } from "components/common/AgGridCommonCellRenderer";

const OverviewCellRenderer = (item: any) => {
  if (!item.data) return null;

  return (
    <Link to={`/data-input/contract-management/contracts/${item.data.id}`}>
      <span>{item.value}</span>
    </Link>
  );
};

export const getColumnDefs = (): Array<ColDef> => ([
  {
    field: 'customer.name',
    headerName: 'Customer',
    sortable: true,
    resizable: true,
    cellRenderer: AgGridCommonCellRenderer('customer'),
    flex: 2,
    comparator: caseInsensitiveComparator(),
    enableRowGroup: true,
  },
  {
    field: 'name',
    headerName: 'Contract',
    sortable: true,
    resizable: true,
    cellRenderer: OverviewCellRenderer,
    flex: 2,
    comparator: caseInsensitiveComparator(),
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    resizable: true,
    enableRowGroup: true,
    flex: 1,
  },
  {
    field: 'arr',
    headerName: 'ARR',
    sortable: true,
    resizable: true,
    type: 'currency',
    aggFunc: 'sum',
    sort: 'desc',
    flex: 1,
  },
  {
    field: 'mrr',
    headerName: 'MRR',
    sortable: true,
    resizable: true,
    type: 'currency',
    aggFunc: 'sum',
    flex: 1,
  },
]);
