import styled, { css } from "styled-components";
import { theme } from "theme/theme";

interface StyledMetricProps {
  over?: boolean;
  beingDragged?: boolean
}

export const StyledMetric = styled.div<StyledMetricProps>`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 16px;

  ${props => props.beingDragged && css`
    opacity: 0.2;
    background-color: ${theme.colors.warningBleach};
  `}

  ${props => props.over && css`
    opacity: 0.5;
    background-color: ${theme.colors.successBleach};
  `}

  & > div:nth-child(2) {
    flex: 1;
    margin-right: 8px;
  }
`;

export const DragHandle = styled.div`
  color: ${theme.colors.textDark};
  cursor: grab;

  svg {
    width: 20px;
    height: 20px;
  }
`;
