import React, {
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useGetContractsSearchQuery,
  useGetContractsSummaryQuery,
} from 'store/services/contracts';
import { FactaTable } from 'components/FactaTable';
import { AgGridReact } from '@ag-grid-community/react';
import { getColumnDefs } from './columnDefinitions';
import { useNavigate } from 'react-router';
import {
  addDays,
  addMonths,
} from 'date-fns';
import { formatDateToISO } from 'utils/dates';
import { SummaryItems } from 'components/SummaryItems';

export const ContractsSummary = () => {
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();
  const [range, setRange] = useState(12);
  const [end] = useState(formatDateToISO(addDays(new Date(), 1)));
  const start = useMemo(() => formatDateToISO(addMonths(new Date(), -range)), [range]);

  const { data: contractsSummary, isFetching } = useGetContractsSummaryQuery();

  const { recentContracts, isFetching: isFetchingContracts } = useGetContractsSearchQuery({
    filtering: {
      deleted: false,
      startDateRange: [start, end],
    },
    pagination: {
      page: 0,
      perPage: 0,
    },
    simplified: false,
  }, {
    selectFromResult: ({ data, isFetching }) => ({
      recentContracts: data?.data,
      isFetching,
    }),
  });

  const columnDefs = getColumnDefs();

  return (
    <>
      <SummaryItems
        items={contractsSummary}
        isLoading={isFetching}
      />
      <FactaTable
        gridRef={gridRef}
        data={recentContracts}
        columnDefs={columnDefs}
        selectedRowsLength={0}
        entityName="Recent Contract"
        dynamicGridOptions={{
          noRowsOverlayComponent: () => `No recent contracts. Only contracts with start date within the last ${range} month${range > 1 ? 's' : ''} are shown here.`,
        }}
        customButtons={[
          {
            title: 'Show last:',
            pushRight: true,
          },
          {
            title: 'Month',
            onClick: () => setRange(1),
            active: range === 1,
            variant: 'borderless',
            size: 'large',
          },
          {
            title: 'Quarter',
            onClick: () => setRange(3),
            active: range === 3,
            variant: 'borderless',
            size: 'large',
          },
          {
            title: 'Year',
            onClick: () => setRange(12),
            active: range === 12,
            variant: 'borderless',
            size: 'large',
          },
          {
            title: 'View all',
            onClick: () => navigate('/data-input/contract-management/contracts'),
            variant: 'borderless',
            size: 'large',
            pushRight: true,
          },
        ]}
        condensed
        minHeight={250}
        isLoading={isFetchingContracts}
        suppressColumnViews
      />
    </>
  );
};
