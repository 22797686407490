import styled, { css } from "styled-components";
import { Button } from "../Button";
import { theme } from "theme/theme";

interface RadioButtonProps {
  selected?: boolean;
  disabled?: boolean;
  condensed?: boolean;
};

export const RadioButton = styled(Button)<RadioButtonProps>`
  font-size: 16px;
  line-height: 24px;
  font-weight: ${props => props.selected ? 600 : 500};
  flex-wrap: wrap;
  
  ${props => props.condensed && css`
    padding: 7px 12px !important;
  `}

  svg {
    transition: all 200ms ease;
    color: ${props => props.selected ? theme.colors.primary : theme.colors.textDisabled};
  }

  &:hover svg {
    color: ${theme.colors.primary};
  }
  
  ${props => props.disabled && css`
    opacity: 1;
    background-color: ${theme.colors.secondaryBackground};
    color: ${theme.colors.textDisabled};

    svg {
      color: ${theme.colors.textDisabled};
    }
  `}

  span {
    margin-left: auto;
    font-size: 12px;
    font-weight: 500;
    color: ${theme.colors.textDisabled};
  }
`;
