import styled, { css } from "styled-components";
import { ResizableBox } from "react-resizable";
import { theme } from "theme/theme";
import { ANIMATION_DURATION } from "utils/constants";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: ${theme.colors.white};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  min-height: 0;
`;

interface ContentContainerProps {
  secondary?: boolean;
}

export const ContentContainer = styled.div<ContentContainerProps>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
  height: 100% !important;
  background-color: ${props => props.secondary ? theme.colors.secondaryBackground : theme.colors.white};
  overflow-y: auto;
  overflow-x: hidden;
`;

interface ContentBodyProps {
  removeMargin?: boolean;
  contentWidth?: number;
}

export const ContentBody = styled.div<ContentBodyProps>`
  flex: 1;
  padding: ${props => props.removeMargin ? '0' : '40px'};
  display: flex;
  flex-direction: column;

  ${props => props.contentWidth && css`
    max-width: ${props.contentWidth}px;
    width: fill-available;
    margin: 0 auto;
  `}

  > * {
    flex-shrink: 0;
  }

  @media screen and (max-height: 800px) {
    padding: ${props => props.removeMargin ? '0' : '30px 40px'};
  }
`;

interface RightPaneContainerProps {
  $isResizing?: boolean;
}

export const RightPaneContainer = styled(ResizableBox) <RightPaneContainerProps>`
  position: relative;
  height: initial !important;
  overflow: hidden;
  display: flex;
  ${props => !props.$isResizing && css`
    transition: width ${ANIMATION_DURATION}ms ease;
  `}
`;

export const RightPaneBody = styled.div<{ collapsed?: boolean }>`
  margin-left: 14px;
  overflow-y: auto;
  width: 100%;
  opacity: ${props => props.collapsed ? 0 : 1};
  display: flex;
  flex-direction: column;

  header {
    position: sticky;
    background-color: ${theme.colors.white};
    z-index: 1;
    top: 0;
    padding: 40px 40px 0;
  }

  main {
    flex: 1;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
  }

  footer {
    position: sticky;
    bottom: 0;
    background-color: ${theme.colors.white};
    padding: 0 40px 40px;
  }

  @media screen and (max-height: 800px) {
    footer {
      padding-bottom: 30px;
    }

    header {
      padding-top: 30px;
    }
  }
`;

export const CollapseHandle = styled.div<{ collapsed?: boolean }>`
  position: absolute; 
  top: 10px;
  left: ${props => props.collapsed ? '-40px' : '-4px'};
  z-index: 2;

  button {
    background-color: ${theme.colors.borderColorDark};
    color: ${theme.colors.white};
    width: 30px;
    height: 30px;
    padding: 4px;

    ${props => props.collapsed ? css`
      border-top-right-radius: 0%;
      border-bottom-right-radius: 0%;
    ` : css`
      border-top-left-radius: 0%;
      border-bottom-left-radius: 0%;
    `}
  }
`;

export const Handle = styled.div<{ collapsed?: boolean }>`
  position: absolute;
  width: 20px;
  height: auto;
  border-left: solid 14px ${theme.colors.borderColorDark};
  top: 0;
  bottom: 0;
  left: ${props => props.collapsed ? '26px' : 0};
  transition: border-left ${ANIMATION_DURATION}ms ease;

  :after {
    content: '||';
    position: absolute;
    top: 50%;
    left: -10px;
    font-size: 10px;
    opacity: 1;
    color: #5d5d5d;
    transition: opacity ${ANIMATION_DURATION}ms ease;
  }

  &:hover {
    border-left: solid 14px ${theme.colors.borderColorLight};
    cursor: ew-resize;

    ${CollapseHandle} {
      opacity: 1;

      button {
        background-color: ${theme.colors.borderColorLight};
        
        &:hover {
          color: ${theme.colors.textDark};
          box-shadow: none;
        }
      }
    }
  };

  &.is-resizing {
    border-left: solid 14px #C4C4D4;
    cursor: ew-resize;
  };
`;

export const StyledLoadingWrapper = styled.div`
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  width: 100%;
`;

export const PanesWrapper = styled.div`
  display: flex;
  width: 100%;
`;
