export const awsExports = {
  Auth: {
    region: import.meta.env.VITE_APP_COGNITO_REGION,
    userPoolId: import.meta.env.VITE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_APP_COGNITO_WEB_CLIENT_ID,
    oauth: {
      domain: import.meta.env.VITE_APP_COGNITO_OAUTH_DOMAIN,
      scope: ['openid', 'phone', 'email', 'profile', 'aws.cognito.signin.user.admin'],
      responseType: "code",
    },
    cookieStorage: {
      domain: import.meta.env.VITE_APP_COGNITO_COOKIE_DOMAIN,
      secure: false,
      path: '/',
      expires: 365,
    },
  },
};
