import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { PeriodType } from "interfaces/accounts";
import { toggleArrayElement } from "utils/toggleArrayElement";

interface State {
  viewBy: PeriodType[];
}

const initialState: State = {
  viewBy: ['monthly'],
};

const accountMappingSlice = createSlice({
  name: 'accountMapping',
  initialState,
  reducers: {
    clear: () => initialState,
    toggleViewBy: (state, action: PayloadAction<PeriodType>) => {
      const { payload } = action;
      state.viewBy = toggleArrayElement(state.viewBy, payload);
    },
  },
});

export const {
  reducer: accountMappingReducer,
  actions: accountMappingActions,
} = accountMappingSlice;
