import React from 'react';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  ArrowLeftIcon,
  CloseIcon,
} from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { SectionLabel } from 'components/common/SectionLabel';
import { Button } from 'components/common/Button';
import { InputBasic } from 'components/common/Input';
import { Dropdown } from 'components/common/Dropdown';
import {
  Product,
  ProductFormFields,
} from 'interfaces/products';
import { RevenueTypeOptions } from 'utils/constants';
import {
  useAddProductMutation,
  useGetAllProductsQuery,
} from 'store/services/products';
import {
  StyledButtonContainer,
  StyledFormContainer,
} from 'components/common/Forms/styled';
import { prepareProductAddPayload } from 'utils/products';
import { useGetAllTagsQuery } from 'store/services/tags';
import { toastSuccess } from 'utils/toast';
import { actionBlocker } from 'utils/actionBlocker';
import { TagType } from 'interfaces/tags';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import { formStateActions } from 'store/slices/formState';
import {
  formDataSelector,
  predefinedDataSelector,
} from 'store/selectors/formState';
import { tagsSortFn } from 'utils/tags';

interface Props {
  onClose: () => void;
  onSuccess?: (result: Product) => void;
  onGoBack?: () => void;
  onAddTag?: () => void;
  isIndirectOpen?: boolean;
}

export const AddProduct = ({
  onClose,
  onSuccess,
  onGoBack,
  onAddTag,
  isIndirectOpen,
}: Props) => {
  const dispatch = useAppDispatch();
  const formData = useAppSelector(formDataSelector);
  const predefinedData = useAppSelector(predefinedDataSelector);
  const [addProduct, { isLoading }] = useAddProductMutation();

  const { tagOptions } = useGetAllTagsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      tagOptions: data
        ?.filter((tag) => !tag.deleted && tag.type === TagType.PRODUCT)
        .sort(tagsSortFn) || [],
    }),
  });

  const {
    productNames,
  } = useGetAllProductsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      productNames: data
        ?.filter((product) => !product.deleted)
        .map((product) => product.name),
    }),
  });

  const schema = yup.object({
    name: yup.string()
      .required('Product name is required.')
      .test(
        'isUnique',
        'Product name must be unique',
        (val) => !(productNames?.includes(val || '')),
      ),
    description: yup.string(),
    revenueType: yup.array()
      .of(
        yup.object({
          label: yup.string(),
          value: yup.string(),
        }))
      .min(1, 'Revenue Type is required.'),
    crmId: yup.string(),
    tags: yup.array(),
  })
    .required();

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<ProductFormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: predefinedData?.name || '',
      description: '',
      revenueType: [],
      crmId: '',
      tags: [],
      ...(isIndirectOpen ? [] : formData),
    },
  });

  const handleSaveAndNew = handleSubmit((data) => {
    const product = prepareProductAddPayload(data);

    return addProduct(product)
      .unwrap()
      .then((result) => {
        toastSuccess('Product successfully added.');
        onSuccess && onSuccess(result);
        reset();
        setTimeout(() => clearErrors(), 0);
      });
  });

  const handleSaveAndClose = handleSubmit((data) => {
    const product = prepareProductAddPayload(data);

    return addProduct(product)
      .unwrap()
      .then((result) => {
        toastSuccess('Product successfully added.');
        onClose();
        onSuccess && onSuccess(result);
      });
  });

  const handleSaveAndBack = handleSubmit((data) => {
    const product = prepareProductAddPayload(data);

    return addProduct(product)
      .unwrap()
      .then((result) => {
        dispatch(formStateActions.updateKeyInFormData({ key: 'product', value: [result] }));
        toastSuccess('Product successfully added.');
        onSuccess && onSuccess(result);
        onGoBack && onGoBack();
      });
  });

  const handleAddTag = ({ name }: { name: string }) => {
    if (onAddTag) {
      dispatch(formStateActions.setPredefinedData({
        name,
        type: TagType.PRODUCT,
      }));
      dispatch(formStateActions.setFormData(getValues()));
      onAddTag();
    }
  };

  return (
    <>
      <header>
        <ButtonsContainer paddingBottom={16}>
          {onGoBack && (
            <Button
              aria-label="Go back"
              variant="icon"
              size="large"
              onClick={onGoBack}
              data-cy={`ap-button-back`}
            >
              <ArrowLeftIcon />
            </Button>
          )}
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={() => actionBlocker(onClose, isDirty)}
            pushRight
            data-cy="ap-button-close"
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <SectionLabel data-cy="ap-header">
          <span>
            Add Product
          </span>
        </SectionLabel>
      </header>
      <main>
        <StyledFormContainer>
          <InputBasic
            isRequired
            labelText="Product Name"
            placeholder="Add product name"
            {...register('name')}
            onBlur={(e) => {
              const fieldValue = e.target.value;

              if (fieldValue) {
                setValue('name', fieldValue.trim(), { shouldValidate: true });
              }
            }}
            error={errors.name?.message}
            dataCy="ap-input-name"
          />
          <InputBasic
            isOptional
            labelText="Description"
            placeholder="Describe your product"
            {...register('description')}
            error={errors.description?.message}
            dataCy="ap-input-description"
          />
          <Controller
            name="revenueType"
            control={control}
            render={({ field }) =>
              <Dropdown
                isRequired
                labelText="Revenue Type"
                options={RevenueTypeOptions}
                values={getValues('revenueType')}
                {...field}
                dataCy="ap-dropdown-revenue"
                error={errors.revenueType?.message}
              />
            }
          />
          <Controller
            name="tags"
            control={control}
            render={({ field }) =>
              <Dropdown
                isOptional
                labelText="Tags"
                options={tagOptions}
                labelField="name"
                valueField="id"
                searchBy="name"
                values={getValues('tags')}
                {...field}
                dataCy="ap-dropdown-tags"
                error={errors.tags?.message}
                create={!!onAddTag}
                onCreateNew={handleAddTag}
                multi
              />
            }
          />
          <InputBasic
            isOptional
            labelText="CRM ID"
            placeholder="Paste unique identifier"
            tooltip="Provide a reference ID from your CRM or any place where you track your products."
            {...register('crmId')}
            error={errors.crmId?.message}
            dataCy="ap-input-crm"
          />
        </StyledFormContainer>
      </main>
      <footer>
        <StyledButtonContainer pushRight>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={isIndirectOpen ? onGoBack : handleSaveAndClose}
            disabled={isLoading}
            isLoading={isIndirectOpen ? false : isLoading}
            data-cy="ap-button-save-close"
          >
            {isIndirectOpen ? 'BACK' : 'SAVE & CLOSE'}
          </Button>
          <Button
            type="submit"
            onClick={isIndirectOpen ? handleSaveAndBack : handleSaveAndNew}
            disabled={isLoading}
            isLoading={isLoading}
            data-cy="ap-button-save-new"
          >
            {isIndirectOpen ? 'SAVE & BACK' : 'SAVE & NEW'}
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};
