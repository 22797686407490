import styled, { css } from "styled-components";
import { theme } from "theme/theme";
import { ANIMATION_DURATION } from "utils/constants";
import { Button } from "../Button";

interface StyledQuickFilterProps {
  pushRight?: boolean;
  inline?: boolean;
}

export const StyledQuickFilter = styled.div<StyledQuickFilterProps>`
  display: flex;
  background-color: ${props => props.inline ? theme.colors.transparent : theme.colors.borderColorDark};
  border-radius: 16px;
  ${props => props.pushRight && css`
    margin-left: auto;
  `}

  input {
    background-color: ${theme.colors.borderColorDark};
    border: 0;

    &:focus {
      outline: none;
    }
  }
`;

interface StyledSearchInputWrapperProps {
  isOpen?: boolean;
  inline?: boolean;
}

export const StyledSearchInputWrapper = styled.div<StyledSearchInputWrapperProps>`
  width: ${props => props.isOpen ? '200' : '0'}px;
  overflow: hidden;
  display: flex;
  align-items: center;
  transition: all ${ANIMATION_DURATION}ms ease;

  input {
    border-radius: 15px;
    height: 30px;
    padding: 0 ${props => props.inline ? 12 : 6}px;;
    width: 160px;
    margin: 0 2px;
  }
`;

export const StyledCloseButton = styled(Button)`
  margin: 7px; 
  width: 18px;
  height: 18px;
  background-color: ${theme.colors.fieldBorder};
  color: ${theme.colors.borderColorDark};

  &:hover {
    background-color: ${theme.colors.textDark};
  }
  
  svg {
    width: 13px;
    height: 13px;
  }
`;

