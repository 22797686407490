import React from "react";
import { BulkImportField } from "interfaces/bulkImport";
import {
  CheckCircleIcon,
  DuplicateIcon,
  ErrorIcon,
} from "assets/icons";
import { StyledTooltipText } from "components/common/Tooltip/styled";
import {
  ColDef,
  ITooltipParams,
} from "@ag-grid-community/core";
import { formatDateStringToDisplay } from "utils/dates";
import { AgGridCellRendererTags } from "components/common/AgGridCellRendererTags";
import { ErrorCell } from "components/common/ErrorCell";
import { Status } from "components/common/Status";
import {
  caseInsensitiveComparator,
  dateComparator,
  numericComparator,
} from "utils/aggrid";
import { BulkImporContractsRowError } from "interfaces/bulkImportContracts";
import { Tag } from "interfaces/tags";
import {
  dateFilter,
  multiFilter,
  numberFilter,
} from "utils/filters";

export const CellRenderer = ({
  valueFormatted,
  value: nativeValue,
  colDef,
}: {
  valueFormatted: any;
  value: BulkImportField<any, any>;
  colDef: ColDef;
}) => {
  const {
    errorCode,
    providedValue,
  } = nativeValue;

  if (errorCode === 'missing_field') {
    return <ErrorCell>
      {colDef.field === 'contractLineName' || colDef.field === 'contractLineAmount' ? 'Enter' : 'Select'} {colDef.headerName}
    </ErrorCell>;
  } else if (errorCode) {
    return <ErrorCell>{providedValue}</ErrorCell>;
  } else {
    return valueFormatted;
  }
};

export const StatusRenderer = ({ value }: { value: BulkImporContractsRowError }) => {
  switch (value) {
    case 'missing_field':
    case 'multiple_errors':
    case 'wrong_value':
      return (
        <Status color="warning">
          <ErrorIcon />
        </Status>
      );
    case 'duplicate':
      return (
        <Status color="warning">
          <DuplicateIcon />
        </Status>
      );
    default:
      return (
        <Status color="success">
          <CheckCircleIcon />
        </Status>
      );
  }
};

const getTooltip = ({ value }: ITooltipParams) => {
  switch (value) {
    case 'missing_field':
      return 'This contract line is missing one or more required fields. Update these fields before submitting.';
    case 'wrong_value':
      return 'Wrong value provided for one or more fields. Update these fields before submitting.';
    case 'multiple_errors':
      return 'This contract line contains multiple issues. Update highlighted fields before submitting.';
    case 'duplicate':
      return 'Duplicate contract line. You need to either rename or delete it before submitting.';
  }
};

export const getColumnDefs = (): Array<ColDef> => ([
  {
    field: 'contractLineErrors',
    headerName: 'Status',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    width: 160,
    cellRenderer: StatusRenderer,
    tooltipComponent: ({ value }: ITooltipParams) => <StyledTooltipText data-show>{value}</StyledTooltipText>,
    tooltipValueGetter: getTooltip,
  },
  {
    field: 'contractLineName',
    headerName: 'Line Name',
    resizable: true,
    sortable: true,
    flex: 1,
    cellRenderer: CellRenderer,
    minWidth: 250,
    valueFormatter: ({ value }) => value.providedValue ? value.providedValue : '-',
    getQuickFilterText: ({ value }) => value.providedValue,
    comparator: caseInsensitiveComparator('providedValue'),
    ...multiFilter,
    filterValueGetter: ({ data }) => data.contractLineName.providedValue,
  },
  {
    field: 'contractName',
    headerName: 'Contract',
    resizable: true,
    sortable: true,
    flex: 1,
    cellRenderer: CellRenderer,
    minWidth: 250,
    valueFormatter: ({ value }) => value.providedValue ? value.providedValue : '-',
    getQuickFilterText: ({ value }) => value.providedValue,
    comparator: caseInsensitiveComparator('providedValue'),
    ...multiFilter,
    filterValueGetter: ({ data }) => data.contractName.providedValue,
  },
  {
    field: 'contractLineProduct',
    headerName: 'Product',
    resizable: true,
    sortable: true,
    flex: 1,
    cellRenderer: CellRenderer,
    minWidth: 250,
    valueFormatter: ({ value }) => value.providedValue ? value.providedValue : '-',
    getQuickFilterText: ({ value }) => value.providedValue,
    comparator: caseInsensitiveComparator('providedValue'),
    ...multiFilter,
  },
  {
    field: 'contractLineAmount',
    headerName: 'Amount',
    resizable: true,
    sortable: true,
    cellRenderer: CellRenderer,
    minWidth: 150,
    getQuickFilterText: ({ value }) => value.matchedValue,
    type: 'currencyOfMatchedValue',
    comparator: numericComparator('matchedValue'),
    ...numberFilter,
    filterValueGetter: ({ data }) => data.contractLineAmount.matchedValue,
  },
  {
    field: 'contractLineBookingDate',
    headerName: 'Booking Date',
    resizable: true,
    sortable: true,
    cellRenderer: CellRenderer,
    minWidth: 150,
    valueFormatter: ({ value }) => value.matchedValue ? formatDateStringToDisplay(value.matchedValue) : '-',
    getQuickFilterText: ({ value }) => value.matchedValue,
    comparator: dateComparator('matchedValue'),
    ...dateFilter,
    filterValueGetter: ({ data }) => data.contractLineBookingDate.matchedValue,
  },
  {
    field: 'contractLineStartDate',
    headerName: 'Start Date',
    resizable: true,
    sortable: true,
    cellRenderer: CellRenderer,
    minWidth: 150,
    valueFormatter: ({ value }) => value.matchedValue ? formatDateStringToDisplay(value.matchedValue) : '-',
    getQuickFilterText: ({ value }) => value.matchedValue,
    comparator: dateComparator('matchedValue'),
    ...dateFilter,
    filterValueGetter: ({ data }) => data.contractLineStartDate.matchedValue,
  },
  {
    field: 'contractLineEndDate',
    headerName: 'End Date',
    resizable: true,
    sortable: true,
    cellRenderer: CellRenderer,
    minWidth: 150,
    valueFormatter: ({ value }) => value.matchedValue ? formatDateStringToDisplay(value.matchedValue) : '-',
    getQuickFilterText: ({ value }) => value.matchedValue,
    comparator: dateComparator('matchedValue'),
    ...dateFilter,
    filterValueGetter: ({ data }) => data.contractLineEndDate.matchedValue,
  },
  {
    field: 'contractLineCancelDate',
    headerName: 'Cancel Date',
    resizable: true,
    sortable: true,
    cellRenderer: CellRenderer,
    minWidth: 150,
    valueFormatter: ({ value }) => value.matchedValue ? formatDateStringToDisplay(value.matchedValue) : '-',
    getQuickFilterText: ({ value }) => value.matchedValue,
    comparator: dateComparator('matchedValue'),
    ...dateFilter,
    filterValueGetter: ({ data }) => data.contractLineCancelDate.matchedValue,
  },
  {
    field: 'contractLineRevRecStartDate',
    headerName: 'Recognition Start Date',
    resizable: true,
    sortable: true,
    cellRenderer: CellRenderer,
    minWidth: 150,
    valueFormatter: ({ value }) => value.matchedValue ? formatDateStringToDisplay(value.matchedValue) : '-',
    getQuickFilterText: ({ value }) => value.matchedValue,
    comparator: dateComparator('matchedValue'),
    ...dateFilter,
    filterValueGetter: ({ data }) => data.contractLineRevRecStartDate.matchedValue,
  },
  {
    field: 'contractLineRevRecEndDate',
    headerName: 'Recognition End Date',
    resizable: true,
    sortable: true,
    cellRenderer: CellRenderer,
    minWidth: 150,
    valueFormatter: ({ value }) => value.matchedValue ? formatDateStringToDisplay(value.matchedValue) : '-',
    getQuickFilterText: ({ value }) => value.matchedValue,
    comparator: dateComparator('matchedValue'),
    ...dateFilter,
    filterValueGetter: ({ data }) => data.contractLineRevRecEndDate.matchedValue,
  },
  {
    field: 'contractLineTags',
    headerName: 'Tags',
    sortable: true,
    resizable: true,
    flex: 1,
    cellRenderer: ({ value }: any) => AgGridCellRendererTags({
      value: value.matchedValue || [],
      errorCode: value.errorCode,
      errorValue: value.providedValue,
    }),
    minWidth: 250,
    comparator: caseInsensitiveComparator('providedValue'),
    getQuickFilterText: ({ value }) => value.providedValue,
    ...multiFilter,
    filterValueGetter: (params) => params.data?.contractLineTags?.matchedValue?.map((tag: Tag) => tag.name) || [],
  },
  {
    field: 'contractLineCRMID',
    headerName: 'CRM ID',
    resizable: true,
    sortable: true,
    cellRenderer: CellRenderer,
    minWidth: 250,
    valueFormatter: ({ value }) => value.providedValue ? value.providedValue : '-',
    getQuickFilterText: ({ value }) => value.providedValue,
    comparator: caseInsensitiveComparator('providedValue'),
    ...multiFilter,
    filterValueGetter: ({ data }) => data.contractLineCRMID.providedValue,
  },
  {
    field: 'contractLineExternalLink',
    headerName: 'External Link',
    resizable: true,
    sortable: true,
    cellRenderer: CellRenderer,
    minWidth: 250,
    valueFormatter: ({ value }) => value.providedValue ? value.providedValue : '-',
    getQuickFilterText: ({ value }) => value.providedValue,
    comparator: caseInsensitiveComparator('providedValue'),
  },
  {
    field: 'contractLineNote',
    headerName: 'Note',
    resizable: true,
    sortable: true,
    cellRenderer: CellRenderer,
    minWidth: 250,
    valueFormatter: ({ value }) => value.providedValue ? value.providedValue : '-',
    getQuickFilterText: ({ value }) => value.providedValue,
    comparator: caseInsensitiveComparator('providedValue'),
    ...multiFilter,
    filterValueGetter: ({ data }) => data.contractLineNote.providedValue,
  },
  {
    field: 'contractLineContractBasedMRRCalc',
    headerName: 'Contract Based MRR Calc',
    resizable: true,
    sortable: true,
    cellRenderer: CellRenderer,
    minWidth: 250,
    valueFormatter: ({ value }) => value.providedValue ? value.providedValue : '-',
    getQuickFilterText: ({ value }) => value.providedValue,
    comparator: caseInsensitiveComparator('providedValue'),
  },
]);
