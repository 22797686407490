import { useState } from "react";
import { UseFormTrigger } from "react-hook-form";
import { useDebouncedEffect } from "./useDebouncedEffect";

/**
 * Hook checks field's uniqueness on backend and returns boolean
 * @param func uniqueness endpoint trigger function. ie. subscriptionUnique
 * @param funcParams object of key/value pairs
 * @param yupFieldName name of yup field to be updated
 * @param trigger react-hook-form's trigger() function
 * @param exclude array of strings to exclude from uniqueness check
 * @returns isUnique boolean, name setter function
 */
export const useUniquenessCheck = (
  func: Function,
  funcParams: { [key: string]: any },
  yupFieldName: string,
  trigger: UseFormTrigger<any>,
  exclude: Array<string | undefined> = [],
): [boolean, React.Dispatch<React.SetStateAction<string>>] => {
  const [value, setValue] = useState('');
  const [isUnique, setIsUnique] = useState(true);
  const deps = Object.values(funcParams);
  const eachParamExists = deps.every((dep) => !!dep);

  useDebouncedEffect(() => {
    if (eachParamExists && value && !exclude.includes(value)) {
      func({ ...funcParams, name: value})
        .unwrap()
        .then((isUnique: boolean) => {
          setIsUnique(isUnique);
          trigger(yupFieldName);
        })
        .catch(() => setIsUnique(true));
    } else {
      setIsUnique(true);
      if (value) {
        trigger(yupFieldName);
      }
    }
  }, [...deps, value], 300);

  return [
    isUnique,
    setValue,
  ];
};
