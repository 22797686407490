import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

interface State {
  unlinkType: string;
}

const initialState: State = {
  unlinkType: 'next',
};

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setUnlinkType: (state, action: PayloadAction<string>) => {
      state.unlinkType = action.payload;
    },
    clear: () => initialState,
  },
});

export const {
  reducer: subscriptionsReducer,
  actions: subscriptionsActions,
} = subscriptionsSlice;
