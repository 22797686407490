import { Spacer } from "components/common/ButtonsContainer";
import { Tooltip } from "components/common/Tooltip";
import styled from "styled-components";
import { theme } from "theme/theme";

interface Props {
  isRequired?: boolean;
  isOptional?: boolean;
  name: string;
  labelText: string;
  tooltip?: string;
  annotation?: string | JSX.Element;
  annotationTooltip?: string;
};

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 8px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  color: ${theme.colors.textDark};
`;

export const StyledOptional = styled.span`
  margin-left: 8px;
  color: ${theme.colors.textDisabled};
`;

export const StyledSup = styled.sup`
  color: ${theme.colors.error};
  margin-left: 4px;
`;

export const StyledTooltipWrapper = styled.div<{ removeRightMargin?: boolean }>`
  display: block;
  margin-left: 8px;
  margin-right: ${props => props.removeRightMargin ? 0 : 8}px;
  height: 16px;
`;

export const StyledAnnotation = styled.div`
  font-weight: 500;

  button {
    position: relative;
    height: 24px;
    font-weight: 500;

    &:before {
      content: '';
      position: absolute;
      top: 100%;
      left: 10px;
      border-width: 7px;
      border-style: solid;
      border-color: ${theme.colors.primary} transparent transparent transparent;
    }
  }
`;

export const Label = ({
  isRequired,
  isOptional,
  name,
  labelText,
  tooltip,
  annotation,
  annotationTooltip,
}: Props) => {
  return (
    <StyledLabel htmlFor={name}>
      <span>{labelText}</span>
      {isRequired && <StyledSup>*</StyledSup>}
      {isOptional && <StyledOptional>(optional)</StyledOptional>}
      {tooltip && <StyledTooltipWrapper>
        <Tooltip title={tooltip} />
      </StyledTooltipWrapper>}
      <Spacer />
      {annotation && <StyledAnnotation>{annotation}</StyledAnnotation>}
      {annotationTooltip && <StyledTooltipWrapper removeRightMargin>
        <Tooltip title={annotationTooltip} />
      </StyledTooltipWrapper>}
    </StyledLabel>
  );
};