export const downloadFile = async (fileContent: string, filename: string) => {
  const a = document.createElement('a');
  const blob = new Blob([fileContent]);
  const url = window.URL.createObjectURL(blob);

  document.body.appendChild(a);
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};
