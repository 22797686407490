import styled from "styled-components";
import { theme } from "theme/theme";

export const AssistantHints = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-bottom: 24px;
  
  button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    height: auto;
    padding: 16px;
    box-shadow: ${theme.boxShadowHighlight};
    
    &:hover {
      box-shadow: ${theme.boxShadowHighlight};
    }
  }
`;
