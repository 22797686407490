import React, {
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import { FileIcon } from "assets/icons";
import { theme } from "theme/theme";

const SavingContainer = styled.div<{ isVisible?: boolean }>`
  display: flex;
  align-items: center;
  color: ${theme.colors.textDisabled};
  transition: all ${props => props.isVisible ? '0.1s' : '0.5s'} ease;
  opacity: ${props => props.isVisible ? 1 : 0};
  font-size: 12px;

  div {
    margin: 0 8px;
  }
`;

export const SavingIndicator = ({ isSaving, children }: { isSaving: boolean, children: JSX.Element | string }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isSaving) {
      setIsVisible(true);
      setSaved(false);
    } else {
      setSaved(true);
      timeout = setTimeout(() => setIsVisible(false), 1000);
    }

    return () => clearTimeout(timeout);
  }, [isSaving]);

  return (
    <SavingContainer isVisible={isVisible}>
      <FileIcon />
      <div>
        {saved ? 'Draft Saved' : children}
      </div>
    </SavingContainer>
  );
};
