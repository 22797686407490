import React, { useState } from 'react';
import {
  CloseIcon,
  OpenInNewIcon,
} from 'assets/icons';
import { Button } from 'components/common/Button';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import {
  StyledButtonContainer,
  StyledContainer,
  StyledFormContainer,
} from 'components/common/Forms/styled';
import { InputBasic } from 'components/common/Input';
import { SectionLabel } from 'components/common/SectionLabel';
import { Metric } from 'interfaces/metrics';
import {
  useDeleteMetricsMutation,
  usePostMetricsDependantsQuery,
} from 'store/services/metrics';
import { Label } from 'components/common/Input/Label';
import { Hint } from 'components/common/Hint';
import {
  Dependant,
  DependantsList,
} from './styled';
import { toastSuccess } from 'utils/toast';

interface Props {
  selectedMetrics: Metric[];
  onClose: () => void;
  onSuccess: () => void;
}

export const DeleteMetric: React.FC<Props> = ({
  selectedMetrics,
  onClose,
  onSuccess,
}) => {
  const [deleteMetrics, { isLoading }] = useDeleteMetricsMutation();
  const [showAllMetrics, setShowAllMetrics] = useState(false);
  const [showAllReports, setShowAllReports] = useState(false);
  const { data: dependants, isFetching: isLoadingDependants } = usePostMetricsDependantsQuery(selectedMetrics.map(({ id }) => id), { skip: !selectedMetrics.length });
  const { metricDependants, reportDependants } = dependants || {
    metricDependants: [],
    reportDependants: [],
  };

  const plural = selectedMetrics.length > 1 ? 's' : '';

  const handleDelete = () => {
    deleteMetrics(selectedMetrics.map(({ id }) => id))
      .unwrap()
      .then(() => {
        toastSuccess('Metric(s) deleted successfully.');
        onSuccess();
      });
  };

  return (
    <StyledContainer>
      <ButtonsContainer alignTop>
        <SectionLabel marginBottom={24}>
          Delete Metric{plural}
        </SectionLabel>
        <Spacer />
        <Button
          aria-label="Close"
          variant="icon"
          size="large"
          onClick={onClose}
        >
          <CloseIcon />
        </Button>
      </ButtonsContainer>
      <StyledFormContainer onSubmit={handleDelete}>
        <InputBasic
          labelText={`Metric${plural}`}
          disabled
          name="metricName"
          value={plural
            ? `${selectedMetrics[0]?.name} (+${selectedMetrics.length - 1} more metrics)`
            : selectedMetrics[0]?.name}
        />
        {(!!metricDependants.length || !!reportDependants.length) && (
          <Hint
            marginBottom={0}
            blinking
          >
            {isLoadingDependants && 'Loading dependants...'}
            Metric{plural} you are going to delete {plural ? 'are' : 'is'} used in other places. Deleting {plural ? 'these' : 'this'} metric{plural} will also
            {!!metricDependants.length && ' delete dependant metrics'}
            {!!metricDependants.length && !!reportDependants.length && ' and'}
            {!!reportDependants.length && ` remove ${plural ? 'them' : 'it'} from reports`}
            . See details below.
          </Hint>
        )}
        {!!metricDependants.length && (
          <div>
            <Label
              labelText="Dependant Metrics"
              name="dependantMetrics"
              tooltip={`The Metric${plural} you are going to delete ${plural ? 'are' : 'is'} used in the following metrics. Deleting ${plural ? 'these' : 'this'} metric${plural} will also delete dependant metrics.`}
            />
            <DependantsList showAll={showAllMetrics}>
              {metricDependants.map((dependant) => (
                <Dependant key={dependant.id}>
                  <span>{dependant.name}</span>
                  <Button
                    variant="icon"
                    color="primary"

                    onClick={() => window.open(`/metrics/${dependant.id}`)}>
                    <OpenInNewIcon />
                  </Button>
                </Dependant>
              ))}
            </DependantsList>
            {metricDependants.length > 3 && (
              <ButtonsContainer>
                <Button
                  variant="simple"
                  color="primary"
                  onClick={() => setShowAllMetrics((prev) => !prev)}
                >
                  {showAllMetrics ? 'Show less' : `...and ${metricDependants.length - 3} more`}
                </Button>
              </ButtonsContainer>
            )}
          </div>
        )}
        {!!reportDependants.length && (
          <div>
            <Label
              labelText="Dependant Reports"
              name="reportDependants"
              tooltip={`The Metric${plural} you are going to delete ${plural ? 'are' : 'is'} used in the following reports. Deleting ${plural ? 'these' : 'this'} metric${plural} will remove ${plural ? 'them' : 'it'} from these reports.`}
            />
            <DependantsList showAll={showAllReports}>
              {reportDependants.map((dependant) => (
                <Dependant key={dependant.id}>
                  <span>{dependant.name}</span>
                  <Button
                    variant="icon"
                    color="primary"
                    onClick={() => window.open(`/reports/${dependant.id}`)}
                  >
                    <OpenInNewIcon />
                  </Button>
                </Dependant>
              ))}
            </DependantsList>
            {reportDependants.length > 3 && (
              <Button
                variant="simple"
                color="primary"
                onClick={() => setShowAllReports((prev) => !prev)}
              >
                {showAllReports ? 'Show less' : `...and ${reportDependants.length - 3} more`}
              </Button>
            )}
          </div>
        )}
      </StyledFormContainer>
      <StyledButtonContainer
        marginTop={20}
        pushRight
      >
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          onClick={onClose}
          disabled={isLoading || isLoadingDependants}
        >
          CANCEL
        </Button>
        <Button
          type="button"
          color="error"
          onClick={handleDelete}
          disabled={isLoading || isLoadingDependants}
          isLoading={isLoading}
        >
          DELETE
        </Button>
      </StyledButtonContainer>
    </StyledContainer>
  );
};
