import React from 'react';
import { useDeleteCustomersMutation } from 'store/services/customers';
import { DeleteItems } from 'components/DeleteItems';
import { useAppSelector } from 'hooks/redux';
import { gridHelperSelector } from 'store/selectors/gridHelper';

interface Props {
  onClose: () => void;
}

export const DeleteCustomers = ({
  onClose,
}: Props) => {
  const {
    isAllSelected,
    selectedIds,
    deselectedIds,
    totalSelected,
    filtering,
  } = useAppSelector(gridHelperSelector);

  const [deleteCustomers, { isLoading }] = useDeleteCustomersMutation();

  const handleDeleteCustomers = () => {
    deleteCustomers({
      isAllSelected,
      selectedIds,
      deselectedIds,
      filtering,
    })
      .unwrap()
      .then(() => {
        onClose();
      });
  };

  return (
    <DeleteItems
      totalSelected={totalSelected}
      onClose={onClose}
      isLoading={isLoading}
      handleDelete={handleDeleteCustomers}
      item="Customer"
    />
  );
};
