import React, { useState } from 'react';
import { Datepicker } from 'components/common/Datepicker';
import { Dropdown } from 'components/common/Dropdown';
import { InputBasic } from 'components/common/Input';
import { MAX_PERIODS } from 'utils/constants';
import { FrequencyOptions } from 'utils/dates';
import {
  addMonths,
  differenceInCalendarMonths,
} from 'date-fns';
import { toastWarning } from 'utils/toast';
import { CloseIcon } from 'assets/icons';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import {
  StyledButtonContainer,
  StyledFormContainer,
  StyledFormRow,
} from 'components/common/Forms/styled';
import { SectionLabel } from 'components/common/SectionLabel';
import { Button } from 'components/common/Button';

interface Props {
  periodStart: any;
  setPeriodStart: any;
  periodEnd: any;
  setPeriodEnd: any;
  periodsNumber: any;
  setPeriodsNumber: any;
  onClose: () => void;
}

export const SelectDates = ({
  periodStart,
  setPeriodStart,
  periodEnd,
  setPeriodEnd,
  periodsNumber,
  setPeriodsNumber,
  onClose,
}: Props) => {
  const [frequency, setFrequency] = useState({
    name: 'Monthly',
    variant: 'monthYearPicker' as const,
  });

  const handleStartDateChange = (date: Date | [Date | null, Date | null] | undefined) => {
    if (!periodEnd) {
      setPeriodStart(date as Date);
      return;
    };

    const newNumberOfPeriods = differenceInCalendarMonths(periodEnd, date as Date) + 1;

    if (newNumberOfPeriods > 0) {
      setPeriodStart(date as Date);
      setPeriodsNumber(newNumberOfPeriods);
    } else {
      toastWarning('Cannot choose period start after period end.');
    }
  };

  const handleEndDateChange = (date: Date | [Date | null, Date | null] | undefined) => {
    if (!periodStart) return;
    const newNumberOfPeriods = differenceInCalendarMonths(date as Date, periodStart) + 1;

    if (newNumberOfPeriods > 0) {
      setPeriodEnd(date as Date);
      setPeriodsNumber(newNumberOfPeriods);
    } else {
      toastWarning('Cannot choose period end before period start.');
    }
  };

  const handlePeriodsChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!periodStart) return;
    if (Number(e.currentTarget.value) > MAX_PERIODS) return MAX_PERIODS;
    if (Number(e.currentTarget.value) < 0) return;

    const newValue = Number(e.currentTarget.value);
    setPeriodsNumber(newValue ? newValue : '');
    setPeriodEnd(addMonths(periodStart, newValue ? newValue - 1 : 0));
  };

  return (
    <>
      <ButtonsContainer
        paddingBottom={40}
        alignTop
      >
        <SectionLabel
          data-cy="am-header"
          marginBottom={0}
        >
          Change Dates
        </SectionLabel>
        <Spacer />
        <Button
          aria-label="Close"
          variant="icon"
          size="large"
          onClick={onClose}
          data-cy="am-button-close"
        >
          <CloseIcon />
        </Button>
      </ButtonsContainer>
      <StyledFormContainer onSubmit={onClose}>
        <StyledFormRow>
          <InputBasic
            name="periods"
            labelText="Periods"
            value={`${periodsNumber}`}
            onChange={handlePeriodsChange}
            type="number"
            max={MAX_PERIODS}
            min="1"
          />
          <Dropdown
            labelText="Frequency"
            values={[frequency]}
            labelField="name"
            valueField="name"
            minWidth={150}
            options={FrequencyOptions}
            onChange={(value: any[]) => {
              setFrequency(value[0]);
            }}
            disabled
          />
        </StyledFormRow>
        <StyledFormRow>
          <Datepicker
            name="periodStart"
            labelText="Period Start"
            onChange={handleStartDateChange}
            value={periodStart || undefined}
            variant={frequency.variant}
            isClearable={false}
          />
          <Datepicker
            name="periodEnd"
            labelText="Period End"
            onChange={handleEndDateChange}
            value={periodEnd || undefined}
            variant={frequency.variant}
            isClearable={false}
          />
        </StyledFormRow>
      </StyledFormContainer>
      <StyledButtonContainer marginTop={40}>
        <Button
          type="button"
          onClick={onClose}
        >
          CONFIRM
        </Button>
      </StyledButtonContainer>
    </>
  );
};
