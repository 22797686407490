import React, {
  useCallback,
  useEffect,
  useState,
} from "react";

export const useLeaveElement = (ref: React.RefObject<any>) => {
  const [isOutside, setIsOutside] = useState(false);
  const [isAborted, setIsAborted] = useState(false);

  const abort = () => {
    setIsAborted(true);
    setIsOutside(false);
  };

  const handleLeaveElement = useCallback(() => !isAborted && setIsOutside(true), [isAborted]);

  useEffect(() => {
    const myRef = ref.current;
    myRef.addEventListener("mouseleave", handleLeaveElement);
    return () => {
      myRef.removeEventListener("mouseleave", handleLeaveElement);
    };
  }, [handleLeaveElement, ref]);

  return { isOutside, abort };
};
