export interface DetailTableForm {
  revenueMetric: { name: string; value: string, dontMultiply?: boolean };
  cohortType: { name: string; value: string };
  dimension1: { name: string };
  dimension2: { name: string };
  dimension3: { name: string };
  startDateField: { name: string };
  arrMrr: { name: string };
  detailFormat: { name: string };
  period: { name: string; value: string };
  revenueType: { name: string; value: string }[];
  customer: { name: string; value: string }[];
  customerTags: { name: string; value: string }[];
  product: { name: string; value: string }[];
  productTags: { name: string; value: string }[];
  contract: { name: string; value: string }[];
  contractTags: { name: string; value: string }[];
  tags: { name: string; value: string }[];
}

export const DetailTableFormatOptions = [
  { name: '-' },
  { name: 'Thousands' },
  { name: 'Millions' },
];

export const DetailTableArrMrrOptions = [
  { name: 'MRR' },
  { name: 'ARR' },
];
