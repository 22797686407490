import styled, { css } from "styled-components";
import { theme } from "theme/theme";

interface BoxProps {
  marginBottom?: number;
  fullHeight?: boolean;
  horizontal?: boolean;
  condensed?: boolean;
  disabled?: boolean;
  disabledText?: string;
  borderWidth?: number;
}

export const Box = styled.div<BoxProps>`
  display: flex;
  flex-direction: ${props => props.horizontal !== undefined ? 'row' : 'column'};
  border: ${props => props.borderWidth || 1}px solid ${theme.colors.borderColorDark};
  border-radius: 8px;
  padding: ${props => props.condensed ? 6 : 24}px;
  margin-bottom: ${props => props.marginBottom !== undefined ? props.marginBottom : 40}px;
  background-color: ${theme.colors.white};
  ${props => props.fullHeight && css`
    flex: 1;
  `}

  ${props => props.horizontal && css`
    justify-content: space-between;
    align-items: center;
  `}

  & & {
    margin-top: 24px;
  }

  ${props => props.disabled && css`
    pointer-events: none;
    position: relative;
    
    &::after {
      content: '${props.disabledText || ' '}';
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(239, 239, 241, 0.6);
      backdrop-filter: blur(15px);
      z-index: 2;
    }
  `}
`;
