import styled from "styled-components";
import { Button } from "components/common/Button";
import { theme } from "theme/theme";

export const StyledUserMenu = styled.div`
  display: none;
  flex-direction: column;
  background-color: ${theme.colors.mainBackground};
  color: ${theme.colors.textDark};
  box-shadow: ${theme.boxShadow};
  min-width: 240px;
  z-index: 3;

  &[data-show] {
    display: flex;
  }
`;

export const StyledUsername = styled.div`
  padding: 12px 20px 4px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;

export const StyledEmail = styled.div`
  padding: 4px 20px 12px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
`;

export const StyledMenuItem = styled(Button)`
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  border-radius: 0;
  justify-content: flex-start;
  padding: 12px 20px;
  color: ${theme.colors.textDark};
  background-color: ${theme.colors.transparent};
  border: none;
  height: auto;

  &:hover {
    background-color: ${theme.colors.secondaryBackground};
    box-shadow: none;
  }
`;
