import styled from "styled-components";
import { theme } from "theme/theme";

export const Dependant = styled.li`
  display: flex;
  gap: 4px;
  align-items: center;
  color: ${theme.colors.textDisabled};
  margin-left: 16px;
  font-size: 14px;

  svg {
    width: 16px;
    height: 16px;
    fill: ${theme.colors.primary40};
  }
`;

export const DependantsList = styled.ul<{ showAll?: boolean }>`
  max-height: ${({ showAll }) => (showAll ? 'none' : '96px')};
  overflow: hidden;
`;