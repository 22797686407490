import { CompanyRevenueType } from 'interfaces/company';
import { DetailTable } from 'components/DetailTable';

interface Props {
  companyRevenueType?: CompanyRevenueType;
}

export const Cohorts = ({ companyRevenueType }: Props) => (
  <DetailTable
    companyRevenueType={companyRevenueType}
    tableType="cohorts"
    title="Cohorts by Period"
  />
);
