import { GLAccount } from "./accounts";

export enum FormulaElementType {
  METRIC = 'METRIC', // Custom type
  ACCOUNT = 'ACCOUNT', // Custom type
  CLAUSE = 'CLAUSE',
  CLAUSE_CLOSE = 'CLAUSE_CLOSE',
  MODIFIER = 'MODIFIER',
  NUMERIC = 'NUMERIC',
  INPUT = 'INPUT',
  UNKNOWN = 'UNKNOWN',
};

export interface FrontendFormulaElement {
  id?: string;
  origin: FormulaElementType;
  value: string;
};

export type FrontendFormula = FrontendFormulaElement[];

export interface FormulaValidateResponse {
  valid: boolean;
  error: string | null;
  errorIndex: number | null;
}

export interface FormulaOption {
  origin: FormulaElementType;
  category: string;
  id: string;
  name: string;
  type: string;
  glAccounts?: GLAccount[];
  description?: string;
}

export interface BackendFormulaElement {
  type: FormulaElementType;
  value: string;
};

export type BackendFormula = BackendFormulaElement[];

export interface FormulaValidateRequest {
  metricId: string;
  formula: BackendFormula;
}

export type FormulaOptions = FormulaOption[];
