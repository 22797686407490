import React, {
  useEffect,
  useRef,
} from "react";
import { SelectItemRenderer } from "react-dropdown-select";
import { getByPath } from "utils/getByPath";
import { StyledConfigurationDropdownItem } from "./styled";

export const ReportConfigurationDropdownItem = ({
  item,
  itemIndex,
  props,
  state,
  methods,
}: SelectItemRenderer<any>) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      ref.current &&
      !props.multi &&
      props.keepSelectedInList &&
      methods.isSelected(item)
    ) {
      ref.current.scrollIntoView({ block: 'nearest', inline: 'start' });
    }
  }, [ref, methods, props.keepSelectedInList, props.multi, item]);

  useEffect(() => {
    if (state.cursor === itemIndex) {
      ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  }, [item, itemIndex, state.cursor]);

  return (
    <StyledConfigurationDropdownItem
      ref={ref}
      key={`${getByPath(item, props.valueField!)}${getByPath(item, props.labelField!)}`}
      aria-selected={methods.isSelected(item)}
      aria-disabled={item.disabled}
      aria-label={getByPath(item, props.labelField!)}
      onClick={item.disabled ? undefined : () => methods.addItem(item)}
      onKeyPress={item.disabled ? undefined : () => methods.addItem(item)}
      tabIndex={-1}
      active={state.cursor === itemIndex}
      className={`react-dropdown-select-item ${
        methods.isSelected(item)
          ? 'react-dropdown-select-item-selected'
          : ''
      }`}
      type={item.type}
    >
      <div className="line1">
        <div>
          {item.name}
        </div>
        <ins>{item.type}</ins>
      </div>
      <div className="line2">
        {item.path}
      </div>
    </StyledConfigurationDropdownItem>
  );
};