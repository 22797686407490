import {
  ContractUpdatePayload,
  ContractFormFields,
  ContractStateFormFields,
  ContractAddPayload,
} from "interfaces/contracts";
import {
  formatDateToISO,
  isoToDate,
} from "./dates";

export const prepareContractAddPayload = (data: ContractFormFields): ContractAddPayload => {
  return {
    id: data.id,
    name: data.name,
    customerId: data.customer?.at(0)?.id || null,
    startDate: formatDateToISO(data.startDate!),
    endDate: formatDateToISO(data.endDate!),
    bookingDate: formatDateToISO(data.bookingDate!),
    cancelDate: data.cancelDate ? formatDateToISO(data.cancelDate) : null,
    crmId: data.crmId || null,
    tagIds: data.tags.map((tag) => tag.id),
    externalLink: data.externalLink || null,
    notes: data.notes || null,
  };
};

export const prepareContractUpdatePayload = (data: ContractFormFields): ContractUpdatePayload => {
  return {
    id: data.id,
    name: data.name,
    customerId: data.customer?.at(0)?.id || null,
    startDate: {
      applyAlsoOnLines: !!data.startDateApplyOnItems,
      date: formatDateToISO(data.startDate!),
    },
    endDate: {
      applyAlsoOnLines: !!data.endDateApplyOnItems,
      date: formatDateToISO(data.endDate!),
    },
    bookingDate: {
      applyAlsoOnLines: !!data.bookingDateApplyOnItems,
      date: formatDateToISO(data.bookingDate!),
    },
    cancelDate: {
      applyAlsoOnLines: !!data.cancelDateApplyOnItems,
      date: data.cancelDate ? formatDateToISO(data.cancelDate) : null,
    },
    crmId: data.crmId || null,
    tagIds: data.tags.map((tag) => tag.id),
    externalLink: data.externalLink || null,
    notes: data.notes || null,
  };
};

export const parseContractFormData = (data: ContractFormFields): ContractStateFormFields => {
  return {
    ...data,
    startDate: data.startDate ? formatDateToISO(data.startDate) : undefined,
    endDate: data.endDate ? formatDateToISO(data.endDate) : undefined,
    bookingDate: data.bookingDate ? formatDateToISO(data.bookingDate) : undefined,
    cancelDate: data.cancelDate ? formatDateToISO(data.cancelDate) : undefined,
  };
};

export const parseStateFormData = (data: ContractStateFormFields): ContractFormFields => {
  return {
    ...data,
    startDate: data.startDate ? isoToDate(data.startDate) : undefined,
    endDate: data.endDate ? isoToDate(data.endDate) : undefined,
    bookingDate: data.bookingDate ? isoToDate(data.bookingDate) : undefined,
    cancelDate: data.cancelDate ? isoToDate(data.cancelDate) : undefined,
  };
};
