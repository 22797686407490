import React from 'react';
import { useAppDispatch } from 'hooks/redux';
import { DeleteItems } from 'components/DeleteItems';
import { bulkImportActions } from 'store/slices/bulkImport';
import { BulkImportContractsRow } from 'interfaces/bulkImportContracts';

interface Props {
  onClose: () => void;
  selectedRows: BulkImportContractsRow[];
}

export const BulkImportDeleteContracts = ({
  onClose,
  selectedRows,
}: Props) => {
  const dispatch = useAppDispatch();
  const associatedContractLines = selectedRows.map((row) => row.relatedRowsIds)
    .flat();

  const handleDeleteRows = () => {
    const ids = selectedRows.map((row) => row.id);

    dispatch(bulkImportActions.deleteContracts(ids));
    dispatch(bulkImportActions.deleteContractLines({ids: associatedContractLines, fromContract: true}));
  };

  const customDescription = selectedRows.length > 1
    ? <p>Are you sure you would like to delete these contracts? Deleting these contracts will also delete {associatedContractLines.length ?? 'all'} contract lines associated with them.</p>
    : <p>Are you sure you would like to delete this contract? Deleting this contract will also delete {associatedContractLines.length ?? 'all'} contract lines associated with this contract.</p>;

  return (
    <DeleteItems
      selectedRows={selectedRows}
      onClose={onClose}
      isLoading={false}
      handleDelete={handleDeleteRows}
      item="row"
      customDescription={customDescription}
    />
  );
};
