import React, { forwardRef } from 'react';
import NumberFormat from 'react-number-format';
import { ErrorDisplay } from '../Input/ErrorDisplay';
import {
  StyledContainer,
  StyledInputWrapper,
  StyledInput,
} from '../Input/styled';
import { Label } from '../Input/Label';
import { useGetAuthMeQuery } from 'store/services/auth';
import { getCurrentCompany } from 'utils/currentCompany';

interface Props {
  name: string;
  error?: string;
  disabled?: boolean;
  decimalScale?: number;
  labelText: string
  placeholder?: string;
  isRequired?: boolean;
  isOptional?: boolean;
  onChange: (data: string) => void;
  value?: string;
  tooltip?: string;
}

export const NumberInput = forwardRef(({
  disabled,
  name,
  decimalScale = 2,
  isRequired,
  isOptional,
  labelText,
  placeholder,
  error,
  onChange,
  value,
  tooltip,
}: Props, ref: any) => {
  const withError = Boolean(error);
  const { data: user } = useGetAuthMeQuery();
  const currency = getCurrentCompany(user)?.currency || {
    name: 'US Dollars',
    code: 'USD',
    symbol: '$',
  };

  const getThousandSeparator = (currencyCode: string) => {
    switch (currencyCode) {
      case 'PLN':
      case 'EUR':
        return ' ';
      default:
        return ',';
    }
  };

  const thousandSeparator = getThousandSeparator(currency.code);

  return (
    <StyledContainer ref={ref}>
      <Label
        isRequired={isRequired}
        isOptional={isOptional}
        labelText={labelText}
        name={name}
        tooltip={tooltip}
      />
      <StyledInputWrapper
        disabled={disabled}
        withError={withError}
      >
        <NumberFormat
          customInput={StyledInput}
          thousandSeparator={thousandSeparator}
          prefix={`${currency.symbol} `}
          decimalScale={decimalScale}
          fixedDecimalScale
          disabled={disabled}
          name={name}
          placeholder={placeholder}
          onValueChange={(c) => {
            onChange(c.value);
          }}
          value={value}
        />
      </StyledInputWrapper>
      {error && <ErrorDisplay error={error} />}
    </StyledContainer>
  );
});
