import React from 'react';
import { ContractLines } from 'interfaces/contractLines';
import { DeleteItems } from 'components/DeleteItems';
import { useDeleteContractLinesMutation } from 'store/services/contractLines';

interface Props {
  onClose: () => void;
  onGoBack?: () => void;
  selectedRows: ContractLines;
}

export const DeleteContractLines = ({
  onClose,
  onGoBack,
  selectedRows,
}: Props) => {
  const [deleteContractLines, { isLoading }] = useDeleteContractLinesMutation();

  const handleDeleteContractLines = () => {
    const ids = selectedRows.map((customer) => customer.id);
    deleteContractLines(ids)
      .unwrap()
      .then(() => {
        onClose();
      });
  };
  return (
    <DeleteItems
      selectedRows={selectedRows}
      onClose={onClose}
      onGoBack={onGoBack}
      isLoading={isLoading}
      handleDelete={handleDeleteContractLines}
      item="Contract Line"
      shortenDescription
    />
  );
};