import styled from "styled-components";

export const StyledLookback = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  > *:nth-child(n+2) {
    margin-top: 24px;
  }

  span {
    padding-right: 14px;
  }
`;
