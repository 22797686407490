import React, {
  Dispatch,
  SetStateAction,
} from 'react';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputBasic } from 'components/common/Input';
import {
  CompanyFirstStepFormFields,
  CompanyFormFields,
} from 'interfaces/company';
import {
  StyledButtonContainer,
  StyledFormContainer,
} from 'components/common/Forms/styled';
import { Button } from 'components/common/Button';

interface Props {
  setStep:  Dispatch<SetStateAction<number>>;
  setCompanyInfo: Dispatch<SetStateAction<CompanyFormFields>>;
  companyInfo: CompanyFormFields;
}

export const FirstStepForm = ({
  setStep,
  setCompanyInfo,
  companyInfo,
}: Props) => {
  const schema = yup.object({
    name: yup.string()
      .required('Company name is required.'),
  })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CompanyFirstStepFormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: companyInfo.name,
    },
  });

  const handleFirstStepSubmit = handleSubmit((data) => {
    setCompanyInfo({
      ...companyInfo,
      name: data.name,
    });
    setStep(2);
  });

  return (
    <>
      <StyledFormContainer>
        <InputBasic
          isRequired
          labelText="Company Name"
          placeholder="Your company's name"
          {...register('name')}
          onBlur={(e) => {
            const fieldValue = e.target.value;

            if (fieldValue) {
              setValue('name', fieldValue.trim(), { shouldValidate: true });
            }
          }}
          error={errors.name?.message}
        />
        <StyledButtonContainer marginTop={36}>
          <Button
            type="submit"
            onClick={handleFirstStepSubmit}
          >
            NEXT
          </Button>
        </StyledButtonContainer>
      </StyledFormContainer>
    </>
  );
};

