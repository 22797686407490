import styled from "styled-components";
import { theme } from "theme/theme";

export const StyledHistory = styled.div`
  padding: 16px 16px 12px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.fieldBorder};

  section {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    margin-bottom: 8px;

  &:last-of-type:not(:first-of-type) {
      margin-top: 16px;
    }
  }
`;

export const StyledHistoryEntry = styled.div<{ bold?: boolean }>`
  font-weight: ${props => props.bold ? '600' : '500'};
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.4px;
  display: flex;
  justify-content: space-between;
  color: ${theme.colors.textDark};

  button {
    width: 26px;
    height: 26px;
  }
`;
