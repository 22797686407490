import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FactaTable } from 'components/FactaTable';
import {
  LeftPane,
  RightPane,
} from 'components/Layout';
import { StyledFormContainer } from 'components/common/Forms/styled';
import { RadioInput } from 'components/common/RadioInput';
import { Dropdown } from 'components/common/Dropdown';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { format as currencyFormat } from "currency-formatter";
import { SectionLabel } from 'components/common/SectionLabel';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import {
  RevenueDetailsData,
  RevenueDetailsDataEntryPeriod,
} from 'interfaces/revenueDetail';
import {
  RevenueDetailMetricsOptions,
  getDimensionOptions,
} from 'utils/revenueDetail';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef } from '@ag-grid-community/core';
import {
  FrequencyOptions,
  formatDateStringToMMMYearDisplay,
} from 'utils/dates';
import {
  DownloadIcon,
  NestedIcon,
} from 'assets/icons';
import {
  NestedField,
  SliderWrapper,
} from './styled';
import { Button } from 'components/common/Button';
import Slider from 'rc-slider';
import { StyledSliderContainer } from 'components/FactaChart/styled';
import { chartDateFormatter } from 'utils/charts';
import { CompanyRevenueType } from 'interfaces/company';
import {
  useGetDraftQuery,
  usePutDraftMutation,
} from 'store/services/drafts';
import { invalidateTags } from 'store/services/api';
import { DraftsTags } from 'store/services/api-tags';
import { useAppDispatch } from 'hooks/redux';
import {
  DetailTableArrMrrOptions,
  DetailTableForm,
  DetailTableFormatOptions,
} from 'utils/detailTable';
import {
  CohortTypeOptions,
  CohortsMetricsOptions,
} from 'utils/cohortDetail';
import { useGetSettingsQuery } from 'store/services/settings';
import { usePostRevenueDetailsQuery } from 'store/services/metrics';
import { useGetReportPeriodsQuery } from 'store/services/reports';
import { ContextMenu } from 'components/ContextMenu';
import { caseInsensitiveComparator } from 'utils/aggrid';
import { multiFilter } from 'utils/filters';
import { RevenueTypeOptions } from 'utils/constants';
import { RevenueType } from 'interfaces/products';

interface Props {
  companyRevenueType?: CompanyRevenueType;
  tableType: 'revenue_details' | 'cohorts';
  title: string;
}

export const DetailTable = ({
  companyRevenueType,
  tableType,
  title,
}: Props) => {
  const gridRef = useRef<AgGridReact>(null);
  const dispatch = useAppDispatch();
  const isCohorts = tableType === 'cohorts';
  const [colDefs, setColDefs] = useState<ColDef[]>([]);
  const [multiplier1, setMultiplier1] = useState(1);
  const [multiplier2, setMultiplier2] = useState(1);
  const [periodValue, setPeriodValue] = useState<string | null>(null);
  const [tempRange, setTempRange] = useState<number[] | null>(null);
  const [range, setRange] = useState(tempRange);
  const [initialized, setInitialized] = useState(false);
  const [startDateFieldSelected, setStartDateField] = useState({ name: '' });
  const [revenueTypeSelected, setRevenueTypeSelected] = useState<string[]>([]);

  const { data: settings } = useGetSettingsQuery();
  const { data: availablePeriods } = useGetReportPeriodsQuery();
  const { data: tableFormat } = useGetDraftQuery(`${tableType}_format`);
  const [putDraft] = usePutDraftMutation();
  const { data, isFetching: isLoading } = usePostRevenueDetailsQuery({
    filtering: {
      startDateField: startDateFieldSelected.name,
      dateFilterColumn: isCohorts ? 'Customer Start Month' : 'Contract Month',
      startPeriod: range ? availablePeriods?.[periodValue as 'monthly']?.[range[0]] : undefined,
      endPeriod: range ? availablePeriods?.[periodValue as 'monthly']?.[range[1]] : undefined,
      revenueTypes: revenueTypeSelected,
    },
  }, {
    skip: !startDateFieldSelected.name || !range || range[1] < 0 || !periodValue || !availablePeriods,
  });

  const gridData: RevenueDetailsData = useMemo(() => (data?.data || []), [data]);

  const tagsName = companyRevenueType === CompanyRevenueType.CONTRACTS
    ? 'Contract Line Tags'
    : 'Subscription Tags';

  const linesName = companyRevenueType === CompanyRevenueType.CONTRACTS
    ? 'Contract Line'
    : 'Subscription';

  const DimensionOptions = getDimensionOptions(isCohorts, companyRevenueType as CompanyRevenueType);

  const defaultValues = useMemo(() => ({
    revenueMetric: { name: 'Revenue', value: 'revenue' },
    cohortType: { name: 'By Period', value: 'byPeriod' },
    dimension1: DimensionOptions[0],
    dimension2: isCohorts ? DimensionOptions.at(1)! : DimensionOptions.at(-1)!,
    dimension3: DimensionOptions.at(-1)!,
    startDateField: { name: settings?.sigmaSaaSStartDate },
    arrMrr: DetailTableArrMrrOptions[0],
    detailFormat: DetailTableFormatOptions[0],
    period: FrequencyOptions[0],
    revenueType: [RevenueTypeOptions.find((r) => r.value === RevenueType.RECURRING)!],
    customer: [],
    customerTags: [],
    product: [],
    productTags: [],
    contract: [],
    contractTags: [],
    tags: [],
  }), [DimensionOptions, isCohorts, settings?.sigmaSaaSStartDate]);

  const {
    getValues,
    control,
    watch,
    reset,
    setValue,
  } = useForm<DetailTableForm>({
    mode: 'onChange',
    defaultValues,
  });

  const {
    revenueMetric,
    cohortType,
    dimension1,
    dimension2,
    dimension3,
    arrMrr,
    detailFormat,
    period,
    revenueType,
    startDateField,
  } = watch();

  const periods = useMemo(() => {
    switch (period.value) {
      case 'monthly':
        return availablePeriods?.monthly || [];
      case 'quarterly':
        return availablePeriods?.quarterly || [];
      case 'annual':
        return availablePeriods?.annual || [];
      default:
        return [];
    }
  }, [availablePeriods, period]);

  const sliderMax = useMemo(() => (periods.length || 1) - 1, [periods.length]);

  const sliderMarks = useMemo(() => {
    return data
      ? periods.reduce((a, v, index) => ({ ...a, [index]: chartDateFormatter(v) }), {})
      : {};
  }, [data, periods]);

  useEffect(() => {
    if (!initialized) return;

    putDraft({
      type: `${tableType}_format`,
      data: {
        revenueMetric,
        cohortType,
        dimension1,
        dimension2,
        dimension3,
        detailFormat,
        arrMrr,
        period,
        revenueType,
      },
    });
  }, [arrMrr, cohortType, detailFormat, dimension1, dimension2, dimension3, initialized, period, putDraft, revenueMetric, revenueType, tableType]);

  const isArrayOfObjects = (entry: any[]) => {
    return entry.every((e) => e.hasOwnProperty('label'));
  };

  useEffect(() => {
    if (tableFormat) {
      reset({
        ...getValues(),
        ...tableFormat,
        revenueType: isArrayOfObjects(tableFormat.revenueType)
          ? tableFormat.revenueType
          : tableFormat.revenueType
            .map((r: any) => RevenueTypeOptions.find((rt) => rt.value === r))
            .filter((rt: any) => rt),
      });
    }
    setInitialized(true);
  }, [getValues, reset, tableFormat]);

  useEffect(() => {
    if (!period.value) return;
    const valueKey = revenueMetric.value as keyof RevenueDetailsDataEntryPeriod;

    const columns: ColDef[] = periods.map((headerPeriod: string, index) => ({
      field: cohortType.value === 'byInception'
        ? `${index}`
        : headerPeriod,
      headerName: cohortType.value === 'byInception'
        ? `${index}`
        : period.value === 'monthly' ? formatDateStringToMMMYearDisplay(headerPeriod) : headerPeriod,
      aggFunc: (params) => {
        if (tableType === 'cohorts' && revenueMetric?.name.endsWith('Logos') && params.rowNode.level > 0) {
          return params.values.some((value) => value)
            ? 1
            : 0;
        } else {
          return params.values.reduce((a, v) => a + (v || 0), 0);
        }
      },
      valueFormatter: ({ value }: any) => currencyFormat(value, {
        code: 'USD',
        precision: 0,
        symbol: '',
      }),
      valueGetter: ({ data }: any) => {
        const value = cohortType.value === 'byInception'
          ? (data?.[period.value] as RevenueDetailsDataEntryPeriod[])?.find((period) => period.customerInception === index)?.[valueKey]
          : (data?.[period.value] as RevenueDetailsDataEntryPeriod[])?.find((period) => period.period === headerPeriod)?.[valueKey];

        return value
          ? Number(value) * multiplier1 * multiplier2
          : undefined;
      },
      type: 'rightAligned',
      sortable: true,
      initialWidth: 150,
      minWidth: 150,
      suppressMenu: true,
      hide: cohortType.value === 'byInception'
        ? !!range && index > (range[1] - range[0])
        : !!range && (index > range[1] || index < range[0]),
      useValueFormatterForExport: true,
    })) || [];

    const getRowGroupIndex = (dimension: string) => {
      switch (dimension) {
        case (dimension1.name):
          return 0;
        case (dimension2.name):
          return 1;
        case (dimension3.name):
          return 2;
        default:
          return 99;
      }
    };

    setTimeout(() => {
      setColDefs([
        {
          field: 'revenueType',
          enableRowGroup: true,
          hide: true,
          ...multiFilter,
          suppressFiltersToolPanel: true,
        },
        {
          field: 'customer',
          enableRowGroup: true,
          rowGroup: [dimension1.name, dimension2.name, dimension3.name].includes('Customer'),
          rowGroupIndex: getRowGroupIndex('Customer'),
          hide: true,
          ...multiFilter,
          comparator: caseInsensitiveComparator(),
        },
        {
          field: 'customerTags',
          valueGetter: (params) => params.data?.customerTags || [],
          valueFormatter: (params) => params.value.length ? params.value.join(', ') : '(no tag)',
          enableRowGroup: true,
          rowGroup: [dimension1.name, dimension2.name, dimension3.name].includes('Customer Tags'),
          rowGroupIndex: getRowGroupIndex('Customer Tags'),
          hide: true,
          ...multiFilter,
        },
        {
          field: 'product',
          enableRowGroup: true,
          rowGroup: [dimension1.name, dimension2.name, dimension3.name].includes('Product'),
          rowGroupIndex: getRowGroupIndex('Product'),
          hide: true,
          ...multiFilter,
          comparator: caseInsensitiveComparator(),
        },
        {
          field: 'productTags',
          valueGetter: (params) => params.data?.productTags || [],
          valueFormatter: (params) => params.value.length ? params.value.join(', ') : '(no tag)',
          enableRowGroup: true,
          rowGroup: [dimension1.name, dimension2.name, dimension3.name].includes('Product Tags'),
          rowGroupIndex: getRowGroupIndex('Product Tags'),
          hide: true,
          ...multiFilter,
        },
        {
          field: 'contract',
          enableRowGroup: true,
          rowGroup: [dimension1.name, dimension2.name, dimension3.name].includes('Contract'),
          rowGroupIndex: getRowGroupIndex('Contract'),
          hide: true,
          ...multiFilter,
          suppressFiltersToolPanel: companyRevenueType === CompanyRevenueType.SUBSCRIPTIONS,
          comparator: caseInsensitiveComparator(),
        },
        {
          field: 'contractTags',
          valueGetter: (params) => params.data?.contractTags || [],
          valueFormatter: (params) => params.value.length ? params.value.join(', ') : '(no tag)',
          enableRowGroup: true,
          rowGroup: [dimension1.name, dimension2.name, dimension3.name].includes('Contract Tags'),
          rowGroupIndex: getRowGroupIndex('Contract Tags'),
          hide: true,
          ...multiFilter,
          suppressFiltersToolPanel: companyRevenueType === CompanyRevenueType.SUBSCRIPTIONS,
        },
        {
          field: 'name',
          headerName: linesName,
          sortable: true,
          hide: true,
          comparator: caseInsensitiveComparator(),
          ...multiFilter,
        },
        {
          field: 'tags',
          headerName: tagsName,
          valueGetter: (params) => params.data?.tags || [],
          valueFormatter: (params) => params.value.length ? params.value.join(', ') : '(no tag)',
          enableRowGroup: true,
          rowGroup: [dimension1.name, dimension2.name, dimension3.name].includes(tagsName),
          rowGroupIndex: getRowGroupIndex(tagsName),
          hide: true,
          ...multiFilter,
        },
        {
          field: 'customerStartMonth',
          enableRowGroup: true,
          rowGroup: [dimension1.name, dimension2.name, dimension3.name].includes('Customer Start Date') && period.value === 'monthly',
          rowGroupIndex: getRowGroupIndex('Customer Start Date'),
          hide: true,
          filter: true,
          filterParams: {
            treeList: false,
            valueFormatter: (params: any) => formatDateStringToMMMYearDisplay(params.value),
          },
          suppressFiltersToolPanel: period.value !== 'monthly',
        },
        {
          field: 'customerStartQuarter',
          enableRowGroup: true,
          rowGroup: [dimension1.name, dimension2.name, dimension3.name].includes('Customer Start Date') && period.value === 'quarterly',
          rowGroupIndex: getRowGroupIndex('Customer Start Date'),
          hide: true,
          valueGetter: (params) => {
            return params.data?.customerStartQuarter?.split(' ')
              .reverse()
              .join(' ');
          },
          valueFormatter: ({ value }) => {
            return value?.split(' ')
              .reverse()
              .join(' ');
          },
          filter: true,
          suppressFiltersToolPanel: period.value !== 'quarterly',
        },
        {
          field: 'customerStartYear',
          enableRowGroup: true,
          rowGroup: [dimension1.name, dimension2.name, dimension3.name].includes('Customer Start Date') && period.value === 'annual',
          rowGroupIndex: getRowGroupIndex('Customer Start Date'),
          hide: true,
          filter: true,
          suppressFiltersToolPanel: period.value !== 'annual',
        },
        ...columns || [],
        {
          field: 'all',
          sortable: false,
          hide: true,
          rowGroup: dimension1.name === 'None',
          valueFormatter: () => 'All',
        },
      ]);
    }, 100);
  }, [data, dimension1.name, dimension2.name, dimension3.name, gridData, multiplier1, multiplier2, periods, period.value, range, revenueMetric, tagsName, cohortType.value, tableType, companyRevenueType, linesName]);

  const handleCSVDownload = (skipLines: boolean) => gridRef.current?.api.exportDataAsCsv({
    fileName: `insights-${tableType}-${arrMrr.name}-${revenueMetric.value}-${period.value}.csv`,
    shouldRowBeSkipped: ({ node }) => !!skipLines && !node.group,
  });

  useEffect(() => {
    setMultiplier1(!revenueMetric.dontMultiply && arrMrr.name === 'ARR' ? 12 : 1); //
  }, [arrMrr, revenueMetric.dontMultiply]);

  useEffect(() => {
    setMultiplier2(detailFormat?.name === 'Millions'
      ? 0.000001
      : detailFormat?.name === 'Thousands'
        ? 0.001
        : 1);
  }, [detailFormat]);

  useEffect(() => {
    const lastN = period.value === 'monthly' ? 12 : 4;
    setRange([Math.max(0, periods.length - lastN), periods.length - 1]);
    setTempRange([Math.max(0, periods.length - lastN), periods.length]);
  }, [periods, period.value]);

  useEffect(() => {
    gridRef.current?.api?.setFilterModel({
      ...gridRef.current.api.getFilterModel(),
      customerStartMonth: {},
      customerStartQuarter: {},
      customerStartYear: {},
    });
  }, [period.value]);

  useEffect(() => {
    if (!initialized) return;
    setPeriodValue(period.value);
  }, [initialized, period.value]);

  useEffect(() => () => {
    dispatch(invalidateTags([DraftsTags.Draft]));
  }, [dispatch]);

  useEffect(() => {
    setStartDateField(startDateField);
  }, [startDateField]);

  useEffect(() => {
    setRevenueTypeSelected(revenueType.map((r) => r.value));
  }, [revenueType]);

  useEffect(() => {
    setValue('startDateField.name', settings?.sigmaSaaSStartDate || '');
  }, [setValue, settings]);

  const contextMenuItems = [
    {
      action: () => handleCSVDownload(true),
      name: 'Download CSV',
    },
    {
      action: () => handleCSVDownload(false),
      name: 'Download CSV with underlying data',
    },
  ];

  return (
    <>
      <LeftPane>
        <ButtonsContainer>
          <Breadcrumbs>
            <Breadcrumb>Dashboards</Breadcrumb>
            <Breadcrumb>{title}</Breadcrumb>
          </Breadcrumbs>
          <Spacer />
          <ContextMenu
            items={contextMenuItems}
            customIcon={<DownloadIcon />}
            tooltip="Download CSV..."
          />
        </ButtonsContainer>
        <SectionLabel condensed>
          {`${arrMrr.name} Details - ${revenueMetric.name}`}
        </SectionLabel>
        <ButtonsContainer>
          <Spacer />
          <SliderWrapper width={(periods.length * 10) + 40}>
            <StyledSliderContainer>
              <Slider
                range
                min={0}
                max={sliderMax}
                marks={sliderMarks}
                onChange={(values) => setTempRange(values as number[])}
                onChangeComplete={(values) => setRange(values as number[])}
                draggableTrack
                value={tempRange!}
                disabled={isLoading}
              />
            </StyledSliderContainer>
          </SliderWrapper>
        </ButtonsContainer>
        <FactaTable
          useNativeFilters
          gridRef={gridRef}
          gridOptions={{
            suppressAggFuncInHeader: true,
            groupDisplayType: 'singleColumn',
            groupIncludeTotalFooter: true,
            isGroupOpenByDefault: (params) => isCohorts
              ? false
              : params.api.getRowGroupColumns().length - 1 > params.level,
            rowClassRules: {
              'total-row': ({ node }) => node.footer,
            },
            rowGroupPanelShow: 'never',
            enableCharts: true,
            statusBar: {
              statusPanels: [
                { statusPanel: 'agAggregationComponent' },
              ],
            },
            onRowGroupOpened: ({ api }) => {
              setTimeout(() => {
                api.autoSizeAllColumns();
              }, 0);
            },
            onFilterModified: ({ api }) => {
              setTimeout(() => {
                api.autoSizeAllColumns();
              }, 0);
            },
            getRowClass: (params) => {
              const levels = params.api.getRowGroupColumns().length;
              if (params.node.group && params.node.level === levels - 3) return 'main-level';
              if (params.node.group && params.node.level === levels - 2) return 'second-level';
              if (params.node.group && params.node.level === levels - 1) return 'third-level';
            },
            suppressRowHoverHighlight: true,
            suppressRowClickSelection: true,
            maintainColumnOrder: false,
            defaultCsvExportParams: {
              skipRowGroups: false,
              shouldRowBeSkipped: ({ node }) => !node.group,
            },
            processCellForClipboard: (params) => params.column.getColId() === 'ag-Grid-AutoColumn'
              ? params.value || params.node?.data?.name || ''
              : params.value || 0,
          }}
          dynamicGridOptions={{
            autoGroupColumnDef: {
              sort: 'asc',
              resizable: true,
              minWidth: 300,
              pinned: 'left',
              headerName: 'Dimension',
              valueFormatter: (params) => params.value || params.data?.name || '',
              cellRendererParams: (params: any) => {
                return {
                  value: params.node?.field === 'customerStartMonth'
                    ? formatDateStringToMMMYearDisplay(params.value)
                    : params.value,
                };
              },
              headerValueGetter: () => `${dimension1.name}${dimension2.name !== 'None' ? ` / ${dimension2.name}` : ''}${dimension3.name !== 'None' ? ` / ${dimension3.name}` : ''}`,
              useValueFormatterForExport: true,
              cellRendererSelector: (params) => {
                if (revenueMetric?.name.endsWith('Logos') && params.node.leafGroup) {
                  return {
                    component: (p: any) => <div style={{ marginLeft: p.node.level * 42 }}>{p.value}</div>,
                  };
                }
                return { component: 'agGroupCellRenderer' };
              },
            },
          }}
          data={gridData}
          suppressNoRowsOverlay
          columnDefs={colDefs}
          selectedRowsLength={0}
          entityName="detail"
          suppressQuantityDisplay
          suppressColumnViews
          customTitle={title}
          condensed
          isLoading={isLoading}
        />
      </LeftPane>
      <RightPane
        isOpen
        fixed
        collapsible
      >
        <header />
        <main>
          <StyledFormContainer>
            <ButtonsContainer>
              <SectionLabel
                tertiary
                marginBottom={0}
              >
                Table Options
              </SectionLabel>
              <Spacer />
              <Button
                type="button"
                onClick={() => {
                  reset(defaultValues);
                  gridRef.current?.api.setFilterModel(null);
                }}
                variant="borderless"
              >
                Reset
              </Button>
            </ButtonsContainer>
            <Controller
              name="revenueMetric"
              control={control}
              render={({ field }) =>
                <Dropdown
                  {...field}
                  labelText="Revenue Metric"
                  options={isCohorts ? CohortsMetricsOptions : RevenueDetailMetricsOptions}
                  labelField="name"
                  valueField="name"
                  searchBy="name"
                  placeholder="Select Metric"
                  values={[getValues('revenueMetric')]}
                  onChange={(v) => {
                    if (v.length) {
                      field.onChange(v[0]);
                      if (v[0]?.name?.endsWith('Logos')) {
                        setValue('dimension2.name', 'Customer');
                      }
                    }
                  }}
                  condensed
                />
              }
            />
            <Controller
              name="cohortType"
              control={control}
              render={({ field }) =>
                <RadioInput
                  {...field}
                  labelText="Cohort Type"
                  defaultValue={getValues('cohortType')}
                  options={CohortTypeOptions}
                  labelField="name"
                  valueField="name"
                  condensed
                  disabled={!isCohorts}
                  hidden={!isCohorts}
                />
              }
            />
            <Controller
              name="dimension1"
              control={control}
              render={({ field }) =>
                <Dropdown
                  {...field}
                  labelText="Dimensions"
                  options={DimensionOptions}
                  labelField="name"
                  valueField="name"
                  searchBy="name"
                  values={[getValues('dimension1')]}
                  onChange={(v) => v.length ? field.onChange(v[0]) : undefined}
                  condensed
                  clearable
                  disabled={isCohorts}
                  onClearAll={() => field.onChange(DimensionOptions.at(-1))}
                />
              }
            />
            <Controller
              name="dimension2"
              control={control}
              render={({ field }) =>
                <NestedField>
                  <NestedIcon />
                  <Dropdown
                    {...field}
                    options={DimensionOptions}
                    labelField="name"
                    valueField="name"
                    searchBy="name"
                    values={[getValues('dimension2')]}
                    onChange={(v) => v.length ? field.onChange(v[0]) : undefined}
                    condensed
                    clearable
                    disabled={isCohorts && revenueMetric.name.endsWith('Logos')}
                    onClearAll={() => field.onChange(DimensionOptions.at(-1))}
                  />
                </NestedField>
              }
            />
            <Controller
              name="dimension3"
              control={control}
              render={({ field }) =>
                <NestedField>
                  <svg />
                  <NestedIcon />
                  <Dropdown
                    {...field}
                    options={DimensionOptions}
                    labelField="name"
                    valueField="name"
                    searchBy="name"
                    values={[getValues('dimension3')]}
                    onChange={(v) => v.length ? field.onChange(v[0]) : undefined}
                    condensed
                    clearable
                    onClearAll={() => field.onChange(DimensionOptions.at(-1))}
                  />
                </NestedField>
              }
            />
            <Controller
              name="startDateField"
              control={control}
              render={({ field }) =>
                <Dropdown
                  {...field}
                  options={[
                    { name: 'Service Start Date' },
                    { name: 'Committed Booking Date' },
                  ]}
                  labelText="Start Date Field"
                  labelField="name"
                  valueField="name"
                  searchBy="name"
                  values={[getValues('startDateField')]}
                  onChange={(v) => v.length ? field.onChange(v[0]) : undefined}
                  condensed
                />
              }
            />
            <Controller
              name="revenueType"
              control={control}
              render={({ field }) =>
                <Dropdown
                  {...field}
                  options={RevenueTypeOptions}
                  multi
                  labelText="Revenue Type"
                  labelField="label"
                  valueField="value"
                  searchBy="label"
                  values={getValues('revenueType')}
                  condensed
                />
              }
            />
            <SectionLabel
              tertiary
              marginBottom={0}
            >
              Format
            </SectionLabel>
            <Controller
              name="detailFormat"
              control={control}
              render={({ field }) =>
                <Dropdown
                  {...field}
                  labelText="Format In"
                  options={DetailTableFormatOptions}
                  labelField="name"
                  valueField="name"
                  searchBy="name"
                  values={[getValues('detailFormat')]}
                  onChange={(v) => v.length ? field.onChange(v[0]) : undefined}
                  condensed
                />
              }
            />
            <Controller
              name="arrMrr"
              control={control}
              render={({ field }) =>
                <RadioInput
                  {...field}
                  labelText="ARR/MRR"
                  defaultValue={getValues('arrMrr')}
                  options={DetailTableArrMrrOptions}
                  labelField="name"
                  valueField="name"
                  condensed
                  disabled={revenueMetric.dontMultiply}
                />
              }
            />
            <Controller
              name="period"
              control={control}
              render={({ field }) =>
                <RadioInput
                  {...field}
                  labelText="Duration"
                  defaultValue={getValues('period')}
                  options={FrequencyOptions}
                  labelField="name"
                  valueField="name"
                  condensed
                  disabled={isLoading}
                />
              }
            />
          </StyledFormContainer>
        </main>
        <footer />
      </RightPane>
    </>
  );
};
