import React from 'react';
import { CloseIcon } from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { SectionLabel } from 'components/common/SectionLabel';
import { Button } from 'components/common/Button';
import { useDeleteReportMutation } from 'store/services/reports';

interface Props {
  reportId: string;
  onClose: () => void;
}

export const DeleteReport = ({ reportId, onClose }: Props) => {
  const [deleteReport, { isLoading }] = useDeleteReportMutation();

  const handleDelete = () => {
    deleteReport([reportId])
      .unwrap()
      .then(() => {
        onClose();
      });
  };

  return (
    <>
      <SectionLabel alignTop>
        <div>
          Delete Report
          <p>
            If you delete this report, you and anyone in your organization will not be able access it again.
            Are you sure you want to delete this report?
          </p>
        </div>
        <Button
          variant="icon"
          onClick={onClose}
          disabled={isLoading}
          pushRight
        >
          <CloseIcon />
        </Button>
      </SectionLabel>
      <ButtonsContainer>
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          onClick={onClose}
          disabled={isLoading}
          pushRight
          minWidth={200}
        >
          CANCEL
        </Button>
        <Button
          type="button"
          color="primary"
          onClick={handleDelete}
          disabled={isLoading}
          isLoading={isLoading}
          minWidth={200}
        >
          DELETE REPORT
        </Button>
      </ButtonsContainer>
    </>
  );
};
