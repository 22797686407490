import { useState } from 'react';

type Message = {
  id?: string;
  name: string;
  content: JSX.Element | string;
};

const useMessages = (initialMessages?: Message[]) => {
  const [messages, setMessages] = useState<Message[]>(initialMessages || []);

  const addMessage = (input: any) => {
    const newMessage: Message = {
      id: crypto.randomUUID(),
      ...input,
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  return { messages, addMessage };
};

export default useMessages;