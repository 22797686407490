import {
  useState,
  useEffect,
} from 'react';
import { FactaTableView } from 'interfaces/factaTable';

const useCustomView = () => {
  const key = `views_${window.location.pathname.replaceAll("/", "_")}`;
  const [items, setItems] = useState<FactaTableView[]>([]);

  useEffect(() => {
    const storedItems = localStorage.getItem(key);
    if (storedItems) {
      setItems(JSON.parse(storedItems));
    }
  }, [key]);

  const addItem = (item: FactaTableView) => {
    const itemIndex = items.findIndex((i) => i.name === item.name);
    let updatedItems = items;
    if (itemIndex === -1) {
      updatedItems = [...items, item];
    } else {
      updatedItems[itemIndex] = item;
    }
    setItems(updatedItems);
    localStorage.setItem(key, JSON.stringify(updatedItems));
  };

  const removeItem = (name: string) => {
    const updatedItems = items.filter((i) => i.name !== name);
    setItems(updatedItems);
    localStorage.setItem(key, JSON.stringify(updatedItems));
  };

  return {
    customViews: items,
    addCustomView: addItem,
    removeCustomView: removeItem,
  };
};

export default useCustomView;