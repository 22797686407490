import { api } from "./api";
import {
  Tag,
  Tags,
  TagUpdatePayload,
} from "interfaces/tags";
import {
  enrichWithDisabledFlag,
  apiDeleteRecipe,
  apiUpdateRecipe,
  apiAddRecipe,
  apiAddMultipleRecipe,
} from "utils/api";
import {
  ContractsTags,
  CustomersTags,
  MetricsTags,
  ProductsTags,
  SubscriptionsTags,
  TagsTags,
} from "./api-tags";

export const tagsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllTags: builder.query<Tags, void>({
      query: () => ({
        url: 'tags',
      }),
      transformResponse: enrichWithDisabledFlag,
      providesTags: [TagsTags.Tags],
    }),
    deleteTags: builder.mutation<void, string[]>({
      query: (ids) => {
        return {
          url: 'tags',
          method: 'DELETE',
          body: ids,
        };
      },
      onQueryStarted: async (ids, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(tagsApi.util
            .updateQueryData('getAllTags', undefined, apiDeleteRecipe(ids)));
        } catch {}
      },
      invalidatesTags: (res, err) => !err ? [
        TagsTags.Tags,
        ContractsTags.Contracts,
        SubscriptionsTags.Subscriptions,
        ProductsTags.Products,
        CustomersTags.Customers,
        MetricsTags.RevenueDetail,
      ] : [],
    }),
    addTag: builder.mutation<Tag, TagUpdatePayload>({
      query: (tag) => {
        return {
          url: 'tags',
          method: 'POST',
          body: tag,
        };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: addedTag} = await queryFulfilled;
          dispatch(tagsApi.util
            .updateQueryData('getAllTags', undefined, apiAddRecipe(addedTag)));
        } catch {}
      },
    }),
    bulkAddTags: builder.mutation<Tag[], TagUpdatePayload[]>({
      query: (tags) => {
        return {
          url: `tags/bulk_create`,
          method: 'POST',
          body: tags,
        };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: addedTags} = await queryFulfilled;
          dispatch(tagsApi.util
            .updateQueryData('getAllTags', undefined, apiAddMultipleRecipe(addedTags)));
        } catch {}
      },
    }),
    updateTag: builder.mutation<Tag, TagUpdatePayload>({
      query: (tag) => {
        return {
          url: `tags/${tag.id}`,
          method: 'PUT',
          body: tag,
        };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: updatedTag} = await queryFulfilled;
          dispatch(tagsApi.util
            .updateQueryData('getAllTags', undefined, apiUpdateRecipe(updatedTag)));
        } catch {}
      },
      invalidatesTags: (res, err) => !err ? [
        ContractsTags.Contracts,
        SubscriptionsTags.Subscriptions,
        ProductsTags.Products,
        CustomersTags.Customers,
        MetricsTags.RevenueDetail,
      ] : [],
    }),
  }),
});

export const {
  useGetAllTagsQuery,
  useAddTagMutation,
  useUpdateTagMutation,
  useDeleteTagsMutation,
  useBulkAddTagsMutation,
} = tagsApi;
