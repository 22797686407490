import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ContractConnection } from 'interfaces/contracts';

const StyledLink = styled(Link)`
  &:after {
    content: ', ';
  }

  &:last-of-type::after {
    content: '';
  }
`;

interface Props {
  value: ContractConnection[];
}

export const renderContractConnections = ({ value }: Props) => {
  if (!value) return null;

  return (value.length > 0
    ? value.map((contract) => (
      <StyledLink
        key={contract.id}
        to={`/data-input/contract-management/contracts/${contract.id}`}
      >
        <span>{contract.name}</span>
      </StyledLink>
    ))
    : '-'
  );
};
