import styled, { css } from "styled-components";
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { SectionLabel } from "../SectionLabel";
import { Button } from "../Button";

export const StyledFormContainer = styled.form<{ minHeight?: number }>`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 24px;
  grid-template-rows: auto;
  margin-bottom: 1px;
  ${props => props.minHeight && css`
    min-height: ${props.minHeight}px;
    align-content: flex-start;
  `}

  @media screen and (max-height: 800px) {
    grid-gap: 16px;
  }
`;

export const StyledDisabledSection = styled.div<{ disabled?: boolean }>`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 24px;
  grid-template-rows: auto;

  ${props => props.disabled && css`
    opacity: 0.5;
    pointer-events: none;
   
    .react-dropdown-select {
      pointer-events: none;
    }
  `}

  @media screen and (max-height: 800px) {
    grid-gap: 16px;
  }
`;

// TODO Remove
export const StyledContainer = styled.div``;

// TODO Remove
export const StyledWrapper = styled.div<{ suppressOverflow?: boolean }>``;

interface StyledButtonContainerProps {
  marginTop?: number;
}

export const StyledButtonContainer = styled(ButtonsContainer) <StyledButtonContainerProps>`
  padding-top: 24px;
  gap: 24px;

  ${props => props.marginTop && css`
    margin-top: ${props.marginTop}px;
  `}

  & > * {
    flex: 1 1 0;
    max-width: 170px;
  }
`;

export const StyledFormSectionLabel = styled(SectionLabel)`
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  margin-bottom: 24px;

  span:nth-of-type(2) {
    font-size: 14px;
    line-height: 32px;
    font-weight: 500;
  }
`;

interface AdditionalOptionsProps {
  isOpen: boolean;
}

export const StyledAdditionalOptionsContainer = styled.div<AdditionalOptionsProps>`
  display: grid;
  grid-gap: 24px;
  max-height: ${({ isOpen }) => isOpen ? '1000px' : '0px'};
  overflow: ${({ isOpen }) => isOpen ? 'unset' : 'hidden'};
  opacity: ${({ isOpen }) => isOpen ? 1 : 0};
  transition: max-height 0.35s ease-in-out, opacity 0.20s ease-in-out;
  transform-origin: ${({ isOpen }) => isOpen ? 'bottom' : 'top'};
  padding-bottom: 1px;

  & + & {
    padding-bottom: 10px;
  }

  @media screen and (max-height: 800px) {
    grid-gap: 16px;
  }
`;

interface StyledExpandButtonProps {
  isOpen: boolean;
}

export const StyledExpandButton = styled(Button) <StyledExpandButtonProps>`
  svg {
    transform: rotate(${({ isOpen }) => isOpen ? '180deg' : '0deg'})
  }
`;

export const StyledCheckboxContainer = styled.div`
  label {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const StyledFormRow = styled.div<{ multipleLines?: boolean }>`
  display: flex;
  flex-direction: ${props => props.multipleLines ? 'column' : 'row'};
  gap: 24px;
  
  & > * {
    flex: 1 1 0px;
  }
`;
