import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  FormulaIcon,
  CloseIcon,
} from 'assets/icons';
import {
  BackendFormula,
  FormulaElementType,
  FormulaOption,
  FormulaOptions,
  FrontendFormula,
} from 'interfaces/formulaEditor';
import {
  StyledFormula,
  StyledFormulaIcon,
  StyledInput,
  StyledElement,
  SaveHint,
  StyledDropdownWrapper,
  StyledDropdown,
  StyledDropdownItem,
  StyledType,
  FormulaContainer,
  StyledDelete,
  StyledHelper,
  SaveButton,
} from './styled';
import { useDebouncedEffect } from 'hooks/useDebouncedEffect';
import {
  useGetAllMetricsQuery,
  useGetMetricQuery,
  usePostSaveFormulaMutation,
  usePostValidateFormulaMutation,
} from 'store/services/metrics';
import { FormulaElementPopup } from './FormulaElementPopup';
import { SectionLabel } from 'components/common/SectionLabel';
import { toastSuccess } from 'utils/toast';
import { LoadingIcon } from 'components/common/Input/styled';
import { useGetFactaAccountsQuery } from 'store/services/accounts';

interface Props {
  metricId: string;
  debug?: boolean;
  onSuccess?: () => void;
  omitFormulaLabel?: boolean;
}

export const FormulaEditor = ({
  metricId,
  debug,
  onSuccess,
  omitFormulaLabel,
}: Props) => {
  const DELETABLE_TYPES = [FormulaElementType.METRIC, FormulaElementType.ACCOUNT, FormulaElementType.UNKNOWN];
  const [ALLOWED_MODIFIERS] = useState(['+', '-', '*', '/', '(', ')', '^']);
  const [formula, setFormula] = useState<FrontendFormula>([{
    origin: FormulaElementType.INPUT,
    value: '',
  },
  ]);
  const [error, setError] = useState<string | null>(null);
  const [errorIndex, setErrorIndex] = useState<number | null>(null);
  const [inputPosition, setInputPosition] = useState(0);
  const [inputText, setInputText] = useState('');
  const [forceOpen, setForceOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<FormulaOptions>([]);
  const [isSaved, setIsSaved] = useState(true);
  const inputRef = useRef<HTMLSpanElement>(null);
  const accountSelectorRef = useRef<HTMLDivElement>(null);

  const { data: metric } = useGetMetricQuery(metricId, { refetchOnMountOrArgChange: true });
  const { data: metrics } = useGetAllMetricsQuery();
  const { data: factaAccountsResponse } = useGetFactaAccountsQuery();

  const accountsOptions: FormulaOptions = useMemo(() => (factaAccountsResponse || [])
    .map((account) => ({
      ...account,
      origin: FormulaElementType.ACCOUNT,
      category: account.financialType,
    })), [factaAccountsResponse]);

  const metricsOptions: FormulaOptions = useMemo(() => (metrics || [])
    .filter((metric) => metric.id !== metricId)
    .map((metric) => ({
      ...metric,
      origin: FormulaElementType.METRIC,
      category: metric.type.category,
      type: metric.type.name,
      description: metric.description || metric.type.description,
    })), [metricId, metrics]);

  const options = useMemo(() => ([...accountsOptions, ...metricsOptions]
    .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
  ), [accountsOptions, metricsOptions]);

  const [validateFormula, { isLoading: isValidating }] = usePostValidateFormulaMutation();
  const [saveFormula, { isLoading: isSaving }] = usePostSaveFormulaMutation();

  const infereTypeOfRest = (rest: string) => !isNaN(+rest)
    ? FormulaElementType.NUMERIC
    : FormulaElementType.UNKNOWN;

  const infereTypeOfModifier = (modifier?: string) => {
    switch (modifier) {
      case '(':
        return FormulaElementType.CLAUSE;
      case ')':
        return FormulaElementType.CLAUSE_CLOSE;
      default:
        return FormulaElementType.MODIFIER;
    }
  };

  const preparePayload = (updatedFormula?: FrontendFormula): BackendFormula => (updatedFormula || formula)
    .filter((el) => el.origin !== FormulaElementType.INPUT)
    .map(({ origin, value }) => ({
      type: origin,
      value,
    }));

  const moveCursor = (destinationIndex: number) => {
    const from = formula.findIndex((element) => element.origin === FormulaElementType.INPUT);
    const updatedFormula = [...formula];
    updatedFormula.splice(destinationIndex, 0, updatedFormula.splice(from, 1)[0]);
    setFormula(updatedFormula);
  };

  const removeElementFromFormula = (removeIndex: number) => {
    if (formula.length === 1) return;
    const updatedFormula = [
      ...formula.slice(0, removeIndex),
      ...formula.slice(removeIndex + 1),
    ];
    setFormula(updatedFormula);
    setIsSaved(false);
  };

  const clearInput = () => {
    setInputText('');
    inputRef!.current!.innerText = '';
  };

  const prevalidateFormula = (updatedFormula?: FrontendFormula) => {
    const unknownsFound = (updatedFormula || formula)
      .filter((el) => el.origin === FormulaElementType.UNKNOWN);

    if (unknownsFound.length) {
      return `Unknown value${unknownsFound.length > 1 ? 's' : ''} found: ${unknownsFound.map(({ value }) => value)
        .join(', ')}.`;
    }

    return '';
  };

  const handleValidate = () => {
    const prevalidateError = prevalidateFormula();

    if (prevalidateError) {
      setError(prevalidateError);
      return;
    }

    validateFormula({ metricId, formula: preparePayload() })
      .unwrap()
      .then(({ valid, error, errorIndex }) => {
        setError(!valid ? error : '');
        setErrorIndex(errorIndex);
      });
  };

  const handleCommitAndSave = () => {
    if (inputRef!.current!.innerText !== '') {
      const { updatedFormula, rest } = updateFormula();
      setInputPosition(Math.min(inputPosition + (rest ? 1 : 0), updatedFormula.length - 1));
      handleSave(updatedFormula);
    } else {
      setInputPosition(formula.length - 1);
      handleSave(formula);
    }
  };

  const handleSave = (updatedFormula: FrontendFormula) => {
    if (isSaved) return;

    const prevalidateError = prevalidateFormula(updatedFormula);

    if (prevalidateError) {
      setError(prevalidateError);
      return;
    }
    setError('');
    setErrorIndex(null);

    saveFormula({ metricId, formula: preparePayload(updatedFormula) })
      .unwrap()
      .then(() => {
        toastSuccess('Formula successfully saved.');
        onSuccess && onSuccess();
        setError('');
        setErrorIndex(null);
        setIsSaved(true);
      })
      .catch(({ data }) => {
        const { error, errorIndex } = data;

        setError(error);
        setErrorIndex(errorIndex);
      });
  };

  const updateFormula = (modifier?: string) => {
    const rest = inputRef!.current!.innerText;
    const typeOfRest = infereTypeOfRest(rest);
    const typeOfModifier = infereTypeOfModifier(modifier);

    const updatedFormula: FrontendFormula = [
      ...formula.slice(0, inputPosition),
      ...(rest ? [{
        id: crypto.randomUUID(),
        origin: typeOfRest,
        value: typeOfRest === FormulaElementType.NUMERIC ? `${Number(rest)}` : rest,
      }] : []),
      ...(modifier ? [{
        id: crypto.randomUUID(),
        origin: typeOfModifier,
        value: modifier,
      }] : []),
      ...formula.slice(inputPosition),
    ];
    setFormula(updatedFormula);
    setForceOpen(false);
    clearInput();

    return {
      rest,
      updatedFormula,
    };
  };

  const updateFormulaWithSelectedMetric = (option: FormulaOption) => {
    setErrorIndex(null);
    const updatedFormula = [
      ...formula.slice(0, inputPosition),
      {
        id: crypto.randomUUID(),
        origin: option.origin,
        value: option.id,
      },
      ...formula.slice(inputPosition),
    ];
    setFormula(updatedFormula);
    setIsSaved(false);
    setForceOpen(false);
    setInputPosition(inputPosition + 1);
    inputRef.current?.focus();
    clearInput();
  };

  const handleFormulaInputKeyDown = (event: any) => {
    const { key } = event;

    setIsSaved(false);

    if (key === 'Tab' && inputText) {
      event.preventDefault();
      if (searchResults.length) {
        updateFormulaWithSelectedMetric(searchResults[0]);
      } else {
        const { updatedFormula } = updateFormula();
        setInputPosition(Math.min(inputPosition + 1, updatedFormula.length - 1));
      }
    }

    if (key === 'Escape') {
      setForceOpen(false);
    }

    if (key === 'Backspace' && inputRef!.current!.innerText === '' && inputPosition > 0) {
      removeElementFromFormula(inputPosition - 1);
      setInputPosition(inputPosition - 1);
      setErrorIndex(null);
    }

    if (key === 'ArrowLeft') {
      updateFormula();
      setInputPosition(Math.max(inputPosition - 1, 0));
    }

    if (key === 'ArrowRight') {
      const { updatedFormula } = updateFormula();
      setInputPosition(Math.min(inputPosition + 1, updatedFormula.length - 1));
    }

    if (key === 'ArrowDown') {
      if (inputText || forceOpen) {
        setTimeout(() => {
          accountSelectorRef?.current?.focus();
        }, 0);
      }
      if (!inputText) {
        setForceOpen(true);
      }
    }

    if (key === 'ArrowUp') {
      setForceOpen(false);
    }

    if (ALLOWED_MODIFIERS.includes(key)) {
      event.preventDefault();
      setTimeout(() => {
        const { rest } = updateFormula(key);
        setInputPosition(inputPosition + (rest ? 2 : 1));
        setErrorIndex(null);
      }, 0);
    }

    if (key === 'Enter' || key === '=') {
      event.preventDefault();
      handleCommitAndSave();
    }
  };

  const handleDropdownKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, sr: FormulaOption) => {
    const { key } = event;

    if (key === 'Escape') {
      inputRef!.current!.focus();
      setForceOpen(false);
      return;
    }

    if (key === 'Backspace' || key === 'ArrowLeft' || key === 'ArrowRight') {
      inputRef!.current!.focus();
      setForceOpen(false);
      return;
    }

    if (key === 'Enter') {
      event.preventDefault();
      updateFormulaWithSelectedMetric(sr);
      return;
    }

    if (key === 'Tab') {
      event.preventDefault();
      updateFormulaWithSelectedMetric(sr);
      return;
    }

    if (key === 'ArrowUp') {
      event.preventDefault();
      const prevSibling = document.activeElement?.previousSibling as HTMLElement;
      if (prevSibling) {
        prevSibling.focus();
      } else {
        inputRef!.current!.focus();
      }
      return;
    }

    if (key === 'ArrowDown') {
      event.preventDefault();
      const nextSibling = document.activeElement?.nextSibling as HTMLElement;
      if (nextSibling) {
        nextSibling.focus();
      }
      return;
    }

    inputRef.current?.focus();
  };

  const handleElementMouseEnter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { left, width } = event.currentTarget.getBoundingClientRect();
    const isLeft = (left + (width / 2)) / document.body.clientWidth < 0.5;
    event.currentTarget.classList.add(isLeft ? 'left' : 'right');
    event.currentTarget.classList.remove(isLeft ? 'right' : 'left');
  };

  const setInputPositionById = (id: string) => {
    const elementIndex = [...formula]
      .filter((el) => el.origin !== FormulaElementType.INPUT)
      .findIndex((el) => el.id === id);

    setInputPosition(elementIndex + 1);
    inputRef?.current?.focus();
  };

  const getOptionById = (id: string) => options.find((option) => option.id === id);

  const getFormulaElementError = (id: string | undefined, origin: FormulaElementType) => {
    const errorElement = formula.filter((el) => el.origin !== FormulaElementType.INPUT)
      .findIndex((el) => el.id === id);

    return origin === FormulaElementType.UNKNOWN || errorIndex === errorElement;
  };

  useEffect(() => {
    moveCursor(inputPosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputPosition]);

  useEffect(() => {
    if (!ALLOWED_MODIFIERS.includes(inputText.charAt(0))) {
      const filteredAccounts = options.filter((option) => option.name.toLocaleLowerCase()
        .trim()
        .includes(inputText.toLocaleLowerCase()
          .trim()));
      setSearchResults(filteredAccounts);
    }
  }, [ALLOWED_MODIFIERS, options, inputText]);

  useDebouncedEffect(() => {
    if (formula.length > 1) {
      handleValidate();
    }
  }, [formula], 1000, isSaving);

  useEffect(() => {
    setError(null);
    setErrorIndex(null);
    setFormula([
      ...metric?.formula?.map((beFormula) => ({
        id: crypto.randomUUID(),
        origin: beFormula.type,
        value: beFormula.value,
      })) || [],
      {
        origin: FormulaElementType.INPUT,
        value: '',
      },
    ]);
    setInputPosition(metric?.formula?.length || 0);
  }, [metric?.formula]);

  return (
    <div>
      <StyledFormula
        onClick={() => {
          inputRef.current?.focus();
          setInputPosition(formula.length - 1);
        }}
        onDoubleClick={() => setForceOpen(!forceOpen)}
        isError={!!error}
        isUnsaved={!isSaved}
      >
        {!omitFormulaLabel && (
          <SectionLabel tertiary>
            Formula
          </SectionLabel>
        )}
        <div>
          <StyledFormulaIcon>
            <FormulaIcon />&nbsp;=&nbsp;
          </StyledFormulaIcon>
          <FormulaContainer>
            {formula.map(({ origin, value, id }, index) => origin === FormulaElementType.INPUT
              ? <StyledInput
                key="INPUT_ELEMENT"
                ref={inputRef}
                role="textbox"
                contentEditable
                onKeyDown={handleFormulaInputKeyDown}
                onInput={() => setInputText(inputRef.current?.innerText || '')}
                onClick={(event) => event.stopPropagation()}
              />
              : <StyledElement
                key={`formula_${id}`}
                type={origin}
                onClick={(event) => {
                  event.stopPropagation();
                  setInputPositionById(id!);
                }}
                onMouseEnter={handleElementMouseEnter}
                error={getFormulaElementError(id, origin)}
              >
                {origin === FormulaElementType.METRIC || origin === FormulaElementType.ACCOUNT
                  ? getOptionById(value)?.name
                  : value
                }
                {DELETABLE_TYPES.includes(origin) && (
                  <StyledDelete
                    onClick={(event) => {
                      event.stopPropagation();
                      removeElementFromFormula(index);
                      setInputPosition(index);
                    }}
                  >
                    <CloseIcon />
                  </StyledDelete>
                )}
                <FormulaElementPopup
                  option={getOptionById(value)}
                  isFormulaSaved={isSaved}
                />
              </StyledElement>,
            )}
          </FormulaContainer>
          {isSaved
            ? <SaveHint success>SAVED</SaveHint>
            : <SaveHint warning>
              {isSaving ? <><LoadingIcon />Saving...</> : 'Hit Enter to Save'}
            </SaveHint>
          }
          {!isSaved && (
            <SaveButton
              variant="outlined"
              onClick={handleCommitAndSave}
            >
              Save Formula
            </SaveButton>
          )}
        </div>
      </StyledFormula>
      <StyledDropdownWrapper>
        <StyledDropdown open={(!!inputText && searchResults.length > 0) || forceOpen}>
          {searchResults.length === 0 && <StyledDropdownItem>No results...</StyledDropdownItem>}
          {searchResults.map((sr, index) => (
            <StyledDropdownItem
              key={`sr_${sr.id}`}
              ref={index === 0 ? accountSelectorRef : null}
              tabIndex={0}
              onClick={() => updateFormulaWithSelectedMetric(sr)}
              onKeyDown={(e) => handleDropdownKeyDown(e, sr)}
            >
              <div className="line1">
                <div>
                  {sr.name}
                  {index === 0 && inputText && <div className="hit-hint">Tab to Auto Complete</div>}
                </div>
                <StyledType type={sr.origin}>{sr.origin}</StyledType>
              </div>
              <div className="line2">
                {sr.category} &nbsp;/&nbsp; {sr.type}
              </div>
            </StyledDropdownItem>
          ))}
        </StyledDropdown>
      </StyledDropdownWrapper>
      <StyledHelper isError={!!error && !isValidating}>
        {!isValidating && error}
        {isValidating && 'Validating...'}
      </StyledHelper>

      {debug && (
        <div style={{ maxHeight: '70vh', overflow: 'auto', position: 'fixed', bottom: 40, right: 40, minWidth: 300, border: '1px solid silver', borderRadius: 8, background: 'white' }}>
          <div style={{ padding: 8 }}>Input position: {inputPosition}</div>
          <div style={{ padding: 8 }}>Input text: {inputText}</div>
          <div style={{ padding: 8 }}>Search results: {searchResults.length}</div>
          {formula.map((element) => <div
            key={`hiweud_${element.id}`}
            style={{ color: element.origin === FormulaElementType.INPUT ? 'red' : 'gray', borderBottom: 'solid 1px silver', padding: '8px', fontSize: 12 }}
          >
            <div>type: "{element.origin}"</div>
            <div>value: "{element.value}"</div>
          </div>)}
        </div>
      )}
    </div>
  );
};
