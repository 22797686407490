import React, {
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import {
  LeftPane,
  RightPane,
} from 'components/Layout';
import Dropzone, { Accept } from 'react-dropzone';
import { SectionLabel } from 'components/common/SectionLabel';
import {
  useUploadBulkImportContractsMutation,
  useUploadBulkImportSubscriptionsMutation,
} from 'store/services/import';
import {
  ChevronDown,
  DeleteIcon,
  ErrorIcon,
} from 'assets/icons';
import { LoadingBar } from 'components/common/LoadingBar';
import { Button } from 'components/common/Button';
import { Tooltip } from 'components/common/Tooltip';
import {
  StyledDropdownMainText,
  StyledDropzoneContainer,
  StyledDropdownHelperText,
  StyledFile,
  StyledWrapper,
  LoadingBarContainer,
  ErrorContainer,
} from './styled';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import { bulkImportActions } from 'store/slices/bulkImport';
import {
  bulkImportCommonDataSelector,
  bulkImportContractConflictedLinesSelector,
  bulkImportFileDetailsSelector,
} from 'store/selectors/bulkImport';
import { FileDetails } from 'interfaces/bulkImport';
import { format } from 'date-fns';
import { formatSize } from 'utils/formatSize';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import { invalidateTags } from 'store/services/api';
import { toastSuccess } from 'utils/toast';
import { CompanyRevenueType } from 'interfaces/company';
import { BulkImportSidePanel } from 'components/BulkImport/SidePanel';
import { BulkImportContractsInterface } from 'interfaces/bulkImportContracts';
import { BulkImportSubscriptionsInterface } from 'interfaces/bulkImportSubscriptions';
import { SubscriptionsTags } from 'store/services/api-tags';
import { ButtonGroup } from 'pages/Reports/ReportConfiguration/styled';
import { ContextMenu } from 'components/ContextMenu';
import { handleDownloadTemplate } from 'utils/bulkImport';

interface Props {
  companyRevenueType: CompanyRevenueType,
}

export const BulkImportUpload = ({
  companyRevenueType,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [uploadBulkImportSubscriptions, { isLoading: isLoadingSubscriptions }] = useUploadBulkImportSubscriptionsMutation();
  const [uploadBulkImportContracts, { isLoading: isLoadingContracts }] = useUploadBulkImportContractsMutation();
  const [uploadProgress, setUploadProgress] = useState({ percent: 0, remaining: 0 });
  const bulkImportCommonData = useAppSelector(bulkImportCommonDataSelector);
  const bulkImportFileDetails = useAppSelector(bulkImportFileDetailsSelector);
  const contractConflictedLines = useAppSelector(bulkImportContractConflictedLinesSelector);
  const [isProcessingFile, setIsProcessingFile] = useState(false);

  const acceptedFiles: Accept = {
    'text/csv': ['.csv'],
    'text/tab-separated-values': ['.tsv'],
    'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
    'application/vnd.ms-excel': ['.xlsx'],
    'application/pdf': ['.pdf'],
  };

  const uploadBulkImportFile = companyRevenueType === CompanyRevenueType.CONTRACTS
    ? uploadBulkImportContracts
    : uploadBulkImportSubscriptions;

  const isLoading = companyRevenueType === CompanyRevenueType.CONTRACTS
    ? isLoadingContracts
    : isLoadingSubscriptions;

  const importName = companyRevenueType === CompanyRevenueType.CONTRACTS
    ? 'Contracts'
    : 'Revenue';

  const importPath = companyRevenueType === CompanyRevenueType.CONTRACTS
    ? '/data-input/contract-management/contracts'
    : '/data-input/revenue-management/revenue';

  const {
    totalRows,
    contractLinesCount,
  } = bulkImportCommonData;

  const {
    name,
    size,
    lastModified,
    uploadedAt,
  } = bulkImportFileDetails;

  const mapFileExtenstionToType = (filename: string) => {
    const extension = filename.split('.')
      .at(-1);

    switch (extension) {
      case 'tsv':
        return 'text/tsv';
      default:
        return 'unknown';
    }
  };

  const handleUploadFile = (acceptedFile: File) => {
    const formData = new FormData();
    formData.append('file', acceptedFile);
    formData.append('type', acceptedFile.type || mapFileExtenstionToType(acceptedFile.name));
    setIsProcessingFile(false);
    setUploadProgress({ percent: 0, remaining: 0 });
    dispatch(bulkImportActions.userReachedReviewDataScreen(false));

    const fileDetails: FileDetails = {
      name: acceptedFile.name,
      size: acceptedFile.size,
      lastModified: format(new Date(acceptedFile.lastModified), "M/d/yyyy 'at' p"),
      uploadedAt: format(new Date(), "M/d/yyyy 'at' p"),
    };

    uploadBulkImportFile({
      formData,
      onProgress: (percent, remaining) => setUploadProgress({
        percent: Math.round(percent),
        remaining: Math.round(remaining / 1000),
      }),
    })
      .unwrap()
      .then((result) => {
        if (result) {
          if (companyRevenueType === CompanyRevenueType.CONTRACTS) {
            const typedResult = result as BulkImportContractsInterface;
            dispatch(bulkImportActions.populateContracts({ result: typedResult, fileDetails }));
          }

          if (companyRevenueType === CompanyRevenueType.SUBSCRIPTIONS) {
            const typedResult = result as BulkImportSubscriptionsInterface;
            dispatch(bulkImportActions.populateSubscriptions({ result: typedResult, fileDetails }));
          }
        } else {
          toastSuccess(`${importName} successfully added`);
          dispatch(invalidateTags([SubscriptionsTags.Subscriptions]));
          dispatch(bulkImportActions.clear());
          navigate(importPath);
        }
      });
  };

  const handleCancel = () => navigate(importPath);
  const handleContinue = () => navigate(`${importPath}/import/clean-data`);

  useEffect(() => {
    if (uploadProgress.percent === 100) {
      setTimeout(() => {
        setIsProcessingFile(true);
      }, 2000);
    }
  }, [uploadProgress]);

  return (
    <>
      <LeftPane>
        <ButtonsContainer paddingBottom={20}>
          <Breadcrumbs>
            <Breadcrumb link={importPath}>{importName}</Breadcrumb>
            <Breadcrumb>Upload File</Breadcrumb>
          </Breadcrumbs>
        </ButtonsContainer>
        <ButtonsContainer alignTop>
          <SectionLabel>
            <span>
              Upload File
            </span>
          </SectionLabel>
          <Spacer />
          <ButtonGroup shouldOmitGrouping={companyRevenueType === CompanyRevenueType.CONTRACTS}>
            <Button
              onClick={() => handleDownloadTemplate('standard', companyRevenueType)}
              variant="contained"
              minWidth={144}
            >
              DOWNLOAD IMPORT TEMPLATE
            </Button>
            {companyRevenueType === CompanyRevenueType.SUBSCRIPTIONS && (
              <ContextMenu
                items={[
                  {
                    name: 'Download Flat Data Template',
                    action: () => handleDownloadTemplate('standard', companyRevenueType),
                  },
                  {
                    name: 'Download Customer Table Template',
                    action: () => handleDownloadTemplate('unpivot', companyRevenueType),
                  },
                ]}
                customIcon={<ChevronDown />}
                variant="contained"
              />
            )}
          </ButtonGroup>
        </ButtonsContainer>
        <StyledWrapper>
          <div>
            <StyledDropdownMainText>Upload File</StyledDropdownMainText>
            <Dropzone
              disabled={isLoading}
              multiple={false}
              onDrop={acceptedFile => handleUploadFile(acceptedFile[0])}
              maxSize={524288000} // 500mb file limit
              accept={acceptedFiles}
            >
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject,
              }) => (
                <StyledDropzoneContainer {...getRootProps({ isDragActive, isDragReject })}>
                  <input {...getInputProps()} />
                  <StyledDropdownMainText>
                    Drop your spreadsheet or
                    {companyRevenueType === CompanyRevenueType.CONTRACTS ? ' contract ' : ' revenue '}
                    pdf file here or <em>browse</em></StyledDropdownMainText>
                  <StyledDropdownHelperText>Max file size: [500mb]</StyledDropdownHelperText>
                </StyledDropzoneContainer>
              )}
            </Dropzone>
            <StyledDropdownHelperText>Supported files: {Object.values(acceptedFiles)
              .join(', ')}</StyledDropdownHelperText>
          </div>
        </StyledWrapper>
        {isLoading && (
          <StyledWrapper>
            <div>
              <StyledDropdownMainText>
                {isProcessingFile
                  ? 'Processing file...'
                  : 'File uploading...'
                }
              </StyledDropdownMainText>
              <LoadingBarContainer>
                <LoadingBar progress={isProcessingFile ? undefined : uploadProgress.percent} />
              </LoadingBarContainer>
              <StyledDropdownHelperText>
                {uploadProgress.percent}%
                {' | '}
                {isProcessingFile
                  ? 'Processing file...'
                  : `${uploadProgress.remaining} seconds remaining`
                }
              </StyledDropdownHelperText>
            </div>
          </StyledWrapper>
        )}
        {uploadedAt && (
          <StyledWrapper>
            <div>
              <StyledDropdownMainText>
                Uploaded File
              </StyledDropdownMainText>
              <StyledFile>
                <div>
                  <span>
                    {name}
                  </span>
                  <span>
                    {lastModified}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {formatSize(size || 0)}
                  </span>
                </div>
                <Tooltip title="Remove file and start over">
                  <Button
                    variant="icon"
                    onClick={() => dispatch(bulkImportActions.clear())}
                    aria-label="Remove file and start over"
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </StyledFile>
              <StyledDropdownHelperText>
                Total records found: {totalRows || contractLinesCount || '-'}
              </StyledDropdownHelperText>
              {!!contractConflictedLines.length && (
                <ErrorContainer>
                  <div>
                    <ErrorIcon />
                  </div>
                  <div>
                    Contract errors detected on {contractConflictedLines.length} line{contractConflictedLines.length > 1 ? 's' : ''}.
                    {' '}
                    {contractConflictedLines.length < 20
                      ? <>
                        Please check line{contractConflictedLines.length > 1 ? 's' : ''}
                        {contractConflictedLines.map((line) => <span key={`cc_${line}`}>{line}</span>)}
                        for contract issues and try again.
                      </>
                      : 'Please check your file before submitting.'}
                  </div>
                </ErrorContainer>
              )}
            </div>
          </StyledWrapper>
        )}
      </LeftPane>
      <RightPane isOpen={true}>
        <BulkImportSidePanel
          companyRevenueType={companyRevenueType}
          handleCancel={handleCancel}
          handleContinue={handleContinue}
          isContinueDisabled={!uploadedAt || !!contractConflictedLines.length}
          step={1}
          isStepDone={!!uploadedAt}
        />
      </RightPane>
    </>
  );
};
