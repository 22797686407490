import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  LeftPane,
  RightPane,
} from "components/Layout";
import { SectionLabel } from "components/common/SectionLabel";
import {
  useChangeCompanyMutation,
  useGetAuthMeQuery,
} from "store/services/auth";
import { FactaTable } from "components/FactaTable";
import { AgGridReact } from "@ag-grid-community/react";
import {
  useNavigate,
  useParams,
} from "react-router";
import {
  CopyIcon,
  DeleteIcon,
  EditIcon,
} from "assets/icons";
import { CompanyStatus } from "interfaces/company";
import { clearApiState } from "store/services/api";
import { useAppDispatch } from "hooks/redux";
import { useRightPanes } from "hooks/useRightPanes";
import {
  highlightNodeById,
  reselectNodeById,
} from "utils/aggrid";
import { Tabs } from "components/common/Tabs";
import { getColumnDefs } from "./columnDefinitions";
import { AddCompanyPane } from "./AddCompanyPane";
import { EditCompanyPane } from "./EditCompanyPane";
import { DuplicateCompany } from "./DuplicateCompany";
import { DeleteCompanies } from "./DeleteCompanies";
import { Company } from "interfaces/company";
import { UserRole } from "interfaces/auth";
import { slugify } from "utils/slugify";

export const Companies = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();
  const [changed, setChanged] = useState(false);
  const { data: user, isLoading } = useGetAuthMeQuery();
  const [changeCompany, { isLoading: isChangingCompany }] = useChangeCompanyMutation();
  const [selectedRows, setSelectedRows] = useState<Company[]>([]);

  const { companyStatus = 'all' } = useParams();

  const companies = useMemo(() => (companyStatus === 'all'
    ? user?.companies
    : user?.companies?.filter((com) => slugify(com.companyStatus!) === companyStatus)),
    [companyStatus, user?.companies]);

  const { rightPanes, setSelectedPane, resetPanes } = useRightPanes({
    add: false,
    edit: false,
    duplicate: false,
    delete: false,
  });

  const handleChangeCompany = (companyId: string) => {
    changeCompany({ companyId })
      .unwrap()
      .then(() => {
        setChanged(true);
      });
  };

  const colDefs = getColumnDefs({ handleChangeCompany });

  useEffect(() => {
    if (changed) {
      dispatch(clearApiState);
      navigate('/');
    }
  }, [dispatch, navigate, user?.currentCompanyId, changed]);

  return (
    <>
      <LeftPane>
        <SectionLabel>
          Companies
        </SectionLabel>
        <Tabs
          tabs={[
            {
              name: 'All',
              value: 'all',
            },
            {
              name: CompanyStatus.ACTIVE,
              value: slugify(CompanyStatus.ACTIVE),
            },
            {
              name: CompanyStatus.PROSPECTS,
              value: slugify(CompanyStatus.PROSPECTS),
            },
            {
              name: CompanyStatus.ARCHIVED,
              value: slugify(CompanyStatus.ARCHIVED),
            },
          ]}
          value={companyStatus}
          onChange={({ value }) => {
            navigate(`/settings/companies/${value}`);
            gridRef.current?.api.deselectAll();
          }}
          disabled={rightPanes.add}
        />
        <FactaTable
          gridOptions={{
            onSelectionChanged: (e) => {
              const selectedRows = e.api.getSelectedRows();
              setSelectedRows(selectedRows);
              if (selectedRows.length) {
                resetPanes();
              }
            },
            rowClassRules: {
              'row-bold': ({ data }) => data?.id === user?.currentCompanyId,
            },
          }}
          gridRef={gridRef}
          data={companies || []}
          useNativeFilters
          columnDefs={colDefs}
          selectedRowsLength={selectedRows.length}
          isLoading={isLoading || isChangingCompany}
          entityName={companies?.length === 1 ? "company" : "companie"}
          customButtons={[
            {
              title: 'Edit',
              icon: <EditIcon />,
              size: 'large',
              variant: 'borderless',
              hidden: !selectedRows.length,
              onClick: () => setSelectedPane('edit'),
              divider: true,
              tooltip: ![UserRole.OWNER, UserRole.SUPERUSER].includes(selectedRows[0]?.role)
                ? 'Cannot edit company with role different than Owner'
                : 'Edit selected company',
              disabled: selectedRows.length > 1 || ![UserRole.OWNER, UserRole.SUPERUSER].includes(selectedRows[0]?.role),
            },
            {
              title: `Duplicate`,
              icon: <CopyIcon />,
              size: 'large',
              variant: 'borderless',
              hidden: !selectedRows.length,
              onClick: () => setSelectedPane('duplicate'),
              divider: true,
              tooltip: ![UserRole.OWNER, UserRole.SUPERUSER, UserRole.ADMIN].includes(selectedRows[0]?.role)
                ? 'Cannot duplicate company with role different than Owner or Admin'
                : 'Duplicate selected company',
              disabled: selectedRows.length > 1 || ![UserRole.OWNER, UserRole.SUPERUSER, UserRole.ADMIN].includes(selectedRows[0]?.role),
            },
            {
              title: 'Delete',
              icon: <DeleteIcon />,
              color: 'error',
              size: 'large',
              variant: 'borderless',
              hidden: !selectedRows.length,
              onClick: () => setSelectedPane('delete'),
              divider: true,
              tooltip: selectedRows.some((row) => row.role !== UserRole.OWNER)
                ? 'Cannot delete company with role different than Owner'
                : 'Delete selected company',
              disabled: selectedRows.some((row) => row.role !== UserRole.OWNER),
            },
          ]}
          customAddLabel="Add Company"
          onClickAdd={() => setSelectedPane('add')}
          showQuickSearch
        />
      </LeftPane>
      <RightPane isOpen={rightPanes.add}>
        <AddCompanyPane
          onClose={resetPanes}
          onSuccess={(result) => highlightNodeById(result?.id, gridRef)}
        />
      </RightPane>
      <RightPane isOpen={rightPanes.delete && selectedRows.length > 0}>
        <DeleteCompanies
          selectedRows={selectedRows}
          onClose={resetPanes}
        />
      </RightPane>
      <RightPane isOpen={rightPanes.edit}>
        <EditCompanyPane
          onClose={resetPanes}
          onSuccess={(id) => {
            highlightNodeById(id, gridRef);
            gridRef.current?.api.deselectAll();
          }}
          selectedRows={selectedRows}
        />
      </RightPane>
      <RightPane isOpen={rightPanes.duplicate && selectedRows.length > 0}>
        <DuplicateCompany
          onClose={resetPanes}
          onSuccess={(result) => {
            highlightNodeById(result?.id, gridRef);
            reselectNodeById(result?.id, gridRef);
          }}
          selectedRows={selectedRows}
        />
      </RightPane>
    </>
  );
};
