import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { FactaTable } from 'components/FactaTable';
import { SectionLabel } from 'components/common/SectionLabel';
import {
  ColDef,
  GridOptions,
} from '@ag-grid-community/core';
import {
  CloseIcon,
  DownloadIcon,
} from 'assets/icons';
import {
  useGetReportQuery,
  useGetReportVersionsQuery,
} from 'store/services/reports';
import { formatDateStringToMMMYearDisplay } from 'utils/dates';
import { LeftPane } from 'components/Layout';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import { Button } from 'components/common/Button';
import { Tooltip } from 'components/common/Tooltip';
import { useLocation } from 'react-router';
import { toastSuccess } from 'utils/toast';
import { format as currencyFormat } from "currency-formatter";
import { TableWithNotes } from './styled';
import { Company } from 'interfaces/company';

interface Props {
  reportId: string;
  onClose: () => void;
  currentCompany: Company;
}

export const PreviewReport = ({
  reportId,
  onClose,
  currentCompany,
}: Props) => {
  const { hash } = useLocation();
  const gridRef = useRef<AgGridReact>(null);
  const [columns, setColumns] = useState<ColDef[]>([]);
  const [rowHeight, setRowHeight] = useState(32);
  const { data: report, isFetching: isLoadingReport } = useGetReportQuery(reportId, { refetchOnMountOrArgChange: true });
  const { data: versions } = useGetReportVersionsQuery();
  const currencyCode = currentCompany.currency.code;

  const gridOptions: GridOptions = useMemo(() => ({
    suppressClipboardPaste: true,
    defaultColDef: {
      resizable: true,
      initialWidth: 200,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    },
    maintainColumnOrder: true,
    suppressMaintainUnsortedOrder: true,
    enableCharts: true,
    onFirstDataRendered: (params) => {
      params.api.autoSizeAllColumns();
    },
    onBodyScrollEnd: (params) => {
      params.api.autoSizeAllColumns();
    },
    rowClassRules: {
      'row-bold': ({ data }: any) => data.format?.bold,
      'row-underline': ({ data }: any) => data.format?.underline,
      'row-border': ({ data }: any) => data.format?.border,
    },
  }), []);

  const dynamicGridOptions: GridOptions = {
    onCellKeyDown: (params) => {
      const key = (params.event as KeyboardEvent)?.key;

      switch (key) {
        case '=':
        case '+':
          setRowHeight(Math.min(rowHeight + 4, 48));
          break;
        case '-':
          setRowHeight(Math.max(rowHeight - 4, 24));
          break;
      }
    },
    rowHeight: rowHeight,
    headerHeight: rowHeight,
    noRowsOverlayComponent: () => hash === '#download'
      ? 'Preparing download...'
      : isLoadingReport
        ? 'Loading...'
        : 'This report is empty.',
  };

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: '',
        minWidth: 270,
        editable: false,
        sortable: false,
      },
      ...columns,
    ];
  }, [columns]);

  useEffect(() => {
    if (!report?.columns) return;

    setColumns(report.columns.map((col) => ({
      field: col.id,
      minWidth: 110,
      headerName: `${(col.period?.length === 10 && !col.period?.includes('FY'))
        ? formatDateStringToMMMYearDisplay(col.period!)
        : col.period || 'No period'}`,
      valueGetter: ({ data }) => {
        const value = data.values?.find((val: any) => val.columnId === col.id)?.value;

        if (!data.source || typeof value !== 'number') return '';

        const precision = data.format.precision ?? data.source.precision;
        const valueFormat = data.format.valueFormat || data.source.valueFormat;

        switch (valueFormat) {
          case 'percentage':
            return `${(value * 100).toFixed(precision)}%`;

          case 'currency':
            return `${currencyFormat(value, {
              code: currencyCode,
              precision: precision,
            })}`;

          case 'number':
            return `${currencyFormat(value, {
              code: 'USD',
              precision: precision,
              symbol: '',
            })}`;

          case 'multiple':
            return `${currencyFormat(value, {
              code: 'USD',
              precision: precision,
              symbol: '',
            })}x`;

          default:
            return '';
        }
      },
      type: 'rightAligned',
      sortable: false,
      suppressMenu: true,
    })));
  }, [currencyCode, report?.columns, versions]);

  const handleCSVDownload = () => gridRef.current?.api.exportDataAsCsv({
    fileName: `insights-report-${report?.name.replace(/[^a-z0-9]/gi, '-')
      .toLowerCase() || 'unnamed'}.csv`,
    skipRowGroups: true,
    skipColumnGroupHeaders: true,
    processHeaderCallback: ({ column }) => column.getColDef().headerName || '',
    appendContent: report?.shouldExportNotes ? report?.notes : undefined,
  });

  useEffect(() => {
    if (report && versions && columns.length && hash === '#download') {
      handleCSVDownload();
      toastSuccess('Report CSV downloaded successfully.');
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash, versions, columns, report, onClose]);

  useEffect(() => {
    gridRef.current?.api?.resetRowHeights();
  }, [rowHeight]);

  return (
    <LeftPane removeMargin>
      <ButtonsContainer alignTop>
        <SectionLabel marginBottom={8}>
          {report?.name || '-'}
        </SectionLabel>
        <Spacer />
        <Tooltip title="Download CSV">
          <Button
            variant="icon"
            aria-label="Download"
            onClick={handleCSVDownload}
          >
            <DownloadIcon />
          </Button>
        </Tooltip>
        <Button
          aria-label="Close"
          variant="icon"
          size="large"
          onClick={onClose}
        >
          <CloseIcon />
        </Button>
      </ButtonsContainer>
      <TableWithNotes>
        <FactaTable
          gridRef={gridRef}
          gridOptions={gridOptions}
          dynamicGridOptions={dynamicGridOptions}
          data={report?.lines || []}
          columnDefs={columnDefs}
          selectedRowsLength={0}
          isDeleteDisabled={false}
          entityName="report line"
          condensed
          isLoading={isLoadingReport}
          suppressAddButton
          noBordersAndFocus
        />
        {report?.shouldExportNotes && report.notes && (
          <aside>
            {report.notes.split('\n')
              .map((line) => <p key={line}>{line}</p>)}
          </aside>
        )}
      </TableWithNotes>
    </LeftPane>
  );
};
