import React, { useEffect } from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router';
import { LeftPane } from 'components/Layout';
import { useGetDashboardsQuery } from 'store/services/dashboards';
import { Loading } from 'components/Loading';

export const Dashboards = () => {
  const { data: dashboards, isLoading } = useGetDashboardsQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const group = location.pathname.split('/')[2];

  useEffect(() => {
    const firstItem = dashboards
      ?.filter((dash) => dash.group === group)
      ?.at(0);

    if (firstItem) {
      navigate(`/dashboards/${firstItem.group}/${firstItem.path}`);
    }
  }, [dashboards, group, navigate]);

  return (
    <>
      {isLoading
        ? (
            <LeftPane>
              <Loading title="Dashboards are loading..." />
            </LeftPane>
          )
        : <Outlet />
      }
    </>
  );
};
