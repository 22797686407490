import { api } from "./api";
import {
  Contract,
  ContractAddPayload,
  Contracts,
  ContractsCancelPayload,
  ContractsLinkPayload,
  ContractsMergePayload,
  ContractsSummary,
  ContractsUnlinkPayload,
  ContractUpdatePayload,
} from "interfaces/contracts";
import {
  enrichEntryWithDisabledFlag,
  enrichSearchResponseWithDisabledFlag,
} from "utils/api";
import { downloadFile } from "utils/downloadFile";
import {
  CommonMetricTags,
  ContractLinesTags,
  ContractsTags,
} from "./api-tags";
import {
  SearchRequest,
  SearchResponse,
} from "interfaces/api";

export const contractsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getContractsSearch: builder.query<SearchResponse<Contracts>, SearchRequest>({
      query: (filtering) => ({
        method: 'POST',
        url: 'contracts/list',
        body: {
          ...filtering,
        },
      }),
      transformResponse: enrichSearchResponseWithDisabledFlag,
      providesTags: [ContractsTags.Contracts],
    }),
    deleteContracts: builder.mutation<void, string[]>({
      query: (ids) => {
        return {
          url: `contracts`,
          method: 'DELETE',
          body: ids,
        };
      },
      invalidatesTags: (res, err) => !err ? [
        ContractsTags.Contracts,
        ContractLinesTags.ContractLines,
        ContractLinesTags.ContractLinesByContractIds,
        ...CommonMetricTags,
      ] : [],
    }),
    getContract: builder.query<Contract, string>({
      query: (contractId) => ({
        url: `contracts/${contractId}`,
      }),
      transformResponse: enrichEntryWithDisabledFlag,
      providesTags: (result, error, id) => [{ type: ContractsTags.Contracts, id }],
    }),
    addContract: builder.mutation<Contract, ContractAddPayload>({
      query: (contract) => ({
        url: 'contracts',
        method: 'POST',
        body: contract,
      }),
      invalidatesTags: (res, err) => !err ? [
        ContractsTags.Contracts,
        ...CommonMetricTags,
      ] : [],
    }),
    updateContract: builder.mutation<Contract, ContractUpdatePayload>({
      query: (contract) => ({
        url: `contracts/${contract.id}`,
        method: 'PUT',
        body: contract,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: updatedContract} = await queryFulfilled;
          dispatch(contractsApi.util
            .updateQueryData('getContract', updatedContract.id, () => updatedContract));
        } catch {}
      },
      invalidatesTags: (res, err) => !err ? [
        ContractsTags.Contracts,
        ContractLinesTags.ContractLines,
        ContractLinesTags.ContractLinesByContractIds,
        ...CommonMetricTags,
      ]: [],
    }),
    cancelContracts: builder.mutation<void, ContractsCancelPayload>({
      query: (cancelBody) => {
        return {
          url: 'contracts/cancel',
          method: 'POST',
          body: cancelBody,
        };
      },
      invalidatesTags: (res, err) => !err ? [
        ContractsTags.Contracts,
        ContractLinesTags.ContractLines,
        ContractLinesTags.ContractLinesByContractIds,
        ...CommonMetricTags,
      ] : [],
    }),
    exportContracts: builder.query<null, void>({
      query: () => {
        return {
          url: 'contracts/export',
          method: 'GET',
          cache: "no-cache",
        };
      },
      transformResponse: (fileContent: string) => {
        downloadFile(fileContent, 'insights-contracts.csv');
        return null;
      },
    }),
    mergeContracts: builder.mutation<Contract, ContractsMergePayload>({
      query: (mergeBody) => {
        return {
          url: 'contracts/merge',
          method: 'POST',
          body: mergeBody,
        };
      },
      invalidatesTags: (res, err) => !err ? [
        ContractsTags.Contracts,
        ContractLinesTags.ContractLines,
        ContractLinesTags.ContractLinesByContractIds,
        ...CommonMetricTags,
      ] : [],
    }),
    linkContracts: builder.mutation<void, ContractsLinkPayload>({
      query: (linkBody) => {
        return {
          url: 'contracts/link',
          method: 'POST',
          body: linkBody,
        };
      },
      invalidatesTags: (res, err) => !err ? [
        ContractsTags.Contracts,
        ContractLinesTags.ContractLines,
        ContractLinesTags.ContractLinesByContractIds,
        ...CommonMetricTags,
      ] : [],
    }),
    unlinkContracts: builder.mutation<void, ContractsUnlinkPayload>({
      query: (linkBody) => {

        const {
          contractId,
          ...rest
        } = linkBody;

        return {
          url: `contracts/${contractId}/unlink`,
          method: 'POST',
          body: rest,
        };
      },
      invalidatesTags: (res, err) => !err ? [
        ContractsTags.Contracts,
        ContractLinesTags.ContractLines,
        ContractLinesTags.ContractLinesByContractIds,
        ...CommonMetricTags,
      ] : [],
    }),
    contractsUniqueness: builder.query<boolean, { name: string }>({
      query: (body) => ({
        method: 'POST',
        url: 'contracts/unique',
        body,
      }),
    }),
    getContractsSummary: builder.query<ContractsSummary, void>({
      query: () => ({
        url: 'contracts/summary',
      }),
      providesTags: [ContractsTags.ContractsSummary],
    }),
  }),
});

export const {
  useDeleteContractsMutation,
  useGetContractQuery,
  useAddContractMutation,
  useUpdateContractMutation,
  useCancelContractsMutation,
  useLazyExportContractsQuery,
  useMergeContractsMutation,
  useLazyContractsUniquenessQuery,
  useLinkContractsMutation,
  useUnlinkContractsMutation,
  useGetContractsSummaryQuery,
  useGetContractsSearchQuery,
} = contractsApi;
