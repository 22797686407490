import { UserRole } from "interfaces/auth";
import { StatusOption } from "interfaces/common";
import {
  BusinessStageType,
  BusinessType,
  CompanyRevenueType,
  CompanyStatus,
  CompanyUserStatus,
  IndustryType,
} from "interfaces/company";
import {
  AggregationMethod,
  MetricCategory,
  MetricOrigin,
} from "interfaces/metrics";
import { RevenueType } from "interfaces/products";
import { TagType } from "interfaces/tags";

export const PER_PAGE = 50;
export const RESPONSIVE_LAYOUT_MIN_PX = 450;
export const RESPONSIVE_LAYOUT_MAX_PERCENT = 0.5;
export const ANIMATION_DURATION = 300; // milliseconds
export const DRAFT_SAVE_INTERVAL = 2000; // milliseconds
export const IMPORT_TEMPLATE_FILENAME = 'Revenues_Import_Template.csv';
export const CONTRACTS_IMPORT_TEMPLATE_FILENAME = 'Contracts_Import_Template.csv';
export const UNPIVOT_TEMPLATE_FILENAME = 'Unpivot_Tool_Template.csv';
export const RETOOL_ADMIN_URL = 'https://facta.retool.com/apps/e1ac8fe8-3453-11ed-a750-87aaec845cc9/Insights%20Admin';
export const EXCEL_ADDIN_URL = 'https://appsource.microsoft.com/en-en/product/Office365/WA200005939';
export const INSIGHTS_HELP_URL = 'https://help.facta.io';
export const AG_GRID_LICENSE_KEY = 'Using_this_{AG_Grid}_Enterprise_key_{AG-056376}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Facta,_Inc}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Insights}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Insights}_need_to_be_licensed___{Insights}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{11_May_2025}____[v3]_[01]_MTc0NjkxODAwMDAwMA==a9c06f27d745f53a77c7ca464c3db14d';
export const MAX_PERIODS = 999;
export const SalesforceFlowId = 'gjbKIlcdq';

export const COMPARE_OPTIONS = [
  {
    value: 'lt',
    name: '<',
  },
  {
    value: 'lte',
    name: '≤',
  },
  {
    value: 'eq',
    name: '=',
  },
  {
    value: 'gte',
    name: '≥',
  },
  {
    value: 'gt',
    name: '>',
  },
];

export const RevenueTypeOptions = [
  {
    label: RevenueType.NON_RECURRING,
    value: RevenueType.NON_RECURRING,
  },
  {
    label: RevenueType.RECURRING,
    value: RevenueType.RECURRING,
  },
  {
    label: RevenueType.USAGE_BASED,
    value: RevenueType.USAGE_BASED,
  },
];

export const TagTypeOptionsContracts = [
  {
    label: TagType.CONTRACT,
    value: TagType.CONTRACT,
  },
  {
    label: TagType.CUSTOMER,
    value: TagType.CUSTOMER,
  },
  {
    label: TagType.PRODUCT,
    value: TagType.PRODUCT,
  },
];

export const TagTypeOptionsSubscriptions = [
  {
    label: 'Revenue',
    value: TagType.CONTRACT,
  },
  {
    label: TagType.CUSTOMER,
    value: TagType.CUSTOMER,
  },
  {
    label: TagType.PRODUCT,
    value: TagType.PRODUCT,
  },
];

export const StatusOptions = [
  {
    value: StatusOption.NEW,
  },
  {
    value: StatusOption.OUTOFTERM,
  },
  {
    value: StatusOption.RENEWED,
  },
  {
    value: StatusOption.CANCELED,
  },
  {
    value: StatusOption.RENEWAL,
  },
  {
    value: StatusOption.UPSELL,
  },
  {
    value: StatusOption.DOWNGRADE,
  },
];

export const IndustryTypeOptions = [
  {
    value: IndustryType.ECOMMERCE,
  },
  {
    value: IndustryType.FINANCIAL_TECHNOLOGY,
  },
  {
    value: IndustryType.PROFESSIONAL_SERVICES,
  },
  {
    value: IndustryType.SOFTWARE_AS_A_SERVICE,
  },
  {
    value: IndustryType.OTHER,
  },
];

export const BusinessTypeOptions = [
  {
    value: BusinessType.ENTERPRISE_B2B_SOFTWARE,
  },
  {
    value: BusinessType.B2C_SOFTWARE,
  },
  {
    value: BusinessType.SMB_SOFTWARE,
  },
  {
    value: BusinessType.OTHER,
  },
];

export const BusinessStageOptions = [
  {
    value: BusinessStageType.EARLY_STAGE,
  },
  {
    value: BusinessStageType.LATE_STAGE,
  },
  {
    value: BusinessStageType.PRIVATE_EQUITY_BACKED,
  },
  {
    value: BusinessStageType.PUBLIC,
  },
  {
    value: BusinessStageType.OTHER,
  },
];

// Note: Please do not change the order of the options
export const UserRoleOptions = [
  {
    value: UserRole.OWNER,
  },
  {
    value: UserRole.ADMIN,
  },
  {
    value: UserRole.MEMBER,
  },
  {
    value: UserRole.VIEWER,
  },
];

export const CompanyUserStatusOptions = [
  {
    value: CompanyUserStatus.ACTIVE,
  },
  {
    value: CompanyUserStatus.DENIED,
  },
  {
    value: CompanyUserStatus.PENDING,
  },
];

export const MetricCategoryOptions = [
  {
    name: MetricCategory.SAAS,
    value: MetricCategory.SAAS,
  },
  {
    name: MetricCategory.PL,
    value: MetricCategory.PL,
  },
  {
    name: MetricCategory.BS,
    value: MetricCategory.BS,
  },
  {
    name: MetricCategory.OTHER,
    value: MetricCategory.OTHER,
  },
];

export const MetricAggregationMethodOptions = [
  {
    name: 'Ending Balance',
    value: AggregationMethod.EOP,
  },
  {
    name: 'Beginning Balance',
    value: AggregationMethod.BOP,
  },
  {
    name: 'Average',
    value: AggregationMethod.AVG,
  },
  {
    name: 'Summative',
    value: AggregationMethod.SUM,
  },
  {
    name: 'Calculation',
    value: AggregationMethod.CALC,
  },
];

export const MetricOriginOptions = [
  {
    name: 'System',
    value: MetricOrigin.SYSTEM,
  },
  {
    name: 'System Copy',
    value: MetricOrigin.SYSTEM_COPY,
  },
  {
    name: 'Custom',
    value: MetricOrigin.CUSTOM,
  },
  {
    name: 'Manual',
    value: MetricOrigin.MANUAL,
  },
];

export const ReportTemplateOptions = [
  {
    id: 'profit_and_loss',
    name: 'Profit & Loss',
  },
  {
    id: 'balance_sheet',
    name: 'Balance Sheet',
  },
  {
    id: 'saas_metrics',
    name: 'SaaS Metrics',
  },
  {
    id: 'all_metrics',
    name: 'All Metrics',
  },
];

export const CompanyRevenueTypeOptions = [
  {
    name: 'Simple Subscriptions',
    value: CompanyRevenueType.SUBSCRIPTIONS,
    description: 'This method makes uploading data easier and is the preferred setup for high volume, low ticket price businesses.',
    annotation: 'Recommended',
  },
  {
    name: 'Enterprise/Term-Based Contracts',
    value: CompanyRevenueType.CONTRACTS,
    description: 'This method is preferred for businesses that sell enterprise-level or more complex contracts, often with a specific term and potentially multiple product elements.',
  },
];

export const CompanyStatusOptions = [
  {
    value: CompanyStatus.ACTIVE,
  },
  {
    value: CompanyStatus.PROSPECTS,
  },
  {
    value: CompanyStatus.ARCHIVED,
  },
];
