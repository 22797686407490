import { ColDef } from '@ag-grid-community/core';
import {
  caseInsensitiveComparator,
  dateComparator,
} from "utils/aggrid";
import { formatDateStringToMMMYearDisplay } from 'utils/dates';

export const getColumnDefs = (): Array<ColDef> => ([
  {
    headerName: 'Earned Month',
    field: 'earnedMonth',
    sortable: true,
    resizable: true,
    minWidth: 250,
    valueFormatter: ({ value }) => formatDateStringToMMMYearDisplay(value) || '-',
    comparator: dateComparator(),
  },
  {
    headerName: 'Payout Month',
    field: 'payoutMonth',
    sortable: true,
    resizable: true,
    minWidth: 250,
    valueFormatter: ({ value }) => formatDateStringToMMMYearDisplay(value) || '-',
    comparator: dateComparator(),
  },
  {
    headerName: 'Commission Event',
    field: 'commissionEvent',
    sortable: true,
    resizable: true,
    minWidth: 250,
    comparator: caseInsensitiveComparator(),
  },
  {
    headerName: 'Sales Rep E-mail',
    field: 'salesRepEmail',
    sortable: true,
    resizable: true,
    minWidth: 250,
    comparator: caseInsensitiveComparator(),
  },
  {
    headerName: 'Customer',
    field: 'customer',
    sortable: true,
    resizable: true,
    minWidth: 250,
    comparator: caseInsensitiveComparator(),
  },
  {
    headerName: 'Invoice To',
    field: 'invoiceTo',
    sortable: true,
    resizable: true,
    minWidth: 250,
    comparator: caseInsensitiveComparator(),
  },
  {
    headerName: 'Commission Note',
    field: 'commissionNote',
    sortable: true,
    resizable: true,
    minWidth: 250,
    comparator: caseInsensitiveComparator(),
  },
  {
    headerName: 'Plan Name',
    field: 'planName',
    sortable: true,
    resizable: true,
    minWidth: 250,
    comparator: caseInsensitiveComparator(),
  },
  {
    headerName: 'TCV',
    field: 'tcv',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Contract Term',
    field: 'contractTerm',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'rightAligned',
    aggFunc: 'sum',
  },
  {
    headerName: 'Net New ARR',
    field: 'netNewARR',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Adj to Net New ARR',
    field: 'adjToNetNewARR',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Adjusted Net New ARR',
    field: 'adjustedNetNewARR',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Net New BCR',
    field: 'netNewBCR',
    sortable: true,
    resizable: true,
    minWidth: 250,
    valueFormatter: ({ value }) => `${(value * 100).toFixed(2)}%`,
    type: 'rightAligned',
    aggFunc: 'sum',
  },
  {
    headerName: 'Net New Commission',
    field: 'netNewCommission',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Multi-Year ARR',
    field: 'multiYearARR',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Adj to Multi-Year ARR',
    field: 'adjToMultiYearARR',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Adjusted Multi-Year ARR',
    field: 'adjustedMultiYearARR',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Multi-Year BCR',
    field: 'multiYearBCR',
    sortable: true,
    resizable: true,
    minWidth: 250,
    valueFormatter: ({ value }) => `${(value * 100).toFixed(2)}%`,
    type: 'rightAligned',
    aggFunc: 'sum',
  },
  {
    headerName: 'Multi-Year Commission',
    field: 'multiYearCommission',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Non-ARR TCV',
    field: 'nonARRTCV',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Adj to Non-ARR',
    field: 'adjToNonARR',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Adjusted Non-ARR',
    field: 'adjustedNonARR',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Non-ARR BCR',
    field: 'nonARRBCR',
    sortable: true,
    resizable: true,
    minWidth: 250,
    valueFormatter: ({ value }) => `${(value * 100).toFixed(2)}%`,
    type: 'rightAligned',
    aggFunc: 'sum',
  },
  {
    headerName: 'Non-ARR Commission',
    field: 'nonARRCommission',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Renewal ARR',
    field: 'renewalARR',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Adj to Renewal ARR',
    field: 'adjToRenewalARR',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Adjusted Renewal ARR',
    field: 'adjustedRenewalARR',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Renewal ARR BCR',
    field: 'renewalARRBCR',
    sortable: true,
    resizable: true,
    minWidth: 250,
    valueFormatter: ({ value }) => `${(value * 100).toFixed(2)}%`,
    type: 'rightAligned',
  },
  {
    headerName: 'Renewal ARR Commission',
    field: 'renewalARRCommission',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'New Logo Bonus From Rep Email',
    field: 'newLogoBonusFromRepEmail',
    sortable: true,
    resizable: true,
    minWidth: 250,
    valueFormatter: ({ value }) => `${(value * 100).toFixed(2)}%`,
    type: 'rightAligned',
    aggFunc: 'sum',
  },
  {
    headerName: 'New Logo Commission',
    field: 'newLogoCommission',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Total Earned Commission',
    field: 'totalEarnedCommission',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Adj to Earned Commissions',
    field: 'adjToEarnedCommissions',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Calculated Payout',
    field: 'calculatedPayout',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Payout Adjustment',
    field: 'payoutAdj',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Outstanding Payout',
    field: 'outstandingPayout',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Quota Retirement',
    field: 'quotaRetirement',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Adj to Quota Retirement',
    field: 'adjToQuotaRetirement',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: 'Adj Quota Retirement',
    field: 'adjQuotaRetirement',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: '1st Accelerator Commission',
    field: 'n1stAcceleratorCommission',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: '2nd Accelerator Commission',
    field: 'n2ndAcceleratorCommission',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
  {
    headerName: '3rd Accelerator Commission',
    field: 'n3rdAcceleratorCommission',
    sortable: true,
    resizable: true,
    minWidth: 250,
    type: 'currency',
    aggFunc: 'sum',
  },
]);
