import React, {
  useEffect,
  useState,
} from 'react';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { SectionLabel } from 'components/common/SectionLabel';
import { LeftPane } from 'components/Layout';
import { ProgressTracker } from 'components/ProgresTracker';
import {
  AnimatedWrapper,
  StyledCaption,
} from './styled';
import { CompanyRevenueType } from 'interfaces/company';
import { OnboardingStep } from './OnboardingStep';
import {
  usePostDismissOnboardingMutation,
  useGetOnboardingQuery,
} from 'store/services/onboarding';
import { Button } from 'components/common/Button';
import { CloseIcon } from 'assets/icons';
import { Tabs } from 'components/common/Tabs';
import { useGetAuthMeQuery } from 'store/services/auth';
import { getCurrentCompany } from 'utils/currentCompany';
import { UserRole } from 'interfaces/auth';
import { Box } from 'components/common/Box';
import { ContractsSummary } from './ContractsSummary';
import { MetricsSummary } from './MetricsSummary';

interface Props {
  companyRevenueType?: CompanyRevenueType;
}

export const Home = ({ companyRevenueType }: Props) => {
  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false);
  const { data: user } = useGetAuthMeQuery();
  const currentCompany = getCurrentCompany(user);
  const [selectedTab, setSelectedTab] = useState('metrics');

  const {
    data: onboarding,
    isLoading,
  } = useGetOnboardingQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skip: !currentCompany,
  });

  const [dismissOnboarding] = usePostDismissOnboardingMutation();

  const percentCompleted = onboarding
    ? Math.floor(onboarding.steps.filter((step) => step.completed).length / onboarding.steps.length * 100)
    : 0;

  const isMetricsLoaded = companyRevenueType && !isLoading;

  const handleDismissSetup = () => {
    dismissOnboarding();
  };

  useEffect(() => {
    setIsOnboardingOpen(!onboarding?.dismissed);
  }, [onboarding?.dismissed]);

  return (
    <LeftPane contentWidth={1200}>
      <SectionLabel>
        <span>
          Welcome to Insights
        </span>
      </SectionLabel>
      <AnimatedWrapper visible={isOnboardingOpen && isMetricsLoaded && currentCompany?.role !== UserRole.VIEWER}>
        {isMetricsLoaded && (
          <Box>
            <ButtonsContainer paddingBottom={24}>
              <StyledCaption>Insights Setup Progress</StyledCaption>
              <Button
                aria-label="Close"
                variant="icon"
                color="secondary"
                size="large"
                onClick={handleDismissSetup}
                pushRight
              >
                <CloseIcon />
              </Button>
            </ButtonsContainer>
            <ProgressTracker
              progress={percentCompleted}
              large
            />
            {onboarding && onboarding.steps.map((step, index) => (
              <OnboardingStep
                {...step}
                key={step.name}
                index={index}
                companyRevenueType={companyRevenueType}
              />
            ))}
          </Box>
        )}
      </AnimatedWrapper>
      <Tabs
        tabs={[
          {
            name: 'Metrics Summary',
            value: 'metrics',
          },
          {
            name: 'Contracts Summary',
            value: 'contracts',
            hidden: currentCompany?.companyRevenueType === CompanyRevenueType.SUBSCRIPTIONS ||
              currentCompany?.role === UserRole.VIEWER,
          },
        ]}
        value={selectedTab}
        onChange={(selectedTab) => setSelectedTab(selectedTab.value)}
      />
      {selectedTab === 'contracts' && (
        <ContractsSummary />
      )}
      {selectedTab === 'metrics' && (
        <MetricsSummary currentCompany={currentCompany!} />
      )}
    </LeftPane>
  );
};
