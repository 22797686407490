import styled, { css } from "styled-components";
import { theme } from "theme/theme";

interface Props {
  inline?: boolean;
}

export const Deleted = styled.ins<Props>`
  ${props => !props.inline && css`
    display: flex;
    align-items: center;
    height: 14px;
  `}

  text-decoration: none;
  border: 1px solid ${theme.colors.borderColorDark} !important;
  border-radius: 2px;
  padding: 0px 3px;
  font-size: x-small;
  text-transform: uppercase;
  color: ${theme.colors.error};
  margin-left: 8px;
`;
