import React, {
  Dispatch,
  SetStateAction,
} from 'react';
import { useNavigate } from 'react-router';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CompanyFormFields,
  CompanyStatus,
  CompanyThirdStepFormFields,
} from 'interfaces/company';
import {
  StyledButtonContainer,
  StyledFormContainer,
} from 'components/common/Forms/styled';
import { Button } from 'components/common/Button';
import { useAddCompanyMutation } from 'store/services/companies';
import { ErrorCell } from 'components/common/ErrorCell';
import { toastSuccess } from 'utils/toast';
import {
  StyledRadioWrapper,
  StyledRadioInput,
  StyledLabel,
} from './styled';
import { SectionLabelPill } from 'components/common/SectionLabel';
import { clearApiState } from 'store/services/api';
import { useAppDispatch } from 'hooks/redux';
import { CheckboxInput } from 'components/common/CheckboxInput';
import { CompanyRevenueTypeOptions } from 'utils/constants';

interface Props {
  setStep: Dispatch<SetStateAction<number>>;
  setCompanyInfo: Dispatch<SetStateAction<CompanyFormFields>>;
  companyInfo: CompanyFormFields;
}

export const ThirdStepForm = ({
  setStep,
  setCompanyInfo,
  companyInfo,
}: Props) => {
  const dispatch = useAppDispatch();
  const [addCompany, { isLoading }] = useAddCompanyMutation();
  const navigate = useNavigate();
  const schema = yup.object({
    companyRevenueType: yup.string()
      .required('Company revenue type is required.'),
    useRevRec: yup.boolean(),
    populateSampleData: yup.boolean(),
  })
    .required();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<CompanyThirdStepFormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      companyRevenueType: companyInfo.companyRevenueType,
      useRevRec: companyInfo.useRevRec,
      populateSampleData: true,
    },
  });

  const handleBack = () => {
    const data = getValues();
    setCompanyInfo({
      ...companyInfo,
      companyRevenueType: data.companyRevenueType,
    });
    setStep(2);
  };

  const handleAddCompany = handleSubmit((data) => {
    addCompany({
      ...companyInfo,
      companyRevenueType: data.companyRevenueType,
      useRevRec: data.useRevRec,
      populateSampleData: data.populateSampleData,
      industry: companyInfo.industry?.at(0)?.value || null,
      businessType: companyInfo.businessType.at(0)?.value || null,
      businessStage: companyInfo.businessStage.at(0)?.value || null,
      tag: null,
      group: null,
      companyStatus: CompanyStatus.ACTIVE,
      currencyCode: companyInfo.currencyCode.at(0)?.code || 'USD',
    })
      .unwrap()
      .then(() => {
        toastSuccess('Company successfully created.');
        navigate('/');
        dispatch(clearApiState);
      });
  });

  return (
    <>
      <StyledFormContainer>
        <fieldset>
          {CompanyRevenueTypeOptions.map((option) => (
            <StyledRadioWrapper key={option.value}>
              <StyledRadioInput
                {...register('companyRevenueType')}
                id={option.value}
                type="radio"
                name="companyRevenueType"
                value={option.value}
              />
              <StyledLabel htmlFor={option.value}>
                <p>
                  {option.name}
                  {option.annotation && <SectionLabelPill>{option.annotation}</SectionLabelPill>}
                </p>
                {option.description}
              </StyledLabel>
            </StyledRadioWrapper>
          ))}
        </fieldset>
        <ErrorCell>{errors.companyRevenueType?.message}</ErrorCell>
        <CheckboxInput
          labelText="Revenue Recognition Module"
          {...register('useRevRec')}
          error={errors.useRevRec?.message}
          tooltip="Add the revenue recognition module to help you see the difference between your SaaS and actual Revenue."
          checkboxLabel="Include Revenue Recognition Module"
          defaultValue={getValues().useRevRec}
        />
        <CheckboxInput
          labelText="Sample Data"
          {...register('populateSampleData')}
          error={errors.populateSampleData?.message}
          tooltip="Sample data can be removed later manually."
          checkboxLabel="Populate this company with sample data"
          defaultValue={getValues().populateSampleData}
        />
        <StyledButtonContainer marginTop={36}>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={handleBack}
          >
            BACK
          </Button>
          <Button
            type="submit"
            onClick={handleAddCompany}
            disabled={isLoading}
            isLoading={isLoading}
          >
            FINISH
          </Button>
        </StyledButtonContainer>
      </StyledFormContainer>
    </>
  );
};

