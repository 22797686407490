import styled, { css } from "styled-components";
import { theme } from "theme/theme";

export const DetailBoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  gap: 40px;

  @media screen and (max-height: 800px) {
    margin-bottom: 20px;
  }
`;

export const StyledDetailBox = styled.div<{ flex?: boolean }>`
  ${props => props.flex
    ? css`
      flex: 1 0 200px;
      width: 324px;
      
      @media (max-width: 1600px) {
        width: 100px;
      }
    `
    : css`
      flex: 1 0 200px;
      max-width: 490px;
      @media (max-width: 1600px) {
        width: 100px;
      }
    `}
`;

export const StyledDetailBoxHeader = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    margin: 4px 0 16px 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }
`;

export const StyledDetailBoxContent = styled.div<{ isFocused?: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow-x: auto;
  border: 1px solid ${theme.colors.borderColorDark};
  border-radius: 8px;

  ${props => props.isFocused && css`
    border: 1px solid ${theme.colors.primary40};
    box-shadow: ${theme.boxShadowHighlight};
  `}
`;

export const StyledDetailBoxEntry = styled.div`
  margin: 16px;
  min-width: 130px;
  flex-shrink: 0;

  p {
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
  }

  p + p {
    font-weight: 500;
    margin-top: 8px;
  }
`;
