import styled from "styled-components";

export const StyledForm = styled.form`
  max-width: 1200px;
`;

export const StyledSetting = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  min-height: 48px;
`;

export const StyledProperty = styled.div`
  display: grid;
  grid-template-columns: 300px auto;
  justify-content: space-between;
  align-items: center;

  h4 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
`;
