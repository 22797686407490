import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="inherit"
      d="M9 3 2 21h2l2.334-6h7.332L16 21h2L11 3H9Zm9 0v2h-2v2h2v2h2V7h2V5h-2V3h-2Zm-8 2.572L12.889 13H7.11L10 5.572Z"
    />
  </svg>
);

export default SvgComponent;
