import styled from "styled-components";

export const DetailsContainer = styled.div`
  display: flex;
  align-items: top;
  gap: 20px 40px;
  padding-bottom: 40px;
  flex-wrap: wrap;
  
  > div {
    min-width: 100px;
  }

  @media screen and (max-height: 800px) {
    padding-bottom: 20px;
  }
`;
