import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as yup from "yup";
import {
  useForm,
  Controller,
} from 'react-hook-form';
import {
  BlockIcon,
  ChevronDown,
  CloseIcon,
  CopyIcon,
  DeleteIcon,
  LinkIcon,
  ReloadIcon,
  UnlinkIcon,
} from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import {
  StyledButtonContainer,
  StyledFormSectionLabel,
  StyledFormContainer,
  StyledAdditionalOptionsContainer,
  StyledExpandButton,
  StyledCheckboxContainer,
} from 'components/common/Forms/styled';
import { copyToClipboard } from 'utils/copyToClipboard';
import { Button } from 'components/common/Button';
import {
  Subscription,
  SubscriptionFormFields,
  SubscriptionStateFormFields,
} from 'interfaces/subscriptions';
import { Tooltip } from 'components/common/Tooltip';
import { yupResolver } from '@hookform/resolvers/yup';
import { Datepicker } from 'components/common/Datepicker';
import { Dropdown } from 'components/common/Dropdown';
import { InputBasic } from 'components/common/Input';
import { NumberInput } from 'components/common/NumberInput';
import { AssistiveText } from 'components/common/Input/AssistiveText';
import { useGetAllProductsQuery } from 'store/services/products';
import {
  formatDateToISO,
  isoToDate,
} from 'utils/dates';
import {
  useBulkEditSubscriptionsMutation,
  useLazySubscriptionUniquenessQuery,
  useUpdateSubscriptionMutation,
} from 'store/services/subscriptions';
import { useGetAllTagsQuery } from 'store/services/tags';
import {
  parseStateFormData,
  parseSubscriptionFormData,
  prepareSubscriptionPayload,
} from 'utils/subscriptions';
import { toastSuccess } from 'utils/toast';
import { bulkEditSubscriptionsSchema } from './yupSchema';
import { actionBlocker } from 'utils/actionBlocker';
import { TagType } from 'interfaces/tags';
import { formStateActions } from 'store/slices/formState';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import { formDataSelector } from 'store/selectors/formState';
import {
  getTagsIdsForSelectedRows,
  tagsSortFn,
} from 'utils/tags';
import { Customer } from 'interfaces/customers';
import { useUniquenessCheck } from 'hooks/useUniquenessCheck';
import { CustomersDropdownLazy } from 'components/common/CustomersDropdownLazy';
import { Checkbox } from 'components/common/Checkbox';
import {
  StyledHistory,
  StyledHistoryEntry,
} from 'pages/Contracts/EditContracts/styled';
import { subscriptionsActions } from 'store/slices/subscriptions';

interface Props {
  onClose: () => void;
  onSuccess?: (id?: string) => void;
  onSave: (id: string) => void;
  onDelete: () => void;
  onCancelSubscriptions: () => void;
  onRenewSubscription: () => void;
  selectedRows: Subscription[];
  onAddTag: () => void;
  onAddCustomer: () => void;
  onAddProduct: () => void;
  onLinkSubscription: () => void;
  onUnlinkSubscription: () => void;
}

export const EditSubscriptions = ({
  onClose,
  onSuccess,
  onSave,
  onDelete,
  onAddTag,
  onAddCustomer,
  onAddProduct,
  onCancelSubscriptions,
  onRenewSubscription,
  selectedRows,
  onLinkSubscription,
  onUnlinkSubscription,
}: Props) => {
  const dispatch = useAppDispatch();
  const formData = useAppSelector(formDataSelector);
  const isBulkEdit = selectedRows.length > 1;
  const [isOptionsExpanded, setIsOptionsExpanded] = useState(false);
  const [isClearExistingTags, setIsClearExistingTags] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Partial<Customer> | undefined>(selectedRows.at(0)?.customer);
  const [updateSubscription, { isLoading }] = useUpdateSubscriptionMutation();
  const [subscriptionUnique, { isFetching: isCheckingUniqueness }] = useLazySubscriptionUniquenessQuery();
  const [bulkEditSubscriptions, { isLoading: isLoadingBulkEdit }] = useBulkEditSubscriptionsMutation();

  const isSubscriptionRenewable = !isBulkEdit
    && !selectedRows[0]?.next?.name
    && !selectedRows[0]?.product.deleted
    && !selectedRows[0]?.customer.deleted;

  const isSubscriptionCancellable = selectedRows.every((row) => !row.cancelDate);
  const selectedRowsProductsIds = selectedRows.map((sub) => sub.product.id);

  const {
    products,
  } = useGetAllProductsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      products: data
        ?.filter((prod) => !prod.deleted || selectedRowsProductsIds.includes(prod.id)),
    }),
  });

  const selectedRowsTagsIds = useMemo(() => getTagsIdsForSelectedRows(selectedRows), [selectedRows]);

  const {
    tagOptions,
  } = useGetAllTagsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      tagOptions: data
        ?.filter((tag) => tag.type === TagType.CONTRACT && (!tag.deleted || selectedRowsTagsIds.includes(tag.id)))
        ?.sort(tagsSortFn) || [],
    }),
  });

  const defaultValues = useMemo(() => ({
    ...(formData
      ? {
        ...parseStateFormData(formData as SubscriptionStateFormFields),
      }
      : {
        customer: [],
        product: [],
        name: '',
        amount: '',
        startDate: undefined,
        endDate: undefined,
        cancelDate: undefined,
        bookingDate: undefined,
        crmId: '',
        externalLink: '',
        notes: '',
        tags: [],
      }
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  const editSubscriptionsSchema = yup.object({
    id: yup.string(),
    customer: yup.array()
      .of(
        yup.object({
          id: yup.string(),
          name: yup.string(),
        }))
      .min(1, 'Customer is required.'),
    product: yup.array()
      .of(
        yup.object({
          id: yup.string(),
          name: yup.string(),
        }))
      .min(1, 'Product is required.'),
    name: yup.string()
      .required('Description is required.')
      .test(
        'isUnique',
        'Description must be unique within the customer.',
        () => isNameUnique,
      ),
    amount: yup.string()
      .required('Amount is required.'),
    startDate: yup.date()
      .nullable()
      .required('Start date is required.'),
    endDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'End date cannot be before start date.'),
      })
      .required('End date is required.'),
    bookingDate: yup.date()
      .when('endDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .max(yup.ref('endDate'),
            'Booking date cannot be after end date.'),
      })
      .nullable(),
    cancelDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'Cancel date cannot be before start date.'),
      }),
    revRecStartDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'Revenue recognition start date cannot be before start date.'),
      }),
    revRecEndDate: yup.date()
      .nullable()
      .when('revRecStartDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('revRecStartDate'),
            'Revenue recognition end date cannot be before Revenue recognition start date.')
          .required('When Revenue recognition start date provided this field is required.'),
      }),
    crmId: yup.string(),
    externalLink: yup.string()
      .url('Please provide valid URL.'),
    notes: yup.string(),
  })
    .required();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors, isDirty, dirtyFields },
    reset,
    trigger,
  } = useForm<SubscriptionFormFields>({
    resolver: yupResolver(isBulkEdit
      ? bulkEditSubscriptionsSchema
      : editSubscriptionsSchema),
    mode: 'onChange',
    defaultValues,
  });

  const triggerTouched = (field: any) => ((dirtyFields as Record<string, boolean | undefined>)[field]) && trigger(field);

  const [isNameUnique, setName] = useUniquenessCheck(subscriptionUnique, { customerID: selectedCustomer?.id }, 'name', trigger, [selectedRows.at(0)?.name]);

  const handleClickRenew = onRenewSubscription;
  const handleClickCancel = onCancelSubscriptions;
  const handleClickDelete = onDelete;

  const handleSaveAndClose = handleSubmit((data) => {
    const subscription = prepareSubscriptionPayload(data);

    return updateSubscription(subscription)
      .unwrap()
      .then((result) => {
        toastSuccess('Revenue successfully updated.');
        onSuccess && onSuccess(result.id);
        onClose();
      });
  });

  const handleSave = handleSubmit((data) => {
    const subscription = prepareSubscriptionPayload(data);

    return updateSubscription(subscription)
      .unwrap()
      .then((result) => {
        onSuccess && onSuccess(result.id);
        toastSuccess('Revenue successfully updated.');
        onSave(result.id);
      });
  });

  const prepareBulkEditPayload = (data: SubscriptionFormFields) => {
    const subscriptionsIds = selectedRows.map((row) => row.id);
    const changedTags = data.tags.length ? data.tags.map((tag) => tag.id) : null;

    return {
      subscriptionsIds,
      customerId: data.customer?.at(0)?.id || null,
      productId: data.product?.at(0)?.id || null,
      startDate: data.startDate ? formatDateToISO(data.startDate) : null,
      endDate: data.endDate ? formatDateToISO(data.endDate) : null,
      bookingDate: data.bookingDate ? formatDateToISO(data.bookingDate) : null,
      cancelDate: data.cancelDate ? formatDateToISO(data.cancelDate) : null,
      revRecStartDate: data.revRecStartDate ? formatDateToISO(data.revRecStartDate) : null,
      revRecEndDate: data.revRecEndDate ? formatDateToISO(data.revRecEndDate) : null,
      amount: Number(data.amount) || null,
      crmId: data.crmId || null,
      tagIds: isClearExistingTags ? [] : changedTags,
      externalLink: data.externalLink || null,
      notes: data.notes || null,
    };
  };

  const handleBulkSave = handleSubmit((data) => {
    const subscriptions = prepareBulkEditPayload(data);

    return bulkEditSubscriptions(subscriptions)
      .unwrap()
      .then(() => {
        toastSuccess('Revenues successfully updated.');
      });
  });

  const handleBulkSaveAndClose = handleSubmit((data) => {
    const subscriptions = prepareBulkEditPayload(data);

    return bulkEditSubscriptions(subscriptions)
      .unwrap()
      .then(() => {
        toastSuccess('Revenues successfully updated.');
        onClose();
      });
  });

  const handleAddTag = ({ name }: { name: string }) => {
    dispatch(formStateActions.setPredefinedData({
      name,
      type: TagType.CONTRACT,
    }));
    dispatch(formStateActions.setFormData(parseSubscriptionFormData(getValues())));
    onAddTag();
  };

  const handleAddCustomer = ({ name }: { name: string }) => {
    if (onAddCustomer) {
      dispatch(formStateActions.setPredefinedData({ name }));
      dispatch(formStateActions.setFormData(parseSubscriptionFormData(getValues())));
      onAddCustomer();
    }
  };

  const handleClearExistingTags = () => {
    setIsClearExistingTags(!isClearExistingTags);
    setValue('tags', []);
  };

  const handleAddProduct = ({ name }: { name: string }) => {
    if (onAddProduct) {
      dispatch(formStateActions.setPredefinedData({ name }));
      dispatch(formStateActions.setFormData(parseSubscriptionFormData(getValues())));
      onAddProduct();
    }
  };

  const handleUnlinkSubscription = (type: 'next' | 'prev') => {
    dispatch(subscriptionsActions.setUnlinkType(type));
    onUnlinkSubscription();
  };

  useEffect(() => {
    const selectedRow = selectedRows[0];

    if (isBulkEdit) {
      reset(defaultValues);
    } else if (selectedRow && !formData) {
      reset({
        id: selectedRow.id,
        customer: [selectedRow.customer],
        product: [selectedRow.product],
        name: selectedRow.name,
        amount: `${selectedRow.amount}`,
        startDate: isoToDate(selectedRow.startDate),
        endDate: isoToDate(selectedRow.endDate),
        cancelDate: isoToDate(selectedRow.cancelDate),
        bookingDate: isoToDate(selectedRow.bookingDate),
        revRecStartDate: isoToDate(selectedRow.revRecStartDate),
        revRecEndDate: isoToDate(selectedRow.revRecEndDate),
        crmId: selectedRow.crmId || '',
        externalLink: selectedRow.externalLink || '',
        notes: selectedRow.notes || '',
        tags: selectedRow.tags,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows, reset, isBulkEdit]);

  useEffect(() => {
    triggerTouched('name');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer]);

  return (
    <>
      <header>
        <ButtonsContainer>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={() => actionBlocker(onClose, isDirty)}
            pushRight
            data-cy="es-button-close"
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <StyledFormSectionLabel data-cy="es-header">
          <span>
            {isBulkEdit ? 'Bulk Edit' : 'Edit Revenue'}
          </span>
          {isBulkEdit
            ? (
              <span>{selectedRows.length} Revenues</span>
            )
            : (
              <ButtonsContainer>
                <Button
                  variant="simple"
                  size="large"
                  type="button"
                  onClick={(e) => copyToClipboard(e, selectedRows[0].id)}
                  data-cy="es-button-id"
                >
                  <span>Insights Revenue ID</span>
                  <CopyIcon />
                </Button>
              </ButtonsContainer>
            )}
        </StyledFormSectionLabel>
        <ButtonsContainer paddingBottom={20}>
          <Tooltip
            title={isBulkEdit ? 'Bulk renew not possible' :
              selectedRows[0]?.next?.name
                ? 'This revenue has already been renewed'
                : isSubscriptionRenewable
                  ? 'Renew'
                  : 'Cannot renew if product or customer has been deleted'}
          >
            <Button
              variant="icon"
              color="primary"
              size="large"
              onClick={handleClickRenew}
              disabled={!isSubscriptionRenewable}
              aria-label="Renew"
              data-cy="es-button-renew"
            >
              <ReloadIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Link Revenue">
            <Button
              variant="icon"
              color="primary"
              size="large"
              onClick={onLinkSubscription}
              aria-label="Link"
              data-cy="eco-button-link"
              disabled={selectedRows.length > 1}
            >
              <LinkIcon />
            </Button>
          </Tooltip>
          <Tooltip
            title={isBulkEdit
              ? isSubscriptionCancellable
                ? 'Cancel Revenues'
                : 'At least one of selected revenues is already canceled'
              : isSubscriptionCancellable
                ? 'Cancel Revenue'
                : 'This revenue has already been canceled'}
          >
            <Button
              variant="icon"
              color="primary"
              size="large"
              onClick={handleClickCancel}
              disabled={!isSubscriptionCancellable}
              aria-label="Cancel"
              data-cy="es-button-cancel"
            >
              <BlockIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              variant="icon"
              color="primary"
              size="large"
              onClick={handleClickDelete}
              aria-label="Delete"
              data-cy="es-button-delete"
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        </ButtonsContainer>
      </header>
      <main>
        <StyledFormContainer>
          {!isBulkEdit && (
            <>
              {selectedRows[0]?.previous?.name && (
                <StyledHistory>
                  <section>Previous Revenue</section>
                  <StyledHistoryEntry key={`hist_${selectedRows[0].previous?.id}`}>
                    <span>{selectedRows[0]?.previous?.name}</span>
                    <Tooltip title="Unlink this revenue">
                      <Button
                        variant="icon"
                        color="primary"
                        size="normal"
                        onClick={() => handleUnlinkSubscription('prev')}
                      >
                        <UnlinkIcon />
                      </Button>
                    </Tooltip>
                  </StyledHistoryEntry>
                </StyledHistory>
              )}
              {selectedRows[0]?.next?.name && (
                <StyledHistory>
                  <section>Next Revenue</section>
                  <StyledHistoryEntry key={`hist_${selectedRows[0].next?.id}`}>
                    <span>{selectedRows[0].next.name}</span>
                    <Tooltip title="Unlink this revenue">
                      <Button
                        variant="icon"
                        color="primary"
                        size="normal"
                        onClick={() => handleUnlinkSubscription('next')}
                      >
                        <UnlinkIcon />
                      </Button>
                    </Tooltip>
                  </StyledHistoryEntry>
                </StyledHistory>
              )}
              <Controller
                name="customer"
                control={control}
                render={({ field }) =>
                  <CustomersDropdownLazy
                    labelText="Customer"
                    labelField="name"
                    valueField="id"
                    searchBy="name"
                    placeholder="Select Customer"
                    values={getValues('customer')}
                    {...field}
                    error={errors.customer?.message}
                    dataCy="es-dropdown-customer"
                    onChange={(customer) => {
                      setSelectedCustomer(customer.at(0));
                      field.onChange(customer);
                    }}
                    create
                    onCreateNew={handleAddCustomer}
                    rowSelectedOption={selectedRows[0]?.customer}
                  />
                }
              />
              <Controller
                name="product"
                control={control}
                render={({ field }) =>
                  <div>
                    <Dropdown
                      labelText="Product"
                      labelField="name"
                      valueField="id"
                      searchBy="name"
                      placeholder="Select Product SKU"
                      options={products || []}
                      values={getValues('product')}
                      {...field}
                      error={errors.product?.message}
                      dataCy="es-dropdown-product"
                      create
                      onCreateNew={handleAddProduct}
                    />
                    <AssistiveText text={getValues('product')[0]?.revenueType} />
                  </div>
                }
              />
              <InputBasic
                labelText="Description"
                placeholder="Describe your revenue"
                {...register('name')}
                onBlur={(e) => {
                  const fieldValue = e.target.value;

                  if (fieldValue) {
                    setValue('name', fieldValue.trim(), { shouldValidate: true });
                  }
                }}
                onCustomChange={(e) => setName(e.target.value)}
                error={errors.name?.message}
                data-cy="es-input-name"
                isLoading={isCheckingUniqueness}
              />
              <Controller
                name="amount"
                control={control}
                defaultValue={''}
                render={({ field }) =>
                  <NumberInput
                    labelText="Amount"
                    {...field}
                    onChange={field.onChange}
                    placeholder="Enter Amount"
                    error={errors.amount?.message}
                    data-cy="es-input-amount"
                  />
                }
              />
              <Controller
                name="startDate"
                control={control}
                defaultValue={undefined}
                render={({ field }) =>
                  <Datepicker
                    labelText="Start Date"
                    placeholder="Select Date"
                    {...field}
                    onChange={(date) => {
                      field.onChange(date);
                      triggerTouched('bookingDate');
                      triggerTouched('endDate');
                      triggerTouched('cancelDate');
                    }}
                    error={errors.startDate?.message}
                    data-cy="es-datepicker-start"
                  />
                }
              />
              <Controller
                name="bookingDate"
                control={control}
                defaultValue={undefined}
                render={({ field }) =>
                  <Datepicker
                    labelText="Different Booking Date"
                    placeholder="Select Date"
                    {...field}
                    onChange={(date) => {
                      field.onChange(date);
                      triggerTouched('startDate');
                      triggerTouched('endDate');
                      triggerTouched('cancelDate');
                    }}
                    error={errors.bookingDate?.message}
                    data-cy="es-datepicker-booking"
                  />
                }
              />
              <Controller
                name="endDate"
                control={control}
                defaultValue={undefined}
                render={({ field }) =>
                  <Datepicker
                    labelText="End Date"
                    placeholder="Select Date"
                    {...field}
                    onChange={(date) => {
                      field.onChange(date);
                      triggerTouched('startDate');
                      triggerTouched('bookingDate');
                      triggerTouched('cancelDate');
                    }}
                    error={errors.endDate?.message}
                    data-cy="es-datepicker-end"
                  />
                }
              />
              <Controller
                name="cancelDate"
                control={control}
                defaultValue={undefined}
                render={({ field }) =>
                  <Datepicker
                    labelText="Cancel Date"
                    placeholder="Cancel Date"
                    {...field}
                    onChange={(date) => {
                      field.onChange(date);
                      triggerTouched('startDate');
                      triggerTouched('bookingDate');
                      triggerTouched('endDate');
                    }}
                    error={errors.cancelDate?.message}
                    data-cy="es-datepicker-cancel"
                  />
                }
              />
              <StyledFormSectionLabel>
                <span>
                  Revenue Recognition
                </span>
              </StyledFormSectionLabel>
              <Controller
                name="revRecStartDate"
                control={control}
                defaultValue={undefined}
                render={({ field }) =>
                  <Datepicker
                    labelText="Recognition Start Date"
                    placeholder="Select Date"
                    {...field}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    error={errors.revRecStartDate?.message}
                    isOptional
                  />
                }
              />
              <Controller
                name="revRecEndDate"
                control={control}
                defaultValue={undefined}
                render={({ field }) =>
                  <Datepicker
                    labelText="Recognition End Date"
                    placeholder="Select Date"
                    {...field}
                    onChange={(date) => {
                      field.onChange(date);
                      triggerTouched('revRecEndDate');
                    }}
                    error={errors.revRecEndDate?.message}
                    isOptional
                  />
                }
              />
              <StyledExpandButton
                variant="borderless"
                size="large"
                type="button"
                onClick={() => setIsOptionsExpanded(!isOptionsExpanded)}
                isOpen={isOptionsExpanded}
                data-cy="es-button-additional"
              >
                Additional Options
                <ChevronDown />
              </StyledExpandButton>
              <StyledAdditionalOptionsContainer isOpen={isOptionsExpanded}>
                <Controller
                  name="tags"
                  control={control}
                  render={({ field }) =>
                    <Dropdown
                      labelText="Tags"
                      labelField="name"
                      valueField="id"
                      searchBy="name"
                      options={tagOptions}
                      placeholder="Select Tags"
                      entityName="tag"
                      values={getValues('tags')}
                      {...field}
                      isOptional
                      clearable
                      multi
                      error={errors.tags?.message}
                      tooltip="Categorize this revenue with a revenue tag(s)."
                      create
                      onCreateNew={handleAddTag}
                      data-cy="es-dropdown-tags"
                    />
                  }
                />
                <InputBasic
                  isOptional
                  labelText="CRM ID"
                  placeholder="Paste CRM identifier"
                  tooltip="Provide a reference ID from your CRM or any place where you track your revenue."
                  {...register('crmId')}
                  error={errors.crmId?.message}
                  data-cy="es-input-crm"
                />
                <InputBasic
                  isOptional
                  labelText="External Link"
                  placeholder="Paste an external link"
                  {...register('externalLink')}
                  error={errors.externalLink?.message}
                  onBlur={(e) => {
                    const fieldValue = e.target.value;

                    if (fieldValue && !fieldValue.startsWith('http')) {
                      setValue('externalLink', 'http://' + fieldValue, { shouldValidate: true });
                    }
                  }}
                  data-cy="es-input-external"
                />
                <InputBasic
                  isOptional
                  labelText="Notes"
                  placeholder="Add a quick note"
                  {...register('notes')}
                  error={errors.notes?.message}
                  data-cy="es-input-notes"
                  multiline
                />
              </StyledAdditionalOptionsContainer>
            </>
          )}
          {isBulkEdit && (
            <div>
              <Controller
                name="tags"
                control={control}
                render={({ field }) =>
                  <Dropdown
                    labelText="Tags"
                    labelField="name"
                    valueField="id"
                    searchBy="name"
                    options={tagOptions}
                    placeholder="Select Tags"
                    entityName="tag"
                    values={getValues('tags')}
                    {...field}
                    isOptional
                    clearable
                    multi
                    error={errors.tags?.message}
                    tooltip="Categorize this revenue with a revenue tag(s)."
                    create
                    onCreateNew={handleAddTag}
                    data-cy="es-dropdown-tags"
                    disabled={isClearExistingTags}
                  />
                }
              />
              {isBulkEdit && (
                <StyledCheckboxContainer>
                  <Checkbox
                    name="clearExistingTags"
                    checked={isClearExistingTags}
                    onChange={handleClearExistingTags}
                  >
                    Clear existing Tags
                  </Checkbox>
                </StyledCheckboxContainer>
              )}
            </div>
          )}
        </StyledFormContainer>
      </main>
      <footer>
        <StyledButtonContainer pushRight>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={isBulkEdit ? handleBulkSave : handleSave}
            disabled={isLoadingBulkEdit || isLoading || isCheckingUniqueness}
            isLoading={isLoadingBulkEdit || isLoading}
            data-cy="es-button-save"
          >
            SAVE
          </Button>
          <Button
            type="submit"
            onClick={isBulkEdit ? handleBulkSaveAndClose : handleSaveAndClose}
            disabled={isLoadingBulkEdit || isLoading || isCheckingUniqueness}
            isLoading={isLoadingBulkEdit || isLoading}
            data-cy="es-button-save-close"
          >
            SAVE & CLOSE
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};