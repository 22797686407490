import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button } from '../Button';
import {
  CloseIcon,
  SearchIcon,
} from 'assets/icons';
import {
  StyledCloseButton,
  StyledQuickFilter,
  StyledSearchInputWrapper,
} from './styled';
import { Tooltip } from '../Tooltip';

interface Props {
  handleSearchChange: (search: string) => void;
  pushRight?: boolean;
  inline?: boolean;
}

export const QuickSearch = ({ handleSearchChange, pushRight, inline }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [innerState, setInnerState] = useState('');
  const searchRef = useRef<HTMLInputElement>(null);

  const handleClickOpen = useCallback(() => {
    setIsOpen(!isOpen);
    if (isOpen) setInnerState('');
    searchRef?.current?.focus();
  }, [isOpen]);

  const handleClickClose = () => {
    setIsOpen(false);
    setInnerState('');
  };

  useEffect(() => {
    handleSearchChange(innerState);
  }, [handleSearchChange, innerState]);


  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.metaKey && e.key === 'f') {
        e.preventDefault();
        handleClickOpen();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleClickOpen]);

  return (
    <StyledQuickFilter
      inline={inline}
      pushRight={pushRight}
    >
      <Tooltip title="Search">
        <Button
          variant="icon"
          color={inline ? 'primary' : 'secondary'}
          size={inline ? 'large' : 'normal'}
          aria-label="Search by"
          onClick={handleClickOpen}
        >
          <SearchIcon />
        </Button>
      </Tooltip>
      <StyledSearchInputWrapper
        isOpen={isOpen}
        inline={inline}
      >
        <input
          ref={searchRef}
          value={innerState}
          onChange={(ev) => setInnerState(ev.currentTarget.value)}
          onKeyDown={(ev) => {
            if (ev.key === 'Escape') {
              handleClickClose();
            }
          }}
        />
        <StyledCloseButton
          variant="icon"
          aria-label="Close search"
          onClick={handleClickClose}
          pushRight
        >
          <CloseIcon />
        </StyledCloseButton>
      </StyledSearchInputWrapper>
    </StyledQuickFilter>
  );
};
