import React, {
  useEffect,
  useMemo,
} from 'react';
import { AppRouter } from 'pages/AppRouter';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { captureMessage } from '@sentry/react';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-material.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { GridChartsModule } from '@ag-grid-enterprise/charts';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ServerSideRowModelModule,
  RangeSelectionModule,
  GridChartsModule,
  StatusBarModule,
  RowGroupingModule,
  RichSelectModule,
  CsvExportModule,
  SetFilterModule,
  MenuModule,
  ClipboardModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  MultiFilterModule,
]);

const App = () => {
  const { route } = useAuthenticator((context) => [context.route]);

  const HANDLED_COGNITO_ROUTES = useMemo(() => [
    'idle',
    'setup',
    'authenticated',
    'signOut',
  ], []);

  useEffect(() => {
    if (!HANDLED_COGNITO_ROUTES.includes(route)) {
      captureMessage(`unhandled route state: ${route}`, 'warning');
    }

    if (route === 'setup') {
      window.location.href = `${import.meta.env.VITE_APP_AUTH_APP_URL}${window.location.origin}`;
    }
  }, [HANDLED_COGNITO_ROUTES, route]);

  useEffect(() => {
    // @ts-ignore
    window.tempApiUrl = new URLSearchParams(window.location.search)
      ?.getAll('api')
      ?.at(0) || undefined;
  }, []);

  return route === 'authenticated' ? <AppRouter /> : null;
};

export default App;
