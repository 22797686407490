import styled from "styled-components";

export const TableWithNotes = styled.div`
  display: flex;
  flex-grow: 1;

  aside {
    margin-left: 30px;
    padding: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    width: 300px;
    flex-shrink: 0;
  }
`;
