import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Button } from 'components/common/Button';
import {
  StyledButtonContainer,
  StyledFormContainer,
} from 'components/common/Forms/styled';
import { ReportLine } from 'interfaces/reports';
import { InputBasic } from "components/common/Input";
import { CloseIcon } from "assets/icons";
import {
  ButtonsContainer,
  Spacer,
} from "components/common/ButtonsContainer";
import { SectionLabel } from "components/common/SectionLabel";
import { useAddLineMutation } from "store/services/reports";
import { toastSuccess } from "utils/toast";

interface Props {
  reportId: string;
  onClose: () => void;
  onSuccess?: (result: ReportLine[]) => void;
}

export const AddMultipleLines = ({
  reportId,
  onClose,
  onSuccess,
}: Props) => {
  const [addLines, { isLoading: isAdding }] = useAddLineMutation();

  const schema = yup.object({
    lines: yup.number()
      .min(1, 'Minimum 1 line required.')
      .max(1000, 'Maximum 1000 lines allowed.')
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .required('Lines number is required.'),
  });

  const {
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm<{ lines: number }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      lines: 10,
    },
  });

  const handleSaveAndClose = handleSubmit((data) => {
    const payload = {
      reportId,
      quantity: data.lines,
    };

    return addLines(payload)
      .unwrap()
      .then((result) => {
        toastSuccess('Lines successfully added.');
        onClose();
        onSuccess && onSuccess(result);
      });
  });

  return (
    <>
      <ButtonsContainer alignTop>
        <SectionLabel marginBottom={24}>
          Add Multiple Report Lines
        </SectionLabel>
        <Spacer />
        <Button
          aria-label="Close"
          variant="icon"
          size="large"
          onClick={onClose}
        >
          <CloseIcon />
        </Button>
      </ButtonsContainer>
      <StyledFormContainer onSubmit={handleSaveAndClose}>
        <InputBasic
          {...register('lines')}
          labelText="Lines number"
          type="number"
          max={1000}
          min={1}
          error={errors.lines?.message}
        />
      </StyledFormContainer>
      <StyledButtonContainer
        pushRight
        marginTop={40}
      >
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          onClick={onClose}
          disabled={isAdding}
        >
          CANCEL
        </Button>
        <Button
          type="button"
          onClick={handleSaveAndClose}
          disabled={isAdding}
          isLoading={isAdding}
        >
          ADD {watch('lines')} LINES
        </Button>
      </StyledButtonContainer>
    </>
  );
};
