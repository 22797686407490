export enum FinancialType {
  PROFIT_AND_LOSS = 'profit_and_loss',
  BALANCE_SHEET = 'balance_sheet',
}

export interface FinancialsItem {
  /** also known as: Heading, Facta Account */
  account: string;
  /** also known as: Category */
  accountType: string;
  entries: Array<{
    date: string;
    value: unknown;
  }>;
  glAccount: string;
}

export type FinancialsUpdatePayload = {
  financialType: FinancialType;
  financials: FinancialsItem[];
}
