import React, {
  useEffect,
  useState,
} from 'react';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  ChevronDown,
  CloseIcon,
} from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { SectionLabel } from 'components/common/SectionLabel';
import { Button } from 'components/common/Button';
import { InputBasic } from 'components/common/Input';
import { Dropdown } from 'components/common/Dropdown';
import {
  StyledFormContainer,
  StyledButtonContainer,
  StyledExpandButton,
  StyledAdditionalOptionsContainer,
  StyledFormSectionLabel,
} from 'components/common/Forms/styled';
import {
  useAddSubscriptionMutation,
  useLazySubscriptionUniquenessQuery,
} from 'store/services/subscriptions';
import { useGetAllTagsQuery } from 'store/services/tags';
import { NumberInput } from 'components/common/NumberInput';
import { Datepicker } from 'components/common/Datepicker';
import {
  SubscriptionFormFields,
  SubscriptionStateFormFields,
} from 'interfaces/subscriptions';
import { toastSuccess } from 'utils/toast';
import {
  parseStateFormData,
  parseSubscriptionFormData,
  prepareSubscriptionPayload,
} from 'utils/subscriptions';
import { useGetAllProductsQuery } from 'store/services/products';
import { AssistiveText } from 'components/common/Input/AssistiveText';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import { actionBlocker } from 'utils/actionBlocker';
import { TagType } from 'interfaces/tags';
import { formDataSelector } from 'store/selectors/formState';
import { formStateActions } from 'store/slices/formState';
import { tagsSortFn } from 'utils/tags';
import { Customer } from 'interfaces/customers';
import { useUniquenessCheck } from 'hooks/useUniquenessCheck';
import { CustomersDropdownLazy } from 'components/common/CustomersDropdownLazy';
import {
  addDays,
  addMonths,
} from 'date-fns';
import { formatDateToISO } from 'utils/dates';

interface Props {
  onClose: () => void;
  onSuccess?: (id?: string) => void;
  onAddTag: () => void;
  onAddCustomer: () => void;
  onAddProduct: () => void;
  revenueType: string;
  entityName: string;
}

export const AddSubscription = ({
  onClose,
  onSuccess,
  onAddTag,
  onAddCustomer,
  onAddProduct,
  revenueType,
  entityName,
}: Props) => {
  const dispatch = useAppDispatch();
  const formData = useAppSelector(formDataSelector);
  const [isOptionsExpanded, setIsOptionsExpanded] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Partial<Customer> | null | undefined>(null);
  const [addSubscription, { isLoading }] = useAddSubscriptionMutation();
  const [subscriptionUnique, { isFetching: isCheckingUniqueness }] = useLazySubscriptionUniquenessQuery();

  const {
    products,
  } = useGetAllProductsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      products: data
        ?.filter((product) => !product.deleted)
        ?.filter((product) => revenueType === "all" || product.revenueType === revenueType),
    }),
  });

  const {
    tagOptions,
  } = useGetAllTagsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      tagOptions: data
        ?.filter((tag) => !tag.deleted && tag.type === TagType.CONTRACT)
        ?.sort(tagsSortFn) || [],
    }),
  });

  const schema = yup.object({
    customer: yup.array()
      .of(
        yup.object({
          id: yup.string(),
          name: yup.string(),
        }))
      .min(1, 'Customer is required.'),
    product: yup.array()
      .of(
        yup.object({
          id: yup.string(),
          name: yup.string(),
        }))
      .min(1, 'Product is required.'),
    name: yup.string()
      .required('Description is required.')
      .test(
        'isUnique',
        'Description must be unique within the customer.',
        () => isNameUnique,
      ),
    amount: yup.string()
      .required('Amount is required.'),
    startDate: yup.date()
      .nullable()
      .required('Start date is required.'),
    endDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'End date cannot be before start date.'),
      })
      .required('End date is required.'),
    bookingDate: yup.date()
      .when('endDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .max(yup.ref('endDate'),
            'Booking date cannot be after end date.'),
      })
      .nullable(),
    cancelDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'Cancel date cannot be before start date.'),
      }),
    revRecStartDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'Revenue recognition start date cannot be before start date.'),
      }),
    revRecEndDate: yup.date()
      .nullable()
      .when('revRecStartDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('revRecStartDate'),
            'Revenue recognition end date cannot be before Revenue recognition start date.')
          .required('When Revenue recognition start date provided this field is required.'),
      }),
    crmId: yup.string(),
    externalLink: yup.string()
      .url('Please provide valid URL.'),
    notes: yup.string(),
  })
    .required();

  const defaultValues = {
    ...(formData
      ? {
        ...parseStateFormData(formData as SubscriptionStateFormFields),
      }
      : {
        customer: [],
        product: [],
        name: '',
        amount: '0',
        startDate: undefined,
        endDate: undefined,
        cancelDate: undefined,
        bookingDate: undefined,
        revRecStartDate: undefined,
        revRecEndDate: undefined,
        crmId: '',
        externalLink: '',
        notes: '',
        tags: [],
      }
    ),
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState: { errors, isDirty, dirtyFields },
    clearErrors,
    trigger,
    watch,
  } = useForm<SubscriptionFormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const [isNameUnique, setName] = useUniquenessCheck(subscriptionUnique, { customerID: selectedCustomer?.id }, 'name', trigger);

  const triggerTouched = (field: any) => ((dirtyFields as Record<string, boolean | undefined>)[field]) && trigger(field);

  const startDate = watch('startDate');
  const suggestedEndDate = startDate
    ? formatDateToISO(addDays(addMonths(startDate, 12), -1))
    : undefined;

  const handleSaveAndNew = handleSubmit((data) => {
    const subscription = prepareSubscriptionPayload(data);

    return addSubscription(subscription)
      .unwrap()
      .then((result) => {
        toastSuccess('Revenue successfully added.');
        onSuccess && onSuccess(result.id);
        reset();
        setTimeout(() => clearErrors(), 0);
      });
  });

  const handleSaveAndClose = handleSubmit((data) => {
    const subscription = prepareSubscriptionPayload(data);

    return addSubscription(subscription)
      .unwrap()
      .then((result) => {
        toastSuccess('Revenue successfully added.');
        onSuccess && onSuccess(result.id);
        onClose();
      });
  });

  const handleAddTag = ({ name }: { name: string }) => {
    dispatch(formStateActions.setPredefinedData({
      name,
      type: TagType.CONTRACT,
    }));
    dispatch(formStateActions.setFormData(parseSubscriptionFormData(getValues())));
    onAddTag();
  };

  const handleAddCustomer = ({ name }: { name: string }) => {
    if (onAddCustomer) {
      dispatch(formStateActions.setPredefinedData({ name }));
      dispatch(formStateActions.setFormData(parseSubscriptionFormData(getValues())));
      onAddCustomer();
    }
  };

  const handleAddProduct = ({ name }: { name: string }) => {
    if (onAddProduct) {
      dispatch(formStateActions.setPredefinedData({ name }));
      dispatch(formStateActions.setFormData(parseSubscriptionFormData(getValues())));
      onAddProduct();
    }
  };

  useEffect(() => {
    if (selectedCustomer !== null && getValues().name) {
      triggerTouched('name');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues, selectedCustomer]);

  return (
    <>
      <header>
        <ButtonsContainer>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={() => actionBlocker(onClose, isDirty)}
            pushRight
            data-cy="as-button-close"
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <SectionLabel>
          <span>
            Add {entityName !== "all" ? entityName : 'Revenue'}
          </span>
        </SectionLabel>
      </header>
      <main>
        <StyledFormContainer>
          <Controller
            name="customer"
            control={control}
            render={({ field }) =>
              <CustomersDropdownLazy
                labelText="Customer"
                labelField="name"
                valueField="id"
                searchBy="name"
                placeholder="Select Customer"
                values={getValues('customer')}
                {...field}
                error={errors.customer?.message}
                isRequired
                create
                onCreateNew={handleAddCustomer}
                dataCy="as-dropdown-customer"
                onChange={(customer) => {
                  setSelectedCustomer(customer.at(0));
                  field.onChange(customer);
                }}
              />
            }
          />
          <Controller
            name="product"
            control={control}
            render={({ field }) =>
              <div>
                <Dropdown
                  labelText="Product"
                  labelField="name"
                  valueField="id"
                  searchBy="name"
                  placeholder="Select Product SKU"
                  options={products || []}
                  values={getValues('product')}
                  {...field}
                  error={errors.product?.message}
                  isRequired
                  create
                  onCreateNew={handleAddProduct}
                  dataCy="as-dropdown-product"
                />
                <AssistiveText text={getValues('product')[0]?.revenueType} />
              </div>
            }
          />
          <InputBasic
            isRequired
            labelText="Description"
            placeholder="Describe your revenue"
            {...register('name')}
            onBlur={(e) => {
              const fieldValue = e.target.value;

              if (fieldValue) {
                setValue('name', fieldValue.trim(), { shouldValidate: true });
              }
            }}
            onCustomChange={(e) => setName(e.target.value)}
            error={errors.name?.message}
            tooltip="You can change the description, but the value must be unique within the customer."
            data-cy="as-input-name"
            isLoading={isCheckingUniqueness}
          />
          <Controller
            name="amount"
            control={control}
            render={({ field }) =>
              <NumberInput
                labelText="Amount"
                {...field}
                onChange={field.onChange}
                placeholder="Enter Amount"
                error={errors.amount?.message}
                tooltip="Enter the total value of the revenue. For month-to-month revenues, enter the monthly amount."
                isRequired
                data-cy="as-input-amount"
              />
            }
          />
          <Controller
            name="startDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Start Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('bookingDate');
                  triggerTouched('endDate');
                  triggerTouched('cancelDate');
                }}
                error={errors.startDate?.message}
                isRequired
                data-cy="as-datepicker-start"
              />
            }
          />
          <Controller
            name="bookingDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Different Booking Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('startDate');
                  triggerTouched('endDate');
                  triggerTouched('cancelDate');
                }}
                error={errors.bookingDate?.message}
              />
            }
          />
          <Controller
            name="endDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="End Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('startDate');
                  triggerTouched('bookingDate');
                  triggerTouched('cancelDate');
                }}
                error={errors.endDate?.message}
                tooltip="This revenue will remain active until it is renewed or cancelled."
                isRequired
                data-cy="as-datepicker-end"
                suggestedDate={suggestedEndDate}
              />
            }
          />
          <Controller
            name="cancelDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Cancel Date"
                placeholder="Cancel Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('startDate');
                  triggerTouched('endDate');
                  triggerTouched('bookingDate');
                }}
                error={errors.cancelDate?.message}
                tooltip="Entering a cancel date will terminate the revenue upon that date. You can add it now or later."
                isOptional
                data-cy="as-datepicker-cancel"
              />
            }
          />
          <StyledFormSectionLabel>
            <span>
              Revenue Recognition
            </span>
          </StyledFormSectionLabel>
          <Controller
            name="revRecStartDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Recognition Start Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('revRecEndDate');
                }}
                error={errors.revRecStartDate?.message}
                tooltip="Enter revenue recognition start date. You can add it now or later."
                isOptional
              />
            }
          />
          <Controller
            name="revRecEndDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Recognition End Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                }}
                error={errors.revRecEndDate?.message}
                tooltip="Enter revenue recognition end date. You can add it now or later."
                isOptional
              />
            }
          />
          <StyledExpandButton
            variant="borderless"
            size="large"
            type="button"
            onClick={() => setIsOptionsExpanded(!isOptionsExpanded)}
            isOpen={isOptionsExpanded}
            data-cy="as-button-additional"
          >
            Additional Options
            <ChevronDown />
          </StyledExpandButton>
          <StyledAdditionalOptionsContainer isOpen={isOptionsExpanded}>
            <Controller
              name="tags"
              control={control}
              render={({ field }) =>
                <Dropdown
                  labelText="Tags"
                  labelField="name"
                  valueField="id"
                  searchBy="name"
                  options={tagOptions}
                  placeholder="Select Tags"
                  entityName="tag"
                  values={getValues('tags')}
                  {...field}
                  isOptional
                  clearable
                  multi
                  error={errors.tags?.message}
                  tooltip="Categorize this revenue with a contract tag(s)."
                  create
                  onCreateNew={handleAddTag}
                  dataCy="as-dropdown-tags"
                />
              }
            />
            <InputBasic
              isOptional
              labelText="CRM ID"
              placeholder="Paste CRM identifier"
              tooltip="Paste in an ID from your CRM to help identify this revenue."
              {...register('crmId')}
              error={errors.crmId?.message}
              data-cy="as-input-crm"
            />
            <InputBasic
              isOptional
              labelText="External Link"
              placeholder="Paste an external link"
              tooltip="You can paste in a link to your CRM or wherever you store your revenue."
              {...register('externalLink')}
              error={errors.externalLink?.message}
              onBlur={(e) => {
                const fieldValue = e.target.value;

                if (fieldValue && !fieldValue.startsWith('http')) {
                  setValue('externalLink', 'http://' + fieldValue, { shouldValidate: true });
                }
              }}
              data-cy="as-input-external"
            />
            <InputBasic
              isOptional
              labelText="Notes"
              placeholder="Add a quick note"
              {...register('notes')}
              error={errors.notes?.message}
              data-cy="as-input-notes"
              multiline
            />
          </StyledAdditionalOptionsContainer>
        </StyledFormContainer>
      </main>
      <footer>
        <StyledButtonContainer pushRight>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={handleSaveAndClose}
            disabled={isLoading || isCheckingUniqueness}
            isLoading={isLoading}
            data-cy="as-button-save-close"
          >
            SAVE & CLOSE
          </Button>
          <Button
            type="submit"
            onClick={handleSaveAndNew}
            disabled={isLoading || isCheckingUniqueness}
            isLoading={isLoading}
            data-cy="as-button-save-new"
          >
            SAVE & NEW
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};