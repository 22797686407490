import {
  forwardRef,
  HTMLAttributes,
} from "react";
import styled, { css } from 'styled-components';
import Select, { SelectProps } from 'react-dropdown-select';
import { theme } from 'theme/theme';
import { Label } from '../Input/Label';
import { ErrorDisplay } from "../Input/ErrorDisplay";

export const StyledDropdownWrapper = styled.div<{ hidden?: boolean }>`
  display: ${props => props.hidden ? 'none' : 'flex'};
  flex-direction: column;
  padding: 0 1px;
`;

interface StyledSelectProps {
  withError?: boolean;
  oneSignType?: boolean;
  borderless?: boolean;
  condensed?: boolean;
  minWidth?: number;
}

export const StyledSelect = styled(Select)<StyledSelectProps>`
  &.react-dropdown-select {
    padding: ${props => props.condensed ? '7px 12px' : '12px'};
    border-radius: 8px;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    border: 1px solid ${props => props.withError ? theme.colors.error : theme.colors.fieldBorder};
    color: ${props => props.disabled ? theme.colors.textDisabled :  theme.colors.textDark};
    background-color: ${props => props.disabled ? theme.colors.secondaryBackground : 'inherit'};

    &::before {
      content: "";
      position: absolute;
      top: -2px;
      right: -2px;
      bottom: -2px;
      left: -2px;
      border: 2px solid ${theme.colors.transparent};
      border-radius: 9px;
      pointer-events: none;
      transition: all 200ms ease;
    }

    :focus,
    :focus-visible,
    :focus-within,
    :hover {
      &::before {
        border: 2px solid ${props => props.withError ? theme.colors.error : theme.colors.primary};
      }
      border: 1px solid ${props => props.withError ? theme.colors.error : theme.colors.primary};
      box-shadow: none;
    }

    ${props => props.minWidth && css`
      min-width: ${props.minWidth}px;
    `}

    ${props => props.disabled && css`
      opacity: 1;
    `}

    ${props => props.oneSignType && css`
      width: 50px;
    `}

    ${props => props.borderless && css`
      border: 0;
      padding: 0;
      font-size: 14px;

      .react-dropdown-select-input {
        &::placeholder {
          font-size: 14px;
          
          ${props.withError && css`
            color: ${theme.colors.error};
          `}
        }
      }
      
      :focus,
      :focus-visible,
      :focus-within,
      :hover {
        border: 0;

        &::before {
          display: none;
        }
      }

      ${props.disabled && css`
        background-color: ${theme.colors.transparent};
        opacity: 0.5;
      `}
    `}
  }

  .react-dropdown-select-dropdown {
    border-radius: 8px;
    width: inherit;
    background-color: ${theme.colors.mainBackground};
    border: none;
    box-shadow: ${theme.boxShadow};
  }

  .react-dropdown-select-content.react-dropdown-select-type-single,
  .react-dropdown-select-content.react-dropdown-select-type-multi {
    overflow: hidden;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .react-dropdown-select-content.react-dropdown-select-type-single {
    flex-wrap: nowrap;
  } 

  .react-dropdown-select-item,
  .react-dropdown-select-dropdown-add-new {
    padding: 12px 16px;
    width: inherit;
    border-bottom: none;
    color: inherit;
    word-break: break-all;
    white-space: normal;
    transition: all 100ms ease;

    :hover {
      background-color: ${theme.colors.primaryBleach};
    }
    
    &-selected {
      color:  ${theme.colors.primary} !important;
      background: ${theme.colors.primaryBleach} !important;
      border-bottom: none !important;
    }
  }
  
  .react-dropdown-select-item-disabled {
    color: ${theme.colors.textDisabled};
    background-color: ${theme.colors.transparent};
    cursor: not-allowed;

    :hover {
      background-color: ${theme.colors.transparent};
    }
  }

  .react-dropdown-select-option {
    border-radius: 6px;
    background-color: ${theme.colors.primary};
  }

  .react-dropdown-select-input {
    margin-left: 0;

    &::placeholder {
      font-size: 16px;
    }
  }

  .react-dropdown-select-dropdown-add-new {
    color: ${theme.colors.primary};
  }
`;

interface Props extends SelectProps<any> {
  labelText?: string;
  isRequired?: boolean;
  isOptional?: boolean;
  withError?: boolean;
  oneSignType?: boolean;
  dataCy?: string;
  error?: string;
  tooltip?: string;
  hidden?: boolean;
  borderless?: boolean;
  maxHeight?: number;
  condensed?: boolean;
  minWidth?: number;
  entityName?: string;
  moreThan?: number;
}

export const Dropdown = forwardRef(({
  ...props
}: Props, ref: any) => {
  const key = props.values.map((v) => v.label)
    .toString();

  return (
    <StyledDropdownWrapper
      hidden={props.hidden}
      ref={ref}
    >
      {props.labelText && (
        <Label
          name={props.name!}
          labelText={props.labelText || ''}
          isOptional={props.isOptional}
          isRequired={props.isRequired}
          tooltip={props.tooltip}
          annotation={props.moreThan ? `More than ${props.moreThan} entries` : ''}
          annotationTooltip={props.moreThan
            ? `This list contains more than ${props.moreThan} entries. 
              Only first ${props.moreThan} will be visible. Use search to narrow down the list.`
            : ''}
        />
      )}
      <StyledSelect
        key={key}
        {...props}
        withError={!!props.error}
        additionalProps={{ "data-cy": `${props.dataCy}` } as HTMLAttributes<HTMLDivElement>}
        disabledLabel={props.disabledLabel || "Deleted"}
        dropdownPosition={props.dropdownPosition || 'auto'}
        dropdownHeight={props.maxHeight ? `${props.maxHeight}px` : undefined}
        createNewLabel={`add {search} as new ${props.entityName || props.name || ''}`}
      />
      {props.error && <ErrorDisplay error={props.error} />}
    </StyledDropdownWrapper>
  );})
;
