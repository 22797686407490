import React, {
  useEffect,
  useState,
} from 'react';
import axios from 'axios';
import { StyledNewVersionPopup } from './styled';
import { Button } from 'components/common/Button';
import { Tooltip } from 'components/common/Tooltip';
import { clearDataFromSessionStorage } from 'utils/sessionStorage';

export const VersionChecker = () => {
  const [localVersion, setLocalVersion] = useState();
  const [serverVersion, setServerVersion] = useState();

  const differentVersions = localVersion && localVersion !== serverVersion;

  useEffect(() => {
    let interval: NodeJS.Timer;

    axios.get('/build.json')
      .then(res => {
        setServerVersion(res?.data?.version);
        setLocalVersion(res?.data?.version);
      })
      .catch(() => { });

    interval = setInterval(() => {
      axios.get('/build.json')
        .then(res => {
          setServerVersion(res?.data?.version);
        })
        .catch(() => { });
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Clears all filters on application version change
    if (differentVersions) {
      clearDataFromSessionStorage('filter');
    }
  }, [differentVersions]);

  return differentVersions
    ? (
      <StyledNewVersionPopup>
        <div>
          New version of Insights App has been released
          <Tooltip title="As we continuously improve Insights, a new version may be released every few days. We strongly recommend reloading whenever you see this popup. Working on outdated versions of Insights may lead to undesired problems." />
        </div>
        <div>
          Please reload the application
        </div>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => window.location.reload()}
        >
          RELOAD
        </Button>
      </StyledNewVersionPopup>
    )
    : null;
};
