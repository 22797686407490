import { IntegrationType } from "./accounts";
import { UserRole } from "./auth";

export interface CompanyFormFields {
  name: string;
  domain: string;
  currencyCode: {
    code: string;
    symbol: string;
    name: string;
  }[];
  industry: { value: IndustryType }[];
  businessType: { value: BusinessType }[];
  businessStage: { value: BusinessStageType }[];
  companyRevenueType: CompanyRevenueType;
  useRevRec: boolean;
  populateSampleData: boolean;
}

export type CompanyFirstStepFormFields = Pick<CompanyFormFields, 'name'>;
export type CompanySecondStepFormFields = Pick<CompanyFormFields, 'currencyCode' | 'domain' | 'industry' | 'businessType' | 'businessStage'>;
export type CompanyThirdStepFormFields = Pick<CompanyFormFields, 'companyRevenueType' | 'useRevRec' | 'populateSampleData'>;

export interface Company {
  businessStage: BusinessStageType | null;
  businessType: BusinessType | null;
  companyRevenueType: CompanyRevenueType;
  currency: {
    code: string;
    symbol: string;
    name: string;
  };
  companyStatus: CompanyStatus | null;
  domain: string;
  group: string | null;
  id: string;
  industry: IndustryType | null;
  integrationSync: Array<{
    provider: IntegrationType;
    syncedAt: string;
  }>;
  isCompanyActive: boolean;
  name: string;
  role: UserRole;
  tag: string | null;
  currencyCode: string;
  useRevRec: boolean;
}

export interface CompanyUpdateByIdPayload extends Omit<Company, 'currency' | 'integrationSync' | 'isCompanyActive' | 'role'> {
  currencyCode: string;
}

export type CompanyUpdatePayload = Omit<CompanyUpdateByIdPayload, 'id'>;

export interface CompanyAddPayload extends CompanyUpdatePayload {
  populateSampleData: boolean;
}

export interface SetCompanyUsersRolesPayload {
  userIds: string[];
  role: UserRole;
}

export interface DuplicateCompanyPayload {
  companyId: string;
  companyName: string;
  factaAccounts: boolean;
  glAccounts: boolean;
  metrics: boolean;
  reports: boolean;
  users: boolean;
  settings: boolean;
  revenueManagement: boolean;
}

export enum IndustryType {
  ECOMMERCE = 'Ecommerce',
  FINANCIAL_TECHNOLOGY = 'Financial Technology',
  PROFESSIONAL_SERVICES = 'Professional Services',
  SOFTWARE_AS_A_SERVICE = 'Software as a Service',
  OTHER = 'Other',
}

export enum BusinessType {
  ENTERPRISE_B2B_SOFTWARE = 'Enterprise B2B Software',
  B2C_SOFTWARE = 'B2C Software',
  SMB_SOFTWARE = 'SMB Software',
  OTHER = 'Other',
}

export enum BusinessStageType {
  EARLY_STAGE = 'Early Stage',
  LATE_STAGE = 'Late Stage',
  PRIVATE_EQUITY_BACKED = 'Private Equity Backed',
  PUBLIC = 'Public',
  OTHER = 'Other',
}

export enum CompanyRevenueType {
  SUBSCRIPTIONS = 'Subscriptions',
  CONTRACTS = 'Contracts',
}

export enum CompanyUserStatus {
  ACTIVE = 'Active',
  PENDING = 'Pending',
  DENIED = 'Denied',
}

export enum CompanyStatus {
  ACTIVE = 'Active',
  PROSPECTS = 'Prospects',
  ARCHIVED = 'Archived',
}

export interface CompanyUser {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  role: UserRole;
  status: CompanyUserStatus;
}