import styled, { css } from "styled-components";
import { theme } from "theme/theme";
import { FormulaElementType } from "interfaces/formulaEditor";
import { StyledPopupWrapper } from "./FormulaElementPopup/styled";
import { Button } from "components/common/Button";

interface SaveHintProps {
  success?: boolean;
  warning?: boolean;
}

export const SaveHint = styled.div<SaveHintProps>`
  opacity: 0;
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 10px;
  line-height: 20px;
  border: 1px solid ${theme.colors.fieldBorder};
  border-radius: 4px;
  padding: 2px 8px;
  transition: all 300ms ease;
  white-space: nowrap;

  svg {
    width: 16px;
    height: 16px;
    margin: 0 4px 0 0;
  }

  ${props => props.success && css`
    color: ${theme.colors.success};
    border: 1px solid ${theme.colors.success};
  `}

  ${props => props.warning && css`
    color: ${theme.colors.textDisabled};
    border: 1px solid ${theme.colors.transparent};
  `}
`;

export const StyledFormulaIcon = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 28px;;
`;

export const SaveButton = styled(Button)`
  font-size: 10px;
  line-height: 20px;
  border: 1px solid ${theme.colors.fieldBorder};
  background-color: ${theme.colors.warningBleach};
  border-radius: 4px;
  padding: 2px 8px;
  height: auto;
  margin-left: 8px;
  white-space: nowrap;
`;

interface StyledFormulaProps {
  isError?: boolean;
  isUnsaved?: boolean;
}

export const StyledFormula = styled.div<StyledFormulaProps>`
  padding: 24px;
  border-radius: 8px;
  border: solid 1px ${theme.colors.borderColorDark};
  background-color: ${theme.colors.white};
  gap: 0;
  flex-wrap: nowrap;
  transition: all 300ms ease;

  ${props => props.isError && css`
    border: solid 1px ${theme.colors.error};
  `}

  ${props => props.isUnsaved && css`
    border: solid 1px ${theme.colors.warning};
  `}

  & > div {
    display: flex;
    align-items: flex-start;
  }

  &:focus-within,
  &:active,
  &:hover {
    border: solid 1px ${props => props.isError ? theme.colors.error : theme.colors.primary};
    cursor: pointer;
  }
  
  &:focus-within,
  &:active {
    box-shadow: ${theme.boxShadowHighlight};

    ${SaveHint} {
      opacity: 1;
    }

    ${SaveButton} {
      background-color: ${theme.colors.transparent};
    }
  }
`;

export const FormulaContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledDelete = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -6px;
  top: -8px;
  opacity: 0;
  transition: all 100ms ease;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-shadow: ${theme.boxShadow};
  color: ${theme.colors.textDark};

  svg {
    width: 13px;
    height: 13px;
  }

  &:hover {
    opacity: 1;
    color: ${theme.colors.error};
  }
`;

export const StyledElement = styled.div<{ type: FormulaElementType, error?: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 4px;
  line-height: 20px;
  background-color: ${props => {
    switch (props.type) {
      case FormulaElementType.ACCOUNT:
        return theme.colors.primaryBleach;
      case FormulaElementType.METRIC:
        return theme.colors.tertiaryBleach;
      case FormulaElementType.UNKNOWN:
        return theme.colors.secondaryBackground;
      default:
        return theme.colors.transparent;
    }
  }};
  color: ${props => {
    switch (props.type) {
      case FormulaElementType.ACCOUNT:
        return theme.colors.primaryStrong;
      case FormulaElementType.METRIC:
        return theme.colors.tertiary;
      case FormulaElementType.MODIFIER:
        return theme.colors.success;
      case FormulaElementType.CLAUSE:
      case FormulaElementType.CLAUSE_CLOSE:
        return theme.colors.primary;
      default:
        return theme.colors.textDark;
    }
  }};
  font-size: ${props => {
    switch (props.type) {
      case FormulaElementType.METRIC:
      case FormulaElementType.ACCOUNT:
      case FormulaElementType.UNKNOWN:
        return '14px';
      default:
        return '16px';
    }
  }};
    font-weight: ${props => {
    switch (props.type) {
      case FormulaElementType.MODIFIER:
        return '600';
      default:
        return '500';
    }
  }};
  padding: ${props => {
    switch (props.type) {
      case FormulaElementType.METRIC:
      case FormulaElementType.ACCOUNT:
      case FormulaElementType.UNKNOWN:
        return '2px 8px';
      default:
        return '2px 6px';
    }
  }};
  margin: ${props => {
    switch (props.type) {
      case FormulaElementType.METRIC:
      case FormulaElementType.ACCOUNT:
      case FormulaElementType.UNKNOWN:
        return '2px 4px';
      default:
        return '2px 0';
    }
  }};
  transition: box-shadow 100ms ease;
  
  &:hover {
    box-shadow: ${theme.boxShadow};

    ${StyledDelete} {
      position: absolute;
      opacity: 1;
      background-color: ${theme.colors.errorBleach};
    }

    ${StyledPopupWrapper} {
      opacity: 1 !important;
      pointer-events: all !important;
    }
  }

  ${props => props.error && css`
    position: relative;

    &:after {
      position: absolute;
      content: '^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^';
      font-size: 9px;
      left: 0;
      right: 0;
      bottom: -12px;
      color: red;
      letter-spacing: -1.5px;
      overflow: hidden;
    }
  `}
`;

export const StyledInput = styled.span`
  padding: 6px 5px;
  border: 0;
  outline: 0;

  &:not(:focus) {
    color: ${theme.colors.warning};
  }
`;

export const StyledDropdownWrapper = styled.div`
  position: relative;
  z-index: 3;
`;

export const StyledDropdown = styled.div<{ open?: boolean }>`
  display: block;
  opacity: ${props => props.open ? '1' : '0'};
  pointer-events: ${props => props.open ? 'all' : 'none'};
  position: absolute;
  top: 8px;
  left: 0;
  border-radius: 6px;
  padding: 0;
  background-color: ${theme.colors.white};
  max-height: 360px;
  box-shadow: ${theme.boxShadow};
  overflow: auto;
  transition: all 300ms ease;

  &:focus-within,
  &:active {
    box-shadow: ${theme.boxShadow};

    .hit-hint {
      opacity: 0;
    }
  }
`;

export const StyledType = styled.div<{ type: FormulaElementType }>`
  margin-left: 24px;
  font-size: 10px;
  line-height: 24px;
  padding: 0 8px;
  border-radius: 4px;
  font-weight: 600;
  transition: all 300ms ease;
  white-space: nowrap;
  background-color: ${props => {
    switch (props.type) {
      case FormulaElementType.ACCOUNT:
        return theme.colors.primaryBleach;
      case FormulaElementType.METRIC:
        return theme.colors.tertiaryBleach;
      default:
        return theme.colors.transparent;
    }
  }};
  color: ${props => {
    switch (props.type) {
      case FormulaElementType.ACCOUNT:
        return theme.colors.primaryStrong;
      case FormulaElementType.METRIC:
        return theme.colors.tertiary;
      default:
        return theme.colors.textDark;
    }
  }};
`;


export const StyledDropdownItem = styled.div`
  padding: 12px 16px;
  cursor: pointer;

  .line1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 24px;

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .line2 {
    font-size: 10px;
    line-height: 18px;
    color: ${theme.colors.textDisabled};
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${theme.colors.primaryBleach};
    outline: 0;

    ${StyledType} {
      background-color: ${theme.colors.white};
    }
  }

  .hit-hint {
    font-size: 10px;
    line-height: 22px;
    color: ${theme.colors.textDisabled};
    margin-left: 12px;
    border: 1px solid ${theme.colors.fieldBorder};
    border-radius: 4px;
    padding: 2px 8px;
    transition: opacity 300ms ease;
  }
`;

export const StyledHelper = styled.p<{ isError?: boolean }>`
  padding-top: 8px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: ${props => props.isError ? theme.colors.error : theme.colors.textDisabled};
  height: 40px;
`;
