import React from 'react';
import styled from 'styled-components';
import {
  LlamaImage,
  LlamaSanta,
} from 'assets/images';
import { featureSwitch } from 'utils/featureSwitch';

const StyledEmptyState = styled.div`
  @media (max-height: 900px) {
    svg {
      display: none;
    }
  }
`;

const ContentContainer = styled.div`
  padding: 24px;
  gap: 24px;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface Props {
  children: React.ReactNode | string;
}

export const AgGridEmptyState = ({ children }: Props) => (
  <StyledEmptyState>
    {featureSwitch.llamaSanta
      ? <LlamaSanta />
      : <LlamaImage />
    }
    <ContentContainer>
      {children}
    </ContentContainer>
  </StyledEmptyState>
);
