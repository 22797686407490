import React from 'react';
import {
  StyledTabsContainer,
  TabButton,
} from './styled';

interface TabInterface {
  name: string | JSX.Element;
  value: string;
  disabled?: boolean;
  hidden?: boolean;
}

interface TabsProps {
  tabs: TabInterface[];
  value: string;
  onChange?: (selectedTab: TabInterface) => void;
  disabled?: boolean;
}

export const Tabs = ({
  tabs,
  value,
  onChange,
  disabled,
}: TabsProps) => {
  return (
    <StyledTabsContainer disabled={disabled}>
      {tabs.filter((tab) => !tab.hidden)
        .map((tab) => (
          <TabButton
            key={tab.value}
            onClick={(ev) => {
              if (onChange && !tab.disabled) {
                ev.preventDefault();
                onChange(tab);
              }
            }}
            disabled={tab.disabled || disabled}
            selected={tab.value === value}
            to={!onChange && !tab.disabled ? tab.value : '#'}
          >
            {tab.name}
          </TabButton>
        ))}
    </StyledTabsContainer>
  );
};

interface TabProps {
  isVisible: boolean;
  children: React.ReactNode;
}

export const Tab = ({
  isVisible,
  children,
}: TabProps ) => isVisible
  ? (
      <div>
        {children}
      </div>
    )
  : null;
