import React, {
  CSSProperties,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { useClickOutside } from 'hooks/useClickOutside';
import { IRowNode } from '@ag-grid-community/core';
import {
  StyledMoveSelected,
  StyledLeftContainer,
  StyledOption,
  StyledFactaAccounts,
} from './styled';

interface Props {
  popperRef: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  style: CSSProperties;
  attributes: {
    [key: string]: {
      [key: string]: string;
    } | undefined;
  };
  gridRef: React.RefObject<AgGridReact<any>>;
  onClose: () => void;
  onMoveGLAccounts: (node: IRowNode) => void;
  onMoveFactaAccounts: (node: IRowNode) => void;
}

export const MoveSelected = ({
  popperRef,
  attributes,
  style,
  gridRef,
  onClose,
  onMoveGLAccounts,
  onMoveFactaAccounts,
}: Props) => {
  const [options, setOptions] = useState<any[]>([]);
  const [visibleFactaType, setVisibleFactaType] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside(containerRef, onClose);

  useEffect(() => {
    const items: IRowNode[] = [];

    gridRef.current?.api.forEachNode((node) => {
      items.push(node);
    });

    const factaTypes: string[] = [...new Set(items.filter((node) => node.data.path[0] !== 'Uncategorized' && node.data.type !== 'total')
      .map((node) => node.data.path[0]))];

    const nestedOptions = factaTypes.map((factaType) => ({
      factaType: factaType,
      factaTypeNode: items.find((node) => node.data.path[0] === factaType && node.data.path.length === 1),
      factaAccounts: items.filter((node) => node.data.path[0] === factaType && node.data.path.length === 2),
    }));

    setOptions(nestedOptions);
    containerRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [gridRef]);

  return (
    <div ref={containerRef}>
      <StyledMoveSelected
        ref={popperRef}
        style={style}
        {...attributes}
      >
        <StyledLeftContainer>
          {options.map(({ factaType, factaTypeNode }) => (
            <StyledOption
              key={factaType}
              highlighted={visibleFactaType === factaType}
              onMouseEnter={() => setVisibleFactaType(factaType !== 'Uncategorized' ? factaType : '')}
              onClick={() => {
                onMoveFactaAccounts(factaTypeNode);
                onClose();
              }}
            >
              {factaType}
            </StyledOption>
          ))}
        </StyledLeftContainer>
        <div>
          {options.map(({ factaType, factaAccounts }) => (
            <StyledFactaAccounts
              key={`containe_${factaType}`}
              visible={visibleFactaType === factaType}
            >
              {factaAccounts.map((node: IRowNode) => (
                <StyledOption
                  key={`${factaType}_${node.data.path[1]}`}
                  onClick={() => {
                    onMoveGLAccounts(node);
                    onClose();
                  }}
                >
                  {node.data.path[1]}
                </StyledOption>
              ))}
            </StyledFactaAccounts>
          ))}
        </div>
      </StyledMoveSelected>
    </div>
  );
};
