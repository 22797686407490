import React, {
  useEffect,
  useRef,
} from "react";
import { SelectItemRenderer } from "react-dropdown-select";
import { getByPath } from "utils/getByPath";
import { StyledItemComponent } from "./styled";

export const ItemComponent = ({ item, itemIndex, props, state, methods }: SelectItemRenderer<any>) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      ref.current &&
      !props.multi &&
      props.keepSelectedInList &&
      methods.isSelected(item)
    ) {
      ref.current.scrollIntoView({ block: 'nearest', inline: 'start' });
    }
  }, [ref, methods, props.keepSelectedInList, props.multi, item]);

  useEffect(() => {
    if (state.cursor === itemIndex) {
      ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  }, [item, itemIndex, state.cursor]);

  return (
    <StyledItemComponent
      ref={ref}
      key={`${getByPath(item, props.valueField!)}${getByPath(item, props.labelField!)}`}
      aria-selected={methods.isSelected(item)}
      aria-disabled={item.disabled}
      aria-label={getByPath(item, props.labelField!)}
      onClick={item.disabled ? undefined : () => methods.addItem(item)}
      onKeyPress={item.disabled ? undefined : () => methods.addItem(item)}
      tabIndex={-1}
      selected={methods.isSelected(item)}
      active={state.cursor === itemIndex}
      disabled={item.disabled}
      className={`react-dropdown-select-item ${
        methods.isSelected(item)
          ? 'react-dropdown-select-item-selected'
          : ''
      }`}
      color={props.color}
      badgeColor={item.color}
    >
      <span>
        {getByPath(item, props.labelField!)}
      </span>
      <div>
        {item.type && <ins>{item.type}</ins>}
        {item.disabled && <ins>{props.disabledLabel}</ins>}
      </div>
    </StyledItemComponent>
  );
};