import styled from "styled-components";
import { theme } from "theme/theme";

export const NestedField = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: -10px;

  & > svg {
    color: ${theme.colors.textDisabled};
    margin-left: 10px;
    width: 24px;
    height: 24px;
  }

  & > div {
    flex: 1;
  }
`;

export const SliderWrapper = styled.div<{ width: number }>`
  margin-top: -35px;
  max-width: 400px;
  min-width: 100px;
  width: ${props => props.width}px;
`;
