import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { theme } from "theme/theme";
import { ANIMATION_DURATION } from "utils/constants";

interface StyledMenuItemProps {
  $active?: boolean;
  $pushDown?: boolean;
  $pushRight?: boolean;
  $isCollapsed?: boolean;
}

export const StyledMenuItem = styled(Link) <StyledMenuItemProps>`
  display: flex;
  align-items: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: ${props => props.$active ? 700 : 500};
  line-height: 21px;
  text-align: left;
  padding: 12px 16px;
  height: 49px;
  background-color: ${props => props.$active ? theme.colors.black : theme.colors.darkBackground};
  color: ${props => props.$active ? theme.colors.primary20 : theme.colors.textLight};
  border: 0;
  border-top: 1px solid ${theme.colors.sidebarBorder};
  cursor: pointer;
  transition: all ${ANIMATION_DURATION}ms ease;
  white-space: nowrap;
  width: 100%;

  &:visited {
    color: ${props => props.$active ? theme.colors.primary20 : theme.colors.textLight};
  }

  ${props => props.$pushDown && css`
    margin-top: auto;
    border-top: none;
  `}

  svg {
    flex-shrink: 0;

    ${props => props.$active && css`
      color: ${theme.colors.primary40};
    `}
  }

  span {
    margin-left: 16px;
    transition: opacity ${ANIMATION_DURATION}ms ease;
  }

  ${props => !props.$active && css`
    &:hover {
      background-color: ${theme.colors.textDark};
    }
  `}
`;

export const StyledMenuSubItem = styled(StyledMenuItem)`
  padding-left: ${props => props.$pushRight ? '65px' : '40px'};
  border-top: none;
  font-size: 14px;

  ${props => props.$isCollapsed && css`
    padding-left: 10px !important;
  `}
`;

export const ChevronContainer = styled.div<{ isOpen?: boolean }>`
  margin-left: auto;
  height: 24px;
  transition: all ${ANIMATION_DURATION}ms ease;
  padding: 4px;

  ${props => props.isOpen && css`
    transform: rotate(180deg);
  `}

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const SubItemsContainer = styled.div<{ height: number }>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow: hidden;
  transition: all ${ANIMATION_DURATION}ms ease;
  height: ${props => props.height}px;
`;

interface StyledSidebarProps {
  isCollapsed?: boolean;
}

export const StyledSidebar = styled.div<StyledSidebarProps>`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.darkBackground};
  padding: 0;
  width: 332px;
  min-width: 200px;
  transition: all ${ANIMATION_DURATION}ms ease;
  overflow-x: hidden;
  overflow-y: auto;
  border-right: 1px solid ${theme.colors.sidebarBorder};
  z-index: 3;

  ${props => props.isCollapsed && css`
    width: 50px;
    min-width: 50px;

    ${StyledMenuItem} {
      padding: 12px;
      
      span {
        opacity: 0;
      }
    }

    ${SubItemsContainer} {
      height: 0;
    }
  `}
`;

export const SubItemsTitle = styled(StyledMenuSubItem)`
  display: none;
  padding: 0 8px !important;
  font-weight: 600;
`;

export const ItemContainer = styled.div<{ isCollapsed?: boolean }>`
  display: flex;
  flex-direction: column;

  &:last-of-type {
    border-bottom: 1px solid ${theme.colors.sidebarBorder};
  }

  ${props => props.isCollapsed && css`
    &:hover {
      ${SubItemsContainer} {
        display: flex;
        padding: 8px 10px;
        position: absolute;
        left: 49px;
      }
    }
    
    ${StyledMenuItem} {
      order: 2;
    }
    
    ${SubItemsContainer} {
      display: none;
      min-width: 200px;
      order: 1;
      flex-direction: column;
      transition: none;
      height: auto;
      border-bottom: 0;

      ${StyledMenuItem} {
        height: 34px;
        font-size: 12px;
        padding: 0;

        span {
          margin-right: 16px;
        }

        &:last-of-type {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }

    ${SubItemsTitle} {
      display: block;
      height: 34px;
      font-size: 12px;
      line-height: 34px;
      padding: 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    ${StyledMenuItem} {
      span {
        opacity: 1;
        width: 100%;
      }
    }
  `}
`;
