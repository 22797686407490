import {
  ContractLineFormFields,
  ContractLineStateFormFields,
  ContractLineUpdatePayload,
  ContractRenewalLine,
  ContractRenewalLineFormFields,
} from "interfaces/contractLines";
import {
  formatDateToISO,
  isoToDate,
} from "./dates";

export const prepareContractLinePayload = (data: ContractLineFormFields): ContractLineUpdatePayload => {
  return {
    id: data.id,
    name: data.name,
    contractId: data.contract?.at(0)?.id || null,
    customerId: data.customer?.at(0)?.id || null,
    productId: data.product?.at(0)?.id || null,
    startDate: formatDateToISO(data.startDate!),
    endDate: formatDateToISO(data.endDate!),
    bookingDate: data.bookingDate ? formatDateToISO(data.bookingDate) : formatDateToISO(data.startDate!),
    cancelDate: data.cancelDate ? formatDateToISO(data.cancelDate) : null,
    revRecStartDate: data.revRecStartDate ? formatDateToISO(data.revRecStartDate) : null,
    revRecEndDate: data.revRecEndDate ? formatDateToISO(data.revRecEndDate) : null,
    amount: Number(data.amount),
    crmId: data.crmId || null,
    tagIds: data.tags.map((tag) => tag.id),
    externalLink: data.externalLink || null,
    notes: data.notes || null,
    contractBasedMRRCalc: data.contractBasedMRRCalc,
  };
};

export const parseContractLineFormData = (data: ContractLineFormFields): ContractLineStateFormFields => {
  return {
    ...data,
    startDate: data.startDate ? formatDateToISO(data.startDate) : undefined,
    endDate: data.endDate ? formatDateToISO(data.endDate) : undefined,
    bookingDate: data.bookingDate ? formatDateToISO(data.bookingDate) : undefined,
    cancelDate: data.cancelDate ? formatDateToISO(data.cancelDate) : undefined,
    revRecStartDate: data.revRecStartDate ? formatDateToISO(data.revRecStartDate) : undefined,
    revRecEndDate: data.revRecEndDate ? formatDateToISO(data.revRecEndDate) : undefined,
  };
};

export const parseStateFormData = (data: ContractLineStateFormFields): ContractLineFormFields => {
  return {
    ...data,
    startDate: data.startDate ? isoToDate(data.startDate) : undefined,
    endDate: data.endDate ? isoToDate(data.endDate) : undefined,
    bookingDate: data.bookingDate ? isoToDate(data.bookingDate) : undefined,
    cancelDate: data.cancelDate ? isoToDate(data.cancelDate) : undefined,
    revRecStartDate: data.revRecStartDate ? isoToDate(data.revRecStartDate) : undefined,
    revRecEndDate: data.revRecEndDate ? isoToDate(data.revRecEndDate) : undefined,
  };
};

export const prepareRenewalContractLine = (data: ContractRenewalLineFormFields): ContractRenewalLine => {
  return {
    ...data,
    previousAmount: Number(data.previousAmount),
    amount: Number(data.amount),
    contract: data.contract?.at(0)!,
    customer: data.customer?.at(0)!,
    product: data.product?.at(0)!,
    id: data.id || crypto.randomUUID(),
    startDate: data.startDate ? formatDateToISO(data.startDate) : '',
    endDate: data.endDate ? formatDateToISO(data.endDate) : '',
    bookingDate: data.bookingDate ? formatDateToISO(data.bookingDate) : '',
    cancelDate: data.cancelDate ? formatDateToISO(data.cancelDate) : '',
    revRecStartDate: data.revRecStartDate ? formatDateToISO(data.revRecStartDate) : '',
    revRecEndDate: data.revRecEndDate ? formatDateToISO(data.revRecEndDate) : '',
  };
};
