import { Button } from "components/common/Button";
import styled, { css } from "styled-components";
import { ANIMATION_DURATION } from "utils/constants";

interface StyledButtonProps {
  isOpen?: boolean;
  tertiary?: boolean
}

export const StyledButton = styled(Button)<StyledButtonProps>`
  transition: all ${ANIMATION_DURATION}ms ease;
  transform: rotate(${props => props.isOpen ? '180deg' : '0deg'});
  flex-shrink: 0;

  ${props => props.tertiary && css`
    width: 24px;
    height: 24px;
  `}
`;
