import styled from "styled-components";

export const PeriodsInputWrapper = styled.div`
  input {
    width: 55px;
    transition: all 0.3s ease;
    transition-delay: 0.3s;

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &:hover, &:focus, &:active {
      width: 75px;
      transition-delay: 0s;

      &::-webkit-inner-spin-button {
        -webkit-appearance: auto;
      }
    }
  }
`;
