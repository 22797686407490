import React, { useEffect } from 'react';
import { LeftPane } from 'components/Layout';
import { Box } from 'components/common/Box';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import { SectionLabel } from 'components/common/SectionLabel';
import { Tooltip } from 'components/common/Tooltip';
import { IntegrationsItem } from './IntegrationsItem';
import {
  NetsuiteIcon,
  QBOIcon,
  SageIcon,
} from 'assets/images';
import {
  OpenInNewIcon,
  SalesforceIcon,
} from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { Button } from 'components/common/Button';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
  useGetActiveIntegrationsQuery,
  usePostConnectQBOCallbackMutation,
  usePostConnectQBOMutation,
  usePostDisconnectQBOMutation,
} from 'store/services/integrations';
import { Modal } from 'components/Layout/Modal';
import { useRightPanes } from 'hooks/useRightPanes';
import { QBOSync } from './QBOSync';
import { useGetAuthMeQuery } from 'store/services/auth';
import { getCurrentCompany } from 'utils/currentCompany';
import { UserRole } from 'interfaces/auth';
import { toastSuccess } from 'utils/toast';
import { StyledHotglueContainer } from './styled';
import { Connections } from '@hotglue/widget';
import { SalesforceFlowId } from 'utils/constants';
import HotglueConfig from '@hotglue/widget';

interface GLIntegration {
  id: string;
  title: string;
  description: string;
  icon: JSX.Element;
  chipText?: string;
  disabled?: boolean;
  onConnect: () => void;
  onSync?: () => void;
  connectLabel?: string;
  onViewSettings?: () => void;
  onClickDocumentation?: () => void;
  onDisconnect?: () => void;
}

interface Props {
  callback?: 'qbo';
}

export const Integrations = ({ callback }: Props) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [connectQBO, { isLoading: isConnectingQBO }] = usePostConnectQBOMutation();
  const [connectQBOCallback, { isLoading: isConnectingQBOCallback }] = usePostConnectQBOCallbackMutation();
  const [disconnectQBO, { isLoading: isDisconnectingQBO }] = usePostDisconnectQBOMutation();
  const { data: integrationsData } = useGetActiveIntegrationsQuery();
  const { data: user } = useGetAuthMeQuery();

  const currentCompany = getCurrentCompany(user);
  const userRole = currentCompany?.role;

  const hotglueConfig = {
    apiKey: import.meta.env.VITE_APP_HOTGLUE_API_KEY!,
    envId: import.meta.env.VITE_APP_HOTGLUE_ENV_ID!,
  };

  const { rightPanes, setSelectedPane, resetPanes } = useRightPanes({
    syncQbo: false,
  });

  const isSendingRequest = isConnectingQBO;
  const isVeryfingConnection = isConnectingQBOCallback;
  const isDisconnecting = isDisconnectingQBO;

  const handleConnectQBO = () => {
    connectQBO()
      .unwrap()
      .then(({ redirectUrl }) => {
        window.location.href = redirectUrl;
      });
  };

  const handleDisconnectQBO = () => {
    disconnectQBO()
      .unwrap()
      .then(() => {
        toastSuccess('Successfully disconnected from Quickbooks Online.');
      });
  };

  const glIntegrations: GLIntegration[] = [
    {
      id: 'qbo',
      title: 'Quickbooks Online',
      description: 'Integration with Quickbooks Online.',
      icon: <QBOIcon />,
      connectLabel: 'Connect to Quickbooks Online',
      onConnect: handleConnectQBO,
      onSync: () => setSelectedPane('syncQbo'),
      onDisconnect: handleDisconnectQBO,
    },
    {
      id: 'netsuite',
      title: 'Netsuite',
      description: '',
      icon: <NetsuiteIcon />,
      chipText: 'Coming soon',
      disabled: true,
      onConnect: () => { },
    },
    {
      id: 'sage',
      title: 'Sage Intacct',
      description: '',
      icon: <SageIcon />,
      chipText: 'Coming soon',
      disabled: true,
      onConnect: () => { },
    },
  ];

  const activeIntegration = glIntegrations.find(({ id }) => integrationsData?.includes(id));

  useEffect(() => {
    if (callback === 'qbo') {
      const code = params.get('code');
      const realmId = params.get('realmId');
      const state = params.get('state');

      if (code && realmId && state) {
        connectQBOCallback({
          code,
          realmId,
          state,
        })
          .unwrap()
          .then(() => {
            navigate('/data-input');
          });
      }
    }
  }, [callback, connectQBOCallback, navigate, params]);

  return (
    <>
      <LeftPane
        contentWidth={1200}
        secondary
      >
        <Breadcrumbs>
          <Breadcrumb link="/data-input">Data Sources</Breadcrumb>
          <Breadcrumb>
            Integrations
          </Breadcrumb>
        </Breadcrumbs>
        <SectionLabel>
          Integrations
        </SectionLabel>
        <Box>
          <SectionLabel secondary>
            General Ledger Integrations
            <Tooltip
              pullLeft
              title="Select an integration from the list below. You can only have one general ledger (GL) integration at a time."
            />
          </SectionLabel>
          {glIntegrations.map(({
            id,
            title,
            description,
            chipText,
            disabled,
            icon,
            onConnect,
            onSync,
            connectLabel,
            onViewSettings,
            onClickDocumentation,
            onDisconnect,
          }) => (
            <IntegrationsItem
              key={id}
              title={title}
              description={description}
              icon={icon}
              chipText={chipText}
              disabled={disabled}
              isInitiallyOpen={activeIntegration?.id === id || callback === id}
              isActive={activeIntegration?.id === id}
            >
              {userRole && [UserRole.ADMIN, UserRole.OWNER, UserRole.SUPERUSER].includes(userRole)
                ? (
                  <ButtonsContainer pushRight>
                    {onClickDocumentation && (
                      <Button
                        variant="borderless"
                        color="transparent"
                        onClick={onClickDocumentation}
                      >
                        <OpenInNewIcon />
                        View developer documentation
                      </Button>
                    )}
                    {onViewSettings && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={onViewSettings}
                      >
                        View settings
                      </Button>
                    )}
                    {activeIntegration?.id === id && onSync && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onSync}
                      >
                        Sync Data...
                      </Button>
                    )}
                    {activeIntegration?.id === id
                      ? (
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={onDisconnect}
                          disabled={isDisconnecting}
                          isLoading={isDisconnecting}
                        >
                          Disconnect
                        </Button>
                      )
                      : (
                        <Button
                          onClick={onConnect}
                          disabled={isSendingRequest || isVeryfingConnection}
                          isLoading={isSendingRequest || isVeryfingConnection}
                        >
                          {connectLabel || 'Connect'}
                        </Button>
                      )}
                  </ButtonsContainer>
                )
                : 'Only admin and owner of the company are allowed to manage integrations.'
              }
            </IntegrationsItem>
          ))}
        </Box>
        <HotglueConfig config={hotglueConfig}>
          <StyledHotglueContainer>
            <SectionLabel secondary>
              CRM Integrations
              <Tooltip
                pullLeft
                title="Select an integration from the list below."
              />
            </SectionLabel>
            {currentCompany?.id && (
              <IntegrationsItem
                key="salesforce"
                title="Salesforce"
                description="Integration with Salesforce"
                icon={<SalesforceIcon />}
                disabled={false}
                isInitiallyOpen={false}
                isActive={integrationsData?.includes('salesforce')}
              >
                {userRole && [UserRole.ADMIN, UserRole.OWNER, UserRole.SUPERUSER].includes(userRole)
                  ? (
                    <ButtonsContainer pushRight>
                      <Connections
                        tenant={currentCompany?.id}
                        filterFlow={(flow) => flow === SalesforceFlowId}
                        hideBackButtons={true}
                      />
                      {integrationsData?.includes('salesforce') && (
                        <Button
                          variant="contained"
                          color="primary"
                          // TODO Add link to mappings on next merge
                          onClick={() => { }}
                          disabled
                        >
                          View Integration
                        </Button>
                      )}
                    </ButtonsContainer>
                  )
                  : 'Only admin and owner of the company are allowed to manage integrations.'
                }
              </IntegrationsItem>
            )}
          </StyledHotglueContainer>
        </HotglueConfig>
      </LeftPane>
      <Modal
        isOpen={rightPanes.syncQbo}
        onClose={() => resetPanes()}
        suppressOverflow
        maxWidth={700}
      >
        <QBOSync onClose={() => resetPanes()} />
      </Modal>
    </>
  );
};
