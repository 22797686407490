import { BulkImportContractsRow } from "interfaces/bulkImportContracts";
import { BulkImportSubscriptionsRow } from "interfaces/bulkImportSubscriptions";
import { CompanyRevenueType } from "interfaces/company";
import { CustomerUpdatePayload } from "interfaces/customers";
import {
  ProductUpdatePayload,
  RevenueType,
} from "interfaces/products";
import {
  TagType,
  TagUpdatePayload,
} from "interfaces/tags";
import { InvalidEntity } from "store/slices/bulkImport";
import {
  UNPIVOT_TEMPLATE_FILENAME,
  CONTRACTS_IMPORT_TEMPLATE_FILENAME,
  IMPORT_TEMPLATE_FILENAME,
} from "./constants";
import { generateTimestampQuery } from "./files";

export const prepareCustomersToCreate = (invalidCustomers: InvalidEntity[]): CustomerUpdatePayload[] => {
  return invalidCustomers
    .filter((product) => product.createNew)
    .map((customer) => {
      return {
        name: customer.name || '',
        crmId: null,
        tagIds: [],
      };
    });
};

export const prepareProductsToCreate = (invalidProducts: InvalidEntity[]): ProductUpdatePayload[] => {
  return invalidProducts
    .filter((product) => product.createNew)
    .map((product) => {
      return {
        name: product.name || '',
        crmId: null,
        description: '',
        revenueType: product.revenueType?.id as RevenueType,
        tagIds: [],
      };
    });
};

export const prepareTagsToCreate = (invalidTags: InvalidEntity[]): TagUpdatePayload[] => {
  return invalidTags
    .filter((tag) => tag.createNew)
    .map((tag) => {
      return {
        name: tag.name || '',
        description: null,
        type: TagType.CONTRACT,
      };
    });
};

export const prepareValidatedSubscriptions = (rows: BulkImportSubscriptionsRow[], invalidCustomers?: InvalidEntity[], invalidProducts?: InvalidEntity[], invalidTags?: InvalidEntity[]) => {
  return rows.map((row) => {
    const productName = invalidProducts?.find((prod) => prod.name?.toLowerCase() === row.product.providedValue?.toLowerCase())?.entity.name || row.product.providedValue;
    const customerName = invalidCustomers?.find((cust) => cust.name?.toLowerCase() === row.customer.providedValue?.toLowerCase())?.entity.name || row.customer.providedValue;

    const providedTagsArray = [...new Set(row.tags.providedValue?.split(',')
      .map((tagName) => tagName.trim()))];

    const tags = [...new Set(providedTagsArray?.map(
      (tagName) => row.tags.matchedValue?.find((tag) => tag.name?.toLowerCase() === tagName.toLowerCase())?.name // if matched tag
          || invalidTags?.find((tag) => !tag.createNew && tag.name?.toLowerCase() === tagName.toLowerCase())?.entity.name // if tag has been created
          || invalidTags?.find((tag) => tag.createNew && tag.name?.toLowerCase() === tagName.toLowerCase())?.name // if tag has been assigned
          || null,
    ))];

    return {
      amount: row.amount.providedValue!,
      bookingDate: row.bookingDate.providedValue,
      cancelDate: row.cancelDate.providedValue,
      revRecStartDate: row.revRecStartDate.providedValue,
      revRecEndDate: row.revRecEndDate.providedValue,
      crmId: row.crmId.providedValue,
      customerName,
      endDate: row.endDate.providedValue!,
      externalLink: null,
      subscriptionName: row.subscriptionName.providedValue!,
      notes: null,
      productName,
      renewedSubscription: row.renewedSubscription.providedValue,
      startDate: row.startDate.providedValue!,
      tags: tags?.toString() || null,
    };
  });
};

export const prepareValidatedContracts = (
  rows: BulkImportContractsRow[],
  updatedRows?: BulkImportContractsRow[],
  invalidCustomers?: InvalidEntity[],
  invalidProducts?: InvalidEntity[],
  invalidTags?: InvalidEntity[],
) => {
  return rows.map((thisRow) => {
    const updatedRow = updatedRows?.find((r) => r.id === thisRow.id);
    const row = updatedRow || thisRow;

    const contractLineProduct = invalidProducts
      ?.find((prod) => prod.name?.toLowerCase() === row.contractLineProduct.providedValue?.toLowerCase())
      ?.entity.name || row.contractLineProduct.providedValue;

    const contractCustomer = invalidCustomers
      ?.find((cust) => cust.name?.toLowerCase() === row.contractCustomer.providedValue?.toLowerCase())
      ?.entity.name || row.contractCustomer.providedValue;

    const providedContractTagsArray = [...new Set(row.contractTags.providedValue?.split(',')
      .map((tagName) => tagName.trim()))];

    const contractTags = [...new Set(providedContractTagsArray?.map(
      (tagName) => row.contractTags.matchedValue?.find((tag) => tag.name?.toLowerCase() === tagName.toLowerCase())?.name // if matched tag
        || invalidTags?.find((tag) => !tag.createNew && tag.name?.toLowerCase() === tagName.toLowerCase())?.entity.name // if tag has been created
        || invalidTags?.find((tag) => tag.createNew && tag.name?.toLowerCase() === tagName.toLowerCase())?.name // if tag has been assigned
        || null,
    ))];

    const providedContractLineTagsArray = [...new Set(row.contractLineTags.providedValue?.split(',')
      .map((tagName) => tagName.trim()))];

    const contractLineTags = [...new Set(providedContractLineTagsArray?.map(
      (tagName) => row.contractLineTags.matchedValue?.find((tag) => tag.name?.toLowerCase() === tagName.toLowerCase())?.name // if matched tag
        || invalidTags?.find((tag) => !tag.createNew && tag.name?.toLowerCase() === tagName.toLowerCase())?.entity.name // if tag has been created
        || invalidTags?.find((tag) => tag.createNew && tag.name?.toLowerCase() === tagName.toLowerCase())?.name // if tag has been assigned
        || null,
    ))];

    return {
      contractName: row.contractName.providedValue,
      contractCustomer,
      contractBookingDate: row.contractBookingDate.providedValue,
      contractStartDate: row.contractStartDate.providedValue,
      contractEndDate: row.contractEndDate.providedValue,
      contractCancelDate: row.contractCancelDate.providedValue,
      contractTags: contractTags?.toString() || null,
      contractCRMID: row.contractCRMID.providedValue,
      contractExternalLink: row.contractExternalLink.providedValue,
      contractNote: row.contractNote.providedValue,
      contractPrevious: row.contractPrevious.providedValue,
      contractLineName: row.contractLineName.providedValue,
      contractLineProduct,
      contractLineAmount: row.contractLineAmount.providedValue,
      contractLineBookingDate: row.contractLineBookingDate.providedValue,
      contractLineStartDate: row.contractLineStartDate.providedValue,
      contractLineEndDate: row.contractLineEndDate.providedValue,
      contractLineCancelDate: row.contractLineCancelDate.providedValue,
      contractLineRevRecStartDate: row.contractLineRevRecStartDate.providedValue,
      contractLineRevRecEndDate: row.contractLineRevRecEndDate.providedValue,
      contractLineTags: contractLineTags?.toString() || null,
      contractLineExternalLink: row.contractLineExternalLink.providedValue,
      contractLineNote: row.contractLineNote.providedValue,
      contractLineCRMID: row.contractLineCRMID.providedValue,
      contractLineContractBasedMRRCalc: row.contractLineContractBasedMRRCalc.providedValue,
    };
  });
};

export const handleDownloadTemplate = (type: 'standard' | 'unpivot', companyRevenueType: CompanyRevenueType) => {
  let template = '';

  if (type === 'unpivot') {
    template = UNPIVOT_TEMPLATE_FILENAME;
  }

  if (type === 'standard') {
    template = companyRevenueType === CompanyRevenueType.CONTRACTS
      ? CONTRACTS_IMPORT_TEMPLATE_FILENAME
      : IMPORT_TEMPLATE_FILENAME;
  }

  window.open(import.meta.env.VITE_APP_CDN_URL + template + generateTimestampQuery());
};
