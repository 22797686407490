import styled from "styled-components";
import RefreshIcon from "./RefreshIcon";

const Icon = styled(RefreshIcon)`
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-359deg);
    }
  }

  animation: rotation 2s infinite linear;
`;

export default Icon;
