import React from 'react';
import { DeleteItems } from 'components/DeleteItems';
import { useDeleteTagsMutation } from 'store/services/tags';
import { Tags } from 'interfaces/tags';

interface Props {
  onClose: () => void;
  selectedRows: Tags;
}

export const DeleteTags = ({
  onClose,
  selectedRows,
}: Props) => {
  const [deleteTags, { isLoading }] = useDeleteTagsMutation();

  const handleDeleteTags = () => {
    const ids = selectedRows.map((tag) => tag.id);
    deleteTags(ids)
      .unwrap()
      .then(() => {
        onClose();
      });
  };

  return (
    <DeleteItems
      selectedRows={selectedRows}
      onClose={onClose}
      isLoading={isLoading}
      handleDelete={handleDeleteTags}
      item="Tag"
    />
  );
};
