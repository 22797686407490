import { CompanyRevenueType } from "interfaces/company";
import {
  Tag,
  TagFormFields,
  TagUpdatePayload,
} from "interfaces/tags";
import {
  TagTypeOptionsContracts,
  TagTypeOptionsSubscriptions,
} from "./constants";

export const prepareTagPayload = (data: TagFormFields): TagUpdatePayload => {
  return {
    id: data.id,
    name: data.name,
    description: data.description || null,
    type: data.type.at(0)!.value,
  };
};

export const tagsSortFn = (valueA: Tag, valueB: Tag ) => valueA?.name.toLowerCase()
  .localeCompare(valueB?.name.toLowerCase());

export const getTagsIdsForSelectedRows = <T extends any[]>(selectedRows: T, key?: string) => selectedRows
  ?.flatMap((entity) => (key
    ? entity[key].tags as Tag[]
    : entity.tags as Tag[]
  ).map((tag) => tag.id)) || [];

export const getTagTypesOptions = (companyRevenueType?: CompanyRevenueType) => {
  switch (companyRevenueType) {
    case CompanyRevenueType.SUBSCRIPTIONS:
      return TagTypeOptionsSubscriptions;
      case CompanyRevenueType.CONTRACTS:
        return TagTypeOptionsContracts;
    default:
      return [];
  }
};