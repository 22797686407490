import styled from "styled-components";
import { theme } from "theme/theme";

interface Props {
  error: string;
};

const StyledErrorDisplay = styled.p`
  padding-top: 8px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: ${theme.colors.error};
`;

export const ErrorDisplay = ({ error }: Props) => {
  return (
    <StyledErrorDisplay>
      {error}
    </StyledErrorDisplay>
  );
};