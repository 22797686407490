import { DeleteItems } from "components/DeleteItems";
import { useDeleteCompaniesMutation } from "store/services/companies";
import { toastSuccess } from "utils/toast";

interface Props {
  selectedRows: any[];
  onClose: () => void;
}

export const DeleteCompanies = ({
  selectedRows,
  onClose,
}: Props) => {
  const [deleteCompanies, { isLoading: isDeletingCompany }] = useDeleteCompaniesMutation();
  const numberOfCompanies = selectedRows.length;

  const handleCompanyDelete = () => {
    deleteCompanies(selectedRows.map(({ id }) => id))
      .unwrap()
      .then(() => {
        toastSuccess('Company(ies) has been deleted.');
        onClose();
      });
  };

  return (
    <DeleteItems
      onClose={onClose}
      isLoading={isDeletingCompany}
      handleDelete={handleCompanyDelete}
      item="Company"
      customDescription={
        <>
          <p>
            We will delete your {numberOfCompanies > 1 ? 'companies' : 'company'}. You have 15 days to restore access to Insights, after which it will be permanently deleted.
            Reach out to support to reinstate access to Insights.
          </p>
          <p>Are you sure you would like to delete {numberOfCompanies > 1 ? 'these companies' : 'this company'}?</p>
        </>
      }
    />
  );
};