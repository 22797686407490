import { format } from "date-fns";
import {
  ChartBackendResponse,
  ChartData,
  ChartDataLabel,
} from "interfaces/charts";
import { isoToDate } from "./dates";

export const defaultChartData: ChartBackendResponse = {
  name: "Loading...",
  data: [
    {
      date: '2023-01-01',
      dataValues: [],
    },
  ],
  chartDataCategories: [],
  chartDataValueLabels: [],
  defaultCategoryId: '',
  chartConfig: {
    valueFormat: 'currency',
    chartPeriodType: 'monthly',
  },
};

export const chartDateFormatter = (value: string) => {
  if (value.at(0) === 'Q' || value.at(1) === 'Y') {
    return value;
  }
  return format(isoToDate(value)!, "MMM ''yy");
};

export const prepareDataObject = (data: ChartData[], labels: ChartDataLabel[], rangeStart: number, rangeEnd: number): any => {
  const barLabelsIds = labels.filter((label) => label.chartType === 'bar')
    .map((label) => label.id);
  const lineLabelsIds = labels.filter((label) => label.chartType === 'line')
    .map((label) => label.id);

  return data.slice(rangeStart, rangeEnd + 1)
    .map((entry) => ({
      date: entry.date,
      values: entry.dataValues.reduce((a, v) => ({
        ...a,
        [v.chartDataValueLabelId]: v.value,
      }), {}),
      sum: entry.dataValues.reduce((a, b) => a + b.value, 0),
      sumBars: entry.dataValues.reduce((a, b) => a + (barLabelsIds.includes(b.chartDataValueLabelId) ? b.value : 0), 0),
      sumLines: entry.dataValues.reduce((a, b) => a + (lineLabelsIds.includes(b.chartDataValueLabelId) ? b.value : 0), 0),
    }));
};
