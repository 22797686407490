import { filterDateComparator } from "./aggrid";

export const multiFilter = {
  filter: 'agMultiColumnFilter',
  filterParams: {
    buttons: ['reset'],
    filters: [
      {
        filter: 'agTextColumnFilter',
        filterParams: {
          buttons: ['reset'],
        },
      },
      {
        filter: 'agSetColumnFilter',
        filterParams: {
          buttons: ['reset'],
          suppressMiniFilter: true,
        },
      },
    ],
  },
};

export const dateFilter = {
  filter: 'agDateColumnFilter',
  filterParams: {
    comparator: filterDateComparator,
    buttons: ['reset'],
    inRangeInclusive: true,
  },
};

export const numberFilter = {
  filter: 'agNumberColumnFilter',
  filterParams: {
    buttons: ['reset'],
  },
};
