import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { StatusOption } from "interfaces/common";
import { ContractRenewalLine } from "interfaces/contractLines";
import {
  Contract,
  Contracts,
  ContractsUnlinkPayload,
} from "interfaces/contracts";

interface State {
  predefinedContract: Contract ;
  previousContracts: Contracts;
  renewalLines: ContractRenewalLine[];
  predefinedContractName: string;
  unlinkPayload: ContractsUnlinkPayload;
}

const initialState: State = {
  predefinedContract: {
    id: '',
    name: '',
    customer: {
      id: '',
      name: '',
      deleted: false,
    },
    bookingDate: '',
    startDate: '',
    endDate: '',
    renewalDate: '',
    cancelDate: '',
    tags: [],
    crmId: '',
    externalLink: '',
    notes: '',
    tcv: 0,
    mrr: 0,
    arr: 0,
    deleted: false,
    subscriptionBased: false,
    status: StatusOption.RENEWAL,
    previous: [],
    next: [],
  },
  previousContracts: [],
  renewalLines: [] as any,
  predefinedContractName: '',
  unlinkPayload: {} as ContractsUnlinkPayload,
};

const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    updatePredefinedContract: (state, action: PayloadAction<Contract>) => {
      state.predefinedContract = action.payload;
    },
    updatePreviousContracts: (state, action: PayloadAction<Contracts>) => {
      state.previousContracts = action.payload;
    },
    setContractRenewalLines: (state, action: PayloadAction<ContractRenewalLine[]>) => {
      state.renewalLines = action.payload;
    },
    updateContractRenewalLine: (state, action: PayloadAction<ContractRenewalLine>) => {
      const isNew = !state.renewalLines.find((line) => line.id === action.payload.id);
      state.renewalLines = isNew
        ? [...state.renewalLines, action.payload]
        : state.renewalLines
          .map((line) => line.id === action.payload.id ? action.payload : line);
    },
    setContractUnlinkPayload: (state, action: PayloadAction<ContractsUnlinkPayload>) => {
      state.unlinkPayload = action.payload;
    },
    clear: () => initialState,
  },
});

export const {
  reducer: contractsReducer,
  actions: contractsActions,
} = contractsSlice;
