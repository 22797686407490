import React from 'react';
import { InfoIcon } from 'assets/icons';
import { ButtonsContainer } from '../ButtonsContainer';
import { Hint } from '../Hint';
import { SectionLabel } from '../SectionLabel';
import { Button } from '../Button';

interface Props {
  isDraftApplicable: boolean;
  onApplyDraft: () => void;
  onDismissDraft: () => void;
}

export const DraftFound = ({
  isDraftApplicable,
  onApplyDraft,
  onDismissDraft,
}: Props) => {
  return (
    <>
      <header>
        <SectionLabel>
          Unsaved version found
        </SectionLabel>
      </header>
      <main>
        <Hint>
          <InfoIcon />
          <div>
            <p>We've found unsaved version of your previous import.</p>
            {isDraftApplicable && <p>Hit apply to bring it back and continue or dismiss to start over.</p>}
            {!isDraftApplicable && <p>Unfortunately, it cannot be applied because it does not match accounts currently in the system. Possibly, some GL accounts have been either added or removed in the meantime.</p>}
          </div>
        </Hint>
      </main>
      <footer>
        <ButtonsContainer pushRight>
          {isDraftApplicable && (
            <Button
              variant="contained"
              onClick={onApplyDraft}
            >
              APPLY
            </Button>
          )}
          <Button
            variant="outlined"
            color="error"
            onClick={onDismissDraft}
          >
            DISMISS
          </Button>
        </ButtonsContainer>
      </footer>
    </>
  );
};
