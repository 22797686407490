import React, { useState } from 'react';
import { RadioInput } from 'components/common/RadioInput';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { Button } from 'components/common/Button';
import {
  DecimalsLeftIcon,
  DecimalsRightIcon,
} from 'assets/icons';
import {
  StyledButtonContainer,
  StyledContainer,
  StyledFormContainer,
} from 'components/common/Forms/styled';
import { Label } from 'components/common/Input/Label';
import { StyledDecimals } from './styled';
import { toastSuccess } from 'utils/toast';
import { Metric } from 'interfaces/metrics';
import { useUpdateMetricsFormatMutation } from 'store/services/metrics';
import { SectionLabel } from 'components/common/SectionLabel';

interface Props {
  metric: Metric;
  onSave: () => void;
}

export const MetricFormat = ({ metric, onSave }: Props) => {
  const [precision, setPrecision] = useState(2);
  const [udpateMetricsFormat] = useUpdateMetricsFormatMutation();

  const MetricFormats = [
    {
      name: 'Currency',
      value: 'currency',
      annotation: `$0${precision ? '.' : ''}${Array(precision)
        .fill(0)
        .join('')}`,
    },
    {
      name: 'Number',
      value: 'number',
      annotation: `0${precision ? '.' : ''}${Array(precision)
        .fill(0)
        .join('')}`,
    },
    {
      name: 'Percentage',
      value: 'percentage',
      annotation: `0${precision ? '.' : ''}${Array(precision)
        .fill(0)
        .join('')}%`,
    },
    {
      name: 'Multiple',
      value: 'multiple',
      annotation: `0${precision ? '.' : ''}${Array(precision)
        .fill(0)
        .join('')}x`,
    },
  ];

  const {
    handleSubmit,
    control,
    getValues,
  } = useForm<{ format: { name: string; value: string; annotation: string; } }>({
    mode: 'onChange',
    defaultValues: {
      format: MetricFormats[0], // TODO Get from metric details, when ready
    },
  });

  const handleSaveAndClose = handleSubmit((data) => {
    udpateMetricsFormat({
      metricsIds: [metric.id],
      precision: precision,
      valueFormat: data.format.value,
    })
      .unwrap()
      .then(() => {
        toastSuccess('Metric successfully saved.');
        onSave();
      });
  });

  return (
    <StyledContainer>
      <SectionLabel tertiary>
        Format
      </SectionLabel>
      <StyledFormContainer>
        <Controller
          name="format"
          control={control}
          render={({ field }) =>
            <RadioInput
              labelText=""
              valueField="value"
              labelField="name"
              annotationField="annotation"
              defaultValue={getValues().format}
              options={MetricFormats}
              {...field}
            />
          }
        />
        <div>
          <Label
            name="decimals"
            labelText="Select Decimal Format"
          />
          <StyledDecimals>
            <ButtonsContainer>
              <Button
                type="button"
                variant="icon"
                disabled={precision === 0}
                onClick={() => setPrecision(Math.max(precision - 1, 0))}
              >
                <DecimalsLeftIcon />
              </Button>
              <Button
                type="button"
                variant="icon"
                disabled={precision === 2}
                onClick={() => setPrecision(Math.min(precision + 1, 2))}
              >
                <DecimalsRightIcon />
              </Button>
            </ButtonsContainer>
          </StyledDecimals>
        </div>
      </StyledFormContainer>
      <StyledButtonContainer pushRight>
        <Button
          type="button"
          onClick={handleSaveAndClose}
        >
          SAVE & CLOSE
        </Button>
      </StyledButtonContainer>
    </StyledContainer>
  );
};
