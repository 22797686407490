import styled from "styled-components";
import { theme } from "theme/theme";

export const StyledSummaryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;

  & > div {
    border: 1px solid ${theme.colors.borderColorDark};
    border-radius: 8px;
    padding: 24px;
    min-height: 160px;
  }

  + div {
    margin-top: 40px;
  }
`;

export const StyledPlaceholderItem = styled.div`
  @keyframes backgroundColor {
    0% {
      background-color: ${theme.colors.white};
    }
    50% {
      background-color: ${theme.colors.borderColorLight};
    }
    100% {
      background-color: ${theme.colors.white};
    }
  }

  border: none !important;
  border-radius: 8px;
  padding: 24px;
  min-height: 160px;
  animation-name: backgroundColor;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;

  &:nth-of-type(1) {
    animation-delay: 100ms;
  }
  &:nth-of-type(2) {
    animation-delay: 200ms;
  }
  &:nth-of-type(3) {
    animation-delay: 300ms;
  }
`;

export const StyledSummaryItemLabel = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.textDark};
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;

  button {
    margin-left: 8px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const StyledSummaryItemValue = styled.div`
  color: ${theme.colors.primary};
  font-size: 34px;
  line-height: 51px;
  font-weight: 600;
  margin: 8px 0;
`;

export const StyledSummaryItemDate = styled.div`
  color: ${theme.colors.textDisabled};
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
`;
