import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ChevronDown,
  CloseIcon,
  DeleteIcon,
} from 'assets/icons';
import { FactaTable } from 'components/FactaTable';
import { SectionLabel } from 'components/common/SectionLabel';
import { Button } from 'components/common/Button';
import { AgGridReact } from '@ag-grid-community/react';
import {
  ColDef,
  ICellRendererParams,
} from '@ag-grid-community/core';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import {
  PeriodButton,
  StyledButtonInnerText,
  StyledDeleteContainer,
  StyledVersion,
  VersionButton,
} from './styled';
import { ContextMenu } from 'components/ContextMenu';
import { formatDateStringToMMMYearDisplay } from 'utils/dates';
import { useGetReportVersionsQuery } from 'store/services/reports';
import { AddMultipleColumns } from '../ReportConfiguration/AddMultipleColumns';

interface Props {
  reportId: string;
  columnsState: any;
  onClose: () => void;
  onSort: (newOrder: string[]) => void;
  onRemoveColumns: (colIds: string[]) => void;
  onClickChangePeriod: (target: any, col: string) => void;
  onClickChangeVersion: (target: any, col: string) => void;
  isRemovingColumns?: boolean;
  columnsBeingRemovedIds: string[];
}

export const EditColumns = ({
  reportId,
  columnsState,
  onClose,
  onSort,
  onRemoveColumns,
  onClickChangePeriod,
  onClickChangeVersion,
  isRemovingColumns,
  columnsBeingRemovedIds,
}: Props) => {
  const gridRef = useRef<AgGridReact>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [view, setView] = useState<'Table Columns' | 'Column Data' | 'Add Multiple Columns'>('Table Columns');
  const { data: versions } = useGetReportVersionsQuery();

  const columnDefs: ColDef[] = [
    {
      field: 'period',
      headerName: 'Table Columns',
      rowDrag: true,
      checkboxSelection: true,
      flex: 1,
      cellRenderer: ({ value, data }: any) => (
        <div>
          {(value?.length === 10 && !value.includes('FY'))
            ? formatDateStringToMMMYearDisplay(value)
            : value || 'No period'}
          <StyledVersion>{versions?.find(({ id }) => id === data.version)?.name || 'No version'}</StyledVersion>
        </div>
      ),
      hide: view !== 'Table Columns',
    },
    {
      field: 'visible',
      hide: true,
    },
    {
      field: 'period',
      flex: 1,
      cellRenderer: ({ value, data, eGridCell }: ICellRendererParams) => (
        <PeriodButton
          size="large"
          color={value ? 'secondary' : 'error'}
          variant="outlined"
          fullWidth
          onClick={() => onClickChangePeriod(eGridCell, data.id)}
        >
          <StyledButtonInnerText>
            {(value?.length === 10 && !value?.includes('FY'))
              ? formatDateStringToMMMYearDisplay(value)
              : value || 'Select'}
          </StyledButtonInnerText>
          <ChevronDown />
        </PeriodButton>
      ),
      cellClass: 'cell-flex',
      hide: view !== 'Column Data',
    },
    {
      field: 'version',
      flex: 1,
      cellRenderer: ({ value, data, eGridCell }: ICellRendererParams) => (
        <VersionButton
          size="large"
          color="secondary"
          variant="outlined"
          fullWidth
          onClick={() => onClickChangeVersion(eGridCell, data.id)}
        >
          <StyledButtonInnerText>
            {versions?.find(({ id }) => id === value)?.name || 'Select'}
          </StyledButtonInnerText>
          <ChevronDown />
        </VersionButton>
      ),
      cellClass: 'cell-flex',
      hide: view !== 'Column Data',
    },
    {
      field: 'delete',
      headerName: '',
      maxWidth: 32,
      type: 'rightAligned',
      cellRenderer: ({ data }: ICellRendererParams) => (
        <StyledDeleteContainer>
          <Button
            variant="icon"
            color="secondary"
            onClick={() => onRemoveColumns([data.id])}
            disabled={columnsBeingRemovedIds?.includes(data.id) && isRemovingColumns}
          >
            <DeleteIcon />
          </Button>
        </StyledDeleteContainer>
      ),
      cellClass: 'custom-header',
      suppressMenu: true,
      hide: view !== 'Table Columns' || !!selectedRows.length,
    },
  ];

  const onRowDragEnd = () => {
    let items: any[] = [];
    gridRef.current?.api.forEachNode((node) => {
      items.push(node.data.id);
    });
    onSort(items);
  };

  useEffect(() => {
    gridRef.current?.api?.deselectAll();
  }, [view]);

  return (
    <>
      <header>
        <ButtonsContainer paddingBottom={16}>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={onClose}
            pushRight
            data-cy="at-button-close"
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <SectionLabel>
          <span>
            Edit Columns
            <p>Select columns you would like to see in the table. Drag and drop the rows to reorder table columns.</p>
          </span>
        </SectionLabel>
      </header>
      <main>
        <ButtonsContainer paddingBottom={4}>
          <ContextMenu
            items={[
              {
                name: 'Table Columns',
                action: () => setView('Table Columns'),
              },
              {
                name: 'Column Data',
                action: () => setView('Column Data'),
              },
              {
                name: 'Add Multiple Columns...',
                action: () => setView('Add Multiple Columns'),
              },
            ]}
            label={view}
            customIcon={<ChevronDown />}
            color="secondary"
          />
          <Spacer />
          <Button
            variant="borderless"
            color="primary"
            size="large"
            hidden={!selectedRows.length}
            onClick={() => onRemoveColumns(selectedRows.map((row) => row.id))}
            disabled={isRemovingColumns}
          >
            <DeleteIcon />
            Delete selected
          </Button>
        </ButtonsContainer>
        {view === 'Add Multiple Columns' && (
          <AddMultipleColumns
            reportId={reportId}
            onClose={() => setView('Table Columns')}
            simpleView
          />
        )}
        {view !== 'Add Multiple Columns' && (
          <FactaTable
            gridRef={gridRef}
            data={columnsState}
            gridOptions={{
              onSelectionChanged: (e) => {
                const selectedRows = e.api.getSelectedRows();
                setSelectedRows(selectedRows);
              },
              rowDragManaged: true,
              enableRangeSelection: false,
              suppressRowClickSelection: true,
              suppressRowHoverHighlight: true,
              suppressCellFocus: true,
              headerHeight: 0,
              onRowDragEnd: onRowDragEnd,
              animateRows: false,
            }}
            columnDefs={columnDefs}
            selectedRowsLength={0}
            entityName="column"
            condensed
            noBordersAndFocus
          />
        )}
      </main>
      <footer />
    </>
  );
};
