import React, { ReactElement } from 'react';
import {
  ArrowLeftIcon,
  CloseIcon,
} from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { SectionLabel } from 'components/common/SectionLabel';
import { Button } from 'components/common/Button';

interface Props<T> {
  selectedRows?: T[];
  totalSelected?: number;
  onClose: () => void;
  onGoBack?: () => void;
  isLoading: boolean;
  handleDelete: () => void;
  item: string;
  shortenDescription?: boolean;
  customDescription?: ReactElement;
  deleteWord?: string;
}

export const DeleteItems = <T extends object | string>({
  selectedRows,
  totalSelected,
  onClose,
  onGoBack,
  isLoading,
  handleDelete,
  item,
  shortenDescription = false,
  customDescription,
  deleteWord = 'Delete',
}: Props<T>) => {
  const testVariable = item.at(0);
  const lowerCasedItem = item.toLowerCase();
  const numberOfSelectedIds = totalSelected || selectedRows?.length || 0;

  return (
    <>
      <header>
        <ButtonsContainer paddingBottom={16}>
          {onGoBack && (
            <Button
              aria-label="Go back"
              variant="icon"
              size="large"
              onClick={onGoBack}
              data-cy={`d${testVariable}-button-close`}
            >
              <ArrowLeftIcon />
            </Button>
          )}
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={onClose}
            pushRight
            data-cy={`d${testVariable}-button-close`}
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <SectionLabel data-cy={`d${testVariable}-header`}>
          {deleteWord} {numberOfSelectedIds > 1 && numberOfSelectedIds} {(item)}{numberOfSelectedIds > 1 && 's'}
        </SectionLabel>
      </header>
      <main>
        {customDescription
          ? customDescription
          : numberOfSelectedIds > 1
            ? (
              <>
                {!shortenDescription && <p>Deleting these {lowerCasedItem}s will remove them from all subscriptions.</p>}
                <p>Are you sure you would like to {deleteWord.toLowerCase()} these {lowerCasedItem}s?</p>
              </>
            )
            : (
              <>
                {!shortenDescription && <p>Deleting this {lowerCasedItem} will remove it from all subscriptions.</p>}
                <p>Are you sure you would like to {deleteWord.toLowerCase()} this {lowerCasedItem}?</p>
              </>
            )
        }
      </main>
      <footer>
        <ButtonsContainer fillRow>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            disabled={isLoading}
            data-cy={`d${testVariable}-button-cancel`}
          >
            CANCEL
          </Button>
          <Button
            color="error"
            onClick={handleDelete}
            disabled={isLoading}
            isLoading={isLoading}
            data-cy={`d${testVariable}-button-delete`}
          >
            {deleteWord.toUpperCase()}
          </Button>
        </ButtonsContainer>
      </footer>
    </>
  );
};
