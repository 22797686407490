import React, { memo } from "react";
import {
  ValueType,
  NameType,
  Payload,
} from "recharts/types/component/DefaultTooltipContent";
import { theme } from "theme/theme";
import { CartesianViewBox } from "recharts/types/util/types";
import {
  StyledTooltipHeader,
  StyledTooltipIcon,
  StyledTooltipLabel,
  StyledTooltipLine,
  StyledTooltipValue,
  StyledTooltipWrapper,
} from "./styled";
import { ChartBackendResponse } from "interfaces/charts";
import { chartDateFormatter } from "utils/charts";

interface Props {
  label: any;
  payload: Payload<ValueType, NameType>[] | undefined;
  viewBox: CartesianViewBox | undefined;
  chartData: ChartBackendResponse;
  simple?: boolean;
  hoveredBarElement?: string;
  valueFormatter: (value: number) => string;
}
const CustomTooltipComponent = ({
  label,
  payload,
  viewBox,
  chartData,
  simple,
  hoveredBarElement,
  valueFormatter,
}: Props) => {
  const hiddenValues = chartData.data.find((e) => e.date === label)
    ?.dataValues.filter((e) => e.chartDataValueLabelId === 'hidden')
    .map((e) => ({
      name: chartData.chartDataValueLabels.find((f) => f.id === e.chartDataValueLabelId)?.name,
      value: e.value,
    }));

  const labelList = simple
    ? payload?.filter((label) => label.dataKey === hoveredBarElement)
    : payload;

  return <StyledTooltipWrapper style={{ maxHeight: viewBox?.height || 'unset'}}>
    <StyledTooltipHeader>
      <StyledTooltipLabel>Period</StyledTooltipLabel>
      <StyledTooltipValue>{label ? chartDateFormatter(label) : ''}</StyledTooltipValue>
    </StyledTooltipHeader>
    {labelList?.map((subValue) => (
      <StyledTooltipLine key={`tooltip_${subValue.name}`}>
        <StyledTooltipIcon style={{ backgroundColor: subValue.color }}></StyledTooltipIcon>
        <StyledTooltipLabel>{subValue.name}</StyledTooltipLabel>
        <StyledTooltipValue>{valueFormatter(subValue.value as number)}</StyledTooltipValue>
      </StyledTooltipLine>
    ))}
    {hiddenValues?.map((subValue) => (
      <StyledTooltipLine key={`tooltip_${subValue.name}`}>
        <StyledTooltipIcon style={{ backgroundColor: theme.colors.secondaryBackground }}></StyledTooltipIcon>
        <StyledTooltipLabel>{subValue.name}</StyledTooltipLabel>
        <StyledTooltipValue>{valueFormatter(subValue.value as number)}</StyledTooltipValue>
      </StyledTooltipLine>
    ))}
  </StyledTooltipWrapper>;
};

export const CustomTooltip = memo(CustomTooltipComponent);
