import { ColDef } from '@ag-grid-community/core';
import { caseInsensitiveComparator } from "utils/aggrid";
import { Tag } from "interfaces/tags";
import { multiFilter } from 'utils/filters';

export const getColumnDefs = (): Array<ColDef> => ([
  {
    field: 'id',
    headerName: 'Id',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    cellRenderer: () => null,
    maxWidth: 70,
    pinned: 'left',
  },
  {
    field: 'name',
    headerName: 'Product Name',
    sortable: true,
    resizable: true,
    flex: 1,
    minWidth: 250,
    comparator: caseInsensitiveComparator(),
    ...multiFilter,
  },
  {
    field: 'description',
    headerName: 'Description',
    sortable: true,
    resizable: true,
    flex: 2,
    minWidth: 250,
    valueFormatter: ({ value }) => value ? value : '-',
    comparator: caseInsensitiveComparator(),
    ...multiFilter,
  },
  {
    field: 'revenueType',
    headerName: 'Revenue Type',
    sortable: true,
    resizable: true,
    minWidth: 250,
    comparator: caseInsensitiveComparator(),
    enableRowGroup: true,
    ...multiFilter,
  },
  {
    field: 'tags',
    headerName: 'Tags',
    sortable: true,
    resizable: true,
    minWidth: 250,
    getQuickFilterText: ({ value }) => value?.map((tag: Tag) => tag.name),
    valueGetter: (params) => params.data?.tags?.map((tag: Tag) => tag.name) || [],
    ...multiFilter,
  },
  {
    field: 'crmId',
    headerName: 'CRM ID',
    sortable: true,
    resizable: true,
    minWidth: 250,
    valueFormatter: ({ value }) => value ? value : '-',
    enableRowGroup: true,
    ...multiFilter,
  },
]);
