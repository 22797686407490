import React from 'react';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useForm,
  Controller,
} from 'react-hook-form';
import {
  ArrowLeftIcon,
  CloseIcon,
  InfoIcon,
} from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { Datepicker } from 'components/common/Datepicker';
import {
  StyledFormContainer,
  StyledButtonContainer,
} from 'components/common/Forms/styled';
import { SectionLabel } from 'components/common/SectionLabel';
import { toastSuccess } from 'utils/toast';
import { Button } from 'components/common/Button';
import {
  formatDateToISO,
  formatDateToISODate,
  isoToDate,
} from 'utils/dates';
import { useCancelContractsMutation } from 'store/services/contracts';
import {
  Contracts,
  ContractsCancelPayload,
} from 'interfaces/contracts';
import { Hint } from 'components/common/Hint';

interface Props {
  onClose: () => void;
  onGoBack: () => void;
  selectedRows: Contracts;
}

export const CancelContracts = ({
  selectedRows,
  onClose,
  onGoBack,
}: Props) => {
  const [cancelContracts, { isLoading }] = useCancelContractsMutation();
  const isCancelAllowed = selectedRows
    .every((con) => !con.cancelDate);

  const latestStartDate = selectedRows.length
    ? isoToDate(selectedRows
      .map((con) => con.startDate)
      .reduce((date1, date2) => (date1 > date2 ? date1 : date2)))!
    : formatDateToISODate(new Date());

  const schema = yup.object({
    cancelDate: yup.date()
      .nullable()
      .test(
        'isNotBeforeLatestStartDate',
        `Cancel date cannot be set before ${selectedRows.length > 1 ? 'latest' : ''} start date.`,
        (val) => val ? (val.getTime() >= latestStartDate.getTime()) : false,
      )
      .required('Cancel date is required.'),
  })
    .required();

  const defaultValues = {
    cancelDate: formatDateToISODate(new Date()),
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<{ cancelDate: Date }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const handleSave = handleSubmit((data) => {
    const payload: ContractsCancelPayload = {
      contractIds: selectedRows.map((row) => row.id),
      cancelDate: {
        applyAlsoOnLines: false,
        date: formatDateToISO(data.cancelDate!),
      },
    };

    return cancelContracts(payload)
      .unwrap()
      .then(() => {
        toastSuccess('Contract(s) successfully canceled.');
      });
  });

  const handleSaveAndBack = handleSubmit((data) => {
    const payload: ContractsCancelPayload = {
      contractIds: selectedRows.map((row) => row.id),
      cancelDate: {
        applyAlsoOnLines: false,
        date: formatDateToISO(data.cancelDate!),
      },
    };

    return cancelContracts(payload)
      .unwrap()
      .then(() => {
        toastSuccess('Contract(s) successfully canceled.');
        onGoBack();
      });
  });

  return (
    <>
      <header>
        <ButtonsContainer paddingBottom={16}>
          <Button
            aria-label="Go back"
            variant="icon"
            size="large"
            onClick={onGoBack}
            data-cy="cc-button-back"
          >
            <ArrowLeftIcon />
          </Button>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={onClose}
            pushRight
            data-cy="cc-button-close"
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <SectionLabel>
          <span>
            {selectedRows?.length > 1 ? 'Cancel Contracts' : 'Cancel Contract'}
          </span>
        </SectionLabel>
      </header>
      <main>
        {!isCancelAllowed && (
          <Hint>
            <InfoIcon />
            <div>
              {selectedRows.length > 1
                ? 'One or more of selected contracts is already either canceled or renewed.'
                : 'Selected contract is already canceled or renewed.'
              }
            </div>
          </Hint>
        )}
        {isCancelAllowed && (
          <StyledFormContainer>
            <Controller
              name="cancelDate"
              control={control}
              defaultValue={undefined}
              render={({ field }) =>
                <Datepicker
                  labelText="Cancel Date"
                  placeholder="Select Date"
                  {...field}
                  error={errors.cancelDate?.message}
                  isRequired
                  data-cy="cc-datepicker-cancel"
                  isClearable={false}
                />
              }
            />
          </StyledFormContainer>
        )}
      </main>
      <footer>
        {isCancelAllowed && (
          <StyledButtonContainer pushRight>
            <Button
              type="submit"
              variant="outlined"
              color="secondary"
              onClick={handleSave}
              disabled={isLoading}
              isLoading={isLoading}
              data-cy="cc-button-save"
            >
              SAVE
            </Button>
            <Button
              type="submit"
              onClick={handleSaveAndBack}
              disabled={isLoading}
              isLoading={isLoading}
              data-cy="cc-button-save-backs"
            >
              SAVE & BACK
            </Button>
          </StyledButtonContainer>
        )}
      </footer>
    </>
  );
};
