import {
  ComponentPropsWithRef,
  forwardRef,
} from "react";
import styled, { css } from "styled-components";
import { theme } from "theme/theme";
import { ANIMATION_DURATION } from "utils/constants";
import { RotatingRefreshIcon } from "assets/icons";

export interface ButtonProps extends ComponentPropsWithRef<'button'> {
  variant?: 'contained' | 'outlined' | 'borderless' | 'icon' | 'icon-inverse' | 'icon-bleach' | 'simple';
  color?: 'primary' | 'secondary' | 'error' | 'tertiary' | 'transparent';
  size?: 'normal' | 'large';
  disabled?: boolean;
  isLoading?: boolean;
  pushRight?: boolean;
  minWidth?: number;
  active?: boolean;
  fullWidth?: boolean;
  inline?: boolean;
};

const defaultColor = 'primary';

const buttons = {
  primary: {
    fill: theme.colors.primary,
    containedText: theme.colors.white,
    outlinedText: theme.colors.primary,
    hoverOutlined: theme.colors.primaryBleach,
  },
  secondary: {
    fill: theme.colors.textDark,
    containedText: theme.colors.white,
    outlinedText: theme.colors.textDark,
    hoverOutlined: theme.colors.mainBackground,
  },
  error: {
    fill: theme.colors.error,
    containedText: theme.colors.white,
    outlinedText: theme.colors.error,
    hoverOutlined: theme.colors.errorBleach,
  },
  tertiary: {
    fill: theme.colors.tertiary,
    containedText: theme.colors.white,
    outlinedText: theme.colors.tertiary,
    hoverOutlined: theme.colors.mainBackground,
  },
  transparent: {
    fill: theme.colors.transparent,
    containedText: theme.colors.white,
    outlinedText: theme.colors.textDark,
    hoverOutlined: theme.colors.mainBackground,
  },
};

export const StyledButton = styled.button<ButtonProps>`
  position: relative;
  display: ${props => props.inline ? 'inline-flex' : 'flex'};
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: ${props => props.size === 'large' ? '0 8px' : '0 8px'};
  height: ${props => props.size === 'large' ? '32px' : '40px'};
  font-size: ${props => props.size === 'large' ? '14px' : '12px'};
  line-height: ${props => props.size === 'large' ? '22px' : '16px'};
  font-weight: 600;
  border-radius: 4px;
  transition: all ${ANIMATION_DURATION}ms ease;
  cursor: pointer;

  ${props => props.active && css`
    border-bottom: 2px solid ${buttons[props.color || defaultColor].fill} !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:hover {
      border-radius: 4px;
    }
  `}

  ${props => props.disabled && css`
    opacity: 0.4;
    pointer-events: none;
  `}

  ${props => props.isLoading && css`
    opacity: 0.4;
    pointer-events: none;
  `}

  ${props => props.pushRight && css`
    margin-left: auto !important;
  `}

  ${props => props.minWidth && css`
    min-width: ${props.minWidth}px;
  `}

  ${props => props.fullWidth && css`
    width: 100%;
  `}

  /* contained */
  ${props => (!props.variant || props.variant === 'contained') && css`
    color: ${buttons[props.color || defaultColor].containedText};
    border: 2px solid transparent;
    background-color: ${buttons[props.color || defaultColor].fill};

    :hover {
      box-shadow: ${theme.boxShadow};
    }
  `}

  /* outlined */
  ${props => props.variant && ['outlined', 'borderless'].includes(props.variant) && css`
    color: ${buttons[props.color || defaultColor].outlinedText};
    border: 1px solid ${buttons[props.color || defaultColor].fill};
    background-color: transparent;

    :hover {
      background-color: ${buttons[props.color || defaultColor].hoverOutlined};
      box-shadow: ${theme.boxShadow};
    }
  `}

  /* borderless */
  ${props => props.variant === 'borderless' && css`
    border: 1px solid transparent;

    :hover {
      border: 1px solid ${buttons[props.color || defaultColor].fill};
      background-color: ${buttons[props.color || defaultColor].hoverOutlined};
    }
  `}

  /* icon */
  ${props => (props.variant?.startsWith('icon')) && css`
    border-radius: 50%;
    border: 1px solid transparent;
    height: 32px;
    width: 32px;
    background-color: ${props.color ? theme.colors.transparent : theme.colors.borderColorDark};
    color: ${props.color ? buttons[props.color].fill : theme.colors.textDark};
    padding: 0;
    justify-content: center;
    font-weight: 500;

    svg {
      width: ${props.size === 'large' ? '24px' : '18px'};
      height: ${props.size === 'large' ? '24px' : '18px'};
    }

    &:hover {
      background-color: ${props.color ? buttons[props.color].hoverOutlined : theme.colors.fieldBorder};
    }
  `}

  /* icon-inverse */
  ${props => props.variant === 'icon-inverse' && css`
    background-color: ${props.color ? theme.colors[props.color] : theme.colors.borderColorDark};
    color: ${props.color ? theme.colors.white : theme.colors.textDark};

    &:hover {
      background-color: ${theme.colors.fieldBorder};
    }
  `}

  /* icon-bleach */
  ${props => props.variant === 'icon-bleach' && css`
    color: ${theme.colors[props.color || 'primary']};
    background-color: ${theme.colors.bleached[props.color || 'primary']};
    
    &:hover {
      background-color: ${theme.colors[props.color || 'primary']};
      color: ${theme.colors.white};
    }
  `}

  ${props => props.variant === 'simple' && css`
    padding: 0;
    border: 1px solid transparent;
    color: ${buttons[props.color || defaultColor].outlinedText};
    background-color: transparent;

    :hover {
      border: 1px solid transparent;
      background-color: transparent;
      box-shadow: none;
      transition: none;
    }
  `}
`;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
  <StyledButton
    ref={ref}
    isLoading={props.isLoading}
    type={props.type || 'button'}
    {...props}
  >
    {props.isLoading && <RotatingRefreshIcon />}
    {props.children}
  </StyledButton>
));;
