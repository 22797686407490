import { Box } from "components/common/Box";
import styled from "styled-components";
import { theme } from "theme/theme";

export const BoxesContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 40px;
`;

export const LeftBox = styled(Box)`
  color: ${theme.colors.textDark};
  width: 300px;
  flex-shrink: 0;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const StyledItem = styled.div`
  padding: 12px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;

  title {
    display: flex;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;

    svg {
      margin-left: 4px;
    }
  }

  li {
    margin-top: 4px;
    display: flex;
    
    span {
      align-items: center;
      line-height: 14px;
      margin: 4px 0;
    }

    svg {
      margin-right: 4px;
      color: ${theme.colors.borderColorDark};
      flex-shrink: 0;
    }
  }
`;

export const SectionLabelPillsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
