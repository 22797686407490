import styled, { css } from "styled-components";
import SortAsc from "assets/sort-asc.svg";
import SortDesc from "assets/sort-desc.svg";
import SortNone from "assets/sort-none.svg";
import { theme } from "theme/theme";
import { ANIMATION_DURATION } from "utils/constants";

interface AgGridContainerProps {
  $disabled?: boolean;
  isFocused?: boolean;
  condensed?: boolean;
  noBordersAndFocus?: boolean;
  autoHeight?: boolean;
  minHeight?: number;
}

export const AgGridContainer = styled.div<AgGridContainerProps>`
  position: relative;
  height: ${props => props.autoHeight ? 'auto' : '100%'};
  width: 100%;
  flex-shrink: 1;
  font-family: 'Montserrat';
  margin-top: 12px;

  --ag-selected-row-background-color: ${theme.colors.secondaryBackground};

  ${props => props.minHeight && css`
    min-height: ${props.minHeight}px;
  `}

  ${props => props.$disabled && css`
    .ag-root-wrapper {
      cursor: not-allowed;
    }
    
    .ag-root {
      pointer-events: none;
      opacity: 0.7;
    }
  `}

  .ag-root-wrapper {
    border-radius: 8px 8px 4px 4px;
    border: solid 1px ${theme.colors.borderColorDark};

    ${props => props.isFocused && css`
      border: 1px solid ${theme.colors.primary40};
      box-shadow: ${theme.boxShadowHighlight};
  `}
  }

  .ag-header-row {
    font-weight: 700;
    font-size: 14px;
    color: ${theme.colors.textDark};
  }

  .ag-header-cell-sorted-none {
    .ag-header-icon {
      opacity: 0;
      transition: opacity ${ANIMATION_DURATION}ms ease;
    }

    :hover {
      .ag-header-icon {
        opacity: 1;
      }
    }
  }

  .ag-center-cols-viewport {
    ${props => props.autoHeight && css`
      min-height: unset !important;
    `}
  }

  .ag-pinned-left-cols-container .ag-row-focus:not(.ag-row.hover),
  .ag-center-cols-viewport .ag-row-focus:not(.ag-row.hover) {
    z-index: 2;
  }

  .ag-header-active {
    background-color: ${theme.colors.mainBackground} !important;
  }

  .ag-header-cell:hover {
    .ag-header-cell-resize {
      opacity: 1;
    }
  }

  .ag-header-cell-resize {
    display: flex;
    align-items: center;
    opacity: 0;
    transition: opacity ${ANIMATION_DURATION}ms ease;

    &:after {
      content: '||';
      position: absolute;
      left: -4px;
      font-size: 10px;
      color: ${theme.colors.fieldBorder};
    }
  }

  .ag-row {
    font-weight: 500;
    font-size: 14px;
    color: ${theme.colors.textDark};

    &.contract-conflict {
      background-color: ${theme.colors.errorBleach};
    }

    &.row-bold {
      font-weight: 700;
    }

    &.row-underline .ag-cell.ag-right-aligned-cell {
      text-decoration: underline;
    }

    &.row-border {
      border-bottom: 2px solid ${theme.colors.fieldBorder};
    }
  }

  .ag-row-focus {
    background: ${theme.colors.mainBackground};

    .remove-overflow {
      overflow: unset;
    }
  }

  .ag-icon {
    width: unset;
    height: unset;
  }

  .ag-icon-asc::before {
    content: url("${SortAsc}");
  }

  .ag-icon-desc::before {
    content: url("${SortDesc}");
  }

  .ag-icon-none::before {
    content: url("${SortNone}");
  }

  .ag-checkbox-input-wrapper::after {
    color: ${theme.colors.fieldBorder}
  }
  
  .ag-checkbox-input-wrapper.ag-checked::after {
    color: ${theme.colors.primary};
  }

  .ag-checkbox-input-wrapper.ag-indeterminate::after {
    color: ${theme.colors.textDisabled};
  }

  .ag-overlay-no-rows-wrapper {
    background-color: ${theme.colors.borderColorLight};
  }

  .ag-cell-data-changed {
    background-color: ${theme.colors.successBleach} !important;
  }
  
  .ag-body-horizontal-scroll.ag-scrollbar-invisible {
    pointer-events: all !important;
  }

  .ag-right-aligned-cell.ag-cell-first-right-pinned {
    display: flex;
    align-items: center;
  }

  .ag-row-position-absolute {
    z-index: 1;
  }

  .ag-pinned-left-cols-container,
  .ag-pinned-right-cols-container {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 10px;
      z-index: 2;
    }
  }

  .ag-pinned-left-cols-container {
    &::after {
      right: -10px;
      background-image: linear-gradient(90deg, rgba(226, 226, 233, 0.5) 0%, rgba(226, 226, 233, 0) 100%);
    }
  }

  .ag-pinned-right-cols-container {
    &::after {
      left: -10px;
      background-image: linear-gradient(90deg, rgba(226, 226, 233, 0) 0%, rgba(226, 226, 233, 0.5) 100%);
    }
  }

  .ag-pinned-right-header {
    box-shadow: 0px 0px 15px ${theme.colors.borderColorDark};
    clip-path: inset(0px 0px 0px -15px);
  }

  .ag-pinned-left-header {
    box-shadow: 0px 0px 15px ${theme.colors.borderColorDark};
    clip-path: inset(0px -15px 0px 0);
  }
  
  .ag-cell {
    border-right-color: ${theme.colors.borderColorLight};

    ${props => props.condensed && css`
      padding-left: 16px;
      padding-right: 16px;
    `}
    
    &.ag-cell-range-single-cell {
      border-color: var(--ag-range-selection-border-color);
    }
  }

  .ag-header-cell,
  .ag-header-group-cell {
    border-right: 1px solid ${theme.colors.borderColorLight};

    ${props => props.condensed && css`
      padding-left: 16px;
      padding-right: 16px;
    `}
  }

  .ag-header-pivot {
    & > .ag-header-group-cell-label {
      flex-direction: row-reverse;
    }
  }

  .ag-group-child-count {
    color: ${theme.colors.primary};
    margin-left: 5px;
    font-size: 12px;
  }

  .ag-row-group {
    font-weight: 600;
  }

  .ag-cell-inline-editing {
    height: 100%;
  }

  input[class^=ag-]:not([type]), 
  input[class^=ag-][type=text], 
  input[class^=ag-][type=number], 
  input[class^=ag-][type=tel], 
  input[class^=ag-][type=date], 
  input[class^=ag-][type=datetime-local], 
  textarea[class^=ag-] {
    border-bottom: none !important;
    padding-bottom: 0;
  }

  .cell-error {
    padding-right: 32px;

    &:after {
      position: absolute;
      top: 0;
      right: 16px;
      content: 'ⓘ';
      color: ${theme.colors.error};
    }
  }

  .cell-warning {
    padding-right: 32px;

    &:after {
      position: absolute;
      top: 0;
      right: 16px;
      content: 'ⓘ';
      color: ${theme.colors.warning};
    }
  }

  .cell-placeholder {
    color: ${theme.colors.textDisabled};
    background-color: ${theme.colors.mainBackground};
  }

  .ag-rich-select-list {
    min-width: 225px;
    height: auto;
  }

  .ag-rich-select-value {
    font-weight: 700;

    ins {
      display: none;
    }
  }

  .ag-rich-select-virtual-list-viewport {
    max-height: 180px !important;
  }

  .custom-header-no-buttons,
  .custom-header {
    padding: 0;
    overflow: unset;

    .line1,
    .line2 {
      display: flex;
      align-items: center;
      position: relative;
      line-height: 48px;
      height: 48px;
    }

    .line1-padding {
      padding: 0 16px;
    }

    .line2 {
      border-top: 1px solid ${theme.colors.borderColorDark};
    }
  }

  .custom-header-no-buttons {
    .line1,
    .line2 {
      padding: 0 16px;
    }
  }

  .cell-flex {
    display: flex;
    align-items: center;
  }

  ${props => props.noBordersAndFocus && css`
    margin-top: 0;

    .ag-root-wrapper {
      border: none;
    }

    .ag-header {
      border-bottom: none;
    }

    .ag-header-cell,
    .ag-cell {
      padding-left: 0;
      padding-right: 0;
      border-right-color: transparent;
    }

    .ag-row {
      border-bottom: none;
    }
    
    .ag-row-selected,
    .ag-row-selected:before,
    .ag-row-focus {
      background: none;
    }

    .ag-selection-checkbox,
    .ag-drag-handle.ag-row-drag {
      margin-right: 12px;
    }
  `}

  .total-row {
    .ag-cell {
      background: ${theme.colors.borderColorDark} !important;
      font-weight: 600;
    }

    &.ag-row-hover .ag-cell {
      background: ${theme.colors.borderColorDark} !important;
    }
  }

  .informative-row {
    background: ${theme.colors.transparent};

    &.ag-row-hover .ag-cell {
      background: ${theme.colors.transparent} !important;
    }

    .ag-cell {
      font-size: 12px;
    }
  }

  .disabled-row {
    .ag-cell {
      background: ${theme.colors.mainBackground} !important;
      color: ${theme.colors.fieldBorder};
    }
  }
  
  .cell-red {
    color: ${theme.colors.error};
  }

  .main-level {
    background-color: ${theme.colors.borderColorDark};
  }

  .second-level {
    background-color: ${theme.colors.secondaryBackground};
  }

  .third-level {
    background-color: ${theme.colors.mainBackground};
  }

  .ag-cell-inline-editing {
    padding: 0;

    .ag-input-wrapper {
      padding: 0 14px;
    }
  }

  .ag-picker-field-wrapper {
    border-radius: 0;
    padding: 0;
  }

  .ag-filter-toolpanel-group.ag-has-filter > .ag-group-title-bar .ag-group-title::after {
    color: ${theme.colors.primary};
  }
`;
