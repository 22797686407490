import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  CloseIcon,
  DeleteIcon,
  InfoIcon,
} from 'assets/icons';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import { SectionLabel } from 'components/common/SectionLabel';
import { Button } from 'components/common/Button';
import { InputBasic } from 'components/common/Input';
import {
  StyledButtonContainer,
  StyledContainer,
  StyledFormContainer,
  StyledWrapper,
} from 'components/common/Forms/styled';
import { toastSuccess } from 'utils/toast';
import { Box } from 'components/common/Box';
import { StyledModalBoxesContainer } from 'components/Layout/Modal/styled';
import { RadioInput } from 'components/common/RadioInput';
import {
  AddMetricRequest,
  Metric,
  MetricCategory,
  MetricFormFields,
  MetricOrigin,
} from 'interfaces/metrics';
import { Dropdown } from 'components/common/Dropdown';
import {
  MetricAggregationMethodOptions,
  MetricCategoryOptions,
} from 'utils/constants';
import {
  useAddMetricMutation,
  useGetAllMetricTypesQuery,
  useGetAllMetricsQuery,
} from 'store/services/metrics';
import { Loading } from 'components/Loading';
import { CheckboxInput } from 'components/common/CheckboxInput';
import { StyledLookback } from '../EditMetric/styled';
import { Tooltip } from 'components/common/Tooltip';
import { metricStartDateOptions } from 'utils/metrics';
import { FormulaEditor } from 'components/FormulaEditor';
import { Hint } from 'components/common/Hint';
import { MetricFormat } from '../MetricFormat';

interface Props {
  onClose: () => void;
  onSuccess?: (result: Metric) => void;
  predefinedName?: string;
  metricOrigin?: MetricOrigin;
}

enum Step {
  FIRST,
  SYSTEM_METRIC,
  CUSTOM_FORMULA,
  MANUAL_METRIC,
}

export const AddMetric = ({
  onClose,
  onSuccess,
  predefinedName,
  metricOrigin,
}: Props) => {
  const [secondStep, setSecondStep] = useState<Step | null>(null);
  const [customMetric, setCustomMetric] = useState<Metric | undefined>();
  const [selectedCategory, setSelectedCategory] = useState(MetricCategory.SAAS);
  const { data: metricTypes, isLoading: isLoadingMetricTypes } = useGetAllMetricTypesQuery();
  const [addMetric, { isLoading: isAddingMetric }] = useAddMetricMutation();
  const { data: metrics } = useGetAllMetricsQuery();
  const allMetricsNames = metrics?.map((metric) => metric.name.toLowerCase()) || [];

  const options = useMemo(() => [...new Set(metrics?.filter((metric) => metric.tags)
    .flatMap((metric) => metric.tags))]
    .map((metric) => ({
      value: metric,
    })), [metrics]);

  const metricTypesByCategory = useMemo(() => metricTypes?.filter((mt) => mt.category === selectedCategory) || [],
    [metricTypes, selectedCategory]);

  const schema = yup.object({
    name: yup.string()
      .required('Metric Name is required.')
      .test(
        'notOneOfCaseInsensitive',
        'Metric with this name already exists.',
        (val) => !allMetricsNames.includes(val?.toLowerCase() || ''),
      ),
    description: yup.string()
      .max(250, 'Maximum of 250 characters allowed.'),
    metricType: yup.array()
      .of(yup.object())
      .min(1, 'Metric Type is required.'),
    aggregationMethod: yup.array()
      .of(yup.object())
      .min(1, 'Aggregation Method is required.'),
    lookbackPeriod: yup.number()
      .when('lookbackYTD', {
        is: (val: boolean) => !val,
        then: yup.number()
          .min(1, 'Period is required')
          .max(36, 'Max 36 months')
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable(),
      })
      .nullable(),
    lookbackYTD: yup.boolean(),
  })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
    watch,
  } = useForm<MetricFormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: predefinedName || '',
      description: '',
      category: null,
      aggregationMethod: [MetricAggregationMethodOptions[0]],
      metricType: [],
      tags: [],
      lookbackPeriod: null,
      lookbackYTD: false,
      startDateField: metricStartDateOptions[0],
    },
  });

  const handleSaveAndClose = handleSubmit((data) => {
    const payload: AddMetricRequest = {
      aggregationMethod: data.aggregationMethod[0].value,
      metricTypeID: data.metricType[0].id,
      name: data.name,
      description: data.description,
      tags: data.tags.map((tag) => tag.value),
      filters: {
        lookback: data.lookbackPeriod || data.lookbackYTD
          ? {
            period: data.lookbackPeriod ? Number(data.lookbackPeriod) : null,
            ytd: data.lookbackYTD,
          }
          : null,
      },
      startDateField: data.startDateField.value,
    };

    return addMetric(payload)
      .unwrap()
      .then((result) => {
        toastSuccess('Metric successfully added.');
        if (predefinedName && secondStep === Step.CUSTOM_FORMULA) {
          setCustomMetric(result);
        } else {
          onClose();
          onSuccess && onSuccess(result);
        }
      });
  });

  useEffect(() => {
    switch (metricOrigin) {
      case MetricOrigin.CUSTOM:
        setSecondStep(Step.CUSTOM_FORMULA);
        break;
      case MetricOrigin.SYSTEM_COPY:
        setSecondStep(Step.SYSTEM_METRIC);
        break;
      case MetricOrigin.MANUAL:
        setSecondStep(Step.MANUAL_METRIC);
        break;
    }
  }, [metricOrigin]);

  if (isLoadingMetricTypes) return <Loading />;

  if (!isLoadingMetricTypes && !metricTypes) {
    return <>Error fetching metric types.</>;
  }

  return (
    <StyledContainer>
      <ButtonsContainer
        paddingBottom={40}
        alignTop
      >
        <SectionLabel
          data-cy="am-header"
          marginBottom={0}
        >
          <span>
            {!secondStep && 'Add Metric'}
            {secondStep === Step.SYSTEM_METRIC && 'Create System Metric'}
            {secondStep === Step.CUSTOM_FORMULA && 'Create Custom Metric'}
            {secondStep === Step.MANUAL_METRIC && 'Create Manual Metric'}
            <ins>{secondStep ? '' : '1 of 2'}</ins>
          </span>
        </SectionLabel>
        <Spacer />
        <Button
          aria-label="Close"
          variant="icon"
          size="large"
          onClick={onClose}
          data-cy="am-button-close"
        >
          <CloseIcon />
        </Button>
      </ButtonsContainer>
      <StyledWrapper>
        {secondStep === null && (
          <StyledModalBoxesContainer>
            <Box marginBottom={0}>
              <SectionLabel tertiary>
                <div>
                  System Metric
                  <p>
                    A System Metric is one of our predefined, “out-of-the-box” metrics.
                  </p>
                  <p>
                    You cannot edit formulas for System Metrics, but you can apply powerful filters to segment and cut a metric however you wish.
                  </p>
                </div>
              </SectionLabel>
              <div>
                <Button onClick={() => setSecondStep(Step.SYSTEM_METRIC)}>
                  Create System Metric
                </Button>
              </div>
            </Box>
            <Box marginBottom={0}>
              <SectionLabel tertiary>
                <div>
                  Custom Formula
                  <p>
                    A Custom Formula allows you to write a formula using any other Metric or Account.
                  </p>
                  <p>
                    Custom Formulas are limited in their ability to any apply filters from System Metrics.
                  </p>
                </div>
              </SectionLabel>
              <div>
                <Button onClick={() => setSecondStep(Step.CUSTOM_FORMULA)}>
                  Create Custom Formula
                </Button>
              </div>
            </Box>
            <Box marginBottom={0}>
              <SectionLabel tertiary>
                <div>
                  Manual Metric
                  <p>
                    A Manual Metric allows you to upload custom values to a metric.
                  </p>
                  <p>
                    You will still be able to apply filters similar to System Metrics.
                  </p>
                </div>
              </SectionLabel>
              <div>
                <Button onClick={() => setSecondStep(Step.MANUAL_METRIC)}>
                  Create Manual Metric
                </Button>
              </div>
            </Box>
          </StyledModalBoxesContainer>
        )}
        {secondStep !== null && !customMetric && (
          <StyledFormContainer onSubmit={handleSaveAndClose}>
            <InputBasic
              isRequired
              labelText="Metric Name"
              placeholder="Add metric name"
              {...register('name')}
              onBlur={(e) => {
                const fieldValue = e.target.value;

                if (fieldValue) {
                  setValue('name', fieldValue.trim(), { shouldValidate: true });
                }
              }}
              error={errors.name?.message}
              dataCy="am-input-name"
            />
            <InputBasic
              isOptional
              labelText="Description"
              placeholder="Description"
              {...register('description')}
              onBlur={(e) => {
                const fieldValue = e.target.value;

                if (fieldValue) {
                  setValue('description', fieldValue.trim(), { shouldValidate: true });
                }
              }}
              error={errors.description?.message}
              dataCy="am-input-description"
            />
            <Controller
              name="category"
              control={control}
              render={({ field }) =>
                <RadioInput
                  isRequired
                  labelText="Metric Category"
                  valueField="value"
                  labelField="name"
                  defaultValue={MetricCategoryOptions[0]}
                  options={MetricCategoryOptions}
                  {...field}
                  onChange={(value) => {
                    if (secondStep === Step.CUSTOM_FORMULA) {
                      const metricType = metricTypes!.find((mt) => mt.name === 'Custom' && mt.category === value.name);
                      setValue('metricType', metricType ? [metricType] : []);
                    } else if (secondStep === Step.MANUAL_METRIC) {
                      const metricType = metricTypes!.find((mt) => mt.name === 'Manual' && mt.category === value.name);
                      setValue('metricType', metricType ? [metricType] : []);
                    } else {
                      setValue('metricType', []);
                    }
                    setSelectedCategory(value.name);
                    field.onChange(value);
                  }}
                  error={errors.category?.message}
                />
              }
            />
            <Controller
              name="metricType"
              control={control}
              render={({ field }) =>
                <Dropdown
                  isRequired
                  labelText="Metric Type"
                  options={metricTypesByCategory}
                  labelField="name"
                  valueField="id"
                  searchBy="name"
                  placeholder="Select Metric Type"
                  values={getValues('metricType')}
                  {...field}
                  error={errors.metricType?.message}
                  dropdownPosition="top"
                  hidden={secondStep === Step.CUSTOM_FORMULA || secondStep === Step.MANUAL_METRIC}
                />
              }
            />
            {secondStep !== Step.MANUAL_METRIC && (
              <StyledLookback>
                <InputBasic
                  labelText="Lookback Period"
                  isOptional
                  {...register('lookbackPeriod')}
                  type="number"
                  max={36}
                  min={1}
                  placeholder="None "
                  disabled={!!watch('lookbackYTD')}
                  error={errors.lookbackPeriod?.message}
                />
                <span>
                  month(s)
                </span>
                <Controller
                  name="lookbackYTD"
                  control={control}
                  render={({ field }) =>
                    <CheckboxInput
                      isOptional
                      checkboxLabel="YTD"
                      {...field}
                      defaultValue={getValues('lookbackYTD')}
                      error={errors.lookbackYTD?.message}
                      onChange={(e) => {
                        setValue('lookbackPeriod', null);
                        field.onChange(e);
                      }}
                    />
                  }
                />
                <Spacer />
                <Tooltip title="Clear Lookback">
                  <Button
                    variant="icon"
                    onClick={((e) => {
                      e.preventDefault();
                      setValue('lookbackPeriod', null);
                      setValue('lookbackYTD', false);
                    })}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </StyledLookback>
            )}
            <Controller
              name="startDateField"
              control={control}
              render={({ field }) =>
                <RadioInput
                  isRequired
                  labelText="Metric Start Date"
                  valueField="value"
                  labelField="name"
                  defaultValue={metricStartDateOptions[0]}
                  options={metricStartDateOptions}
                  {...field}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  error={errors.startDateField?.message}
                />
              }
            />
            <Controller
              name="aggregationMethod"
              control={control}
              render={({ field }) =>
                <Dropdown
                  isRequired
                  labelText="Aggregation Method"
                  options={MetricAggregationMethodOptions}
                  labelField="name"
                  valueField="value"
                  searchBy="name"
                  placeholder="Select Aggregation Method"
                  values={getValues('aggregationMethod')}
                  {...field}
                  error={errors.aggregationMethod?.message}
                  dropdownPosition="top"
                  tooltip="This setting determines how other metrics aggregate data from this metric. All metrics default to the ending balance."
                />
              }
            />
            <Controller
              name="tags"
              control={control}
              render={({ field }) =>
                <Dropdown
                  tooltip="Start typing to add new tag"
                  labelText="Tags"
                  options={options}
                  labelField="value"
                  valueField="value"
                  searchBy="value"
                  placeholder="Select Tags"
                  entityName="tag"
                  values={getValues('tags')}
                  {...field}
                  error={errors.tags?.message}
                  dropdownPosition="top"
                  create
                  multi
                />
              }
            />
          </StyledFormContainer>
        )}
        {!!customMetric && (
          <div>
            <SectionLabel tertiary>
              Formula
            </SectionLabel>
            <FormulaEditor
              metricId={customMetric.id}
              omitFormulaLabel
            />
            <Hint
              marginTop={1}
              marginBottom={20}
            >
              <InfoIcon />
              Use field above to enter formula for your custom metric.
              Remember to save your formula before closing this modal.
            </Hint>
          </div>
        )}
        {!!customMetric && (
          <MetricFormat
            metric={customMetric}
            onSave={() => {
              onClose();
              onSuccess && onSuccess(customMetric);
            }}
          />
        )}
      </StyledWrapper>
      {secondStep !== null && !customMetric && (
        <StyledButtonContainer pushRight>
          {!metricOrigin && (
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              onClick={() => setSecondStep(null)}
              disabled={isAddingMetric}
              data-cy="am-button-back"
            >
              BACK
            </Button>
          )}
          <Button
            type="button"
            onClick={handleSaveAndClose}
            disabled={isAddingMetric}
            isLoading={isAddingMetric}
            data-cy="am-button-save"
          >
            CREATE METRIC
          </Button>
        </StyledButtonContainer>
      )}
    </StyledContainer >
  );
};
