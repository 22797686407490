import { api } from "./api";
import {
  ContractLine,
  ContractLines,
  ContractLineBulkEditPayload,
  ContractLineCancelPayload,
  ContractLineUpdatePayload,
} from "interfaces/contractLines";
import {
  apiAddRecipe,
  apiDeleteRecipe,
  apiUpdateRecipe,
} from "utils/api";
import {
  CommonMetricTags,
  ContractLinesTags,
  ContractsTags,
} from "./api-tags";

export const contractLinesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllContractLines: builder.query<ContractLines, void>({
      query: () => ({
        url: 'contract_lines',
        params: {
          deleted: "false",
        },
      }),
      providesTags: [ContractLinesTags.ContractLines],
    }),
    getContractLinesByContractIds: builder.query<ContractLines, string>({
      query: (contractIDs) => ({
        url: 'contract_lines',
        params: {
          contractIDs,
          deleted: "false",
        },
      }),
      providesTags: [ContractLinesTags.ContractLinesByContractIds],
    }),
    deleteContractLines: builder.mutation<void, string[]>({
      query: (ids) => {
        return {
          url: `contract_lines`,
          method: 'DELETE',
          body: ids,
        };
      },
      onQueryStarted: async (ids, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(contractLinesApi.util
            .updateQueryData('getAllContractLines', undefined, apiDeleteRecipe(ids)));
        } catch {}
      },
      invalidatesTags: (res, err) => !err ? [
        ContractsTags.Contracts,
        ContractLinesTags.ContractLines,
        ContractLinesTags.ContractLinesByContractIds,
        ...CommonMetricTags,
      ] : [],
    }),
    addContractLine: builder.mutation<ContractLine, ContractLineUpdatePayload>({
      query: (contractLine) => {
        return {
          url: 'contract_lines',
          method: 'POST',
          body: contractLine,
        };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: addedContractLine} = await queryFulfilled;
          dispatch(contractLinesApi.util
            .updateQueryData('getAllContractLines', undefined, apiAddRecipe(addedContractLine)));
          dispatch(contractLinesApi.util
            .updateQueryData('getContractLinesByContractIds', addedContractLine.contract.id, apiAddRecipe(addedContractLine)));
        } catch {}
      },
      invalidatesTags: (res, err) => !err ? [
        ContractsTags.Contracts,
        ...CommonMetricTags,
      ] : [],
    }),
    updateContractLine: builder.mutation<ContractLine, ContractLineUpdatePayload>({
      query: (contractLine) => {
        return {
          url: `contract_lines/${contractLine.id}`,
          method: 'PUT',
          body: contractLine,
        };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: updatedContractLine} = await queryFulfilled;
          dispatch(contractLinesApi.util
            .updateQueryData('getAllContractLines', undefined, apiUpdateRecipe(updatedContractLine)));
          dispatch(contractLinesApi.util
            .updateQueryData('getContractLinesByContractIds', updatedContractLine.contract.id, apiUpdateRecipe(updatedContractLine)));
        } catch {}
      },
      invalidatesTags: (res, err) => !err ? [
        ContractsTags.Contracts,
        ...CommonMetricTags,
      ] : [],
    }),
    cancelContractLines: builder.mutation<void, ContractLineCancelPayload>({
      query: (cancelBody) => {
        return {
          url: 'contract_lines/cancel',
          method: 'POST',
          body: cancelBody,
        };
      },
      invalidatesTags: (res, err) => !err ? [
        ContractsTags.Contracts,
        ContractLinesTags.ContractLines,
        ContractLinesTags.ContractLinesByContractIds,
        ...CommonMetricTags,
      ] : [],
    }),
    renewContractLine: builder.mutation<ContractLines, ContractLineUpdatePayload>({
      query: (contractLine) => {
        return {
          url: `contract_lines/${contractLine.id}/renew`,
          method: 'POST',
          body: contractLine,
        };
      },
      invalidatesTags: (res, err) => !err ? [
        ContractsTags.Contracts,
        ContractLinesTags.ContractLines,
        ContractLinesTags.ContractLinesByContractIds,
        ...CommonMetricTags,
      ] : [],
    }),
    bulkEditContractLines: builder.mutation<undefined, ContractLineBulkEditPayload>({
      query: (contractLines) => {
        return {
          url: 'contract_lines/bulk_edit',
          method: 'POST',
          body: contractLines,
        };
      },
      invalidatesTags: (res, err) => !err ? [
        ContractsTags.Contracts,
        ContractLinesTags.ContractLines,
        ContractLinesTags.ContractLinesByContractIds,
        ...CommonMetricTags,
      ] : [],
    }),
    contractLinesUniqueness: builder.query<boolean, { contractID: string, name: string }>({
      query: (body) => ({
        method: 'POST',
        url: 'contract_lines/unique',
        body,
      }),
    }),
  }),
});

export const {
  useGetAllContractLinesQuery,
  useGetContractLinesByContractIdsQuery,
  useDeleteContractLinesMutation,
  useAddContractLineMutation,
  useUpdateContractLineMutation,
  useCancelContractLinesMutation,
  useRenewContractLineMutation,
  useBulkEditContractLinesMutation,
  useLazyContractLinesUniquenessQuery,
} = contractLinesApi;