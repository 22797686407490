export enum AccountsTags {
  AccountMappings = 'AccountMappings',
  AccountTypes = 'AccountTypes',
  Accounts = 'Accounts',
  FactaAccounts = 'FactaAccounts',
}

export enum AuthTags {
  User = 'User',
}

export enum CompaniesTags {
  Company = 'Company',
}

export enum ContractsTags {
  Contracts = 'Contracts',
  ContractsSummary = 'ContractsSummary',
}

export enum ContractLinesTags {
  ContractLines = 'ContractLines',
  ContractLinesByContractIds = 'ContractLinesByContractIds',
}

export enum CustomersTags {
  Customers = 'Customers',
}

export enum CustomReportsTags {
  CustomReportData = 'CustomReportData',
  LatestCustomReportSync = 'LatestCustomReportSync',
}

export enum ProductsTags {
  Products = 'Products',
}

export enum ReportsTags {
  Reports = 'Reports',
  Report = 'Report',
  ReportPeriods = 'ReportPeriods',
  ReportVersions = 'ReportVersions',
}

export enum SubscriptionsTags {
  Subscriptions = 'Subscriptions',
}

export enum TagsTags {
  Tags = 'Tags',
}

export enum UsersTags {
  Users = 'Users',
}

export enum MetricsTags {
  Metric = 'Metric',
  Metrics = 'Metrics',
  Chart = 'Chart',
  RevenueDetail = 'RevenueDetail',
  MetricsWithValues = 'MetricsWithValues',
  OneMetricWithValues = 'OneMetricWithValues',
  MetricDependants = 'MetricDependants',
  MetricsDependants = 'MetricsDependants',
  MetricsSummary = 'MetricsSummary',
}

export enum IntegrationsTags {
  Integrations = 'Integrations',
}

export enum DraftsTags {
  Draft = 'Draft',
}

export enum CommissionTags {
  Sales = 'Sales',
  Management = 'Management',
  SDR = 'SDR',
}

export const AllTags = {
  ...AccountsTags,
  ...AuthTags,
  ...CompaniesTags,
  ...ContractsTags,
  ...ContractLinesTags,
  ...CustomersTags,
  ...CustomReportsTags,
  ...ProductsTags,
  ...ReportsTags,
  ...SubscriptionsTags,
  ...TagsTags,
  ...UsersTags,
  ...MetricsTags,
  ...IntegrationsTags,
  ...DraftsTags,
  ...CommissionTags,
};

export const CommonMetricTags = [
  MetricsTags.Metric,
  MetricsTags.Metrics,
  MetricsTags.MetricsWithValues,
  MetricsTags.Chart,
  MetricsTags.RevenueDetail,
  MetricsTags.MetricDependants,
  MetricsTags.MetricsDependants,
  MetricsTags.MetricsSummary,
  ReportsTags.Report,
  ReportsTags.Reports,
  ReportsTags.ReportPeriods,
];

export const tagTypes = Object.values(AllTags);
