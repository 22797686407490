import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import { InfoIcon } from 'assets/icons';
import {
  StyledTooltipIcon,
  StyledTooltipText,
  StyledTrigger,
} from './styled';

interface Props {
  children?: JSX.Element;
  title?: string;
  position?: Placement;
  hidden?: boolean;
  maxWidth?: number;
  pullLeft?: boolean;
  inline?: boolean;
}

export const Tooltip = ({
  children,
  title,
  position,
  hidden,
  maxWidth,
  pullLeft,
  inline,
}: Props) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: position || 'bottom',
    strategy: 'absolute',
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          altAxis: true,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  const handleShow = () => {
    update && update();
    popperElement?.setAttribute('data-show', '');
  };

  const handleHide = () => {
    popperElement?.removeAttribute('data-show');
  };

  if (!title) return children || <StyledTooltipIcon><InfoIcon /></StyledTooltipIcon>;

  return (
    <>
      <StyledTrigger
        ref={setReferenceElement}
        onMouseEnter={handleShow}
        onMouseLeave={handleHide}
        onFocus={handleShow}
        onBlur={handleHide}
        pullLeft={pullLeft}
        inline={inline}
      >
        {children || <StyledTooltipIcon><InfoIcon /></StyledTooltipIcon>}
      </StyledTrigger>
      {!hidden && (
        <StyledTooltipText
          ref={setPopperElement}
          style={styles.popper}
          maxWidth={maxWidth}
          {...attributes.popper}
        >
          {title}
        </StyledTooltipText>
      )}
    </>
  );
};
