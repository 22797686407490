export const actionBlocker = (action: () => void, blocker: boolean, message?: string) => {
  if (blocker) {
    const shouldAllowAction = window.confirm(message || 'There are unsaved changes, are you sure you want to leave?');

    if (shouldAllowAction) {
      action();
    }
  } else {
    action();
  }
};
