import styled from "styled-components";
import { theme } from "theme/theme";

export const StyledOnboardingStep = styled.div`
  display: flex;
  margin-top: 24px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;

  p {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
  }

  div {
    min-width: 36px;
  }

  div:last-of-type {
    margin-left: auto;
    color: ${theme.colors.primary};
  }
`;
