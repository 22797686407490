import React from 'react';
import {
  ArrowLeftIcon,
  CloseIcon,
} from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import {
  StyledFormContainer,
  StyledButtonContainer,
  StyledFormSectionLabel,
} from 'components/common/Forms/styled';
import { SectionLabel } from 'components/common/SectionLabel';
import { toastSuccess } from 'utils/toast';
import { Button } from 'components/common/Button';
import { useUnlinkContractsMutation } from 'store/services/contracts';
import { Contracts } from 'interfaces/contracts';
import { useAppSelector } from 'hooks/redux';
import { contractUnlinkPayloadSelector } from 'store/selectors/contracts';
import { InputBasic } from 'components/common/Input';

interface Props {
  onClose: () => void;
  onGoBack: () => void;
  selectedRows: Contracts;
}

export const UnlinkContract = ({
  onClose,
  onGoBack,
  selectedRows,
}: Props) => {
  const [unlinkContracts, { isLoading }] = useUnlinkContractsMutation();
  const {
    unlinkedContractName,
    ...payload
  } = useAppSelector(contractUnlinkPayloadSelector);

  const handleUnlink = () => {
    unlinkContracts(payload)
      .unwrap()
      .then(() => {
        toastSuccess('Contract successfully unlinked.');
        onClose();
      });
  };

  return (
    <>
      <header>
        <ButtonsContainer paddingBottom={16}>
          <Button
            aria-label="Go back"
            variant="icon"
            size="large"
            onClick={onGoBack}
            data-cy="ulc-button-back"
          >
            <ArrowLeftIcon />
          </Button>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={onClose}
            pushRight
            data-cy="ulc-button-close"
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <SectionLabel>
          <span>Unlink Contract</span>
        </SectionLabel>
      </header>
      <main>
        <p>Are you sure you would like to unlink these contracts?</p>
        <StyledFormSectionLabel />
        <StyledFormContainer>
          <InputBasic
            name="curr"
            labelText="Current Contract"
            disabled
            value={selectedRows[0].name}
          />
          <InputBasic
            name="nextprev"
            labelText={`${payload.nextContractIds.length > 0 ? 'Next' : 'Previous'} Contract`}
            disabled
            value={unlinkedContractName}
          />
        </StyledFormContainer>
      </main>
      <footer>
        <StyledButtonContainer pushRight>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={onGoBack}
            disabled={isLoading}
            data-cy="ulc-button-save-back"
          >
            CANCEL
          </Button>
          <Button
            type="submit"
            onClick={handleUnlink}
            disabled={isLoading}
            isLoading={isLoading}
            data-cy="ulc-button-save"
          >
            UNLINK
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};
