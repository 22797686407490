import styled, { css } from "styled-components";
import { theme } from "theme/theme";

interface StyledItemComponentProps {
  selected?: boolean;
  active?: boolean;
  disabled?: boolean;
  badgeColor?: string;
}

export const StyledItemComponent = styled.div<StyledItemComponentProps>`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 12px;
  
  span {
    word-break: normal;
  }
    
  :hover,
  :focus {
    background: ${theme.colors.primaryBleach};
    outline: none;
  }
  
  ${props => props.active && css`
    background: ${theme.colors.primaryBleach};
  `}
    
  ins {
    text-decoration: none;
    border: 1px solid ${theme.colors.fieldBorder};
    border-radius: 2px;
    padding: 0 3px;
    margin-left: 8px;
    font-size: 9px;
    text-transform: uppercase;
    color: ${props => props.disabled ? theme.colors.textDisabled : props.badgeColor || theme.colors.textDisabled};
    background-color: ${theme.colors.secondaryBackground};
    vertical-align: top;
    white-space: nowrap;
  }
`;
