import { AgGridReact } from "@ag-grid-community/react";
import {
  GridOptions,
  IAggFuncParams,
  ProcessCellForExportParams,
} from "@ag-grid-community/core";
import { ANIMATION_DURATION } from "./constants";
import { Entity } from "interfaces/common";
import { isoToDate } from "./dates";

export const cellProcessor = ({ value, column }: ProcessCellForExportParams<any, any>): string => {
  switch (column.getColId()) {
    case 'next':
    case 'previous':
      return value.map((item: any) => item.name);
    default:
      return value;
  }
};

export const commonGridOptions: GridOptions = {
  unSortIcon: true,
  doesExternalFilterPass: () => true,
  getRowId: (params) => params.data.id,
  maintainColumnOrder: true,
  tooltipShowDelay: 0,
  onFirstDataRendered: (params) => {
    params.api.autoSizeColumn('ag-Grid-AutoColumn');
  },
  onColumnRowGroupChanged: (params) => {
    params.api.autoSizeColumn('ag-Grid-AutoColumn');
  },
  onRowGroupOpened: (params) => {
    params.api.autoSizeColumn('ag-Grid-AutoColumn');
  },
  enableRangeSelection: true,
  processCellForClipboard: cellProcessor,
  defaultCsvExportParams: {
    processCellCallback: cellProcessor,
    skipRowGroups: true,
  },
  defaultExcelExportParams: {
    processCellCallback: cellProcessor,
    skipRowGroups: true,
  },
  autoGroupColumnDef: {
    cellRendererParams: {
      innerRenderer: "groupColumnCellRenderer",
    },
    pinned: "left",
  },
  components: {
    groupColumnCellRenderer: (props: any) => props.value,
  },
  rowGroupPanelShow: "onlyWhenGrouping",
  rowSelection: 'multiple',
  animateRows: true,
  icons: {
    rowDrag: '<i class="ag-icon ag-icon-columns"/>',
  },
  popupParent: document.querySelector("body"),
};

export const numericComparator = (key?: string) => (valueA: any, valueB: any ) =>
  (key ? String(valueA?.[key]) : String(valueA))
    .localeCompare(key ? String(valueB?.[key]) : String(valueB), 'en', { numeric: true });

export const caseInsensitiveComparator = (key?: string) => (valueA: any, valueB: any ) => {
  const getValue = (value: any): string => key ? value?.[key]?.toString() || '' : value?.toString() || '';

  return getValue(valueA)
    .localeCompare(getValue(valueB), undefined, { numeric: true, sensitivity: 'base' });
};

export const entityComparator = <T extends Entity>(valueA: T[], valueB: T[] ) => valueA.map(entity => entity.name)
  .toString()
  .toLowerCase()
  .localeCompare(valueB.map(entity => entity.name)
    .toString()
    .toLowerCase());

export const dateComparator = (key?: string) => (valueA: any, valueB: | any ) => {
  const date1Number = new Date(key ? valueA?.[key] : valueA)
    .getTime();
  const date2Number = new Date(key ? valueB?.[key] : valueB)
    .getTime();

  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }

  return date1Number - date2Number;
};

/**
 * Updates table's row by id
 * @param id string
 * @param data any object like: Product, Customer, etc.
 * @param gridRef gridRef of current table instance
 */
export const updateNodeById = (id: string, data: any, gridRef: React.RefObject<AgGridReact>) => {
  gridRef.current?.api.forEachNode((node) => {
    if (node.data.id === id) {
      node.updateData(data);
    }
  });
};

export const highlightNodeById = (id: string | undefined, gridRef: React.RefObject<AgGridReact>, noFocus?: boolean) => {
  if (id) {
    setTimeout(() => {
      const newRowNode = gridRef.current?.api.getRowNode(id)!;

      if (newRowNode) {
        const newRowId = newRowNode.rowIndex || 0;
        gridRef.current!.api.ensureIndexVisible(newRowId);
        if (!noFocus) {
          gridRef.current!.api.setFocusedCell(newRowId, 'name');
        }
        gridRef.current?.api.flashCells({ rowNodes: [newRowNode] });
      }
    }, ANIMATION_DURATION);
  }
};

export const highlightColumnsByIds = (ids: string[] | undefined, gridRef: React.RefObject<AgGridReact>) => {
  if (ids) {
    setTimeout(() => {
      gridRef.current?.api.flashCells({ columns: ids });
    }, ANIMATION_DURATION);
  }
};


export const reselectNodeById = (id: string, gridRef: React.RefObject<AgGridReact>) => {
  const node = gridRef.current?.api.getRowNode(id);
  node?.setSelected(false);
  node?.setSelected(true);
};

export const scrollToGridBottom = () => {
  setTimeout(() => {
    document.getElementsByClassName('ag-body-viewport')[0]?.scrollTo(0,9999999);
  }, 100);
};

export const scrollToGridRight = () => {
  setTimeout(() => {
    document.getElementsByClassName('ag-center-cols-viewport')[0]?.scrollTo({ left: 9999999 });
  }, 0);
};

export const deselectAll = (gridRef: React.RefObject<AgGridReact<any>>) => gridRef?.current?.api.setServerSideSelectionState({
  selectAll: false,
  toggledNodes: [],
});

export const sumFunction = (params: IAggFuncParams) => params.values.reduce((acc, val) => typeof val === 'number' && acc + val, 0);

export const roundedSumAggFunction = (params: IAggFuncParams) => Math.round(sumFunction(params) * 100) / 100;

export const filterDateComparator = (filterLocalDateAtMidnight: Date, cellValue: string) => {
  if (cellValue == null) return 0;
  const cellDate = isoToDate(cellValue.substring(0, 10))!;

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
};
