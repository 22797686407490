import styled from "styled-components";
import { theme } from "theme/theme";

export const StyledName = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${theme.colors.textDark};
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  button {
    margin-top: 4px;
  }
`;

export const StyledMessage = styled.div<{ isLoading?: boolean }>`
  @keyframes blink {
    0% {
      background-color: ${theme.colors.white};
    }
    2% {
      background-color: ${theme.colors.white};
    }
    10% {
      background-color: ${theme.colors.primaryBleach};
    }
    100% {
      background-color: ${theme.colors.white};
    }
  }

  @keyframes loading {
    0% {
      background-color: ${theme.colors.white};
    }
    50% {
      background-color: ${theme.colors.warningBleach};
    }
    100% {
      background-color: ${theme.colors.white};
    }
  }
  
  display: flex;
  align-items: flex-start;
  margin: 10px 0;
  color: ${theme.colors.textDisabled};
  line-height: 1.4;

  > button {
    flex-shrink: 0;
    width: 28px;
    height: 28px;
    font-size: 12px;
  }

  > div {
    margin: 0;
    padding: 5px 8px;
    margin-left: 4px;
    animation-name: ${props => props.isLoading ? 'loading' : 'blink'};
    animation-duration: ${props => props.isLoading ? '1s' : '2.2s'};
    animation-iteration-count: ${props => props.isLoading ? 'infinite' : '1'};
    border-radius: 4px;
    font-size: 14px;
  }

  &:not(:last-child) {
    ${StyledContent} {
      button {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
`;
