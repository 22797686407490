import React, {
  Dispatch,
  SetStateAction,
} from 'react';
import * as yup from "yup";
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputBasic } from 'components/common/Input';
import {
  CompanyFormFields,
  CompanySecondStepFormFields,
} from 'interfaces/company';
import {
  StyledButtonContainer,
  StyledFormContainer,
} from 'components/common/Forms/styled';
import { Button } from 'components/common/Button';
import { Dropdown } from 'components/common/Dropdown';
import {
  IndustryTypeOptions,
  BusinessTypeOptions,
  BusinessStageOptions,
} from 'utils/constants';
import { useGetCurrenciesQuery } from 'store/services/companies';

interface Props {
  setStep: Dispatch<SetStateAction<number>>;
  setCompanyInfo: Dispatch<SetStateAction<CompanyFormFields>>;
  companyInfo: CompanyFormFields;
}

export const SecondStepForm = ({
  setStep,
  setCompanyInfo,
  companyInfo,
}: Props) => {
  const { data: currencies, isFetching: isLoadingCurrencies } = useGetCurrenciesQuery();

  const schema = yup.object({
    currencyCode: yup.array()
      .min(1, 'Currency is required.'),
    domain: yup.string(),
    industry: yup.array(),
    businessType: yup.array(),
    businessStage: yup.array(),
  })
    .required();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<CompanySecondStepFormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      currencyCode: companyInfo.currencyCode,
      domain: companyInfo.domain,
      industry: companyInfo.industry,
      businessType: companyInfo.businessType,
      businessStage: companyInfo.businessStage,
    },
  });

  const handleBack = () => {
    const data = getValues();
    setCompanyInfo({
      ...companyInfo,
      currencyCode: data.currencyCode,
      domain: data.domain,
      industry: data.industry,
      businessType: data.businessType,
      businessStage: data.businessStage,
    });
    setStep(1);
  };

  const handleContinue = handleSubmit((data) => {
    setCompanyInfo({
      ...companyInfo,
      currencyCode: data.currencyCode,
      domain: data.domain,
      industry: data.industry,
      businessType: data.businessType,
      businessStage: data.businessStage,
    });
    setStep(3);
  });

  return (
    <>
      <StyledFormContainer>
        <Controller
          name="currencyCode"
          control={control}
          render={({ field }) =>
            <Dropdown
              isRequired
              labelText="Currency"
              options={currencies || []}
              labelField="name"
              valueField="code"
              searchBy="name"
              values={getValues('currencyCode')}
              {...field}
              loading={isLoadingCurrencies}
              error={errors.currencyCode?.message}
            />
          }
        />
        <InputBasic
          isOptional
          labelText="Domain"
          placeholder="www.facta.io"
          {...register('domain')}
          error={errors.domain?.message}
        />
        <Controller
          name="industry"
          control={control}
          render={({ field }) =>
            <Dropdown
              isOptional
              labelText="Industry"
              options={IndustryTypeOptions}
              labelField="value"
              valueField="value"
              searchBy="value"
              placeholder="Select Industry"
              values={getValues('industry')}
              {...field}
              error={errors.industry?.message}
            />
          }
        />
        <Controller
          name="businessType"
          control={control}
          render={({ field }) =>
            <Dropdown
              isOptional
              labelText="Business Type"
              options={BusinessTypeOptions}
              labelField="value"
              valueField="value"
              searchBy="value"
              placeholder="Select Business Type"
              values={getValues('businessType')}
              {...field}
              error={errors.businessType?.message}
            />
          }
        />
        <Controller
          name="businessStage"
          control={control}
          render={({ field }) =>
            <Dropdown
              isOptional
              labelText="Business Stage"
              options={BusinessStageOptions}
              labelField="value"
              valueField="value"
              searchBy="value"
              placeholder="Select Business Stage"
              values={getValues('businessStage')}
              {...field}
              error={errors.businessStage?.message}
            />
          }
        />
        <StyledButtonContainer marginTop={36}>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={handleBack}
          >
            BACK
          </Button>
          <Button
            type="submit"
            onClick={handleContinue}
          >
            CONTINUE
          </Button>
        </StyledButtonContainer>
      </StyledFormContainer>
    </>
  );
};

