import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useForm,
  Controller,
} from 'react-hook-form';
import * as yup from "yup";
import {
  ChevronDown,
  CloseIcon,
  DeleteIcon,
} from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import {
  StyledButtonContainer,
  StyledFormSectionLabel,
  StyledFormContainer,
  StyledAdditionalOptionsContainer,
  StyledExpandButton,
} from 'components/common/Forms/styled';
import { Button } from 'components/common/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { Datepicker } from 'components/common/Datepicker';
import { Dropdown } from 'components/common/Dropdown';
import { InputBasic } from 'components/common/Input';
import { NumberInput } from 'components/common/NumberInput';
import { AssistiveText } from 'components/common/Input/AssistiveText';
import { useGetAllCustomersQuery } from 'store/services/customers';
import { useGetAllProductsQuery } from 'store/services/products';
import {
  formatDateToISO,
  isoToDate,
} from 'utils/dates';
import { bulkImportSubsGetRowByAgGridId } from 'store/selectors/bulkImport';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import { bulkImportActions } from 'store/slices/bulkImport';
import {
  BulkImportSubscriptionsFormFields,
  BulkImportSubscriptionsRow,
} from 'interfaces/bulkImportSubscriptions';
import { actionBlocker } from 'utils/actionBlocker';
import { useGetAllTagsQuery } from 'store/services/tags';
import { Tooltip } from 'components/common/Tooltip';
import { useGetAllSubscriptionsQuery } from 'store/services/subscriptions';
import { TagType } from 'interfaces/tags';
import { tagsSortFn } from 'utils/tags';
import { formStateActions } from 'store/slices/formState';

interface Props {
  id: string;
  onClose: () => void;
  onDelete: () => void;
  onAddTag: () => void;
  onAddProduct: () => void;
  onAddCustomer: () => void;
}

export const BulkImportEdit = ({
  id,
  onClose,
  onDelete,
  onAddTag,
  onAddProduct,
  onAddCustomer,
}: Props) => {
  const dispatch = useAppDispatch();
  const selectedRow = useAppSelector(bulkImportSubsGetRowByAgGridId(id))!;
  const [isOptionsExpanded, setIsOptionsExpanded] = useState(false);
  const [isErrorOnTags, setIsErrorOnTags] = useState(!!selectedRow.tags.providedValue && !!selectedRow.tags.errorCode);
  const [isErrorOnRenewedSubscription, setIsErrorOnRenewedSubscription] = useState(!!selectedRow.renewedSubscription.errorCode);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | undefined>(selectedRow.customer.matchedValue?.id);

  const { data: subscriptions } = useGetAllSubscriptionsQuery();

  const excludedSubscriptionsNames = subscriptions
    ?.filter((sub) => !sub.deleted && sub.customer.id === selectedCustomerId)
    ?.map((sub) => sub.name);

  const {
    data: customersAll,
  } = useGetAllCustomersQuery();
  const customers = useMemo(() => customersAll?.filter((cust) => !cust.deleted), [customersAll]);

  const {
    data: productsAll,
  } = useGetAllProductsQuery();
  const products = useMemo(() => productsAll?.filter((prod) => !prod.deleted), [productsAll]);

  const {
    data: tags,
  } = useGetAllTagsQuery();

  const tagOptions = useMemo(() => tags
    ?.filter((tag) => !tag.deleted && tag.type === TagType.CONTRACT)
    ?.sort(tagsSortFn) || [],
    [tags]);

  const defaultValues = useMemo(() => ({
    customer: [],
    product: [],
    name: '',
    amount: '',
    startDate: undefined,
    endDate: undefined,
    cancelDate: undefined,
    bookingDate: undefined,
    revRecStartDate: undefined,
    revRecEndDate: undefined,
    crmId: '',
    tags: [],
  }), []);

  const schema = yup.object({
    customer: yup.array()
      .of(
        yup.object({
          id: yup.string(),
          name: yup.string(),
        }))
      .min(1, 'Customer is required.'),
    product: yup.array()
      .of(
        yup.object({
          id: yup.string(),
          name: yup.string(),
        }))
      .min(1, 'Product is required.'),
    subscriptionName: yup.string()
      .required('Revenue name is required.')
      .test(
        'isUnique',
        'Subscription name must be unique.',
        (val) => !(excludedSubscriptionsNames?.includes(val || '')),
      ),
    amount: yup.string()
      .required('Amount is required.'),
    startDate: yup.date()
      .nullable()
      .required('Start date is required.'),
    endDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'End date cannot be before start date.'),
      })
      .required('End date is required.'),
    bookingDate: yup.date()
      .when('endDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .max(yup.ref('endDate'),
            'Booking date cannot be after end date.'),
      })
      .nullable(),
    cancelDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'Cancel date cannot be before start date.'),
      }),
    revRecStartDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'Revenue recognition start date cannot be before start date.'),
      }),
    revRecEndDate: yup.date()
      .nullable()
      .when('revRecStartDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('revRecStartDate'),
            'Revenue recognition end date cannot be before Revenue recognition start date.')
          .required('When Revenue recognition start date provided this field is required.'),
      }),
    renewedSubscription: yup.string(),
    crmId: yup.string(),
    tags: yup.array(),
  })
    .required();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty, dirtyFields },
    reset,
    trigger,
  } = useForm<BulkImportSubscriptionsFormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const triggerTouched = (field: any) => ((dirtyFields as Record<string, boolean | undefined>)[field]) && trigger(field);

  const prepareBulkImportRowPayload = (data: BulkImportSubscriptionsFormFields): BulkImportSubscriptionsRow => {
    return {
      id: selectedRow?.id,
      customer: {
        providedValue: data.customer[0].name,
        matchedValue: data.customer[0],
        errorCode: null,
      },
      product: {
        providedValue: data.product[0].name,
        matchedValue: data.product[0],
        errorCode: null,
      },
      subscriptionName: {
        providedValue: data.subscriptionName,
        errorCode: null,
      },
      amount: {
        providedValue: data.amount ? data.amount : null,
        matchedValue: data.amount ? Number(data.amount) : null,
        errorCode: null,
      },
      startDate: {
        providedValue: formatDateToISO(data.startDate!),
        matchedValue: formatDateToISO(data.startDate!),
        errorCode: null,
      },
      endDate: {
        providedValue: formatDateToISO(data.endDate!),
        matchedValue: formatDateToISO(data.endDate!),
        errorCode: null,
      },
      cancelDate: {
        providedValue: data.cancelDate ? formatDateToISO(data.cancelDate!) : null,
        matchedValue: data.cancelDate ? formatDateToISO(data.cancelDate!) : null,
        errorCode: null,
      },
      bookingDate: {
        providedValue: data.bookingDate ? formatDateToISO(data.bookingDate!) : null,
        matchedValue: data.bookingDate ? formatDateToISO(data.bookingDate!) : null,
        errorCode: null,
      },
      revRecStartDate: {
        providedValue: data.revRecStartDate ? formatDateToISO(data.revRecStartDate!) : null,
        matchedValue: data.revRecStartDate ? formatDateToISO(data.revRecStartDate!) : null,
        errorCode: null,
      },
      revRecEndDate: {
        providedValue: data.revRecEndDate ? formatDateToISO(data.revRecEndDate!) : null,
        matchedValue: data.revRecEndDate ? formatDateToISO(data.revRecEndDate!) : null,
        errorCode: null,
      },
      renewedSubscription: {
        providedValue: data.renewedSubscription,
        errorCode: null,
      },
      crmId: {
        providedValue: data.crmId,
        errorCode: null,
      },
      tags: {
        providedValue: data.tags.map((tag) => tag.name)
          .toString(),
        matchedValue: data.tags,
        errorCode: null,
      },
      errors: null,
    };
  };

  const handleAddTag = ({ name }: { name: string }) => {
    dispatch(formStateActions.setPredefinedData({
      name,
      type: TagType.CONTRACT,
    }));
    onAddTag();
  };

  const handleAddProduct = ({ name }: { name: string }) => {
    dispatch(formStateActions.setPredefinedData({ name }));
    onAddProduct();
  };

  const handleAddCustomer = ({ name }: { name: string }) => {
    dispatch(formStateActions.setPredefinedData({ name }));
    onAddCustomer();
  };

  const handleSaveAndClose = handleSubmit((data) => {
    const bulkImportRow = prepareBulkImportRowPayload(data);

    dispatch(bulkImportActions.updateSubsRow({
      id,
      data: bulkImportRow,
    }));
    onClose();
  });

  const handleSave = handleSubmit((data) => {
    const bulkImportRow = prepareBulkImportRowPayload(data);

    dispatch(bulkImportActions.updateSubsRow({
      id,
      data: bulkImportRow,
    }));
  });

  useEffect(() => {
    const product = products?.find((prod) => prod.id === selectedRow.product.matchedValue?.id);
    const customer = customers?.find((cust) => cust.id === selectedRow.customer.matchedValue?.id);

    reset({
      customer: customer ? [customer] : [],
      product: product ? [product] : [],
      subscriptionName: selectedRow.subscriptionName.providedValue || '',
      amount: `${selectedRow.amount?.matchedValue !== null ? selectedRow.amount?.matchedValue : ''}`,
      startDate: isoToDate(selectedRow.startDate?.matchedValue),
      endDate: isoToDate(selectedRow.endDate?.matchedValue),
      cancelDate: isoToDate(selectedRow.cancelDate?.matchedValue),
      bookingDate: isoToDate(selectedRow.bookingDate?.matchedValue),
      revRecStartDate: isoToDate(selectedRow.revRecStartDate?.matchedValue),
      revRecEndDate: isoToDate(selectedRow.revRecEndDate?.matchedValue),
      renewedSubscription: selectedRow.renewedSubscription.providedValue || '',
      crmId: selectedRow.crmId?.providedValue || '',
      tags: selectedRow.tags.matchedValue || [],
    });

    setIsErrorOnTags(!!selectedRow.tags.providedValue && !!selectedRow.tags.errorCode);
    setIsErrorOnRenewedSubscription(!!selectedRow.renewedSubscription.errorCode);

    trigger();
  }, [customers, products, reset, selectedRow, trigger, tagOptions]);

  useEffect(() => {
    triggerTouched('subscriptionName');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomerId]);

  return (
    <>
      <header>
        <ButtonsContainer>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={() => actionBlocker(onClose, isDirty)}
            pushRight
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <StyledFormSectionLabel data-cy="es-header">
          <span>
            Edit Revenue
          </span>
        </StyledFormSectionLabel>
        <ButtonsContainer paddingBottom={20}>
          <Tooltip title="Delete">
            <Button
              aria-label="Delete"
              variant="icon"
              size="large"
              onClick={onDelete}
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        </ButtonsContainer>
      </header>
      <main>
        <StyledFormContainer>
          <Controller
            name="customer"
            control={control}
            render={({ field }) =>
              <div>
                <Dropdown
                  labelText="Customer"
                  labelField="name"
                  valueField="id"
                  searchBy="name"
                  placeholder="Select Customer"
                  options={customers || []}
                  values={getValues('customer')}
                  {...field}
                  error={errors.customer?.message}
                  isRequired
                  create
                  onCreateNew={handleAddCustomer}
                  onChange={(customer) => {
                    setSelectedCustomerId(customer.at(0)?.id);
                    field.onChange(customer);
                  }}
                />
                {selectedRow.customer.errorCode === 'renewal_mismatch' && (
                  <AssistiveText
                    error
                    text="Provided value doesn't match with reveue that is being renewed."
                  />
                )}
              </div>
            }
          />
          <Controller
            name="product"
            control={control}
            render={({ field }) =>
              <div>
                <Dropdown
                  labelText="Product"
                  labelField="name"
                  valueField="id"
                  searchBy="name"
                  placeholder="Select Product SKU"
                  options={products || []}
                  values={getValues('product')}
                  {...field}
                  error={errors.product?.message}
                  isRequired
                  create
                  onCreateNew={handleAddProduct}
                />
                <AssistiveText text={getValues('product')[0]?.revenueType} />
                {selectedRow.product.errorCode === 'renewal_mismatch' && (
                  <AssistiveText
                    error
                    text="Provided value doesn't match with revenue that is being renewed."
                  />
                )}
              </div>
            }
          />
          <InputBasic
            isRequired
            labelText="Revenue Name"
            placeholder="Name your revenue"
            {...register('subscriptionName')}
            error={errors.subscriptionName?.message}
          />
          <Controller
            name="amount"
            control={control}
            render={({ field }) =>
              <NumberInput
                labelText="Amount"
                {...field}
                onChange={field.onChange}
                placeholder="Enter Amount"
                error={errors.amount?.message}
                tooltip="Enter the total value of the revenue. For month-to-month revenue, enter the monthly amount."
                isRequired
              />
            }
          />
          <Controller
            name="startDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <div>
                <Datepicker
                  labelText="Start Date"
                  placeholder="Select Date"
                  {...field}
                  onChange={(date) => {
                    field.onChange(date);
                    triggerTouched('bookingDate');
                    triggerTouched('endDate');
                    triggerTouched('cancelDate');
                    triggerTouched('revRecStartDate');
                  }}
                  error={errors.startDate?.message}
                  isRequired
                />
                {selectedRow.startDate.errorCode === 'renewal_mismatch' && (
                  <AssistiveText
                    error
                    text="Provided value doesn't match with revenue that is being renewed."
                  />
                )}
              </div>
            }
          />
          <Controller
            name="bookingDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Different Booking Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('startDate');
                  triggerTouched('endDate');
                  triggerTouched('cancelDate');
                }}
                error={errors.bookingDate?.message}
                isOptional
                tooltip="Fill in only when Booking Date is different from Start Date"
              />
            }
          />
          <Controller
            name="endDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="End Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('bookingDate');
                  triggerTouched('startDate');
                  triggerTouched('cancelDate');
                }}
                error={errors.endDate?.message}
                tooltip="This revenue will remain active until it is renewed or cancelled."
                isRequired
              />
            }
          />
          <Controller
            name="cancelDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Cancel Date"
                placeholder="Cancel Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('startDate');
                  triggerTouched('bookingDate');
                  triggerTouched('endDate');
                }}
                error={errors.cancelDate?.message}
                tooltip="Entering a cancel date will terminate the revenue upon that date. You can add it now or later."
                isOptional
              />
            }
          />
          <Controller
            name="revRecStartDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Recognition Start Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                }}
                error={errors.revRecStartDate?.message}
                tooltip="Enter revenue recognition start date. You can add it now or later."
                isOptional
              />
            }
          />
          <Controller
            name="revRecEndDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Recognition End Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                }}
                error={errors.revRecEndDate?.message}
                tooltip="Enter revenue recognition end date. You can add it now or later."
                isOptional
              />
            }
          />
          <InputBasic
            labelText="Renewed Revenue"
            placeholder="Reference renewed revenue"
            {...register('renewedSubscription')}
            error={isErrorOnRenewedSubscription ? 'Renewal mismatch' : undefined}
            tooltip="Reference revenue"
            onCustomChange={() => setIsErrorOnRenewedSubscription(false)}
          />
          <StyledExpandButton
            variant="borderless"
            size="large"
            type="button"
            onClick={() => setIsOptionsExpanded(!isOptionsExpanded)}
            isOpen={isOptionsExpanded}
          >
            Additional Options
            <ChevronDown />
          </StyledExpandButton>
          <StyledAdditionalOptionsContainer isOpen={isOptionsExpanded}>
            <Controller
              name="tags"
              control={control}
              render={({ field }) =>
                <Dropdown
                  labelText="Tags"
                  labelField="name"
                  valueField="id"
                  searchBy="name"
                  options={tagOptions}
                  placeholder="Select Tags"
                  entityName="tag"
                  values={getValues('tags')}
                  {...field}
                  isOptional
                  clearable
                  multi
                  error={isErrorOnTags ? 'Wrong tags provided' : undefined}
                  tooltip="Categorize this revenue with a contract tag(s)."
                  onDropdownOpen={() => setIsErrorOnTags(false)}
                  onClearAll={() => setIsErrorOnTags(false)}
                  create
                  onCreateNew={handleAddTag}
                />
              }
            />
            <InputBasic
              isOptional
              labelText="CRM ID"
              placeholder="Paste CRM identifier"
              tooltip="Paste in an ID from your CRM to help identify this revenue."
              {...register('crmId')}
              error={errors.crmId?.message}
            />
          </StyledAdditionalOptionsContainer>
        </StyledFormContainer>
      </main>
      <footer>
        <StyledButtonContainer pushRight>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={handleSave}
          >
            SAVE
          </Button>
          <Button
            type="submit"
            onClick={handleSaveAndClose}
          >
            SAVE & CLOSE
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};
