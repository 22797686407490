export const stepsContracts = [
  {
    name: 'customers',
    label: 'Add a customer',
    description: 'Setup your pipeline by adding your first customer to Insights.',
    navigateTo: '/data-input/contract-management/customers#add',

  },
  {
    name: 'products',
    label: 'Add a product',
    description: 'Setup a product to build your contract around.',
    navigateTo: '/data-input/contract-management/products#add',
  },
  {
    name: 'contracts',
    label: 'Add a contract',
    description: 'Build a contract for your customer.',
    navigateTo: '/data-input/contract-management/contracts#add',
  },
  {
    name: 'contractLines',
    label: 'Add a contract line',
    description: 'Add a contract line to your contract.',
    navigateTo: '/data-input/contract-management/contracts/contract-lines#add',
  },
];

export const stepsSubscriptions = [
  {
    name: 'customers',
    label: 'Add a customer',
    description: 'Setup your pipeline by adding your first customer to Insights.',
    navigateTo: '/data-input/revenue-management/customers#add',
  },
  {
    name: 'products',
    label: 'Add a product',
    description: 'Setup a product to build your subscription around.',
    navigateTo: '/data-input/revenue-management/products#add',
  },
  {
    name: 'subscriptions',
    label: 'Add a revenue',
    description: 'Build a revenue for your customer.',
    navigateTo: '/data-input/revenue-management/revenue#add',
  },
];
