import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="inherit">
      <path d="M3.984 11.085a.97.97 0 0 1-.696-.277.977.977 0 0 1-.288-.72c0-.304.096-.544.288-.72a.97.97 0 0 1 .696-.276.97.97 0 0 1 .696.276c.192.176.288.416.288.72a.977.977 0 0 1-.288.72.97.97 0 0 1-.696.277ZM9.741 11.12a3.32 3.32 0 0 1-1.8-.503c-.528-.337-.944-.825-1.248-1.465-.304-.648-.456-1.432-.456-2.352 0-.92.152-1.7.456-2.34.304-.648.72-1.14 1.248-1.476a3.32 3.32 0 0 1 1.8-.504 3.28 3.28 0 0 1 1.8.504c.528.336.944.828 1.248 1.476.312.64.468 1.42.468 2.34 0 .92-.156 1.704-.468 2.352-.304.64-.72 1.129-1.248 1.465a3.28 3.28 0 0 1-1.8.503Zm0-1.356c.392 0 .732-.104 1.02-.312.288-.216.512-.544.672-.984.168-.44.252-.996.252-1.668 0-.68-.084-1.236-.252-1.668-.16-.44-.384-.764-.672-.972a1.652 1.652 0 0 0-1.02-.324c-.376 0-.712.108-1.008.324-.288.208-.516.532-.684.972-.16.432-.24.988-.24 1.668 0 .672.08 1.228.24 1.668.168.44.396.768.684.984a1.71 1.71 0 0 0 1.008.312ZM18.215 11.12a3.32 3.32 0 0 1-1.8-.503c-.528-.337-.944-.825-1.248-1.465-.304-.648-.456-1.432-.456-2.352 0-.92.152-1.7.456-2.34.304-.648.72-1.14 1.248-1.476a3.32 3.32 0 0 1 1.8-.504 3.28 3.28 0 0 1 1.8.504c.528.336.944.828 1.248 1.476.312.64.468 1.42.468 2.34 0 .92-.156 1.704-.468 2.352-.304.64-.72 1.129-1.248 1.465a3.28 3.28 0 0 1-1.8.503Zm0-1.356c.392 0 .732-.104 1.02-.312.288-.216.512-.544.672-.984.168-.44.252-.996.252-1.668 0-.68-.084-1.236-.252-1.668-.16-.44-.384-.764-.672-.972a1.652 1.652 0 0 0-1.02-.324c-.376 0-.712.108-1.008.324-.288.208-.516.532-.684.972-.16.432-.24.988-.24 1.668 0 .672.08 1.228.24 1.668.168.44.396.768.684.984a1.71 1.71 0 0 0 1.008.312ZM16.442 16.558a.625.625 0 0 1 0 .884l-3.978 3.977a.625.625 0 1 1-.883-.884L15.116 17l-3.535-3.536a.625.625 0 1 1 .883-.883l3.978 3.977ZM8 16.375h8v1.25H8v-1.25Z" />
    </g>
  </svg>
);

export default SvgComponent;
