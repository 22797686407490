import React, { MutableRefObject } from 'react';
import {
  ContentContainer,
  ContentBody,
} from "../styled";

interface Props {
  children: React.ReactNode;
  removeMargin?: boolean;
  contentWidth?: number;
  secondary?: boolean
  _ref?: MutableRefObject<HTMLDivElement | null>;
}

export const LeftPane = ({
  children,
  removeMargin,
  contentWidth,
  secondary,
  _ref,
}: Props) => (
  <ContentContainer secondary={secondary}>
    <ContentBody
      ref={_ref}
      removeMargin={removeMargin}
      contentWidth={contentWidth}
    >
      {children}
    </ContentBody>
  </ContentContainer>
);
