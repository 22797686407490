import { CompanyRevenueType } from 'interfaces/company';
import { DetailTable } from 'components/DetailTable';

interface Props {
  companyRevenueType?: CompanyRevenueType;
}

export const RevenueDetails = ({ companyRevenueType }: Props) => (
  <DetailTable
    companyRevenueType={companyRevenueType}
    tableType="revenue_details"
    title="Revenue Details"
  />
);
