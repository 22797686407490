import * as React from "react";

const SvgComponent = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 4V6H2V20H22V6H19V4H13V6H11V4H5ZM7 6H9V8H15V6H17V8H18H20V18H4V8H7V6Z"
      fill="inherit"
    />
  </svg>
);

export default SvgComponent;
