import {
  toast,
  ToastOptions,
} from "react-toastify";

export const toastSuccess = (message: string, options?: ToastOptions) => toast.success(message, {
  hideProgressBar: true,
  autoClose: 2000,
  position: "top-center",
  ...options,
});

export const toastError = (message: string, options?: ToastOptions) => toast.error(message, options);

export const toastWarning = (message: string, options?: ToastOptions) => toast.warn(message, {
  hideProgressBar: true,
  position: "top-center",
  ...options,
});

export const toastLoading = (message: string, options?: ToastOptions) => toast.loading(message, {
  autoClose: 2000,
  position: "top-center",
  ...options,
});
