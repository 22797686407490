import React from 'react';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { CloseIcon } from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { SectionLabel } from 'components/common/SectionLabel';
import { Button } from 'components/common/Button';
import { InputBasic } from 'components/common/Input';
import { Dropdown } from 'components/common/Dropdown';
import {
  StyledFormContainer,
  StyledButtonContainer,
} from 'components/common/Forms/styled';
import { toastSuccess } from 'utils/toast';
import { actionBlocker } from 'utils/actionBlocker';
import { UserRoleOptions } from 'utils/constants';
import {
  useInviteUserMutation,
  useGetAllCompanyUsersQuery,
} from 'store/services/users';
import { UserRole } from 'interfaces/auth';
import { useGetAuthMeQuery } from 'store/services/auth';
import { getCurrentCompany } from 'utils/currentCompany';

interface Props {
  onClose: () => void;
}

export const InviteUser = ({
  onClose,
}: Props) => {
  const [addUser, { isLoading }] = useInviteUserMutation();
  const { data: user } = useGetAuthMeQuery();
  const currentCompany = getCurrentCompany(user);
  const permissionLevelIndex = UserRoleOptions.findIndex((role) => role.value === currentCompany?.role);
  const filteredUserRoleOptions = UserRoleOptions.filter((_, index) => index >= permissionLevelIndex);

  const { users } = useGetAllCompanyUsersQuery(undefined, {
    selectFromResult: ({ data }) => ({
      users: data
        ?.map((user) => user.email),
    }),
  });

  const schema = yup.object({
    email: yup.string()
      .email('Please provide valid email address.')
      .test(
        'isUnique',
        'User with this email is already added to this company.',
        (val) => !(users?.includes(val || '')),
      )
      .required('Email is required.'),

    role: yup.array()
      .min(1, 'User role is required.')
      .nullable(),
  })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    control,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<{ email: string; role: { value: UserRole }[]; }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      role: [],
    },
  });

  const handleSaveAndNew = handleSubmit((data) => {
    const user = {
      email: data.email,
      role: data.role[0]?.value,
    };

    return addUser(user)
      .unwrap()
      .then(() => {
        toastSuccess('User successfully invited.');
        reset();
        setTimeout(() => clearErrors(), 0);
      });
  });

  const handleSaveAndClose = handleSubmit((data) => {
    const user = {
      email: data.email,
      role: data.role[0]?.value,
    };

    return addUser(user)
      .unwrap()
      .then(() => {
        toastSuccess('User successfully invited.');
        onClose();
      });
  });

  return (
    <>
      <header>
        <ButtonsContainer paddingBottom={16}>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={() => actionBlocker(onClose, isDirty)}
            pushRight
            data-cy="au-button-close"
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <SectionLabel data-cy="au-header">
          <span>
            Invite User
          </span>
        </SectionLabel>
      </header>
      <main>
        <StyledFormContainer>
          <InputBasic
            isRequired
            labelText="Email Address"
            placeholder="you@company.com"
            {...register('email')}
            onBlur={(e) => {
              const fieldValue = e.target.value;

              if (fieldValue) {
                setValue('email', fieldValue.trim(), { shouldValidate: true });
              }
            }}
            error={errors.email?.message}
            dataCy="au-input-email"
          />
          <Controller
            name="role"
            control={control}
            render={({ field }) =>
              <Dropdown
                isRequired
                labelText="User Role"
                options={filteredUserRoleOptions}
                labelField="value"
                valueField="value"
                searchBy="value"
                {...field}
                values={getValues('role')}
                dataCy="au-input-user-role"
                error={errors.role?.message}
              />
            }
          />
        </StyledFormContainer>
      </main>
      <footer>
        <StyledButtonContainer pushRight>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={handleSaveAndClose}
            disabled={isLoading}
            isLoading={isLoading}
            data-cy="au-button-save-close"
          >
            SAVE & CLOSE
          </Button>
          <Button
            type="submit"
            onClick={handleSaveAndNew}
            disabled={isLoading}
            isLoading={isLoading}
            data-cy="au-button-save-new"
          >
            SAVE & NEW
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};
