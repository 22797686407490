import React from 'react';
import { Subscriptions } from 'interfaces/subscriptions';
import { DeleteItems } from 'components/DeleteItems';
import { useDeleteSubscriptionsMutation } from 'store/services/subscriptions';

interface Props {
  onClose: () => void;
  onGoBack?: () => void;
  selectedRows: Subscriptions;
}

export const DeleteSubscriptions = ({
  onClose,
  onGoBack,
  selectedRows,
}: Props) => {
  const [deleteSubscriptions, { isLoading }] = useDeleteSubscriptionsMutation();

  const handleDeleteSubscriptions = () => {
    const ids = selectedRows.map((customer) => customer.id);
    deleteSubscriptions(ids)
      .unwrap()
      .then(() => {
        onClose();
      });
  };
  return (
    <DeleteItems
      selectedRows={selectedRows}
      onClose={onClose}
      onGoBack={onGoBack}
      isLoading={isLoading}
      handleDelete={handleDeleteSubscriptions}
      item="Revenue"
      shortenDescription
    />
  );
};