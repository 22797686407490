import { theme } from 'theme/theme';
import styled from 'styled-components';

export const StyledInitials = styled.div`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  display: grid;
  place-items: center;
  background-color: ${theme.colors.primary};
  color: ${theme.colors.white};
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 16px;
`;

export const StyledContactInfo = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    margin: 4px 0;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin: 4px 0;
  }
`;