import { FinancialType } from "./financials";

export interface AccountMapping {
  account: {
    id: string;
    /** also known as: Heading, Facta Account */
    name: string;
    /** also known as: Category */
    type: string;
  } | null,
  glAccount: {
    id: string;
    name: string;
    source: string;
    /** is it active in QBO (deleted) */
    active: boolean;
    /** does not have any financial data (no transactions) */
    used: boolean;
    number: string | null;
    displayName: string;
    /** QBO type */
    type: string;
  },
  financialsData: {
    monthly: {
      [key: string]: string;
    };
    quarterly: {
      [key: string]: string;
    };
    annual: {
      [key: string]: string;
    };
  }
}

export interface AccountMappings {
  accountMappings: AccountMapping[];
  financialsDataRange: {
    monthly: string[];
    quarterly: string[];
    annual: string[];
  }
}

export enum IntegrationType {
  QBO = 'qbo',
  MANUAL = 'manual',
}

export interface Account {
  id: string;
  name: ProfitAndLossFAEnum | BalanceSheetFAEnum;
  type: string;
  accounts: Array<{
    id: string;
    name: string;
    type: string;
  }>
}

export interface AccountType {
  id: string;
  name: string;
}

export interface AccountMappingParams {
  mappedOnly?: boolean;
  financialType: FinancialType;
}

export interface AccountMappingTableUpdateItem {
  glAccountName: string;
  accountName: string;
  accountType: string;
}

/** AccountMappingUpdateItem
 * Facta accounts that you do not provide will be removed (along with the metrics)
 * Not providing GL account with qbo source will return error
 * Not providing GL account with manual source will simply remove this GL acocunt
 * If you want to create a Facta account without a GL account, glAccounts should be empty
 * If you don't want to map the GL account (or unmap), the id, name and type should be empty
 * If you want a new Facta account, do not enter any id
 * If you want to change Facta account's name, provide id account and new name
 */

export interface AccountMappingTreeUpdateItem {
  glAccounts: Array<{
    id: string;
    name: string;
    source: string;
  }>;
  id: string | null;
  name: string | null;
  type: string | null;
}

export type AccountMappingTableUpdatePayload = AccountMappingTableUpdateItem[];
export type AccountMappingTreeUpdatePayload = AccountMappingTreeUpdateItem[];
export type AccountTypes = AccountType[];
export type Accounts = Account[];

export type PeriodType = 'monthly' | 'quarterly' | 'annual';

export interface GLAccount {
  active: true;
  displayName: string;
  id: string;
  name: string;
  number: string;
  source: string;
  type: string;
  used: boolean;
}

export interface FactaAccount {
  financialType: string;
  glAccounts: GLAccount[];
  id: string;
  name: string;
  type: string;
}

export type FactaAccounts = FactaAccount[];

export enum ProfitAndLossFAEnum {
  'Total Revenue' = 'Total Revenue',
  'Total Cost of Goods Sold' = 'Total Cost of Goods Sold',
  'Total Sales & Marketing' = 'Total Sales & Marketing',
  'Total Research & Development' =  'Total Research & Development',
  'Total General & Administrative' = 'Total General & Administrative',
  'Total Other Income' = 'Total Other Income',
  'Total Other Expenses' = 'Total Other Expenses',
  'Adjustments' = 'Adjustments',
}

export enum BalanceSheetFAEnum {
  'Total Cash' = 'Total Cash',
  'Total Accounts Receivable' = 'Total Accounts Receivable',
  'Total Other Current Assets' = 'Total Other Current Assets',
  'Total Fixed Assets' = 'Total Fixed Assets',
  'Total Intangible Assets' = 'Total Intangible Assets',
  'Total Other Assets' = 'Total Other Assets',
  'Total Accounts Payable' = 'Total Accounts Payable',
  'Total Other Current Liabilities' = 'Total Other Current Liabilities',
  'Total Long Term Liabilities' = 'Total Long Term Liabilities',
  'Total Equity' = 'Total Equity',
}

export interface CategorizedAccount {
  glAccount: string;
  category: string;
  factaAccount: string;
}

export type CategorizeResponse = {
  accounts: CategorizedAccount[];
}
