import React, {
  forwardRef,
  useEffect,
  useState,
} from 'react';
import { ErrorDisplay } from '../Input/ErrorDisplay';
import { Label } from '../Input/Label';
import styled from 'styled-components';
import {
  RadioCheckedIcon,
  RadioUncheckedIcon,
} from 'assets/icons';
import { RadioButton } from '../RadioButton';
import { theme } from 'theme/theme';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1px;
`;

export const StyledOptions = styled.div`
  & > button,
  & > button:hover {
    border: 1px solid ${theme.colors.fieldBorder};
    padding: 12px;
    width: 100%;
    justify-content: flex-start;
    border-radius: 0;
    border-bottom: 0;
    height: auto;
    font-size: 14px;
    line-height: 21px;
    transition: all 200ms ease;

    &:first-of-type {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;;
    }

    &:last-of-type {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      border-bottom: 1px solid ${theme.colors.fieldBorder};
    }
  }

  & > button:hover {
    background-color: ${theme.colors.primaryBleach};
  }
`;

const StyledDescription = styled.div`
  flex-basis: 100%;
  text-align: left;
  margin-left: 33px;
  font-weight: normal;
`;

interface Props {
  name: string;
  isRequired?: boolean;
  error?: string;
  labelText: string;
  disabled?: boolean;
  isOptional?: boolean;
  tooltip?: string;
  dataCy?: string;
  onChange: (value: any) => void;
  defaultValue: any;
  options: any[];
  labelField: string;
  valueField: string;
  annotationField?: string;
  descriptionField?: string;
  condensed?: boolean;
  hidden?: boolean;
}

export const RadioInput = forwardRef(({
  name,
  labelText,
  error,
  isRequired,
  isOptional,
  tooltip,
  disabled,
  onChange,
  defaultValue,
  options,
  labelField,
  valueField,
  annotationField,
  descriptionField,
  condensed,
  hidden,
}: Props,
  ref: any) => {
  const [selected, setSelected] = useState(defaultValue);

  const handleChange = (e: React.MouseEvent<HTMLButtonElement>, option: any) => {
    setSelected(option[valueField]);
    onChange(option);
  };

  useEffect(() => {
    setSelected(defaultValue[valueField]);
    onChange(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, valueField]);

  return (
    <StyledContainer
      hidden={hidden}
      ref={ref}
    >
      <Label
        isRequired={isRequired}
        isOptional={isOptional}
        labelText={labelText}
        tooltip={tooltip}
        name={name}
      />
      <StyledOptions>
        {options.map((option) => (
          <RadioButton
            key={option[valueField]}
            type="button"
            variant="simple"
            color="secondary"
            onClick={(e) => handleChange(e, option)}
            selected={selected === option[valueField]}
            disabled={disabled}
            condensed={condensed}
          >
            {selected === option[valueField] ? <RadioCheckedIcon /> : <RadioUncheckedIcon />}
            {option[labelField]}
            {annotationField && (
              <span>
                {option[annotationField]}
              </span>
            )}
            {descriptionField && (
              <StyledDescription>
                {option[descriptionField]}
              </StyledDescription>
            )}
          </RadioButton>
        ))}
      </StyledOptions>
      {error && <ErrorDisplay error={error} />}
    </StyledContainer>
  );
});
