import React from 'react';
import { useAppDispatch } from 'hooks/redux';
import { DeleteItems } from 'components/DeleteItems';
import { bulkImportActions } from 'store/slices/bulkImport';
import { BulkImportContractsRow } from 'interfaces/bulkImportContracts';

interface Props {
  onClose: () => void;
  selectedRows: BulkImportContractsRow[];
}

export const BulkImportDeleteContractLines = ({
  onClose,
  selectedRows,
}: Props) => {
  const dispatch = useAppDispatch();

  const handleDeleteRows = () => {
    const ids = selectedRows.map((row) => row.id);

    dispatch(bulkImportActions.deleteContractLines({ ids }));
  };

  return (
    <DeleteItems
      selectedRows={selectedRows}
      onClose={onClose}
      isLoading={false}
      handleDelete={handleDeleteRows}
      item="row"
      shortenDescription
    />
  );
};
