import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { CloseIcon } from 'assets/icons';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import { SectionLabel } from 'components/common/SectionLabel';
import { Button } from 'components/common/Button';
import {
  StyledButtonContainer,
  StyledFormContainer,
  StyledFormRow,
} from 'components/common/Forms/styled';
import { toastSuccess } from 'utils/toast';
import { Dropdown } from 'components/common/Dropdown';
import {
  BulkAddColumnsFormFields,
  BulkAddPeriod,
  ReportColumnWithValues,
} from 'interfaces/reports';
import { Datepicker } from 'components/common/Datepicker';
import {
  useBulkAddColumnsMutation,
  useGetReportVersionsQuery,
} from "store/services/reports";
import {
  differenceInCalendarMonths,
  differenceInCalendarQuarters,
  differenceInCalendarYears,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  max,
  min,
} from "date-fns";
import {
  formatDateToISO,
  isoToDate,
} from "utils/dates";
import { useGetSettingsQuery } from "store/services/settings";
import {
  useEffect,
  useMemo,
} from "react";
import { Loading } from "components/Loading";

interface Props {
  reportId: string;
  onClose: () => void;
  simpleView?: boolean;
  onSuccess?: (result: ReportColumnWithValues[]) => void;
}

export const AddMultipleColumns = ({
  reportId,
  onClose,
  simpleView,
  onSuccess,
}: Props) => {
  const [bulkAddColumns, { isLoading: isAdding }] = useBulkAddColumnsMutation();
  const { data: versions } = useGetReportVersionsQuery();
  const { data: settings } = useGetSettingsQuery();

  const minDate = useMemo(() => {
    if (!settings?.saasActualStartDate) return undefined;
    return isoToDate(settings.saasActualStartDate);
  }, [settings]);

  const maxDate = useMemo(() => {
    if (!settings?.saasActualEndDate) return undefined;
    return isoToDate(settings.saasActualEndDate);
  }, [settings]);

  const periods: BulkAddPeriod[] = [
    { name: 'Monthly', variant: 'monthYearPicker', value: 'monthly' },
    { name: 'Quarterly', variant: 'quarterYearPicker', value: 'quarterly' },
    { name: 'Annual', variant: 'yearPicker', value: 'annual' },
  ];

  const schema = yup.object({
    version: yup.array()
      .of(yup.object())
      .min(1, 'Source is required.'),
    period: yup.array()
      .of(yup.object())
      .min(1, 'Period is required.'),
    startDate: yup.date()
      .nullable()
      .required('Start date is required.'),
    endDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'End date cannot be before start date.'),
      })
      .required('End date is required.'),
  })
    .required();

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm<BulkAddColumnsFormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      version: versions?.length ? [versions[0]] : [
        {
          id: null,
          name: 'System',
        }],
      period: [periods[0]],
      startDate: minDate,
      endDate: maxDate,
    },
  });

  const numberOfColumns = () => {
    const period = watch('period');
    const start = watch('startDate');
    const end = watch('endDate');

    if (!start || !end || !period) return '';

    switch (period?.[0]?.value) {
      case 'monthly':
        return differenceInCalendarMonths(end, start) + 1;
      case 'quarterly':
        return differenceInCalendarQuarters(end, start) + 1;
      case 'annual':
        return differenceInCalendarYears(end, start) + 1;
    }
  };

  const getEndDate = (period: 'monthly' | 'quarterly' | 'annual', date: Date) => {
    switch (period) {
      case 'monthly':
        return endOfMonth(date);
      case 'quarterly':
        return endOfQuarter(date);
      case 'annual':
        return endOfYear(date);
    }
  };

  const handleSaveAndClose = handleSubmit((data) => {
    const payload = {
      reportId,
      version: data.version[0].id,
      period: data.period[0].value,
      startDate: formatDateToISO(max([
        data.startDate,
        minDate!,
      ])),
      endDate: formatDateToISO(min([
        getEndDate(data.period[0].value, data.endDate),
        maxDate!,
      ])),
    };

    return bulkAddColumns(payload)
      .unwrap()
      .then((result) => {
        const columnsLength = result.length;
        toastSuccess(`${columnsLength} Columns successfully added.`);
        onClose();
        onSuccess && onSuccess(result);
      });
  });

  useEffect(() => {
    if (maxDate && minDate) {
      setValue('endDate', maxDate);
      setValue('startDate', minDate);
    }
  }, [minDate, maxDate, setValue]);

  if (!versions || !settings) return <Loading />;

  return (
    <>
      {!simpleView && (
        <ButtonsContainer
          paddingBottom={40}
          alignTop
        >
          <SectionLabel
            data-cy="am-header"
            marginBottom={0}
          >
            Add Multiple Columns
          </SectionLabel>
          <Spacer />
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={onClose}
            data-cy="am-button-close"
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
      )}
      <StyledFormContainer onSubmit={handleSaveAndClose}>
        <StyledFormRow multipleLines={simpleView}>
          <Controller
            name="version"
            control={control}
            render={({ field }) =>
              <Dropdown
                isRequired
                labelText="Source"
                options={versions || []}
                labelField="name"
                valueField="name"
                searchBy="name"
                placeholder="Select Source"
                values={getValues('version')}
                {...field}
                error={errors.version?.message}
              />
            }
          />
          <Controller
            name="period"
            control={control}
            render={({ field }) =>
              <Dropdown
                isRequired
                labelText="Period"
                options={periods}
                labelField="name"
                valueField="variant"
                searchBy="name"
                placeholder="Select Period"
                values={getValues('period')}
                {...field}
                error={errors.period?.message}
                clearable={false}
              />
            }
          />
        </StyledFormRow>
        <StyledFormRow multipleLines={simpleView}>
          <Controller
            name="startDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                tooltip="To change available date range, go to Settings > Reports > SaaS Settings > Actual Start Date and Actual End Date"
                labelText="Start Date"
                placeholder="Select Date"
                {...field}
                variant={watch('period')?.[0]?.variant as any || 'monthYearPicker'}
                error={errors.startDate?.message}
                isClearable={false}
                isRequired
                minDate={minDate}
                maxDate={maxDate}
              />
            }
          />
          <Controller
            name="endDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                tooltip="To change available date range, go to Settings > Reports > SaaS Settings > Actual Start Date and Actual End Date"
                labelText="End Date"
                placeholder="Select Date"
                {...field}
                variant={watch('period')?.[0]?.variant as any || 'monthYearPicker'}
                error={errors.endDate?.message}
                isClearable={false}
                isRequired
                minDate={minDate}
                maxDate={maxDate}
              />
            }
          />
        </StyledFormRow>
      </StyledFormContainer>
      <StyledButtonContainer
        pushRight
        marginTop={simpleView ? 24 : 40}
      >
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          onClick={onClose}
          disabled={isAdding}
        >
          CANCEL
        </Button>
        <Button
          type="button"
          onClick={handleSaveAndClose}
          disabled={isAdding}
          isLoading={isAdding}
        >
          ADD {numberOfColumns()} COLUMNS
        </Button>
      </StyledButtonContainer>
    </>
  );
};
