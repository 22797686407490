import React from 'react';
import { CloseIcon } from 'assets/icons';
import { StyledModalBoxesContainer } from 'components/Layout/Modal/styled';
import { Box } from 'components/common/Box';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import { SectionLabel } from 'components/common/SectionLabel';
import { Button } from 'components/common/Button';
import {
  StyledContainer,
  StyledWrapper,
} from 'components/common/Forms/styled';

interface Props {
  onClose: () => void;
}

export const WhatAreMetrics = ({ onClose }: Props) => (
  <StyledContainer>
    <ButtonsContainer
      paddingBottom={40}
      alignTop
    >
      <SectionLabel
        data-cy="wam-header"
        marginBottom={0}
      >
        <span>What are metrics?</span>
      </SectionLabel>
      <Spacer />
      <Button
        aria-label="Close"
        variant="icon"
        size="large"
        onClick={onClose}
        data-cy="wam-button-close"
      >
        <CloseIcon />
      </Button>
    </ButtonsContainer>
    <SectionLabel tertiary>
      <div>
        Overview
        <p>
          Metrics are the cornerstone of Facta’s system. You can build reports and even
          other metrics by utilizing one of our existing metrics or building your own.
        </p>
        <p>
          There are three types of metrics used in Facta: System, Custom Formula, and Manual Metrics.
          Each has their unique benefits, which are described below.
        </p>
      </div>
    </SectionLabel>
    <StyledWrapper>
      <StyledModalBoxesContainer>
        <Box
          marginBottom={0}
          borderWidth={3}
        >
          <SectionLabel tertiary>
            <div>
              System Metric
              <p>
                A System Metric is one of our predefined, “out-of-the-box” metrics.
              </p>
              <p>
                You cannot edit formulas for System Metrics, but you can apply powerful filters to segment and cut a metric however you wish.
              </p>
            </div>
          </SectionLabel>
        </Box>
        <Box
          marginBottom={0}
          borderWidth={3}
        >
          <SectionLabel tertiary>
            <div>
              Custom Formula
              <p>
                A Custom Formula allows you to write a formula using any other Metric or Account.
              </p>
              <p>
                Custom Formulas are limited in their ability to any apply filters from System Metrics.
              </p>
            </div>
          </SectionLabel>
        </Box>
        <Box
          marginBottom={0}
          borderWidth={3}
        >
          <SectionLabel tertiary>
            <div>
              Manual Metric
              <p>
                A Manual Metric allows you to upload custom values to a metric.
              </p>
              <p>
                You will still be able to apply filters similar to System Metrics.
              </p>
            </div>
          </SectionLabel>
        </Box>
      </StyledModalBoxesContainer>
    </StyledWrapper>
  </StyledContainer>
);
