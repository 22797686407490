import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="inherit">
      <path d="M3.587 16V8.2l.684.708H1.81V7.6h3.336V16h-1.56ZM7.928 16v-1.043l3.336-3.168c.28-.264.488-.496.624-.697.136-.2.224-.383.264-.552.048-.175.072-.34.072-.491 0-.385-.132-.68-.396-.889-.264-.216-.652-.324-1.164-.324-.408 0-.78.072-1.116.216a2.256 2.256 0 0 0-.852.66l-1.092-.84c.328-.44.768-.78 1.32-1.02a4.574 4.574 0 0 1 1.872-.372c.608 0 1.136.1 1.584.3.456.192.804.468 1.044.828.248.36.372.788.372 1.284 0 .273-.036.545-.108.817a2.88 2.88 0 0 1-.408.84c-.2.296-.492.628-.876.995l-2.868 2.724-.324-.588h4.908V16H7.928ZM18.676 16.12a6.103 6.103 0 0 1-1.74-.252c-.56-.176-1.032-.416-1.416-.72l.672-1.212a3.64 3.64 0 0 0 1.104.6c.432.152.884.228 1.356.228.56 0 .996-.112 1.308-.336.312-.232.468-.544.468-.936 0-.384-.144-.688-.432-.912-.288-.224-.752-.336-1.392-.336h-.768v-1.068l2.4-2.856.204.588h-4.512V7.6h5.724v1.044l-2.4 2.856-.816-.48h.468c1.032 0 1.804.232 2.316.696.52.456.78 1.044.78 1.764 0 .472-.12.908-.36 1.308s-.608.724-1.104.972c-.488.24-1.108.36-1.86.36Z" />
    </g>
  </svg>
);

export default SvgComponent;
