import React, {
  useRef,
  useState,
} from 'react';
import { usePopper } from 'react-popper';
import { MoreIcon } from 'assets/icons';
import { Button } from 'components/common/Button';
import { useClickOutside } from 'hooks/useClickOutside';
import {
  StyledContextMenu,
  StyledMenuItem,
  StyledMenuSpacer,
} from './styled';
import { Tooltip } from 'components/common/Tooltip';

export interface ActionItem {
  name: string;
  action?: () => void;
  hidden?: boolean;
  disabled?: boolean;
}

interface Props {
  items: ActionItem[];
  customIcon?: JSX.Element;
  color?: "error" | "secondary" | "transparent" | "tertiary" | "primary";
  label?: string;
  tooltip?: string;
  largeIcon?: boolean;
  variant?: 'contained' | 'outlined' | 'borderless' | 'icon' | 'icon-inverse' | 'icon-bleach' | 'simple';
}

export const ContextMenu = ({
  items,
  customIcon,
  color,
  label,
  tooltip,
  largeIcon,
  variant,
}: Props) => {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false,
          altAxis: true,
          padding: 40,
        },
      },
    ],
  });

  const handleToggleContextMenu = () => {
    popperElement?.getAttribute('data-show') === null
      ? popperElement?.setAttribute('data-show', '')
      : popperElement?.removeAttribute('data-show');

    if (update) {
      update();
    }
  };

  const handleClose = () => {
    popperElement?.removeAttribute('data-show');
  };

  useClickOutside(divRef, handleClose);

  return (
    <div ref={divRef}>
      <Tooltip title={tooltip}>
        <Button
          ref={setReferenceElement}
          variant={variant ? variant : label ? 'simple' : 'icon'}
          size={label || largeIcon ? 'large' : 'normal'}
          aria-label="User Menu"
          onClick={handleToggleContextMenu}
          color={color}
        >
          {label}
          {customIcon || <MoreIcon />}
        </Button>
      </Tooltip>
      <StyledContextMenu
        ref={setPopperElement}
        style={styles.popper}
        {...attributes}
      >
        {items
          .filter((item) => !item.hidden)
          .map((item) => item.name === '--'
            ? <StyledMenuSpacer key={item.name} />
            : (
              <StyledMenuItem
                key={item.name}
                onClick={() => {
                  item.action && item.action();
                  handleClose();
                }}
                disabled={item.disabled}
                active={item.name === label}
              >
                {item.name}
              </StyledMenuItem>
            ))}
      </StyledContextMenu>
    </div>
  );
};
