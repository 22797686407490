import React from "react";
import { BulkImportField } from "interfaces/bulkImport";
import {
  CheckCircleIcon,
  DuplicateIcon,
  ErrorIcon,
} from "assets/icons";
import { StyledTooltipText } from "components/common/Tooltip/styled";
import {
  ColDef,
  ITooltipParams,
} from "@ag-grid-community/core";
import { formatDateStringToDisplay } from "utils/dates";
import { AgGridCellRendererTags } from "components/common/AgGridCellRendererTags";
import { ErrorCell } from "components/common/ErrorCell";
import { Status } from "components/common/Status";
import {
  caseInsensitiveComparator,
  dateComparator,
  numericComparator,
} from "utils/aggrid";
import { BulkImportSubscriptionsRowError } from "interfaces/bulkImportSubscriptions";
import { Tag } from "interfaces/tags";
import {
  dateFilter,
  multiFilter,
  numberFilter,
} from "utils/filters";

export const CellRenderer = ({
  valueFormatted,
  value: nativeValue,
  colDef,
}: {
  valueFormatted: any;
  value: BulkImportField<any, any>;
  colDef: ColDef;
}) => {
  const {
    errorCode,
    providedValue,
  } = nativeValue;

  if (errorCode === 'missing_field') {
    return <ErrorCell>
      {colDef.field === 'subscriptionName' || colDef.field === 'amount' ? 'Enter' : 'Select'} {colDef.headerName}
    </ErrorCell>;
  } else if (errorCode) {
    return <ErrorCell>{providedValue}</ErrorCell>;
  } else {
    return valueFormatted;
  }
};

export const StatusRenderer = ({ value }: { value: BulkImportSubscriptionsRowError }) => {
  switch (value) {
    case 'missing_field':
    case 'multiple_errors':
    case 'renewal_mismatch':
    case 'wrong_value':
      return (
        <Status color="warning">
          <ErrorIcon />
        </Status>
      );
    case 'duplicate':
      return (
        <Status color="warning">
          <DuplicateIcon />
        </Status>
      );
    default:
      return (
        <Status color="success">
          <CheckCircleIcon />
        </Status>
      );
  }
};

const getTooltip = ({ value }: ITooltipParams) => {
  switch (value) {
    case 'renewal_mismatch':
      return 'One or more of provided values doesn\'t match with revenue that is being renewed.';
    case 'renewal_not_found':
      return 'Revenue that you\'re trying to renew doesn\'t exist.';
    case 'missing_field':
      return 'This revenue is missing one or more required fields. Update these fields before submitting.';
    case 'wrong_value':
      return 'Wrong value provided for one or more fields. Update these fields before submitting.';
    case 'multiple_errors':
      return 'This revenue contains multiple issues. Update highlighted fields before submitting.';
    case 'duplicate':
      return 'Duplicate revenue. You need to either rename or delete it before submitting.';

  }
};

const getRenewalTooltip = (value: BulkImportField<string, string>) => {
  switch (value.errorCode) {
    case 'renewal_not_found':
      return 'Revenue that you\'re trying to renew doesn\'t exist.';
    case 'renewal_mismatch':
      return 'One or more of provided values doesn\'t match with revenue that is being renewed.';
  }
};

export const getColumnDefs = (): Array<ColDef> => ([
  {
    field: 'errors',
    headerName: 'Status',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    width: 160,
    cellRenderer: StatusRenderer,
    tooltipComponent: ({ value }: ITooltipParams) => <StyledTooltipText data-show>{value}</StyledTooltipText>,
    tooltipValueGetter: getTooltip,
  },
  {
    field: 'subscriptionName',
    headerName: 'Revenue Name',
    resizable: true,
    sortable: true,
    flex: 1,
    cellRenderer: CellRenderer,
    minWidth: 250,
    valueFormatter: ({ value }) => value.providedValue ? value.providedValue : '-',
    getQuickFilterText: ({ value }) => value.providedValue,
    comparator: caseInsensitiveComparator('providedValue'),
    ...multiFilter,
    filterValueGetter: ({ data }) => data.subscriptionName.providedValue,
  },
  {
    field: 'customer',
    headerName: 'Customer',
    resizable: true,
    sortable: true,
    flex: 1,
    cellRenderer: CellRenderer,
    minWidth: 250,
    valueFormatter: ({ value }) => value.providedValue ? value.providedValue : '-',
    getQuickFilterText: ({ value }) => value.providedValue,
    comparator: caseInsensitiveComparator('providedValue'),
    ...multiFilter,
    filterValueGetter: ({ data }) => data.customer.providedValue,
  },
  {
    field: 'product',
    headerName: 'Product',
    resizable: true,
    sortable: true,
    flex: 1,
    cellRenderer: CellRenderer,
    minWidth: 250,
    valueFormatter: ({ value }) => value.providedValue ? value.providedValue : '-',
    getQuickFilterText: ({ value }) => value.providedValue,
    comparator: caseInsensitiveComparator('providedValue'),
    ...multiFilter,
    filterValueGetter: ({ data }) => data.product.providedValue,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    resizable: true,
    sortable: true,
    cellRenderer: CellRenderer,
    minWidth: 150,
    getQuickFilterText: ({ value }) => value.matchedValue,
    type: 'currencyOfMatchedValue',
    comparator: numericComparator('matchedValue'),
    ...numberFilter,
    filterValueGetter: ({ data }) => data.amount.matchedValue,
  },
  {
    field: 'bookingDate',
    headerName: 'Booking Date',
    resizable: true,
    sortable: true,
    cellRenderer: CellRenderer,
    minWidth: 150,
    valueFormatter: ({ value }) => value.matchedValue ? formatDateStringToDisplay(value.matchedValue) : '-',
    getQuickFilterText: ({ value }) => value.matchedValue,
    comparator: dateComparator('matchedValue'),
    filterValueGetter: ({ data }) => data.bookingDate.matchedValue,
    ...dateFilter,
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    resizable: true,
    sortable: true,
    cellRenderer: CellRenderer,
    minWidth: 150,
    valueFormatter: ({ value }) => value.matchedValue ? formatDateStringToDisplay(value.matchedValue) : '-',
    getQuickFilterText: ({ value }) => value.matchedValue,
    comparator: dateComparator('matchedValue'),
    ...dateFilter,
    filterValueGetter: ({ data }) => data.startDate.matchedValue,
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    resizable: true,
    sortable: true,
    cellRenderer: CellRenderer,
    minWidth: 150,
    valueFormatter: ({ value }) => value.matchedValue ? formatDateStringToDisplay(value.matchedValue) : '-',
    getQuickFilterText: ({ value }) => value.matchedValue,
    comparator: dateComparator('matchedValue'),
    ...dateFilter,
    filterValueGetter: ({ data }) => data.endDate.matchedValue,
  },
  {
    field: 'cancelDate',
    headerName: 'Cancel Date',
    resizable: true,
    sortable: true,
    cellRenderer: CellRenderer,
    minWidth: 150,
    valueFormatter: ({ value }) => value.matchedValue ? formatDateStringToDisplay(value.matchedValue) : '-',
    getQuickFilterText: ({ value }) => value.matchedValue,
    comparator: dateComparator('matchedValue'),
    ...dateFilter,
    filterValueGetter: ({ data }) => data.cancelDate.matchedValue,
  },
  {
    field: 'revRecStartDate',
    headerName: 'Rev Rec Start Date',
    resizable: true,
    sortable: true,
    cellRenderer: CellRenderer,
    minWidth: 150,
    valueFormatter: ({ value }) => value.matchedValue ? formatDateStringToDisplay(value.matchedValue) : '-',
    getQuickFilterText: ({ value }) => value.matchedValue,
    comparator: dateComparator('matchedValue'),
    ...dateFilter,
    filterValueGetter: ({ data }) => data.revRecStartDate.matchedValue,
  },
  {
    field: 'revRecEndDate',
    headerName: 'Rev Rec End Date',
    resizable: true,
    sortable: true,
    cellRenderer: CellRenderer,
    minWidth: 150,
    valueFormatter: ({ value }) => value.matchedValue ? formatDateStringToDisplay(value.matchedValue) : '-',
    getQuickFilterText: ({ value }) => value.matchedValue,
    comparator: dateComparator('matchedValue'),
    ...dateFilter,
    filterValueGetter: ({ data }) => data.revRecEndDate.matchedValue,
  },
  {
    field: 'renewedSubscription',
    headerName: 'Renewed Revenue',
    resizable: true,
    sortable: true,
    flex: 1,
    cellRenderer: CellRenderer,
    minWidth: 270,
    valueFormatter: ({ value }) => value.providedValue ? value.providedValue : '-',
    getQuickFilterText: ({ value }) => value.providedValue,
    comparator: caseInsensitiveComparator('providedValue'),
    tooltipComponent: ({ value }: ITooltipParams) => <StyledTooltipText data-show>{value}</StyledTooltipText>,
    tooltipValueGetter: ({ value }) => getRenewalTooltip(value),
    ...multiFilter,
    filterValueGetter: ({ data }) => data.renewedSubscription.providedValue,
  },
  {
    field: 'crmId',
    headerName: 'CRM ID',
    resizable: true,
    sortable: true,
    cellRenderer: CellRenderer,
    minWidth: 250,
    valueFormatter: ({ value }) => value.providedValue ? value.providedValue : '-',
    getQuickFilterText: ({ value }) => value.providedValue,
    comparator: caseInsensitiveComparator('providedValue'),
    ...multiFilter,
    filterValueGetter: ({ data }) => data.crmId.providedValue,
  },
  {
    field: 'tags',
    headerName: 'Tags',
    sortable: true,
    resizable: true,
    flex: 1,
    cellRenderer: ({ value }: any) => AgGridCellRendererTags({
      value: value.matchedValue || [],
      errorCode: value.errorCode,
      errorValue: value.providedValue,
    }),
    minWidth: 250,
    comparator: caseInsensitiveComparator('providedValue'),
    getQuickFilterText: ({ value }) => value.providedValue,
    ...multiFilter,
    filterValueGetter: (params) => params.data?.tags?.matchedValue?.map((tag: Tag) => tag.name) || [],
  },
]);
