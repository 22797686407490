import { FormulaElementType } from "interfaces/formulaEditor";
import styled from "styled-components";
import { theme } from "theme/theme";

export const StyledPopupWrapper = styled.div`
  position: absolute;
  top: 24px;
  padding: 12px;
  opacity: 0;
  pointer-events: none;
  z-index: 4;

  .left & {
    left: -100px;
  }

  .right & {
    right: -100px;
  }
`;

export const StyledPopupContainer = styled.div`
  display: block;
  padding: 24px;
  border-radius: 6px;
  background-color: ${theme.colors.white};
  color: ${theme.colors.textDark};
  box-shadow: ${theme.boxShadow};
  transition: all 300ms ease;
  min-width: 500px;
`;

export const StyledCategory = styled.div`
  display: flex;
  justify-content: space-between;
  
  span {
    font-size: 10px;
    line-height: 24px;
    font-weight: 500;
    color: ${theme.colors.textDisabled};
  }

  button {
    height: 24px;
  }
`;

export const StyledName = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${theme.colors.textDark};
  padding: 7px 0;
`;

export const StyledOrigin = styled.div<{ type: FormulaElementType }>`
  display: flex;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  color: ${theme.colors.textDisabled};
  margin: 4px 0;
  
  &:before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: ${props => props.type === FormulaElementType.ACCOUNT
    ? theme.colors.primaryBleach
    : theme.colors.tertiaryBleach};
    margin-right: 8px;
  }
`;

export const StyledCaption = styled.div`
  font-size: 12px;
  line-height: 24px;
  font-weight: 600;
  color: ${theme.colors.textDark};
  margin-top: 24px;
`;

export const StyledList = styled.ul`
  li {
    padding: 8px;
    border-bottom: solid 1px ${theme.colors.borderColorDark};
    font-size: 14px;
    line-height: 21px;
  }

  .empty {
    color: ${theme.colors.textDisabled};
  }
`;