import * as React from "react";

const SvgComponent = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="inherit"
      d="M15 21V3h-2v18h2Zm4 0V3h-2v18h2Zm-8 0V3H9v18h2Zm-6 0h2V3H5v18Z"
    />
  </svg>
);

export default SvgComponent;
