import { ChartBackendResponse } from "./charts";
import { Customer } from "./customers";
import { FormulaElementType } from "./formulaEditor";
import {
  Product,
  RevenueType,
} from "./products";
import { Tag } from "./tags";

export type ValueFormat = 'currency' | 'number' | 'percentage' | 'multiple';

export interface Metric {
  id: string;
  name: string;
  type: MetricType;
  aggregationMethod: AggregationMethod;
  tags: string[] | null;
  origin: MetricOrigin;
  archived: boolean;
  created: string;
  createdBy: {
    id: string;
    firstName: string;
    lastName: string;
  } | null;
  updated: string;
  updatedBy: {
    id: string;
    firstName: string;
    lastName: string;
  } | null;
  precision: number;
  valueFormat: ValueFormat;
  description: string;
}

export interface MetricWithDetails extends Metric {
  formula: Array<{
    type: FormulaElementType;
    value: string;
  }>;
  recurringRevenueType: "MRR" | "ARR",
  filters: {
    lookback: {
      period: number | null;
      ytd: boolean;
    };
    revenueTypeFilter: string[];
    customerFilter: Array<{
      id: string;
      name: string;
    }>;
    customerTagFilter: Array<{
      id: string;
      name: string;
    }>;
    productFilter: Array<{
      id: string;
      name: string;
    }>;
    productTagFilter: Array<{
      id: string;
      name: string;
    }>;
    contractTagFilter: Array<{
      id: string;
      name: string;
    }>;
    contractLineTagFilter: Array<{
      id: string;
      name: string;
    }>;
  };
  created: string;
  createdBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
  updated: string;
  updatedBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
  startDateField: StartDateField;
}

export interface MetricType {
  id: string;
  name: string;
  category: MetricCategory;
  description: string;
}

export interface MetricFormFields {
  name: string;
  description: string;
  category: { name: MetricCategory, value: MetricCategory } | null;
  metricType: MetricType[];
  aggregationMethod: Array<{ name: string, value: AggregationMethod }>;
  tags:  Array<{ value: string }>;
  lookbackPeriod: number | null;
  lookbackYTD: boolean;
  startDateField: { name: StartDateField, value: StartDateField };
}

export interface DuplicateMetricsForm {
  name: string;
  lookbackPeriods: Array<{
    period: number | null;
    ytd: boolean;
  }>
}

export enum MetricOrigin {
  SYSTEM = 'system',
  SYSTEM_COPY = 'system-copy',
  CUSTOM = 'custom',
  MANUAL = 'manual',
}

export enum MetricCategory {
  SAAS = 'SaaS',
  PL = 'Profit & Loss',
  BS = 'Balance Sheet',
  OTHER = 'Other',
}

export enum AggregationMethod {
  EOP = 'EOP',
  BOP = 'BOP',
  AVG = 'AVG',
  SUM = 'SUM',
  CALC = 'CALC',
}

export enum StartDateField {
  BOOKING = 'Committed Booking Date',
  SERVICE = 'Service Start Date',
}

export interface AddMetricRequest {
  aggregationMethod: AggregationMethod;
  metricTypeID: string;
  name: string;
  description: string;
  tags: string[] | null;
  filters: {
    lookback: {
      period: number | null;
      ytd: boolean | null;
    } | null;
  }
  startDateField: StartDateField;
}

export interface DuplicateMetricsRequest {
  lookbacks: Array<{
    period: number | null;
    ytd: boolean;
  }>;
  metricsIds: string[];
  name: string | null;
}

export interface BulkTagsRequest {
  metricsIds: string[];
  tags: string[];
  overwrite: boolean;
}

export interface UpdateMetricFiltersRequest {
  lookback: {
    period: number | null;
    ytd: boolean | null;
  } | null;
  revenueTypeFilter: string[];
  customerFilter: string[];
  customerTagFilter: string[];
  productFilter: string[];
  productTagFilter: string[];
  contractTagFilter: string[];
  contractLineTagFilter: string[];
};

export interface UpdateMetricFiltersForm {
  revenueTypeFilter: Array<{ label: RevenueType; name: RevenueType; }>;
  customerFilter: Customer[];
  customerTagFilter: Tag[];
  productFilter: Product[];
  productTagFilter: Tag[];
  contractTagFilter: Tag[];
  contractLineTagFilter: Tag[];
};

export interface UpdateMetricRequest {
  metricId: string;
  name: string;
  description: string;
  type: string;
  aggregationMethod: AggregationMethod;
  tags: string[] | null;
  recurringRevenueType: "MRR" | "ARR",
  filters?: UpdateMetricFiltersRequest;
  startDateField: StartDateField
}

export type Metrics = Metric[];
export type MetricTypes = MetricType[];

export interface UploadManual {
  name: string;
  aggregationMethod: AggregationMethod;
  category: string;
  description: string;
  entries: {
    date: string;
    value: number;
  }[];
  tags: string[];
}

export type UploadManualsRequest = UploadManual[];

export interface MetricWithValues {
  id: string;
  name: string;
  aggregationMethod: AggregationMethod;
  tags: string[] | null;
  origin: MetricOrigin;
  category: MetricCategory;
  description: string;
  entries: {
    period: string;
    value: number;
  }[]
}

export type MetricsWithValues = MetricWithValues[];

export interface MetricDependants {
  metricDependants: {
    id: string;
    name: string;
  }[];
  reportDependants: {
    id: string;
    name: string;
  }[];
}

export interface MetricSummary extends ChartBackendResponse{
  id: string;
}

export type MetricsSummary = MetricSummary[];
