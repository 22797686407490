import styled from "styled-components";

export const StyledRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;

  & > * {
    width: 50%;
  }
`;
