import styled, { css } from "styled-components";
import { Button } from "components/common/Button";
import { theme } from "theme/theme";

export const StyledButton = styled(Button)<{ active?: boolean }>`
  border-radius: 2px;

  ${props => props.active && css`
    border: 1px solid ${theme.colors.primary};
    border-bottom: 1px solid ${theme.colors.primary} !important;
  `}
`;
