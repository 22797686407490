import {
  caseInsensitiveComparator,
  dateComparator,
} from "utils/aggrid";
import { formatDateStringToDisplay } from "utils/dates";
import { Tag } from "interfaces/tags";
import { AgGridCellRendererLink } from "components/common/AgGridCellRendererLink";
import { AgGridCommonCellRenderer } from "components/common/AgGridCommonCellRenderer";
import { ColGroupDef } from '@ag-grid-community/core';
import {
  dateFilter,
  multiFilter,
  numberFilter,
} from "utils/filters";

export const getColumnDefs = (): ColGroupDef[] => ([
  {
    children: [
      {
        field: 'id',
        headerName: 'Id',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        cellRenderer: () => null,
        maxWidth: 70,
        pinned: 'left',
      },
    ],
  },
  {
    headerName: 'General',
    children: [
      {
        field: 'product.name',
        headerName: 'Product',
        sortable: true,
        resizable: true,
        cellRenderer: AgGridCommonCellRenderer('product'),
        flex: 1,
        minWidth: 250,
        comparator: caseInsensitiveComparator(),
        enableRowGroup: true,
        ...multiFilter,
      },
      {
        field: 'product.revenueType',
        headerName: 'Revenue Type',
        sortable: true,
        resizable: true,
        minWidth: 220,
        comparator: caseInsensitiveComparator(),
        enableRowGroup: true,
        ...multiFilter,
      },
      {
        field: 'name',
        headerName: 'Contract Line',
        sortable: true,
        resizable: true,
        flex: 1,
        minWidth: 300,
        comparator: caseInsensitiveComparator(),
        ...multiFilter,
      },
    ],
  },
  {
    headerName: 'SaaS Metrics',
    children: [
      {
        field: 'mrr',
        headerName: 'MRR',
        sortable: true,
        minWidth: 210,
        type: 'currency',
        aggFunc: 'sum',
        ...numberFilter,
      },
      {
        field: 'arr',
        headerName: 'ARR',
        sortable: true,
        minWidth: 210,
        type: 'currency',
        aggFunc: 'sum',
        ...numberFilter,
      },
      {
        field: 'amount',
        headerName: 'Amount',
        sortable: true,
        minWidth: 210,
        type: 'currency',
        aggFunc: 'sum',
        ...numberFilter,
      },
    ],
  },
  {
    headerName: 'Dates',
    children: [
      {
        field: 'bookingDate',
        headerName: 'Booking Date',
        sortable: true,
        valueFormatter: ({ value }) => formatDateStringToDisplay(value) || '-',
        getQuickFilterText: ({ value }) => formatDateStringToDisplay(value),
        comparator: dateComparator(),
        enableRowGroup: true,
        ...dateFilter,
      },
      {
        field: 'startDate',
        headerName: 'Start Date',
        sortable: true,
        valueFormatter: ({ value }) => formatDateStringToDisplay(value) || '-',
        getQuickFilterText: ({ value }) => formatDateStringToDisplay(value),
        comparator: dateComparator(),
        enableRowGroup: true,
        ...dateFilter,
      },
      {
        field: 'endDate',
        headerName: 'End Date',
        sortable: true,
        valueFormatter: ({ value }) => formatDateStringToDisplay(value) || '-',
        getQuickFilterText: ({ value }) => formatDateStringToDisplay(value),
        comparator: dateComparator(),
        enableRowGroup: true,
        ...dateFilter,
      },
      {
        field: 'cancelDate',
        headerName: 'Cancel Date',
        sortable: true,
        valueFormatter: ({ value }) => formatDateStringToDisplay(value) || '-',
        getQuickFilterText: ({ value }) => formatDateStringToDisplay(value),
        comparator: dateComparator(),
        enableRowGroup: true,
        ...dateFilter,
      },
    ],
  },
  {
    headerName: 'Other',
    children: [
      {
        field: 'status',
        headerName: 'Type/Status',
        sortable: true,
        resizable: true,
        minWidth: 200,
        comparator: caseInsensitiveComparator(),
        enableRowGroup: true,
        ...multiFilter,
      },
      {
        field: 'tags',
        headerName: 'Tags',
        sortable: true,
        resizable: true,
        minWidth: 200,
        getQuickFilterText: ({ value }) => value?.map((tag: Tag) => tag.name),
        valueGetter: (params) => params.data?.tags?.map((tag: Tag) => tag.name) || [],
        ...multiFilter,
      },
      {
        field: 'externalLink',
        headerName: 'External Link',
        sortable: true,
        cellRenderer: AgGridCellRendererLink,
      },
      {
        field: 'crmId',
        headerName: 'CRM ID',
        sortable: true,
        resizable: true,
        minWidth: 200,
        valueFormatter: ({ value }) => value ? value : '-',
        enableRowGroup: true,
        ...multiFilter,
      },
    ],
  },
]);
