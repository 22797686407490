import styled, { css } from "styled-components";
import { theme } from "theme/theme";
import { ANIMATION_DURATION } from "utils/constants";

interface StyledTriggerProps {
  pullLeft?: boolean;
  inline?: boolean;
}

export const StyledTrigger = styled.div<StyledTriggerProps>`
  ${props => props.inline && css`
    display: inline-flex;
  `}
  cursor: pointer;

  ${props => props.pullLeft && css`
    margin-right: auto;
    margin-left: 10px;
  `}
`;

interface StyledTooltipTextProps {
  maxWidth?: number;
}

export const StyledTooltipText = styled.div<StyledTooltipTextProps>`
  opacity: 0;
  top: 0;
  left: 0;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${theme.colors.textDark};
  color: ${theme.colors.textLight};
  z-index: 3;
  max-width: ${props => props.maxWidth ? props.maxWidth : 210}px;
  pointer-events: none;
  transition: opacity ${ANIMATION_DURATION}ms ease;

  &[data-show] {
    opacity: 1;
  }
`;

export const StyledTooltipIcon = styled.div`
  svg {
    width: 16px;
    height: 16px;
    color: ${theme.colors.primary};
  }
`;
