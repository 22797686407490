import React from 'react';
import { Checkbox } from 'components/common/Checkbox';
import { Divider } from 'components/common/ButtonsContainer';

interface Props {
  setIncludeUnused: React.Dispatch<React.SetStateAction<boolean>>;
  includeUnused: boolean;
}

export const InactiveGLAccounts = ({
  setIncludeUnused,
  includeUnused,
}: Props) => {
  return (
    <>
      <Divider />
      <Checkbox
        name="includeUnused"
        checked={includeUnused}
        onChange={() => setIncludeUnused(!includeUnused)}
        condensed
      >
        Include Inactive GL Accounts
      </Checkbox>
    </>
  );
};
