import styled from "styled-components";
import { theme } from "theme/theme";

export const StyledHelpOverlay = styled.div`
    right: 40px;
    width: 85%;
    max-width: 1300px;
    top: 90px;
    bottom: 40px;
    z-index: 1002;
    color: ${theme.colors.white};
    position: fixed;
    background: ${theme.colors.black};
    overflow: hidden;
    opacity: 0.85;
    padding: 40px;
    border-radius: 8px;

    h1 {
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
    }

    h2 {
      color: ${theme.colors.warning};
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      margin: 24px 0 12px 0;
    }

    p {
      font-size: 14px;
    }

    p + p {
      margin-top: 0;
    }

    em {
      color: ${theme.colors.warning};
    }
`;

export const StyledHelpOverlayItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 50px;
  overflow: auto;
  max-height: 90%;
`;

export const StyledHelpOverlayItem = styled.div<{ hidden?: boolean }>`
  display: block !important;
  opacity: ${props => props.hidden ? 0.3 : 1};
`;