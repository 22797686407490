import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as yup from "yup";
import {
  useForm,
  Controller,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CloseIcon,
  CopyIcon,
  DeleteIcon,
} from 'assets/icons';
import { RevenueTypeOptions } from 'utils/constants';
import {
  useBulkEditProductsMutation,
  useGetAllProductsQuery,
  useUpdateProductMutation,
} from 'store/services/products';
import { Button } from 'components/common/Button';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import {
  Product,
  ProductFormFields,
} from 'interfaces/products';
import {
  StyledButtonContainer,
  StyledCheckboxContainer,
  StyledFormContainer,
  StyledFormSectionLabel,
} from 'components/common/Forms/styled';
import { Dropdown } from 'components/common/Dropdown';
import { InputBasic } from 'components/common/Input';
import { copyToClipboard } from 'utils/copyToClipboard';
import { prepareUpdateProductPayload } from 'utils/products';
import { useGetAllTagsQuery } from 'store/services/tags';
import { toastSuccess } from 'utils/toast';
import { actionBlocker } from 'utils/actionBlocker';
import { TagType } from 'interfaces/tags';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import { formDataSelector } from 'store/selectors/formState';
import { formStateActions } from 'store/slices/formState';
import {
  getTagsIdsForSelectedRows,
  tagsSortFn,
} from 'utils/tags';
import { Tooltip } from 'components/common/Tooltip';
import { Checkbox } from 'components/common/Checkbox';

interface Props {
  onClose: () => void;
  onSuccess: (id?: string) => void;
  onSave: (id: string) => void;
  onAddTag: () => void;
  onDelete: () => void;
  selectedRows: Product[];
}

export const EditProducts = ({
  onClose,
  onSuccess,
  onSave,
  onAddTag,
  onDelete,
  selectedRows,
}: Props) => {
  const [updateProduct, { isLoading }] = useUpdateProductMutation();
  const [bulkEditProducts, { isLoading: isLoadingBulkEdit }] = useBulkEditProductsMutation();
  const [isClearExistingTags, setIsClearExistingTags] = useState(false);
  const dispatch = useAppDispatch();
  const formData = useAppSelector(formDataSelector);
  const isBulkEdit = selectedRows.length > 1;

  const {
    productNames,
  } = useGetAllProductsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      productNames: data
        ?.filter((product) => !product.deleted && product.name !== selectedRows[0]?.name)
        .map((product) => product.name),
    }),
  });

  const selectedRowsTagsIds = useMemo(() => getTagsIdsForSelectedRows(selectedRows), [selectedRows]);

  const { tagOptions } = useGetAllTagsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      tagOptions: data
        ?.filter((tag) => tag.type === TagType.PRODUCT && (!tag.deleted || selectedRowsTagsIds.includes(tag.id)))
        .sort(tagsSortFn) || [],
    }),
  });

  const bulkEditSchema = yup.object({
    description: yup.string(),
    revenueType: yup.array()
      .of(
        yup.object({
          label: yup.string(),
          value: yup.string(),
        })),
    crmId: yup.string()
      .nullable(),
    tags: yup.array(),
  })
    .required();

  const schema = bulkEditSchema.concat(
    yup.object()
      .shape({
        id: yup.string(),
        name: yup.string()
          .required('Product name is required.')
          .test(
            'isUnique',
            'Product name must be unique',
            (val) => !(productNames?.includes(val || '')),
          ),
        revenueType: yup.array()
          .of(
            yup.object({
              label: yup.string(),
              value: yup.string(),
            }))
          .min(1, 'Revenue Type is required.'),
      }));

  const defaultValues = useMemo(() => ({
    ...(formData
      ? {
        ...formData,
      }
      : {
        id: '',
        name: '',
        description: '',
        revenueType: [],
        crmId: '',
        tags: [],
      }
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm<ProductFormFields>({
    resolver: yupResolver(isBulkEdit ? bulkEditSchema : schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  useEffect(() => {
    const product = selectedRows[0];

    if (isBulkEdit && !formData) {
      reset({
        id: '',
        name: '',
        description: '',
        revenueType: [],
        crmId: '',
        tags: [],
      });
    } else if (product && !formData) {
      reset({
        id: product.id,
        name: product.name,
        description: product.description,
        revenueType: [{
          label: product.revenueType,
          value: product.revenueType,
        }],
        crmId: product.crmId,
        tags: product.tags,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  const prepareBulkEditPayload = (data: ProductFormFields) => {
    const productsIds = selectedRows.map((row) => row.id);
    const changedTags = data.tags.length ? data.tags.map((tag) => tag.id) : null;

    return {
      productsIds,
      crmId: data.crmId || null,
      tagIds: isClearExistingTags ? [] : changedTags,
      description: data.description || null,
    };
  };

  const handleSaveAndClose = handleSubmit((data) => {
    const product = prepareUpdateProductPayload(data);

    return updateProduct(product)
      .unwrap()
      .then((result) => {
        toastSuccess('Product successfully updated.');
        onClose();
        onSuccess(result.id);
      });
  });

  const handleBulkSaveAndClose = handleSubmit((data) => {
    return bulkEditProducts(prepareBulkEditPayload(data))
      .unwrap()
      .then(() => {
        toastSuccess('Products successfully updated.');
        onClose();
        onSuccess();
      });
  });

  const handleSave = handleSubmit((data) => {
    const product = prepareUpdateProductPayload(data);

    return updateProduct(product)
      .unwrap()
      .then((result) => {
        toastSuccess('Product successfully updated.');
        onSuccess(result.id);
        onSave(result.id);
      });
  });

  const handleBulkSave = handleSubmit((data) => {
    return bulkEditProducts(prepareBulkEditPayload(data))
      .unwrap()
      .then(() => {
        toastSuccess('Products successfully updated.');
        onSuccess();
      });
  });

  const handleAddTag = ({ name }: { name: string }) => {
    dispatch(formStateActions.setPredefinedData({
      name,
      type: TagType.PRODUCT,
    }));
    dispatch(formStateActions.setFormData(getValues()));
    onAddTag();
  };

  const handleClickDelete = onDelete;

  const handleClearExistingTags = () => {
    setIsClearExistingTags(!isClearExistingTags);
    setValue('tags', []);
  };

  return (
    <>
      <header>
        <ButtonsContainer>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={() => actionBlocker(onClose, isDirty)}
            pushRight
            data-cy="ep-button-close"
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <StyledFormSectionLabel data-cy="ep-header">
          <span>
            {isBulkEdit ? 'Bulk Edit' : 'Edit Product'}
          </span>
          {isBulkEdit
            ? (
              <span>{selectedRows.length} Products</span>
            )
            : (
              <ButtonsContainer>
                <Button
                  variant="simple"
                  size="large"
                  type="button"
                  onClick={(e) => copyToClipboard(e, selectedRows[0].id)}
                  data-cy="ep-button-copy"
                >
                  <span>Insights Product ID</span>
                  <CopyIcon />
                </Button>
              </ButtonsContainer>
            )}
        </StyledFormSectionLabel>
        <ButtonsContainer paddingBottom={20}>
          <Tooltip title="Delete">
            <Button
              variant="icon"
              color="primary"
              size="large"
              onClick={handleClickDelete}
              aria-label="Delete"
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        </ButtonsContainer>
      </header>
      <main>
        <StyledFormContainer>
          {!isBulkEdit && (
            <InputBasic
              labelText="Product Name"
              placeholder="Add product name"
              {...register('name')}
              onBlur={(e) => {
                const fieldValue = e.target.value;

                if (fieldValue) {
                  setValue('name', fieldValue.trim(), { shouldValidate: true });
                }
              }}
              error={errors.name?.message}
              dataCy="ep-input-name"
            />
          )}
          <InputBasic
            labelText="Description"
            placeholder="Describe your product"
            {...register('description')}
            error={errors.description?.message}
            dataCy="ep-input-description"
          />
          <Controller
            name="revenueType"
            control={control}
            render={({ field }) =>
              <Dropdown
                labelText="Revenue Type"
                options={RevenueTypeOptions}
                values={getValues('revenueType')}
                {...field}
                error={errors.revenueType?.message}
                dataCy="ep-dropdown-revenue"
                disabled
                tooltip="Editing revenue type is blocked as it may cause undesirable changes in your MRR/ARR calculations"
              />
            }
          />
          <div>
            <Controller
              name="tags"
              control={control}
              render={({ field }) =>
                <Dropdown
                  isOptional
                  labelText="Tags"
                  options={tagOptions}
                  labelField="name"
                  valueField="id"
                  searchBy="name"
                  {...field}
                  values={getValues('tags')}
                  dataCy="ep-dropdown-tags"
                  error={errors.tags?.message}
                  multi
                  create
                  onCreateNew={handleAddTag}
                  disabled={isClearExistingTags}
                />
              }
            />
            {isBulkEdit && (
              <StyledCheckboxContainer>
                <Checkbox
                  name="clearExistingTags"
                  checked={isClearExistingTags}
                  onChange={handleClearExistingTags}
                >
                  Clear existing Tags
                </Checkbox>
              </StyledCheckboxContainer>
            )}
          </div>
          <InputBasic
            isOptional
            labelText="CRM ID"
            placeholder="Paste unique identifier"
            {...register('crmId')}
            error={errors.crmId?.message}
            dataCy="ep-input-crm"
          />
        </StyledFormContainer>
      </main>
      <footer>
        <StyledButtonContainer pushRight>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={isBulkEdit ? handleBulkSave : handleSave}
            disabled={isBulkEdit ? isLoadingBulkEdit : isLoading}
            isLoading={isBulkEdit ? isLoadingBulkEdit : isLoading}
            data-cy="ep-button-save"
          >
            SAVE
          </Button>
          <Button
            type="submit"
            onClick={isBulkEdit ? handleBulkSaveAndClose : handleSaveAndClose}
            disabled={isBulkEdit ? isLoadingBulkEdit : isLoading}
            isLoading={isBulkEdit ? isLoadingBulkEdit : isLoading}
            data-cy="ep-button-save-close"
          >
            SAVE & CLOSE
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};
