import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import store from 'store/store';
import { Reset } from 'theme/styled-reset';
import { ThemeProvider } from 'styled-components';
import { theme } from 'theme/theme';
import { GlobalStyles } from 'theme';
import './theme/fonts.css';
import {
  AmplifyProvider,
  Authenticator,
} from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import { awsExports } from 'config/amplifyCognito';
import { ToastContainer } from 'react-toastify';
import { FullStoryComponent } from 'components/FullStoryComponent';
import { VersionChecker } from 'components/VersionChecker';
import 'react-toastify/dist/ReactToastify.css';
import 'utils/sentry';
import { featureSwitch } from 'utils/featureSwitch';

Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <Reset />
    <Provider store={store}>
      <GlobalStyles />
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <AmplifyProvider>
            <Authenticator.Provider>
              <FullStoryComponent />
              {!featureSwitch.isLocalDevelopment && <VersionChecker />}
              <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <App />
            </Authenticator.Provider>
          </AmplifyProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);
