import React from "react";
import { UserRole } from "interfaces/auth";
import {
  Navigate,
  Outlet,
} from "react-router";
import { Company } from "interfaces/company";

interface Props {
  redirectPath?: string;
  currentCompany: Company | null;
  children?: React.ReactElement;
  allowedRoles?: UserRole[];
}

export const ProtectedRoute = ({
  redirectPath,
  children,
  currentCompany,
  allowedRoles,
}: Props) => {
  if (allowedRoles && currentCompany && !allowedRoles.includes(currentCompany?.role)) {
    return (
      <Navigate
        to={redirectPath || '/'}
        replace
      />
    );
  };

  return children ? children : <Outlet />;
};
