import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="transparent"
    stroke="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <g
      stroke="inherit"
      strokeLinejoin="round"
      strokeWidth={2}>
      <path
        strokeLinecap="square"
        d="M19 5 5 19" />
      <path
        strokeLinecap="round"
        d="M6.5 9a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM17.5 20a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
      />
    </g>
  </svg>
);

export default SvgComponent;
