import {
  useMemo,
  useState,
} from "react";

export const useRightPanes = <T extends { [key: string]: boolean }>(defaultRightPanes: T) => {
  const [rightPanes, setRightPanes] = useState(defaultRightPanes);

  const resetedPanes = useMemo(() => Object.fromEntries(Object.entries(defaultRightPanes)
    .map(([key]) => ([key, false]))), [defaultRightPanes]);

  const setSelectedPane = (pane: keyof T) => setRightPanes({
    ...resetedPanes as T,
    [pane]: true,
  });

  const resetPanes = () => setRightPanes(defaultRightPanes);

  return {
    rightPanes,
    setSelectedPane,
    resetPanes,
  };
};
