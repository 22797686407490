import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  BulkImportEntityType,
  BulkImportField,
  FileDetails,
} from "interfaces/bulkImport";
import {
  BulkImportSubscriptionsInterface,
  BulkImportSubscriptionsRow,
} from "interfaces/bulkImportSubscriptions";
import {
  BulkImportContractsInterface,
  BulkImportContractsRow,
} from "interfaces/bulkImportContracts";
import { getDistinctObjectsByPath } from "utils/distinctValues";

export interface InvalidEntity {
  id: string;
  status: 'incorrect' | 'correct';
  type: 'product' | 'customer' | 'tag';
  name: string | null;
  createNew: boolean;
  entity: {
    createNew: boolean;
    id: string | null;
    name: string | null;
  };
  revenueType: {
    createNew: boolean;
    id: string | null;
  };
  // Removed as a request from sc-16801, but likely to be brought back some time
  // items: number;
}

interface UpdateInvalidEntityPayload {
  id: string;
  createNew?: boolean;
  entity?: {
    createNew: boolean;
    id: string | null;
    name: string | null;
  };
  revenueType?: {
    createNew: boolean;
    id: string | null;
  };
  status?: 'incorrect' | 'correct';
}

interface State {
  subscriptionsResults: Pick<BulkImportSubscriptionsInterface, 'rows'>;
  contractsResults: Pick<BulkImportContractsInterface, 'rows'>;
  fileDetails: FileDetails;
  invalidCustomers: InvalidEntity[];
  invalidProducts: InvalidEntity[];
  invalidTags: InvalidEntity[];
  createAllNewCustomers: boolean;
  createAllNewProducts: boolean;
  createAllNewTags: boolean;
  contractConflictedLines: number[];
  isUserAbleToNavigateBackToCleanData: boolean;
  commonData: {
    totalRows?: number | null,
    errorRows?: number | null,
    duplicateRows?: number | null,
    contractsCount?: number | null;
    contractsErrorsCount?: number | null;
    contractsDuplicatesCount?: number | null;
    contractLinesCount?: number | null;
    contractLinesErrorsCount?: number | null;
    contractLinesDuplicatesCount?: number | null;
  };
}

const initialState: State = {
  subscriptionsResults: {
    rows: [],
  },
  contractsResults: {
    rows: [],
  },
  commonData: {},
  fileDetails: {
    name: null,
    size: null,
    lastModified: null,
    uploadedAt: null,
  },
  invalidCustomers: [],
  invalidProducts: [],
  invalidTags: [],
  createAllNewCustomers: false,
  createAllNewProducts: false,
  createAllNewTags: false,
  contractConflictedLines: [],
  isUserAbleToNavigateBackToCleanData: false,
};

const countErrorRows = (rows: any[]): number => rows
  .reduce((acc, row) => row.errors && row.errors !== 'duplicate' ? acc + 1 : acc, 0);

const countDuplicateRows = (rows: any[]): number => rows
  .reduce((acc, row) => row.errors && row.errors === 'duplicate' ? acc + 1 : acc, 0);

const countContractErrorRows = (rows: any[]): number => rows
  .reduce((acc, row) => row.contractErrors && row.contractErrors !== 'duplicate' ? acc + 1 : acc, 0);

const countContractLineErrorRows = (rows: any[]): number => rows
  .reduce((acc, row) => row.contractLineErrors && row.contractLineErrors !== 'duplicate' ? acc + 1 : acc, 0);

const countContractDuplicateRows = (rows: any[]): number => rows
  .reduce((acc, row) => row.contractErrors && row.contractErrors === 'duplicate' ? acc + 1 : acc, 0);

const countContractLineDuplicateRows = (rows: any[]): number => rows
  .reduce((acc, row) => row.contractLineErrors && row.contractLineErrors === 'duplicate' ? acc + 1 : acc, 0);

const bulkImportSlice = createSlice({
  name: 'bulkImport',
  initialState,
  reducers: {
    /**
     * Contracts import reducers
     */
    populateContracts: (state, action: PayloadAction<{
      result: BulkImportContractsInterface,
      fileDetails?: FileDetails,
    }>) => {
      const {
        result,
        fileDetails,
      } = action.payload;

      const {
        rows,
        ...commonData
      } = result;

      state.contractsResults = result;
      state.commonData = commonData;

      if (fileDetails) {
        state.fileDetails = fileDetails;
      }

      state.invalidCustomers = getDistinctObjectsByPath(rows, 'contractCustomer', 'providedValue')
        .filter((row) => !row.contractCustomer.matchedValue?.id && row.contractCustomer.providedValue)
        .map((row) => ({
          id: row.id,
          status: 'incorrect',
          type: 'customer',
          name: row.contractCustomer.providedValue,
          createNew: false,
          entity: {
            createNew: false,
            id: null,
            name: null,
          },
          revenueType: {
            createNew: false,
            id: null,
          },
          // TODO This should be returned from BE, not calculated here
          // items: Number(result.contractLinesCount) > 1000
          //   ? 9999
          //   : rows.filter((r) => r.contractCustomer.providedValue === row.contractCustomer.providedValue ).length,
        }));

      state.invalidProducts = getDistinctObjectsByPath(rows, 'contractLineProduct', 'providedValue')
        .filter((row) => !row.contractLineProduct.matchedValue?.id && row.contractLineProduct.providedValue)
        .map((row) => ({
          id: row.id,
          status: 'incorrect',
          type: 'product',
          name: row.contractLineProduct.providedValue,
          createNew: false,
          entity: {
            createNew: false,
            id: null,
            name: null,
          },
          revenueType: {
            createNew: false,
            id: null,
          },
          // TODO This should be returned from BE, not calculated here
          // items: Number(result.contractLinesCount) > 1000
          //   ? 9999
          //   : rows.filter((r) => r.contractLineProduct.providedValue === row.contractLineProduct.providedValue ).length,
        }));

      const foundContractTags = [
        ...rows.flatMap((row) => row.contractTags.matchedValue && row.contractTags.matchedValue?.map((tag) => tag.name?.toLowerCase())),
      ];
      const foundLineTags = [
        ...rows.flatMap((row) => row.contractLineTags.matchedValue && row.contractLineTags.matchedValue?.map((tag) => tag.name?.toLowerCase())),
      ];
      const foundTags = [...new Set([...foundContractTags, ...foundLineTags])];

      const providedContractTags = rows.flatMap((row) => row.contractTags.providedValue && row.contractTags.providedValue?.split(',')
        .map((tagName) => tagName.trim()));
      const providedLineTags = rows.flatMap((row) => row.contractLineTags.providedValue && row.contractLineTags.providedValue?.split(',')
        .map((tagName) => tagName.trim()));
      const invalidTags = [...new Set([...providedContractTags, ...providedLineTags])]
        .filter(tagName => tagName && !foundTags.includes(tagName.toLowerCase()));

      state.invalidTags = invalidTags.map((tagName) => ({
        id: tagName!,
        status: 'incorrect',
        type: 'tag',
        name: tagName!,
        createNew: false,
        entity: {
          createNew: false,
          id: null,
          name: null,
        },
        revenueType: {
          createNew: false,
          id: null,
        },
        items: 0,
      }));

      state.contractConflictedLines = (rows as any[])
        .reduce((acc, row, index) => row.errors && row.errors === 'contract_conflict'
          ? [...acc, index + 1]
          : acc,
        [] as number[]);
    },
    updateContractsRow: (state, action: PayloadAction<{ id: string, data: BulkImportContractsRow }>) => {
      const { id, data } = action.payload;

      const rowsUpdated = state.contractsResults.rows
        .map((row) => row.id === id ? data : row);

      const rows = rowsUpdated
        .map((row) => data.relatedRowsIds.includes(row.id)
          ? {
              ...row,
              contractName: data.contractName,
            }
          : row,
        );

      state.contractsResults.rows = rows;
      state.commonData.contractsErrorsCount = countContractErrorRows(rows);
      state.commonData.contractsDuplicatesCount = countContractDuplicateRows(rows);
      state.commonData.contractLinesErrorsCount = countContractLineErrorRows(rows);
      state.commonData.contractLinesDuplicatesCount = countContractLineDuplicateRows(rows);
    },
    updateContractsField: (state, action: PayloadAction<{ id: string, name: string, data: BulkImportField<any, any> }>) => {
      const { id, name, data } = action.payload;

      const rows = state.contractsResults.rows
        .map((row) => row.id === id ? { ...row, [name]: data } : row);

      state.contractsResults.rows = rows;
      state.commonData.contractsErrorsCount = countContractErrorRows(rows);
      state.commonData.contractsDuplicatesCount = countContractDuplicateRows(rows);
      state.commonData.contractLinesErrorsCount = countContractLineErrorRows(rows);
      state.commonData.contractLinesDuplicatesCount = countContractLineDuplicateRows(rows);
    },
    deleteContracts: (state, action: PayloadAction<string[]>) => {
      const ids = action.payload;

      const rows = state.contractsResults.rows.filter((row) => !ids.includes(row.id));

      state.contractsResults.rows = rows;
      state.commonData.contractsCount = state.commonData.contractsCount! - ids.length;
      state.commonData.contractsErrorsCount = countContractErrorRows(rows);
      state.commonData.contractsDuplicatesCount = countContractDuplicateRows(rows);
    },
    deleteContractLines: (state, action: PayloadAction<{
      ids: string[],
      fromContract?: boolean,
    }>) => {
      const ids = action.payload.ids;
      const fromContract = action.payload.fromContract;

      const emptyField = {
        providedValue: null,
        matchedValue: null,
        errorCode: null,
      };

      const withContractDeletedRows = state.contractsResults.rows.filter((row) => !ids.includes(row.id));

      const onlyContractLineRows = state.contractsResults.rows.map((row) => ids.includes(row.id)
        ? {
            ...row,
            contractLineName: emptyField,
            contractLineProduct: emptyField,
            contractLineAmount: emptyField,
            contractLineBookingDate: emptyField,
            contractLineStartDate: emptyField,
            contractLineEndDate: emptyField,
            contractLineCancelDate: emptyField,
            contractLineTags: emptyField,
            contractLineExternalLink: emptyField,
            contractLineNote: emptyField,
            contractLineCRMID: emptyField,
            contractLineContractBasedMRRCalc: emptyField,
            contractLineErrors: null,
            newContractLine: false,
          }
        : row,
      );

      const rows = fromContract ? withContractDeletedRows : onlyContractLineRows;

      state.contractsResults.rows = rows;
      state.commonData.contractLinesCount = state.commonData.contractLinesCount! - ids.length;
      state.commonData.contractLinesErrorsCount = countContractLineErrorRows(rows);
      state.commonData.contractLinesDuplicatesCount = countContractLineDuplicateRows(rows);
    },
    /**
     * Subscriptions import reducers
     */
    populateSubscriptions: (state, action: PayloadAction<{
      result: BulkImportSubscriptionsInterface,
      fileDetails?: FileDetails,
    }>) => {
      state.subscriptionsResults = action.payload.result;
      state.commonData.totalRows = action.payload.result.totalRows;
      state.commonData.duplicateRows = action.payload.result.duplicateRows;
      state.commonData.errorRows = action.payload.result.errorRows;

      if (action.payload.fileDetails) {
        state.fileDetails = action.payload.fileDetails;
      }

      state.invalidCustomers = getDistinctObjectsByPath(action.payload.result.rows, 'customer', 'providedValue')
        .filter((row) => !row.customer.matchedValue?.id && row.customer.providedValue)
        .map((row) => ({
          id: row.id,
          status: 'incorrect',
          type: 'customer',
          name: row.customer.providedValue,
          createNew: false,
          entity: {
            createNew: false,
            id: null,
            name: null,
          },
          revenueType: {
            createNew: false,
            id: null,
          },
          // TODO This should be returned from BE, not calculated here
          items: Number(action.payload.result.totalRows) > 1000
            ? 9999
            : action.payload.result.rows.filter((r) => r.customer.providedValue === row.customer.providedValue ).length,
        }));

      state.invalidProducts = getDistinctObjectsByPath(action.payload.result.rows, 'product', 'providedValue')
        .filter((row) => !row.product.matchedValue?.id && row.product.providedValue)
        .map((row) => ({
          id: row.id,
          status: 'incorrect',
          type: 'product',
          name: row.product.providedValue,
          createNew: false,
          entity: {
            createNew: false,
            id: null,
            name: null,
          },
          revenueType: {
            createNew: false,
            id: null,
          },
          // TODO This should be returned from BE, not calculated here
          items: Number(action.payload.result.totalRows) > 1000
            ? 9999
            : action.payload.result.rows.filter((r) => r.product.providedValue === row.product.providedValue ).length,
        }));

      const foundTags = [
        ...new Set(action.payload.result.rows.flatMap((row) => row.tags.matchedValue && row.tags.matchedValue?.map((tag) => tag.name?.toLocaleLowerCase()))),
      ];

      const invalidTags = [
        ...new Set(action.payload.result.rows.flatMap((row) => row.tags.providedValue && row.tags.providedValue?.split(',')
          .map((tagName) => tagName.trim())),
        ),
      ]
        .filter(tagName => tagName && !foundTags.includes(tagName.toLocaleLowerCase()));

      state.invalidTags = invalidTags.map((tagName) => ({
        id: tagName!,
        status: 'incorrect',
        type: 'tag',
        name: tagName!,
        createNew: false,
        entity: {
          createNew: false,
          id: null,
          name: null,
        },
        revenueType: {
          createNew: false,
          id: null,
        },
        items: 0,
      }));

      state.contractConflictedLines = (action.payload.result.rows as any[])
        .reduce((acc, row, index) => row.errors && row.errors === 'contract_conflict'
          ? [...acc, index + 1]
          : acc,
        [] as number[]);
    },
    updateSubsRow: (state, action: PayloadAction<{ id: string, data: BulkImportSubscriptionsRow }>) => {
      const { id, data } = action.payload;

      const rows = state.subscriptionsResults.rows
        .map((row) => row.id === id ? data : row);

      state.subscriptionsResults.rows = rows;
      state.commonData.errorRows = countErrorRows(rows);
      state.commonData.duplicateRows = countDuplicateRows(rows);
    },
    updateSubsField: (state, action: PayloadAction<{ id: string, name: string, data: BulkImportField<any, any> }>) => {
      const { id, name, data } = action.payload;

      const rows = state.subscriptionsResults.rows
        .map((row) => row.id === id ? { ...row, [name]: data } : row);

      state.subscriptionsResults.rows = rows;
      state.commonData.errorRows = countErrorRows(rows);
      state.commonData.duplicateRows = countDuplicateRows(rows);
    },
    deleteSubsRows: (state, action: PayloadAction<string[]>) => {
      const ids = action.payload;

      const rows = (state.subscriptionsResults.rows)
        .filter((row) => !ids.includes(row.id));

      state.subscriptionsResults.rows = rows;
      state.commonData.totalRows = rows.length;
      state.commonData.errorRows = countErrorRows(rows);
      state.commonData.duplicateRows = countDuplicateRows(rows);
    },
    /**
     * Clean Data - common reducers
     */
    updateInvalidCustomer: (state, action: PayloadAction<UpdateInvalidEntityPayload>) => {
      const { payload } = action;
      const status = payload.entity?.createNew || payload.entity?.id ? 'correct' : 'incorrect';

      state.invalidCustomers = state.invalidCustomers.map((entity) => entity.id === payload.id
        ? { ...entity, ...payload, status}
        : entity);
      state.createAllNewCustomers = state.invalidCustomers.filter((cust) => !cust.createNew).length === 0;
    },
    updateInvalidProduct: (state, action: PayloadAction<UpdateInvalidEntityPayload>) => {
      const { payload } = action;
      const status = (payload.revenueType?.createNew && payload.revenueType?.id) || payload.entity?.id ? 'correct' : 'incorrect';

      state.invalidProducts = state.invalidProducts.map((entity) => entity.id === payload.id
        ? { ...entity, ...payload, status }
        : entity);
      state.createAllNewProducts = state.invalidProducts.filter((prod) => !prod.createNew).length === 0;
    },
    updateInvalidTag: (state, action: PayloadAction<UpdateInvalidEntityPayload>) => {
      const { payload } = action;
      const status = payload.entity?.createNew || payload.entity?.id ? 'correct' : 'incorrect';

      state.invalidTags = state.invalidTags.map((entity) => entity.id === payload.id
        ? { ...entity, ...payload, status }
        : entity);
      state.createAllNewTags = state.invalidTags.filter((tag) => !tag.createNew).length === 0;
    },
    toggleCreateNew: (state, action: PayloadAction<{ checked: boolean; tab: string }>) => {
      const { checked, tab } = action.payload;

      if (tab === BulkImportEntityType.PRODUCTS) {
        state.invalidProducts = state.invalidProducts.map((product) => ({
          ...product,
          createNew: checked,
          entity: {
            createNew: checked,
            id: null,
            name: null,
          },
          revenueType: {
            createNew: checked,
            id: null,
          },
          status: 'incorrect',
        }));
        state.createAllNewProducts = checked;
      }

      if (tab === BulkImportEntityType.CUSTOMERS) {
        state.invalidCustomers = state.invalidCustomers.map((customer) => ({
          ...customer,
          createNew: checked,
          entity: {
            createNew: checked,
            id: null,
            name: null,
          },
          status: checked ? 'correct' : 'incorrect',
        }));
        state.createAllNewCustomers = checked;
      };

      if (tab === BulkImportEntityType.TAGS) {
        state.invalidTags = state.invalidTags.map((tag) => ({
          ...tag,
          createNew: checked,
          entity: {
            createNew: checked,
            id: null,
            name: null,
          },
          status: checked ? 'correct' : 'incorrect',
        }));
        state.createAllNewTags = checked;
      };
    },
    // Some reducers were removed in favor of making re-validation on backend
    // Removed reducers gist: https://gist.github.com/fgrzelak/bc28572d5531f37e48dacc65cf635dc0
    clear: () => initialState,
    userReachedReviewDataScreen: (state, action: PayloadAction<boolean>) => {
      state.isUserAbleToNavigateBackToCleanData = action.payload;
    },
  },
});

export const {
  reducer: bulkImportReducer,
  actions: bulkImportActions,
} = bulkImportSlice;
