import { User } from "interfaces/auth";

const save = (name: string, payload: any) => {
  sessionStorage.setItem(name, JSON.stringify(payload));
};

const read = (name: string) => {
  const sessionData = sessionStorage.getItem(name);
  if (!sessionData) return null;

  const sessionDataParsed = JSON.parse(sessionData);
  if (Array.isArray(sessionData) && sessionDataParsed.length === 0) return null;

  return sessionDataParsed;
};

export const clearSessionStorage = () => {
  sessionStorage.clear();
};

export const clearDataFromSessionStorage = (key: string) => {
  Object.keys(sessionStorage)
    .filter((item) => item.startsWith(key))
    .forEach((item) => sessionStorage.removeItem(item));
};

export const readDataFromSessionStorage = (key: string, user: User) => {
  return read(`${key}_${user.currentCompanyId}_${user.id}_${window.location.pathname}`);
};

export const saveDataToSessionStorage = (key: string, user: User, filtersToApply: any) => {
  save(`${key}_${user.currentCompanyId}_${user.id}_${window.location.pathname}`, filtersToApply);
};
