import { api } from "./api";
import {
  Subscription,
  Subscriptions,
  SubscriptionsBulkEditPayload,
  SubscriptionsCancelPayload,
  SubscriptionsLinkPayload,
  SubscriptionsUnlinkPayload,
  SubscriptionUpdatePayload,
} from "interfaces/subscriptions";
import {
  apiAddRecipe,
  apiUpdateRecipe,
} from "utils/api";
import {
  CommonMetricTags,
  SubscriptionsTags,
} from "./api-tags";

export const subscriptionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllSubscriptions: builder.query<Subscriptions, void>({
      query: () => ({
        url: 'subscriptions',
        params: {
          deleted: "false",
        },
      }),
      providesTags: [SubscriptionsTags.Subscriptions],
    }),
    deleteSubscriptions: builder.mutation<void, string[]>({
      query: (ids) => {
        return {
          url: `subscriptions`,
          method: 'DELETE',
          body: ids,
        };
      },
      invalidatesTags: (res, err) => !err ? [
        SubscriptionsTags.Subscriptions,
        ...CommonMetricTags,
      ] : [],
    }),
    addSubscription: builder.mutation<Subscription, SubscriptionUpdatePayload>({
      query: (subscription) => {
        return {
          url: 'subscriptions',
          method: 'POST',
          body: subscription,
        };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: addedSubscription} = await queryFulfilled;
          dispatch(subscriptionsApi.util
            .updateQueryData('getAllSubscriptions', undefined, apiAddRecipe(addedSubscription)));
        } catch {}
      },
      invalidatesTags: (res, err) => !err ? [
        ...CommonMetricTags,
      ] : [],
    }),
    updateSubscription: builder.mutation<Subscription, SubscriptionUpdatePayload>({
      query: (subscription) => {
        return {
          url: `subscriptions/${subscription.id}`,
          method: 'PUT',
          body: subscription,
        };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: updatedSubscription} = await queryFulfilled;
          dispatch(subscriptionsApi.util
            .updateQueryData('getAllSubscriptions', undefined, apiUpdateRecipe(updatedSubscription)));
        } catch {}
      },
      invalidatesTags: (res, err) => !err ? [
        SubscriptionsTags.Subscriptions,
        ...CommonMetricTags,
      ] : [],
    }),
    cancelSubscriptions: builder.mutation<void, SubscriptionsCancelPayload>({
      query: (cancelBody) => {
        return {
          url: 'subscriptions/cancel',
          method: 'POST',
          body: cancelBody,
        };
      },
      invalidatesTags: (res, err) => !err ? [
        SubscriptionsTags.Subscriptions,
        ...CommonMetricTags,
      ] : [],
    }),
    renewSubscription: builder.mutation<Subscription, SubscriptionUpdatePayload>({
      query: (subscription) => {
        return {
          url: `subscriptions/${subscription.id}/renew`,
          method: 'POST',
          body: subscription,
        };
      },
      invalidatesTags: (res, err) => !err ? [
        SubscriptionsTags.Subscriptions,
        ...CommonMetricTags,
      ] : [],
    }),
    bulkEditSubscriptions: builder.mutation<undefined, SubscriptionsBulkEditPayload>({
      query: (subscriptions) => {
        return {
          url: 'subscriptions/bulk_edit',
          method: 'POST',
          body: subscriptions,
        };
      },
      invalidatesTags: (res, err) => !err ? [
        SubscriptionsTags.Subscriptions,
        ...CommonMetricTags,
      ] : [],
    }),
    subscriptionUniqueness: builder.query<boolean, { customerID: string, name: string }>({
      query: (body) => ({
        method: 'POST',
        url: 'subscriptions/unique',
        body,
      }),
    }),
    linkSubscription: builder.mutation<void, SubscriptionsLinkPayload>({
      query: (linkBody) => {

        const {
          subscriptionId,
          ...rest
        } = linkBody;

        return {
          url: `subscriptions/${subscriptionId}/link`,
          method: 'POST',
          body: rest,
        };
      },
      invalidatesTags: (res, err) => !err ? [
        SubscriptionsTags.Subscriptions,
        ...CommonMetricTags,
      ] : [],
    }),
    unlinkSubscription: builder.mutation<void, SubscriptionsUnlinkPayload>({
      query: ({ subscriptionId, type }) => {
        return {
          url: `subscriptions/${subscriptionId}/unlink/${type}`,
          method: 'POST',
        };
      },
      invalidatesTags: (res, err) => !err ? [
        SubscriptionsTags.Subscriptions,
        ...CommonMetricTags,
      ] : [],
    }),
  }),
});

export const {
  useGetAllSubscriptionsQuery,
  useDeleteSubscriptionsMutation,
  useAddSubscriptionMutation,
  useUpdateSubscriptionMutation,
  useCancelSubscriptionsMutation,
  useRenewSubscriptionMutation,
  useBulkEditSubscriptionsMutation,
  useLazySubscriptionUniquenessQuery,
  useLinkSubscriptionMutation,
  useUnlinkSubscriptionMutation,
} = subscriptionsApi;