import styled from "styled-components";
import { theme } from "theme/theme";

export const SummaryContainer = styled.div`
  padding: 16px;
  background-color: ${theme.colors.secondaryBackground};
  border-radius: 8px;
  margin-bottom: 40px;

  section {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;

  &:last-of-type:not(:first-of-type) {
      margin-top: 16px;
    }
  }
`;

export const SummaryEntry = styled.div<{ bold?: boolean }>`
  font-weight: ${props => props.bold ? '600' : '500'};
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  color: ${theme.colors.textDark};

  svg {
    width: 16px;
    height: 16px;
    color: ${props => props.bold ? theme.colors.primary : theme.colors.fieldBorder}
  }
`;
