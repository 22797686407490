import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box } from 'components/common/Box';
import {
  addMonths,
  format,
} from 'date-fns';
import { toastSuccess } from 'utils/toast';
import { isoToDate } from 'utils/dates';
import { AgGridReact } from '@ag-grid-community/react';
import { FactaTable } from 'components/FactaTable';
import {
  useGetOneMetricWithValuesQuery,
  usePutManualMetricsMutation,
} from 'store/services/metrics';
import {
  ColDef,
  GridOptions,
  ProcessCellForExportParams,
} from '@ag-grid-community/core';
import {
  mapMetricsWithValuesToTableView,
  prepareManualsPayload,
} from 'utils/metrics';
import { MetricOrigin } from 'interfaces/metrics';
import { Modal } from 'components/Layout/Modal';
import { SelectDates } from './SelectDates';
import { format as currencyFormat } from "currency-formatter";
import { parsedPastedValue } from 'utils/financialsUploader';

interface Props {
  metricId: string;
}

export const ManualMetricTable = ({ metricId }: Props) => {
  const gridRef = useRef<AgGridReact>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const [periodStart, setPeriodStart] = useState<Date | undefined>(undefined);
  const [periodEnd, setPeriodEnd] = useState<Date | undefined>(undefined);
  const [periodsNumber, setPeriodsNumber] = useState<number | string | undefined>(0);
  const [tableData, setTableData] = useState<any[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isSelectDatesOpen, setIsSelectDatesOpen] = useState(false);

  const { data: metrics, isFetching: isFetchingMetrics } = useGetOneMetricWithValuesQuery({
    metricId,
    origin: MetricOrigin.MANUAL,
    omitSettingsDates: 'true',
  });

  const [putManuals, { isLoading: isUploading }] = usePutManualMetricsMutation();

  const processCellFromClipboard = (params: ProcessCellForExportParams<any, any>) => {
    const colDef = params.column.getColDef();

    if (colDef.type === 'numberField') {
      return parsedPastedValue(params.value);
    }

    return params.value;
  };

  const gridOptions: GridOptions = {
    onCellEditingStopped: (e) => {
      e.api.autoSizeAllColumns();
    },
    processCellFromClipboard,
    suppressRowClickSelection: true,
    undoRedoCellEditing: true,
    undoRedoCellEditingLimit: 10,
    columnTypes: {
      numberField: {
        editable: true,
      },
    },
    maintainColumnOrder: false,
    statusBar: {
      statusPanels: [
        { statusPanel: 'agAggregationComponent' },
      ],
    },
  };

  const commonColumns: ColDef[] = [
    {
      field: 'id',
      headerName: 'Version',
      resizable: true,
      pinned: 'left',
      minWidth: 200,
      valueGetter: () => 'Actuals',
    },
  ];
  const columnDefs: ColDef[] = useMemo(() => {
    return [
      ...commonColumns,
      ...columns,
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const handleResetForm = useCallback(() => {
    setTableData(mapMetricsWithValuesToTableView(metrics));
  }, [metrics]);

  const handleSave = async () => {
    gridRef.current?.api.stopEditing();
    if (!periodStart || !periodEnd) return;

    const items: any[] = [];
    gridRef.current?.api.forEachNode((node) => {
      items.push(node.data);
    });

    const payload = prepareManualsPayload({
      data: items,
      start: periodStart,
      end: periodEnd,
    });

    await putManuals(payload)
      .unwrap()
      .then(() => {
        toastSuccess('Metric successfully updated.');
      });
  };

  useEffect(() => {
    const columns: ColDef[] = new Array(periodsNumber)
      .fill(undefined)
      .map((_, index) => {
        const field = format(addMonths(periodStart || new Date(), index), 'yyyy-MM-dd');

        const headerName = format(addMonths(periodStart || new Date(), index), 'MMM y');
        return {
          field,
          headerName,
          editable: true,
          type: 'numberField',
          minWidth: 170,
          width: 170,
          headerClass: 'ag-right-aligned-header',
          cellEditor: 'agNumberCellEditor',
          cellEditorParams: {
            precision: 10,
          },
          cellClass: 'ag-right-aligned-cell',
          valueFormatter: ({ value }) => value !== '' && value !== undefined && value !== null ? currencyFormat(value, { code: 'USD', precision: 2, symbol: '' }) : '',
        };
      });

    setColumns(columns);
  }, [periodStart, periodEnd, periodsNumber]);

  useEffect(() => {
    if (!metrics || isInitialized) return;
    setPeriodStart(isoToDate(metrics?.at(0)?.entries.at(0)?.period));
    setPeriodEnd(isoToDate(metrics?.at(0)?.entries.at(-1)?.period));
    setPeriodsNumber(metrics?.at(0)?.entries.length || 0);
    setIsInitialized(true);
  }, [isInitialized, metrics]);

  useEffect(() => {
    handleResetForm();
  }, [handleResetForm]);

  return (
    <Box>
      <FactaTable
        gridRef={gridRef}
        data={tableData}
        columnDefs={columnDefs}
        selectedRowsLength={0}
        entityName={'line'}
        gridOptions={gridOptions}
        condensed
        customButtons={[
          {
            title: 'Change Dates',
            onClick: () => setIsSelectDatesOpen(true),
            variant: 'borderless',
            size: 'large',
            divider: true,
          },
          {
            title: 'Reset Form',
            onClick: handleResetForm,
            variant: 'borderless',
            size: 'large',
            pushRight: true,
          },
          {
            title: 'Submit',
            onClick: handleSave,
            disabled: isUploading,
            variant: 'contained',
            size: 'large',
          },
        ]}
        suppressLoadingOverlay
        isDisabled={isUploading || isFetchingMetrics}
        autoHeight
        customTitle="Paste in values"
        suppressQuantityDisplay
        suppressColumnViews
      />
      <Modal
        isOpen={isSelectDatesOpen}
        onClose={() => setIsSelectDatesOpen(false)}
        suppressOverflow
      >
        <SelectDates
          periodStart={periodStart}
          setPeriodStart={setPeriodStart}
          periodEnd={periodEnd}
          setPeriodEnd={setPeriodEnd}
          periodsNumber={periodsNumber}
          setPeriodsNumber={setPeriodsNumber}
          onClose={() => setIsSelectDatesOpen(false)}
        />
      </Modal>
    </Box>
  );
};
