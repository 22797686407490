import React from 'react';
import { Button } from '../Button';
import { OpenInNewIcon } from 'assets/icons';

export const AgGridCellRendererLink = (item: any) => {
  if (!item.data) return null;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Button
        variant="simple"
        color={item.value ? "primary" : "secondary"}
        disabled={!item.value}
        onClick={() => window.open(item.value, "_blank")}
      >
        <OpenInNewIcon />
      </Button>
    </div>
  );
};