import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { ICellEditorParams } from '@ag-grid-community/core';
import { Dropdown } from 'components/common/Dropdown';
import {
  SelectItemRenderer,
  SelectKeyDown,
} from 'react-dropdown-select';
import { ItemComponent } from './ItemComponent';
import { StyledAgGridDropdown } from './styled';

interface AgGridDropdownProps extends ICellEditorParams {
  options: any[];
  width: number;
  customItemRenderer: (props: SelectItemRenderer<any>) => JSX.Element;
  create?: boolean;
  onCreateNew?: ((item: any) => void) | undefined;
  /** A property that must exist on option object to allow Create New functionality */
  optionSpecificProperty: string;
}

export const AgGridDropdown = memo(
  forwardRef((props: AgGridDropdownProps, ref) => {
    const [value, setValue] = useState(props.value);
    const [done, setDone] = useState(false);
    const [isCanceled, setCanceled] = useState(false);

    useImperativeHandle(ref, () => ({
      getValue() {
        if (!Array.isArray(value)) return props.value;
        return value?.[0] ? value[0] : null;
      },
      isCancelAfterEnd() {
        return isCanceled;
      },
    }));

    const handleKeyDownFn = ({ event }: SelectKeyDown<any>) => {
      if (event.key === 'Escape') {
        setCanceled(true);
      }

      if (event.key === 'Backspace') {
        event.stopPropagation();
      }
    };

    useEffect(() => {
      if (done) props.stopEditing();
    }, [done, props]);

    return (
      <StyledAgGridDropdown style={{ width: `${props.width}px` }}>
        <Dropdown
          values={props.value ? [props.value] : []}
          options={props.options}
          labelField="name"
          valueField="id"
          searchable
          searchBy="name"
          onChange={(newValue) => {
            if (props.optionSpecificProperty === undefined || newValue[0]?.hasOwnProperty(props.optionSpecificProperty)) {
              setValue(newValue);
            }
            setDone(true);
          }}
          keepOpen
          itemRenderer={(innerProps) => {
            if (props.customItemRenderer) {
              return <props.customItemRenderer {...innerProps} />;
            } else {
              return <ItemComponent {...innerProps} />;
            }
          }}
          handleKeyDownFn={handleKeyDownFn}
          create={props.create}
          onCreateNew={props.onCreateNew}
        />
      </StyledAgGridDropdown>
    );
  }),
);
