import styled, { css } from "styled-components";

interface ButtonsContainerProps {
  fillRow?: boolean;
  pushDown?: boolean;
  pushRight?: boolean;
  paddingBottom?: number;
  gap?: number;
  alignTop?: boolean;
}

export const ButtonsContainer = styled.div<ButtonsContainerProps>`
  position: relative;
  display: flex;
  align-items: ${props => props.alignTop ? 'flex-start' : 'center'};
  flex-wrap: wrap;
  gap: ${props => props.gap || '12'}px;
  width: 100%;

  ${props => props.pushRight && css`
    justify-content: flex-end;
  `}

  ${props => props.paddingBottom && css`
    padding-bottom: ${props.paddingBottom}px;
  `}

  ${props => props.pushDown && css`
    margin-top: auto;
  `}

  ${props => props.fillRow && css`
    gap: 24px;
    
    & > * {
      flex-grow: 1;
    }
  `}
`;

export const Divider = styled.div`
  border-right: 1px solid black;
  height: 16px;
`;

export const Spacer = styled.div`
  width: 1px;
  margin-left: auto;
`;