import React, { useEffect } from 'react';
import * as yup from "yup";
import {
  useForm,
  Controller,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CloseIcon,
  DeleteIcon,
} from 'assets/icons';
import { Button } from 'components/common/Button';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import {
  StyledButtonContainer,
  StyledFormContainer,
  StyledFormSectionLabel,
} from 'components/common/Forms/styled';
import { Dropdown } from 'components/common/Dropdown';
import { toastSuccess } from 'utils/toast';
import { actionBlocker } from 'utils/actionBlocker';
import { Tooltip } from 'components/common/Tooltip';
import { CompanyUser } from 'interfaces/company';
import { useBulkEditUserRolesMutation } from 'store/services/users';
import { UserRoleOptions } from 'utils/constants';
import { UserRole } from 'interfaces/auth';

interface Props {
  onClose: () => void;
  onSuccess: (id?: string) => void;
  onDelete: () => void;
  selectedRows: CompanyUser[];
  permissionLevel: UserRole;
}

export const EditUsersRoles = ({
  onClose,
  onSuccess,
  onDelete,
  selectedRows,
  permissionLevel,
}: Props) => {
  const [updateUsersRole, { isLoading }] = useBulkEditUserRolesMutation();
  const isBulkEdit = selectedRows.length > 1;
  const onSuccessToast = isBulkEdit
    ? 'Users roles successfully updated.'
    : 'User role successfully updated.';

  const permissionLevelIndex = UserRoleOptions.findIndex((role) => role.value === permissionLevel);
  const permissionBasedUserRoleOptions = UserRoleOptions.filter((_, index) => index >= permissionLevelIndex);

  const bulkEditSchema = yup.object({
    userRole: yup.array()
      .min(1, 'User role is required.')
      .nullable(),
  })
    .required();

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors, isDirty },
  } = useForm<{ userRole: { value: UserRole }[] }>({
    resolver: yupResolver(bulkEditSchema),
    mode: 'onChange',
    defaultValues: {
      userRole: [],
    },
  });

  useEffect(() => {
    const user = selectedRows[0];

    if (isBulkEdit) {
      reset({
        userRole: [],
      });
    } else if (user) {
      reset({
        userRole: [{ value: user.role }],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose, selectedRows.length]);

  const handleSaveAndClose = handleSubmit((data) => {
    const payload = {
      userIds: selectedRows.map((user) => user.id),
      role: data.userRole.at(0)?.value!,
    };

    return updateUsersRole(payload)
      .unwrap()
      .then(() => {
        toastSuccess(onSuccessToast);
        onClose();
        onSuccess();
      });
  });

  const handleClickDelete = onDelete;

  return (
    <>
      <header>
        <ButtonsContainer>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={() => actionBlocker(onClose, isDirty)}
            pushRight
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <StyledFormSectionLabel data-cy="ecu-header">
          <span>
            {isBulkEdit ? 'Bulk Edit' : 'Edit User Role'}
          </span>
          {isBulkEdit && (
            <span>{selectedRows.length} Users</span>
          )}
        </StyledFormSectionLabel>

        <ButtonsContainer paddingBottom={20}>
          <Tooltip title="Remove">
            <Button
              variant="icon"
              color="primary"
              size="large"
              onClick={handleClickDelete}
              aria-label="Remove"
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        </ButtonsContainer>
      </header>
      <main>
        <StyledFormContainer>
          <Controller
            name="userRole"
            control={control}
            render={({ field }) =>
              <Dropdown
                isRequired
                labelText="User Role"
                options={permissionBasedUserRoleOptions}
                labelField="value"
                valueField="value"
                searchBy="value"
                {...field}
                values={getValues('userRole')}
                dataCy="ecu-dropdown-user-roles"
                error={errors.userRole?.message}
              />
            }
          />
        </StyledFormContainer>
      </main>
      <footer>
        <StyledButtonContainer pushRight>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={onClose}
            disabled={isLoading || !isDirty}
            isLoading={isLoading}
            data-cy="ecu-button-cancel"
          >
            CANCEL
          </Button>
          <Button
            type="submit"
            onClick={handleSaveAndClose}
            disabled={isLoading || !isDirty}
            isLoading={isLoading}
            data-cy="ecu-button-save-close"
          >
            SAVE & CLOSE
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};
