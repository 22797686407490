import { yupResolver } from "@hookform/resolvers/yup";
import { CloseIcon } from "assets/icons";
import { Button } from "components/common/Button";
import {
  ButtonsContainer,
  Spacer,
} from "components/common/ButtonsContainer";
import {
  StyledButtonContainer,
  StyledFormContainer,
} from "components/common/Forms/styled";
import { InputBasic } from "components/common/Input";
import { SectionLabel } from "components/common/SectionLabel";
import { useForm } from "react-hook-form";
import * as yup from "yup";

interface Props {
  onSave: (name: string) => void;
  onClose: () => void;
}

export const AddCustomView = ({ onSave, onClose }: Props) => {
  const schema = yup.object({
    name: yup.string()
      .required('Name is required.'),
  })
    .required();

  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm<{ name: string }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: '',
    },
  });

  const handleSaveAndClose = handleSubmit((data) => {
    onSave(data.name);
  });

  return (
    <>
      <ButtonsContainer
        paddingBottom={40}
        alignTop
      >
        <SectionLabel marginBottom={0}>
          Save current view as...
        </SectionLabel>
        <Spacer />
        <Button
          aria-label="Close"
          variant="icon"
          size="large"
          onClick={onClose}
        >
          <CloseIcon />
        </Button>
      </ButtonsContainer>
      <StyledFormContainer onSubmit={handleSaveAndClose}>
        <InputBasic
          isRequired
          labelText="View Name"
          placeholder="Name your view"
          {...register('name')}
          onBlur={(e) => {
            const fieldValue = e.target.value;

            if (fieldValue) {
              setValue('name', fieldValue.trim(), { shouldValidate: true });
            }
          }}
          error={errors.name?.message}
          autoFocus
        />
      </StyledFormContainer>
      <StyledButtonContainer pushRight>
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          CANCEL
        </Button>
        <Button
          type="button"
          onClick={handleSaveAndClose}
        >
          SAVE
        </Button>
      </StyledButtonContainer>
    </>
  );
};