import styled from "styled-components";
import { theme } from "theme/theme";

export const StyledNewVersionPopup = styled.div`
  position: fixed;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.fieldBorder};
  z-index: 999;
  bottom: 60px;
  right: 60px;
  width: 280px;
  background-color: ${theme.colors.primaryBleach};
  opacity: 0.95;
  box-shadow: ${theme.boxShadow};
  color: ${theme.colors.textDark};
  line-height: 20px;
  
  div {
    display: inline;
    margin-bottom: 20px;

    svg {
      margin-left: 8px;
    }
  }
`;
