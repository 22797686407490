import { format } from "date-fns";
import {
  AccountMappingTableUpdatePayload,
  AccountMappingTreeUpdatePayload,
  AccountMappings,
  Accounts,
  BalanceSheetFAEnum,
  PeriodType,
  ProfitAndLossFAEnum,
} from "interfaces/accounts";
import {
  FinancialType,
  FinancialsUpdatePayload,
} from "interfaces/financials";
import { format as currencyFormat } from "currency-formatter";
import {
  formatDateStringToMMMYearDisplay,
  formatDateToISODate,
} from "./dates";
import { path } from "ramda";
import {
  ColDef,
  IRowNode,
  ValueGetterParams,
} from "@ag-grid-community/core";

export const prepareFinancialsPayload = (
  data: any[],
  start: Date,
  end: Date,
  financialType: FinancialType,
): FinancialsUpdatePayload => {
  const periodStart = format(formatDateToISODate(start), 'yyyy-MM-dd');
  const periodEnd = format(formatDateToISODate(end), 'yyyy-MM-dd');

  const lines = data.filter((line: any) => line.glAccountName)
    .map((line: any) => {
      const {
        id,
        glAccountName,
        accountType,
        accountName,
        ...tableItems
      } = line;

      const entries = Object.entries(tableItems)
        .map((line) => ({
          date: line[0],
          value: line[1],
        }))
        .filter((line) => line.date >= periodStart && line.date <= periodEnd);

      return {
        glAccount: glAccountName,
        accountType: accountType,
        account: accountName,
        entries,
      };
    });

  return {
    financialType,
    financials: lines,
  };
};

export const verifyFinancialsPayload = (payload: FinancialsUpdatePayload) => {
  return payload.financials.every((item) => item.glAccount);
};

/** Account Mapping Table View */

export const mapAccountMappingsToTableView = (accountMappingsData: AccountMappings) => {
  return accountMappingsData?.accountMappings?.map((accountMapping) => ({
    glAccountId: accountMapping.glAccount.id,
    glAccountName: accountMapping.glAccount.name,
    glAccountDisplayName: accountMapping.glAccount.displayName,
    source: accountMapping.glAccount?.source,
    accountName: accountMapping.account?.name,
    accountType: accountMapping.account?.type,
    financialsData: accountMapping.financialsData,
    used: accountMapping.glAccount.used,
  }));
};

export const prepareAccountMappingTablePayload = (data: any[]): AccountMappingTableUpdatePayload => {
  return data.map((line: any) => {
    const {
      glAccountName,
      financialsData,
      glAccountDisplayName,
      used,
      ...rest
    } = line;

    return rest;
  });
};

export const countUncategorized = (payload: AccountMappingTableUpdatePayload) => {
  return payload.filter((item) => !item.accountName || !item.accountType).length;
};

/** Account Mapping Tree View */

export const mapAccountsToTreeView = (accountsData: Accounts, accountMappingsData: AccountMappings) => {
  let accounts: any[] = [{
    id: '_uncategorized',
    path: ['Uncategorized'],
    type: 'uncategorized',
    used: true,
  }];

  accountsData.forEach((cat) => {
    accounts.push({
      id: `cat_${crypto.randomUUID()}`,
      path: [cat.name],
      type: 'facta_account_type',
      used: true,
    });

    cat.accounts.forEach((acc) => {
      const glAccountId = `fa_${cat.name}_${acc.name}`;
      if (accounts.find((a) => a.glAccountId === glAccountId)) return;

      accounts.push({
        id: acc.id,
        path: [cat.name, acc.name],
        type: 'facta_account_name',
        used: true,
      });
    });

    // TODO below should be moved to backend ep:
    // GET accounts/{fin_type}
    if (cat.name === ProfitAndLossFAEnum['Total Cost of Goods Sold']) {
      accounts.push({
        id: 'total-gross-profit',
        path: ['Gross Profit'],
        type: 'total',
        used: true,
      });
    }

    if (cat.name === ProfitAndLossFAEnum['Total General & Administrative']) {
      accounts.push({
        id: 'total-operating-expenses',
        path: ['Total Operating Expenses'],
        type: 'total',
        used: true,
      }, {
        id: 'total-operating-profit',
        path: ['Operating Profit'],
        type: 'total',
        used: true,
      });
    }

    if (cat.name === ProfitAndLossFAEnum['Adjustments']) {
      accounts.push({
        id: 'total-net-income',
        path: ['Net Income'],
        type: 'total',
        used: true,
      });
    }

    if (cat.name === BalanceSheetFAEnum['Total Other Assets']) {
      accounts.push({
        id: 'total-assets',
        path: ['Total Assets'],
        type: 'total',
        used: true,
      });
    }

    if (cat.name === BalanceSheetFAEnum['Total Long Term Liabilities']) {
      accounts.push({
        id: 'total-liabilities',
        path: ['Total Liabilities'],
        type: 'total',
        used: true,
      });
    }

    if (cat.name === BalanceSheetFAEnum['Total Equity']) {
      accounts.push({
        id: 'total-liabilities-plus-equity',
        path: ['Total Liabilities + Equity'],
        type: 'total',
        used: true,
      }, {
        id: 'balance-sheet-balance-check',
        path: ['Balance Sheet Balance Check'],
        type: 'informative',
        used: true,
      });
    }
  });

  const accountMappings = accountMappingsData?.accountMappings?.map((am) => ({
    path: am.account?.type && am.account.name
      ? [am.account.type, am.account.name, am.glAccount.id]
      : ['Uncategorized', am.glAccount.id],
    id: am.glAccount?.id,
    type: 'gl_account',
    source: am.glAccount.source,
    glAccountName: am.glAccount.name,
    glAccountDisplayName: am.glAccount.displayName,
    glAccountType: am.glAccount.type,
    financialsData: am.financialsData,
    used: am.glAccount.used,
  }));

  return [...accounts, ...accountMappings];
};

export const prepareAccountMappingTreePayload = (data: any[]): AccountMappingTreeUpdatePayload => {
  const uncategorizedGlAccounts = data.filter(({ path, type }) => type === 'gl_account' && path[0] === 'Uncategorized')
    .map(({ id: glId, glAccountName, source }) => ({
      id: glId,
      name: glAccountName,
      source: source,
    }));

  const uncategorized = uncategorizedGlAccounts.length
    ? [{
        name: null,
        type: null,
        id: null,
        glAccounts: uncategorizedGlAccounts,
      }]
    : [];

  const factaAccounts = data.filter(({ type }) => type === 'facta_account_name')
    .map(({ id: faId, path: faPath, isNew }) => {
      const glAccounts = data.filter(({ type, path: glPath }) => type === 'gl_account' && faPath[0] === glPath[0] && faPath[1] === glPath[1])
        .map(({ id: glId, source, glAccountName }) => ({
          id: glId,
          name: glAccountName,
          source: source,
        }));

      return {
        name: faPath[1],
        type: faPath[0],
        id: faId.includes('new_account') || isNew ? null : faId,
        glAccounts: glAccounts,
      };
    });

  return [...uncategorized, ...factaAccounts];
};

/** Account Mapping common */

const valueGetter = (colId: string) => ({ data, api }: ValueGetterParams) => {
  if (data?.type === 'total' || data?.type === 'informative') {
    let total = 0;

    const addToTotal = (namesArray: Array<ProfitAndLossFAEnum | BalanceSheetFAEnum>, node: IRowNode) => {
      namesArray.forEach((name) => {
        if (node.data?.path[0] === name && node.data?.financialsData) {
          const value = path(`data.${colId}`.split('.'), node) as number | undefined;
          total = Number((total + (value || 0)).toFixed(2));
        }
      });
    };

    const subtractFromTotal = (namesArray: Array<ProfitAndLossFAEnum | BalanceSheetFAEnum>, node: IRowNode) => {
      namesArray.forEach((name) => {
        if (node.data?.path[0] === name && node.data?.financialsData) {
          const value = path(`data.${colId}`.split('.'), node) as number | undefined;
          total = Number((total - (value || 0)).toFixed(2));
        }
      });
    };

    switch (data.id) {
      case 'total-gross-profit':
        api.forEachNode((node) => {
          addToTotal([ProfitAndLossFAEnum['Total Revenue']], node);
          subtractFromTotal([ProfitAndLossFAEnum['Total Cost of Goods Sold']], node);
        });
        return total;

      case 'total-operating-expenses':
        api.forEachNode((node) => {
          addToTotal([
            ProfitAndLossFAEnum['Total Sales & Marketing'],
            ProfitAndLossFAEnum['Total Research & Development'],
            ProfitAndLossFAEnum['Total General & Administrative'],
          ], node);
        });
        return total;

      case 'total-operating-profit':
        api.forEachNode((node) => {
          addToTotal([ProfitAndLossFAEnum['Total Revenue']], node);
          subtractFromTotal([
            ProfitAndLossFAEnum['Total Cost of Goods Sold'],
            ProfitAndLossFAEnum['Total Sales & Marketing'],
            ProfitAndLossFAEnum['Total Research & Development'],
            ProfitAndLossFAEnum['Total General & Administrative'],
          ], node);
        });
        return total;

      case 'total-net-income':
        api.forEachNode((node) => {
          addToTotal([
            ProfitAndLossFAEnum['Total Revenue'],
            ProfitAndLossFAEnum['Total Other Income'],
            ProfitAndLossFAEnum['Adjustments'],
          ], node);
          subtractFromTotal([
            ProfitAndLossFAEnum['Total Cost of Goods Sold'],
            ProfitAndLossFAEnum['Total Sales & Marketing'],
            ProfitAndLossFAEnum['Total Research & Development'],
            ProfitAndLossFAEnum['Total General & Administrative'],
            ProfitAndLossFAEnum['Total Other Expenses'],
          ], node);
        });
        return total;

      case 'total-assets':
        api.forEachNode((node) => {
          addToTotal([
            BalanceSheetFAEnum['Total Cash'],
            BalanceSheetFAEnum['Total Accounts Receivable'],
            BalanceSheetFAEnum['Total Other Current Assets'],
            BalanceSheetFAEnum['Total Fixed Assets'],
            BalanceSheetFAEnum['Total Intangible Assets'],
            BalanceSheetFAEnum['Total Other Assets'],
          ], node);
        });
        return total;

      case 'total-liabilities':
        api.forEachNode((node) => {
          addToTotal([
            BalanceSheetFAEnum['Total Accounts Payable'],
            BalanceSheetFAEnum['Total Other Current Liabilities'],
            BalanceSheetFAEnum['Total Long Term Liabilities'],
          ], node);
        });
        return total;

      case 'total-liabilities-plus-equity':
        api.forEachNode((node) => {
          addToTotal([
            BalanceSheetFAEnum['Total Accounts Payable'],
            BalanceSheetFAEnum['Total Other Current Liabilities'],
            BalanceSheetFAEnum['Total Long Term Liabilities'],
            BalanceSheetFAEnum['Total Equity'],
          ], node);
        });
        return total;

      case 'balance-sheet-balance-check':
        api.forEachNode((node) => {
          addToTotal([
            BalanceSheetFAEnum['Total Cash'],
            BalanceSheetFAEnum['Total Accounts Receivable'],
            BalanceSheetFAEnum['Total Other Current Assets'],
            BalanceSheetFAEnum['Total Fixed Assets'],
            BalanceSheetFAEnum['Total Intangible Assets'],
            BalanceSheetFAEnum['Total Other Assets'],
          ], node);
          subtractFromTotal([
            BalanceSheetFAEnum['Total Accounts Payable'],
            BalanceSheetFAEnum['Total Other Current Liabilities'],
            BalanceSheetFAEnum['Total Long Term Liabilities'],
            BalanceSheetFAEnum['Total Equity'],
          ], node);
        });
        return Math.abs(total) === 0
          ? '✅'
          : `❗ ${currencyFormat(total, { code: undefined, precision: 0 })}`;

      default:
        return 'unknown total';
    }
  }
  const value = path(colId.split('.'), data);
  return value;
};

export const createAccountMappingFinancialsColumns = (accountMappingsData: AccountMappings, viewBy: PeriodType[], currencyCode: string) => {
  const commonProperties: ColDef = {
    editable: false,
    type: 'rightAligned',
    minWidth: 140,
    width: 140,
    aggFunc: 'sum',
    sortable: false,
    valueFormatter: ({ value }) => {
      return typeof(value) === 'number' ? currencyFormat(value, { code: currencyCode, precision: 0 }) : value;
    },
    headerClass: 'ag-right-aligned-header',
  };

  const monthly = accountMappingsData.financialsDataRange.monthly.map((date: string) => {
    const headerName = formatDateStringToMMMYearDisplay(date.replaceAll('_', ' ')
      .trim());
    return {
      ...commonProperties,
      field: `financialsData.monthly.${date}`,
      headerName,
      hide: !viewBy.includes('monthly'),
      valueGetter: valueGetter(`financialsData.monthly.${date}`),
    };
  });

  const quarterly = accountMappingsData.financialsDataRange.quarterly.map((date: string) => {
    const headerName = date.replaceAll('_', ' ')
      .trim();

    return {
      ...commonProperties,
      field: `financialsData.quarterly.${date}`,
      headerName,
      hide: !viewBy.includes('quarterly'),
      valueGetter: valueGetter(`financialsData.quarterly.${date}`),
    };
  });

  const annual = accountMappingsData.financialsDataRange.annual.map((date: string) => {
    const headerName = date.replaceAll('_', ' ')
      .trim();

    return {
      ...commonProperties,
      field: `financialsData.annual.${date}`,
      headerName,
      hide: !viewBy.includes('annual'),
      valueGetter: valueGetter(`financialsData.annual.${date}`),
    };
  });

  return [...monthly, ...quarterly, ...annual];
};
