import {
  CustomerFormFields,
  CustomerUpdatePayload,
} from "interfaces/customers";

export const prepareCustomerPayload = (data: CustomerFormFields): CustomerUpdatePayload => {
  return {
    ...data,
    crmId: data.crmId || null,
    tagIds: data.tags.map((tag) => tag.id),
  };
};
