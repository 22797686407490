import * as React from "react";

const SvgComponent = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="inherit">
      <path d="M6.333 1H1.667C1.3 1 1 1.3 1 1.667v4.666C1 6.7 1.3 7 1.667 7h4.666C6.7 7 7 6.7 7 6.333V1.667C7 1.3 6.7 1 6.333 1Zm0 5.333H1.667V1.667h4.666v4.666Z" />
      <path d="M3 4h-.667v1.667H3V4ZM5.667 2.333H5v3.334h.667V2.333ZM4.333 4.667h-.667v1h.667v-1ZM4.333 3.333h-.667V4h.667v-.667Z" />
    </g>
    <path
      d="M19.764 14.357c.035-.28.062-.56.062-.857 0-.297-.027-.578-.062-.857l1.847-1.444a.442.442 0 0 0 .105-.56l-1.75-3.028a.437.437 0 0 0-.534-.192l-2.179.875a6.392 6.392 0 0 0-1.479-.858l-.332-2.319a.427.427 0 0 0-.429-.367h-3.5a.427.427 0 0 0-.429.367l-.332 2.32a6.724 6.724 0 0 0-1.479.857l-2.179-.875a.495.495 0 0 0-.157-.026.433.433 0 0 0-.376.218L4.81 10.64a.432.432 0 0 0 .105.56l1.846 1.444a6.94 6.94 0 0 0-.061.857c0 .289.026.578.06.857l-1.845 1.444a.441.441 0 0 0-.105.56l1.75 3.028a.437.437 0 0 0 .533.192l2.18-.875c.454.35.944.639 1.478.858l.332 2.319c.027.21.21.367.43.367h3.5a.427.427 0 0 0 .428-.367l.332-2.32a6.726 6.726 0 0 0 1.48-.857l2.178.875a.495.495 0 0 0 .157.027.434.434 0 0 0 .377-.22l1.75-3.027a.441.441 0 0 0-.105-.56l-1.847-1.444Zm-1.732-1.496c.035.271.044.455.044.639a6.5 6.5 0 0 1-.044.639l-.123.989.78.612.944.735-.612 1.059-1.112-.447-.91-.367-.787.595c-.376.28-.735.49-1.094.639l-.927.376-.14.989-.175 1.181H12.65l-.167-1.181-.14-.989-.927-.376a4.966 4.966 0 0 1-1.076-.622l-.797-.612-.927.376-1.111.447-.613-1.06.945-.734.779-.613-.123-.988a7.034 7.034 0 0 1-.043-.648c0-.175.017-.376.043-.639l.123-.989-.779-.612-.945-.735.613-1.059 1.11.447.91.367.788-.595c.377-.28.735-.49 1.094-.639l.928-.376.14-.989.175-1.181h1.216l.166 1.181.14.989.928.376c.376.158.726.359 1.076.622l.796.612.928-.376 1.11-.447.613 1.06-.936.743-.779.613.123.988ZM13.263 10a3.499 3.499 0 0 0-3.5 3.5c0 1.934 1.566 3.5 3.5 3.5s3.5-1.566 3.5-3.5-1.566-3.5-3.5-3.5Zm0 5.25c-.962 0-1.75-.787-1.75-1.75s.788-1.75 1.75-1.75c.963 0 1.75.787 1.75 1.75s-.787 1.75-1.75 1.75Z"
      fill="inherit"
    />
  </svg>
);

export default SvgComponent;
