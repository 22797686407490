import styled from "styled-components";

export const StyledSettingsItem = styled.div`
  margin-bottom: 24px;
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  max-width: 1200px;

  h2 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    margin: 4px 0;
  }

  p {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    margin: 4px 0;
  }
`;

export const Setting = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 40px;
  min-width: 320px;
  margin-left: 20px;
`;

export const SettingsButtons = styled.div`
  display: flex;
  gap: 10px;
`;
