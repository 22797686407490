import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ContractFormFields } from "interfaces/contracts";
import { CustomerFormFields } from "interfaces/customers";
import { ProductFormFields } from "interfaces/products";
import { SubscriptionStateFormFields } from "interfaces/subscriptions";
import { Tag } from "interfaces/tags";

type AllowedForms = ProductFormFields | CustomerFormFields | SubscriptionStateFormFields | ContractFormFields;

interface State {
  predefinedData: {
    [key: string]: string
  };
  formData: AllowedForms | null;
}

const initialState: State = {
  predefinedData: {},
  formData: null,
};

const formStateSlice = createSlice({
  name: 'formState',
  initialState,
  reducers: {
    setPredefinedData: (state, action: PayloadAction<{ [key: string]: string }>) => {
      state.predefinedData = action.payload;
    },
    setFormData: (state, action: PayloadAction<AllowedForms>) => {
      state.formData = action.payload;
    },
    addTagToFormData: (state, action: PayloadAction<Tag>) => {
      state.formData!.tags = [
        ...state.formData!.tags,
        action.payload,
      ];
    },
    updateKeyInFormData: (state, action: PayloadAction<{ key: string, value: any}>) => {
      (state.formData as any)[action.payload.key] = action.payload.value;
    },
    clear: () => initialState,
  },
});

export const {
  reducer: formStateReducer,
  actions: formStateActions,
} = formStateSlice;
