import styled from 'styled-components';
import { theme } from 'theme/theme';

export const StyledRadioWrapper = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  column-gap: 24px;
  align-items: baseline;
  margin-bottom: 24px;
`;

export const StyledRadioInput = styled.input`
  appearance: none;
  margin: 0;
  width: 18px;
  height: 18px;
  background-color: ${theme.colors.white};
  border-radius: 50%;
  outline: 2px solid ${theme.colors.textDisabled};
  display: grid;
  place-content: center;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  &::before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: ${theme.colors.primary};
  }

  &:checked {
    outline: 2px solid ${theme.colors.primary};
    &::before {
      transform: scale(1);
    }
  }
`;

export const StyledLabel = styled.label<{isDisabled?: boolean}>`
  color: ${theme.colors.textDark};
  cursor: ${props => props.isDisabled ? 'not-allowed': 'pointer'};
  line-height: 24px;

  & > p {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    padding-bottom: 6px;
  }
`;
