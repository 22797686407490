import { ColDef } from '@ag-grid-community/core';
import { caseInsensitiveComparator } from "utils/aggrid";
import { multiFilter } from 'utils/filters';

export const getColumnDefs = (): Array<ColDef> => ([
  {
    field: 'lastName',
    headerName: 'Name',
    sortable: true,
    resizable: true,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    flex: 1,
    minWidth: 250,
    valueGetter: ({ data }) => {
      if (!data) return null;

      if (data.firstName && data.lastName) {
        return `${data.firstName || ''} ${data.lastName || ''}`;
      } else {
        return '(Unnamed user)';
      }
    },
    comparator: caseInsensitiveComparator(),
    ...multiFilter,
  },
  {
    field: 'email',
    headerName: 'Email',
    sortable: true,
    resizable: true,
    flex: 1,
    minWidth: 250,
    comparator: caseInsensitiveComparator(),
    ...multiFilter,
  },
  {
    field: 'role',
    headerName: 'Role',
    sortable: true,
    resizable: true,
    minWidth: 120,
    comparator: caseInsensitiveComparator(),
    enableRowGroup: true,
    ...multiFilter,
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    resizable: true,
    minWidth: 120,
    comparator: caseInsensitiveComparator(),
    enableRowGroup: true,
    ...multiFilter,
  },
]);