import React, { useState } from "react";
import { Box } from "..";
import { SectionLabel } from "components/common/SectionLabel";
import { ChevronDown } from "assets/icons";
import { StyledButton } from "./styled";

interface Props {
  initiallyOpen?: boolean;
  title: string;
  subtitle?: string;
  children: any;
  secondary?: boolean;
  tertiary?: boolean;
  fullHeight?: boolean
  style?: any;
  autoFocus?: boolean;
  marginBottom?: number;
}

export const CollapsibleBox = ({
  initiallyOpen,
  title,
  subtitle,
  children,
  secondary,
  tertiary,
  fullHeight,
  style,
  autoFocus,
  marginBottom,
}: Props) => {
  const [isOpen, setIsOpen] = useState(initiallyOpen);

  const handleToggle = () => setIsOpen(!isOpen);

  return (
    <Box
      fullHeight={fullHeight}
      style={style}
      marginBottom={marginBottom || 0}
    >
      <SectionLabel
        secondary={secondary}
        tertiary={tertiary}
        alignTop
        marginBottom={isOpen ? undefined : 0}
      >
        <span>
          {title}
          {isOpen && subtitle && <p>{subtitle}</p>}
        </span>
        <StyledButton
          onClick={handleToggle}
          isOpen={isOpen}
          variant="icon"
          tertiary={tertiary}
          color="secondary"
          autoFocus={autoFocus}
        >
          <ChevronDown />
        </StyledButton>
      </SectionLabel>
      {isOpen && children}
    </Box>
  );
};
