import { useClickOutside } from 'hooks/useClickOutside';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { ANIMATION_DURATION } from 'utils/constants';
import {
  StyledModalWrapper,
  StyledModal,
} from './styled';

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  maxWidth?: number;
  minWidth?: number;
  condensed?: boolean;
  suppressOverflow?: boolean;
  fullScreen?: boolean;
}
export const Modal = ({
  children,
  isOpen,
  onClose,
  maxWidth,
  minWidth,
  condensed,
  suppressOverflow,
  fullScreen,
}: Props) => {
  const [opacity, setOpacity] = useState(0);
  const [visible, setVisible] = useState(false);

  const modalRef = useRef<HTMLDivElement>(null);
  useClickOutside(modalRef, onClose);

  useEffect(() => {
    if (isOpen) {
      setVisible(true);
      setTimeout(() => setOpacity(1), 0);
    } else {
      setOpacity(0);
      setTimeout(() => setVisible(false), ANIMATION_DURATION);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && (event.target as Element).nodeName !== 'INPUT') {
        onClose();
      }
    };

    if (visible) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose, visible]);

  return visible
    ? (
      <StyledModalWrapper opacity={opacity}>
        <StyledModal
          ref={modalRef}
          maxWidth={maxWidth}
          minWidth={minWidth}
          condensed={condensed}
          suppressOverflow={suppressOverflow}
          fullScreen={fullScreen}
        >
          {children}
        </StyledModal>
      </StyledModalWrapper>
    )
    : null;
};
