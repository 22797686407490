import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  ChevronDown,
  CloseIcon,
} from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { SectionLabel } from 'components/common/SectionLabel';
import { Button } from 'components/common/Button';
import { InputBasic } from 'components/common/Input';
import { Dropdown } from 'components/common/Dropdown';
import {
  StyledFormContainer,
  StyledButtonContainer,
  StyledExpandButton,
  StyledAdditionalOptionsContainer,
  StyledDisabledSection,
  StyledFormSectionLabel,
} from 'components/common/Forms/styled';
import {
  useAddContractLineMutation,
  useLazyContractLinesUniquenessQuery,
} from 'store/services/contractLines';
import { useGetAllTagsQuery } from 'store/services/tags';
import { NumberInput } from 'components/common/NumberInput';
import { Datepicker } from 'components/common/Datepicker';
import {
  ContractLineFormFields,
  ContractLineStateFormFields,
} from 'interfaces/contractLines';
import { toastSuccess } from 'utils/toast';
import {
  parseStateFormData,
  parseContractLineFormData,
  prepareContractLinePayload,
} from 'utils/contractLines';
import { useGetAllProductsQuery } from 'store/services/products';
import { AssistiveText } from 'components/common/Input/AssistiveText';
import { useGetContractsSearchQuery } from 'store/services/contracts';
import {
  formatDateStringToDisplay,
  isoToDate,
} from 'utils/dates';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import { actionBlocker } from 'utils/actionBlocker';
import { TagType } from 'interfaces/tags';
import { formDataSelector } from 'store/selectors/formState';
import { formStateActions } from 'store/slices/formState';
import { tagsSortFn } from 'utils/tags';
import { Tooltip } from 'components/common/Tooltip';
import { Contract } from 'interfaces/contracts';
import { CheckboxInput } from 'components/common/CheckboxInput';
import { useUniquenessCheck } from 'hooks/useUniquenessCheck';

interface Props {
  onClose: () => void;
  onSuccess?: (id?: string) => void;
  onAddTag: () => void;
  onAddProduct: () => void;
  contractId?: string;
}

export const AddContractLine = ({
  onClose,
  onSuccess,
  onAddTag,
  onAddProduct,
  contractId,
}: Props) => {
  const dispatch = useAppDispatch();
  const formData = useAppSelector(formDataSelector);
  const [isOptionsExpanded, setIsOptionsExpanded] = useState(false);
  const [selectedContract, setSelectedContract] = useState<Contract | undefined>(undefined);
  const [addContractLine, { isLoading }] = useAddContractLineMutation();
  const [contractLinesUnique, { isFetching: isCheckingUniqueness }] = useLazyContractLinesUniquenessQuery();

  const {
    contracts,
    isLoadingContracts,
  } = useGetContractsSearchQuery({
    filtering: { deleted: false },
    pagination: {
      page: 0,
      perPage: 0,
    },
    simplified: false,
  }, {
    selectFromResult: ({ data, isLoading }) => ({
      contracts: data?.data.filter((contract) => !contract.deleted && !contract.subscriptionBased),
      isLoadingContracts: isLoading,
    }),
  });

  const preSelectedContract = contractId
    ? contracts?.find((con) => con.id === contractId)
    : undefined;

  const {
    products,
  } = useGetAllProductsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      products: data?.filter((product) => !product.deleted),
    }),
  });

  const {
    tagOptions,
  } = useGetAllTagsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      tagOptions: data
        ?.filter((tag) => !tag.deleted && tag.type === TagType.CONTRACT)
        ?.sort(tagsSortFn) || [],
    }),
  });

  const cannotBeBeforeContractStartDateFn = useMemo(() => (val: Date | null | undefined) => {
    if (val && selectedContract?.startDate) {
      return val.getTime() >= isoToDate(selectedContract.startDate)!
        .getTime();
    } else {
      return true;
    }
  }, [selectedContract?.startDate]);

  const cannotBeBeforeContractBookingDateFn = useMemo(() => (val: Date | null | undefined) => {
    if (val && selectedContract?.bookingDate) {
      return val.getTime() >= isoToDate(selectedContract.bookingDate)!
        .getTime();
    } else {
      return true;
    }
  }, [selectedContract?.bookingDate]);

  const schema = yup.object({
    contract: yup.array()
      .of(
        yup.object({
          id: yup.string(),
          name: yup.string(),
        }))
      .min(1, 'Contract is required.'),
    product: yup.array()
      .of(
        yup.object({
          id: yup.string(),
          name: yup.string(),
        }))
      .min(1, 'Product is required.'),
    name: yup.string()
      .required('Contract line name is required.')
      .test(
        'isUnique',
        'Contract line name must be unique.',
        () => isNameUnique,
      ),
    amount: yup.string()
      .required('Amount is required.'),
    bookingDate: yup.date()
      .nullable()
      .when('endDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .max(yup.ref('endDate'),
            'Service booking date cannot be after service end date.'),
      })
      .test(
        'cannotBeBeforeContractBookingDate',
        `Service booking date cannot be before contract's booking date (${formatDateStringToDisplay(selectedContract?.bookingDate)})`,
        cannotBeBeforeContractBookingDateFn,
      )
      .required('Service booking date is required.'),
    startDate: yup.date()
      .nullable()
      .test(
        'cannotBeBeforeContractStartDate',
        `Service start date cannot be before contract's start date (${formatDateStringToDisplay(selectedContract?.startDate)})`,
        cannotBeBeforeContractStartDateFn,
      )
      .required('Start date is required.'),
    endDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'Service end date cannot be before start date.'),
      })
      .test(
        'cannotBeBeforeContractStartDate',
        `Service end date cannot be before contract's start date (${formatDateStringToDisplay(selectedContract?.startDate)})`,
        cannotBeBeforeContractStartDateFn,
      )
      .test(
        'cannotBeAfterContractEndDate',
        `Service end date cannot be after contract's end date (${formatDateStringToDisplay(selectedContract?.endDate!)})`,
        (val) => {
          if (val && selectedContract?.endDate) {
            return val.getTime() <= isoToDate(selectedContract.endDate)!
              .getTime();
          } else {
            return true;
          }
        },
      )
      .required('Service end date is required.'),
    cancelDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'Service cancel date cannot be before start date.'),
      })
      .test(
        'cannotBeBeforeContractStartDate',
        `Service cancel date cannot be before contract's start date (${formatDateStringToDisplay(selectedContract?.startDate)})`,
        cannotBeBeforeContractStartDateFn,
      )
      .test(
        'cannotBeAfterContractCancelDate',
        `Service cancel date cannot be after contract's cancel date (${formatDateStringToDisplay(selectedContract?.cancelDate!)})`,
        (val) => {
          if (val && selectedContract?.cancelDate) {
            return val.getTime() <= isoToDate(selectedContract.cancelDate)!
              .getTime();
          } else {
            return true;
          }
        },
      )
      .test(
        'cancelDateRequiredWhenContractCancelDateProvided',
        'Service cancel date is required for Contracts with provided cancel date',
        (val) => !(!val && !!selectedContract?.cancelDate),
      ),
    revRecStartDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'Revenue recognition start date cannot be before line item start date.'),
      })
      .test(
        'cannotBeBeforeContractStartDate',
        `Revenue recognition start date cannot be before contract's start date (${formatDateStringToDisplay(selectedContract?.startDate)})`,
        cannotBeBeforeContractStartDateFn,
      ),
    revRecEndDate: yup.date()
      .nullable()
      .when('revRecStartDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'Revenue recognition end date cannot be before Revenue recognition start date.'),
      })
      .test(
        'cannotBeBeforeContractStartDate',
        `Revenue recognition end date cannot be before contract's start date (${formatDateStringToDisplay(selectedContract?.startDate)})`,
        cannotBeBeforeContractStartDateFn,
      ),
    crmId: yup.string(),
    externalLink: yup.string()
      .url('Please provide valid URL.'),
    notes: yup.string(),
    contractBasedMRRCalc: yup.bool(),
  })
    .required();

  const defaultValues = {
    ...(formData
      ? {
        ...parseStateFormData(formData as ContractLineStateFormFields),
      }
      : {
        contract: preSelectedContract
          ? [preSelectedContract]
          : [],
        customer: [],
        product: [],
        name: '',
        amount: '0',
        startDate: undefined,
        endDate: undefined,
        cancelDate: undefined,
        bookingDate: undefined,
        revRecStartDate: undefined,
        revRecEndDate: undefined,
        crmId: '',
        externalLink: '',
        notes: '',
        tags: [],
        contractBasedMRRCalc: false,
      }
    ),
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState: { errors, isDirty, dirtyFields },
    clearErrors,
    trigger,
  } = useForm<ContractLineFormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const [isNameUnique, setName] = useUniquenessCheck(contractLinesUnique, { contractID: selectedContract?.id }, 'name', trigger);

  const triggerTouched = (field: any) => ((dirtyFields as Record<string, boolean | undefined>)[field]) && trigger(field);

  const handleSaveAndNew = handleSubmit((data) => {
    const contractLine = prepareContractLinePayload(data);

    return addContractLine(contractLine)
      .unwrap()
      .then((result) => {
        toastSuccess('Contract Line successfully added.');
        onSuccess && onSuccess(result.id);
        reset();
        setTimeout(() => clearErrors(), 0);
      });
  });

  const handleSaveAndClose = handleSubmit((data) => {
    const contractLine = prepareContractLinePayload(data);

    return addContractLine(contractLine)
      .unwrap()
      .then((result) => {
        toastSuccess('Contract Line successfully added.');
        onSuccess && onSuccess(result.id);
        onClose();
      });
  });

  const handleAddTag = ({ name }: { name: string }) => {
    dispatch(formStateActions.setPredefinedData({
      name,
      type: TagType.CONTRACT,
    }));
    dispatch(formStateActions.setFormData(parseContractLineFormData(getValues())));
    onAddTag();
  };

  const handleAddProduct = ({ name }: { name: string }) => {
    if (onAddProduct) {
      dispatch(formStateActions.setPredefinedData({ name }));
      dispatch(formStateActions.setFormData(parseContractLineFormData(getValues())));
      onAddProduct();
    }
  };

  const handleContractSelect = () => {
    const contract = getValues('contract')
      ?.at(0);

    if (!contract) {
      reset(defaultValues);
    } else {
      reset({
        ...defaultValues,
        contract: [contract],
        customer: [contract.customer],
        endDate: defaultValues.endDate || isoToDate(contract.endDate),
        bookingDate: defaultValues.bookingDate || isoToDate(contract.bookingDate),
        startDate: defaultValues.startDate || isoToDate(contract.startDate),
        cancelDate: defaultValues.cancelDate || isoToDate(contract.cancelDate),
        revRecStartDate: undefined,
        revRecEndDate: undefined,
        crmId: defaultValues.crmId || contract.crmId || undefined,
        tags: defaultValues.tags.length ? defaultValues.tags : [],
      });
    }
  };

  useEffect(() => {
    handleContractSelect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContract]);

  useEffect(() => {
    if (preSelectedContract) {
      setSelectedContract(preSelectedContract);
    }
  }, [preSelectedContract]);

  return (
    <>
      <header>
        <ButtonsContainer>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={() => actionBlocker(onClose, isDirty)}
            pushRight
            data-cy="acl-button-close"
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <SectionLabel>
          <span>
            Add Contract Line
          </span>
        </SectionLabel>
      </header>
      <main>
        <StyledFormContainer>
          <Controller
            name="contract"
            control={control}
            render={({ field }) =>
              <Dropdown
                labelText="Contract"
                labelField="name"
                valueField="id"
                searchBy="name"
                placeholder="Select Contract"
                options={contracts || []}
                values={getValues('contract')}
                {...field}
                error={errors.contract?.message}
                tooltip={contractId ? undefined : "Select available contract."}
                clearable
                onChange={(value) => {
                  field.onChange(value);
                  setSelectedContract(value.at(0));
                }}
                dataCy="acl-dropdown-contract"
                isRequired={!contractId}
                disabled={!!contractId}
              />
            }
          />
          <SectionLabel
            secondary
            marginTop={16}
          >
            <span>Contract Line Information</span>
            {(!getValues('contract').length) && (
              <Tooltip title="To enable rest of the fields below, please select a contract first." />
            )}
          </SectionLabel>
          <StyledDisabledSection disabled={!getValues('contract').length || isLoadingContracts}>
            <InputBasic
              isRequired
              labelText="Contract Line Name"
              placeholder="Name your contract line"
              {...register('name')}
              onBlur={(e) => {
                const fieldValue = e.target.value;

                if (fieldValue) {
                  setValue('name', fieldValue.trim(), { shouldValidate: true });
                }
              }}
              onCustomChange={(e) => setName(e.target.value)}
              error={errors.name?.message}
              tooltip="You can change the contract line name, but it must be a unique value."
              isLoading={isCheckingUniqueness}
              data-cy="acl-input-name"
            />
            <Controller
              name="product"
              control={control}
              render={({ field }) =>
                <div>
                  <Dropdown
                    labelText="Product"
                    labelField="name"
                    valueField="id"
                    searchBy="name"
                    placeholder="Select Product SKU"
                    options={products || []}
                    values={getValues('product')}
                    {...field}
                    error={errors.product?.message}
                    isRequired
                    create
                    onCreateNew={handleAddProduct}
                    dataCy="acl-dropdown-product"
                  />
                  <AssistiveText
                    text={getValues('product')
                      .at(0)?.revenueType} />
                </div>
              }
            />
            <Controller
              name="amount"
              control={control}
              render={({ field }) =>
                <NumberInput
                  labelText="Amount"
                  {...field}
                  onChange={field.onChange}
                  placeholder="Enter Amount"
                  error={errors.amount?.message}
                  tooltip="Enter the total value of this product from the Service Start Date to the Service End Date."
                  isRequired
                  data-cy="acl-input-amount"
                />
              }
            />
            <Controller
              name="bookingDate"
              control={control}
              defaultValue={undefined}
              render={({ field }) =>
                <Datepicker
                  labelText="Service Booking Date"
                  placeholder="Select Date"
                  {...field}
                  onChange={(date) => {
                    field.onChange(date);
                    triggerTouched('startDate');
                    triggerTouched('endDate');
                    triggerTouched('cancelDate');
                  }}
                  error={errors.bookingDate?.message}
                  tooltip="Service booking date is used to indicate when this contract line was initially booked."
                  isRequired
                />
              }
            />
            <Controller
              name="startDate"
              control={control}
              defaultValue={undefined}
              render={({ field }) =>
                <Datepicker
                  labelText="Service Start Date"
                  placeholder="Select Date"
                  {...field}
                  onChange={(date) => {
                    field.onChange(date);
                    triggerTouched('bookingDate');
                    triggerTouched('endDate');
                    triggerTouched('cancelDate');
                  }}
                  error={errors.startDate?.message}
                  tooltip="The service start date is used to indicate the beginning of service for this contract line."
                  isRequired
                  data-cy="acl-datepicker-start"
                />
              }
            />
            <Controller
              name="endDate"
              control={control}
              defaultValue={undefined}
              render={({ field }) =>
                <Datepicker
                  labelText="Service End Date"
                  placeholder="Select Date"
                  {...field}
                  onChange={(date) => {
                    field.onChange(date);
                    triggerTouched('startDate');
                    triggerTouched('bookingDate');
                    triggerTouched('cancelDate');
                  }}
                  error={errors.endDate?.message}
                  tooltip="This contract line will remain active until it is renewed or cancelled."
                  isRequired
                  data-cy="acl-datepicker-end"
                />
              }
            />
            <Controller
              name="cancelDate"
              control={control}
              defaultValue={undefined}
              render={({ field }) =>
                <Datepicker
                  labelText="Service Cancel Date"
                  placeholder="Cancel Date"
                  {...field}
                  onChange={(date) => {
                    field.onChange(date);
                    triggerTouched('startDate');
                    triggerTouched('endDate');
                    triggerTouched('bookingDate');
                  }}
                  error={errors.cancelDate?.message}
                  tooltip={`Entering a service cancel date will terminate the contract line upon that date. Your overall contract will still remain active.
                    ${!!selectedContract?.cancelDate ? 'Service cancel date is required because Contract cancel date was provided ' : ''}`}
                  isOptional={!selectedContract?.cancelDate}
                  isRequired={!!selectedContract?.cancelDate}
                  data-cy="acl-datepicker-cancel"
                />
              }
            />
            <StyledFormSectionLabel>
              <span>
                Revenue Recognition
              </span>
            </StyledFormSectionLabel>
            <Controller
              name="revRecStartDate"
              control={control}
              defaultValue={undefined}
              render={({ field }) =>
                <Datepicker
                  labelText="Recognition Start Date"
                  placeholder="Select Date"
                  {...field}
                  onChange={(date) => {
                    field.onChange(date);
                    triggerTouched('contractLineRevRecEndDate');
                  }}
                  error={errors.revRecStartDate?.message}
                  tooltip="Enter revenue recognition start date. You can add it now or later."
                  isOptional
                />
              }
            />
            <Controller
              name="revRecEndDate"
              control={control}
              defaultValue={undefined}
              render={({ field }) =>
                <Datepicker
                  labelText="Recognition End Date"
                  placeholder="Select Date"
                  {...field}
                  onChange={(date) => {
                    field.onChange(date);
                  }}
                  error={errors.revRecEndDate?.message}
                  tooltip="Enter revenue recognition end date. You can add it now or later."
                  isOptional
                />
              }
            />
            <StyledExpandButton
              variant="borderless"
              size="large"
              type="button"
              onClick={() => setIsOptionsExpanded(!isOptionsExpanded)}
              isOpen={isOptionsExpanded}
              data-cy="acl-button-additional"
            >
              Additional Options
              <ChevronDown />
            </StyledExpandButton>
            <StyledAdditionalOptionsContainer isOpen={isOptionsExpanded}>
              <Controller
                name="tags"
                control={control}
                render={({ field }) =>
                  <Dropdown
                    labelText="Tags"
                    labelField="name"
                    valueField="id"
                    searchBy="name"
                    options={tagOptions}
                    placeholder="Select Tags"
                    entityName="tag"
                    values={getValues('tags')}
                    {...field}
                    isOptional
                    clearable
                    multi
                    error={errors.tags?.message}
                    tooltip="Categorize this contract line with a contract tag(s)."
                    create
                    onCreateNew={handleAddTag}
                    dataCy="acl-dropdown-tags"
                  />
                }
              />
              <InputBasic
                isOptional
                labelText="CRM ID"
                placeholder="Paste CRM identifier"
                tooltip="Paste in an ID from your CRM to help identify this contract line."
                {...register('crmId')}
                error={errors.crmId?.message}
                data-cy="acl-input-crm"
              />
              <InputBasic
                isOptional
                labelText="External Link"
                placeholder="Paste an external link"
                tooltip="You can paste in a link to your CRM or wherever you store your contracts."
                {...register('externalLink')}
                error={errors.externalLink?.message}
                onBlur={(e) => {
                  const fieldValue = e.target.value;

                  if (fieldValue && !fieldValue.startsWith('http')) {
                    setValue('externalLink', 'http://' + fieldValue, { shouldValidate: true });
                  }
                }}
                data-cy="acl-input-external"
              />
              <InputBasic
                isOptional
                labelText="Notes"
                placeholder="Add a quick note"
                {...register('notes')}
                error={errors.notes?.message}
                data-cy="acl-input-notes"
                multiline
              />
              <CheckboxInput
                isOptional
                labelText="MRR Start Date"
                {...register('contractBasedMRRCalc')}
                error={errors.contractBasedMRRCalc?.message}
                data-cy="acl-input-mrr-calc"
                tooltip="MRR calculations default to using the Item start and end date. By selecting the toggle to the Contract start and end date, MRR will be calculated using those dates."
                checkboxLabel="Contract Start/End Date"
                defaultValue={false}
              />
            </StyledAdditionalOptionsContainer>
          </StyledDisabledSection>
        </StyledFormContainer>
      </main>
      <footer>
        <StyledButtonContainer pushRight>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={handleSaveAndClose}
            disabled={isLoading || isCheckingUniqueness}
            isLoading={isLoading}
            data-cy="acl-button-save-close"
          >
            SAVE & CLOSE
          </Button>
          <Button
            type="submit"
            onClick={handleSaveAndNew}
            disabled={isLoading || isCheckingUniqueness}
            isLoading={isLoading}
            data-cy="acl-button-save-new"
          >
            SAVE & NEW
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};