import {
  AggregationMethod,
  MetricsWithValues,
  StartDateField,
  UploadManualsRequest,
} from "interfaces/metrics";
import { MetricAggregationMethodOptions } from "./constants";
import { formatDateToISODate } from "./dates";
import { format } from "date-fns";
import { ChartConfig } from "interfaces/charts";
import { format as currencyFormat } from "currency-formatter";

export const metricStartDateOptions = [
  {
    name: StartDateField.SERVICE,
    value: StartDateField.SERVICE,
  },
  {
    name: StartDateField.BOOKING,
    value: StartDateField.BOOKING,
  },
];

export const FormatOptions = [
  {
    value: 'currency',
    label: 'Currency',
  },
  {
    value: 'number',
    label: 'Number',
  },
  {
    value: 'percentage',
    label: 'Percentage',
  },
  {
    value: 'multiple',
    label: 'Multiple',
  },
];

export const mapMetricsWithValuesToTableView = (metrics?: MetricsWithValues) => {
  const mappedMetrics = metrics
    ?.map((metric) => ({
      ...metric,
      aggregationMethod: MetricAggregationMethodOptions.find((am) => am.value === metric.aggregationMethod)?.name,
      tags: metric.tags?.join(', ') || '',
      newlyCreated: false,
      ...metric.entries.reduce((acc, { period, value }) => ({ ...acc, [period]: value }), {}),
    })) || [];

  return mappedMetrics;
};

interface PrepareManualsProps {
  data: any[],
  start: Date,
  end: Date,
}

export const prepareManualsPayload = ({
  data,
  start,
  end,
}: PrepareManualsProps): UploadManualsRequest => {
  const periodStart = format(formatDateToISODate(start), 'yyyy-MM-dd');
  const periodEnd = format(formatDateToISODate(end), 'yyyy-MM-dd');

  const lines = data.filter((line: any) => line.name)
    .map((line: any) => {
      const {
        id,
        name,
        aggregationMethod,
        category,
        description,
        tags,
        origin,
        newlyCreated,
        entries: oldEntries,
        format,
        ...tableItems
      } = line;

      const entries = Object.entries(tableItems)
        .map((line: any) => ({
          date: line[0],
          value: line[1],
        }))
        .filter((line) => line.date >= periodStart && line.date <= periodEnd);

      return {
        id,
        name,
        aggregationMethod: MetricAggregationMethodOptions.find((am) => am.name === aggregationMethod)?.value || AggregationMethod.EOP,
        category,
        description,
        tags: tags?.length ? tags.split(',')
          .map((tag: string) => tag.trim()) : [],
        entries,
        format,
      };
    });

  return lines;
};

export const verifyManualsPayload = (payload: UploadManualsRequest) => payload.every((item) => item.name && item.category);

export const getAggregationMethodName = (aggMethod: AggregationMethod) => MetricAggregationMethodOptions.find((option) => option.value === aggMethod)?.name;

export const getFormattedMetricValue = (value: number, currencyCode: string, chartConfig?: Pick<ChartConfig, 'multiplier' | 'precision' | 'valueFormat'>) => {
  if (!chartConfig) return '';

  const {
    multiplier,
    precision,
    valueFormat,
  } = chartConfig;

  const multipliedValue = multiplier === 'M'
    ? value / 1000000
    : multiplier === 'K'
      ? value / 1000
      : value;

  const metricValue = `${
    valueFormat === 'percentage'
      ? `${(multipliedValue * 100).toFixed(precision)}%`
      : currencyFormat(multipliedValue, {
        code: currencyCode || 'USD',
        precision: precision,
        symbol: valueFormat === 'currency' ? undefined : '',
    })}${multiplier && valueFormat !== 'multiple'
      ? multiplier
      : ''
    }${valueFormat === 'multiple'
      ? 'x'
      : ''
    }`;

  return metricValue;
};
