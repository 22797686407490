export enum SettingsType {
  OPTION,
  DATE,
  MONTH,
  NUMBER,
  NUMBER_WITH_DECIMALS,
  NUMBERFORMAT,
  PERIOD,
  MULTIDROPDOWN,
}

export interface DashboardSettings {
  name: string;
  value: string;
}
