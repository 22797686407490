import React, { useState } from "react";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import { ChevronDown } from "assets/icons";
import {
  StyledDropdownHeader,
  StyledDropdownButton,
  StyledYearSelector,
  StyledYearButtonsContainer,
  StyledYearButton,
} from "./styled";

export const DatepickerHeader = ({
  date,
  changeYear,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps) => {
  const NUMBER_OF_YEARS = 20;
  const [isYearSelectorOpen, setIsYearSelectorOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(date.getFullYear());
  const datepickerYear = date.getFullYear();

  const years = Array.from({ length: NUMBER_OF_YEARS }, (_, i) => (selectedYear - NUMBER_OF_YEARS + 12) + i);

  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December",
  ];

  return (
    <StyledDropdownHeader>
      <StyledDropdownButton
        type="button"
        onClick={() => setIsYearSelectorOpen(true)}
      >
        <span>{`${monthNames[date.getMonth()]} ${date.getFullYear()} `}</span>
        <ChevronDown />
      </StyledDropdownButton>
      <StyledDropdownButton
        type="button"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        iconRotate={90}
        pushRight
      >
        <ChevronDown />
      </StyledDropdownButton>
      <StyledDropdownButton
        type="button"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        iconRotate={270}
      >
        <ChevronDown />
      </StyledDropdownButton>

      <StyledYearSelector isOpen={isYearSelectorOpen}>
        <StyledDropdownHeader>
          <StyledDropdownButton
            type="button"
            onClick={() => setIsYearSelectorOpen(false)}
            iconRotate={180}
          >
            <span>{`${monthNames[date.getMonth()]} ${date.getFullYear()} `}</span>
            <ChevronDown />
          </StyledDropdownButton>
          <StyledDropdownButton
            type="button"
            onClick={() => setSelectedYear(selectedYear - NUMBER_OF_YEARS)}
            iconRotate={90}
            pushRight
          >
            <ChevronDown />
          </StyledDropdownButton>
          <StyledDropdownButton
            type="button"
            onClick={() => setSelectedYear(selectedYear + NUMBER_OF_YEARS)}
            iconRotate={270}
          >
            <ChevronDown />
          </StyledDropdownButton>
        </StyledDropdownHeader>
        <StyledYearButtonsContainer>
          {years.map((year) => (
            <StyledYearButton
              key={year}
              type="button"
              onClick={() => {
                changeYear(year);
                setIsYearSelectorOpen(false);
              }}
              selected={datepickerYear === year}
            >
              {year}
            </StyledYearButton>
          ))}
        </StyledYearButtonsContainer>
      </StyledYearSelector>
    </StyledDropdownHeader>
  );
};
