import * as yup from "yup";
import {
  formatDateStringToDisplay,
  isoToDate,
} from "utils/dates";
import { Contract } from "interfaces/contracts";

export const cannotBeBeforeContractStartDateFn = (selectedContract: Contract) => (val: Date | null | undefined) => {
  if (val && selectedContract?.startDate) {
    return val.getTime() >= isoToDate(selectedContract?.startDate)!
      .getTime();
  } else {
    return true;
  }
};

export const cannotBeBeforeContractBookingDateFn = (selectedContract: Contract) => (val: Date | null | undefined) => {
  if (val && selectedContract?.bookingDate) {
    return val.getTime() >= isoToDate(selectedContract?.bookingDate)!
      .getTime();
  } else {
    return true;
  }
};

export const bulkEditContractLinesSchema = (selectedContract: Contract) => yup.object({
  product: yup.array()
    .of(
      yup.object({
        id: yup.string(),
        name: yup.string(),
      })),
  amount: yup.string(),
  bookingDate: yup.date()
    .nullable()
    .test(
      'cannotBeBeforeContractBookingDate',
      `Service booking date cannot be before contract's booking date (${formatDateStringToDisplay(selectedContract?.bookingDate)})`,
      cannotBeBeforeContractBookingDateFn(selectedContract),
    ),
  startDate: yup.date()
    .nullable()
    .test(
      'cannotBeBeforeContractStartDate',
      `Service start date cannot be before contract's start date (${formatDateStringToDisplay(selectedContract?.startDate)})`,
      cannotBeBeforeContractStartDateFn(selectedContract),
    ),
  endDate: yup.date()
    .nullable()
    .when('startDate', {
      is: (val: Date | null) => val,
      then: yup.date()
        .min(yup.ref('startDate'),
          'Service end date cannot be before start date.'),
    })
    .test(
      'cannotBeBeforeContractStartDate',
      `Service end date cannot be before contract's start date (${formatDateStringToDisplay(selectedContract?.startDate)})`,
      cannotBeBeforeContractStartDateFn(selectedContract),
    )
    .test(
      'cannotBeAfterContractEndDate',
      `Service end date cannot be after contract's end date (${formatDateStringToDisplay(selectedContract?.endDate!)})`,
      (val) => {
        if (val && selectedContract?.endDate) {
          return val.getTime() <= isoToDate(selectedContract.endDate)!
            .getTime();
        } else {
          return true;
        }
      },
    ),
  cancelDate: yup.date()
    .nullable()
    .when('startDate', {
      is: (val: Date | null) => val,
      then: yup.date()
        .min(yup.ref('startDate'),
          'Service cancel date cannot be before start date.'),
    })
    .test(
      'cannotBeBeforeContractStartDate',
      `Service cancel date cannot be before contract's start date (${formatDateStringToDisplay(selectedContract?.startDate)})`,
      cannotBeBeforeContractStartDateFn(selectedContract),
    )
    .test(
      'cannotBeAfterContractCancelDate',
      `Service cancel date cannot be after contract's cancel date (${formatDateStringToDisplay(selectedContract?.cancelDate!)})`,
      (val) => {
        if (val && selectedContract?.cancelDate) {
          return val.getTime() <= isoToDate(selectedContract.cancelDate)!
            .getTime();
        } else {
          return true;
        }
      },
    ),
  revRecStartDate: yup.date()
    .nullable()
    .when('startDate', {
      is: (val: Date | null) => val,
      then: yup.date()
        .min(yup.ref('startDate'),
          'Revenue recognition start date cannot be before line item start date.'),
    })
    .test(
      'cannotBeBeforeContractStartDate',
      `Revenue recognition start date cannot be before contract's start date (${formatDateStringToDisplay(selectedContract?.startDate)})`,
      cannotBeBeforeContractStartDateFn(selectedContract),
    ),
  revRecEndDate: yup.date()
    .nullable()
    .when('revRecStartDate', {
      is: (val: Date | null) => val,
      then: yup.date()
        .min(yup.ref('startDate'),
          'Revenue recognition end date cannot be before Revenue recognition start date.'),
    })
    .test(
      'cannotBeBeforeContractStartDate',
      `Revenue recognition end date cannot be before contract's start date (${formatDateStringToDisplay(selectedContract?.startDate)})`,
      cannotBeBeforeContractStartDateFn(selectedContract),
    ),
  crmId: yup.string(),
  externalLink: yup.string()
    .url('Please provide valid URL.'),
  notes: yup.string(),
})
  .required();
