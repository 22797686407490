import { ColDef } from '@ag-grid-community/core';
import { caseInsensitiveComparator } from "utils/aggrid";
import { Tag } from "interfaces/tags";

export const getColumnDefs = (tagsOptions: string[]): Array<ColDef> => ([
  {
    field: 'id',
    headerName: 'Id',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    cellRenderer: () => null,
    maxWidth: 70,
    pinned: true,
  },
  {
    field: 'name',
    headerName: 'Customer Name',
    sortable: true,
    resizable: true,
    flex: 1,
    minWidth: 250,
    comparator: caseInsensitiveComparator(),
    // Note: Can't use ...multiFilter here
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      maxNumConditions: 1,
      buttons: ['reset'],
    },
  },
  {
    field: 'tags',
    headerName: 'Tags',
    sortable: true,
    resizable: true,
    flex: 1,
    minWidth: 250,
    getQuickFilterText: ({ value }) => value?.map((tag: Tag) => tag.name),
    // Note: Can't use ...multiFilter here
    filter: true,
    valueGetter: ({ data }) => data.tags.map((tag: Tag) => tag.name) || [],
    filterValueGetter: ({ data }) => data.tags.map((tag: Tag) => tag.name) || [],
    filterParams: {
      values: tagsOptions,
      buttons: ['reset'],
    },
  },
  {
    field: 'crmId',
    headerName: 'CRM ID',
    sortable: true,
    resizable: true,
    flex: 1,
    minWidth: 250,
    valueFormatter: ({ value }) => value ? value : '-',
    // Note: Can't use ...multiFilter here
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      maxNumConditions: 1,
      buttons: ['reset'],
    },
  },
]);