import * as React from "react";

const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <path
      fill="inherit"
      d="m7 16.793 1.956-9.92c.178-.912.54-1.619 1.086-2.12.557-.502 1.287-.753 2.19-.753.367 0 .713.051 1.036.154.334.091.607.228.819.41l-.669 1.215c-.312-.285-.707-.428-1.186-.428-.48 0-.864.137-1.154.41-.278.274-.473.69-.585 1.25l-.25 1.18-.084.683-1.554 7.92H7ZM7 9.08l.25-1.352h5.667l-.268 1.352H7ZM11.444 20l2.357-3.14-.02.687-2.247-3.027h1.394l1.575 2.155h-.532l1.585-2.155h1.354l-2.267 3.027.01-.687L17 20h-1.414l-1.655-2.288.522.071L12.828 20h-1.384Z"
    />
  </svg>
);

export default SvgComponent;
