import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ResizableBox,
  ResizeCallbackData,
  ResizeHandle,
} from 'react-resizable';
import {
  RESPONSIVE_LAYOUT_MIN_PX,
  RESPONSIVE_LAYOUT_MAX_PERCENT,
  ANIMATION_DURATION,
} from 'utils/constants';
import {
  CollapseHandle,
  Handle,
  RightPaneBody,
  RightPaneContainer,
} from '../styled';
import { Button } from 'components/common/Button';
import {
  CollapseIcon,
  ExpandIcon,
} from 'assets/icons';

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  fixed?: boolean;
  collapsible?: boolean;
}

export const RightPane = ({ children, isOpen, fixed, collapsible }: Props) => {
  const documentsWidth = window.innerWidth;
  const defaultWidth = fixed
    ? RESPONSIVE_LAYOUT_MIN_PX
    : localStorage.getItem('defaultSize') || RESPONSIVE_LAYOUT_MIN_PX;
  const [isResizing, setIsResizing] = useState(false);
  const [isReadyToBeShown, setIsReadyToBeShown] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const resizeRef = useRef<ResizableBox>(null);

  const setDefaultSize = (size: number) => localStorage.setItem('defaultSize', `${size}`);

  const handleResizeStart = () => {
    setIsResizing(true);
  };

  const handleResizeStop = (e: React.SyntheticEvent<Element, Event>, data: ResizeCallbackData) => {
    if (!fixed) {
      setDefaultSize(data.size.width);
    }
    setIsResizing(false);
  };

  const handleDoubleClick = () => {
    const udpatedWidth = RESPONSIVE_LAYOUT_MIN_PX;
    setDefaultSize(udpatedWidth);
    resizeRef.current?.setState({
      width: udpatedWidth,
      height: 100,
    });
    setCollapsed(false);
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setIsReadyToBeShown(true);
      }, ANIMATION_DURATION);
    } else {
      setIsReadyToBeShown(false);
    }

    return () => setIsReadyToBeShown(false);
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) return;

    resizeRef.current?.setState({
      width: collapsed ? 40 : Number(defaultWidth),
      height: 100,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed, isOpen]);

  return (
    <RightPaneContainer
      ref={resizeRef}
      width={isOpen ? Number(defaultWidth) : 0}
      height={100}
      axis="x"
      handle={(h: ResizeHandle, ref: React.RefObject<HTMLDivElement>) => <Handle
        ref={ref}
        onDoubleClick={handleDoubleClick}
        className={isResizing ? 'is-resizing' : ''}
        collapsed={collapsed}
      >
        {collapsible && (
          <CollapseHandle collapsed={collapsed}>
            <Button onClick={() => setCollapsed(!collapsed)}>
              {collapsed
                ? <CollapseIcon />
                : <ExpandIcon />
              }
            </Button>
          </CollapseHandle>
        )}
      </Handle>}
      resizeHandles={['w']}
      minConstraints={[RESPONSIVE_LAYOUT_MIN_PX, 0]}
      maxConstraints={fixed ? [RESPONSIVE_LAYOUT_MIN_PX, 0] : [documentsWidth * RESPONSIVE_LAYOUT_MAX_PERCENT, 0]}
      onResizeStop={handleResizeStop}
      onResizeStart={handleResizeStart}
      $isResizing={isResizing}
      onResize={() => setCollapsed(false)}
    >
      <RightPaneBody collapsed={collapsed}>
        {isReadyToBeShown && children}
      </RightPaneBody>
    </RightPaneContainer>
  );
};
