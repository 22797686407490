import React, { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
} from 'react-router';
import { Home } from './Home';
import { PageNotFound } from './PageNotFound';
import { Customers } from './Customers';
import { Products } from './Products';
import { Layout } from 'components/Layout';
import { Dashboards } from './Dashboards';
import { Dashboard } from './Dashboards/Dashboard';
import { Settings } from './Settings';
import { Subscriptions } from './Subscriptions';
import { BulkImportUpload } from 'components/BulkImport/Upload';
import { BulkImportReview } from './Subscriptions/BulkImport/Review';
import { useMarkerIO } from 'hooks/useMarkerIO';
import { Tags } from './Tags';
import { AddCompany } from './AddCompany';
import { useGetAuthMeQuery } from 'store/services/auth';
import { BulkImportCleanData } from 'components/BulkImport/CleanData';
import { Loading } from 'components/Loading';
import { StyledLoadingWrapper } from 'components/Layout/styled';
import { GeneralSettings } from './Settings/GeneralSettings';
import { UserProfile } from './Settings/UserProfile';
import { CompanyUsers } from './Settings/Users';
import { Contracts } from './Contracts';
import { ContractOverview } from './Contracts/ContractOverview';
import { UserRole } from 'interfaces/auth';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { ContractLines } from './Contracts/ContractLines';
import { CompanyRevenueType } from 'interfaces/company';
import { RenewContractPage } from './Contracts/RenewContractPage';
import { BulkImportContractsReviewContracts } from './Contracts/BulkImport/ReviewContracts';
import { BulkImportContractsReview } from './Contracts/BulkImport/ReviewContractLines';
import { getCurrentCompany } from 'utils/currentCompany';
import { Companies } from './Companies';
import { useAgGridEnterpriseLicense } from 'hooks/useAgGridEnterpriseLicense';
import { Integrations } from './Integrations';
import { IntegrationsFinancialsUploader } from './Accounts/Upload/FinancialsUploader';
import { AccountMappingTreeView } from './Accounts/AccountMapping/TreeView';
import { AccountMappingTableView } from './Accounts/AccountMapping/TableView';
import { ReportConfiguration } from './Reports/ReportConfiguration';
import { Reports } from './Reports';
import { Metrics } from './Metrics';
import { MetricConfiguration } from './Metrics/MetricConfiguration';
import { UnpivotTool } from './Integrations/Unpivot';
import { PendingCompany } from './PendingCompany';
import { RevenueDetails } from './Dashboards/RevenueDetails';
import { Cohorts } from './Dashboards/Cohorts';
import { SalesCommissions } from './Dashboards/SalesCommissions';
import { featureSwitch } from 'utils/featureSwitch';
import { ManagementCommissions } from './Dashboards/ManagementCommissions';
import { ManualMetrics } from './Metrics/ManualMetrics';
import { SDRCommissions } from './Dashboards/SDRCommissions';
import { ComparisonChart } from './Dashboards/ComparisonChart';
import { PageUnauthorized } from './PageUnauthorized';
import {
  setContext,
  setUser,
} from '@sentry/react';
import { Sync } from './Sync';

export const AppRouter = () => {
  useAgGridEnterpriseLicense();
  const [setCustomMarkerData] = useMarkerIO(import.meta.env.VITE_APP_MARKERIO_DESTINATION!);
  const { data: user, isLoading } = useGetAuthMeQuery();
  const currentCompany = getCurrentCompany(user);
  const isCompanyActive = currentCompany?.isCompanyActive;
  const managementPath = currentCompany?.companyRevenueType === CompanyRevenueType.SUBSCRIPTIONS
    ? 'revenue-management'
    : 'contract-management';

  interface InternalRoutes {
    path: string;
    element: React.ReactElement;
    hidden?: boolean;
    redirect?: string;
    allowedRoles?: UserRole[];
    nestedRoutes?: InternalRoutes[];
  }

  useEffect(() => {
    if (user) {
      setUser({
        id: user.id,
        email: user.email,
        username: `${user.firstName} ${user.lastName}`,
        ip_address: '{{auto}}',
      });
      setContext('Company', currentCompany || {});
    }
  }, [currentCompany, user]);

  const routes: InternalRoutes[] = [
    {
      path: '/',
      element: !currentCompany
        ? !user?.companies.length
          ? <Navigate
            to="/add-company"
            replace
          />
          : <Navigate
            to="/settings/companies"
            replace
          />
        : !isCompanyActive
          ? <PendingCompany />
          : currentCompany.role === UserRole.VIEWER
            ? <Navigate
              to="/reports"
              replace
            />
            : <Home companyRevenueType={currentCompany?.companyRevenueType} />,
      hidden: false,
    },
    {
      path: '/sync',
      element: <Sync />,
    },
    {
      path: '/add-company',
      element: <AddCompany />,
    },
    {
      path: '/dashboards',
      element: <Navigate
        to="/dashboards/revenue-detail"
        replace
      />,
    },
    {
      path: '/dashboards/financial-dashboards',
      element: <Dashboards />,
    },
    {
      path: '/dashboards/financial-dashboards/:dashboardName',
      element: <Dashboard />,
    },
    {
      path: '/dashboards/financial-reports',
      element: <Dashboards />,
    },
    {
      path: '/dashboards/financial-reports/sales-commissions',
      element: <Navigate
        to="/dashboards/sales-commissions"
        replace
      />,
    },
    {
      path: '/dashboards/financial-reports/:dashboardName',
      element: <Dashboard />,
    },
    {
      path: '/dashboards/saas',
      element: <Dashboards />,
    },
    {
      path: '/dashboards/saas/:dashboardName',
      element: <Dashboard />,
    },
    {
      path: '/dashboards/revenue-detail',
      element: <RevenueDetails companyRevenueType={currentCompany?.companyRevenueType} />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.OWNER, UserRole.ADMIN, UserRole.MEMBER],
    },
    {
      path: 'dashboards/cohorts',
      element: <Cohorts companyRevenueType={currentCompany?.companyRevenueType} />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.OWNER, UserRole.ADMIN, UserRole.MEMBER],
    },
    {
      path: '/dashboards/sales-commissions',
      element: <SalesCommissions />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.OWNER, UserRole.ADMIN, UserRole.MEMBER, UserRole.VIEWER],
      hidden: featureSwitch.isLocalDevelopment ? false : currentCompany?.id !== 'd14bef13-1b5b-4360-b962-0b646e6dd8a1',
    },
    {
      path: '/dashboards/management-commissions',
      element: <ManagementCommissions />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.OWNER, UserRole.ADMIN, UserRole.MEMBER, UserRole.VIEWER],
      hidden: (featureSwitch.isLocalDevelopment ? false : currentCompany?.id !== 'd14bef13-1b5b-4360-b962-0b646e6dd8a1') || currentCompany?.role === UserRole.VIEWER,
    },
    {
      path: '/dashboards/sdr-commissions',
      element: <SDRCommissions />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.OWNER, UserRole.ADMIN, UserRole.MEMBER, UserRole.VIEWER],
      hidden: (featureSwitch.isLocalDevelopment ? false : currentCompany?.id !== 'd14bef13-1b5b-4360-b962-0b646e6dd8a1') || currentCompany?.role === UserRole.VIEWER,
    },
    {
      path: '/dashboards/comparison-chart',
      element: <ComparisonChart />,
    },
    {
      path: '/data-input',
      element: <Navigate
        to="/data-input/integrations"
        replace
      />,
    },
    {
      path: `/data-input/${managementPath}`,
      element: <Navigate
        to={currentCompany?.companyRevenueType === CompanyRevenueType.SUBSCRIPTIONS
          ? `/data-input/revenue-management/revenue/all`
          : `/data-input/${managementPath}/contracts`}
        replace
      />,
    },
    {
      path: `/data-input/revenue-management/revenue/import`,
      element: currentCompany?.companyRevenueType === CompanyRevenueType.SUBSCRIPTIONS
        ? <BulkImportUpload companyRevenueType={currentCompany?.companyRevenueType} />
        : <Navigate to={`/data-input/${managementPath}`} />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.OWNER, UserRole.ADMIN, UserRole.MEMBER],
    },
    {
      path: `/data-input/revenue-management/revenue/import/clean-data`,
      element: currentCompany?.companyRevenueType === CompanyRevenueType.SUBSCRIPTIONS
        ? <BulkImportCleanData companyRevenueType={currentCompany?.companyRevenueType} />
        : <Navigate to={`/data-input/${managementPath}`} />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.OWNER, UserRole.ADMIN, UserRole.MEMBER],
    },
    {
      path: `/data-input/revenue-management/revenue/import/review`,
      element: currentCompany?.companyRevenueType === CompanyRevenueType.SUBSCRIPTIONS
        ? <BulkImportReview />
        : <Navigate to={`/data-input/${managementPath}`} />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.OWNER, UserRole.ADMIN, UserRole.MEMBER],
    },
    {
      path: `/data-input/revenue-management/revenue/:revenueType`,
      element: currentCompany?.companyRevenueType === CompanyRevenueType.SUBSCRIPTIONS
        ? <Subscriptions setCustomMarkerData={setCustomMarkerData} />
        : <Navigate to={`/data-input/${managementPath}`} />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.OWNER, UserRole.ADMIN, UserRole.MEMBER],
    },
    {
      path: `/data-input/contract-management/contracts`,
      element: currentCompany?.companyRevenueType === CompanyRevenueType.CONTRACTS
        ? <Contracts setCustomMarkerData={setCustomMarkerData} />
        : <Navigate to={`/data-input/${managementPath}`} />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.OWNER, UserRole.ADMIN, UserRole.MEMBER],
    },
    {
      path: `/data-input/contract-management/contracts/contract-lines`,
      element: currentCompany?.companyRevenueType === CompanyRevenueType.CONTRACTS
        ? <ContractLines setCustomMarkerData={setCustomMarkerData} />
        : <Navigate to={`/data-input/${managementPath}`} />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.OWNER, UserRole.ADMIN, UserRole.MEMBER],
    },
    {
      path: `/data-input/contract-management/contracts/:contractId`,
      element: currentCompany?.companyRevenueType === CompanyRevenueType.CONTRACTS
        ? <ContractOverview
          setCustomMarkerData={setCustomMarkerData}
          currentCompany={currentCompany!}
        />
        : <Navigate to={`/data-input/${managementPath}`} />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.OWNER, UserRole.ADMIN, UserRole.MEMBER],
    },
    {
      path: `/data-input/contract-management/contracts/import`,
      element: currentCompany?.companyRevenueType === CompanyRevenueType.CONTRACTS
        ? <BulkImportUpload companyRevenueType={currentCompany?.companyRevenueType} />
        : <Navigate to={`/data-input/${managementPath}`} />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.OWNER, UserRole.ADMIN, UserRole.MEMBER],
    },
    {
      path: `/data-input/contract-management/contracts/import/clean-data`,
      element: currentCompany?.companyRevenueType === CompanyRevenueType.CONTRACTS
        ? <BulkImportCleanData companyRevenueType={currentCompany?.companyRevenueType} />
        : <Navigate to={`/data-input/${managementPath}`} />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.OWNER, UserRole.ADMIN, UserRole.MEMBER],
    },
    {
      path: `/data-input/contract-management/contracts/import/review-contracts`,
      element: currentCompany?.companyRevenueType === CompanyRevenueType.CONTRACTS
        ? <BulkImportContractsReviewContracts />
        : <Navigate to={`/data-input/${managementPath}`} />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.OWNER, UserRole.ADMIN, UserRole.MEMBER],
    },
    {
      path: `/data-input/contract-management/contracts/import/review`,
      element: currentCompany?.companyRevenueType === CompanyRevenueType.CONTRACTS
        ? <BulkImportContractsReview />
        : <Navigate to={`/data-input/${managementPath}`} />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.OWNER, UserRole.ADMIN, UserRole.MEMBER],
    },
    {
      path: `/data-input/contract-management/contracts/renew`,
      element: currentCompany?.companyRevenueType === CompanyRevenueType.CONTRACTS
        ? <RenewContractPage />
        : <Navigate to={`/data-input/${managementPath}`} />,
    },
    {
      path: `/data-input/${managementPath}/customers`,
      element: <Customers
        setCustomMarkerData={setCustomMarkerData}
        companyRevenueType={currentCompany?.companyRevenueType}
      />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.OWNER, UserRole.ADMIN, UserRole.MEMBER],
    },
    {
      path: `/data-input/${managementPath}/products`,
      element: <Products
        setCustomMarkerData={setCustomMarkerData}
        companyRevenueType={currentCompany?.companyRevenueType}
      />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.OWNER, UserRole.ADMIN, UserRole.MEMBER],
    },
    {
      path: `/data-input/${managementPath}/tags`,
      element: <Tags
        setCustomMarkerData={setCustomMarkerData}
        companyRevenueType={currentCompany?.companyRevenueType}
      />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.OWNER, UserRole.ADMIN, UserRole.MEMBER],
    },
    {
      path: '/settings',
      element: <Settings currentCompany={currentCompany!} />,
      nestedRoutes: [
        {
          path: 'profile',
          element: <UserProfile />,
        },
        {
          path: 'general',
          element: <GeneralSettings />,
        },
        {
          path: 'companies',
          element: <Navigate
            to="/settings/companies/all"
            replace
          />,
        },
        {
          path: 'companies/:companyStatus',
          element: <Companies />,
          allowedRoles: [UserRole.SUPERUSER, UserRole.ADMIN, UserRole.OWNER, UserRole.MEMBER],
        },
        {
          path: 'users',
          element: <CompanyUsers currentCompany={currentCompany!} />,
          allowedRoles: [UserRole.SUPERUSER, UserRole.ADMIN, UserRole.OWNER],
          redirect: '/settings/profile',
        },
      ],
    },
    {
      path: '/data-input/contract-management/*',
      element: <Navigate
        to={`/data-input/${managementPath}`}
        replace
      />,
    },
    {
      path: '/data-input/revenue-management/*',
      element: <Navigate
        to={`/data-input/${managementPath}`}
        replace
      />,
    },
    {
      path: '/data-input/integrations',
      element: <Integrations />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.ADMIN, UserRole.OWNER, UserRole.MEMBER],
    },
    {
      path: '/data-input/qbo-callback',
      element: <Integrations callback="qbo" />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.ADMIN, UserRole.OWNER, UserRole.MEMBER],
    },
    {
      path: '/accounts',
      element: <Navigate
        to="/accounts/account-mapping/profit_and_loss"
        replace
      />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.ADMIN, UserRole.OWNER, UserRole.MEMBER],
    },
    {
      path: '/accounts/upload',
      element: <Navigate
        to="/accounts/upload/profit_and_loss"
        replace
      />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.ADMIN, UserRole.OWNER, UserRole.MEMBER],
    },
    {
      path: '/accounts/upload/:financialType',
      element: <IntegrationsFinancialsUploader currentCompany={currentCompany!} />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.ADMIN, UserRole.OWNER, UserRole.MEMBER],
    },
    {
      path: '/accounts/account-mapping',
      element: <Navigate
        to="/accounts/account-mapping/profit_and_loss"
        replace
      />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.ADMIN, UserRole.OWNER, UserRole.MEMBER],
    },
    {
      path: '/accounts/account-mapping/:financialType',
      element: <AccountMappingTreeView currentCompany={currentCompany!} />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.ADMIN, UserRole.OWNER, UserRole.MEMBER],
    },
    {
      path: '/accounts/account-mapping/table-view/:financialType',
      element: <AccountMappingTableView currentCompany={currentCompany!} />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.ADMIN, UserRole.OWNER, UserRole.MEMBER],
    },
    {
      path: '/data-input/unpivot',
      element: currentCompany?.companyRevenueType === CompanyRevenueType.SUBSCRIPTIONS
        ? <UnpivotTool />
        : <Navigate to="/data-input" />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.ADMIN, UserRole.OWNER, UserRole.MEMBER],
    },
    {
      path: '/reports',
      element: <Reports currentCompany={currentCompany!} />,
    },
    {
      path: '/reports/:reportId',
      element: <ReportConfiguration currentCompany={currentCompany!} />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.ADMIN, UserRole.OWNER, UserRole.MEMBER],
    },
    {
      path: '/reports/:reportId/preview',
      element: <Reports currentCompany={currentCompany!} />,
    },
    {
      path: '/metrics',
      element: <Navigate
        to="/metrics/metrics/all"
        replace
      />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.ADMIN, UserRole.OWNER, UserRole.MEMBER],
    },
    {
      path: '/metrics/metrics',
      element: <Navigate
        to="/metrics/metrics/all"
        replace
      />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.ADMIN, UserRole.OWNER, UserRole.MEMBER],
    },
    {
      path: '/metrics/metrics/:category',
      element: <Metrics />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.ADMIN, UserRole.OWNER, UserRole.MEMBER],
    },
    {
      path: '/metrics/manual',
      element: <ManualMetrics />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.ADMIN, UserRole.OWNER, UserRole.MEMBER],
    },
    {
      path: '/metrics/:metricId',
      element: <MetricConfiguration />,
      allowedRoles: [UserRole.SUPERUSER, UserRole.ADMIN, UserRole.OWNER, UserRole.MEMBER],
    },
    {
      path: '/unauthorized',
      element: <PageUnauthorized />,
    },
    {
      path: '*',
      element: <PageNotFound />,
    },
  ];

  return (
    <Layout omitHeaderAndSidebar={!currentCompany || !isCompanyActive}>
      {isLoading
        ? (
          <StyledLoadingWrapper>
            <Loading />
          </StyledLoadingWrapper>
        )
        : (
          <Routes>
            {routes
              .filter(({ hidden }) => !hidden)
              .map(({ path, element, nestedRoutes, allowedRoles, redirect }) => (
                <Route
                  key={path}
                  element={
                    <ProtectedRoute
                      allowedRoles={allowedRoles}
                      redirectPath={redirect}
                      currentCompany={currentCompany!}
                    />}
                >
                  <Route
                    path={path}
                    element={element}
                  >
                    {nestedRoutes && (
                      <Route
                        index
                        element={nestedRoutes.at(0)!.element}
                      />
                    )}
                    {nestedRoutes && nestedRoutes.map((nestedRoute) => (
                      <Route
                        path={nestedRoute.path}
                        key={nestedRoute.path}
                        element={
                          <ProtectedRoute
                            allowedRoles={nestedRoute.allowedRoles}
                            redirectPath={nestedRoute.redirect}
                            currentCompany={currentCompany!}
                          >
                            {nestedRoute.element}
                          </ProtectedRoute>
                        }
                      />
                    ))}
                  </Route>
                </Route>
              ))}
          </Routes>
        )}
    </Layout>
  );
};
