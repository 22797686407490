import styled from "styled-components";
import { theme } from "theme/theme";

export const StyledDropdownMainText = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: ${theme.colors.textDark};
  font-weight: 500;
  margin: 4px 0;

  em {
    font-weight: 600;
    color: ${theme.colors.primary};
    cursor: pointer;
  }
`;

export const StyledDropdownHelperText = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: ${theme.colors.textDark};
  margin: 4px 0;
`;

export const StyledWrapper = styled.section`
  display: flex;
  justify-content: center;
  
  & > div {
    border: 1px solid ${theme.colors.borderColorLight};
    border-radius: 8px;
    margin-bottom: 40px;
    max-width: 1000px;
    flex-grow: 1;
  }

  ${StyledDropdownMainText}, ${StyledDropdownHelperText} {
    padding: 16px;
  }
`;

interface StyledDropzoneContainerProps {
  isDragActive?: boolean;
  isDragReject?: boolean;
}

export const StyledDropzoneContainer = styled.div<StyledDropzoneContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 35vh;
  min-height: 200px;
  border: 3px dashed ${props => props.isDragActive ? theme.colors.primary : theme.colors.primary20};
  background-color: ${props => props.isDragReject ? theme.colors.errorBleach : theme.colors.primaryBleach};
`;

export const StyledFile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-top: 1px solid ${theme.colors.borderColorLight};
  border-bottom: 1px solid ${theme.colors.borderColorLight};

  & > div {
    display: flex;
    flex-direction: column;

    & span:first-of-type {
      color: ${theme.colors.primary};
      font-size: 16px;
      line-height: 24px;
    }

    & span:nth-of-type(2n) {
      color: ${theme.colors.textDark};
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

export const LoadingBarContainer = styled.div`
  padding: 16px;
`;

export const ErrorContainer = styled.div`
  margin-top: 10px;
  display: flex;
  padding: 16px;
  align-items: flex-start;
  color: ${theme.colors.error};
  
  svg {
    margin-right: 8px;
  }

  > div {
    display: inline;
    line-height: 24px;
  }

  span {
    margin-left: 4px;

    &:last-of-type {
      margin-right: 4px;
    }

    &:not(:last-of-type):after {
      content: ', ';
    }
  }
`;
