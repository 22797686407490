import * as yup from "yup";

export const bulkEditSubscriptionsSchema = yup.object({
  id: yup.string(),
  customer: yup.array()
    .of(
      yup.object({
        id: yup.string(),
        name: yup.string(),
      })),
  product: yup.array()
    .of(
      yup.object({
        id: yup.string(),
        name: yup.string(),
      })),
  name: yup.string(),
  amount: yup.string(),
  startDate: yup.date()
    .nullable()
    .when('bookingDate', {
      is: (val: Date | null) => val,
      then: yup.date()
        .min(yup.ref('bookingDate'),
          'Start date cannot be before booking date.'),
    }),
  endDate: yup.date()
    .nullable()
    .when('startDate', {
      is: (val: Date | null) => val,
      then: yup.date()
        .min(yup.ref('startDate'),
          'End date cannot be before start date.'),
    })
    .when('bookingDate', {
      is: (val: Date | null) => val,
      then: yup.date()
        .min(yup.ref('bookingDate'),
          'End date cannot be before booking date.'),
    }),
  bookingDate: yup.date(),
  cancelDate: yup.date()
    .nullable()
    .when('startDate', {
      is: (val: Date | null) => val,
      then: yup.date()
        .min(yup.ref('startDate'),
          'Cancel date cannot be before start date.'),
    }),
  revRecStartDate: yup.date()
    .nullable()
    .when('startDate', {
      is: (val: Date | null) => val,
      then: yup.date()
        .min(yup.ref('startDate'),
          'Revenue recognition start date cannot be before start date.'),
    }),
  revRecEndDate: yup.date()
    .nullable()
    .when('revRecStartDate', {
      is: (val: Date | null) => val,
      then: yup.date()
        .min(yup.ref('revRecStartDate'),
          'Revenue recognition end date cannot be before Revenue recognition start date.')
        .required('When Revenue recognition start date provided this field is required.'),
    }),
  crmId: yup.string(),
  externalLink: yup.string(),
  notes: yup.string(),
})
  .required();
