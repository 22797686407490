export const theme = {
  colors: {
    white: '#FFFFFF',
    black: '#0B0B0F',
    transparent: 'transparent',

    textDark: '#414158',
    textLight: '#F0F0F4',
    textDisabled: '#8989A9', // placeholders, disabled

    primary: '#5533FF', // violet
    primaryBleach: '#EAE5FF',
    primary20: '#D4CCFF',
    primary40: '#7F66FF',
    primaryStrong: '#2A00FF',
    secondary: '#07AAF8', // skyBlue
    tertiary: '#601E7B', // orchid
    tertiaryBleach: '#ECD6F5',

    success: '#17824D',
    successBleach: '#D4F7E6',
    error: '#DA072A',
    errorBleach: '#FDCED6',
    warning: '#F9D006',
    warningBleach: '#FEF6CD',

    mainBackground: '#F9F9FB',
    secondaryBackground: '#F0F0F4',
    darkBackground: '#2B2B3B',

    borderColorLight: '#F0F0F4',
    borderColorDark: '#E2E2E9',
    fieldBorder: '#A7A7BE',
    sidebarBorder: '#565676',

    bleached: {
      primary: '#EAE5FF',
      secondary: '#07AAF8',
      tertiary: '#952FBF',
      success: '#D4F7E6',
      error: '#FDCED6',
      transparent: 'transparent',
    },
  },
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
  boxShadowHighlight: '0px 0px 20px -6px rgba(127, 102, 255, 1)',
  boxShadowModal: '0px 7px 8px 0px rgba(0, 0, 0, 0.20), 0px 5px 22px 0px rgba(0, 0, 0, 0.12), 0px 12px 17px 0px rgba(0, 0, 0, 0.14)',
  chartColors: [
    "#6378eb",
    "#64c24e",
    "#a244b9",
    "#4b922c",
    "#a267e3",
    "#bfb736",
    "#4655ca",
    "#97b54b",
    "#6354b5",
    "#e59b33",
    "#578ae6",
    "#e85936",
    "#48b1da",
    "#b5391d",
    "#50c27e",
    "#d93b8e",
    "#368c4e",
    "#e66dd2",
    "#557d37",
    "#b23f99",
    "#59ccb3",
    "#db3750",
    "#3ea28f",
    "#cb416d",
    "#24765a",
    "#c288e1",
    "#81872d",
    "#7b51a7",
    "#aa7b22",
    "#4668a7",
    "#d77236",
    "#919fdf",
    "#56611d",
    "#8972b6",
    "#86b375",
    "#894f8f",
    "#367042",
    "#d987bd",
    "#806420",
    "#a24f7e",
    "#b2b06f",
    "#b14140",
    "#7e7641",
    "#e38197",
    "#cda662",
    "#9c4c5d",
    "#e4966f",
    "#a05233",
    "#e1726a",
    "#976237",
  ],
};
