import React, {
  useRef,
  useState,
} from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { SectionLabel } from 'components/common/SectionLabel';
import { GridOptions } from '@ag-grid-community/core';
import {
  LeftPane,
  RightPane,
} from 'components/Layout';
import { Button } from 'components/common/Button';
import { useRightPanes } from 'hooks/useRightPanes';
import { AgGridEmptyState } from 'components/common/AgGridEmptyState';
import { useGetAllCompanyUsersQuery } from 'store/services/users';
import { getColumnDefs } from './columnDefinitions';
import { FactaTable } from 'components/FactaTable';
import { EditUsersRoles } from './EditUsersRoles';
import {
  Company,
  CompanyUser,
} from 'interfaces/company';
import { highlightNodeById } from 'utils/aggrid';
import { DeleteUsers } from './DeleteUsers';
import { InviteUser } from './InviteUser';
import { UserRoleOptions } from 'utils/constants';
import { useGetAuthMeQuery } from 'store/services/auth';

interface Props {
  currentCompany: Company;
}

export const CompanyUsers = ({ currentCompany }: Props) => {
  const gridRef = useRef<AgGridReact>(null);
  const columnDefs = getColumnDefs();
  const [selectedRows, setSelectedRows] = useState<CompanyUser[]>([]);
  const { rightPanes, setSelectedPane, resetPanes } = useRightPanes({
    add: false,
    edit: true,
    delete: false,
  });
  const { data: user } = useGetAuthMeQuery();
  const { role: permissionLevel } = currentCompany;
  const myPermissionLevelIndex = UserRoleOptions.findIndex((role) => role.value === permissionLevel);

  const gridOptions: GridOptions = {
    isRowSelectable: (node) => {
      const nodePermissionLevelIndex = UserRoleOptions.findIndex((role) => role.value === node.data.role);
      return nodePermissionLevelIndex >= myPermissionLevelIndex && user?.email !== node.data.email;
    },
    onSelectionChanged: (e) => {
      const selectedRows = e.api.getSelectedRows();
      setSelectedRows(selectedRows);
      if (selectedRows.length) {
        resetPanes();
      }
    },
  };

  const handleInviteUser = () => {
    setSelectedPane('add');
  };

  const handleTriggerDelete = () => {
    setSelectedPane('delete');
  };

  const {
    data: users,
    isLoading,
    isFetching,
  } = useGetAllCompanyUsersQuery();

  const NoRowsOverlay = () => (
    <AgGridEmptyState>
      You don’t have any users invited yet. Invite users to get started.
      <Button
        variant="contained"
        size="normal"
        onClick={handleInviteUser}
      >
        INVITE USERS
      </Button>
    </AgGridEmptyState>
  );

  return (
    <>
      <LeftPane contentWidth={1200}>
        <SectionLabel>
          Users
        </SectionLabel>
        <FactaTable
          gridRef={gridRef}
          data={users}
          useNativeFilters
          columnDefs={columnDefs}
          isLoading={isLoading || isFetching}
          isDisabled={rightPanes.add}
          onClickAdd={handleInviteUser}
          selectedRowsLength={selectedRows.length}
          entityName="User"
          gridOptions={gridOptions}
          customEmptyState={NoRowsOverlay}
          customAddLabel="Invite User"
        />
      </LeftPane>
      <RightPane isOpen={rightPanes.edit && selectedRows.length > 0}>
        <EditUsersRoles
          selectedRows={selectedRows}
          onClose={() => gridRef.current?.api.deselectAll()}
          onDelete={handleTriggerDelete}
          onSuccess={(id) => highlightNodeById(id, gridRef)}
          permissionLevel={currentCompany.role}
        />
      </RightPane>
      <RightPane isOpen={rightPanes.delete && selectedRows.length > 0}>
        <DeleteUsers
          selectedRows={selectedRows}
          onClose={() => {
            resetPanes();
            gridRef.current?.api.deselectAll();
          }}
        />
      </RightPane>
      <RightPane isOpen={rightPanes.add}>
        <InviteUser onClose={() => resetPanes()} />
      </RightPane>
    </>
  );
};
