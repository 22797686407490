import styled, { css } from "styled-components";
import { theme } from "theme/theme";
import { ChevronDown } from "assets/icons";

export const StyledSteps = styled.div`
  display: flex;
  margin-bottom: 60px;
`;

export const StyledStepNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  min-width: 32px;
  color: ${theme.colors.white};
  border-radius: 50%;
  background-color: ${theme.colors.textDisabled};
  margin-right: 24px;
`;

export const StyledChevron = styled(ChevronDown)`
  transform: rotate(270deg);
  pointer-events: none;
  cursor: none;
  margin: 0 40px 0;
`;

export const StyledStep = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  font-weight: 600;
  cursor: none;
  pointer-events: none;
  color: ${(props) => props.isActive ? theme.colors.primary : theme.colors.textDisabled};
  
  ${props => props.isActive && css`
      cursor: pointer;
      pointer-events: all;
      ${StyledStepNumber} {
        background-color: ${theme.colors.primary};
      };
    `};

  & > p {
    color: inherit;
  }

  &:first-of-type {
    svg {
      display: none;
    }
  }
`;

export const StyledContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 60px;
`;

export const StyledHeading = styled.h3`
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
`;

export const StyledSubHeading = styled.p`
  margin-top: 8px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 60px;
`;

export const StyledComponentWrapper = styled.div`
  max-width: 660px;
`;
