import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { GridOptions } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { SectionLabel } from 'components/common/SectionLabel';
import {
  DetailBox,
  DetailBoxEntries,
  DetailBoxEntry,
} from 'components/DetailBox';
import { DetailBoxContainer } from 'components/DetailBox/styled';
import { FactaTable } from 'components/FactaTable';
import {
  LeftPane,
  RightPane,
} from 'components/Layout';
import { format } from 'currency-formatter';
import { useRightPanes } from 'hooks/useRightPanes';
import {
  ContractLineUpdatePayload,
  ContractRenewalLine,
} from 'interfaces/contractLines';
import { AddTag } from 'pages/Tags/AddTag';
import {
  highlightNodeById,
  reselectNodeById,
} from 'utils/aggrid';
import { formatDateStringToDisplay } from 'utils/dates';
import { getColumnDefs } from './columnDefinitions';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import {
  predefinedContractSelector,
  previousContractsSelector,
  renewalLinesSelector,
} from 'store/selectors/contracts';
import { RenewContractSidePanel } from './RenewSidePanel';
import {
  Contract,
  ContractsMergePayload,
} from 'interfaces/contracts';
import { AddRenewContract } from './AddRenewContract';
import { contractsActions } from 'store/slices/contracts';
import { useMergeContractsMutation } from 'store/services/contracts';
import { RenewContractLines } from './RenewContractLine';
import { AddRenewContractLine } from './AddRenewContractLine';
import { toastSuccess } from 'utils/toast';
import { useGetAuthMeQuery } from 'store/services/auth';
import { getCurrentCompany } from 'utils/currentCompany';

export const RenewContractPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const gridRef = useRef<AgGridReact>(null);
  const [selectedRows, setSelectedRows] = useState<ContractRenewalLine[]>([]);
  const [editedRow, setEditedRow] = useState<ContractRenewalLine[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(2);
  const { rightPanes, setSelectedPane, resetPanes } = useRightPanes({
    summary: true,
    add: false,
    edit: false,
    editContract: false,
    addTagFromEditContract: false,
    addTagFromAddContractLine: false,
    addTagFromEditContractLine: false,
  });
  const { data: user } = useGetAuthMeQuery();
  const currencyCode = getCurrentCompany(user)?.currency.code || 'USD';

  const previousContracts = useAppSelector(previousContractsSelector);
  const renewalLines = useAppSelector(renewalLinesSelector);
  const contract = useAppSelector(predefinedContractSelector);
  const [mergeContracts, { isLoading }] = useMergeContractsMutation();

  const setEditedLine = (id: string) => {
    setEditedRow(renewalLines.filter((row) => row.id === id));
    setSelectedPane('edit');
  };

  const columnDefs = getColumnDefs({ setEditedLine });

  const gridOptions: GridOptions = {
    onSelectionChanged: (e) => {
      const selectedRows = e.api.getSelectedRows();
      setSelectedRows(selectedRows);
    },
    suppressRowClickSelection: true,
    onCellClicked: (e) => {
      if (e.column.getColId() !== 'edit') {
        e.node.setSelected(true);
      }
    },
  };

  const handleAddContractLine = () => {
    setSelectedPane('add');
  };

  const handleAddTagGoBack = () => {
    if (rightPanes.addTagFromEditContract) {
      setSelectedPane('editContract');
    } else {
      setSelectedPane(rightPanes.addTagFromAddContractLine ? 'add' : 'edit');
    }
  };

  const handleEditContract = () => {
    setSelectedPane('editContract');
  };

  const handleCreateRenewContract = () => {
    if (currentStep === 2) {
      setCurrentStep(3);
    }
  };

  const handleRenewContract = () => {
    const linesPayload: ContractLineUpdatePayload[] = selectedRows.map(({
      previousAmount,
      tags,
      id,
      customer,
      product,
      amount,
      bookingDate,
      cancelDate,
      startDate,
      endDate,
      revRecStartDate,
      revRecEndDate,
      contract,
      externalLink,
      crmId,
      notes,
      ...rest
    }) => ({
      ...rest,
      customerId: customer?.id || null,
      productId: product?.id || null,
      tagIds: tags?.map((tag) => tag.id) || [],
      amount: Number(amount),
      startDate: startDate!,
      endDate: endDate!,
      bookingDate: bookingDate ?? startDate!,
      cancelDate: cancelDate ? cancelDate : null,
      revRecStartDate: revRecStartDate ? revRecStartDate : null,
      revRecEndDate: revRecEndDate ? revRecEndDate : null,
      contractId: '',
      externalLink: externalLink ? externalLink : null,
      crmId: crmId ? crmId : null,
      notes: notes ? notes : null,
    }));

    const contractPayload: ContractsMergePayload = {
      name: contract.name || '',
      customerId: contract?.customer?.id || null,
      startDate: contract.startDate!,
      endDate: contract.endDate!,
      bookingDate: contract.bookingDate!,
      cancelDate: contract.cancelDate ? contract.cancelDate : null,
      crmId: contract.crmId || null,
      tagIds: contract?.tags?.map((tag) => tag.id) || [],
      externalLink: contract.externalLink || null,
      notes: contract.notes || null,
      contractsIds: previousContracts.map((prevContract) => prevContract.id),
      contractsLines: linesPayload,
    };

    mergeContracts(contractPayload)
      .unwrap()
      .then((result) => {
        toastSuccess('Contract succesfully renewed.');
        dispatch(contractsActions.clear());
        navigate(`/data-input/contract-management/contracts/${result.id}`);
      });
  };

  const handleCancel = () => {
    dispatch(contractsActions.clear());
    navigate('/data-input/contract-management/contracts/');
  };

  const isAddTagPaneOpen = rightPanes.addTagFromAddContractLine
    || rightPanes.addTagFromEditContractLine
    || rightPanes.addTagFromEditContract;

  useEffect(() => {
    if (currentStep === 2 && contract) {
      handleEditContract();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const progressSteps = useMemo(() => [
    {
      step: 2,
      action: handleEditContract,
      disabled: false,
      instruction: 'Please fill in all the renewal contract information.',
    },
    {
      step: 3,
      action: handleRenewContract,
      disabled: selectedRows.length < 1 || isLoading,
      instruction: 'Select the items you would like to renew from the previous contract or add new. If you don’t select an item, it will be marked as canceled as of the renewal date.',
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [currentStep, selectedRows]);

  const currentProgressInfo = useMemo(() => progressSteps.filter((step) => step.step === currentStep)[0], [currentStep, progressSteps]);

  useEffect(() => {
    const transformLines = (contractLines: ContractRenewalLine[]) =>
      contractLines.map((line) => ({
        ...line,
        bookingDate: contract.bookingDate || '',
        startDate: contract.startDate,
        endDate: contract.endDate,
        cancelDate: contract.cancelDate,
      }));

    dispatch(contractsActions.setContractRenewalLines(transformLines(renewalLines)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const newContractTCV = useMemo(() => selectedRows.reduce((acc, row) => acc + Number(row.amount), 0), [selectedRows, renewalLines]);
  const contractsDifference = useMemo(() => newContractTCV - previousContracts.reduce((acc, row) => acc + row.tcv, 0), [previousContracts, newContractTCV]);

  return (
    <>
      <LeftPane>
        <ButtonsContainer paddingBottom={20}>
          <Breadcrumbs>
            <Breadcrumb link="/data-input/contract-management/contracts">Contracts</Breadcrumb>
          </Breadcrumbs>
        </ButtonsContainer>
        <SectionLabel condensed>
          <span>
            {contract.name || 'Unnamed Contract'}
            <p>{contract.customer?.name || ''}</p>
          </span>
        </SectionLabel>
        <DetailBoxContainer>
          <DetailBox
            title="Contract Overview"
            linkTitle="Edit"
            linkAction={handleEditContract}
            flex
            isFocused={currentStep === 2}
          >
            <DetailBoxEntry
              title="Customer"
              value={contract.customer?.name || ''}
              deleted={false}
            />
            <DetailBoxEntry
              title="Booking Date"
              value={contract.bookingDate ? formatDateStringToDisplay(contract.bookingDate) : '-'}
            />
            <DetailBoxEntry
              title="Start Date"
              value={contract.startDate ? formatDateStringToDisplay(contract.startDate) : '-'}
            />
            <DetailBoxEntry
              title="End Date"
              value={contract.endDate ? formatDateStringToDisplay(contract.endDate) : '-'}
            />
            <DetailBoxEntry
              title="Cancel Date"
              value={contract.cancelDate ? formatDateStringToDisplay(contract.cancelDate) : '-'}
            />
          </DetailBox>
        </DetailBoxContainer>
        <DetailBoxContainer>
          <DetailBox
            title={previousContracts.length > 1 ? 'Previous Contracts' : 'Previous Contract'}
            flex
          >
            <DetailBoxEntries
              title="Contract"
              value={previousContracts.map((prevContract) => prevContract.name)}
            />
            <DetailBoxEntries
              title="Previous TCV"
              value={previousContracts.map((prevContract) => format(prevContract.tcv, { code: currencyCode }))}
            />
            <DetailBoxEntries
              title="Difference"
              value={[format(contractsDifference, { code: currencyCode })]}
            />
            <DetailBoxEntries
              title="New TCV"
              value={[format(newContractTCV, { code: currencyCode })]}
            />
          </DetailBox>
        </DetailBoxContainer>
        <FactaTable
          gridRef={gridRef}
          data={renewalLines}
          useNativeFilters
          columnDefs={columnDefs}
          isDisabled={rightPanes.add || currentStep !== 3}
          isFocused={currentStep >= 3}
          onClickAdd={handleAddContractLine}
          selectedRowsLength={selectedRows.length}
          entityName="Renewed Item"
          gridOptions={gridOptions}
        />
      </LeftPane>
      <RightPane isOpen={rightPanes.summary && !rightPanes.edit && !rightPanes.add}>
        <RenewContractSidePanel
          handleCancel={handleCancel}
          handleContinue={currentProgressInfo.action}
          isContinueDisabled={currentProgressInfo.disabled}
          instruction={currentProgressInfo.instruction}
        />
      </RightPane>
      <RightPane isOpen={rightPanes.editContract}>
        <AddRenewContract
          onClose={resetPanes}
          onSave={handleCreateRenewContract}
          selectedRows={[contract as Contract]}
          onAddTag={() => setSelectedPane('addTagFromEditContract')}
        />
      </RightPane>
      <RightPane isOpen={rightPanes.add}>
        <AddRenewContractLine
          onAddTag={() => setSelectedPane('addTagFromAddContractLine')}
          onClose={resetPanes}
          onSuccess={(id) => {
            highlightNodeById(id, gridRef);
            reselectNodeById(id!, gridRef);
          }}
        />
      </RightPane>
      <RightPane isOpen={rightPanes.edit && !isAddTagPaneOpen}>
        {editedRow && (
          <RenewContractLines
            selectedRows={editedRow}
            onClose={() => resetPanes()}
            onSuccess={(id) => {
              reselectNodeById(id, gridRef);
              highlightNodeById(id, gridRef);
            }}
            onAddTag={() => setSelectedPane('addTagFromEditContractLine')}
          />
        )}
      </RightPane>
      <RightPane isOpen={isAddTagPaneOpen}>
        <AddTag
          onClose={() => resetPanes()}
          onGoBack={handleAddTagGoBack}
          isIndirectOpen
        />
      </RightPane>
    </>
  );
};