import * as React from "react";

const SvgComponent = (props: any) => (
  <svg
    width={30}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_508_2422)">
      <path
        d="M12.0065 32.3064L15 34L30 25.5V8.50001L27.0065 6.8064C21.9957 9.65038 16.9848 12.4624 12.0065 15.3064V22.1128C16.0087 19.844 20.0109 17.5752 23.9805 15.3064V22.1128C19.9783 24.3816 15.9762 26.6504 11.974 28.9192L12.0065 32.3064Z"
        fill="url(#paint0_linear_508_2422)"
      />
      <path
        d="M5.98699 11.9192C10.9978 9.07519 15.9761 6.26316 20.987 3.41917L15 0L0 8.5V25.5L5.98699 28.8872C5.98699 23.2312 5.98699 17.5752 5.98699 11.9192Z"
        fill="url(#paint1_linear_508_2422)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_508_2422"
        x1="11.974"
        y1="34"
        x2="30"
        y2="34"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9730C2"/>
        <stop
          offset="1"
          stopColor="#11ADF8"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_508_2422"
        x1="0"
        y1="28.8872"
        x2="20.987"
        y2="28.8872"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9730C2"/>
        <stop
          offset="1"
          stopColor="#11ADF8"
        />
      </linearGradient>
      <clipPath id="clip0_508_2422">
        <rect
          width="30"
          height="34"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
