import React, { useState } from 'react';
import * as yup from "yup";
import { SectionLabel } from 'components/common/SectionLabel';
import { LeftPane } from 'components/Layout';
import {
  StyledInitials,
  StyledContactInfo,
} from './styled';
import { Button } from 'components/common/Button';
import { EditIcon } from 'assets/icons';
import {
  useGetAuthMeQuery,
  usePutAuthMeMutation,
} from 'store/services/auth';
import { SettingsButtons } from '../common/SettingsItem/styled';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { User } from 'interfaces/auth';
import { InputBasic } from 'components/common/Input';
import { toastSuccess } from 'utils/toast';
import {
  StyledProperty,
  StyledSetting,
  StyledForm,
} from '../common/styled';

export const UserProfile = () => {
  const { data: user } = useGetAuthMeQuery();
  const [editedProperty, setEditedProperty] = useState('');
  const [updateMe, { isLoading: isUpdating }] = usePutAuthMeMutation();

  const nameInitials = `${user?.firstName?.at(0)
    ?.toUpperCase() || '?'}${user?.lastName?.at(0)
      ?.toUpperCase() || '?'}`;

  const schema = yup.object({
    firstName: yup.string()
      .required('First name is required.'),
    lastName: yup.string()
      .required('Last name is required.'),
  })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<User>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: user,
  });

  const handleSave = handleSubmit((formData) => {
    updateMe({
      firstName: formData.firstName,
      lastName: formData.lastName,
    })
      .unwrap()
      .then(() => {
        toastSuccess('User details successfully updated.');
        setEditedProperty('');
      });
  });

  const renderEditButtons = (property: string, disabled?: boolean) => {
    const isCurrentlyEdited = editedProperty === property;

    return (
      <>
        {isCurrentlyEdited ? (
          <SettingsButtons>
            <Button
              variant="borderless"
              size="large"
              onClick={handleSave}
              disabled={isUpdating}
              isLoading={isUpdating}
            >
              Save
            </Button>
            <Button
              variant="borderless"
              size="large"
              color="secondary"
              onClick={() => setEditedProperty('')}
              disabled={isUpdating}
            >
              Cancel
            </Button>
          </SettingsButtons>
        ) : (
          <Button
            variant="icon"
            color="primary"
            onClick={() => setEditedProperty(property)}
            disabled={disabled}
          >
            <EditIcon />
          </Button>
        )}
      </>
    );
  };

  return (
    <LeftPane contentWidth={1200}>
      <SectionLabel>Your Profile</SectionLabel>
      <StyledInitials>
        {nameInitials}
      </StyledInitials>
      <StyledContactInfo>
        <h2>{(user?.firstName || user?.lastName)
          ? `${user?.firstName} ${user?.lastName}`
          : 'Unknown'}
        </h2>
        <p>{user?.email}</p>
      </StyledContactInfo>
      <StyledForm>
        <StyledSetting>
          <StyledProperty>
            <h4>First name</h4>
            {editedProperty !== 'firstName' && (<p>{user?.firstName}</p>)}
            <InputBasic
              labelText=""
              placeholder="First Name"
              {...register('firstName')}
              error={errors.firstName?.message}
              isHidden={editedProperty !== 'firstName'}
              condensed
            />
          </StyledProperty>
          {renderEditButtons('firstName')}
        </StyledSetting>
        <StyledSetting>
          <StyledProperty>
            <h4>Last name</h4>
            {editedProperty !== 'lastName' && (<p>{user?.lastName}</p>)}
            <InputBasic
              labelText=""
              placeholder="Last Name"
              {...register('lastName')}
              error={errors.lastName?.message}
              isHidden={editedProperty !== 'lastName'}
              condensed
            />
          </StyledProperty>
          {renderEditButtons('lastName')}
        </StyledSetting>
        <StyledSetting>
          <StyledProperty>
            <h4>Email</h4>
            <p>{user?.email}</p>
          </StyledProperty>
        </StyledSetting>
      </StyledForm>
    </LeftPane>
  );
};
