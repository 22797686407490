import { api } from "./api";
import { DashboardSettings } from "interfaces/settings";

interface Dashboard {
  title: string;
  path: string;
  group: 'financial-dashboards' | 'financial-reports' | 'saas';
}

type Dashboards = Dashboard[];

export const dashboardsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDashboards: builder.query<Dashboards, void>({
      query: () => ({
        url: 'dashboards',
      }),
    }),
    getDashboardSignedUrl: builder.query<{ url: string }, string>({
      query: (dashboardPath) => ({
        url: `dashboards/${dashboardPath}/signed-url`,
      }),
    }),
    patchDashboards: builder.mutation<void, { dashboardPath: string, settings: DashboardSettings }>({
      query: ({ dashboardPath, settings }) => {
        return {
          url: `dashboards/${dashboardPath}/settings`,
          method: 'PATCH',
          body: settings,
        };
      },
    }),
  }),
});

export const {
  useGetDashboardsQuery,
  useGetDashboardSignedUrlQuery,
  usePatchDashboardsMutation,
} = dashboardsApi;
