import React, {
  useEffect,
  useState,
} from 'react';
import { useNavigate } from "react-router-dom";
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { CloseIcon } from 'assets/icons';
import { Button } from 'components/common/Button';
import { StyledButtonContainer } from 'components/common/Forms/styled';
import { SectionLabel } from 'components/common/SectionLabel';
import {
  StyledCheckboxContainer,
  StyledList,
  StyledListItem,
  StyledListText,
  StyledSectionLabel,
} from './styled';
import { Checkbox } from 'components/common/Checkbox';
import { handleDownloadTemplate } from 'utils/bulkImport';
import { CompanyRevenueType } from 'interfaces/company';

interface Props {
  onClose: () => void;
  name: string;
  targetPath: string;
}

export const BulkImportPanel = ({
  onClose,
  name,
  targetPath,
}: Props) => {
  const navigate = useNavigate();
  const [hideInstructions, setHideInstructions] = useState(localStorage.getItem('hideInstructions') === 'true');

  const handleToggleInstructions = () => setHideInstructions(!hideInstructions);
  const handleContinue = () => navigate(targetPath, { replace: true });

  useEffect(() => {
    localStorage.setItem('hideInstructions', hideInstructions ? 'true' : 'false');
  }, [hideInstructions]);

  return (
    <>
      <header>
        <ButtonsContainer>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={onClose}
            pushRight
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <StyledSectionLabel>
          <span>Bulk Import</span>
          <p>Follow the instructions below to upload multiple {name} into Insights.</p>
        </StyledSectionLabel>
      </header>
      <main>
        <SectionLabel secondary>Instructions</SectionLabel>
        <StyledList type="1">
          <StyledListItem>
            <StyledListText>Download the {name} import template.</StyledListText>
            {name === 'contracts' && (
              <Button
                variant="simple"
                color="primary"
                size="large"
                onClick={() => handleDownloadTemplate('standard', CompanyRevenueType.CONTRACTS)}
              >
                Import Template
              </Button>
            )}
            {name === 'revenues' && (
              <>
                <Button
                  variant="simple"
                  color="primary"
                  size="large"
                  onClick={() => handleDownloadTemplate('standard', CompanyRevenueType.SUBSCRIPTIONS)}
                >
                  Flat Data Import Template
                </Button>
                <Button
                  variant="simple"
                  color="primary"
                  size="large"
                  onClick={() => handleDownloadTemplate('unpivot', CompanyRevenueType.SUBSCRIPTIONS)}
                >
                  Customer Table Import Template
                </Button>
              </>
            )}
          </StyledListItem>
          <StyledListItem>
            <StyledListText>Populate the template with the required data.</StyledListText>
          </StyledListItem>
          <StyledListItem>
            <StyledListText>Save the file and upload it into the upload field.</StyledListText>
          </StyledListItem>
          <StyledListItem>
            <StyledListText>Review the imported data and make any changes. </StyledListText>
          </StyledListItem>
          <StyledListItem>
            <StyledListText>Complete the import by submitting the data.</StyledListText>
          </StyledListItem>
        </StyledList>
      </main>
      <footer>
        <StyledCheckboxContainer>
          <Checkbox
            name="instructions"
            checked={hideInstructions}
            onChange={handleToggleInstructions}
          >
            Do not show me these instructions again.
          </Checkbox>
        </StyledCheckboxContainer>
        <StyledButtonContainer pushRight>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            CANCEL
          </Button>
          <Button
            type="submit"
            onClick={handleContinue}
          >
            CONTINUE
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};
