export interface BulkImportField<T extends any, R extends any> {
  providedValue: T | null;
  matchedValue?: R | null;
  errorCode: any | null;
}

export interface FileDetails {
  name: string | null;
  size: number | null;
  lastModified: string | null;
  uploadedAt: string | null;
}

export enum BulkImportEntityType {
  CUSTOMERS = 'customers',
  PRODUCTS = 'products',
  TAGS = 'tags',
}
