import styled from "styled-components";
import { theme } from "theme/theme";

interface Props {
  text?: string;
  error?: boolean;
};

const StyledAssistiveText = styled.p<{ error?: boolean }>`
  padding-top: 8px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: ${props => props.error ? theme.colors.error : theme.colors.textDark};
`;

export const AssistiveText = ({ text, error }: Props) => {
  return text
    ? (
        <StyledAssistiveText error={error}>
          {text}
        </StyledAssistiveText>
      )
    : null;
};
