import {
  CellClassParams,
  ColDef,
} from "@ag-grid-community/core";
import { Link } from "react-router-dom";
import { multiFilter } from "utils/filters";

const LinkCellRenderer = (item: any, handleChangeCompany: (companyId: string) => void) => {
  if (!item.data) return null;
  return (
    <Link
      to="#"
      onClick={() => handleChangeCompany(item.data.id)}
    >
      <span>{item.data.name}</span>
    </Link>
  );
};

interface Props {
  handleChangeCompany: (companyId: string) => void
}

export const getColumnDefs = ({
  handleChangeCompany,
}: Props): Array<ColDef> => ([
  {
    field: 'id',
    headerName: 'Id',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    cellRenderer: () => null,
    maxWidth: 70,
    cellClassRules: {
      'cell-red': ({ value }: CellClassParams) => {
        return !!value;
      },
    },
  },
  {
    field: 'name',
    headerName: 'Company',
    sortable: true,
    flex: 2,
    ...multiFilter,
    cellRenderer: (item: any) => LinkCellRenderer(item, handleChangeCompany),
    cellClass: ({ data }) => !data?.isCompanyActive ? 'cell-error' : '',
    tooltipValueGetter: ({ data }) => !data?.isCompanyActive ? 'Pending acceptation...' : '',
    minWidth: 250,
  },
  {
    field: 'currency.name',
    headerName: 'Currency',
    sortable: true,
    flex: 1,
    enableRowGroup: true,
    ...multiFilter,
    minWidth: 170,
  },
  {
    field: 'companyStatus',
    headerName: 'Status',
    sortable: true,
    flex: 1,
    enableRowGroup: true,
    ...multiFilter,
    minWidth: 150,
    hide: true,
  },
  {
    field: 'industry',
    headerName: 'Industry',
    sortable: true,
    flex: 1,
    enableRowGroup: true,
    ...multiFilter,
    minWidth: 170,
  },
  {
    field: 'group',
    headerName: 'Group',
    sortable: true,
    flex: 1,
    enableRowGroup: true,
    ...multiFilter,
    minWidth: 150,
  },
  {
    field: 'businessType',
    headerName: 'Business Type',
    sortable: true,
    flex: 1,
    enableRowGroup: true,
    ...multiFilter,
    minWidth: 210,
  },
  {
    field: 'businessStage',
    headerName: 'Business Stage',
    sortable: true,
    flex: 1,
    enableRowGroup: true,
    ...multiFilter,
    minWidth: 220,
  },
  {
    field: 'tag',
    headerName: 'Tag',
    sortable: true,
    flex: 1,
    enableRowGroup: true,
    ...multiFilter,
    minWidth: 150,
  },
  {
    field: 'companyRevenueType',
    headerName: 'Revenue Type',
    sortable: true,
    flex: 1,
    enableRowGroup: true,
    ...multiFilter,
    minWidth: 190,
  },
  {
    field: 'role',
    headerName: 'Your Role',
    sortable: true,
    flex: 1,
    enableRowGroup: true,
    ...multiFilter,
    minWidth: 170,
  },
]);