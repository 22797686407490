import * as React from "react";

const SvgComponent = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="inherit"
      d="M3 15h18v-2H3v2Zm0 4h18v-2H3v2Zm0-8h18V9H3v2Zm0-6v2h18V5H3Z"
    />
  </svg>
);
export default SvgComponent;
