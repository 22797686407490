import React from 'react';
import { useDeleteProductsMutation } from 'store/services/products';
import { Products } from 'interfaces/products';
import { DeleteItems } from 'components/DeleteItems';

interface Props {
  onClose: () => void;
  selectedRows: Products;
}

export const DeleteProducts = ({
  onClose,
  selectedRows,
}: Props) => {
  const [deleteProducts, { isLoading }] = useDeleteProductsMutation();

  const handleDeleteProducts = () => {
    const ids = selectedRows.map((product) => product.id);
    deleteProducts(ids)
      .unwrap()
      .then(() => {
        onClose();
      });
  };

  return (
    <DeleteItems
      selectedRows={selectedRows}
      onClose={onClose}
      isLoading={isLoading}
      handleDelete={handleDeleteProducts}
      item="Product"
    />
  );
};
