import { LeftPane } from "components/Layout";
import { SectionLabel } from "components/common/SectionLabel";
import { useGetAuthMeQuery } from "store/services/auth";
import { useNavigate } from "react-router";
import { Button } from "components/common/Button";
import { getCurrentCompany } from "utils/currentCompany";
import { SweatingLlamaImage } from "assets/images";
import { ErrorPageContainer } from "pages/PageNotFound";

export const PendingCompany = () => {
  const navigate = useNavigate();
  const { data: user, refetch, isFetching } = useGetAuthMeQuery();
  const currentCompany = getCurrentCompany(user);

  return (
    <LeftPane contentWidth={1200}>
      <SectionLabel>
        Pending acceptation
        <Button
          variant="borderless"
          size="large"
          onClick={() => navigate('/settings/companies')}
        >
          Choose other Company
        </Button>
      </SectionLabel>
      <LeftPane>
        <ErrorPageContainer>
          <SweatingLlamaImage />
          <h4>Pending acceptation...</h4>
          <p>Selected company: <strong>{currentCompany?.name}</strong> is waiting for our approval.</p>
          <p>If you have any questions, please contact us at <a href="mailto:support@facta.io">support@facta.io</a>.</p>
          <Button
            disabled={isFetching}
            isLoading={isFetching}
            onClick={() => refetch()}
          >
            Refresh status...
          </Button>
        </ErrorPageContainer>
      </LeftPane>
    </LeftPane>
  );
};
