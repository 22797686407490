import * as React from "react";

const SvgComponent = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="inherit"
      d="m15.5 10.2.971 2.329 2.329.971-2.329.971L15.5 16.8l-.971-2.329L12.2 13.5l2.329-.971.971-2.329Zm0-5.2L13 11l-6 2.5 6 2.5 2.5 6 2.5-6 6-2.5-6-2.5-2.5-6ZM4.125 7.875 5.5 12l1.375-4.125L11 6.5 6.875 5.125 5.5 1 4.125 5.125 0 6.5l4.125 1.375Zm2.25 10.75L5.5 16l-.875 2.625L2 19.5l2.625.875L5.5 23l.875-2.625L9 19.5l-2.625-.875Z"
    />
  </svg>
);
export default SvgComponent;
