import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/store";

const formState = (state: RootState) => state.formState;

export const formDataSelector = createSelector(
  formState,
  (form) => form.formData,
);

export const predefinedDataSelector = createSelector(
  formState,
  (form) => form.predefinedData,
);
