import styled from "styled-components";
import { theme } from "theme/theme";

export const StyledQuickTagging = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: ${theme.colors.white};
  border: solid 1px ${theme.colors.borderColorLight};
  border-radius: 8px;
  box-shadow: ${theme.boxShadow};
  width: 450px;
  z-index: 2;
  gap: 24px;
`;