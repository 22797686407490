import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { IServerSideSelectionState } from "@ag-grid-community/core";

interface State {
  isAllSelected: boolean;
  selectedIds: string[];
  deselectedIds: string[];
  total?: number;
  filtering: {
    [key: string]: string | boolean;
  };
}

const initialState: State = {
  isAllSelected: false,
  selectedIds: [],
  deselectedIds: [],
  total: 0,
  filtering: {
    deleted: false,
  },
};

const gridHelperSlice = createSlice({
  name: 'gridHelper',
  initialState,
  reducers: {
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setSelectionState: (state, action: PayloadAction<IServerSideSelectionState | null>) => {
      if (!action.payload) {
        return;
      }

      const {
        selectAll,
        toggledNodes,
      } = action.payload;

      state.isAllSelected = !!selectAll;
      state.selectedIds = selectAll ? [] : toggledNodes;
      state.deselectedIds = selectAll ? toggledNodes : [];
    },
    clear: () => initialState,
    setFiltering: (state, action: PayloadAction<{ [key: string]: string }>) => {
      state.filtering = {
        ...action.payload,
        search: state.filtering.search,
        deleted: false,
      };
    },
    setQuickSearchFiltering: (state, action: PayloadAction<{ search: string }>) => {
      state.filtering = {
        ...state.filtering,
        search: action.payload.search,
        deleted: false,
      };
    },
  },
});

export const {
  reducer: gridHelperReducer,
  actions: gridHelperActions,
} = gridHelperSlice;

export type GridSelectionPayload = State;
