import { Company } from "./company";

export interface User {
  companies: Company[],
  currentCompanyId: string;
  email: string;
  firstName: string | null;
  id: string;
  lastName: string | null;
}

export enum UserRole {
  SUPERUSER = 'SuperUser',
  OWNER = 'Owner',
  ADMIN = 'Admin',
  MEMBER = 'Member',
  VIEWER = 'Viewer',
}
