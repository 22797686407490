import React, { useEffect } from 'react';
import * as yup from "yup";
import {
  useForm,
  Controller,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CloseIcon,
  CopyIcon,
  DeleteIcon,
} from 'assets/icons';
import {
  useGetAllTagsQuery,
  useUpdateTagMutation,
} from 'store/services/tags';
import { Button } from 'components/common/Button';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import {
  Tag,
  TagFormFields,
  TagType,
} from 'interfaces/tags';
import {
  StyledButtonContainer,
  StyledFormContainer,
  StyledFormSectionLabel,
} from 'components/common/Forms/styled';
import { Dropdown } from 'components/common/Dropdown';
import { InputBasic } from 'components/common/Input';
import { copyToClipboard } from 'utils/copyToClipboard';
import {
  getTagTypesOptions,
  prepareTagPayload,
} from 'utils/tags';
import { toastSuccess } from 'utils/toast';
import { actionBlocker } from 'utils/actionBlocker';
import { Tooltip } from 'components/common/Tooltip';
import { CompanyRevenueType } from 'interfaces/company';

interface Props {
  onClose: () => void;
  onSuccess?: (id?: string) => void;
  onSave: (id: string) => void;
  onDelete: () => void;
  selectedRows: Tag[];
  companyRevenueType?: CompanyRevenueType;
}

export const EditTags = ({
  onClose,
  onSuccess,
  onSave,
  onDelete,
  selectedRows,
  companyRevenueType,
}: Props) => {
  const [updateTag, { isLoading }] = useUpdateTagMutation();
  const isBulkEdit = selectedRows.length > 1;

  const { data: tags } = useGetAllTagsQuery();

  const TagTypeOptions = getTagTypesOptions(companyRevenueType);

  const schema = yup.object({
    name: yup.string()
      .required('Tag name is required.')
      .test(
        'isUnique',
        'Tag name must be unique for provided Tag Type.',
        (val, context) => !(tags
          ?.filter((tag) => !tag.deleted)
          ?.filter((tag) => tag.type === context.parent.type[0]?.value)
          ?.filter((tag) => tag.id !== selectedRows[0]?.id)
          .map((tag) => tag.name)
          ?.includes(val || '')),
      ),
    description: yup.string()
      .nullable(),
    type: yup.array()
      .of(
        yup.object({
          label: yup.string(),
          value: yup.string(),
        }))
      .min(1, 'Type is required.'),
  })
    .required();


  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState: { errors, isDirty },
    trigger,
  } = useForm<TagFormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      id: '',
      name: '',
      description: '',
      type: [],
    },
  });

  useEffect(() => {
    if (selectedRows.length !== 0) {
      const tag = selectedRows[0];

      reset({
        id: tag.id,
        name: tag.name,
        description: tag.description,
        type: [{
          label: companyRevenueType === CompanyRevenueType.CONTRACTS ? tag.type : 'Revenue' as TagType,
          value: tag.type,
        }],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose, selectedRows.length]);

  const handleSaveAndClose = handleSubmit((data) => {
    const tag = prepareTagPayload(data);

    return updateTag(tag)
      .unwrap()
      .then((result) => {
        toastSuccess('Tag successfully updated.');
        onSuccess && onSuccess(result.id);
        onClose();
      });
  });

  const handleSave = handleSubmit((data) => {
    const tag = prepareTagPayload(data);

    return updateTag(tag)
      .unwrap()
      .then((result) => {
        toastSuccess('Tag successfully updated.');
        onSuccess && onSuccess(result.id);
        onSave(result.id);
      });
  });

  const handleClickDelete = onDelete;

  return (
    <>
      <header>
        <ButtonsContainer>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={() => actionBlocker(onClose, isDirty)}
            pushRight
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <StyledFormSectionLabel data-cy="et-header">
          <span>
            {isBulkEdit ? 'Bulk Edit' : 'Edit Tag'}
          </span>
          {isBulkEdit
            ? (
              <span>{selectedRows.length} tags</span>
            )
            : (
              <ButtonsContainer>
                <Button
                  variant="simple"
                  size="large"
                  type="button"
                  onClick={(e) => copyToClipboard(e, selectedRows[0].id)}
                  data-cy="et-button-copy"
                >
                  <span>
                    Insights Tag ID
                  </span>
                  <CopyIcon />
                </Button>
              </ButtonsContainer>
            )}
        </StyledFormSectionLabel>
        <ButtonsContainer paddingBottom={20}>
          <Tooltip title="Delete">
            <Button
              variant="icon"
              color="primary"
              size="large"
              onClick={handleClickDelete}
              aria-label="Delete"
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        </ButtonsContainer>
      </header>
      <main>
        {!isBulkEdit && (
          <StyledFormContainer>
            <InputBasic
              isRequired
              labelText="Tag Name"
              placeholder="Name your tag"
              {...register('name')}
              onBlur={(e) => {
                const fieldValue = e.target.value;

                if (fieldValue) {
                  setValue('name', fieldValue.trim(), { shouldValidate: true });
                }
              }}
              error={errors.name?.message}
              dataCy="et-input-tag-name"
            />
            <InputBasic
              isOptional
              labelText="Description"
              placeholder="Describe your tag"
              {...register('description')}
              error={errors.description?.message}
              dataCy="et-input-tag-description"
            />
            <Controller
              name="type"
              control={control}
              render={({ field }) =>
                <Dropdown
                  isRequired
                  labelText="Tag Type"
                  options={TagTypeOptions}
                  values={getValues('type')}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger();
                  }}
                  placeholder="Select Tag Type"
                  dataCy="et-dropdown-type"
                  error={errors.type?.message}
                  disabled
                />
              }
            />
          </StyledFormContainer>
        )}
      </main>
      <footer>
        {!isBulkEdit && (
          <StyledButtonContainer pushRight>
            <Button
              type="submit"
              variant="outlined"
              color="secondary"
              onClick={handleSave}
              disabled={isLoading || !isDirty}
              isLoading={isLoading}
              data-cy="et-button-save"
            >
              SAVE
            </Button>
            <Button
              type="submit"
              onClick={handleSaveAndClose}
              disabled={isLoading || !isDirty}
              isLoading={isLoading}
              data-cy="et-button-save-close"
            >
              SAVE & CLOSE
            </Button>
          </StyledButtonContainer>
        )}
      </footer>
    </>
  );
};
