import styled, { css } from "styled-components";
import { theme } from "theme/theme";

interface Props {
  marginBottom?: number;
  marginTop?: number;
  blinking?: boolean;
}

export const Hint = styled.div<Props>`
  display: flex;
  padding: 16px;
  background-color: ${theme.colors.secondaryBackground};
  color: ${theme.colors.textDisabled};
  border-radius: 8px;
  margin-bottom: ${props => props.marginBottom !== undefined ? props.marginBottom : 40}px;
  margin-top: ${props => props.marginTop !== undefined ? props.marginTop : 0}px;
  font-size: 14px;
  line-height: 24px;

  p {
    display: block;
    font-size: 14px;
    line-height: 24px;
  }
  
  p + section {
    margin-top: 20px;
  }

  svg {
    min-width: 20px;
    max-width: 20px;
    margin-right: 10px;
  }

  section {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  @keyframes borderColor {
    0% {
      border-color: ${theme.colors.error};
    }
    50% {
      border-color: ${theme.colors.secondaryBackground};
    }
    100% {
      border-color: ${theme.colors.error};
    }
  }

  ${props => props.blinking && css`
    border: 2px solid ${theme.colors.error};
    animation-name: borderColor;
    animation-duration: 400ms;
    animation-iteration-count: 8;
  `}
`;
