import { api } from "./api";
import {
  Customer,
  Customers,
  CustomersBulkUpdatePayload,
  CustomerUpdatePayload,
} from "interfaces/customers";
import {
  enrichWithDisabledFlag,
  apiUpdateRecipe,
  apiAddRecipe,
  apiAddMultipleRecipe,
  enrichSearchResponseWithDisabledFlag,
} from "utils/api";
import {
  SearchRequest,
  SearchResponse,
} from "interfaces/api";
import { GridSelectionPayload } from "store/slices/gridHelper";
import { downloadFile } from "utils/downloadFile";
import {
  ContractLinesTags,
  ContractsTags,
  CustomersTags,
  SubscriptionsTags,
} from "./api-tags";

export const customersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllCustomers: builder.query<Customers, void>({
      query: () => ({
        url: `customers`,
      }),
      transformResponse: enrichWithDisabledFlag,
      providesTags: [CustomersTags.Customers],
    }),
    getCustomersSearch: builder.query<SearchResponse<Customers>, SearchRequest>({
      query: (filtering) => ({
        method: 'POST',
        url: 'customers/list',
        body: {
          ...filtering,
        },
      }),
      transformResponse: enrichSearchResponseWithDisabledFlag,
      keepUnusedDataFor: 10,
    }),
    exportCustomers: builder.query<null, {[key: string]: string} | undefined>({
      query: (filtering) => {
        return {
          url: 'customers/list',
          method: 'POST',
          headers: {
            'Accept': 'text/csv',
          },
          body: {
            filtering: {
              deleted: false,
              ...filtering,
            },
          },
        };
      },
      transformResponse: (fileContent: string, _, filtering) => {
        downloadFile(fileContent, `insights-customers${filtering ? '-filtered' : ''}.csv`);
        return null;
      },
    }),
    deleteCustomers: builder.mutation<void, GridSelectionPayload>({
      query: (body) => {
        return {
          url: `customers`,
          method: 'DELETE',
          body,
        };
      },
      invalidatesTags: (res, err) => !err ? [
        ContractsTags.Contracts,
        SubscriptionsTags.Subscriptions,
        ContractLinesTags.ContractLinesByContractIds,
      ] : [],
    }),
    addCustomer: builder.mutation<Customer, CustomerUpdatePayload>({
      query: (customer) => {
        return {
          url: `customers`,
          method: 'POST',
          body: customer,
        };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: addedCustomer} = await queryFulfilled;
          dispatch(customersApi.util
            .updateQueryData('getAllCustomers', undefined, apiAddRecipe(addedCustomer)));
        } catch {}
      },
    }),
    updateCustomer: builder.mutation<Customer, CustomerUpdatePayload>({
      query: (customer) => {
        return {
          url: `customers/${customer.id}`,
          method: 'PUT',
          body: customer,
        };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: updatedCustomer} = await queryFulfilled;
          dispatch(customersApi.util
            .updateQueryData('getAllCustomers', undefined, apiUpdateRecipe(updatedCustomer)));
        } catch {}
      },
      invalidatesTags: (res, err) => !err ? [
        ContractsTags.Contracts,
        SubscriptionsTags.Subscriptions,
        ContractLinesTags.ContractLinesByContractIds,
      ] : [],
    }),
    bulkAddCustomers: builder.mutation<Customer[], CustomerUpdatePayload[]>({
      query: (customers) => {
        return {
          url: `customers/bulk_create`,
          method: 'POST',
          body: customers,
        };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: addedCustomers} = await queryFulfilled;
          dispatch(customersApi.util
            .updateQueryData('getAllCustomers', undefined, apiAddMultipleRecipe(addedCustomers)));
        } catch {}
      },
    }),
    bulkEditCustomers: builder.mutation<undefined, CustomersBulkUpdatePayload>({
      query: (body) => {
        return {
          url: 'customers/bulk_edit',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (res, err) => !err ? [
        CustomersTags.Customers,
        ContractsTags.Contracts,
        SubscriptionsTags.Subscriptions,
        ContractLinesTags.ContractLinesByContractIds,
      ] : [],
    }),
    customersUniqueness: builder.query<boolean, { name: string }>({
      query: (body) => ({
        method: 'POST',
        url: 'customers/unique',
        body,
      }),
    }),
  }),
});

export const {
  useGetAllCustomersQuery,
  useAddCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomersMutation,
  useBulkAddCustomersMutation,
  useBulkEditCustomersMutation,
  useLazyCustomersUniquenessQuery,
  useLazyGetCustomersSearchQuery,
  useLazyExportCustomersQuery,
} = customersApi;
