import React from 'react';
import styled from "styled-components";
import { Checkbox } from "components/common/Checkbox";
import { useAppDispatch } from "hooks/redux";
import {
  bulkImportActions,
  InvalidEntity,
} from "store/slices/bulkImport";
import { BulkImportEntityType } from 'interfaces/bulkImport';

const CheckboxWrapper = styled.div`
  height: 100%;

  label {
    height: 100%;
  }

  div {
    padding: 0 10px 0 0;
    height: 100%;

    div {
      height: 32px;
      padding: 0;
    }
  }
`;

interface Props {
  data: InvalidEntity;
  value: boolean;
  tab: string;
}

export const CheckboxRenderer = ({
  value,
  data,
  tab,
}: Props) => {
  const dispatch = useAppDispatch();

  const handleCheckboxChange = (checked: boolean) => {
    if (tab === BulkImportEntityType.PRODUCTS) {
      dispatch(bulkImportActions.updateInvalidProduct({
        id: data.id,
        createNew: checked,
        entity: {
          createNew: checked,
          id: null,
          name: null,
        },
        revenueType: {
          createNew: checked,
          id: null,
        },
      }));
    }

    if (tab === BulkImportEntityType.CUSTOMERS) {
      dispatch(bulkImportActions.updateInvalidCustomer({
        id: data.id,
        createNew: checked,
        entity: {
          createNew: checked,
          id: null,
          name: null,
        },
      }));
    }

    if (tab === BulkImportEntityType.TAGS) {
      dispatch(bulkImportActions.updateInvalidTag({
        id: data.id,
        createNew: checked,
        entity: {
          createNew: checked,
          id: null,
          name: null,
        },
      }));
    }
  };

  return (
    <CheckboxWrapper>
      <Checkbox
        name="Create new"
        checked={value}
        onChange={handleCheckboxChange}
        condensed
      >
        {value ? 'Create new' : ' '}
      </Checkbox>
    </CheckboxWrapper>
  );
};
