import React from 'react';
import { useAppSelector } from 'hooks/redux';
import { ProgressTracker } from 'components/ProgresTracker';
import {
  StyledButtonContainer,
  StyledFormSectionLabel,
} from 'components/common/Forms/styled';
import {
  CheckCircleIcon,
  InfoIcon,
  RadioUncheckedIcon,
} from 'assets/icons';
import {
  SummaryContainer,
  SummaryEntry,
} from './styled';
import {
  bulkImportFileDetailsSelector,
  bulkImportCommonDataSelector,
} from 'store/selectors/bulkImport';
import { Button } from 'components/common/Button';
import { CompanyRevenueType } from 'interfaces/company';
import { Hint } from 'components/common/Hint';
import { SectionLabel } from 'components/common/SectionLabel';

interface Props {
  companyRevenueType: CompanyRevenueType;
  handleCancel: () => void;
  handleContinue: () => void;
  isContinueDisabled?: boolean;
  step: number;
  isStepDone: boolean;
  submitLabel?: string;
  isOutside?: boolean;
}

export const BulkImportSidePanel = ({
  companyRevenueType,
  handleCancel,
  handleContinue,
  isContinueDisabled,
  step,
  isStepDone,
  submitLabel,
  isOutside,
}: Props) => {
  const bulkImportCommonData = useAppSelector(bulkImportCommonDataSelector);
  const bulkImportFileDetails = useAppSelector(bulkImportFileDetailsSelector);

  const {
    totalRows,
    errorRows,
    duplicateRows,
    contractsCount,
    contractsErrorsCount,
    contractsDuplicatesCount,
    contractLinesCount,
    contractLinesErrorsCount,
    contractLinesDuplicatesCount,
  } = bulkImportCommonData;

  const {
    uploadedAt,
  } = bulkImportFileDetails;

  const steps = companyRevenueType === CompanyRevenueType.CONTRACTS ? 5 : 4;
  const accomplishedStep = isStepDone ? step : step - 1;
  const progress = Math.floor(accomplishedStep / steps * 100);

  return (
    <>
      <header>
        <StyledFormSectionLabel >
          <span>
            Import Summary
          </span>
          {uploadedAt && (
            <span>
              Created on {uploadedAt}
            </span>
          )}
        </StyledFormSectionLabel>
      </header>
      <main>
        <SummaryContainer>
          <section>Progress tracker</section>
          <ProgressTracker progress={progress} />
          <SummaryEntry bold={step === 1}>
            <span>1. Upload file</span>
            <span>{accomplishedStep >= 1 ? <CheckCircleIcon /> : <RadioUncheckedIcon />}</span>
          </SummaryEntry>
          <SummaryEntry bold={step === 2}>
            <span>2. Clean data</span>
            <span>{accomplishedStep >= 2 ? <CheckCircleIcon /> : <RadioUncheckedIcon />}</span>
          </SummaryEntry>
          {companyRevenueType === CompanyRevenueType.SUBSCRIPTIONS && (
            <>
              <SummaryEntry bold={step === 3}>
                <span>3. Review data & submit</span>
                <span>{accomplishedStep === 3 ? <CheckCircleIcon /> : <RadioUncheckedIcon />}</span>
              </SummaryEntry>
              <SummaryEntry>
                <span>4. Done</span>
                <span><RadioUncheckedIcon /></span>
              </SummaryEntry>
            </>
          )}
          {companyRevenueType === CompanyRevenueType.CONTRACTS && (
            <>
              <SummaryEntry bold={step === 3}>
                <span>3. Review contracts</span>
                <span>{accomplishedStep >= 3 ? <CheckCircleIcon /> : <RadioUncheckedIcon />}</span>
              </SummaryEntry>
              <SummaryEntry bold={step === 4}>
                <span>4. Review contract lines</span>
                <span>{accomplishedStep === 4 ? <CheckCircleIcon /> : <RadioUncheckedIcon />}</span>
              </SummaryEntry>
              <SummaryEntry>
                <span>5. Done</span>
                <span><RadioUncheckedIcon /></span>
              </SummaryEntry>
            </>
          )}
        </SummaryContainer>
        {companyRevenueType === CompanyRevenueType.SUBSCRIPTIONS && (
          <SummaryContainer>
            <section>Data Summary</section>
            <SummaryEntry>
              <span>Total Records Found</span>
              <span>{totalRows || '-'}</span>
            </SummaryEntry>
            <SummaryEntry>
              <span>Issues Remaining</span>
              <span>{errorRows || '-'}</span>
            </SummaryEntry>
            <SummaryEntry>
              <span>Duplicates Found</span>
              <span>{duplicateRows || '-'}</span>
            </SummaryEntry>
          </SummaryContainer>
        )}
        {companyRevenueType === CompanyRevenueType.CONTRACTS && (
          <SummaryContainer>
            <section>Contracts</section>
            <SummaryEntry>
              <span>Total Found</span>
              <span>{contractsCount || '-'}</span>
            </SummaryEntry>
            <SummaryEntry>
              <span>Issues Remaining</span>
              <span>{contractsErrorsCount || '-'}</span>
            </SummaryEntry>
            <SummaryEntry>
              <span>Duplicates Found</span>
              <span>{contractsDuplicatesCount || '-'}</span>
            </SummaryEntry>
            <section>Contract Lines</section>
            <SummaryEntry>
              <span>Total Found</span>
              <span>{contractLinesCount || '-'}</span>
            </SummaryEntry>
            <SummaryEntry>
              <span>Issues Remaining</span>
              <span>{contractLinesErrorsCount || '-'}</span>
            </SummaryEntry>
            <SummaryEntry>
              <span>Duplicates Found</span>
              <span>{contractLinesDuplicatesCount || '-'}</span>
            </SummaryEntry>
          </SummaryContainer>
        )}
      </main>
      <footer>
        {isOutside && (
          <Hint
            blinking
            marginTop={20}
            marginBottom={20}
          >
            <InfoIcon />
            <div>
              <SectionLabel tertiary>Unfinished Import</SectionLabel>
              <p>
                Your data has not been imported into Facta yet.
                Please finish your bulk upload and click Submit below to complete the process.
              </p>
            </div>
          </Hint>
        )}
        <StyledButtonContainer pushRight>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
          >
            CANCEL
          </Button>
          <Button
            type="submit"
            onClick={handleContinue}
            disabled={isContinueDisabled}
          >
            {submitLabel || 'CONTINUE'}
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};
