import React, {
  CSSProperties,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { useClickOutside } from 'hooks/useClickOutside';
import { IRowNode } from '@ag-grid-community/core';
import {
  StyledMoveSelected,
  StyledLeftContainer,
  StyledOption,
} from '../MoveSelected/styled';
import { toastWarning } from 'utils/toast';

interface Props {
  popperRef: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  style: CSSProperties;
  attributes: {
    [key: string]: {
      [key: string]: string;
    } | undefined;
  };
  gridRef: React.RefObject<AgGridReact<any>>;
  onClose: () => void;
}

export const AddFactaAccount = ({
  popperRef,
  attributes,
  style,
  gridRef,
  onClose,
}: Props) => {
  const [options, setOptions] = useState<any[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside(containerRef, onClose);

  const handleAddAccount = (node: IRowNode) => {
    const id = `new_account_${crypto.randomUUID()}`;
    const type = node.data.path[0];
    const name = 'New Facta Account';

    const isAlreadyAdding = !!gridRef.current?.api.getEditingCells().length;

    if (isAlreadyAdding) {
      toastWarning('Finish adding the previous Facta Account.');
    }

    if (!isAlreadyAdding) {
      gridRef.current?.api.applyTransaction({
        add: [{
          id,
          account: {
            name: name,
            type: type,
          },
          type: 'facta_account_name',
          path: [...node.data.path, name],
          active: true,
          used: true,
        }],
      });

      setTimeout(() => {
        onClose();
        const rowIndex =  gridRef.current?.api.getRowNode(id)?.rowIndex;

        if (rowIndex) {
          gridRef.current?.api.startEditingCell({
            rowIndex: rowIndex,
            colKey: 'ag-Grid-AutoColumn',
          });
        }
      }, 0);
    }
  };

  useEffect(() => {
    const items: IRowNode[] = [];

    gridRef.current?.api.forEachNode((node) => {
      if (node.data.path.length === 1 && node.data.path[0] !== 'Uncategorized' && node.data.type !== 'total') {
        items.push(node);
      }
    });

    setOptions(items);
    containerRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [gridRef]);

  return (
    <div ref={containerRef}>
      <StyledMoveSelected
        ref={popperRef}
        style={style}
        {...attributes}
      >
        <StyledLeftContainer>
          {options.map((node) => (
            <StyledOption
              key={node.data.path[0]}
              onClick={() => handleAddAccount(node)}
            >
              {node.data.path[0]}
            </StyledOption>
          ))}
        </StyledLeftContainer>
      </StyledMoveSelected>
    </div>
  );
};
