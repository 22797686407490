import React from 'react';
import { LeftPane } from 'components/Layout';
import { Button } from 'components/common/Button';
import { SectionLabel } from 'components/common/SectionLabel';
import { useSyncMetricsMutation } from 'store/services/metrics';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import {
  RefreshIcon,
  RotatingRefreshIcon,
} from 'assets/icons';
import { differenceInMilliseconds } from 'date-fns';
import { Box } from 'components/common/Box';

export const Sync = () => {
  const [syncMetrics, {
    isLoading,
    status,
    startedTimeStamp,
    fulfilledTimeStamp,
  }] = useSyncMetricsMutation();

  const handleSyncClick = () => {
    syncMetrics();
  };

  return (
    <LeftPane contentWidth={1200}>
      <SectionLabel marginBottom={40}>Sync Metrics</SectionLabel>
      <Box marginBottom={40}>
        <SectionLabel
          tertiary
          marginBottom={0}
        >
          Status: {status}
          {fulfilledTimeStamp && startedTimeStamp && ` in ${differenceInMilliseconds(new Date(fulfilledTimeStamp), new Date(startedTimeStamp)) / 1000} seconds`}
        </SectionLabel>
      </Box>
      <ButtonsContainer pushRight>
        <Button
          onClick={handleSyncClick}
          disabled={isLoading}
        >
          {isLoading ? <RotatingRefreshIcon /> : <RefreshIcon />}
          Trigger Sync
        </Button>
      </ButtonsContainer>
    </LeftPane >
  );
};
