import * as yup from "yup";
import { CloseIcon } from 'assets/icons';
import { SectionLabel } from 'components/common/SectionLabel';
import { Button } from 'components/common/Button';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { InputBasic } from 'components/common/Input';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CheckboxInput } from "components/common/CheckboxInput";
import {
  StyledButtonContainer,
  StyledFormContainer,
} from "components/common/Forms/styled";
import {
  useGetReportQuery,
  useUpdateReportMutation,
} from "store/services/reports";
import { toastSuccess } from "utils/toast";
import { UpdateReportPayload } from "interfaces/reports";

interface Props {
  reportId: string;
  onClose: () => void;
}

export const EditNotes = ({
  reportId,
  onClose,
}: Props) => {

  const { data: report, isLoading } = useGetReportQuery(reportId, { refetchOnMountOrArgChange: true });
  const [updateReport, { isLoading: isUpdating }] = useUpdateReportMutation();

  const schema = yup.object({
    notes: yup.string(),
    shouldExportNotes: yup.boolean(),
  })
    .required();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<{ notes: string; shouldExportNotes: boolean }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      notes: report?.notes || '',
      shouldExportNotes: report?.shouldExportNotes || false,
    },
  });

  const handleSave = handleSubmit((data) => {
    const payload: UpdateReportPayload = {
      reportId,
      name: report?.name || '',
      notes: data.notes,
      shouldExportNotes: data.shouldExportNotes,
    };

    updateReport(payload)
      .unwrap()
      .then(() => {
        toastSuccess('Notes updated successfully.');
        onClose();
      });
  });

  return (
    <>
      <header>
        <ButtonsContainer paddingBottom={16}>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={onClose}
            pushRight
            data-cy="en-button-close"
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <SectionLabel>
          Notes
        </SectionLabel>
      </header>
      <main>
        <StyledFormContainer onSubmit={handleSave}>
          <InputBasic
            isOptional
            labelText="Notes"
            placeholder="Add a quick note"
            {...register('notes')}
            error={errors.notes?.message}
            data-cy="en-input-notes"
            multiline
            height={192}
          />
          <CheckboxInput
            isOptional
            {...register('shouldExportNotes')}
            error={errors.shouldExportNotes?.message}
            data-cy="en-should-export-notes"
            checkboxLabel="Include notes in report previews"
            defaultValue={getValues().shouldExportNotes}
            small
          />
        </StyledFormContainer>
      </main>
      <footer>
        <StyledButtonContainer pushRight>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={onClose}
            disabled={isLoading || isUpdating}
            data-cy="en-button-cancel"
          >
            CANCEL
          </Button>
          <Button
            type="submit"
            onClick={handleSave}
            disabled={isLoading || isUpdating}
            isLoading={isUpdating}
            data-cy="en-button-save"
          >
            SAVE
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};
