import React, {
  useMemo,
  useRef,
} from 'react';
import {
  StyledHelpOverlay,
  StyledHelpOverlayItem,
  StyledHelpOverlayItemsWrapper,
} from './styled';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { Button } from 'components/common/Button';
import { CloseIcon } from 'assets/icons';
import { useClickOutside } from 'hooks/useClickOutside';
import {
  ColDef,
  ColGroupDef,
  GridOptions,
} from '@ag-grid-community/core';

interface Props {
  onClose: () => void;
  columnDefs: ColDef[] | ColGroupDef[];
  gridOptions: GridOptions;
}

export const FactaTableHelp = ({ onClose, columnDefs, gridOptions }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, onClose);

  const isBestOSEver = navigator.userAgent.indexOf("Mac") !== -1;
  const cmdCtrl = isBestOSEver
    ? '⌘'
    : 'ctrl';
  const optionAlt = isBestOSEver
    ? '⌥'
    : 'alt';
  const shift = '⇧';

  interface IndexableColDef extends ColDef {
    [s: string]: any;
  }

  const columnDefFeatureCheck = useMemo(() => (feature: string) => columnDefs.some((col) => col.hasOwnProperty('children')
    ? (col as ColGroupDef).children.some((sub) => (sub as IndexableColDef)[feature])
    : (col as IndexableColDef)[feature]), [columnDefs]);

  const features = {
    editable: columnDefFeatureCheck('editable'),
    sortable: columnDefFeatureCheck('sortable'),
    rowGroupable: columnDefFeatureCheck('enableRowGroup'),
    dataSummary: !!gridOptions.statusBar,
    charts: !!gridOptions.enableCharts,
    agGridFiltering: columnDefFeatureCheck('filter'),
  };

  return (
    <StyledHelpOverlay ref={ref}>
      <ButtonsContainer paddingBottom={20}>
        <h1>Advanced table features</h1>
        <Button
          pushRight
          variant="icon-inverse"
          color="transparent"
          onClick={onClose}
        >
          <CloseIcon />
        </Button>
      </ButtonsContainer>
      <StyledHelpOverlayItemsWrapper>
        <StyledHelpOverlayItem hidden={!features.sortable}>
          <h2>Sorting</h2>
          <p>To sort by one column, just click its header. Click again to change order.</p>
          <p>To sort by multiple columns click 2nd and every other column's header holding the <em>{shift} Shift</em> key.</p>
        </StyledHelpOverlayItem>
        <StyledHelpOverlayItem>
          <h2>Filtering</h2>
          {features.agGridFiltering
            ? <p>To filter table data, use specific column's context menu (<em>🜄</em>). </p>
            : <p>Filtering can be done by using <em>Filter</em> button.</p>}
          <p>Note that not every column is suitable to filter data.</p>
        </StyledHelpOverlayItem>
        <StyledHelpOverlayItem>
          <h2>Columns order</h2>
          <p>To change column's order, simply drag it to desired place. Use <em>Reset view</em> to bring back default view.</p>
        </StyledHelpOverlayItem>
        <StyledHelpOverlayItem>
          <h2>Columns pinning</h2>
          <p>To pin a column to either left or right side of table, open context menu (<em>≡</em>) and use <em>Pin column...</em> submenu.</p>
        </StyledHelpOverlayItem>
        <StyledHelpOverlayItem>
          <h2>Columns visibility</h2>
          <p>To hide a column, either drag it out of the table or use context menu (<em>≡</em>) and adjust columns visibility on the second tab (<em>᎒᎒᎒</em>).</p>
        </StyledHelpOverlayItem>
        <StyledHelpOverlayItem hidden={!features.rowGroupable}>
          <h2>Row grouping</h2>
          <p>Under specific column's context menu (<em>≡</em>), click <em>Group by...</em> to group by this column.</p>
          <p>You can group by multiple columns. Use bar at the top to change sorting or to drag and drop columns. </p>
          <p>Note that not every column is suitable to be grouped by.</p>
        </StyledHelpOverlayItem>
        <StyledHelpOverlayItem hidden={!features.charts}>
          <h2>Charts</h2>
          <p>To create a chart, use your mouse to select range of data, then <em>right click</em> and select <em>Chart Range...</em></p>
        </StyledHelpOverlayItem>
        <StyledHelpOverlayItem hidden={!features.dataSummary}>
          <h2>Data summary</h2>
          <p>To see data summary (sum, average, etc...) in the status bar, just use mouse to select range containing numbers.</p>
        </StyledHelpOverlayItem>
        <StyledHelpOverlayItem>
          <h2>Copy/paste</h2>
          <p>Select range of data and then use <em>{cmdCtrl} + C</em> or <em>right click</em> and select <em>Copy</em>.</p>
        </StyledHelpOverlayItem>
        {features.editable
          ? (
            <StyledHelpOverlayItem>
              <h2>Key shortcuts</h2>
              <p><em>{cmdCtrl} + X</em> - Cut</p>
              <p><em>{cmdCtrl} + C</em> - Copy</p>
              <p><em>{cmdCtrl} + V</em> - Paste</p>
              {/* <p><em>{cmdCtrl} + D</em> - Fill down</p> */}
              <p><em>{cmdCtrl} + {optionAlt} + &ndash;</em> - Delete line</p>
              <p><em>{cmdCtrl} + Z</em> - Undo</p>
              <p><em>{cmdCtrl} + {shift} + Z</em> - Redo</p>
              <p><em>{cmdCtrl} + A</em> - Select all</p>
              <p><em>Space</em> - Edit cell</p>
              <p><em>{shift} + Space</em> - Select rows range</p>
              <p><em>ctrl + Space</em> - Select columns range</p>
            </StyledHelpOverlayItem>
          )
          : (
            <StyledHelpOverlayItem>
              <h2>Key shortcuts</h2>
              <p><em>{cmdCtrl} + X</em> - Cut</p>
              <p><em>{cmdCtrl} + C</em> - Copy</p>
              <p><em>{cmdCtrl} + A</em> - Select all</p>
              <p><em>Space</em> - Select row</p>
              <p><em>{shift} + Space</em> - Select rows range</p>
              <p><em>ctrl + Space</em> - Select columns range</p>
            </StyledHelpOverlayItem>
          )}
      </StyledHelpOverlayItemsWrapper>
    </StyledHelpOverlay>
  );
};

