import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { theme } from "theme/theme";
import { ANIMATION_DURATION } from "utils/constants";

interface TabsContainerProps {
  disabled?: boolean;
}

export const StyledTabsContainer = styled.div<TabsContainerProps>`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 2px solid ${theme.colors.borderColorDark};
  margin-bottom: 40px;

  @media screen and (max-height: 800px) {
    margin-bottom: 20px;
  }

  ${props => props.disabled && css`
      pointer-events: none;
    `}
`;

interface TabButtonProps {
  selected?: boolean;
  disabled?: boolean;
}

export const TabButton = styled(Link)<TabButtonProps>`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${props => props.selected
    ? theme.colors.primary
    : props.disabled
      ? theme.colors.textDisabled
      : theme.colors.textDark};
  padding: 16px;
  height: auto;
  border: 0;
  border-bottom: 2px solid ${props => props.selected ? theme.colors.primary : theme.colors.borderColorDark};
  border-radius: 0%;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  background-color: unset;
  transition: all ${ANIMATION_DURATION}ms ease;
  margin-bottom: -2px;

  &:hover {
    ${props => !props.disabled && css`
      background-color: ${theme.colors.mainBackground};
    `}
  }

  &:visited {
    color: ${props => props.selected
    ? theme.colors.primary
    : props.disabled
      ? theme.colors.textDisabled
      : theme.colors.textDark};
  }
`;
