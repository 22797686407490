import { useRef } from "react";
import {
  DragStoppedEvent,
  DragStartedEvent,
} from "@ag-grid-community/core";

export const useDragColumnChange = (callback: (e: DragStoppedEvent) => void) => {
  const columnOrderRef = useRef<string[]>([]);

  const onDragStarted = (e: DragStartedEvent) => {
    columnOrderRef.current = e.api.getColumnState()
      .map(column => column.colId);
  };

  const onDragStopped = (e: DragStoppedEvent) => {
    const newColumnOrder = e.api.getColumnState()
      .map(column => column.colId);

    const sameOrder = columnOrderRef.current.every(
      (column, i) => column === newColumnOrder[i],
    );

    const isHidden = e.api.getColumnState()
      .find(column => column.hide);

    if (!sameOrder || isHidden) {
      callback(e);
    }
  };

  return { onDragStarted, onDragStopped };
};