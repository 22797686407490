import React from 'react';
import { Button } from 'components/common/Button';
import {
  StyledDetailBox,
  StyledDetailBoxHeader,
  StyledDetailBoxContent,
  StyledDetailBoxEntry,
} from './styled';
import { Deleted } from 'components/common/Deleted';

interface DetailBoxProps {
  title: string;
  linkTitle?: string;
  linkAction?: () => void;
  children: React.ReactNode;
  flex?: boolean;
  isFocused?: boolean;
}

export const DetailBox = ({
  title,
  linkTitle,
  linkAction,
  children,
  flex,
  isFocused,
}: DetailBoxProps) => {
  return (
    <StyledDetailBox flex={flex}>
      <StyledDetailBoxHeader>
        <p>{title}</p>
        {linkTitle && linkAction && (
          <Button
            size="large"
            variant="borderless"
            onClick={linkAction}
          >
            {linkTitle}
          </Button>
        )}
      </StyledDetailBoxHeader>
      <StyledDetailBoxContent isFocused={isFocused}>
        {children}
      </StyledDetailBoxContent>
    </StyledDetailBox>
  );
};

interface DetailBoxEntryProps {
  title: string;
  value: string;
  deleted?: boolean;
  children?: React.ReactNode;
}

export const DetailBoxEntry = ({
  title,
  value,
  deleted,
  children,
}: DetailBoxEntryProps) => {
  return (
    <StyledDetailBoxEntry>
      <p>{title}</p>
      <p>
        {children
          ? children
          : (
              <>
                {value}
                {deleted && <Deleted inline>deleted</Deleted>}
              </>
            )}
      </p>
    </StyledDetailBoxEntry>
  );
};

interface DetailBoxEntriesProps {
  title: string;
  value: string[];
}

export const DetailBoxEntries = ({
  title,
  value,
}: DetailBoxEntriesProps) => {
  return (
    <StyledDetailBoxEntry>
      <p>{title}</p>
      {value.map((val) => (
        <p key={val}>
          {val}
        </p>
      ))}
    </StyledDetailBoxEntry>
  );
};