import React from 'react';
import { DeleteItems } from 'components/DeleteItems';
import { CompanyUser } from 'interfaces/company';
import { useDeleteUsersMutation } from 'store/services/users';

interface Props {
  onClose: () => void;
  selectedRows: CompanyUser[];
}

export const DeleteUsers = ({
  onClose,
  selectedRows,
}: Props) => {
  const [deleteUsers, { isLoading }] = useDeleteUsersMutation();

  const handleDeleteUsers = () => {
    const ids = selectedRows.map((customer) => customer.id);
    deleteUsers(ids)
      .unwrap()
      .then(() => {
        onClose();
      });
  };

  return (
    <DeleteItems
      selectedRows={selectedRows}
      onClose={onClose}
      isLoading={isLoading}
      handleDelete={handleDeleteUsers}
      deleteWord="Remove"
      item="user"
      shortenDescription
    />
  );
};
