import { ColDef } from '@ag-grid-community/core';
import { CompanyRevenueType } from 'interfaces/company';
import { caseInsensitiveComparator } from "utils/aggrid";
import { multiFilter } from 'utils/filters';

export const getColumnDefs = (companyRevenueType?: CompanyRevenueType): Array<ColDef> => ([
  {
    field: 'id',
    headerName: 'Id',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    cellRenderer: () => null,
    maxWidth: 70,
    pinned: 'left',
  },
  {
    field: 'name',
    headerName: 'Tag Name',
    sortable: true,
    resizable: true,
    flex: 1,
    minWidth: 250,
    comparator: caseInsensitiveComparator(),
    ...multiFilter,
  },
  {
    field: 'description',
    headerName: 'Description',
    sortable: true,
    resizable: true,
    flex: 1,
    minWidth: 250,
    valueFormatter: ({ value }) => value ? value : '-',
    comparator: caseInsensitiveComparator(),
    ...multiFilter,
  },
  {
    field: 'type',
    headerName: 'Type',
    sortable: true,
    resizable: true,
    flex: 1,
    minWidth: 250,
    comparator: caseInsensitiveComparator(),
    enableRowGroup: true,
    ...multiFilter,
    valueGetter: ({ data }) => data.type === 'Contract'
      ? companyRevenueType === CompanyRevenueType.CONTRACTS ? 'Contract' : 'Revenue'
      : data.type,
  },
]);