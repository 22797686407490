import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useForm,
  Controller,
} from 'react-hook-form';
import * as yup from "yup";
import {
  ChevronDown,
  CloseIcon,
} from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import {
  StyledButtonContainer,
  StyledFormSectionLabel,
  StyledFormContainer,
  StyledAdditionalOptionsContainer,
  StyledExpandButton,
} from 'components/common/Forms/styled';
import { Button } from 'components/common/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { Datepicker } from 'components/common/Datepicker';
import { Dropdown } from 'components/common/Dropdown';
import { InputBasic } from 'components/common/Input';
import { useGetAllCustomersQuery } from 'store/services/customers';
import {
  formatDateToISO,
  isoToDate,
} from 'utils/dates';
import { bulkImportContractsGetRowByAgGridId } from 'store/selectors/bulkImport';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import { bulkImportActions } from 'store/slices/bulkImport';
import { actionBlocker } from 'utils/actionBlocker';
import { useGetAllTagsQuery } from 'store/services/tags';
import { TagType } from 'interfaces/tags';
import { tagsSortFn } from 'utils/tags';
import { formStateActions } from 'store/slices/formState';
import {
  BulkImportContractsFormFields,
  BulkImportContractsRow,
} from 'interfaces/bulkImportContracts';
import { useGetContractsSearchQuery } from 'store/services/contracts';

interface Props {
  id: string;
  onClose: () => void;
  onAddTag?: () => void;
  onAddCustomer: () => void;
  onDelete: () => void;
}

export const BulkImportContractsEditContract = ({
  id,
  onClose,
  onAddTag,
  onAddCustomer,
}: Props) => {
  const dispatch = useAppDispatch();
  const selectedRow = useAppSelector(bulkImportContractsGetRowByAgGridId(id))!;
  const [isOptionsExpanded, setIsOptionsExpanded] = useState(false);
  const [isErrorOnTags, setIsErrorOnTags] = useState(!!selectedRow.contractTags.providedValue && !!selectedRow.contractTags.errorCode);

  const {
    contractNames,
  } = useGetContractsSearchQuery({
    filtering: { deleted: false },
    pagination: {
      page: 0,
      perPage: 0,
    },
    simplified: false,
  }, {
    selectFromResult: ({ data }) => ({
      contractNames: data?.data.map((cont) => cont.name),
    }),
  });

  const {
    data: customersAll,
  } = useGetAllCustomersQuery();
  const customers = useMemo(() => customersAll?.filter((cust) => !cust.deleted), [customersAll]);

  const {
    data: tags,
  } = useGetAllTagsQuery();

  const tagOptions = useMemo(() => tags
    ?.filter((tag) => !tag.deleted && tag.type === TagType.CONTRACT)
    ?.sort(tagsSortFn) || [],
    [tags]);

  const defaultValues = useMemo(() => ({
    contractName: '',
    contractCustomer: [],
    contractBookingDate: undefined,
    contractStartDate: undefined,
    contractEndDate: undefined,
    contractCancelDate: undefined,
    contractTags: [],
    contractCRMID: '',
    contractExternalLink: '',
    contractNote: '',
    contractPrevious: '',
  }), []);

  const schema = yup.object({
    contractName: yup.string()
      .required('Contract name is required.')
      .test(
        'isUnique',
        'Contract name must be unique.',
        (val) => !(contractNames?.includes(val || '')),
      ),
    contractCustomer: yup.array()
      .of(
        yup.object({
          id: yup.string(),
          name: yup.string(),
        }))
      .min(1, 'Customer is required.'),
    contractStartDate: yup.date()
      .nullable()
      .required('Start date is required.'),
    contractEndDate: yup.date()
      .nullable()
      .when('contractStartDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('contractStartDate'),
            'End date cannot be before start date.'),
      })
      .required('End date is required.'),
    contractBookingDate: yup.date()
      .nullable()
      .when('contractEndDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .max(yup.ref('contractEndDate'),
            'Booking date cannot be after end date.'),
      })
      .required('Booking date is required.'),
    contractCancelDate: yup.date()
      .nullable()
      .when('contractStartDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('contractStartDate'),
            'Cancel date cannot be before start date.'),
      }),
    contractCRMID: yup.string(),
    contractExternalLink: yup.string()
      .url('Please provide valid URL.'),
    contractNote: yup.string(),
  })
    .required();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty, dirtyFields },
    reset,
    trigger,
    setValue,
  } = useForm<BulkImportContractsFormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const triggerTouched = (field: any) => ((dirtyFields as Record<string, boolean | undefined>)[field]) && trigger(field);

  const prepareBulkImportRowPayload = (data: BulkImportContractsFormFields): BulkImportContractsRow => {
    return {
      id: selectedRow?.id,
      contractName: {
        providedValue: data.contractName,
        errorCode: null,
      },
      contractCustomer: {
        providedValue: data.contractCustomer[0].name,
        matchedValue: data.contractCustomer[0],
        errorCode: null,
      },
      contractBookingDate: {
        providedValue: data.contractBookingDate ? formatDateToISO(data.contractBookingDate!) : null,
        matchedValue: data.contractBookingDate ? formatDateToISO(data.contractBookingDate!) : null,
        errorCode: null,
      },
      contractStartDate: {
        providedValue: formatDateToISO(data.contractStartDate!),
        matchedValue: formatDateToISO(data.contractStartDate!),
        errorCode: null,
      },
      contractEndDate: {
        providedValue: formatDateToISO(data.contractEndDate!),
        matchedValue: formatDateToISO(data.contractEndDate!),
        errorCode: null,
      },
      contractCancelDate: {
        providedValue: data.contractCancelDate ? formatDateToISO(data.contractCancelDate!) : null,
        matchedValue: data.contractCancelDate ? formatDateToISO(data.contractCancelDate!) : null,
        errorCode: null,
      },
      contractCRMID: {
        providedValue: data.contractCRMID,
        errorCode: null,
      },
      contractTags: {
        providedValue: data.contractTags.map((tag) => tag.name)
          .toString(),
        matchedValue: data.contractTags,
        errorCode: null,
      },
      contractExternalLink: {
        providedValue: data.contractExternalLink,
        errorCode: null,
      },
      contractNote: {
        providedValue: data.contractNote,
        errorCode: null,
      },
      contractPrevious: {
        providedValue: data.contractPrevious,
        errorCode: null,
      },
      contractErrors: null,
      newContract: selectedRow.newContract,
      relatedRowsIds: selectedRow.relatedRowsIds,

      contractLineName: selectedRow.contractLineName,
      contractLineProduct: selectedRow.contractLineProduct,
      contractLineAmount: selectedRow.contractLineAmount,
      contractLineBookingDate: selectedRow.contractLineBookingDate,
      contractLineStartDate: selectedRow.contractLineStartDate,
      contractLineEndDate: selectedRow.contractLineEndDate,
      contractLineCancelDate: selectedRow.contractLineCancelDate,
      contractLineRevRecStartDate: selectedRow.contractLineRevRecStartDate,
      contractLineRevRecEndDate: selectedRow.contractLineRevRecEndDate,
      contractLineTags: selectedRow.contractLineTags,
      contractLineExternalLink: selectedRow.contractLineExternalLink,
      contractLineNote: selectedRow.contractLineNote,
      contractLineCRMID: selectedRow.contractLineCRMID,
      contractLineContractBasedMRRCalc: selectedRow.contractLineContractBasedMRRCalc,
      contractLineErrors: selectedRow.contractLineErrors,
      newContractLine: selectedRow.newContractLine,
    };
  };

  const handleAddTag = ({ name }: { name: string }) => {
    dispatch(formStateActions.setPredefinedData({
      name,
      type: TagType.CONTRACT,
    }));
    onAddTag && onAddTag();
  };

  const handleAddCustomer = ({ name }: { name: string }) => {
    dispatch(formStateActions.setPredefinedData({ name }));
    onAddCustomer();
  };

  const handleSaveAndClose = handleSubmit((data) => {
    const bulkImportRow = prepareBulkImportRowPayload(data);

    dispatch(bulkImportActions.updateContractsRow({
      id,
      data: bulkImportRow,
    }));
    onClose();
  });

  const handleSave = handleSubmit((data) => {
    const bulkImportRow = prepareBulkImportRowPayload(data);

    dispatch(bulkImportActions.updateContractsRow({
      id,
      data: bulkImportRow,
    }));
  });

  useEffect(() => {
    const customer = customers?.find((cust) => cust.id === selectedRow.contractCustomer.matchedValue?.id);

    reset({
      contractName: selectedRow.contractName.providedValue || '',
      contractCustomer: customer ? [customer] : [],
      contractBookingDate: isoToDate(selectedRow.contractBookingDate?.matchedValue),
      contractStartDate: isoToDate(selectedRow.contractStartDate?.matchedValue),
      contractEndDate: isoToDate(selectedRow.contractEndDate?.matchedValue),
      contractCancelDate: isoToDate(selectedRow.contractCancelDate?.matchedValue),
      contractTags: selectedRow.contractTags.matchedValue || [],
      contractCRMID: selectedRow.contractCRMID?.providedValue || '',
      contractExternalLink: selectedRow.contractExternalLink?.providedValue || '',
      contractNote: selectedRow.contractNote?.providedValue || '',
      contractPrevious: selectedRow.contractPrevious?.providedValue || '',
    });

    setIsErrorOnTags(!!selectedRow.contractTags.providedValue && !!selectedRow.contractTags.errorCode);

    trigger();
  }, [customers, reset, selectedRow, trigger, tagOptions]);

  return (
    <>
      <header>
        <ButtonsContainer>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={() => actionBlocker(onClose, isDirty)}
            pushRight
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <StyledFormSectionLabel>
          <span>Edit Contract</span>
        </StyledFormSectionLabel>
      </header>
      <main>
        <StyledFormContainer>
          <InputBasic
            isRequired
            labelText="Contract Name"
            placeholder="Name your contract"
            {...register('contractName')}
            error={errors.contractName?.message}
          />
          <Controller
            name="contractCustomer"
            control={control}
            render={({ field }) =>
              <Dropdown
                labelText="Customer"
                labelField="name"
                valueField="id"
                searchBy="name"
                placeholder="Select Customer"
                options={customers || []}
                values={getValues('contractCustomer')}
                {...field}
                error={errors.contractCustomer?.message}
                isRequired
                create
                onCreateNew={handleAddCustomer}
              />
            }
          />
          <Controller
            name="contractBookingDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Booking Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('contractStartDate');
                  triggerTouched('contractEndDate');
                  triggerTouched('contractCancelDate');
                }}
                error={errors.contractBookingDate?.message}
                isRequired
                tooltip="Contract booking date is used to indicate when this contract was initially booked."
              />
            }
          />
          <Controller
            name="contractStartDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <div>
                <Datepicker
                  labelText="Start Date"
                  placeholder="Select Date"
                  {...field}
                  onChange={(date) => {
                    field.onChange(date);
                    triggerTouched('contractBookingDate');
                    triggerTouched('contractEndDate');
                    triggerTouched('contractCancelDate');
                  }}
                  error={errors.contractStartDate?.message}
                  isRequired
                />
              </div>
            }
          />
          <Controller
            name="contractEndDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="End Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('contractBookingDate');
                  triggerTouched('contractStartDate');
                  triggerTouched('contractCancelDate');
                }}
                error={errors.contractEndDate?.message}
                tooltip="This contrat will remain active until it is renewed or cancelled."
                isRequired
              />
            }
          />
          <Controller
            name="contractCancelDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Cancel Date"
                placeholder="Cancel Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('contractStartDate');
                  triggerTouched('contractBookingDate');
                  triggerTouched('contractEndDate');
                }}
                error={errors.contractCancelDate?.message}
                tooltip="Entering a cancel date will terminate the contract upon that date. You can add it now or later."
                isOptional
              />
            }
          />
          <InputBasic
            isOptional
            labelText="Previous Contract(s)"
            placeholder="Add previous contract name(s)"
            tooltip="Change or fix previous contract(s) name(s)."
            multiline
            {...register('contractPrevious')}
            error={errors.contractPrevious?.message}
          />
          <StyledExpandButton
            variant="borderless"
            size="large"
            type="button"
            onClick={() => setIsOptionsExpanded(!isOptionsExpanded)}
            isOpen={isOptionsExpanded}
          >
            Additional Options
            <ChevronDown />
          </StyledExpandButton>
          <StyledAdditionalOptionsContainer isOpen={isOptionsExpanded}>
            <Controller
              name="contractTags"
              control={control}
              render={({ field }) =>
                <Dropdown
                  labelText="Tags"
                  labelField="name"
                  valueField="id"
                  searchBy="name"
                  options={tagOptions}
                  placeholder="Select Tags"
                  entityName="tag"
                  values={getValues('contractTags')}
                  {...field}
                  isOptional
                  clearable
                  multi
                  error={isErrorOnTags ? 'Wrong tags provided' : undefined}
                  tooltip="Categorize this contract with a contract tag(s)."
                  onDropdownOpen={() => setIsErrorOnTags(false)}
                  onClearAll={() => setIsErrorOnTags(false)}
                  create={!!onAddTag}
                  onCreateNew={handleAddTag}
                />
              }
            />
            <InputBasic
              isOptional
              labelText="CRM ID"
              placeholder="Paste CRM identifier"
              tooltip="Paste in an ID from your CRM to help identify this contract."
              {...register('contractCRMID')}
              error={errors.contractCRMID?.message}
            />
            <InputBasic
              isOptional
              labelText="External Link"
              placeholder="Provide external link"
              {...register('contractExternalLink')}
              error={errors.contractExternalLink?.message}
              onBlur={(e) => {
                const fieldValue = e.target.value;

                if (fieldValue && !fieldValue.startsWith('http')) {
                  setValue('contractExternalLink', 'http://' + fieldValue, { shouldValidate: true });
                }
              }}
            />
            <InputBasic
              isOptional
              labelText="Note"
              placeholder="Provide note"
              {...register('contractNote')}
              error={errors.contractNote?.message}
            />
          </StyledAdditionalOptionsContainer>
        </StyledFormContainer>
      </main>
      <footer>
        <StyledButtonContainer pushRight>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={handleSave}
          >
            SAVE
          </Button>
          <Button
            type="submit"
            onClick={handleSaveAndClose}
          >
            SAVE & CLOSE
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};
