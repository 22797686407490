import styled, { css } from "styled-components";
import { theme } from "theme/theme";
import { RefreshIcon } from "assets/icons";

interface StyledInputProps {
  withError?: boolean;
  condensed?: boolean;
  height?: number;
}

interface StyledInputWrapperProps {
  withError?: boolean;
  disabled?: boolean;
}

export const StyledInput = styled.input<StyledInputProps>`
  outline: none;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  border: 0px;
  background: none;
  width: 100%;
  padding: ${props => props.condensed ? '7px 12px' : '12px'};

  ::placeholder,
  ::-webkit-input-placeholder,
  :-ms-input-placeholder {
    color: ${theme.colors.textDisabled};
  }
`;

export const StyledTextarea = styled.textarea<StyledInputProps>`
  position: relative;
  outline: none;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  border: 0px;
  background: none;
  width: 100%;
  padding: ${props => props.condensed ? '7px 12px' : '12px'};
  height: ${props => props.height || 94}px;
  min-height: 48px;
  resize: vertical;
  background: url('/expand.png') no-repeat;
  background-position: bottom right;
  border-radius: 6px;

  ::placeholder,
  ::-webkit-input-placeholder,
  :-ms-input-placeholder {
    color: ${theme.colors.textDisabled};
  }

  ::-webkit-resizer {
    background: url('/expand.png') no-repeat;
    background-position: bottom right;
  }
`;

export const StyledInputWrapper = styled.div<StyledInputWrapperProps>`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 8px;
  border: 1px solid ${props => props.withError ? theme.colors.error : theme.colors.fieldBorder};
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${props => props.disabled ? theme.colors.textDisabled :  theme.colors.textDark};
  background-color: ${props => props.disabled ? theme.colors.secondaryBackground : 'inherit'};
  margin-bottom: 1px;

  ${props => props.disabled && css`
    pointer-events: none;
  `};

  ${props => props.withError && css`
    color: ${theme.colors.error};
  `};

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    border: 2px solid ${theme.colors.transparent};
    border-radius: 9px;
    pointer-events: none;
    transition: all 200ms ease;
  }

  :focus,
  :focus-visible,
  :active,
  :hover,
  :focus-within {
    &::before {
      border: 2px solid ${props => props.withError ? theme.colors.error : theme.colors.primary};
    }
    border: 1px solid ${props => props.withError ? theme.colors.error : theme.colors.primary};
  }
`;

interface StyledContainerProps {
  isHidden?: boolean;
}

export const StyledContainer = styled.div<StyledContainerProps>`
  display: ${props => props.isHidden ? 'none' : 'flex'};
  flex-direction: column;
  padding: 0 1px;
`;

export const LoadingIcon = styled(RefreshIcon)`
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-359deg);
    }
  }

  color: ${theme.colors.textDisabled};
  animation: rotation 2s infinite linear;
  margin: 0 12px;
`;

export const Suffix = styled.span`
  margin: 0 8px;
`;
