import React, {
  useEffect,
  useState,
} from 'react';
import {
  StyledChildrenContainer,
  StyledConnected,
  StyledIntegrationsItem,
  StyledIntegrationsTrigger,
  StyledPushRight,
} from './styled';
import { CheckCircleIcon } from 'assets/icons';

interface Props {
  title: string;
  description: string;
  icon?: JSX.Element;
  children?: string | JSX.Element | never[];
  ctaIcon?: JSX.Element;
  ctaAction?: () => void;
  chipText?: string;
  disabled?: boolean;
  isInitiallyOpen?: boolean;
  isActive?: boolean;
}

export const IntegrationsItem = ({
  title,
  description,
  icon,
  ctaIcon,
  ctaAction,
  children,
  chipText,
  disabled,
  isInitiallyOpen,
  isActive,
}: Props) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);

  const handleClick = () => {
    if (disabled) return;
    ctaAction
      ? ctaAction()
      : setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(isInitiallyOpen);
  }, [isInitiallyOpen]);

  return (
    <StyledIntegrationsItem>
      <StyledIntegrationsTrigger
        disabled={disabled}
        onClick={handleClick}
      >
        {icon && (
          <div>
            {icon}
          </div>
        )}
        <div>
          <span>{title} {chipText && <ins>{chipText}</ins>}</span>
          {description}
        </div>
        <StyledPushRight>
          {isActive && <StyledConnected><CheckCircleIcon />Active Integration</StyledConnected>}
          {ctaAction && ctaIcon && (
            <>{ctaIcon}</>
          )}
        </StyledPushRight>
      </StyledIntegrationsTrigger>
      <StyledChildrenContainer isOpen={isOpen && !disabled}>
        <div>
          {children}
        </div>
      </StyledChildrenContainer>
    </StyledIntegrationsItem>
  );
};
