import styled, { css } from "styled-components";
import { theme } from "theme/theme";
import {
  Dropdown,
  StyledDropdownWrapper,
} from "components/common/Dropdown";

export const StyledHeader = styled.div`
  display: flex; 
  align-items: center;
  justify-content: space-between; 
  background-color: ${theme.colors.darkBackground};
  color: ${theme.colors.white};

  & > ${StyledDropdownWrapper} {
    margin-right: 12px;
  }
`;

export const EnvIndicator = styled.div`
  font-size: 12px;
  background-color: ${theme.colors.tertiaryBleach};
  color: ${theme.colors.textDark};
  padding: 6px 12px;
  border-radius: 8px;
`;

interface HeaderSectionProps {
  center?: boolean;
  pushRight?: boolean;
  $width?: number;
}

export const HeaderSection = styled.div<HeaderSectionProps>`
  padding: 0 16px;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 16px;

  ${props => props.pushRight && css`
    margin-left: auto;
  `}
`;

export const HeaderLogoSection = styled(HeaderSection)`
  padding: 0;
  justify-content: center;
  width: 50px;
  border-right: 1px solid ${theme.colors.sidebarBorder};
  flex-shrink: 0;
  cursor: pointer;
`;

export const StyledHeaderDropdown = styled(Dropdown)`
  &.react-dropdown-select {
    min-width: 240px;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 18px;
    background-color: ${theme.colors.secondaryBackground};

    :focus,
    :focus-visible,
    :focus-within,
    :hover {
      &::before {
        border-radius: 5px;
      }
    }
  }

  .react-dropdown-select-dropdown {
    border-radius: 4px;
    background-color: ${theme.colors.mainBackground};
  }

  .react-dropdown-select-option {
    border-radius: 6px;
    background-color: ${theme.colors.primary};
  }

  .react-dropdown-select-input {
    margin-left: 0;

    &::placeholder {
      font-size: 12px;
    }
  }

  .react-dropdown-select-dropdown-add-new {
    color: ${theme.colors.primary};
  }
`;

export const StyledHeaderDropdownItem = styled.div<{ isAddCompany?: boolean, selected?: boolean }>`
  padding: 12px 16px;
  width: inherit;
  border-bottom: none;
  color: ${props => props.isAddCompany ? theme.colors.primary : 'inherit'};
  background-color: ${props => props.selected ? theme.colors.secondaryBackground : 'transparent'};
  transition: all 100ms ease;

  ${props => props.isAddCompany && css`
    display: flex;
    align-items: center;
    gap: 4px;
  `}

  :hover {
    background-color: ${theme.colors.primaryBleach};
  }
  
  &-selected {
    color:  ${theme.colors.primary} !important;
    background: ${theme.colors.primaryBleach} !important;
    border-bottom: none !important;
  }

  svg {
    margin-left: -5px;
    width: 20px;
    height: 20px;
  }
`;

export const StyledHeaderDropdownIns = styled.ins`
  text-decoration: none;
  border: 1px solid ${theme.colors.fieldBorder};
  border-radius: 2px;
  padding: 0px 3px;
  margin-left: 8px;
  font-size: 9px;
  text-transform: uppercase;
  color: ${theme.colors.textDisabled};
  vertical-align: top;
`;

export const UnreadMessagesIndicator = styled.div`
  position: absolute;
  right: -7px;
  top: -2px;
  font-size: 10px;
  line-height: 16px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${theme.colors.error};
`;
