import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useForm,
  Controller,
} from 'react-hook-form';
import * as yup from "yup";
import {
  ChevronDown,
  CloseIcon,
  DeleteIcon,
} from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import {
  StyledButtonContainer,
  StyledFormSectionLabel,
  StyledFormContainer,
  StyledAdditionalOptionsContainer,
  StyledExpandButton,
} from 'components/common/Forms/styled';
import { Button } from 'components/common/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { Datepicker } from 'components/common/Datepicker';
import { Dropdown } from 'components/common/Dropdown';
import { InputBasic } from 'components/common/Input';
import {
  formatDateStringToDisplay,
  formatDateToISO,
  isoToDate,
} from 'utils/dates';
import {
  bulkImportContractsGetRowByAgGridId,
  bulkImportContractsResultsSelector,
} from 'store/selectors/bulkImport';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import { bulkImportActions } from 'store/slices/bulkImport';
import { actionBlocker } from 'utils/actionBlocker';
import { useGetAllTagsQuery } from 'store/services/tags';
import { TagType } from 'interfaces/tags';
import { tagsSortFn } from 'utils/tags';
import { formStateActions } from 'store/slices/formState';
import {
  BulkImportContractLinesFormFields,
  BulkImportContractsRow,
} from 'interfaces/bulkImportContracts';
import { useGetContractLinesByContractIdsQuery } from 'store/services/contractLines';
import { useGetAllProductsQuery } from 'store/services/products';
import { NumberInput } from 'components/common/NumberInput';
import { Tooltip } from 'components/common/Tooltip';
import { Contract } from 'interfaces/contracts';
import { useGetContractQuery } from 'store/services/contracts';
import { CheckboxInput } from 'components/common/CheckboxInput';

interface Props {
  id: string;
  onClose: () => void;
  onAddTag?: () => void;
  onAddProduct: () => void;
  onDelete: () => void;
}

export const BulkImportContractsEditContractLine = ({
  id,
  onClose,
  onAddTag,
  onAddProduct,
  onDelete,
}: Props) => {
  const dispatch = useAppDispatch();
  const selectedRow = useAppSelector(bulkImportContractsGetRowByAgGridId(id))!;
  const rows = useAppSelector(bulkImportContractsResultsSelector).rows;
  const [isOptionsExpanded, setIsOptionsExpanded] = useState(false);
  const [isErrorOnTags, setIsErrorOnTags] = useState(!!selectedRow.contractLineTags.providedValue && !!selectedRow.contractLineTags.errorCode);
  const { data: matchedContract } = useGetContractQuery(selectedRow.contractName.matchedValue?.id!, { skip: !selectedRow.contractName.matchedValue?.id });

  type SelectedContract = Pick<Contract, 'startDate' | 'bookingDate' | 'cancelDate' | 'endDate' | 'id'> | undefined;
  const getSelectedContract = (): SelectedContract => {
    const matchedContractWithinFile = rows.find((row) => row.contractName.providedValue === selectedRow.contractName.providedValue && row.newContract);

    if (matchedContractWithinFile) {
      return {
        id: '',
        bookingDate: matchedContractWithinFile.contractBookingDate.matchedValue!,
        startDate: matchedContractWithinFile.contractStartDate.matchedValue!,
        endDate: matchedContractWithinFile.contractEndDate.matchedValue!,
        cancelDate: matchedContractWithinFile.contractCancelDate.matchedValue!,
      };
    } else if (selectedRow.contractName.matchedValue?.id) {
      return matchedContract;
    }
  };

  const selectedContract = getSelectedContract();

  const {
    data: contractLines,
  } = useGetContractLinesByContractIdsQuery(selectedContract?.id!, {
    skip: !selectedContract?.id,
  });

  const contractLineNamesInSystem = contractLines
    ?.filter((line) => !line.deleted)
    ?.map((line) => line.name) || [];

  const contractLineNamesInFile = rows
    .filter((line) => line.contractName.providedValue === selectedRow.contractName.providedValue && selectedRow.id !== line.id)
    ?.map((line) => line.contractLineName.providedValue) || [];

  const contractLineNames = [...new Set([...contractLineNamesInSystem, ...contractLineNamesInFile])];

  const {
    data: productsAll,
  } = useGetAllProductsQuery();
  const products = useMemo(() => productsAll?.filter((prod) => !prod.deleted), [productsAll]);

  const {
    data: tags,
  } = useGetAllTagsQuery();

  const tagOptions = useMemo(() => tags
    ?.filter((tag) => !tag.deleted && tag.type === TagType.CONTRACT)
    ?.sort(tagsSortFn) || [],
    [tags]);

  const defaultValues = useMemo(() => ({
    contractLineName: '',
    contractLineProduct: [],
    contractLineAmount: '',
    contractLineBookingDate: undefined,
    contractLineStartDate: undefined,
    contractLineEndDate: undefined,
    contractLineCancelDate: undefined,
    contractLineRevRecStartDate: undefined,
    contractLineRevRecEndDate: undefined,
    contractLineTags: [],
    contractLineExternalLink: '',
    contractLineNote: '',
    contractLineCRMID: '',
    contractLineContractBasedMRRCalc: false,
  }), []);

  const cannotBeBeforeContractStartDateFn = (selectedContract?: SelectedContract) => (val: Date | null | undefined) => {
    if (val && selectedContract?.startDate) {
      return val.getTime() >= isoToDate(selectedContract?.startDate)!
        .getTime();
    } else {
      return true;
    }
  };

  const cannotBeBeforeContractBookingDateFn = (selectedContract?: SelectedContract) => (val: Date | null | undefined) => {
    if (val && selectedContract?.bookingDate) {
      return val.getTime() >= isoToDate(selectedContract?.bookingDate)!
        .getTime();
    } else {
      return true;
    }
  };

  const schema = yup.object({
    contractLineProduct: yup.array()
      .of(
        yup.object({
          id: yup.string(),
          name: yup.string(),
        }))
      .min(1, 'Product is required.'),
    contractLineName: yup.string()
      .required('Contract line name is required.')
      .test(
        'isUnique',
        'Contract line name must be unique within contract.',
        (val) => !(contractLineNames?.includes(val || '')),
      ),
    contractLineAmount: yup.string()
      .required('Amount is required.'),
    contractLineBookingDate: yup.date()
      .nullable()
      .test(
        'cannotBeBeforeContractBookingDate',
        `Booking date cannot be before contract's booking date (${formatDateStringToDisplay(selectedContract?.bookingDate)})`,
        cannotBeBeforeContractBookingDateFn(selectedContract),
      )
      .required('Booking date is required.'),
    contractLineStartDate: yup.date()
      .nullable()
      .test(
        'cannotBeBeforeContractStartDate',
        `Start date cannot be before contract's start date (${formatDateStringToDisplay(selectedContract?.startDate)})`,
        cannotBeBeforeContractStartDateFn(selectedContract),
      )
      .required('Start date is required.'),
    contractLineEndDate: yup.date()
      .nullable()
      .when('contractLineStartDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('contractLineStartDate'),
            'End date cannot be before start date.'),
      })
      .test(
        'cannotBeBeforeContractStartDate',
        `End date cannot be before contract's start date (${formatDateStringToDisplay(selectedContract?.startDate)})`,
        cannotBeBeforeContractStartDateFn(selectedContract),
      )
      .test(
        'cannotBeAfterContractEndDate',
        `End date cannot be after contract's end date (${formatDateStringToDisplay(selectedContract?.endDate!)})`,
        (val) => {
          if (val && selectedContract?.endDate) {
            return val.getTime() <= isoToDate(selectedContract.endDate)!
              .getTime();
          } else {
            return true;
          }
        },
      )
      .required('End date is required.'),
    contractLineCancelDate: yup.date()
      .nullable()
      .when('contractLineStartDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('contractLineStartDate'),
            'Cancel date cannot be before start date.'),
      })
      .test(
        'cannotBeBeforeContractStartDate',
        `Cancel date cannot be before contract's start date (${formatDateStringToDisplay(selectedContract?.startDate)})`,
        cannotBeBeforeContractStartDateFn(selectedContract),
      )
      .test(
        'cannotBeAfterContractCancelDate',
        `Cancel date cannot be after contract's cancel date (${formatDateStringToDisplay(selectedContract?.cancelDate!)})`,
        (val) => {
          if (val && selectedContract?.cancelDate) {
            return val.getTime() <= isoToDate(selectedContract.cancelDate)!
              .getTime();
          } else {
            return true;
          }
        },
      ),
    contractLineRevRecStartDate: yup.date()
      .nullable()
      .when('contractLineStartDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('contractLineStartDate'),
            'Revenue recognitionstart date cannot be before line item start date.'),
      })
      .test(
        'cannotBeBeforeContractStartDate',
        `Revenue recognitionstart date cannot be before contract's start date (${formatDateStringToDisplay(selectedContract?.startDate)})`,
        cannotBeBeforeContractStartDateFn(selectedContract),
      ),
    contractLineRevRecEndDate: yup.date()
      .nullable()
      .when('contractLineRevRecStartDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('contractLineStartDate'),
            'Revenue recognitionend date cannot be before Revenue recognitionstart date.'),
      })
      .test(
        'cannotBeBeforeContractStartDate',
        `Revenue recognitionend date cannot be before contract's start date (${formatDateStringToDisplay(selectedContract?.startDate)})`,
        cannotBeBeforeContractStartDateFn(selectedContract),
      ),
    contractLineCRMID: yup.string(),
    contractLineContractBasedMRRCalc: yup.boolean(),
    contractLineExternalLink: yup.string()
      .url('Please provide valid URL.'),
    contractLineNote: yup.string(),
  })
    .required();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty, dirtyFields },
    reset,
    trigger,
    setValue,
  } = useForm<BulkImportContractLinesFormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const triggerTouched = (field: any) => ((dirtyFields as Record<string, boolean | undefined>)[field]) && trigger(field);

  const prepareBulkImportRowPayload = (data: BulkImportContractLinesFormFields): BulkImportContractsRow => {
    return {
      id: selectedRow?.id,
      contractName: selectedRow.contractName,
      contractCustomer: selectedRow.contractCustomer,
      contractBookingDate: selectedRow.contractBookingDate,
      contractStartDate: selectedRow.contractStartDate,
      contractEndDate: selectedRow.contractEndDate,
      contractCancelDate: selectedRow.contractCancelDate,
      contractCRMID: selectedRow.contractCRMID,
      contractTags: selectedRow.contractTags,
      contractExternalLink: selectedRow.contractExternalLink,
      contractNote: selectedRow.contractNote,
      contractPrevious: selectedRow.contractPrevious,
      contractErrors: selectedRow.contractErrors,
      newContract: selectedRow.newContract,
      relatedRowsIds: selectedRow.relatedRowsIds,

      contractLineName: {
        providedValue: data.contractLineName,
        errorCode: null,
      },
      contractLineProduct: {
        providedValue: data.contractLineProduct[0].name,
        matchedValue: data.contractLineProduct[0],
        errorCode: null,
      },
      contractLineAmount: {
        providedValue: data.contractLineAmount ? data.contractLineAmount : null,
        matchedValue: data.contractLineAmount ? Number(data.contractLineAmount) : null,
        errorCode: null,
      },
      contractLineBookingDate: {
        providedValue: data.contractLineBookingDate ? formatDateToISO(data.contractLineBookingDate!) : null,
        matchedValue: data.contractLineBookingDate ? formatDateToISO(data.contractLineBookingDate!) : null,
        errorCode: null,
      },
      contractLineStartDate: {
        providedValue: data.contractLineStartDate ? formatDateToISO(data.contractLineStartDate!) : null,
        matchedValue: data.contractLineStartDate ? formatDateToISO(data.contractLineStartDate!) : null,
        errorCode: null,
      },
      contractLineEndDate: {
        providedValue: data.contractLineEndDate ? formatDateToISO(data.contractLineEndDate!) : null,
        matchedValue: data.contractLineEndDate ? formatDateToISO(data.contractLineEndDate!) : null,
        errorCode: null,
      },
      contractLineCancelDate: {
        providedValue: data.contractLineCancelDate ? formatDateToISO(data.contractLineCancelDate!) : null,
        matchedValue: data.contractLineCancelDate ? formatDateToISO(data.contractLineCancelDate!) : null,
        errorCode: null,
      },
      contractLineRevRecStartDate: {
        providedValue: data.contractLineRevRecStartDate ? formatDateToISO(data.contractLineRevRecStartDate) : null,
        matchedValue: data.contractLineRevRecStartDate ? formatDateToISO(data.contractLineRevRecStartDate) : null,
        errorCode: null,
      },
      contractLineRevRecEndDate: {
        providedValue: data.contractLineRevRecEndDate ? formatDateToISO(data.contractLineRevRecEndDate) : null,
        matchedValue: data.contractLineRevRecEndDate ? formatDateToISO(data.contractLineRevRecEndDate) : null,
        errorCode: null,
      },
      contractLineTags: {
        providedValue: data.contractLineTags.map((tag) => tag.name)
          .toString(),
        matchedValue: data.contractLineTags,
        errorCode: null,
      },
      contractLineExternalLink: {
        providedValue: data.contractLineExternalLink,
        errorCode: null,
      },
      contractLineNote: {
        providedValue: data.contractLineNote,
        errorCode: null,
      },
      contractLineCRMID: {
        providedValue: data.contractLineCRMID,
        errorCode: null,
      },
      contractLineContractBasedMRRCalc: {
        providedValue: data.contractLineContractBasedMRRCalc.toString(),
        errorCode: null,
      },
      contractLineErrors: null,
      newContractLine: selectedRow.newContractLine,
    };
  };

  const handleAddTag = ({ name }: { name: string }) => {
    dispatch(formStateActions.setPredefinedData({
      name,
      type: TagType.CONTRACT,
    }));
    onAddTag && onAddTag();
  };

  const handleAddProduct = ({ name }: { name: string }) => {
    dispatch(formStateActions.setPredefinedData({ name }));
    onAddProduct();
  };

  const handleSaveAndClose = handleSubmit((data) => {
    const bulkImportRow = prepareBulkImportRowPayload(data);

    dispatch(bulkImportActions.updateContractsRow({
      id,
      data: bulkImportRow,
    }));
    onClose();
  });

  const handleSave = handleSubmit((data) => {
    const bulkImportRow = prepareBulkImportRowPayload(data);

    dispatch(bulkImportActions.updateContractsRow({
      id,
      data: bulkImportRow,
    }));
  });

  useEffect(() => {
    const product = products?.find((prod) => prod.id === selectedRow.contractLineProduct.matchedValue?.id);

    reset({
      contractLineName: selectedRow.contractLineName.providedValue || '',
      contractLineProduct: product ? [product] : [],
      contractLineAmount: `${selectedRow.contractLineAmount?.matchedValue !== null ? selectedRow.contractLineAmount?.matchedValue : ''}`,
      contractLineBookingDate: isoToDate(selectedRow.contractLineBookingDate?.matchedValue),
      contractLineStartDate: isoToDate(selectedRow.contractLineStartDate?.matchedValue),
      contractLineEndDate: isoToDate(selectedRow.contractLineEndDate?.matchedValue),
      contractLineCancelDate: isoToDate(selectedRow.contractLineCancelDate?.matchedValue),
      contractLineRevRecStartDate: isoToDate(selectedRow.contractLineRevRecStartDate?.matchedValue),
      contractLineRevRecEndDate: isoToDate(selectedRow.contractLineRevRecEndDate?.matchedValue),
      contractLineTags: selectedRow.contractLineTags.matchedValue || [],
      contractLineExternalLink: selectedRow.contractLineExternalLink?.providedValue || '',
      contractLineNote: selectedRow.contractLineNote?.providedValue || '',
      contractLineCRMID: selectedRow.contractLineCRMID?.providedValue || '',
      contractLineContractBasedMRRCalc: selectedRow.contractLineContractBasedMRRCalc?.providedValue === 'true',
    });

    setIsErrorOnTags(!!selectedRow.contractLineTags.providedValue && !!selectedRow.contractLineTags.errorCode);

    trigger();
  }, [products, reset, selectedRow, trigger, tagOptions]);

  return (
    <>
      <header>
        <ButtonsContainer>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={() => actionBlocker(onClose, isDirty)}
            pushRight
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <StyledFormSectionLabel>
          <span>Edit Contract Line</span>
        </StyledFormSectionLabel>
        <ButtonsContainer paddingBottom={20}>
          <Tooltip title="Delete">
            <Button
              aria-label="Delete"
              variant="icon"
              size="large"
              onClick={onDelete}
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        </ButtonsContainer>
      </header>
      <main>
        <StyledFormContainer>
          <InputBasic
            disabled
            labelText="Contract"
            name="contractName"
            value={selectedRow.contractName.providedValue || ''}
            tooltip="Name of the contract this line will be assigned to."
          />
          <InputBasic
            isRequired
            labelText="Contract Line Name"
            placeholder="Name your contract line"
            {...register('contractLineName')}
            error={errors.contractLineName?.message}
          />
          <Controller
            name="contractLineProduct"
            control={control}
            render={({ field }) =>
              <Dropdown
                labelText="Product"
                labelField="name"
                valueField="id"
                searchBy="name"
                placeholder="Select Product"
                options={products || []}
                values={getValues('contractLineProduct')}
                {...field}
                error={errors.contractLineProduct?.message}
                isRequired
                create
                onCreateNew={handleAddProduct}
              />
            }
          />
          <Controller
            name="contractLineAmount"
            control={control}
            render={({ field }) =>
              <NumberInput
                labelText="Amount"
                {...field}
                onChange={field.onChange}
                placeholder="Enter Amount"
                error={errors.contractLineAmount?.message}
                tooltip="Enter the total value of the contract line."
                isRequired
              />
            }
          />
          <Controller
            name="contractLineBookingDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Booking Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('contractLineStartDate');
                  triggerTouched('contractLineEndDate');
                  triggerTouched('contractLineCancelDate');
                }}
                error={errors.contractLineBookingDate?.message}
                isRequired
                tooltip="Booking date is used to indicate when this contract line was initially booked."
              />
            }
          />
          <Controller
            name="contractLineStartDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <div>
                <Datepicker
                  labelText="Start Date"
                  placeholder="Select Date"
                  {...field}
                  onChange={(date) => {
                    field.onChange(date);
                    triggerTouched('contractLineBookingDate');
                    triggerTouched('contractLineEndDate');
                    triggerTouched('contractLineCancelDate');
                    triggerTouched('contractLineRevRecStartDate');
                    triggerTouched('contractLineRevRecEndDate');
                  }}
                  error={errors.contractLineStartDate?.message}
                  isRequired
                />
              </div>
            }
          />
          <Controller
            name="contractLineEndDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="End Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('contractLineBookingDate');
                  triggerTouched('contractLineStartDate');
                  triggerTouched('contractLineCancelDate');
                }}
                error={errors.contractLineEndDate?.message}
                tooltip="This contrat will remain active until it is renewed or cancelled."
                isRequired
              />
            }
          />
          <Controller
            name="contractLineCancelDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Cancel Date"
                placeholder="Cancel Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('contractLineStartDate');
                  triggerTouched('contractLineBookingDate');
                  triggerTouched('contractLineEndDate');
                }}
                error={errors.contractLineCancelDate?.message}
                tooltip="Entering a cancel date will terminate the contract upon that date. You can add it now or later."
                isOptional
              />
            }
          />
          <StyledFormSectionLabel>
            <span>
              Revenue Recognition
            </span>
          </StyledFormSectionLabel>
          <Controller
            name="contractLineRevRecStartDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Recognition Start Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('contractLineRevRecEndDate');
                }}
                error={errors.contractLineRevRecStartDate?.message}
                tooltip="Enter revenue recognition start date. You can add it now or later."
                isOptional
              />
            }
          />
          <Controller
            name="contractLineRevRecEndDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Recognition End Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                }}
                error={errors.contractLineRevRecEndDate?.message}
                tooltip="Enter revenue recognition end date. You can add it now or later."
                isOptional
              />
            }
          />
          <StyledExpandButton
            variant="borderless"
            size="large"
            type="button"
            onClick={() => setIsOptionsExpanded(!isOptionsExpanded)}
            isOpen={isOptionsExpanded}
          >
            Additional Options
            <ChevronDown />
          </StyledExpandButton>
          <StyledAdditionalOptionsContainer isOpen={isOptionsExpanded}>
            <Controller
              name="contractLineTags"
              control={control}
              render={({ field }) =>
                <Dropdown
                  labelText="Tags"
                  labelField="name"
                  valueField="id"
                  searchBy="name"
                  options={tagOptions}
                  placeholder="Select Tags"
                  entityName="tag"
                  values={getValues('contractLineTags')}
                  {...field}
                  isOptional
                  clearable
                  multi
                  error={isErrorOnTags ? 'Wrong tags provided' : undefined}
                  tooltip="Categorize this contract with a contract tag(s)."
                  onDropdownOpen={() => setIsErrorOnTags(false)}
                  onClearAll={() => setIsErrorOnTags(false)}
                  create={!!onAddTag}
                  onCreateNew={handleAddTag}
                />
              }
            />
            <InputBasic
              isOptional
              labelText="CRM ID"
              placeholder="Paste CRM identifier"
              tooltip="Paste in an ID from your CRM to help identify this contract."
              {...register('contractLineCRMID')}
              error={errors.contractLineCRMID?.message}
            />
            <InputBasic
              isOptional
              labelText="External Link"
              placeholder="Provide external link"
              {...register('contractLineExternalLink')}
              error={errors.contractLineExternalLink?.message}
              onBlur={(e) => {
                const fieldValue = e.target.value;

                if (fieldValue && !fieldValue.startsWith('http')) {
                  setValue('contractLineExternalLink', 'http://' + fieldValue, { shouldValidate: true });
                }
              }}
            />
            <InputBasic
              isOptional
              labelText="Note"
              placeholder="Provide note"
              {...register('contractLineNote')}
              error={errors.contractLineNote?.message}
            />
            <CheckboxInput
              isOptional
              labelText="MRR Start Date"
              {...register('contractLineContractBasedMRRCalc')}
              error={errors.contractLineContractBasedMRRCalc?.message}
              tooltip="MRR calculations default to using the Item start and end date. By selecting the toggle to the Contract start and end date, MRR will be calculated using those dates."
              checkboxLabel="Contract Start/End Date"
              defaultValue={getValues().contractLineContractBasedMRRCalc}
            />
          </StyledAdditionalOptionsContainer>
        </StyledFormContainer>
      </main>
      <footer>
        <StyledButtonContainer pushRight>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={handleSave}
          >
            SAVE
          </Button>
          <Button
            type="submit"
            onClick={handleSaveAndClose}
          >
            SAVE & CLOSE
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};
