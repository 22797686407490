import React, { useState } from 'react';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  ChevronDown,
  CloseIcon,
} from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { SectionLabel } from 'components/common/SectionLabel';
import { Button } from 'components/common/Button';
import { InputBasic } from 'components/common/Input';
import { Dropdown } from 'components/common/Dropdown';
import {
  StyledFormContainer,
  StyledButtonContainer,
  StyledExpandButton,
  StyledAdditionalOptionsContainer,
} from 'components/common/Forms/styled';
import { useGetAllTagsQuery } from 'store/services/tags';
import { Datepicker } from 'components/common/Datepicker';
import { toastSuccess } from 'utils/toast';
import {
  parseContractFormData,
  parseStateFormData,
  prepareContractAddPayload,
} from 'utils/contracts';
import {
  useAddContractMutation,
  useLazyContractsUniquenessQuery,
} from 'store/services/contracts';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import { actionBlocker } from 'utils/actionBlocker';
import { TagType } from 'interfaces/tags';
import { formStateActions } from 'store/slices/formState';
import { tagsSortFn } from 'utils/tags';
import {
  ContractFormFields,
  ContractStateFormFields,
} from 'interfaces/contracts';
import { formDataSelector } from 'store/selectors/formState';
import { useUniquenessCheck } from 'hooks/useUniquenessCheck';
import { CustomersDropdownLazy } from 'components/common/CustomersDropdownLazy';
import { predefinedContractNameSelector } from 'store/selectors/contracts';
import { formatDateToISO } from 'utils/dates';
import {
  addDays,
  addMonths,
} from 'date-fns';

interface Props {
  onClose: () => void;
  onSuccess?: (id?: string) => void;
  onAddTag: () => void;
  onAddCustomer: () => void;
}

export const AddContract = ({
  onClose,
  onSuccess,
  onAddTag,
  onAddCustomer,
}: Props) => {
  const dispatch = useAppDispatch();
  const formData = useAppSelector(formDataSelector);
  const [isOptionsExpanded, setIsOptionsExpanded] = useState(false);
  const [addContract, { isLoading }] = useAddContractMutation();
  const [contractsUnique, { isFetching: isCheckingUniqueness }] = useLazyContractsUniquenessQuery();
  const predefinedName = useAppSelector(predefinedContractNameSelector);

  const {
    tagOptions,
  } = useGetAllTagsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      tagOptions: data
        ?.filter((tag) => !tag.deleted && tag.type === TagType.CONTRACT)
        ?.sort(tagsSortFn) || [],
    }),
  });

  const schema = yup.object({
    name: yup.string()
      .required('Contract name is required.')
      .test(
        'isUnique',
        'Contract name must be unique.',
        () => isNameUnique,
      ),
    customer: yup.array()
      .of(
        yup.object({
          id: yup.string(),
          name: yup.string(),
        }))
      .min(1, 'Customer is required.'),
    startDate: yup.date()
      .nullable()
      .required('Start date is required.'),
    endDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'End date cannot be before start date.'),
      })
      .required('End date is required.'),
    bookingDate: yup.date()
      .nullable()
      .when('endDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .max(yup.ref('endDate'),
            'Booking date cannot be after end date.'),
      })
      .required('Booking date is required.'),
    cancelDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'Cancel date cannot be before start date.',
          ),
      }),
    crmId: yup.string(),
    externalLink: yup.string()
      .url('Please provide valid URL.'),
    notes: yup.string(),
  })
    .required();

  const defaultValues = {
    ...(formData
      ? {
        ...parseStateFormData(formData as ContractStateFormFields),
      }
      : {
        name: predefinedName || '',
        customer: [],
        startDate: undefined,
        endDate: undefined,
        bookingDate: undefined,
        cancelDate: undefined,
        crmId: '',
        tags: [],
        externalLink: '',
        notes: '',
      }
    ),
  };

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty, dirtyFields },
    trigger,
    clearErrors,
    setValue,
    reset,
    watch,
  } = useForm<ContractFormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const [isNameUnique, setName] = useUniquenessCheck(contractsUnique, {}, 'name', trigger);

  const triggerTouched = (field: any) => ((dirtyFields as Record<string, boolean | undefined>)[field]) && trigger(field);

  const startDate = watch('startDate');
  const suggestedBookingDate = startDate
    ? formatDateToISO(startDate)
    : undefined;

  const bookingDate = watch('bookingDate');
  const suggestedStartDate = bookingDate
    ? formatDateToISO(bookingDate)
    : undefined;

  const suggestedEndDate = startDate
    ? formatDateToISO(addDays(addMonths(startDate, 12), -1))
    : undefined;

  const handleSaveAndNext = handleSubmit((data) => {
    const contract = prepareContractAddPayload(data);

    return addContract(contract)
      .unwrap()
      .then((contract) => {
        toastSuccess('Contract successfully added.');
        onSuccess && onSuccess(contract.id);
        reset();
        setTimeout(() => clearErrors(), 0);
      });
  });

  const handleSaveAndClose = handleSubmit((data) => {
    const contract = prepareContractAddPayload(data);

    return addContract(contract)
      .unwrap()
      .then((contract) => {
        toastSuccess('Contract successfully added.');
        onSuccess && onSuccess(contract.id);
        onClose();
      });
  });

  const handleAddTag = ({ name }: { name: string }) => {
    dispatch(formStateActions.setPredefinedData({
      name,
      type: TagType.CONTRACT,
    }));
    dispatch(formStateActions.setFormData(parseContractFormData(getValues())));
    onAddTag();
  };

  const handleAddCustomer = ({ name }: { name: string }) => {
    if (onAddCustomer) {
      dispatch(formStateActions.setPredefinedData({ name }));
      dispatch(formStateActions.setFormData(parseContractFormData(getValues())));
      onAddCustomer();
    }
  };

  return (
    <>
      <header>
        <ButtonsContainer paddingBottom={16}>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={() => actionBlocker(onClose, isDirty)}
            pushRight
            data-cy="aco-button-close"
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <SectionLabel>
          <span>Add Contract</span>
        </SectionLabel>
      </header>
      <main>
        <StyledFormContainer>
          <SectionLabel secondary>
            Contract Overview
          </SectionLabel>
          <InputBasic
            isRequired
            labelText="Contract Name"
            placeholder="Name your contract"
            {...register('name')}
            onBlur={(e) => {
              const fieldValue = e.target.value;

              if (fieldValue) {
                setValue('name', fieldValue.trim(), { shouldValidate: true });
              }
            }}
            onCustomChange={(e) => setName(e.target.value)}
            error={errors.name?.message}
            tooltip="You can change the contract name, but it must be a unique value."
            isLoading={isCheckingUniqueness}
          />
          <Controller
            name="customer"
            control={control}
            render={({ field }) =>
              <CustomersDropdownLazy
                labelText="Customer"
                labelField="name"
                valueField="id"
                searchBy="name"
                placeholder="Select Customer"
                values={getValues('customer')}
                {...field}
                error={errors.customer?.message}
                isRequired
                create
                onCreateNew={handleAddCustomer}
                dataCy="aco-dropdown-customer"
              />
            }
          />
          <Controller
            name="bookingDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Contract Booking Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('startDate');
                  triggerTouched('endDate');
                  triggerTouched('cancelDate');
                }}
                error={errors.bookingDate?.message}
                isRequired
                data-cy="aco-datepicker-booking"
                tooltip="Contract booking date is used to indicate when this contract was initially booked."
                suggestedDate={suggestedBookingDate}
              />
            }
          />
          <Controller
            name="startDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Contract Start Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('bookingDate');
                  triggerTouched('endDate');
                  triggerTouched('cancelDate');
                }}
                error={errors.startDate?.message}
                isRequired
                data-cy="aco-datepicker-start"
                tooltip="The contract start date is used to indicate the beginning of service for the overall contract. It is used to calculate the term for ARR, MRR, and other SaaS metrics"
                suggestedDate={suggestedStartDate}
              />
            }
          />
          <Controller
            name="endDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Contract End Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('startDate');
                  triggerTouched('bookingDate');
                  triggerTouched('cancelDate');
                }}
                error={errors.endDate?.message}
                tooltip="Contract end date is used to calculate the term for ARR, MRR, and other SaaS metrics. This contract will remain active until it is renewed or cancelled."
                isRequired
                data-cy="aco-datepicker-end"
                suggestedDate={suggestedEndDate}
              />
            }
          />
          <Controller
            name="cancelDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Contract Cancel Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('startDate');
                  triggerTouched('bookingDate');
                  triggerTouched('endDate');
                }}
                error={errors.cancelDate?.message}
                isOptional
                data-cy="aco-datepicker-cancel"
                tooltip="Entering a contract cancel date will terminate the overall contract upon that date. Unless renewed, this contract will be counted as churn after this date."
              />
            }
          />
          <StyledExpandButton
            variant="borderless"
            size="large"
            type="button"
            onClick={() => setIsOptionsExpanded(!isOptionsExpanded)}
            isOpen={isOptionsExpanded}
          >
            Additional Options
            <ChevronDown />
          </StyledExpandButton>
          <StyledAdditionalOptionsContainer isOpen={isOptionsExpanded}>
            <Controller
              name="tags"
              control={control}
              render={({ field }) =>
                <Dropdown
                  labelText="Tags"
                  options={tagOptions}
                  labelField="name"
                  valueField="id"
                  searchBy="name"
                  placeholder="Select Tags"
                  entityName="tag"
                  values={getValues('tags')}
                  {...field}
                  isOptional
                  clearable
                  multi
                  error={errors.tags?.message}
                  tooltip="Categorize this contract with a contract tag(s)."
                  create
                  onCreateNew={handleAddTag}
                  dataCy="aco-dropdown-tag"
                />
              }
            />
            <InputBasic
              isOptional
              labelText="CRM ID"
              placeholder="Paste CRM identifier"
              tooltip="Paste in an ID from your CRM to help identify this contract."
              {...register('crmId')}
              error={errors.crmId?.message}
              data-cy="aco-input-crm"
            />
            <InputBasic
              isOptional
              labelText="External Link"
              placeholder="Paste an external link"
              tooltip="You can paste in a link to your CRM or wherever you store your contracts."
              {...register('externalLink')}
              error={errors.externalLink?.message}
              onBlur={(e) => {
                const fieldValue = e.target.value;

                if (fieldValue && !fieldValue.startsWith('http')) {
                  setValue('externalLink', 'http://' + fieldValue, { shouldValidate: true });
                }
              }}
              data-cy="aco-input-external"
            />
            <InputBasic
              isOptional
              labelText="Notes"
              placeholder="Add a quick note"
              {...register('notes')}
              error={errors.notes?.message}
              data-cy="aco-input-notes"
              multiline
            />
          </StyledAdditionalOptionsContainer>
        </StyledFormContainer>
      </main>
      <footer>
        <StyledButtonContainer pushRight>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={handleSaveAndClose}
            disabled={isLoading || isCheckingUniqueness}
            isLoading={isLoading}
            data-cy="aco-button-save-back"
          >
            SAVE & CLOSE
          </Button>
          <Button
            type="submit"
            onClick={handleSaveAndNext}
            disabled={isLoading || isCheckingUniqueness}
            isLoading={isLoading}
            data-cy="aco-button-save"
          >
            SAVE & NEXT
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};
