import styled, { css } from "styled-components";
import { theme } from "theme/theme";

interface StyledConfigurationDropdownProps {
  type: 'Metric' | 'Account';
  active?: boolean;
}

export const StyledConfigurationDropdownItem = styled.div<StyledConfigurationDropdownProps>`
  padding: 12px 16px;
  cursor: pointer;

  .line1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 24px;

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .line2 {
    font-size: 10px;
    line-height: 18px;
    color: ${theme.colors.textDisabled};
  }

    
  ${props => props.active && css`
    background: ${theme.colors.primaryBleach};
  `}
    
  &:hover,
  &:focus,
  &:active {
    background-color: ${theme.colors.primaryBleach};
    outline: 0;

    ins {
      background-color: ${theme.colors.white};
    }
  }

  .hit-hint {
    font-size: 10px;
    line-height: 22px;
    color: ${theme.colors.textDisabled};
    margin-left: 12px;
    border: 1px solid ${theme.colors.fieldBorder};
    border-radius: 4px;
    padding: 2px 8px;
    transition: opacity 300ms ease;
  }

  ins {
    margin-left: 24px;
    font-size: 10px;
    line-height: 24px;
    padding: 0 8px;
    border-radius: 4px;
    font-weight: 600;
    transition: all 300ms ease;
    white-space: nowrap;
    text-decoration: none;

    background-color: ${props => {
    switch (props.type) {
      case 'Account':
        return theme.colors.primaryBleach;
      case 'Metric':
        return theme.colors.tertiaryBleach;
      default:
        return theme.colors.transparent;
    }}};

    color: ${props => {
    switch (props.type) {
      case 'Account':
        return theme.colors.primaryStrong;
      case 'Metric':
        return theme.colors.tertiary;
      default:
        return theme.colors.textDark;
    }}};
  }
`;