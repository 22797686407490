import styled, { css } from "styled-components";
import { theme } from "theme/theme";
import { ANIMATION_DURATION } from "utils/constants";

export const StyledDropdownHeader = styled.div`
  display: flex;
`;

interface StyledDropdownButtonProps {
  pushRight?: boolean;
  iconRotate?: number;
}

export const StyledDropdownButton = styled.button<StyledDropdownButtonProps>`
  padding: 12px;
  margin: 0;
  border: 0;
  cursor: pointer;
  background-color: ${theme.colors.transparent};
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;

  ${props => props.pushRight && css`
    margin-left: auto;
  `}
  
  span {
    margin-right: 10px;
  }
  
  svg {
    width: 24px;
    height: 24px;

    ${props => props.iconRotate && css`
      transform: rotate(${props.iconRotate}deg);
    `}
  }
`;

interface StyledYearSelectorProps {
  isOpen?: boolean;
}

export const StyledYearSelector = styled.div<StyledYearSelectorProps>`
  opacity: ${props => props.isOpen ? 1 : 0};
  pointer-events: ${props => props.isOpen ? 'all' : 'none'};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${theme.colors.mainBackground};
  padding: 12px 16px;
  transition: opacity ${ANIMATION_DURATION}ms ease;

  div {
    padding: 8px 0;
  }
`;

export const StyledYearButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 4px 19px;
`;

export const StyledYearButton = styled.button<{ selected?: boolean }>`
  border: none;
  height: 44px;
  background-color: ${theme.colors.transparent};
  cursor: pointer;
  ${props => props.selected && css`
    font-weight: 700;
  `}

  &:hover {
    background-color: ${theme.colors.primaryBleach};
  }
`;
