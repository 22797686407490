import React from 'react';
import * as yup from "yup";
import { CloseIcon } from 'assets/icons';
import { SectionLabel } from 'components/common/SectionLabel';
import { Button } from 'components/common/Button';
import { useDuplicateReportMutation } from 'store/services/reports';
import { AgGridReact } from '@ag-grid-community/react';
import { highlightNodeById } from 'utils/aggrid';
import { toastSuccess } from 'utils/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  StyledButtonContainer,
  StyledFormContainer,
} from 'components/common/Forms/styled';
import { InputBasic } from 'components/common/Input';
import { Report } from 'interfaces/reports';

interface Props {
  report: Report;
  onClose: () => void;
  gridRef: React.RefObject<AgGridReact<any>>;
}

export const DuplicateReport = ({ report, onClose, gridRef }: Props) => {
  const [duplicateReport, { isLoading }] = useDuplicateReportMutation();

  const schema = yup.object({
    name: yup.string()
      .nullable(),
  })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{ name: string; }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: '',
    },
  });

  const handleDuplicate = handleSubmit((data) => {
    const payload = {
      reportId: report.id,
      name: data.name,
    };

    duplicateReport(payload)
      .unwrap()
      .then((result) => {
        toastSuccess('Report duplicated successfully');
        highlightNodeById(result.id, gridRef);
        onClose();
      });
  });

  return (
    <>
      <SectionLabel alignTop>
        <div>
          Duplicate Report
        </div>
        <Button
          variant="icon"
          onClick={onClose}
          disabled={isLoading}
          pushRight
        >
          <CloseIcon />
        </Button>
      </SectionLabel>
      <StyledFormContainer onSubmit={handleDuplicate}>
        <InputBasic
          labelText="Report Name"
          isOptional
          placeholder={`${report?.name} (copy)`}
          {...register('name')}
          onBlur={(e) => {
            const fieldValue = e.target.value;

            if (fieldValue) {
              setValue('name', fieldValue.trim(), { shouldValidate: true });
            }
          }}
          error={errors.name?.message}
        />
      </StyledFormContainer>
      <StyledButtonContainer>
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          onClick={onClose}
          disabled={isLoading}
          pushRight
          minWidth={200}
        >
          CANCEL
        </Button>
        <Button
          type="button"
          color="primary"
          onClick={handleDuplicate}
          disabled={isLoading}
          isLoading={isLoading}
          minWidth={200}
        >
          DUPLICATE REPORT
        </Button>
      </StyledButtonContainer>
    </>
  );
};
