import styled from "styled-components";
import { theme } from "theme/theme";
import { Button } from "components/common/Button";

export const HeaderButton = styled(Button)<{ error?: boolean }>`
  font-weight: 700;
  justify-content: space-between;
  text-align: left;
  box-shadow: none;
  border: none;
  padding: 0;
  height: 48px;
  width: 100%;
  color: ${props => props.error ? theme.colors.error : theme.colors.textDark};
  line-height: 16px;
  padding: 0 16px;
  background-color: white;

  span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &:hover {
    color: ${theme.colors.primary};
    background: transparent;
    box-shadow: none;
    border: none;
  }
`;

export const ButtonGroup = styled.div<{ shouldOmitGrouping?: boolean }>`
  display: flex;
  gap: 1px;
  
  & > button {
    margin-left: 16px;
    height: 28px;
    ${props => !props.shouldOmitGrouping && 'border-top-right-radius: 0;'}
    ${props => !props.shouldOmitGrouping && 'border-bottom-right-radius: 0;'}
  }  

  & > div > button {
    height: 28px;
    padding: 0 2px;
    ${props => !props.shouldOmitGrouping && 'border-top-left-radius: 0;'}
    ${props => !props.shouldOmitGrouping && 'border-bottom-left-radius: 0;'}
  }  
`;
