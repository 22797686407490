import styled, { css } from 'styled-components';
import { theme } from 'theme/theme';
import { Button } from 'components/common/Button';

export const StyledWrapper = styled.div`
  height: 340px;
  z-index: 3;
`;

export const StyledContainer = styled.div`
  display: flex;
  background-color: ${theme.colors.mainBackground};
  color: ${theme.colors.textDark};
  box-shadow: ${theme.boxShadow};
  max-height: 340px;
`;

export const StyledOption = styled(Button)<{ selected?: boolean; highlighted?: boolean; upperDivider?: boolean; }>`
  font-size: 14px;
  line-height: 21px;
  font-weight: ${props => props.selected ? 600 : 500};
  border-radius: 0;
  justify-content: flex-start;
  padding: 12px 20px;
  color: ${props => props.selected ? theme.colors.primary : theme.colors.textDark};
  background-color: ${props => props.highlighted ? theme.colors.secondaryBackground : theme.colors.transparent};
  border: none;
  height: auto;
  width: 100%;
  min-width: 150px;

  ${props => props.upperDivider && css`
    margin-top: 20px;

    &:before {
      content: '';
      position: absolute;
      top: -10px;
      display: block;
      height: 1px;
      width: 80%;
      background-color: ${theme.colors.borderColorDark};
    }
  `}

  &:hover {
    background-color: ${theme.colors.primaryBleach};
    box-shadow: none;
  }
`;

export const StyledLeftContainer = styled.div`
  overflow: auto;
  height: 100%;
`;

export const StyledRightContainer = styled.div<{ visible?: boolean }>`
  border-left: 1px solid ${theme.colors.borderColorDark};
  position: relative;
  top: 0;
  display: ${props => props.visible ? 'block' : 'none'};
  pointer-events: ${props => props.visible ? 'all' : 'none'};
  overflow: auto;
  height: 100%;
`;
