import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { CloseIcon } from 'assets/icons';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import { SectionLabel } from 'components/common/SectionLabel';
import { Button } from 'components/common/Button';
import {
  StyledButtonContainer,
  StyledFormContainer,
  StyledFormRow,
} from 'components/common/Forms/styled';
import { toastSuccess } from 'utils/toast';
import { Datepicker } from 'components/common/Datepicker';
import {
  formatDateToDisplay,
  formatDateToISO,
  isoToDate,
} from "utils/dates";
import { usePostSyncQBOMutation } from "store/services/integrations";
import { CheckboxInput } from "components/common/CheckboxInput";
import { QBOSyncFormFields } from "interfaces/integrations";
import { useGetSettingsQuery } from "store/services/settings";
import {
  useEffect,
  useMemo,
} from "react";
import { useGetAuthMeQuery } from "store/services/auth";
import { getCurrentCompany } from "utils/currentCompany";
import { IntegrationType } from "interfaces/accounts";

interface Props {
  onClose: () => void;
}

export const QBOSync = ({
  onClose,
}: Props) => {
  const [syncQBO, { isLoading: isSyncingQBO }] = usePostSyncQBOMutation();
  const { data: user } = useGetAuthMeQuery();
  const { data: settings, isLoading: isLoadingSettings } = useGetSettingsQuery();

  const currentCompany = getCurrentCompany(user);
  const lastSync = useMemo(() => isoToDate(currentCompany?.integrationSync.find((int) => int.provider === IntegrationType.QBO)?.syncedAt), [currentCompany?.integrationSync]);
  const glActualStartDate = useMemo(() => isoToDate(settings?.glActualStartDate), [settings?.glActualStartDate]);

  const schema = yup.object({
    startDate: yup.date()
      .nullable()
      .required('Start date is required.'),
    endDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'End date cannot be before start date.'),
      })
      .required('End date is required.'),
  })
    .required();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<QBOSyncFormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      startDate: lastSync || glActualStartDate,
      endDate: new Date(),
      acc: true,
      bs: true,
      pl: true,
    },
  });

  const handleSaveAndClose = handleSubmit((data) => {
    const payload = {
      acc: data.acc,
      bs: data.bs,
      pl: data.pl,
      startDate: formatDateToISO(data.startDate),
      endDate: formatDateToISO(data.endDate),
    };

    return syncQBO(payload)
      .unwrap()
      .then(() => {
        toastSuccess(`Synced successfully.`);
        onClose();
      });
  });

  useEffect(() => {
    if (glActualStartDate) {
      setValue('startDate', lastSync || glActualStartDate);
    }
  }, [glActualStartDate, lastSync, setValue]);

  return (
    <>
      <ButtonsContainer
        paddingBottom={40}
        alignTop
      >
        <SectionLabel
          data-cy="am-header"
          marginBottom={0}
        >
          <div>
            Sync Data from QBO
            <p>Last Sync: {lastSync ? formatDateToDisplay(lastSync) : 'none'}</p>
          </div>
        </SectionLabel>
        <Spacer />
        <Button
          aria-label="Close"
          variant="icon"
          size="large"
          onClick={onClose}
          data-cy="am-button-close"
        >
          <CloseIcon />
        </Button>
      </ButtonsContainer>
      <StyledFormContainer onSubmit={handleSaveAndClose}>
        <StyledFormRow>
          <Controller
            name="startDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Sync Start Date"
                placeholder="Select Date"
                {...field}
                error={errors.startDate?.message}
                isClearable={false}
                isRequired
                disabled={isLoadingSettings || isSyncingQBO}
              />
            }
          />
          <Controller
            name="endDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Sync End Date"
                placeholder="Select Date"
                {...field}
                error={errors.endDate?.message}
                isClearable={false}
                isRequired
                disabled={isSyncingQBO}
              />
            }
          />
        </StyledFormRow>
        <Controller
          name="acc"
          control={control}
          render={({ field }) =>
            <CheckboxInput
              isOptional
              {...field}
              checkboxLabel="Accounts"
              defaultValue={true}
              error={errors.acc?.message}
              disabled={isSyncingQBO}
            />
          }
        />
        <Controller
          name="pl"
          control={control}
          render={({ field }) =>
            <CheckboxInput
              isOptional
              {...field}
              checkboxLabel="Profit and Loss"
              defaultValue={true}
              error={errors.pl?.message}
              disabled={isSyncingQBO}
            />
          }
        />
        <Controller
          name="bs"
          control={control}
          render={({ field }) =>
            <CheckboxInput
              isOptional
              {...field}
              checkboxLabel="Balance Sheet"
              defaultValue={true}
              error={errors.bs?.message}
              disabled={isSyncingQBO}
            />
          }
        />
      </StyledFormContainer>
      <StyledButtonContainer>
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          onClick={onClose}
          disabled={isSyncingQBO || isLoadingSettings}
        >
          CANCEL
        </Button>
        <Button
          type="button"
          onClick={handleSaveAndClose}
          disabled={isSyncingQBO || isLoadingSettings}
          isLoading={isSyncingQBO}
        >
          SYNC
        </Button>
      </StyledButtonContainer>
    </>
  );
};
