import { Link } from 'react-router-dom';
import { ColDef } from '@ag-grid-community/core';
import {
  entityComparator,
  caseInsensitiveComparator,
  dateComparator,
} from "utils/aggrid";
import { formatDateStringToDisplay } from "utils/dates";
import { Tag } from "interfaces/tags";
import { AgGridCellRendererLink } from "components/common/AgGridCellRendererLink";
import { AgGridCommonCellRenderer } from "components/common/AgGridCommonCellRenderer";
import { renderContractConnections } from 'components/common/AgGridCellRendererContracts';
import { ContractConnection } from 'interfaces/contracts';
import {
  dateFilter,
  multiFilter,
  numberFilter,
} from 'utils/filters';

const OverviewCellRenderer = (item: any) => {
  if (!item.data) return null;

  return (
    <Link to={`/data-input/contract-management/contracts/${item.data.id}`}>
      <span>{item.value}</span>
    </Link>
  );
};

export const getColumnDefs = (): Array<ColDef> => ([
  {
    field: 'id',
    headerName: 'Id',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    cellRenderer: () => null,
    maxWidth: 70,
    pinned: 'left',
  },
  {
    field: 'name',
    headerName: 'Contract',
    sortable: true,
    resizable: true,
    cellRenderer: OverviewCellRenderer,
    flex: 1,
    minWidth: 300,
    comparator: caseInsensitiveComparator(),
    ...multiFilter,
  },
  {
    field: 'customer.name',
    headerName: 'Customer',
    sortable: true,
    resizable: true,
    cellRenderer: AgGridCommonCellRenderer('customer'),
    flex: 1,
    minWidth: 250,
    comparator: caseInsensitiveComparator(),
    enableRowGroup: true,
    ...multiFilter,

  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    resizable: true,
    enableRowGroup: true,
    ...multiFilter,
  },
  {
    field: 'bookingDate',
    headerName: 'Booking Date',
    sortable: true,
    valueFormatter: ({ value }) => formatDateStringToDisplay(value) || '-',
    getQuickFilterText: ({ value }) => formatDateStringToDisplay(value),
    comparator: dateComparator(),
    enableRowGroup: true,
    ...dateFilter,
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    sortable: true,
    valueFormatter: ({ value }) => formatDateStringToDisplay(value) || '-',
    getQuickFilterText: ({ value }) => formatDateStringToDisplay(value),
    comparator: dateComparator(),
    enableRowGroup: true,
    ...dateFilter,
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    sortable: true,
    valueFormatter: ({ value }) => formatDateStringToDisplay(value) || '-',
    getQuickFilterText: ({ value }) => formatDateStringToDisplay(value),
    comparator: dateComparator(),
    enableRowGroup: true,
    ...dateFilter,
  },
  {
    field: 'cancelDate',
    headerName: 'Cancel Date',
    sortable: true,
    valueFormatter: ({ value }) => formatDateStringToDisplay(value) || '-',
    getQuickFilterText: ({ value }) => formatDateStringToDisplay(value),
    comparator: dateComparator(),
    enableRowGroup: true,
    ...dateFilter,
  },
  {
    field: 'mrr',
    headerName: 'MRR',
    sortable: true,
    type: 'currency',
    aggFunc: 'sum',
    ...numberFilter,
  },
  {
    field: 'arr',
    headerName: 'ARR',
    sortable: true,
    type: 'currency',
    aggFunc: 'sum',
    ...numberFilter,
  },
  {
    field: 'tcv',
    headerName: 'TCV',
    sortable: true,
    type: 'currency',
    aggFunc: 'sum',
    ...numberFilter,
  },
  {
    field: 'tags',
    headerName: 'Tags',
    sortable: true,
    resizable: true,
    minWidth: 200,
    getQuickFilterText: ({ value }) => value?.map((tag: Tag) => tag.name),
    valueGetter: (params) => params.data?.tags?.map((tag: Tag) => tag.name) || [],
    ...multiFilter,
  },
  {
    field: 'previous',
    headerName: 'Previous Contract(s)',
    sortable: true,
    resizable: true,
    minWidth: 250,
    comparator: entityComparator,
    cellRenderer: renderContractConnections,
    valueFormatter: ({ value }) => value?.map((connection: ContractConnection) => connection.name),
    getQuickFilterText: ({ value }) => value?.map((connection: ContractConnection) => connection.name),
    enableRowGroup: true,
  },
  {
    field: 'next',
    headerName: 'Next Contract',
    sortable: true,
    resizable: true,
    minWidth: 250,
    comparator: entityComparator,
    cellRenderer: renderContractConnections,
    valueFormatter: ({ value }) => value?.map((connection: ContractConnection) => connection.name),
    getQuickFilterText: ({ value }) => value?.map((connection: ContractConnection) => connection.name),
    enableRowGroup: true,
  },
  {
    field: 'crmId',
    headerName: 'CRM ID',
    sortable: true,
    resizable: true,
    minWidth: 200,
    valueFormatter: ({ value }) => value ? value : '-',
    comparator: caseInsensitiveComparator(),
    enableRowGroup: true,
    ...multiFilter,
  },
  {
    field: 'externalLink',
    headerName: 'External Link',
    sortable: true,
    cellRenderer: AgGridCellRendererLink,
  },
]);
