import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  LlamaImage,
  LlamaSanta,
  SweatingLlamaImage,
} from 'assets/images';
import { LoadingBar } from 'components/common/LoadingBar';
import { featureSwitch } from 'utils/featureSwitch';

const StyledLoading = styled.div<{ suppressFullHeight?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${props => props.suppressFullHeight ? 'auto' : '100%'};
  gap: 30px;
`;

const LoadingBarContainer = styled.div`
  position: relative;
  max-width: 900px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
`;

const LoadingText = styled.p`
  margin-bottom: 20px;
`;

const AdditionalMessage = styled.p`
  margin-top: 20px;
  min-height: 24px;
  font-weight: 500;
`;

export interface TimeoutMessage {
  timeoutMs: number;
  message: string;
}

interface Props {
  title?: string;
  timeoutMessages?: Array<TimeoutMessage>;
  suppressFullHeight?: boolean;
}

export const Loading = ({
  title,
  timeoutMessages,
  suppressFullHeight,
}: Props) => {
  const [additionalMessage, setAdditionalMessage] = useState<string>('');
  const timers = useRef<Array<any>>([]);

  useEffect(() => {
    timeoutMessages?.forEach((timeoutMessage, index) => {
      timers.current[index] = setTimeout(() => setAdditionalMessage(timeoutMessage.message), timeoutMessage.timeoutMs);
    });

    return () => {
      timers.current.forEach((timer) => { // eslint-disable-line react-hooks/exhaustive-deps
        clearTimeout(timer);
      });
    };
  }, [timeoutMessages]);

  return (
    <StyledLoading suppressFullHeight={suppressFullHeight}>
      {additionalMessage
        ? <SweatingLlamaImage />
        : featureSwitch.llamaSanta
          ? <LlamaSanta />
          : <LlamaImage />
      }
      <LoadingBarContainer>
        <LoadingText>{title || 'Loading...'}</LoadingText>
        <LoadingBar />
        <AdditionalMessage>{additionalMessage}</AdditionalMessage>
      </LoadingBarContainer>
    </StyledLoading>
  );
};
