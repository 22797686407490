export interface Tag {
  id: string;
  name: string;
  description: string;
  type: TagType;
  deleted: boolean;
}

export interface TagUpdatePayload {
  id?: string;
  name: string;
  description: string | null;
  type: TagType;
}

export interface TagFormFields {
  id: string;
  name: string;
  description: string;
  type: Array<{
    label: TagType,
    value: TagType,
  }>;
}

export type Tags = Tag[];

export enum TagType {
  CONTRACT = 'Contract',
  PRODUCT = 'Product',
  CUSTOMER = 'Customer',
}
