import {
  SubscriptionFormFields,
  SubscriptionStateFormFields,
  SubscriptionUpdatePayload,
} from "interfaces/subscriptions";
import {
  formatDateToISO,
  isoToDate,
} from "./dates";

export const prepareSubscriptionPayload = (data: SubscriptionFormFields): SubscriptionUpdatePayload => {
  return {
    id: data.id,
    name: data.name,
    customerId: data.customer?.at(0)?.id || null,
    productId: data.product?.at(0)?.id || null,
    startDate: formatDateToISO(data.startDate!),
    endDate: formatDateToISO(data.endDate!),
    bookingDate: data.bookingDate ? formatDateToISO(data.bookingDate) : formatDateToISO(data.startDate!),
    cancelDate: data.cancelDate ? formatDateToISO(data.cancelDate) : null,
    revRecStartDate: data.revRecStartDate ? formatDateToISO(data.revRecStartDate) : null,
    revRecEndDate: data.revRecEndDate ? formatDateToISO(data.revRecEndDate) : null,
    amount: Number(data.amount),
    crmId: data.crmId || null,
    tagIds: data.tags.map((tag) => tag.id),
    externalLink: data.externalLink || null,
    notes: data.notes || null,
  };
};

export const parseSubscriptionFormData = (data: SubscriptionFormFields): SubscriptionStateFormFields => {
  return {
    ...data,
    startDate: data.startDate ? formatDateToISO(data.startDate) : undefined,
    endDate: data.endDate ? formatDateToISO(data.endDate) : undefined,
    bookingDate: data.bookingDate ? formatDateToISO(data.bookingDate) : undefined,
    cancelDate: data.cancelDate ? formatDateToISO(data.cancelDate) : undefined,
    revRecStartDate: data.revRecStartDate ? formatDateToISO(data.revRecStartDate) : undefined,
    revRecEndDate: data.revRecEndDate ? formatDateToISO(data.revRecEndDate) : undefined,
  };
};

export const parseStateFormData = (data: SubscriptionStateFormFields): SubscriptionFormFields => {
  return {
    ...data,
    startDate: data.startDate ? isoToDate(data.startDate) : undefined,
    endDate: data.endDate ? isoToDate(data.endDate) : undefined,
    bookingDate: data.bookingDate ? isoToDate(data.bookingDate) : undefined,
    cancelDate: data.cancelDate ? isoToDate(data.cancelDate) : undefined,
    revRecStartDate: data.revRecStartDate ? isoToDate(data.revRecStartDate) : undefined,
    revRecEndDate: data.revRecEndDate ? isoToDate(data.revRecEndDate) : undefined,
  };
};
