import styled from "styled-components";

export const StyledEditName = styled.div`
  position: relative;
  width: 25vw;
  min-width: 200px;
  max-width: 400px;

  label {
    display: none;
  }

  & > div {
    height: 35px;
  }

  p {
    position: absolute;
    top: -20px;
    right: 0;
    margin-top: 0;
    padding-top: 0;
  }
`;