import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { theme } from 'theme/theme';
import { ChevronDown } from 'assets/icons';

const StyledBreadcrumbs = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 32px;
`;

const StyledBreadcrumb = styled.div<{ isLink: boolean }>`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  color: ${theme.colors.textDark};
  
  a, a:visited {
    border: 0;
    padding: 0;
    background-color: transparent;
    cursor: ${props => props.isLink ? 'pointer' : 'auto'};
    color: ${props => props.isLink ? theme.colors.primary : theme.colors.textDark};
  }

  &:last-of-type {
    svg {
      display: none;
    }
  }
`;

const StyledChevron = styled(ChevronDown)`
  transform: rotate(270deg);
  width: 16px;
  height: 16px;
  margin: 4px;
`;

interface BreadcrumbsProps {
  children: React.ReactNode;
}

export const Breadcrumbs = ({ children }: BreadcrumbsProps) => {

  return (
    <StyledBreadcrumbs>
      {children}
    </StyledBreadcrumbs>
  );
};

interface BreadcrumbProps {
  children: string;
  link?: string;
}

export const Breadcrumb = ({
  children,
  link,
}: BreadcrumbProps) => {
  return (
    <StyledBreadcrumb isLink={!!link}>
      <Link to={link || '#'}>
        {children}
      </Link>
      <StyledChevron />
    </StyledBreadcrumb>
  );
};
