import { SearchResponse } from "interfaces/api";

export const enrichEntryWithDisabledFlag = (item: any) => item.deleted
  ? {
      ...item,
      disabled: true,
    }
  : item;

export const enrichWithDisabledFlag = <T extends any[]>(list: T) => list.map(enrichEntryWithDisabledFlag);

export const enrichSearchResponseWithDisabledFlag = <T extends SearchResponse<any>>(list: T) => ({ ...list, data: list.data.map(enrichEntryWithDisabledFlag) });

export const apiDeleteRecipe = <R extends { id: string, deleted: boolean }>(ids: string[]) => <T extends R[]>(entities: T) => {
  return entities.map((entity) => ids.includes(entity.id)
    ? {
        ...entity,
        deleted: true,
        disabled: true,
      }
    : entity,
  );
};

export const apiHardDeleteRecipe = <R extends { id: string }>(ids: string[]) => <T extends R[]>(entities: T) => {
  return entities.filter((entity) => !ids.includes(entity.id));
};

export const apiAddRecipe = <R extends {}>(addedEntity: R) => <T extends R[]>(entities: T) => {
  return [
    ...entities,
    addedEntity,
  ];
};

export const apiUpdateRecipe = <R extends { id: string }>(updatedEntity: R) => <T extends R[]>(entities: T) => {
  return entities.map((entity) => entity.id === updatedEntity.id
    ? updatedEntity
    : entity,
  );
};

export const apiAddMultipleRecipe = <R extends {}[]>(addedEntities: R) => <T extends R>(entities: T) => {
  return [
    ...entities,
    ...addedEntities,
  ];
};
