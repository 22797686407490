import {
  ProductFormFields,
  ProductUpdatePayload,
  ProductAddPayload,
} from "interfaces/products";

export const prepareProductAddPayload = (data: ProductFormFields): ProductAddPayload => {
  const { tags, ...rest } = data;

  return {
    ...rest,
    crmId: data.crmId || null,
    tagIds: data.tags.map((tag) => tag.id),
    revenueType: data.revenueType.at(0)!.value,
  };
};

export const prepareUpdateProductPayload = (data: ProductFormFields): ProductUpdatePayload => {
  const { tags, revenueType, ...rest } = data;

  return {
    ...rest,
    crmId: data.crmId || null,
    tagIds: data.tags.map((tag) => tag.id),
  };
};
