import * as React from "react";

const SvgComponent = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="inherit"
      d="M3 3v16c0 1.093.907 2 2 2h16v-2H5V3H3Zm16.32 3.314-4.332 4.291-2.994-2.863-5.691 5.541 1.394 1.434 4.309-4.196 3.006 2.873 5.716-5.66-1.408-1.42Z"
    />
  </svg>
);

export default SvgComponent;
