export enum StatusOption {
  NEW = 'New',
  OUTOFTERM = 'Out of Term',
  RENEWED = 'Renewed',
  CANCELED = 'Canceled',
  RENEWAL = 'Renewal',
  UPSELL = 'Upsell',
  DOWNGRADE = 'Downgrade',
}


export interface Entity {
  name: string;
}

export enum PeriodType {
  ANNUAL = 'annual',
  QUARTERLY = 'quarterly',
  MONTHLY = 'monthly',
}

export interface Frequency {
  name: "Annual" | "Monthly" | "Quarterly",
  variant: string,
  value: PeriodType,
}
