import React from 'react';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useForm,
  Controller,
} from 'react-hook-form';
import {
  ArrowLeftIcon,
  CloseIcon,
  InfoIcon,
} from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import {
  StyledFormContainer,
  StyledButtonContainer,
} from 'components/common/Forms/styled';
import { SectionLabel } from 'components/common/SectionLabel';
import { toastSuccess } from 'utils/toast';
import { Button } from 'components/common/Button';
import {
  formatDateToISODate,
  isoToDate,
} from 'utils/dates';
import { Hint } from 'components/common/Hint';
import { Dropdown } from 'components/common/Dropdown';
import { isAfter } from 'date-fns';
import {
  useGetAllSubscriptionsQuery,
  useLinkSubscriptionMutation,
} from 'store/services/subscriptions';
import {
  Subscriptions,
  SubscriptionsLinkPayload,
} from 'interfaces/subscriptions';

interface Props {
  onClose: () => void;
  onGoBack: () => void;
  selectedRows: Subscriptions;
}

export const LinkSubscription = ({
  selectedRows,
  onClose,
  onGoBack,
}: Props) => {
  const [linkSubscription, { isLoading }] = useLinkSubscriptionMutation();
  const isLinkingAllowed = selectedRows
    .every((con) => !con.previous?.name);

  const {
    subscriptions,
  } = useGetAllSubscriptionsQuery(undefined, {
    //TODO if possible use BE filters instead of selectFromResults
    selectFromResult: ({ data }) => ({
      subscriptions: data?.filter((sub) => !sub.deleted
        && (sub?.customer?.id === selectedRows[0]?.customer?.id)
        && (sub?.product?.id === selectedRows[0]?.product?.id)
        && !sub.next?.name
        && sub.id !== selectedRows[0].id
        && isAfter(isoToDate(selectedRows[0].startDate)!, isoToDate(sub.startDate)!)),
    }),
  });

  const schema = yup.object({
    subscriptions: yup.array()
      .min(1, 'Select previous revenue to link.'),
  })
    .required();

  const defaultValues = {
    subscriptions: [],
    cancelDate: formatDateToISODate(new Date()),
  };

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<{ subscriptions: Subscriptions, cancelDate: Date }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const handleSaveAndBack = handleSubmit((data) => {
    const payload: SubscriptionsLinkPayload = {
      subscriptionId: data.subscriptions.map((row) => row.id)[0],
      nextSubscriptionId: selectedRows.map((row) => row.id)[0],
    };

    return linkSubscription(payload)
      .unwrap()
      .then(() => {
        toastSuccess('Revenue successfully linked.');
        onGoBack();
      });
  });

  return (
    <>
      <header>
        <ButtonsContainer paddingBottom={16}>
          <Button
            aria-label="Go back"
            variant="icon"
            size="large"
            onClick={onGoBack}
            data-cy="lr-button-back"
          >
            <ArrowLeftIcon />
          </Button>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={onClose}
            pushRight
            data-cy="lr-button-close"
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <SectionLabel>
          <span>
            Link Revenue
          </span>
        </SectionLabel>
      </header>
      <main>
        <Hint>
          <InfoIcon />
          {!isLinkingAllowed
            ? (
              <div>Selected revenue is already linked.</div>
            )
            : (
              <div>Create a history for your revenue by linking it to previous revenue.</div>
            )}
        </Hint>
        {isLinkingAllowed && (
          <StyledFormContainer>
            <Controller
              name="subscriptions"
              control={control}
              render={({ field }) =>
                <Dropdown
                  labelText="Select Previous Revenue"
                  labelField="name"
                  valueField="id"
                  searchBy="name"
                  placeholder="Select Previous Revenue"
                  options={subscriptions || []}
                  values={getValues('subscriptions')}
                  {...field}
                  error={errors.subscriptions?.message}
                  dataCy="lr-dropdown-subscription"
                  multi
                  tooltip="In this list, you will only see revenues with the same customer and product
                    that have not been previously linked and whose start date is earlier
                    than the start date of this revenue."
                  noDataLabel="No eligible revenues"
                />
              }
            />
          </StyledFormContainer>
        )}
      </main>
      <footer>
        {isLinkingAllowed && (
          <StyledButtonContainer pushDown>
            <Button
              type="submit"
              onClick={handleSaveAndBack}
              disabled={isLoading}
              isLoading={isLoading}
              data-cy="lr-button-save-backs"
            >
              SAVE & BACK
            </Button>
          </StyledButtonContainer>
        )}
      </footer>
    </>
  );
};
