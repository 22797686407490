import { ICellRendererParams } from "@ag-grid-community/core";
import React from "react";
import styled from "styled-components";
import { theme } from "theme/theme";

const StyledCellRenderer = styled.div<{ width?: number }>`
  display: flex;
  align-items: flex-start;
  min-width: ${({ width }) => (width ? `${width - 38}px` : "auto")};

  ins {
    display: block;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
    text-align: center;
    width: 14px;
    border-radius: 4px;
    text-decoration: none;
    margin-right: 6px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 700;
  }
`;

interface Props extends ICellRendererParams {
  width?: number;
}

export const RichSelectCellRenderer = (props: Props) => {
  const [index, name] = props?.value?.split(':') || ['', '-'];

  return (
    <StyledCellRenderer width={props?.column?.getActualWidth() || props?.width || undefined}>
      <ins>{index}</ins>
      <div>{name}</div>
    </StyledCellRenderer>
  );
};
