import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import {
  LeftPane,
  RightPane,
} from 'components/Layout';
import { SectionLabel } from 'components/common/SectionLabel';
import {
  bulkImportCommonDataSelector,
  bulkImportContractLinesSelector,
} from 'store/selectors/bulkImport';
import { BulkImportSidePanel } from '../../../../components/BulkImport/SidePanel';
import { Tabs } from 'components/common/Tabs';
import { AgGridReact } from '@ag-grid-community/react';
import { GridOptions } from '@ag-grid-community/core';
import { getColumnDefs } from './columnDefinitions';
import { useRightPanes } from 'hooks/useRightPanes';
import { BulkImportContractsEditContractLine } from '../EditContractLine';
import { useGetAllProductsQuery } from 'store/services/products';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import { useBulkImportContractsMutation } from 'store/services/import';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import { bulkImportActions } from 'store/slices/bulkImport';
import { invalidateTags } from 'store/services/api';
import {
  toastSuccess,
  toastWarning,
} from 'utils/toast';
import { Loading } from 'components/Loading';
import { AddProduct } from 'pages/Products/AddProduct';
import { AddTag } from 'pages/Tags/AddTag';
import { Customer } from 'interfaces/customers';
import { Product } from 'interfaces/products';
import { Tag } from 'interfaces/tags';
import { prepareValidatedContracts } from 'utils/bulkImport';
import { FactaTable } from 'components/FactaTable';
import { CompanyRevenueType } from 'interfaces/company';
import { BulkImportContractsRow } from 'interfaces/bulkImportContracts';
import { BulkImportDeleteContractLines } from '../DeleteContractLines';
import {
  ContractLinesTags,
  ContractsTags,
} from 'store/services/api-tags';
import { useLeaveElement } from 'hooks/useLeaveElement';
import { PanesWrapper } from 'components/Layout/styled';

export const BulkImportContractsReview = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const columnDefs = getColumnDefs();
  const gridRef = useRef<AgGridReact>(null);
  const [bulkImport, { isLoading }] = useBulkImportContractsMutation();
  const [selectedTab, setSelectedTab] = useState('all');
  const [selectedRows, setSelectedRows] = useState<BulkImportContractsRow[]>([]);
  const rows = useAppSelector(bulkImportContractLinesSelector);
  const bulkImportCommonData = useAppSelector(bulkImportCommonDataSelector);
  useGetAllProductsQuery();
  const pageRef = useRef(null);
  const { isOutside } = useLeaveElement(pageRef);

  const {
    contractLinesCount,
    contractLinesErrorsCount,
    contractLinesDuplicatesCount,
  } = bulkImportCommonData;

  const { resetPanes, rightPanes, setSelectedPane } = useRightPanes({
    summary: true,
    edit: false,
    delete: false,
    addProduct: false,
    addTag: false,
  });

  const gridOptions: GridOptions = {
    onSelectionChanged: (e) => {
      const selectedNodes = e.api.getSelectedNodes();
      setSelectedRows(e.api.getSelectedRows());
      if (selectedNodes.length === 1) {
        setSelectedPane('edit');
      } else {
        setSelectedPane('summary');
      }
    },
    processCellForClipboard: ({ value }) => value?.providedValue || '',
  };

  const tabs = [
    {
      name: 'All',
      value: "all",
    },
    {
      name: `Issues ${contractLinesErrorsCount ? `(${contractLinesErrorsCount})` : ''}`,
      value: "errors",
      disabled: !contractLinesErrorsCount,
    },
    {
      name: `Duplicates ${contractLinesDuplicatesCount ? `(${contractLinesDuplicatesCount})` : ''}`,
      value: "duplicates",
      disabled: !contractLinesDuplicatesCount,
    },
  ];

  const tabbedRows = rows.filter((row) => {
    switch (selectedTab) {
      case 'duplicates':
        return row.contractLineErrors === 'duplicate';
      case 'errors':
        return row.contractLineErrors !== null && row.contractLineErrors !== 'duplicate';
      default:
        return row;
    }
  });

  const handleCancel = () => navigate('/data-input/contract-management/contracts/import');

  const handleContinue = () => {
    const validatedContracts = prepareValidatedContracts(rows);

    bulkImport({ data: validatedContracts, save: true })
      .unwrap()
      .then((result) => {
        if (result) {
          toastWarning('There are still some issues with your data. Resolve all errors/duplicates.');
          dispatch(bulkImportActions.populateContracts({ result }));
        } else {
          toastSuccess('Contracts successfully added');
          dispatch(invalidateTags([
            ContractsTags.Contracts,
            ContractLinesTags.ContractLines,
          ]));
          dispatch(bulkImportActions.clear());
          navigate('/data-input/contract-management/contracts');
        }
      });
  };

  const handleTriggerDelete = () => {
    setSelectedPane('delete');
  };

  const handleUpdateRow = (id: string, field: string, addedEntity: Customer | Product | Tag) => {
    dispatch(bulkImportActions.updateContractsField({
      id,
      name: field,
      data: {
        providedValue: addedEntity.name,
        matchedValue: { id: addedEntity.id },
        errorCode: null,
      },
    }));
    setSelectedPane('edit');
  };

  useEffect(() => {
    if (!rows.length) {
      navigate('/data-input/contract-management/contracts/import');
    } else {
      dispatch(bulkImportActions.userReachedReviewDataScreen(true));
    }
  }, [dispatch, navigate, rows]);

  if (isLoading) {
    return (
      <LeftPane>
        <Loading
          title={`Uploading ${contractLinesCount} contracts...`}
          timeoutMessages={[
            {
              timeoutMs: 20000,
              message: 'Upload process is taking more time than usual... but we\'re working on it!',
            },
            {
              timeoutMs: 40000,
              message: 'Upload process is taking too much time. If it fails, consider reducing number of lines in your file.',
            },
          ]}
        />
      </LeftPane>
    );
  }

  return (
    <PanesWrapper ref={pageRef}>
      <LeftPane>
        <ButtonsContainer paddingBottom={20}>
          <Breadcrumbs>
            <Breadcrumb link="/data-input/contract-management/contracts">Contracts</Breadcrumb>
            <Breadcrumb link="/data-input/contract-management/contracts/import">Upload File</Breadcrumb>
            <Breadcrumb link="/data-input/contract-management/contracts/import/clean-data">Clean Data</Breadcrumb>
            <Breadcrumb link="/data-input/contract-management/contracts/import/review-contracts">Review Contracts</Breadcrumb>
            <Breadcrumb>Review Contract Lines & Submit</Breadcrumb>
          </Breadcrumbs>
        </ButtonsContainer>
        <SectionLabel>
          <span>
            Review Contract Lines & Submit
            <p>Please update any required fields before submitting your contract lines.</p>
          </span>
        </SectionLabel>
        <Tabs
          tabs={tabs}
          value={selectedTab}
          onChange={(tab) => setSelectedTab(tab.value)}
        />
        <FactaTable
          gridRef={gridRef}
          data={tabbedRows}
          useNativeFilters
          columnDefs={columnDefs}
          isLoading={isLoading}
          selectedRowsLength={selectedRows.length}
          entityName="Contract Line"
          gridOptions={gridOptions}
          onClickDelete={handleTriggerDelete}
          customEmptyState={() => <>Nothing to show</>}
          showQuickSearch
        />
      </LeftPane>
      <RightPane isOpen>
        {rightPanes.summary && (
          <BulkImportSidePanel
            companyRevenueType={CompanyRevenueType.CONTRACTS}
            handleCancel={handleCancel}
            handleContinue={handleContinue}
            isContinueDisabled={!!contractLinesErrorsCount || !!contractLinesDuplicatesCount || isLoading}
            step={4}
            isStepDone={contractLinesErrorsCount === 0 && contractLinesDuplicatesCount === 0}
            submitLabel="SUBMIT"
            isOutside={isOutside}
          />
        )}
        {rightPanes.edit && selectedRows.length === 1 && (
          <BulkImportContractsEditContractLine
            id={selectedRows[0].id}
            onClose={() => gridRef.current?.api.deselectAll()}
            // onAddTag={() => setSelectedPane('addTag')} // TODO needs to be fixed for Bulk import (ticket: 14095)
            onAddProduct={() => setSelectedPane('addProduct')}
            onDelete={handleTriggerDelete}
          />
        )}
        {rightPanes.delete && selectedRows.length > 0 && (
          <BulkImportDeleteContractLines
            selectedRows={selectedRows}
            onClose={() => resetPanes()}
          />
        )}
        {rightPanes.addProduct && (
          <AddProduct
            onClose={() => setSelectedPane('edit')}
            onSuccess={(addedEntity) => handleUpdateRow(selectedRows.at(0)!.id, 'contractLineProduct', addedEntity)}
            onGoBack={() => setSelectedPane('edit')}
            isIndirectOpen
          />
        )}
        {rightPanes.addTag && (
          <AddTag
            onClose={() => setSelectedPane('edit')}
            onSuccess={(addedEntity) => handleUpdateRow(selectedRows.at(0)!.id, 'contractLineTags', addedEntity)}
            onGoBack={() => setSelectedPane('edit')}
            isIndirectOpen
          />
        )}
      </RightPane>
    </PanesWrapper>
  );
};
