import { ColDef } from '@ag-grid-community/core';
import {
  caseInsensitiveComparator,
  dateComparator,
} from "utils/aggrid";
import { formatDateStringToDisplay } from "utils/dates";
import { Tag } from "interfaces/tags";
import { AgGridCellRendererLink } from "components/common/AgGridCellRendererLink";
import { AgGridCommonCellRenderer } from "components/common/AgGridCommonCellRenderer";
import { Button } from 'components/common/Button';
import { EditIcon } from 'assets/icons';
import {
  dateFilter,
  multiFilter,
  numberFilter,
} from 'utils/filters';

interface ColumnDefsProps {
  setEditedLine: (id: any) => void;
}

export const getColumnDefs = ({
  setEditedLine,
}: ColumnDefsProps): Array<ColDef> => ([
  {
    field: 'id',
    headerName: 'Id',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    cellRenderer: () => null,
    maxWidth: 70,
    pinned: 'left',
  },
  {
    field: 'name',
    headerName: 'Contract Line',
    sortable: true,
    resizable: true,
    flex: 1,
    minWidth: 300,
    comparator: caseInsensitiveComparator(),
    ...multiFilter,
  },
  {
    field: 'contract.name',
    headerName: 'Contract',
    sortable: true,
    resizable: true,
    flex: 1,
    minWidth: 250,
    comparator: caseInsensitiveComparator(),
    ...multiFilter,
  },
  {
    field: 'customer.name',
    headerName: 'Customer',
    sortable: true,
    resizable: true,
    cellRenderer: AgGridCommonCellRenderer('customer'),
    flex: 1,
    minWidth: 250,
    comparator: caseInsensitiveComparator(),
    ...multiFilter,
  },
  {
    field: 'product.name',
    headerName: 'Product',
    sortable: true,
    resizable: true,
    cellRenderer: AgGridCommonCellRenderer('product'),
    flex: 1,
    minWidth: 250,
    comparator: caseInsensitiveComparator(),
  },
  {
    field: 'product.revenueType',
    headerName: 'Revenue Type',
    sortable: true,
    resizable: true,
    minWidth: 200,
    comparator: caseInsensitiveComparator(),
    ...multiFilter,
  },
  {
    field: 'status',
    headerName: 'Type/Status',
    sortable: true,
    resizable: true,
    minWidth: 200,
    comparator: caseInsensitiveComparator(),
    ...multiFilter,
  },
  {
    field: 'previousAmount',
    headerName: 'Original Amount',
    sortable: true,
    type: 'currency',
    ...numberFilter,
  },
  {
    field: 'amount',
    headerName: 'Renewal Amount',
    sortable: true,
    type: 'currency',
    ...numberFilter,
  },
  {
    field: 'bookingDate',
    headerName: 'Booking Date',
    sortable: true,
    valueFormatter: ({ value }) => formatDateStringToDisplay(value),
    getQuickFilterText: ({ value }) => formatDateStringToDisplay(value),
    comparator: dateComparator(),
    ...dateFilter,
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    sortable: true,
    valueFormatter: ({ value }) => formatDateStringToDisplay(value),
    getQuickFilterText: ({ value }) => formatDateStringToDisplay(value),
    comparator: dateComparator(),
    ...dateFilter,
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    sortable: true,
    valueFormatter: ({ value }) => formatDateStringToDisplay(value),
    getQuickFilterText: ({ value }) => formatDateStringToDisplay(value),
    comparator: dateComparator(),
    ...dateFilter,
  },
  {
    field: 'cancelDate',
    headerName: 'Cancel Date',
    sortable: true,
    valueFormatter: ({ value }) => formatDateStringToDisplay(value) || '-',
    getQuickFilterText: ({ value }) => formatDateStringToDisplay(value),
    comparator: dateComparator(),
    ...dateFilter,
  },
  {
    field: 'revRecStartDate',
    headerName: 'Recognition Start Date',
    sortable: true,
    valueFormatter: ({ value }) => formatDateStringToDisplay(value) || '-',
    getQuickFilterText: ({ value }) => formatDateStringToDisplay(value),
    comparator: dateComparator(),
    ...dateFilter,
  },
  {
    field: 'revRecEndDate',
    headerName: 'Recognition End Date',
    sortable: true,
    valueFormatter: ({ value }) => formatDateStringToDisplay(value) || '-',
    getQuickFilterText: ({ value }) => formatDateStringToDisplay(value),
    comparator: dateComparator(),
    ...dateFilter,
  },
  {
    field: 'tags',
    headerName: 'Tags',
    sortable: true,
    resizable: true,
    minWidth: 200,
    getQuickFilterText: ({ value }) => value?.map((tag: Tag) => tag.name),
    valueGetter: (params) => params.data?.tags?.map((tag: Tag) => tag.name) || [],
    ...multiFilter,
  },
  {
    field: 'crmId',
    headerName: 'CRM ID',
    sortable: true,
    resizable: true,
    minWidth: 200,
    valueFormatter: ({ value }) => value ? value : '-',
    ...multiFilter,
  },
  {
    field: 'externalLink',
    headerName: 'External Link',
    sortable: true,
    cellRenderer: AgGridCellRendererLink,
  },
  {
    field: 'edit',
    headerName: '',
    pinned: 'right',
    sortable: false,
    type: 'rightAligned',
    width: 80,
    cellRenderer: ({ data }: any) => (
      <Button
        variant="icon"
        color="primary"
        onClick={() => setEditedLine(data.id)}
        size="large"
        pushRight
      >
        <EditIcon />
      </Button>
    ),
  },
]);
