import {
  useMemo,
  useRef,
  useState,
} from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { FactaTable } from 'components/FactaTable';
import { SectionLabel } from 'components/common/SectionLabel';
import {
  ColDef,
  GridOptions,
} from '@ag-grid-community/core';
import {
  ArrowRightIcon,
  CloseIcon,
} from 'assets/icons';
import { LeftPane } from 'components/Layout';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import { Button } from 'components/common/Button';
import { Status } from 'components/common/Status';

interface Props {
  results: any[];
  onClose: () => void;
  onApply: () => void;
}

export const AIAssistantResults = ({
  results,
  onClose,
  onApply,
}: Props) => {
  const gridRef = useRef<AgGridReact>(null);
  const [showGrouped, setShowGrouped] = useState(false);
  const gridOptions: GridOptions = useMemo(() => ({
    getRowId: (data) => data.data.glAccount,
    suppressClipboardPaste: true,
    defaultColDef: {
      resizable: true,
      initialWidth: 200,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    },
    maintainColumnOrder: true,
    suppressMaintainUnsortedOrder: true,
    onFirstDataRendered: (params) => {
      params.api.autoSizeAllColumns();
    },
    rowClassRules: {
      'disabled-row': ({ node }) => !node?.data?.category || !node?.data?.factaAccount,
    },
  }), []);

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        field: 'glAccount',
        editable: false,
        sortable: true,
      },
      {
        field: '',
        headerName: '',
        resizable: false,
        width: 80,
        cellRenderer: ({ data }: any) => <Status>{data?.category && data?.factaAccount ? <ArrowRightIcon /> : <CloseIcon />}</Status>,
        suppressMenu: true,
        sortable: false,
        hide: showGrouped,
      },
      {
        field: 'category',
        editable: false,
        sortable: true,
        enableRowGroup: true,
        rowGroup: showGrouped,
        hide: showGrouped,
      },
      {
        field: 'factaAccount',
        editable: false,
        sortable: true,
        enableRowGroup: true,
        rowGroup: showGrouped,
        hide: showGrouped,
      },
    ];
  }, [showGrouped]);

  return (
    <LeftPane removeMargin>
      <ButtonsContainer alignTop>
        <SectionLabel marginBottom={8}>
          Results
        </SectionLabel>
        <Spacer />
        <Button
          aria-label="Close"
          variant="icon"
          size="large"
          onClick={onClose}
        >
          <CloseIcon />
        </Button>
      </ButtonsContainer>
      <FactaTable
        gridRef={gridRef}
        gridOptions={gridOptions}
        data={results || []}
        columnDefs={columnDefs}
        selectedRowsLength={0}
        entityName="result"
        condensed
        suppressAddButton
        suppressColumnViews
        customButtons={[
          {
            title: showGrouped ? 'Switch to Flat List' : 'Switch to Grouped View',
            onClick: () => {
              setShowGrouped(!showGrouped);
              setTimeout(() => {
                gridRef?.current?.api.expandAll();
              }, 0);
            },
            variant: 'borderless',
            size: 'large',
            divider: true,
          },
          {
            title: 'CLOSE',
            onClick: onClose,
            variant: 'outlined',
            pushRight: true,
          },
          {
            title: 'APPLY & CLOSE',
            onClick: () => {
              onApply();
              onClose();
            },
            variant: 'contained',
          },
        ]}
      />
    </LeftPane>
  );
};
