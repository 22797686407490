import React, {
  CSSProperties,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useClickOutside } from 'hooks/useClickOutside';
import {
  StyledContainer,
  StyledLeftContainer,
  StyledOption,
  StyledWrapper,
  StyledRightContainer,
} from '../ChangeVersion/styled';
import { Instance } from '@popperjs/core';
import { useGetReportPeriodsQuery } from 'store/services/reports';
import { formatDateStringToMMMYearDisplay } from 'utils/dates';

interface Props {
  popperRef: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  style: CSSProperties;
  attributes: {
    [key: string]: {
      [key: string]: string;
    } | undefined;
  };
  popperUpdate: Instance['update'];
  onClose: () => void;
  onApply: (dataSource: string, col: string) => void;
  col: string | null;
  range: {
    start: Date;
    end: Date;
  }
}

export const ChoosePeriod = ({
  popperRef,
  attributes,
  style,
  popperUpdate,
  onClose,
  onApply,
  col,
}: Props) => {
  const [options, setOptions] = useState<any[]>([]);
  const [visibleFactaType, setVisibleFactaType] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);
  const { data: periods } = useGetReportPeriodsQuery();
  useClickOutside(containerRef, onClose);

  useEffect(() => {
    const options = [
      {
        periodType: 'Month',
        periodOptions: (periods?.monthly || []),
      },
      {
        periodType: 'Quarter',
        periodOptions: periods?.quarterly || [],
      },
      {
        periodType: 'Year',
        periodOptions: periods?.annual || [],
      },
    ];

    setOptions(options);
  }, [periods]);

  return (
    <div ref={containerRef}>
      <StyledWrapper
        ref={popperRef}
        style={style}
        {...attributes}
      >
        <StyledContainer>
          <StyledLeftContainer>
            {options.map(({ periodType }) => (
              <StyledOption
                key={periodType}
                highlighted={visibleFactaType === periodType}
                onMouseEnter={() => {
                  setVisibleFactaType(periodType !== 'Uncategorized' ? periodType : '');
                  popperUpdate();
                }}
              >
                {periodType}
              </StyledOption>
            ))}
          </StyledLeftContainer>
          <div>
            {options.map(({ periodType, periodOptions }) => (
              <StyledRightContainer
                key={`containe_${periodType}`}
                visible={visibleFactaType === periodType}
              >
                {periodOptions.map((periodOption: string, index: number) => (
                  <StyledOption
                    key={`${periodType}_${periodOption}`}
                    onClick={() => {
                      onApply(periodOption, col!);
                      onClose();
                    }}
                    upperDivider={index !== 0 && (periodOption.slice(4, 8) === '-01-' || periodOption.slice(0, 2) === 'Q1')}
                  >
                    {periodType === 'Month'
                      ? formatDateStringToMMMYearDisplay(periodOption)
                      : periodOption}
                  </StyledOption>
                ))}
              </StyledRightContainer>
            ))}
          </div>
        </StyledContainer>
      </StyledWrapper>
    </div>
  );
};
