import styled, { css } from "styled-components";
import { theme } from "theme/theme";

export const Status = styled.div<{ color?: 'success' | 'error' | 'warning' }>`
  ${props => props.color && css`
    color: ${theme.colors[props.color]};
  `}
  
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  span {
    margin-left: 2px;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    top: -5px;
  }
`;
