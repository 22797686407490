import * as React from "react";

const SvgComponent = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="inherit"
      d="M8 3v16H6V7H3v14h18V9h-3v10h-2v-6h-3v6h-2V3H8Z"
    />
  </svg>
);

export default SvgComponent;
