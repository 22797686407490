import styled from "styled-components";

export const StyledCaption = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;

interface AnimateWrapperProps{
  visible?: boolean;
}

export const AnimatedWrapper = styled.div<AnimateWrapperProps>`
  max-height: ${props => props.visible ? '600px' : 0};
  opacity: ${props => props.visible ? 1 : 0};
  transition: all 0.6s ease-in-out;
  overflow: hidden;
`;

export const ComingSoon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: auto;
  margin-bottom: auto;
`;