import React, { useState } from 'react';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  SubscriptionFormFields,
  Subscriptions,
} from 'interfaces/subscriptions';
import {
  ArrowLeftIcon,
  ChevronDown,
  CloseIcon,
} from 'assets/icons';
import { ButtonsContainer } from 'components/common/ButtonsContainer';
import { Datepicker } from 'components/common/Datepicker';
import {
  StyledFormContainer,
  StyledButtonContainer,
  StyledAdditionalOptionsContainer,
  StyledExpandButton,
  StyledFormSectionLabel,
} from 'components/common/Forms/styled';
import { SectionLabel } from 'components/common/SectionLabel';
import {
  isoToDate,
  formatDateToISODate,
  formatDateToISO,
} from 'utils/dates';
import { Button } from 'components/common/Button';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import { Dropdown } from 'components/common/Dropdown';
import { InputBasic } from 'components/common/Input';
import { AssistiveText } from 'components/common/Input/AssistiveText';
import { NumberInput } from 'components/common/NumberInput';
import { useGetAllProductsQuery } from 'store/services/products';
import {
  useGetAllSubscriptionsQuery,
  useRenewSubscriptionMutation,
} from 'store/services/subscriptions';
import { useGetAllTagsQuery } from 'store/services/tags';
import {
  parseStateFormData,
  parseSubscriptionFormData,
  prepareSubscriptionPayload,
} from 'utils/subscriptions';
import { toastSuccess } from 'utils/toast';
import { actionBlocker } from 'utils/actionBlocker';
import { TagType } from 'interfaces/tags';
import { formStateActions } from 'store/slices/formState';
import { formDataSelector } from 'store/selectors/formState';
import { SubscriptionStateFormFields } from "interfaces/subscriptions";
import { tagsSortFn } from 'utils/tags';
import { CustomersDropdownLazy } from 'components/common/CustomersDropdownLazy';
import {
  addDays,
  addMonths,
} from 'date-fns';

interface Props {
  onClose: () => void;
  onGoBack: () => void;
  onAddTag: () => void;
  selectedRows: Subscriptions;
}

export const RenewSubscription = ({
  onClose,
  onGoBack,
  onAddTag,
  selectedRows,
}: Props) => {
  const dispatch = useAppDispatch();
  const formData = useAppSelector(formDataSelector);
  const [isOptionsExpanded, setIsOptionsExpanded] = useState(false);
  const [renewSubscription, { isLoading }] = useRenewSubscriptionMutation();

  const {
    products,
  } = useGetAllProductsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      products: data?.filter((product) => !product.deleted),
    }),
  });

  const {
    subscriptionsNames,
  } = useGetAllSubscriptionsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      subscriptionsNames: data
        ?.filter((subscription) => !subscription.deleted)
        ?.map((subscription) => subscription.name),
    }),
  });

  const {
    tagOptions,
  } = useGetAllTagsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      tagOptions: data
        ?.filter((tag) => !tag.deleted && tag.type === TagType.CONTRACT)
        ?.sort(tagsSortFn) || [],
    }),
  });

  const latestStartDateOrBookingDate = selectedRows.length
    ? isoToDate(selectedRows
      .flatMap((sub) => sub.bookingDate ? [sub.startDate, sub.bookingDate] : [sub.startDate])
      .reduce((date1, date2) => (date1 > date2 ? date1 : date2)))!
    : formatDateToISODate(new Date());

  const schema = yup.object({
    customer: yup.array()
      .of(
        yup.object({
          id: yup.string(),
          name: yup.string(),
        }))
      .min(1, 'Customer is required.'),
    product: yup.array()
      .of(
        yup.object({
          id: yup.string(),
          name: yup.string(),
        }))
      .min(1, 'Product is required.'),
    name: yup.string()
      .required('Description is required.')
      .test(
        'isUnique',
        'Description must be unique.',
        (val) => !(subscriptionsNames?.includes(val || '')),
      ),
    amount: yup.string()
      .required('Amount is required.'),
    startDate: yup.date()
      .nullable()
      .test(
        'isNotBeforeStartDateOrBookingDate',
        'Renewal date cannot be set before previous revenue start date or booking date.',
        (val) => val ? (val.getTime() >= latestStartDateOrBookingDate.getTime()) : false,
      )
      .required('Renewal Date is required.'),
    endDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'End date cannot be before start date.'),
      })
      .required('End date is required.'),
    bookingDate: yup.date()
      .nullable()
      .when('endDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .max(yup.ref('endDate'),
            'Booking date cannot be after end date.'),
      })
      .test(
        'isNotBeforeStartDateOrBookingDate',
        'Booking date cannot be set before previous revenue start date or booking date.',
        (val) => val ? (val.getTime() >= latestStartDateOrBookingDate.getTime()) : true,
      ),
    cancelDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'Cancel date cannot be before start date.'),
      }),
    revRecStartDate: yup.date()
      .nullable()
      .when('startDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('startDate'),
            'Revenue recognition start date cannot be before start date.'),
      }),
    revRecEndDate: yup.date()
      .nullable()
      .when('revRecStartDate', {
        is: (val: Date | null) => val,
        then: yup.date()
          .min(yup.ref('revRecStartDate'),
            'Revenue recognition end date cannot be before Revenue recognition start date.')
          .required('When Revenue recognition start date provided this field is required.'),
      }),
    crmId: yup.string(),
    externalLink: yup.string(),
    notes: yup.string(),
  })
    .required();

  const defaultValues = {
    ...(formData
      ? {
        ...parseStateFormData(formData as SubscriptionStateFormFields),
        id: selectedRows[0].id,
      }
      : {
        id: selectedRows[0].id,
        customer: [selectedRows[0].customer],
        product: [selectedRows[0].product],
        name: '',
        amount: `${selectedRows[0].amount}`,
        startDate: formatDateToISODate(new Date()),
        endDate: undefined,
        cancelDate: undefined,
        bookingDate: undefined,
        revRecStartDate: undefined,
        revRecEndDate: undefined,
        crmId: selectedRows[0].crmId || '',
        externalLink: selectedRows[0].externalLink || '',
        notes: selectedRows[0].notes || '',
        tags: selectedRows[0].tags,
      }
    ),
  };

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty, dirtyFields },
    trigger,
    watch,
  } = useForm<SubscriptionFormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const triggerTouched = (field: any) => ((dirtyFields as Record<string, boolean | undefined>)[field]) && trigger(field);

  const startDate = watch('startDate');
  const suggestedEndDate = startDate
    ? formatDateToISO(addDays(addMonths(startDate, 12), -1))
    : undefined;

  const handleSaveAndClose = handleSubmit((data) => {
    const subscription = prepareSubscriptionPayload({
      ...data,
      id: selectedRows[0].id,
    });

    return renewSubscription(subscription)
      .unwrap()
      .then(() => {
        toastSuccess('Revenue successfully renewed.');
        onClose();
      });
  });

  const handleAddTag = ({ name }: { name: string }) => {
    dispatch(formStateActions.setPredefinedData({
      name,
      type: TagType.CONTRACT,
    }));
    dispatch(formStateActions.setFormData(parseSubscriptionFormData(getValues())));
    onAddTag();
  };

  return (
    <>
      <header>

        <ButtonsContainer paddingBottom={16}>
          <Button
            aria-label="Go back"
            variant="icon"
            size="large"
            onClick={onGoBack}
          >
            <ArrowLeftIcon />
          </Button>
          <Button
            aria-label="Close"
            variant="icon"
            size="large"
            onClick={() => actionBlocker(onClose, isDirty)}
            pushRight
          >
            <CloseIcon />
          </Button>
        </ButtonsContainer>
        <SectionLabel>
          <span>Renew Revenue</span>
        </SectionLabel>
      </header>
      <main>
        <StyledFormContainer>
          <InputBasic
            labelText="Previous Revenue Description"
            placeholder=""
            value={selectedRows[0]?.name}
            name="Previous Revenue Description"
            disabled
            readonly
          />
          <Controller
            name="customer"
            control={control}
            render={({ field }) =>
              <CustomersDropdownLazy
                labelText="Customer"
                labelField="name"
                valueField="id"
                searchBy="name"
                placeholder="Select Customer"
                values={getValues('customer')}
                {...field}
                error={errors.customer?.message}
                disabled
              />
            }
          />
          <Controller
            name="product"
            control={control}
            render={({ field }) =>
              <div>
                <Dropdown
                  labelText="Product"
                  labelField="name"
                  valueField="id"
                  searchBy="name"
                  placeholder="Select Product SKU"
                  options={products || []}
                  values={getValues('product')}
                  {...field}
                  error={errors.product?.message}
                  disabled
                />
                <AssistiveText text={getValues('product')[0]?.revenueType} />
              </div>
            }
          />
          <InputBasic
            labelText="Description"
            placeholder="Describe your revenue"
            {...register('name')}
            isRequired
            error={errors.name?.message}
          />
          <Controller
            name="amount"
            control={control}
            defaultValue={''}
            render={({ field }) =>
              <NumberInput
                labelText="Amount"
                {...field}
                onChange={field.onChange}
                placeholder="Enter Amount"
                isRequired
                error={errors.amount?.message}
              />
            }
          />
          <Controller
            name="startDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Renewal Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('bookingDate');
                  triggerTouched('endDate');
                  triggerTouched('cancelDate');
                }}
                isRequired
                error={errors.startDate?.message}
              />
            }
          />
          <Controller
            name="bookingDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Different Booking Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('startDate');
                  triggerTouched('endDate');
                  triggerTouched('cancelDate');
                }}
                error={errors.bookingDate?.message}
              />
            }
          />
          <Controller
            name="endDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="End Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('startDate');
                  triggerTouched('bookingDate');
                  triggerTouched('cancelDate');
                }}
                error={errors.endDate?.message}
                isRequired
                suggestedDate={suggestedEndDate}
              />
            }
          />
          <Controller
            name="cancelDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Cancel Date"
                placeholder="Cancel Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('startDate');
                  triggerTouched('bookingDate');
                  triggerTouched('endDate');
                }}
                isOptional
                error={errors.cancelDate?.message}
              />
            }
          />
          <StyledFormSectionLabel>
            <span>
              Revenue Recognition
            </span>
          </StyledFormSectionLabel>
          <Controller
            name="revRecStartDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Recognition Start Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                }}
                error={errors.revRecStartDate?.message}
                isOptional
              />
            }
          />
          <Controller
            name="revRecEndDate"
            control={control}
            defaultValue={undefined}
            render={({ field }) =>
              <Datepicker
                labelText="Recognition End Date"
                placeholder="Select Date"
                {...field}
                onChange={(date) => {
                  field.onChange(date);
                  triggerTouched('revRecEndDate');
                }}
                error={errors.revRecEndDate?.message}
                isOptional
              />
            }
          />
          <StyledExpandButton
            variant="borderless"
            size="large"
            type="button"
            onClick={() => setIsOptionsExpanded(!isOptionsExpanded)}
            isOpen={isOptionsExpanded}
          >
            Additional Options
            <ChevronDown />
          </StyledExpandButton>
          <StyledAdditionalOptionsContainer isOpen={isOptionsExpanded}>
            <Controller
              name="tags"
              control={control}
              render={({ field }) =>
                <Dropdown
                  labelText="Tags"
                  labelField="name"
                  valueField="id"
                  searchBy="name"
                  options={tagOptions}
                  placeholder="Select Tags"
                  entityName="tag"
                  values={getValues('tags')}
                  {...field}
                  isOptional
                  clearable
                  multi
                  error={errors.tags?.message}
                  tooltip="Categorize this revenue with a revenue tag(s)."
                  create
                  onCreateNew={handleAddTag}
                />
              }
            />
            <InputBasic
              isOptional
              labelText="CRM ID"
              placeholder="Paste CRM identifier"
              tooltip="Provide a reference ID from your CRM or any place where you track your revenue."
              {...register('crmId')}
              error={errors.crmId?.message}
            />
            <InputBasic
              isOptional
              labelText="External Link"
              placeholder="Paste an external link"
              {...register('externalLink')}
              error={errors.externalLink?.message}
            />
            <InputBasic
              isOptional
              labelText="Notes"
              placeholder="Add a quick note"
              {...register('notes')}
              error={errors.notes?.message}
            />
          </StyledAdditionalOptionsContainer>
        </StyledFormContainer>
      </main>
      <footer>
        <StyledButtonContainer pushRight>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            CANCEL
          </Button>
          <Button
            type="submit"
            onClick={handleSaveAndClose}
            disabled={isLoading}
            isLoading={isLoading}
            data-cy="rs-renew-back-button"
          >
            RENEW & BACK
          </Button>
        </StyledButtonContainer>
      </footer>
    </>
  );
};
