import React, { useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import FullStory, { FullStoryAPI } from 'react-fullstory';

export const FullStoryComponent = () => {
  const env = import.meta.env.VITE_APP_FULLSTORY_ORG;
  const { user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    if (env && user) {
      FullStoryAPI('identify', user.attributes?.email || '');
      FullStoryAPI('setUserVars', {
        displayName: `${user.attributes?.given_name} ${user.attributes?.family_name}`,
        email: user?.attributes?.email,
      });
    }
  }, [env, user]);

  return env
    ? <FullStory org={import.meta.env.VITE_APP_FULLSTORY_ORG!} />
    : null;
};
