import styled from "styled-components";
import { LoadingIcon } from "../Input/styled";
import { theme } from "theme/theme";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  
  svg {
    margin: 0 23px;
  }

  span {
    margin: 0 23px;
    color: ${theme.colors.textDisabled};
  }
`;

export const AgGridLoadingCellRenderer = () => (
  <StyledWrapper>
    <LoadingIcon />
    <span>Loading...</span>
  </StyledWrapper>
);
