import React from 'react';
import {
  Outlet,
  useLocation,
} from 'react-router';
import {
  StyledContainer,
  StyledLink,
  StyledMenu,
  StyledMenuHeader,
} from './styled';
import { UserRole } from 'interfaces/auth';
import { usePrefetchSettings } from 'store/services/settings';
import { usePrefetchUsers } from 'store/services/users';
import { Company } from 'interfaces/company';

interface Props {
  currentCompany: Company;
}

export const Settings = ({ currentCompany }: Props) => {
  const location = useLocation();
  const prefetchSettings = usePrefetchSettings('getSettings');
  const prefetchUsers = usePrefetchUsers('getAllCompanyUsers');

  const settingsMenuItems = [
    {
      name: 'Your Profile',
      path: 'profile',
    },
    {
      name: 'General Settings',
      path: 'general',
      prefetch: () => prefetchSettings(),
      hidden: !currentCompany?.isCompanyActive,
      allowedRoles: [UserRole.SUPERUSER, UserRole.ADMIN, UserRole.OWNER, UserRole.MEMBER],
    },
    {
      name: 'Companies',
      path: 'companies',
    },
    {
      name: 'Users',
      path: 'users',
      allowedRoles: [UserRole.SUPERUSER, UserRole.ADMIN, UserRole.OWNER],
      hidden: !currentCompany?.isCompanyActive,
      prefetch: () => prefetchUsers(),
    },
  ];

  const isSubRoute = settingsMenuItems.find((item) => location.pathname.includes(item.path));

  return (
    <StyledContainer>
      <StyledMenu>
        <StyledMenuHeader>Settings</StyledMenuHeader>
        {settingsMenuItems.map((item, index) => {
          const isActive = isSubRoute
            ? location.pathname.includes(item.path)
            : index === 0;

          return (
            item.allowedRoles && !item.allowedRoles?.includes(currentCompany?.role)
              || item.hidden
              ? null
              : (
                <StyledLink
                  key={item.name}
                  to={item.path}
                  $isHighlighted={isActive}
                  onMouseOver={item.prefetch}
                >
                  {item.name}
                </StyledLink>
              )
          );
        })}
      </StyledMenu>
      <Outlet />
    </StyledContainer>
  );
};
